import Api from '../../../lib/api';
import Utils from '../../../lib/utils';
import * as types from './types';
import get from 'lodash/get';
export const setAddReviewApplicationsSuccess = (success) => {
  return {
    type: types.SET_ADD_REVIEW_APPLICATIONS_SUCCESS,
    payload: {
      success
    }
  };
};

export const setAddReviewApplicationsError = (error, errorCode) => {
  return {
    type: types.SET_ADD_REVIEW_APPLICATIONS_ERROR,
    payload: {
      error,
      errorCode
    }
  };
};


export const setFetchingReviewApplications = (isFetching) => {
  return {
    type: types.SET_FETCHING_REVIEW_APPLICATIONS,
    payload: {
      isFetching
    }
  };
};
export const setReviewApplicationsList = (list, total) => {
  return {
    type: types.SET_REVIEW_APPLICATIONS_LIST,
    payload: {
      list,
      total
    }
  };
};
export const setReviewApplicationsListError = (error) => {
  return {
    type: types.SET_REVIEW_APPLICATIONS_LIST_ERROR,
    payload: {
      error
    }
  };
};

export const setReviewApplicationsHiringClient = (list) => {
  return {
    type: types.SET_REVIEW_APPLICATIONS_HIRING_CLIENT,
    payload: {
      list
    }
  };
};
export const setReviewApplicationsHiringClientError = (error) => {
  return {
    type: types.SET_REVIEW_APPLICATIONS_HIRING_CLIENT_ERROR,
    payload: {
      error
    }
  };
};
export const setReviewApplicationsSubcontractor = (subcontractorData) => {
  return {
    type: types.SET_REVIEW_APPLICATIONS_SUBCONTRACTOR,
    payload: subcontractorData
  };
};

export const setProcessingAddReviewApplicationEndPoint = (processing) => {
  return {
    type: types.SET_PROCESSING_ADD_REVIEW_APPLICATION,
    processing
  };
};


export const setAddReviewApplications = (payload, callback) => {
  return (dispatch, getState) => {
    const { login: { authToken }, localization } = getState();
    const {
      error10000,
      error10001,
      error10003,
      error10005,
      error10006,
      error10007,
      error10019,
      error10156,
      error10157,
      errorDefault,
      errorConnection
    } = localization.strings.reviewApplications.errors;
    const { submitted, autoApproved } = localization.strings.reviewApplications.success;
    const errors = {
      10000: error10000,
      10001: error10001,
      10003: error10003,
      10005: error10005,
      10006: error10006,
      10017: error10007,
      10019: error10019,
      10156: error10156,
      10157: error10157,
    };
  //console.log(payload);
    dispatch(setProcessingAddReviewApplicationEndPoint(true));
    dispatch(setAddReviewApplicationsError(null,null));
    const getErrorMessage = (code) => get(errors, code, '');
    return Api.post("subcontractors/applications", payload, authToken)
      .then((response) => {
        const { success, data, message } = response.data;
        dispatch(setProcessingAddReviewApplicationEndPoint(false));
        dispatch(setReviewApplicationsSubcontractor({}));
        if (success) {
          let message = submitted;

          if (data.autoApprove) {
            message = autoApproved;
          }

          dispatch(
            setAddReviewApplicationsSuccess(
              message.replace("{hiringClientName}", payload.hiringClientName)
            )
          );

          dispatch(
            setReviewApplicationsSubcontractor({
              subcontractorEmail: payload.subcontractorContactEmail,
              subcontractorPassword: payload.password,
            })
          );

          if (callback) {
            callback(data);
          }
        } else {
          const errorMsg = errors[data.errorCode] || errorDefault;
          dispatch(
            setAddReviewApplicationsError(message || errorMsg, data.errorCode)
          );
          if (callback) callback(null);
        }
      })
      .catch((error) => {
        //console.log('setAddReviewApplications error', error);
        const { data } = get(error, "response.data", { data: {} });

        if (data.isVertikal) {
          // taxId or email already associated to SC or HC in same Parent Group
          if (data.errorCode === 10156 || data.errorCode === 10157) {
            const message = getErrorMessage(data.errorCode).replace(
              `{${data.errorCode}}`,
              data.companyName
            );
            dispatch(setAddReviewApplicationsError(message, data.errorCode));
          }
        } else {
          dispatch(setAddReviewApplicationsError(errorDefault, data.errorCode));
        }
      });
  }
};


export const fetchReviewApplications = (queryParams) => {
  return (dispatch, getState) => {
    const { login, localization, search } = getState();
    const { errorDefault, errorConnection } = localization.strings.hiringClients.actions;
    const { authToken } = login;
    const { itemsPerPage } = search;
  //console.log(queryParams);

    dispatch(setFetchingReviewApplications(true));
    dispatch(setReviewApplicationsListError(null));
    dispatch(setReviewApplicationsList([]));

    const urlParameters = Utils.getPaginatedUrlParameters(queryParams, itemsPerPage);
    let urlQuery = `/subcontractors/applications`;
    return Api.get(`${urlQuery}${urlParameters}`, authToken)
      .then(response => {
        const { success, data, totalCount } = response.data;
        //console.log(response.data);
        if(success) {
          dispatch(setReviewApplicationsList(data, totalCount));
        }
        else {
          dispatch(setReviewApplicationsListError(errorDefault));
        }
        dispatch(setFetchingReviewApplications(false));
      })
      .catch(error => {
        dispatch(setReviewApplicationsListError(errorConnection));
        dispatch(setFetchingReviewApplications(false));
      });
  };
};

export const fetchHiringClient = (payload, callback) => {
  return (dispatch, getState) => {
    const { login, localization } = getState();
    const { errorDefault, errorConnection } = localization.strings.hiringClients.actions;
    const { authToken } = login;
    const queryParams = {};

    dispatch(setReviewApplicationsHiringClientError(null));
    dispatch(setReviewApplicationsHiringClient([]));

    const urlParameters = Utils.getUrlParameters(queryParams);
    let urlQuery = `hiringclients`;
    return Api.get(`${urlQuery}${urlParameters}`, authToken)
      .then(response => {
        const { success, data } = response.data;
        if(success) {
          dispatch(setReviewApplicationsHiringClient(data.hiringClients));
        }
        else {
          dispatch(setReviewApplicationsHiringClientError(errorDefault));
        }
      })
      .catch(error => {
        dispatch(setReviewApplicationsHiringClientError(errorConnection));
      });
  };
};

export const setApplicationsError = (error) => {
  return {
    type: types.SET_APPLICATIONS_ERROR,
    payload: {
      error
    }
  };
};

export const setApproveApplications = (data) => {
  return {
    type: types.SET_APPROVE_APPLICATIONS,
    payload: {
      data
    }
  };
};

export const setDeclineApplications = (data) => {
  return {
    type: types.SET_DECLINE_APPLICATIONS,
    payload: {
      data
    }
  };
};

export const approveApplications = (payload, callback) => {
//console.log("approveApplications");
  return (dispatch, getState) => {
    const { login: { authToken }, localization } = getState();
    const {
      error10000,
      error10001,
      error10003,
      error10005,
      error10006,
      error10007,
      error10019,
      errorDefault,
      errorConnection
    } = localization.strings.reviewApplications.errors;

    const errors = {
      10000: error10000,
      10001: error10001,
      10003: error10003,
      10005: error10005,
      10006: error10006,
      10017: error10007,
      10019: error10019,
    };
  //console.log(payload);
    
    dispatch(setApplicationsError(null));
    dispatch(setApproveApplications(null));
    
    return Api.post('subcontractors/applications/approve', payload, authToken)
      .then(response => {
        const {success, data} = response.data;
        
        if (success) {
          dispatch(setApproveApplications(true));
          if (callback) callback (success);
        } else {
          const errorMsg = errors[data.errorCode] || errorDefault;          
          dispatch(setApplicationsError(errorMsg));
          if (callback) callback (null);
        }
      })
      .catch(() => {
        dispatch(setApplicationsError(errorConnection));
        if (callback) callback (null);
      });
  }
};

export const declineApplications = (payload, callback) => {
//console.log("declineApplications");
  return (dispatch, getState) => {
    const { login: { authToken }, localization } = getState();
    const {
      error10000,
      error10001,
      error10003,
      error10005,
      error10006,
      error10007,
      error10019,
      errorDefault,
      errorConnection
    } = localization.strings.reviewApplications.errors;

    const errors = {
      10000: error10000,
      10001: error10001,
      10003: error10003,
      10005: error10005,
      10006: error10006,
      10017: error10007,
      10019: error10019,
    };
  //console.log(payload);
    
    dispatch(setApplicationsError(null));
    dispatch(setDeclineApplications(null));
    
    return Api.post('subcontractors/applications/decline', payload, authToken)
      .then(response => {
        const {success, data} = response.data;
        
        if (success) {
          dispatch(setDeclineApplications(true));
          if (callback) callback (success);
        } else {
          const errorMsg = errors[data.errorCode] || errorDefault;          
          dispatch(setApplicationsError(errorMsg));
          if (callback) callback (null);
        }
      })
      .catch(() => {
        dispatch(setApplicationsError(errorConnection));
        if (callback) callback (null);
      });
  }
};

export const setShowModal = (show) => {
  return {
    type: types.SET_SHOW_ANSWERS_MODAL,
    payload: show
  }
};
