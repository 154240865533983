import * as types from './types';
import Api from '../../../../../../lib/api';

export const setFetchingRegistrationSettings = (condition) => {
  return {
    type: types.SET_FETCHING_REGISTRATION_SETTINGS,
    payload: condition,
  };
};
export const setFetchRegistrationSettingsError = (error) => {
  return {
    type: types.SET_FETCH_REGISTRATION_SETTINGS_ERROR,
    payload: error
  };
};
export const setPostRegistrationSettingsError = (error) => {
  return {
    type: types.SET_POST_REGISTRATION_SETTINGS_ERROR,
    payload: error
  };
};
export const fetchRegistrationSettings = (holderId, callback) => {
  return (dispatch, getState) => {
    const { login: { authToken }, localization } = getState();
    const { errorDefault, errorConnection } = localization.strings.holderSettings.errors;

    dispatch(setFetchingRegistrationSettings(true));

    return Api.get(`cf/holderSettings?holderId=${holderId}`, authToken)
      .then(response => {
        const { success, data } = response.data;
        if(success) {
          dispatch(setFetchingRegistrationSettings(false));
          if (callback) callback((data && data.length > 0) ? data[0] : []);
        } else {
          dispatch(setFetchRegistrationSettingsError(errorDefault));
        }
      })
      .catch(() => {
        dispatch(setFetchRegistrationSettingsError(errorConnection));
      });
  };
};

export const sendRegistrationSettings = (settings, callback) => {
  return (dispatch, getState) => {
    const { login: { authToken }, localization } = getState();
    const { errorDefault, errorConnection } = localization.strings.holderSettings.errors;

    dispatch(setFetchingRegistrationSettings(true));

    return Api.put(`cf/holderSettings`, settings, authToken)
      .then(response => {
        const { success } = response.data;
        if(success) {
          dispatch(setFetchingRegistrationSettings(false));
          if (callback) callback(false);
        } else {
          dispatch(setPostRegistrationSettingsError(errorDefault));
          if (callback) callback(true);
        }
      })
      .catch(() => {
        dispatch(setPostRegistrationSettingsError(errorConnection));
        if (callback) callback(true);
      });
  };
};

export const fetchRequirementSets = (queryParams, callback) => {
  return (dispatch, getState) => {
    const { login: { authToken }, localization } = getState();
    const { errorDefault, errorConnection } = localization.strings.holderSettings.errors;
    const urlParameters = `?${Object.keys(queryParams).map(i => `${i}=${queryParams[i]}`).join('&')}`;

    return Api.get(`cf/requirementSets${urlParameters}`, authToken)
      .then(response => {
        const { success, requirementSets } = response.data;
        if(success) {
          callback(false, requirementSets);
        } else {
          callback(true, errorDefault);
        }
      })
      .catch(() => {
        callback(true, errorConnection);
      });
  };
};