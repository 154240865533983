import moment from "moment";
import Swal from 'sweetalert2';
import {Modal} from "react-bootstrap";
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';

import './Waivers.css';
import * as actions from './actions';
import FilterWaivers from './filter';
import Utils from '../../../../../lib/utils';
import PTable from '../../../../common/ptable';
import {showQuickConfirmation} from "../../../../alerts";
import * as commonActions from '../../../../common/actions';
import ViewWaivedModal from "../../../modals/viewWaivedModal";
import * as deficienciesActions from '../../../../common/deficiences/actions/actions.js'

class Waivers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {
        pageNumber: 1,
      },
      showFilterBox: true,
      tableOrderActive: 'waiverEndDate',
      order: {
        waiverEndDate: 'asc',
        holderName: 'desc',
        projectName: 'desc',
        coverageName: 'desc',
        conditionValue: 'desc',
        attributeValue: 'desc',
      },
      showModalWaived: false,
      currentWaive: null,
    };
  }

  componentDidMount() {
    const { actions, insuredId } = this.props;

    actions.fetchWaivers({
      orderBy: 'waiverEndDate',
      orderDirection:'ASC',
      ...(insuredId && { insuredId }),
    });
  }

  addId = (query) => {
    const { insuredId } = this.props;

    if (insuredId) {
      return { ...query, insuredId };
    }

    return query;
  }

  clickOnColumnHeader = (e, field) => {

    if (field === 'edit' || field === 'undo') return;

    // get base query
    const pageNumber = this.state.filter.pageNumber;
    const orderDirection = this.state.order[field] === 'asc' ? 'DESC' : 'ASC';
    let preQuery = Utils.getFetchQuery(field, pageNumber, orderDirection);

    const query = this.addId(preQuery);

    // fetch using query
    this.props.actions.fetchWaivers(query);

    // save new active tab and order
    let newState = {
      tableOrderActive: field,
      order: {
        waiverEndDate: field === 'waiverEndDate' ? 'asc' : 'desc',
        holderName: field === 'holderName' ? 'asc' : 'desc',
        projectName: field === 'projectName' ? 'asc' : 'desc',
        coverageName: field === 'coverageName' ? 'asc' : 'desc',
        conditionValue: field === 'conditionValue' ? 'asc' : 'desc',
        attributeValue: field === 'attributeValue' ? 'asc' : 'desc',
      },
    };

    newState.order[field] = this.state.order[field] === 'asc' ? 'desc' : 'asc';
    this.setState(newState);
  }

  setPageFilter = (e, pageNumber, force) => {
    if(force || this.state.filter.pageNumber !== pageNumber) {
      // get base query
      const field = this.state.tableOrderActive;
      const orderDirection = this.state.order[field] === 'asc' ? 'ASC' : 'DESC';
      let preQuery = Utils.getFetchQuery(field, pageNumber, orderDirection);

      const query = this.addId(preQuery);

      // fetch using query
      this.props.actions.fetchWaivers(query);

      // save page number
      this.setState({
        filter: {
          pageNumber,
        }
      });
    }
  }

  toggleFilterBox = () => {
    this.setState({ showFilterBox: !this.state.showFilterBox });
  }

  submitFilterForm = (values) => {
    // get base query
    const field = this.state.tableOrderActive;
    const pageNumber = 1;
    const orderDirection = this.state.order[field] === 'asc' ? 'ASC' : 'DESC'
    let preQuery = Utils.getFetchQuery(field, pageNumber, orderDirection);

    // add search filters
    const filterBox = {
      holderName: values.holderName || '',
      projectName: values.projectName || '',
      waiverEndDate: values.waiverEndDate || '',
    };

    preQuery = Utils.addSearchFiltersToQuery(preQuery, filterBox);

    const query = this.addId(preQuery);

    // fetch using query
    this.props.actions.fetchWaivers(query);

    // save searchterm and pagenumber
    this.setState({
      filterBox,
      filter: {
        pageNumber: 1
      }
    });
  }

  actionUnDoWaive = (waive) => {
    const {CFRole} = this.props.profile;
    const {Name} = CFRole;
    if(Name != 'Holder View Only'){
      const {title, description} = this.props.locale.alerts.undoWaive;
      Swal({
        title: title,
        html: `<strong>${waive.coverageName} - ${waive.attributeName}</strong><br/>${description}`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2E5965',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
      }).then((result) => {
        if (result.value) {
          const {projectInsuredId, projectInsuredDeficiencyID} = waive;
          this.props.commonActions.setLoading(true);
          this.props.deficienciesActions.fetchUndoWaiverDeficiences(projectInsuredDeficiencyID, projectInsuredId, () => {
            this.props.commonActions.setLoading(false);
            this.setPageFilter(null, 1, true);
          });
        }
      });
    }
  }

  renderEditWaiveLink = (waive) => {
    const {editWaive} = this.props.locale.actions;
      return (
        <a
          onClick={() => this.showWaivedDetail(waive)}
          className="cell-table-link"
        >{editWaive}</a>
      )
    }
    
  renderUndoWaiveLink = (waive) => {
    const {undoWaive} = this.props.locale.actions;
    return (
        <a
          onClick={() => this.actionUnDoWaive(waive)}
          className="cell-table-link"
          style={{ color: '#F00' }}
        >{undoWaive}</a>

    )
  }

  showWaivedDetail = (waive) => {
    const {CFRole} = this.props.profile;
    const {Name} = CFRole;
    if(Name != 'Holder View Only'){
      this.setState({
        showModalWaived: true,
        currentWaive: waive,
      })
    }
  }
  closeWaivedDetail = () => {
    this.setState({
      showModalWaived: false,
      currentWaive: null,
    })
  }

  handleUpdateWaiveSubmit = (values) => {
    const queryParams = {
      waiverEndDate: values.waiverEndDate,
      projectInsuredDeficiencyID: values.projectInsuredDeficiencyID,
    }
    this.props.actions.updateWaivedDeficiency(queryParams)
      .then(success => {
        if (success) {
          showQuickConfirmation(
            {
              title: this.props.locale.messages.confirm,
              timer: 3000
            }
          );
          this.setPageFilter(null, 1, true);
          this.closeWaivedDetail();
        } else {
          showQuickConfirmation(
            {
              title: this.props.locale.messages.error,
              timer: 1500
            }
          );
          return false;
        }
      })
  }

  render() {
    const { insuredId, insuredArchived } = this.props;
    const {CFRole} = this.props.profile;
    const {
      holder,
      project,
      deficiency,
      attribute,
      changeStatusColumn,
      dateExpires,
      requiredValue,
      providedValue,
    } = this.props.locale.list;

    const TableMetadata = {
      fields: [
        'holderName',
        'projectName',
        'coverageName',
        'attributeName',
        'waiverEndDate',
        'conditionValue',
        'attributeValue',
        'edit',
        'undo',
      ],
      header: {
        holderName: holder,
        projectName: project,
        coverageName: deficiency,
        attributeName: attribute,
        waiverEndDate: dateExpires,
        conditionValue: requiredValue,
        attributeValue: providedValue,
        edit: '',
        undo: '',
      },
    };

    const TableBody = this.props.waivers.list.map((record) => {
      const {
        holderName,
        projectFullName,
        attributeName,
        coverageName,
        waiverEndDate,
        conditionValue,
        attributeValue,
      } = record;
      return {
        holderName: holderName,
        waiverEndDate: moment.utc(waiverEndDate).format('MM/DD/YYYY'),
        projectName: projectFullName,
        coverageName: coverageName,
        attributeName: attributeName,
        conditionValue: conditionValue,
        attributeValue: attributeValue,
        edit: (!insuredArchived)  && this.renderEditWaiveLink(record),
        undo: (!insuredArchived) &&  this.renderUndoWaiveLink(record),
      };
    });

    const templatesTableData = {
      fields: TableMetadata.fields,
      header: TableMetadata.header,
      body: TableBody
    };

    let {
      totalAmountOfRecords,
      recordsPerPage,
      fetching,
    } = this.props.waivers;

    const paginationSettings = {
      total: totalAmountOfRecords,
      itemsPerPage: recordsPerPage,
      setPageHandler: this.setPageFilter,
      currentPageNumber: this.state.filter.pageNumber,
    };

    return (
      <div className="list-view admin-view-body finance-list">

        <Modal
          show={this.state.showModalWaived}
          onHide={this.closeWaivedDetail}
          className="add-item-modal noteEditorModal"
        >
          <Modal.Body>
            <ViewWaivedModal
              locale={this.props.locale.modal}
              handleSubmit={this.handleUpdateWaiveSubmit}
              waivedData={this.state.currentWaive}
              onHide={this.closeWaivedDetail}
            />
          </Modal.Body>
        </Modal>

        <div className="finance-list-header">
          <div>
            <a onClick={this.toggleFilterBox}
              className="nav-btn icon-login-door">
              Filter Waivers
            </a>
          </div>
        </div>

        {this.state.showFilterBox &&
          <section className="list-view-filters">
            <FilterWaivers
              onSubmit={this.submitFilterForm}
              insuredId={insuredId}
            />
          </section>
        }

        <PTable
          sorted={true}
          items={templatesTableData}
          wrapperState={this.state}
          tableOrderActive={this.state.tableOrderActive}
          clickOnColumnHeader={this.clickOnColumnHeader}
          isFetching={fetching}
          pagination={paginationSettings}
        />
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    waivers: state.waivers,
    locale: state.localization.strings.waivers,
    login: state.login,
    profile: state.login.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    deficienciesActions: bindActionCreators(deficienciesActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Waivers);
