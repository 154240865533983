import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';

import PTable from '../../../common/ptable';
import AddDocumentTypesModal from '../../modals/addDocumentTypesModal';
import FilterDocumentTypes from './filter';
import Utils from '../../../../lib/utils';
import RolAccess from '../../../common/rolAccess';

import * as commonActions from '../../../common/actions';
import * as actions from './actions';

import './DocumentTypes.css';

class DocumentTypes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {
        pageNumber: 1
      },
      filterBox: {
        documentTypeId: '',
        documentTypeName: '',
      },
      tableOrderActive: 'documentTypeId',
      order: {
        documentTypeId: 'asc',
        documentTypeName: 'desc',
      },
      currentDocumentType: null,
      showFilterBox: false,
    };
  }

  componentDidMount() {
    const { actions } = this.props;

    actions.fetchDocumentTypes({
      orderBy: 'documentTypeID',
      orderDirection:'ASC',
    });
  }

  clickOnColumnHeader = (e, field) => {
    if (field === 'edit') return;

    // get base query
    const pageNumber = this.state.filter.pageNumber;
    const orderDirection = this.state.order[field] === 'asc' ? 'DESC' : 'ASC';
    let preQuery = Utils.getFetchQuery(field, pageNumber, orderDirection);

    // add search filters
    preQuery = Utils.addSearchFiltersToQuery(preQuery, this.state.filterBox);

    // fetch using query
    this.props.actions.fetchDocumentTypes(preQuery);

    // save new active tab and order
    let newState = {
      tableOrderActive: field,
      order: {
        documentTypeId: field === 'documentTypeId' ? 'asc' : 'desc',
        documentTypeName: field === 'documentTypeName' ? 'asc' : 'desc',
      },
    };

    newState.order[field] = this.state.order[field] === 'asc' ? 'desc' : 'asc';
    this.setState(newState);
  }

  setPageFilter = (e, pageNumber, force) => {
    if(force || this.state.filter.pageNumber !== pageNumber) {

      // get base query
      const field = this.state.tableOrderActive;
      const orderDirection = this.state.order[field] === 'asc' ? 'ASC' : 'DESC';
      let preQuery = Utils.getFetchQuery(field, pageNumber, orderDirection);

      // add search filters
      preQuery = Utils.addSearchFiltersToQuery(preQuery, this.state.filterBox);

      // fetch using query
      this.props.actions.fetchDocumentTypes(preQuery);

      // save page number
      this.setState({
        filter: {
          pageNumber,
        }
      });
    }
  }

  submitFilterForm = (values)=> {
    // get base query
    const field = this.state.tableOrderActive;
    const pageNumber = 1;
    const orderDirection = this.state.order[field] === 'asc' ? 'ASC' : 'DESC'
    let preQuery = Utils.getFetchQuery(field, pageNumber, orderDirection);

    // add search filters
    const filterBox = {
      documentTypeName: values.documentTypeName || "",
    };
    preQuery = Utils.addSearchFiltersToQuery(preQuery, filterBox);

    // fetch using query
    this.props.actions.fetchDocumentTypes(preQuery);

    // save searchterm and pagenumber
    this.setState({
      filterBox,
      filter: {
        pageNumber: 1,
      }
    });
  }

  openEdit = (data) => {
    this.setState({ currentDocumentType: data }, this.openModal);
  }

  onDeleteDocumentType = (data) => {
    this.props.commonActions.setLoading(true);
    this.props.actions.deleteDocumentTypes({ documentTypeId: data.DocumentTypeID }, (success) => {
      this.props.commonActions.setLoading(false);

      if (success) {
        this.setPageFilter(null, 1, true);
      }
    });
  }

  openModal = () => {
    this.props.actions.setShowModal(true);
  }

  closeModalAndRefresh = (update) => {
    this.props.actions.setShowModal(false);
    if (update) this.setPageFilter(null, 1, true);
    this.setState({ currentDocumentType: null });
  }

  renderAddDocumentTypeLink() {
    const { addBtn } = this.props.local.strings.certFocusSettings.documentTypes.documentTypesList;
    let component = (
      <a onClick={this.openModal}
        className="nav-btn nav-bn icon-add"
      >
        {addBtn}
      </a>
    );
    return component;
  }

  renderEditDocumentTypeLink(documentType) {
    const { editDocumentType } = this.props.local.strings.certFocusSettings.documentTypes.documentTypesList;
    let component = (
      <a
        onClick={() => this.openEdit(documentType)}
        className="cell-table-link icon-edit"
      >
        {editDocumentType}
      </a>
    );
    return component;
  }

  renderDeleteDocumentTypeLink(documentType) {
    const { deleteDocumentType } = this.props.local.strings.certFocusSettings.documentTypes.documentTypesList;
    let component = (
      <a
        onClick={() => this.onDeleteDocumentType(documentType)}
        className="cell-table-link icon-delete"
      >
        {deleteDocumentType}
      </a>
    );
    return component;
  }

  render() {
    const {
      documentTypeIdColumn,
      documentTypeNameColumn,
      filterBtn,
    } = this.props.local.strings.certFocusSettings.documentTypes.documentTypesList;

    const TableMetadata = {
      fields: [
        'documentTypeId',
        'documentTypeName',
        'edit',
        'delete',
      ],
      header: {
        documentTypeId: documentTypeIdColumn,
        documentTypeName: documentTypeNameColumn,
        edit: '',
        delete: '',
      },
    };

    const TableBody = this.props.documentTypesSettings.list.map((documentType) => {
      const {
        DocumentTypeID,
        DocumentTypeName,
      } = documentType;
      
      const isFixed = (documentType.DocumentTypeID === 1) ? true : false;

      return {
        documentTypeId: DocumentTypeID,
        documentTypeName: DocumentTypeName,
        edit: (!isFixed) && (
          <RolAccess
            masterTab="settings"
            sectionTab="edit_document_type"
            component={() => this.renderEditDocumentTypeLink(documentType)}>
          </RolAccess>          
        ),
        delete: (!isFixed) && (
          <RolAccess
            masterTab="settings"
            sectionTab="delete_document_type"
            component={() => this.renderDeleteDocumentTypeLink(documentType)}>
          </RolAccess>          
        ),
      };
    });

    const templatesTableData = {
      fields: TableMetadata.fields,
      header: TableMetadata.header,
      body: TableBody
    };

    let {
      totalAmountOfDocumentTypes,
      documentTypesPerPage,
      fetching,
      showModal,
    } = this.props.documentTypesSettings;

    const paginationSettings = {
      total: totalAmountOfDocumentTypes,
      itemsPerPage: documentTypesPerPage,
      setPageHandler: this.setPageFilter,
      currentPageNumber: this.state.filter.pageNumber,
    };

    return (
      <div className="list-view admin-view-body coverage-types-settings-list">
        <Modal
          show={showModal}
          onHide={this.closeModalAndRefresh}
          className="add-item-modal add-entity-small"
        >
          <Modal.Body>
            <AddDocumentTypesModal
              selectedDocumentType={this.state.currentDocumentType}
              close={this.closeModalAndRefresh}
            />
          </Modal.Body>
        </Modal>

        <div className="coverage-types-list-header">
          <div>
            <a
              onClick={() => this.setState({ showFilterBox: !this.state.showFilterBox })}
              className="nav-btn icon-login-door"
            >
              {filterBtn}
            </a>
          </div>

          <div>
            <RolAccess
              masterTab="settings"
              sectionTab="add_document_type"
              component={() => this.renderAddDocumentTypeLink()}>
            </RolAccess>            
          </div>
        </div>

        {this.state.showFilterBox &&
          <section className="list-view-filters">
            <FilterDocumentTypes
              onSubmit={this.submitFilterForm}
            />
          </section>
        }

        <PTable
          sorted={true}
          items={templatesTableData}
          wrapperState={this.state}
          tableOrderActive={this.state.tableOrderActive}
          clickOnColumnHeader={this.clickOnColumnHeader}
          isFetching={fetching}
          pagination={paginationSettings}
        />
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    documentTypesSettings: state.documentTypesSettings,
    local: state.localization,
    login: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTypes);