import * as types from './types';
import Api from '../../../lib/api';

export const clearForm = () => {
  return (dispatch, getState) => {
    dispatch({type: types.CLEAR_LINK_FORM });
  }
}

export const getFormAndValuesFromSubmissionId = (submissionId) => {
  return (dispatch, getState) => {
    dispatch({type: types.SET_LINK_RETRIEVING});
    const loginState = getState().login;
    // get the saved form id using the link hash
    getSavedForm (submissionId, null, loginState, function(savedFormRes){
      const savedForm = savedFormRes.data.savedForms && savedFormRes.data.savedForms.length > 0? savedFormRes.data.savedForms[0]:null;
      if (savedFormRes.success && savedForm) {
        // an incomplete saved form was found, now get the form template
        getForm (savedForm.formId, loginState, function(formRes){
          const form = formRes.data.forms && formRes.data.forms.length > 0? formRes.data.forms[0]:null;
          if (formRes.success && form) {
            // a form was found, lets get the saved values if any
            getFormFieldValues(savedForm.id, loginState, function(valuesRes){
              let savedValues = [];
              if (valuesRes.success && valuesRes.data.totalCount > 0) {
                savedValues = valuesRes.data.savedValues;
              }
              dispatch({type: types.ASSIGN_LINK_SC, form, subcontractorId: savedForm.subcontractorID});
              dispatch({type: types.ASSIGN_LINK_FORM, form, savedFormId: savedForm.id, savedValues, savedFormStatus: savedForm.status, savedFormIsLocked: savedForm.isLocked});
            });

          } else {
            // no form was found with that id, maybe the form was deleted (very unlikely error)
            dispatch({type: types.SET_LINK_ERROR, error: 'Seems like the form is unavailable, check that the link is correct', redirect:true});
          }

        });

      } else {
        // no incomplete form was retrieved
        dispatch({type: types.SET_LINK_ERROR, error: 'Seems like the form is unavailable, check that the link is correct', redirect:true });
      }
    });
  }
}

export const getFormAndValuesFromLink = (linkHash) => {
  return (dispatch, getState) => {
    dispatch({type: types.SET_LINK_RETRIEVING});
    const loginState = getState().login;
    // get the saved form id using the link hash
    getSavedFormIdFromLink(linkHash, loginState, function(idRes){
      if (idRes.success) {
        // saved form id was retrieved, now get the full saved form
        // second parameter must be false when getting only incomplete forms
        getSavedForm (idRes.savedFormId, null, loginState, function(savedFormRes){
          const savedForm = savedFormRes.data.savedForms && savedFormRes.data.savedForms.length > 0? savedFormRes.data.savedForms[0]:null;
          if (savedFormRes.success && savedForm) {
            // an incomplete saved form was found, now get the form template
            getForm (savedForm.formId, loginState, function(formRes){
              const form = formRes.data.forms && formRes.data.forms.length > 0? formRes.data.forms[0]:null;
              if (formRes.success && form) {
                // a form was found, lets get the saved values if any
                getFormFieldValues(savedForm.id, loginState, function(valuesRes){
                  let savedValues = [];
                  if (valuesRes.success && valuesRes.data.totalCount > 0) {
                    savedValues = valuesRes.data.savedValues;
                  }
                  dispatch({type: types.ASSIGN_LINK_SC, form, subcontractorId: idRes.subcontractorId});
                  dispatch({type: types.ASSIGN_LINK_FORM, form, savedFormId: savedForm.id, savedValues, savedFormStatus: savedForm.status});
                });

              } else {
                // no form was found with that id, maybe the form was deleted (very unlikely error)
                dispatch({type: types.SET_LINK_ERROR, error: 'Seems like the form is unavailable, check that the link is correct', redirect:true});
              }

            });

          } else {
            // no incomplete form was retrieved
            dispatch({type: types.SET_LINK_ERROR, error: 'Seems like this form was already submited, or the link is invalid', redirect:true });
          }
        });
      } else {
        // the link didnt have a saved form
        dispatch({type: types.SET_LINK_ERROR, error: 'The link is invalid', redirect:true });
      }

    });
  }
}

const getSavedFormIdFromLink = (linkHash, loginState, callback) => {
  //callback({success: true, savedFormId: linkHash});
  const token = loginState.authToken;
  Api.get(
    `subcontractors/submission?submissionCode=${linkHash}`,
    token
  ).then((data) => {
    callback(data.data);
  })
}

const getSavedForm = (savedFormId, isComplete, loginState, callback) => {
    const token = loginState.authToken;
    const queryParams = {
      savedFormId
    };
    if (isComplete !== null){
      queryParams.isComplete = isComplete;
    }
    const urlParameters = `?${Object.keys(queryParams).map(i => `${i}=${queryParams[i]}`).join('&')}`;

    Api.get(
      `forms/savedforms${urlParameters}`,
      token
    ).then((data) => {
      callback(data.data);
    })
}

const getForm = (formId, loginState, callback) => {
  const token = loginState.authToken;

  Api.get(
    `forms?formId=${formId}&justFormData=false`,
    token
  ).then((data) => {
    callback(data.data);
  })
}



const getFormFieldValues = (savedFormId, loginState, callback) => {
  const token = loginState.authToken;
  Api.get(
    `forms/savedformfieldsvalues?savedFormId=${savedFormId}`,
    token
  ).then((data) => {
    callback(data.data);
  })
}

/* SAVE FORM VALUES */
export const saveFormValues = (savedFormId, savedValues, isComplete, subcontractorId,  relatedFiles) => {
  return (dispatch, getState) => {
    dispatch({type: types.SET_LINK_LOADING});
    const loginState = getState().login;

    putFieldValuesRequest(savedFormId, savedValues, loginState, isComplete, subcontractorId, function(valuesRes){
      if (valuesRes.success && valuesRes.data.valuesSaved) {
        if(isComplete) {
          // all values were successfully saved, lets submit the saved form
          markSavedFormAsComplete(savedFormId, null, loginState, function(completeRes){
            if (completeRes.success && completeRes.data.formUpdated) {
              dispatch({
                type: types.VALUES_SUCCESSFULLY_SAVED,
                successMsg: "The form was successfully submitted. Your prequalification will be reviewed and completed shortly and @HiringClient@ will be notified. No further action is required at this time.",
                savedValues,
                redirect:true
              });

            } else {
              dispatch({ type: types.SET_LINK_ERROR, error: "There was an error submitting the form", redirect:false });
            }
          });
        } else {
          // values saved but the form is not submitted
          dispatch({
            type: types.VALUES_SUCCESSFULLY_SAVED,
            successMsg: "The form was successfully saved",
            savedValues,
            redirect:false
          });
        }
      } else {
        // Delete files associated
          try {
            Api.delete(`files`, { filesIds: relatedFiles }, loginState.authToken);
          } catch(e) {
            console.error(e);
          }
        dispatch({ type: types.SET_LINK_ERROR, error: "There was an error saving the values", redirect:false });
      }
    });

  };
}

const putFieldValuesRequest = (savedFormId, savedValues, loginState, isComplete, subcontractorId, callback) => {
  const token = loginState.authToken;
  const body = {
    savedFormId,
    savedValues,
    isComplete,
    subcontractorId
  };

  Api.put(
    `forms/savedformfieldsvalues`,
    body,
    token
  ).then((data)=> {
    callback(data.data);
  });

}

export const markSavedFormAsComplete = (savedFormId, externalSubmissionId, loginState, formioSubmissionData, callback) => {
  const token = loginState.authToken;
  const body = {
    externalSubmissionId,
    savedFormId,
    isComplete: "true",
    formioSubmissionData: formioSubmissionData
  };

  Api.put(
    `forms/savedforms`,
    body,
    token
  ).then((data)=> {
    callback(data.data);
  });
}

export const addSubcontractorFormsLog = ({ 
  savedFormId, subcontractorId, eventType, logType, callback = () => {}
}) => {
  return (dispatch, getState) => {
    const token = getState().login.authToken;
    const body = {
      savedFormId,
      subcontractorId,
      eventType: eventType,
      logType: logType
    };
    Api.post(
      `log/subcontractors`,
      body,
      token
    ).then(response => {
      const {success, data} = response.data;
      if(success && data) {
        callback(data, null);
      } else {
        callback(null, null);
      }
    })
    .catch(error => {
      callback(null, error);
    });
  };
};

export const getSubcontractorTaxId = ({ subcontractorId, callback = () => {} }) => {
  return (dispatch, getState) => {
    const token = getState().login.authToken;
    Api.get(
      `subcontractors/${subcontractorId}/tax_id`,
      token
    ).then(response => {
      const {success, data} = response.data;
      if(success && data) {
        callback(data, null);
      } else {
        callback(null, null);
      }
    })
    .catch(error => {
      callback(null, error);
    });
  };
};

export const saveForm = (formId, userId, subContractorId, hiringClientId, callback) => {
  return (dispatch, getState) => {
    const token = getState().login.authToken;
    const body = {
      formId,
      userId,
      subContractorId,
      hiringClientId
    };
    Api.post(
      `forms/savedforms`,
      body,
      token
    ).then(response => {
      const {success, data} = response.data;
      if(success && data) {
        callback(data, null);
      } else {
        callback(null, null);
      }
    })
    .catch(error => {
      callback(null, error);
    });
  };
}

export const setMustPayFlag = (hcId, scId, mustPay, callback) => {
  return (dispatch, getState) => {
    const token = getState().login.authToken;
    const body = {
      hcId,
      scId,
      mustPay: mustPay ? 1 : 0
    };
    Api.post(
      `forms/setMustPayFlag`,
      body,
      token
    ).then(response => {
      const {data} = response;
      if(data.success && data.data) {
        callback(true, data.data);
      } else {
        callback(false, null);
      }
    })
    .catch(error => {
      callback(false, error);
    });
  };
}

export const getLastIncompletedSavedFormId = (hcId, scId, callback) => {
  return (dispatch, getState) => {
    const token = getState().login.authToken;
    Api.get(
      `forms/getLastIncompletedSavedFormId?hcId=${hcId}&scId=${scId}`,
      token
    ).then(response => {
      const {data} = response;
      if(data.success && data.data) {
        callback(true, data.data);
      } else {
        callback(false, null);
      }
    })
    .catch(error => {
      callback(false, error);
    });
  };
}

export const getSavedFormForFinInfoTab = (savedFormId, callback) => {
  return (dispatch, getState) => {
    const token = getState().login.authToken;
    Api.get(
      `forms/savedforms?savedFormId=${savedFormId}`,
      token
    ).then((data) => {
      callback(data.data);
    })
    .catch(error => {
      callback(false, error)
    })
  }
}


export const getSavedFormioFormFinInfoTab = (formId, callback) => {
  return (dispatch, getState) => {
    const token = getState().login.authToken;
    Api.get(
      `forms/savedforms/formioform?formId=${formId}`,
      token
    ).then((data) => {
      callback(data.data);
    })
    .catch(error => {
      console.log('Error: ' + error);
      callback(false, error)
    })
  }
}

export const getSavedFormsByFormioSubmissionId = (formioSubmissionId, callback) => {
  return (dispatch, getState) => {
    const token = getState().login.authToken;
    Api.get(
      `forms/savedforms?formioSubmissionId=${formioSubmissionId}`,
      token
    ).then((data) => {
      callback(data.data);
    }
    ).catch(error => {
      console.log('Error: ' + error);
      callback(false, error)
    });
  };
};


export const updateSavedFormStatus = (savedFormId, isComplete, callback, { formioSubmissionId } = {}, dataObject = {}) => {
  const data = dataObject ? dataObject.formioSubmissionData : null;
  return (dispatch, getState) => {
    const token = getState().login.authToken;
    const body = {
      savedFormId,
      isComplete,
      externalSubmissionId: formioSubmissionId,
      formioSubmissionData: data
    };
    Api.put(
      `forms/savedforms`,
      body,
      token
    ).then(response => {
      const {success, data} = response.data;
      if(success && data) {
        callback(data, null);
      } else {
        callback(null, null);
      }
    })
    .catch(error => {
      callback(null, error);
    });
  };
}

export const saveFormSubmissionLogs = (savedFormId , formioSubmissionData, callback, logError) => {
  return (dispatch, getState) => {
    const token = getState().login.authToken;
    const body = {
      savedFormId,
      formioSubmissionData,
      logError
    };
    Api.post(
      `log/formsubmissions`,
      body,
      token
    ).then(response => {
      const {success, data} = response.data;
      if(success && data) {
        callback(data, null);
      } else {
        callback(null, null);
      }
    })
    .catch(error => {
      callback(null, error);
    });
  };
}
