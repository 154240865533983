import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import _ from 'lodash';

import {clearCache} from './companyInfoTab/form/asyncValidate';
import CompanyInfoTab from './companyInfoTab';
import ContactTab from './contactTab';
import * as hcActions from '../actions';

export const AddHCModal = (props) => {
  const [step, setStep] = useState(1);

  const tabProps = {
    onClose: props.onClose, 
    profile: props.profile,
    continueHandler: () => {
      if (props.profile) {
        props.actions.updateHiringClient(props.onClose);
      } else {
        if (step === 1) {
          setStep(2);
        } else {
          props.actions.createHiringClient(props.onClose);
        }
      }
    },
  };

  useEffect(() => {
    clearCache();
    props.actions.setHCListError('');
  }, [0]);

  const getIconClassName = (step, index) => {
    return `step-icon-bubble ${step === index ? 'active' : ''}`;
  }

  return (
    <div className="newhc-form wiz-wrapper" data-testid="modal-new-hc">
      <header>
        <h2 className="modal-wiz-title" data-testid="modal-new-hc-title">
          {_.get(props, 'local.strings.hiringClients.addHCModal.title', '-')}
        </h2>
        <ul className="step-icons">
          <li>
            <span className={getIconClassName(step, 1)}>
              <span 
                className={`step-icon icon-company`} 
                data-testid="modal-company-icon"
              />
            </span>
          </li>
          {!props.profile && (
            <li>
              <span className={getIconClassName(step, 2)}>
                <span 
                  className={`step-icon icon-personal_info`}
                  data-testid="modal-contact-icon"
                />
              </span>
            </li>
          )}
        </ul>
      </header>
      <div className="steps-bodies add-item-view">
        <div className={
          `step-body add-item-form-subsection step-${step} active`}>
          {step === 1 && <CompanyInfoTab {...tabProps}/>}
          {step === 2 && <ContactTab {...tabProps}/>}
        </div>
      </div>
    </div>
  );
};

AddHCModal.propTypes = {
  local: PropTypes.object.isRequired,
  profile: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  actions: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.shape({
      updateHiringClient: PropTypes.func.isRequired,
      createHiringClient: PropTypes.func.isRequired
    }),
  ]),
};

AddHCModal.defaultProps = {
  local: {},
  hc: {},
  profile: null,
  onClose: () => {},
  actions: {
    updateHiringClient: () => {},
    createHiringClient: () => {},
  },
};

const mapStateToProps = (state, ownProps) => ({
  local: state.localization,
  hc: state.hc,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(hcActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddHCModal);