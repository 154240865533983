import React from 'react';
import { Errors } from '@formio/react';
import * as formsActions from '../../../../actions';
import { Confirm } from '../../../../common';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FormNavbar from '../../form/components/FormNavbar';
import SubmissionNavbar from './SubmissionNavbar';

class SubmissionDelete extends React.Component {
  componentDidMount() {
    this.props.actions.getForm(this.props.match.params.formId)
    this.props.actions.getSubmission(this.props.match.params.submissionId, this.props.match.params.formId)
  }


  render() {
    const formId = this.props.match.params.formId;
    const submissionId = this.props.match.params.submissionId;
    const eventId = this.props.match.params.eventId;
    const formName = '';

    const onYes = () => {
      this.props.actions.deleteSubmission(submissionId || eventId, formId, formName, (err) => {
        if (!err) {
          this.props.history.push(`/${formId ? `formio/forms/${formId}/submission` : `${formName}`}`);
        }
      });
    };
    const onNo = () => {
      this.props.history.goBack();
    };

    const message = `Are you sure you wish to delete the submission '${submissionId || eventId}'?`;

    return (
      <div className="container">
        <FormNavbar formId={this.props.formioForms.form._id} />
        <SubmissionNavbar formId={formId} submissionId={submissionId} />
        <Errors errors={[this.props.formioSubmission.error, this.props.formioForms.error]} />
        <Confirm
          message={message}
          onYes={onYes}
          onNo={onNo}
        />
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    formioForms: state.formioForms,
    formioSubmission: state.formioSubmission,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(formsActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionDelete);