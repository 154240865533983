export const SET_HC_PROFILE_ERROR = 'SET_HC_PROFILE_ERROR';
export const SET_HC_PROFILE = 'SET_HC_PROFILE';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const SET_FETCHING_LANGUAGES = 'SET_FETCHING_LANGUAGES';
export const SET_LANGUAGES_LIST = 'SET_LANGUAGES_LIST';
export const SET_LANGUAGES_ERROR = 'SET_LANGUAGES_ERROR';
export const EDIT_LANGUAGES = 'EDIT_LANGUAGES';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const SET_SC_LIST_FOR_SELECT_COMPONENT = 'SET_SC_LIST_FOR_SELECT_COMPONENT';
export const SET_LOADING = 'SET_LOADING';
