export const SET_COVERAGE_SETTINGS_TYPES_ERROR = 'SET_COVERAGE_SETTINGS_TYPES_ERROR';
export const SET_COVERAGE_SETTINGS_TYPES_LIST = 'SET_COVERAGE_SETTINGS_TYPES_LIST';
export const SET_TOTAL_AMOUNT_OF_COVERAGE_SETTINGS_TYPES = 'SET_TOTAL_AMOUNT_OF_COVERAGE_SETTINGS_TYPES';
export const SET_FETCHING_COVERAGE_SETTINGS_TYPES = 'SET_FETCHING_COVERAGE_SETTINGS_TYPES';
export const SET_COVERAGE_SETTINGS_TYPES_SHOW_MODAL = 'SET_COVERAGE_SETTINGS_TYPES_SHOW_MODAL';
export const SET_COVERAGE_SETTINGS_TYPES_ADD_ERROR = 'SET_COVERAGE_SETTINGS_TYPES_ADD_ERROR';
export const SET_COVERAGE_SETTINGS_TYPES_ADD_SUCCESS = 'SET_COVERAGE_SETTINGS_TYPES_ADD_SUCCESS';
export const SET_COVERAGE_SETTINGS_TYPES_ADD_FETCHING = 'SET_COVERAGE_SETTINGS_TYPES_ADD_FETCHING';

export const SET_ATTRIBUTES_LIST_ERROR = 'SET_ATTRIBUTES_LIST_ERROR';
export const SET_ATTRIBUTES_LIST = 'SET_ATTRIBUTES_LIST';
export const SET_FETCHING_ATTRIBUTES = 'SET_FETCHING_ATTRIBUTES';
export const SET_SHOW_ATTRIBUTES_MODAL = 'SET_SHOW_ATTRIBUTES_MODAL';
export const SET_SHOW_ADD_ATTRIBUTES_MODAL = 'SET_SHOW_ADD_ATTRIBUTES_MODAL';