import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as refreshTokenAction from './actions'


const RefreshToken = (props) => {
    useEffect(() => {
      if (props.login.isLoggedIn) {
        props.actions.refreshToken();
        props.actions.initRefreshTokenInterval(props.actions.refreshToken);
      } 
      return () => props.actions.clearRefreshTokenInterval();
    }, []);
    return <></>
}

const mapStateToProps = function(state) {
    return {
      login: state.login,
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(refreshTokenAction, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(RefreshToken)