import * as regex from '../../../../lib/regex';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';

const validate = (values, props) => {
  const {
    requiredValidation,
    wrongTaxIdFormat,
    zipcodeErrorMessage,
    fieldLengthValidation,
    phoneErrorMessage,
    wrongEmailFormat,
    postalCodeErrorMessageCanada,
    postalCodeErrorMessage,
  } = props.local.strings.register.companyProfile;
  const {
    passwordBoth
  } = props.local.strings.register.userInformation;

  const errors = {};

  if (isEmpty(get(values, 'firstName', '').trim())) {
    errors.firstName = requiredValidation;
  }

  if (isEmpty(get(values, 'lastName', '').trim())) {
    errors.lastName = requiredValidation;
  }

  if (!values.companyName || !values.companyName.trim()) {
    errors.companyName = requiredValidation;
  } else if (values.companyName.length > 100) {
    errors.companyName = fieldLengthValidation;
  }

  if (isEmpty(get(values, 'password', '').trim())) {
    errors.password = requiredValidation;
  }

  if (isEmpty(get(values, 'passwordAgain', '').trim())) {
    errors.passwordAgain = requiredValidation;
  }

  if (values.password !== values.passwordAgain) {
    errors.password = passwordBoth;
    errors.passwordAgain = passwordBoth;
  }

  const title = get(values, 'title.value', null);
  if (!title) {
    errors.title = requiredValidation;
  }

  if (!get(values, 'targetHiringClient.value')) {
    errors.targetHiringClient = requiredValidation;
  }

  if (isEmpty(get(values, 'phone', '').trim())) {
    errors.phone = requiredValidation;
  } else if (!(regex.PHONE_INTERNATIONAL.test(values.phone) || 
    regex.PHONE.test(values.phone))) {
    errors.phone = phoneErrorMessage;
  }

  const primaryTrade = get(values, 'primaryTrade.value', null);
  if (!primaryTrade || primaryTrade === 19678 || primaryTrade <= 0) {
    errors.primaryTrade = requiredValidation;
  }

  const country = get(values, 'country.value', null);
  if (!country) {
    errors.country = requiredValidation;
  }

  const state = isObject(values.state)
    ? get(values, 'state.label') 
    : values.state || '';

  if (!state || !state.trim()) {
    errors.state = requiredValidation;
  } else if (state.length > 100) {
    errors.state = fieldLengthValidation;
  }

  if (!values.city || !values.city.trim()) {
    errors.city = requiredValidation;
  } else if (values.city.length > 150) {
    errors.city = fieldLengthValidation;
  }

  if (!values.address || !values.address.trim()) {
    errors.address = requiredValidation;
  } else if (values.address.length > 150) {
    errors.address = fieldLengthValidation;
  }

  // Zip code validations 
  if (!values.zipCode || !values.zipCode.trim()) {
    errors.zipCode = requiredValidation;
  } else if (values.zipCode.length > 10) {
    errors.zipCode = fieldLengthValidation;
  } else if (country && country === 1) {
    // Validate USA Zipcode
    if (!regex.POSTAL_CODE.test(values.zipCode)) {
      errors.zipCode = postalCodeErrorMessage;
    }
  } else if (country && country === 34) {
    // Validate Canadian Zipcode
    if (!regex.CANADIAN_POSTAL_CODE.test(values.zipCode)) {
      errors.zipCode = postalCodeErrorMessageCanada;
    }
  }

  if (!values.taxId || !values.taxId.trim()) {
    errors.taxId = requiredValidation;
  } else if (values.taxId.length > 16) {
    errors.taxId = fieldLengthValidation;
  }
  else if(country && country === 34){
// If country is Canada validate the tax id format
    if(values.taxId && !regex.CANADIAN_TAX_FORMAT.test(values.taxId)){
      errors.taxId = wrongTaxIdFormat;
    }
  }
   else if (country && country === 1) {
    // If country is USA validate the tax id format
    if (!regex.TAX_ID.test(values.taxId)) {
      errors.taxId = wrongTaxIdFormat;
    }
  } else if (country && country !== 1) {
    // For any other country, just check that this is a number
    if (Number.isNaN(Number(values.taxId))) {
      errors.taxId = wrongTaxIdFormat;
    }
  }

  if (isEmpty(get(values, 'email', '').trim())) {
    errors.email = requiredValidation;
  } else if (!regex.EMAIL.test(values.email) || values.email.length > 80) {
    errors.email = wrongEmailFormat;
  }

  if (values.hcContactEmail && !values.hcContactEmail.match(regex.EMAIL)) {
    errors.hcContactEmail = wrongEmailFormat;
  }

  if (!values.agree) {
    errors.agree = requiredValidation;
  }

  return errors;
};

export default validate;