import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _ from 'lodash';

import * as hcActions from '../../actions';
import CompanyInfoForm from './form';
import {bindActionCreators} from 'redux';

export const CompanyInfoTab = (props) => {
  useEffect(() => {
    // Side effects: this will restore the state on the reducer
    // to avoid showing the button as disabled after a submit was
    // previously performed.
    props.actions.setIsSubmittedHC(false);
    props.actions.setIsSubmittingHC(false);
  }, []);

  return (
    <section 
      className="wiz-step white-section company-info-tab" 
      data-testid="tab-company"
    >
      <div className="admin-form-field-wrapper">
        <h2 className="step-title" data-testid="tab-company-title">
          {props.local.title}
        </h2>
        <CompanyInfoForm
          profile={props.profile}
          onClose={props.onClose}
          onSubmit={({companyLogo, ...values}) => {
            const entityType = _.get(values, 'entityType.value', 0);
            const parentHC = _.get(values, 'parentHC.value', null);
            const payload = {
              companyInfo: {
                ...values,
                entityType,
                parentHC: entityType === 1 ? parentHC : null,
                allowApplications: values.allowApplications ? 1 : 0,
                autoApproveApplications: values.autoApproveApplications ? 1 : 0,
              },
              companyLogo
            };

            if (_.get(props, 'profile.id', null)) {
              payload.id = props.profile.id;
            }

            props.addSerializedHcData(payload);
            props.continueHandler();
          }}
        />
        <div className="save-hc-modal-error" data-testid="tab-company-error">
          {props.hc.errorHC}
        </div>
      </div>
    </section>
  );
};

CompanyInfoTab.propTypes = {
  local: PropTypes.object.isRequired,
  profile: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  addSerializedHcData: PropTypes.func.isRequired,
  continueHandler: PropTypes.func.isRequired,
  hc: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.shape({
      errorHC: PropTypes.string.isRequired
    })
  ]).isRequired,
  actions: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.shape({
      setIsSubmittedHC: PropTypes.func.isRequired,
      setIsSubmittingHC: PropTypes.func.isRequired
    })
  ]).isRequired
};

CompanyInfoTab.defaultProps = {
  local: {},
  hc: {errorHC: null},
  profile: null,
  onClose: () => {},
  addSerializedHcData: () => {},
  continueHandler: () => {},
  actions: {
    setIsSubmittedHC: () => {},
    setIsSubmittingHC: () => {}
  }
};

const mapStateToProps = (state, ownProps) => ({
  local: state.localization.strings.hiringClients.addHCModal.companyInfoTab,
  hc: state.hc
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(hcActions, dispatch),
  addSerializedHcData: (serializedHcObj) => {
    dispatch(hcActions.addSerializedHcData(serializedHcObj));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfoTab);