import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import renderField from '../../../../customInputs/renderField';
import renderTypeAhead from '../../../../customInputs/renderTypeAhead';
import renderRemovable from '../../../../customInputs/renderRemovable';

import * as commonActions from '../../../../common/actions';
import validate from './validation';

class DocumentTypesForm extends React.Component {
  constructor(props) {
    super(props);

    const { selectedDocumentType } = this.props;
    if (selectedDocumentType) {
      props.dispatch(change('DocumentTypesForm', 'documentTypeName', selectedDocumentType.DocumentTypeName));
    }
  }

  renderFormField = (element, idx) => {
    const {
      name, label, ph, type, conditional, show
    } = element;
    const fieldType = type || 'text';
    const style = {};
    if (conditional && !show) {
      style.display = 'none';
    }

    if (fieldType === 'typeAhead') {
      const { fetching, results, error, handleSearch, onSelect } = element;

      return (
        <div key={idx} className="wiz-field admin-form-field-wrapper" style={style}>
          <label htmlFor={name}>{`${label}:`}</label>
          <Field
            resetOnClick
            name={name}
            placeholder={ph}
            fetching={fetching}
            results={results}
            handleSearch={handleSearch}
            fetchError={error}
            component={renderTypeAhead}
            onSelect={onSelect}
          />
        </div>
      );
    } else if (fieldType === 'removable') {
      const { valueText, disabled, onRemove } = element;
      return (
        <div key={idx} className="wiz-field admin-form-field-wrapper" style={style}>
          <label htmlFor={name}>{`${label}:`}</label>
          <Field
            name={name}
            valueText={valueText}
            component={renderRemovable}
            onRemove={onRemove}
            disabled={disabled}
          />
        </div>
      );
    }

    return (
      <div key={idx} className="admin-form-field-wrapper" style={style}>
        <label htmlFor={name}>{`${label}:`}</label>
          <Field
            name={name}
            type={type || "text"}
            placeholder={ph}
            component={renderField}
          />
      </div>
    );
  }  

  render() {
    const { handleSubmit } = this.props;
    const {
      documentTypeNameLabel,
      cancelButton,
      saveButton,
    } = this.props.local.strings.certFocusSettings.documentTypes.addModal;

    const fields = [
      { name: 'documentTypeName', label: documentTypeNameLabel, ph: '-- Document Type Name --' },
    ];

    return (
      <form
        autoComplete="off"
        className="entity-info-form"
        onSubmit={handleSubmit}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {fields.map(this.renderFormField)}
            </div>
          </div>
        </div>

        {this.props.customTerminology.addDocumentTypesError &&
          <div className="error-item-form">
            {this.props.customTerminology.addDocumentTypesError}
          </div>
        }

        {this.props.customTerminology.addDocumentTypesFetching ? (
          <div className="spinner-wrapper">
            <div className="spinner" />
          </div>
        ) : (
          <div className="add-item-bn">
            <button
              className="bn bn-small bg-green-dark-gradient create-item-bn icon-save"
              type="submit"
            >
              {saveButton}
            </button>
            <a
              className="cancel-add-item"
              onClick={this.props.close} >
              {cancelButton}
            </a>
          </div>
        )}

      </form>
    );
  }
};

DocumentTypesForm = reduxForm({
  form: 'DocumentTypesForm',
  validate,
})(DocumentTypesForm);

const mapStateToProps = (state) => {
  return {
    currentForm: state.form,
    local: state.localization,
    common: state.common,
    customTerminology: state.customTerminologySettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTypesForm);
