import React, { Component } from 'react';
import { connect } from 'react-redux';
import Utils from '../../../lib/utils';

import RolAccess from "../../common/rolAccess";
class ProjectInfo extends Component {
  state = {
    showFullDescription: false,
  };

  toggleShowFullDescription = () => {
    this.setState({ showFullDescription: !this.state.showFullDescription });
  }

  MAX_DESCRIPTION_LENGTH = 400;
  renderButtonEditProject() {
    const { onEditProject } = this.props;
    const component = (
      <button onClick={onEditProject} className="header-primary-button">
        {this.props.local.strings.certFocusProjects.projectView.editBtn}
      </button>
    );
    return component;
  }

  render() {
    const {
      setToMyList,
      renderCustomFields,
      projectDetails,
      login,
    } = this.props;

    if (projectDetails.fetching || !login.profile.Id) {
      return (
        <div className="spinner-wrapper">
          <div className="spinner mb-4" />
        </div>
      );
    }

    if (projectDetails.errorProject) {
      return (
        <div className="d-flex justify-content-center">
          {projectDetails.errorProject}
        </div>
      );
    }

    // lables in the form

    let {
      addToMyListBtn,
      removeFromMyListBtn,
      holderLabel,
      addressLabel,
      requireSetLabel,
      contactNameLabel,
      contactPhoneLabel,
      projectNoteLabel,
      projectNumberLabel,
      projectEmailLabel,
      projectDescriptionLabel,
      archivedLabel,
      projectName2Label,
      emailLabel,
      accountManagerLabel,
      projectAIWordingLabel
    } = this.props.local.strings.certFocusProjects.projectView;

// values in the form
    const {
      CFContactName,
      CFContactPhone,
      address1,
      address2,
      city,
      description,
      favorite,
      holderName,
      id,
      number,
      StateName,
      zipCode,
      CFNote,
      CountryName,
      projectCustomFields,
      archived,
      CFProjectName2,
      projectFullName,
      reqSets,
      CFProjectEmail,
      manager,
      accountManagers,
      AIWording
    } = this.props.projectDetails.projectData;
    const accountManagerObj =
      manager && accountManagers && manager !== null && accountManagers !== null
        ? JSON.parse(accountManagers).find((am) => am.value === JSON.parse(manager))
        : undefined;
    const accountManager = accountManagerObj ? accountManagerObj.label : '';
    return (
      <div>
        <div className="section-header my-3">
          <h2 className="profile-view-header-title" style={{ display: 'inline' }}>{projectFullName}</h2>
          {(archived !== 0) && (
            <div className="alert alert-danger" style={{ float: 'right', border: '1px solid #721c24' }}>
              <span><i className="linear-icon-warning" /></span> ARCHIVED
            </div>
          )}
          
          <div className="d-flex">
            <div className="mr-3">
            {(!archived) && (
              <RolAccess
                masterTab="projects"
                sectionTab="edit_project"
                component={(e) => this.renderButtonEditProject()}>
              </RolAccess>
            )}         
            </div>

            {this.props.projectDetails.favoriteFetching ?
              <div className="spinner-wrapper mt-0 mx-5">
                <div className="spinner" />
              </div> : (favorite ?
                <div>
                  <button onClick={() => setToMyList(false, id)} className="header-primary-button">{removeFromMyListBtn}</button>
                </div> :
                <div>
                  <button onClick={() => setToMyList(true, id)} className="header-primary-button">{addToMyListBtn}</button>
                </div>
              )
            }
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-sm mt-3">
            <table className="table table-hover profile-view-info-table">
              <tbody>
                <tr>
                  <td>{archivedLabel}:</td>                                
                  <td>
                    <span className={`${(!archived) ? 'unarchived-text' : 'archived-text'}`}>
                      {(!archived) ? 'Unarchived' : 'Archived'}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>{projectName2Label}:</td>
                  <td>{CFProjectName2}</td>
                </tr>
                <tr>
                  <td>{accountManagerLabel}:</td>
                  <td>{accountManager}</td>
                </tr>
                <tr>
                  <td>{holderLabel}:</td>
                  <td>{holderName}</td>
                </tr>
                <tr>
                  <td>{addressLabel}:</td>
                  <td>
                    {address1 || <span>&nbsp;</span>}<br/>
                    {address2 || <span>&nbsp;</span>}<br/>
                    {(city || StateName || zipCode) ? <span>{city}, {StateName}, {zipCode}</span> : <span>&nbsp;</span>}<br/>
                    {CountryName || <span>&nbsp;</span>}<br/>
                  </td>
                </tr>
                <tr>
                  <td>{requireSetLabel}:</td>
                  <td>
                    {reqSets && (JSON.parse(reqSets)).map((item, index) =>{
                      return (JSON.parse(reqSets)).length-1 == index? item.label:item.label + ", ";
                    })}
                  </td>
                </tr>
                <tr>
                  <td>{projectNoteLabel}:</td>
                  <td>{CFNote}</td>
                </tr>
                <tr>
                  <td>{projectAIWordingLabel}:</td>
                  <td>{AIWording}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-sm mt-3">
            <table className="table table-hover profile-view-info-table">
              <tbody>
                <tr>
                  <td>{contactNameLabel}:</td>
                  <td>{CFContactName}</td>
                </tr>
                <tr>
                  <td>{contactPhoneLabel}:</td>
                  <td>{Utils.formatPhoneNumber(CFContactPhone)}</td>
                </tr>

                <tr>
                  <td>{emailLabel}:</td>
                  <td>{CFProjectEmail}</td>
                </tr>
                
                <tr>
                  <td>{projectNumberLabel}:</td>
                  <td>{number}</td>
                </tr>

                { projectCustomFields && projectCustomFields.map(renderCustomFields) }
                <tr>
                  <td>{projectDescriptionLabel}:</td>
                  <td style={{wordBreak: "break-all"}}>
                    {description && description.length && description.length > this.MAX_DESCRIPTION_LENGTH
                        ? `${description.substring(0, this.MAX_DESCRIPTION_LENGTH)}...`
                        : description}
                    {description && description.length && description.length > this.MAX_DESCRIPTION_LENGTH && (
                        <button className="header-primary-button" onClick={this.toggleShowFullDescription}>Show More</button>
                    )}
                    {this.state.showFullDescription && (
                        <div className="modal project-description-modal">
                          <div className="modal-dialog project-description-modal-dialog">
                            <div className="modal-content project-description-modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title">{projectDescriptionLabel}</h5>
                                <button type="button" className="close" onClick={this.toggleShowFullDescription}>
                                  <span>&times;</span>
                                </button>
                              </div>
                              <div className="modal-body project-description-modal-body">
                                <p>{description}</p>
                              </div>
                              <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={this.toggleShowFullDescription}>
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    local: state.localization,
    login: state.login,
  }
};

export default connect(mapStateToProps)(ProjectInfo);
