import Keycloak from 'keycloak-js';

const keycloakConfig = {
  realm: process.env.REACT_APP_KEYCLOAK_REALM_NAME,
  url: process.env.REACT_APP_KEYCLOAK_URL,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  'ssl-required': 'external',
  'public-client': true
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
