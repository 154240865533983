import React from 'react'

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import RolAccess from '../../common/rolAccess';
import { CERTFOCUS_ANALYTICS_PATH } from '../../../lib/pathConstants';
import { CF_ADMIN_ROLE_ID } from '../../../lib/appConstants';

 class SubNavigationBar extends React.Component{

  renderDashboardIcon = () => {
    const { dashboardTitle } = this.props.localization.strings.common.sidebar.headerIcons;
    return <Nav.Link as={Link} to="/dashboard">{dashboardTitle}</Nav.Link>
  }

  renderSettingsIcon = () => {
    const {settingsTitle} = this.props.localization.strings.common.sidebar.headerIcons;
    return <Nav.Link as={Link} to="/certfocus/settings">{settingsTitle}</Nav.Link> 
  }

  renderInsuredIcon = () => {
    const {insuredsTitle} = this.props.localization.strings.common.sidebar.headerIcons;
    return <Nav.Link as={Link} to="/certfocus/insureds">{insuredsTitle}</Nav.Link> 
  }

  renderDocumentsIcon = () => {
    const {
      documentsTitle,
    } = this.props.localization.strings.common.sidebar.headerIcons;
    return <Nav.Link as={Link} to="/certfocus/documents">{documentsTitle}</Nav.Link> 
  }

  renderBellIcon = () => {
    const { tasksTitle } = this.props.localization.strings.common.sidebar.headerIcons;
    return <Nav.Link as={Link} to="/tasks">{tasksTitle}</Nav.Link>
  }

  renderEmail = () => {
    const {mailTitle} = this.props.localization.strings.common.sidebar.headerIcons;
    return <Nav.Link as={Link} to="/mail">{mailTitle}</Nav.Link>
  }

  renderAgenciesIcon = () => {
    const { agenciesTitle } = this.props.localization.strings.common.sidebar.headerIcons;
    const { isCertFocusUser } = this.props;
    if (isCertFocusUser) return <Nav.Link as={Link} to="/certfocus/agencies">{agenciesTitle}</Nav.Link>
      return null
  }

  renderUsersIcon = () => {
    const {usersTitle} = this.props.localization.strings.common.sidebar.headerIcons;
    return <Nav.Link as={Link} to="/admin/users/">{usersTitle}</Nav.Link>
  }

  renderHiringClientsIcon = () => {
    const { holdersTitle } = this.props.localization.strings.common.sidebar.headerIcons;
    return <Nav.Link as={Link} to="/hiringclients">{holdersTitle}</Nav.Link>
  }

  renderAnalyticsIcon = () => {
    const { analyticsTitle } = this.props.localization.strings.common.sidebar.headerIcons;
    const { currentUserCFRoleId, login } = this.props;

    if (currentUserCFRoleId === CF_ADMIN_ROLE_ID && login.profile.BIAccess) {
      return  <Nav.Link  onClick={(e) => { this.props.openYurbi(login.authToken, login.profile.BIAccess) }} >{analyticsTitle}</Nav.Link>
      
    }
    else if(login.profile.BIAccess && currentUserCFRoleId) {
      return <Nav.Link as={Link} to={CERTFOCUS_ANALYTICS_PATH}>{analyticsTitle}</Nav.Link>
    }
    return null;
  }

  renderProjectsIcon = () => {
    const { projectsTitle } = this.props.localization.strings.common.sidebar.headerIcons;
    const { isCertFocusUser } = this.props;
    if (isCertFocusUser) {
      return <Nav.Link as={Link} to="/certfocus/projects">{projectsTitle}</Nav.Link>
    } else {
      return null
    }
  }


  render(){


  return (
    <>
    <Navbar  expand="lg" className='breadcrumb m-0 rounded-0' >
      <Container fluid >
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          <RolAccess
          masterTab="dashboard"
          sectionTab="view_section"
          component={this.renderDashboardIcon} ></RolAccess>
          <RolAccess
          masterTab="yurbi_dashboard"
          sectionTab="view_section"
          component={this.renderAnalyticsIcon} ></RolAccess>
            <RolAccess
          masterTab="hiring_clients"
          sectionTab="view_section"
          component={this.renderHiringClientsIcon} ></RolAccess>
            <RolAccess
          masterTab="projects"
          sectionTab="view_section"
          component={this.renderProjectsIcon} ></RolAccess>
            <RolAccess
          masterTab="insured"
          sectionTab="list_insured"
          component={this.renderInsuredIcon} ></RolAccess>
          <RolAccess
        masterTab="documents"
        sectionTab="view_document"
        component={this.renderDocumentsIcon} ></RolAccess>
            <RolAccess
          masterTab="tasks"
          sectionTab="view_section"
          component={this.renderBellIcon} ></RolAccess>
            <RolAccess
          masterTab="mail"
          sectionTab="send_email"
          component={()=>this.renderEmail()}></RolAccess>
            <RolAccess
          masterTab="agencies"
          sectionTab="view_section"
          component={this.renderAgenciesIcon} ></RolAccess>
            <RolAccess
          masterTab="users"
          sectionTab="view_users"
          component={this.renderUsersIcon} ></RolAccess>
            <RolAccess
          masterTab="requirements"
          sectionTab="view_requirements"
          component={this.renderSettingsIcon} ></RolAccess>
             {
               this.props.isCertFocusUser ?
              <NavDropdown className='menu-dropdown' title="Help" id="basic-nav-dropdown">
                {
                ((this.props.currentUserRoleId !== 0) && (this.props.currentUserRoleId !== 8) && (this.props.currentUserRoleId !== 4))
                ? 
              <NavDropdown.Item as={Link} to="/video">
                Videos
              </NavDropdown.Item>
              : null
              }
            </NavDropdown>
              : null}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>


    </>
  )
  }
}
export default SubNavigationBar