import React, { Component } from 'react';

class SelectHC extends Component {
  render() {
    const {
      onChangeHCSelect,
      options,
      selectedHcId,
    } = this.props;

    const hcOptions = options.map((opt) => (
      <option value={opt.id} key={opt.id}>
        {opt.name}
      </option>
    ));

    return (
      <div className="select-wrapper add-sc-select">
        <select
          value={selectedHcId}
          onChange={onChangeHCSelect}
        >
          {hcOptions}
        </select>
      </div>
    );
  }
}

export default SelectHC;