import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

import DataEntryForm from './form';
import * as actions from '../actions';
import * as commonActions from '../../../common/actions';
import * as projectInsuredsActions from '../../project-insureds/actions';

import CopyCertificates from '../../copy-certificates/index';

import './DataEntry.css';
class DataEntry extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showCopyCertificateModal: false,
      copyCertificateData: null,
    };
  }

  onUnload = e => { // the method that will be used for both add and remove event
    const {
      certificateData,
      actions,
    } = this.props;
  //console.log(certificateData);
    actions.unlockCOI({
      certificateId: certificateData.certificateId,
      documentId: certificateData.documentId
    })
    // e.preventDefault();
    // e.returnValue = '';
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
  }

  componentDidMount() {

    window.addEventListener("beforeunload", this.onUnload);

    const { 
      certificateData,
      actions,
    } = this.props;

    if (certificateData) {
      //actions.fetchDataEntryAgencies({ withoutPag: true });
      actions.fetchRequirementSetDetail({ 
        holderId: certificateData.holderId, 
        projectId: certificateData.projectId, 
        documentId: certificateData.documentId,
        requirementSetId: certificateData.requirementSetId,
      });

      this.setBreadcrumb();
    }    
  }

  setBreadcrumb = () => {
    this.props.commonActions.setBreadcrumbItems([
      {
        pathName: this.props.certificateData.holderName,
        hrefName: '/certfocus/holders/' + this.props.certificateData.holderId
      },
      {
        pathName: this.props.certificateData.projectFullName,
        hrefName: '/certfocus/projects/' + this.props.certificateData.projectId
      },
      {
        pathName: this.props.certificateData.insuredName,
        hrefName: '/certfocus/insureds/' + this.props.certificateData.insuredId
      }
    ]);
  }

  initLoadingSpinner = () => {
    this.props.commonActions.setLoading(true);
  }

  closeLoadingSpinner = () => {
    this.props.commonActions.setLoading(false);
  }

  openCopyCertificateModal = (certificateId) => {
    this.initLoadingSpinner();
    this.props.actions.fetchCopyCertificateData(certificateId, (err, data) => {            
      if (!err) {
        const certificateData = {
          certificateId: certificateId,
          documentId: data.DocumentId,
          fileName: data.FileName,
          documentUrl: data.DocumentUrl,
          holderId : data.HolderId,
          holderName: data.HolderName,
          projectId: data.ProjectId,
          projectName: data.ProjectName,
          insuredId: data.InsuredId,
          insuredName: data.InsuredName,
          projectInsuredId: data.ProjectInsuredId,
        }

        this.props.actions.fetchDataEntry(certificateId, (err, data) => {
          if (!err) {
            const copyCertificateData = {
              ...certificateData,
              ...data,
            }
            this.setState({
              showCopyCertificateModal: true,
              copyCertificateData: copyCertificateData
            });
          }
        });
      }
      this.closeLoadingSpinner();  
    });
  }

  closeCopyCertificateModal = (success) => {
    if (success) {
      this.props.closeCopyCertificateModal(success);
    }
    this.setState({
      showCopyCertificateModal: false,
    });
  }

  renderModal = () => {
    const { copyCertificateData } = this.state;
    return (
      <CopyCertificates
        holderId={this.props.certificateData.holderId}
        projectInsuredId={this.props.certificateData.projectInsuredId}
        insuredId={this.props.certificateData.insuredId}
        copyCertificateData={copyCertificateData}
        redirectTo={() => this.props.history.push(`/certfocus/documents`)}
      />
    );
  }
  
  onSubmit = (data) => {
    const { userId } = this.props.login;
    const { holderId, projectId, insuredId, documentId, projectInsuredId} = data;
    // console.log('SUBMIT! ', data);
    this.initLoadingSpinner();

    if (!data.unreadable) {
      this.props.actions.addDataEntry(data, (certificateId) => {
        // console.log('certificateId: ', certificateId);
        if (!certificateId) {
          Swal({
            type: 'error',
            title: 'Could not save data entry',
            allowOutsideClick: false,
            text: 'Your time has expired and another user is working on it.',
          }).then((result) => {
            if (result.value) {
              // window.close();
              //if window.close fails redirect.
              this.props.history.push(`/certfocus/documents`);
            }
          });
        } else {
          this.props.actions.calculateDeficiencies(holderId, projectId, insuredId, documentId, certificateId, (data) => {
            if (data) {
              Swal({
                type: 'success',
                title: 'Data Entry',
                allowOutsideClick: false,
                text: 'Deficiencies have been successfully calculated.',
              }).then((result) => {
                if (result.value) {
                  const { pdfHandler } = this.props;
                  if (pdfHandler) pdfHandler.close();
                  this.props.history.push(`/certfocus/certificates/${projectInsuredId}`)
                  // window.close();
                  // here we must open copy certificates popup based on master certificate value
                  //this.openCopyCertificateModal(certificateId);
                }
              });
            } else {
              Swal({
                type: 'error',
                title: 'Could not save data entry',
                allowOutsideClick: false,
                text: 'Some Error Occurred.',
              }).then((result) => {
                if (result.value) {
                  window.close();
                }
              });
            }
          });
        }
      });
    } else {
      Swal({
        type: 'warning',
        title: 'Set document as will not process',
        allowOutsideClick: false,
        text: 'are you sure?. All data entry done will be lost',
        showCancelButton: true,
        confirmButtonColor: '#2E5965',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
      }).then((result) => {
        if (result.value) {
          this.props.actions.setDocumentAsUnreadable(data, (success) => {
            Swal({
              type: success? 'success' : 'error',
              title: success? 'Document will not process' : 'Could not set document as will not process',
              allowOutsideClick: false,
              text: success? 'The document was set as will not process.' : 'Your time has expired and another user is working on it.',
            }).then((result) => {
              if (result.value) {
                window.close();
                //if window.close fails redirect.
                this.props.history.push(`/certfocus/documents`);
              }
            });
          });
        }
      });

    }
    this.closeLoadingSpinner();
  };

  render() {
    let {
      showCopyCertificateModal
    } = this.state;
    
    return (
      <section className="wiz-step white-section">
        <div className="admin-form-field-wrapper">
          <DataEntryForm
            onSubmit={this.onSubmit}
            toggleFileViewer={this.props.toggleFileViewer}
            enablePreview={this.props.enablePreview}
            isNew={this.props.isNew}
            certificateData={this.props.certificateData}
            formData={this.props.formData}
          />
        </div>
        
        <Modal
          show={showCopyCertificateModal}
          onHide={this.closeCopyCertificateModal}
          className="add-item-modal add-hc">
          <Modal.Body className="add-item-modal-body large-modal">
            {this.renderModal()}
          </Modal.Body>
        </Modal>
      </section>      
    );
  }
};

const mapStateToProps = (state) => {
  return {
    local: state.localization,
    processing: state.processing,
    projectInsureds: state.projectInsureds,
    login: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    projectInsuredsActions: bindActionCreators(projectInsuredsActions, dispatch),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataEntry));