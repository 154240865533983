import React from 'react';
import { connect } from 'react-redux';

import logo from '../../../assets/img/logo-nav.svg';
import vertikalLogo from '../../../assets/img/VertikalLogo.png';

const RegisterSidebar = (props) => {
  const hcLogo = props.hcLogo ? `data:image/jpeg;base64,${props.hcLogo}` : null;
  const hcLogoStyle = {
    maxWidth: '40%',
    width: 'auto',
    height: 'auto',
  }
  const slogan =  props.local.strings.register.registerSidebar.slogan;
  return (
      <div className="col-sm-12 col-md-4 bg-blue-gradient register-sidebar">
          <div className="vertikal-logo-wrapper">
              <img className="vertikal-img register-sidebar-img" src={vertikalLogo} alt="Vertikal"/>
          </div>
          <div className="logos-wrapper">
          <div className="side-by-side-logos">
            <img className="prequal-img register-sidebar-img" src={logo} alt="Prequal"/>
            {
              hcLogo?
                  <img className="skender-img register-sidebar-img" style={hcLogoStyle} src={hcLogo} alt="Skender"/>
                  :null
            }
          </div>
        </div>
        <p className="register-slogan">{slogan}</p>
      </div>
  )
}

const mapStateToProps = (state) => {
  return {
    local: state.localization,
  };
};

export default connect(mapStateToProps)(RegisterSidebar);
