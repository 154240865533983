import React, { Fragment, useCallback, useMemo, useReducer, useState } from 'react';
import Api from '../../../../../lib/api';
import SpinnerLoader from '../../../../common/loader/SpinnerLoader';
import { showErrorAlert } from '../../../../alerts';

const colorToId = ["Select Color", "green", "yellow", "red"];

const createOrSaveLabel = async (form) => {
    const token = localStorage.getItem('auth-token');
    const res = await Api.post(
        `forms/discreteAccountConfigurations`,
        { data: form },
        token
    );
    return res.data;
}

const ActionLabelItem = ({ item, onSave, onCancel }) => {
    const [isEditing, setIsEditing] = useState(item.id == 0);
    const [editFields, setEditFields] = useState(item);
    const [saveLoader, setSaveLoader] = useState(false)
    const [fieldErrors, setFieldErrors] = useState({
        label: false,
        color: false,
    });

    const handleEdit = (type, value) => {
        setEditFields({
            ...editFields,
            ...{ [type]: value }
        });
        setFieldErrors({
            ...fieldErrors,
            ...{ [type]: !value || (type == 'color' && value == 0) }
        });
    }
    const handleSave = async (editFields) => {
        if (!editFields.label.trim() || (!editFields.color || editFields.color == 0)) {
            setFieldErrors({
                label: !editFields.label || editFields.label.trim() == '',
                color: !editFields.color || editFields.color == 0,
            })
            return;
        }
        setSaveLoader(true);
        await onSave({
            ...editFields,
            label: editFields.label.trim(),
        }).then(() => {
            setIsEditing(false);
            setSaveLoader(false);
        }).catch(() => {
            setSaveLoader(false);
            showErrorAlert("Can't Save Label!", "Error while saving label. Please try again!");
        });
    }
    const handleCancel = () => {
        setIsEditing(false);
        setEditFields(item);
        if (onCancel) {
            onCancel();
        }
    }

    return (
        <div className='content-content'>
            {isEditing ? (
                <div className='editable-content-container'>
                    <div className='editable-label-input-container'>
                        <h3 className='label'>Label</h3>
                        <input
                            type='text'
                            value={editFields['label']}
                            placeholder={'Type Label'}
                            onChange={(e) => handleEdit('label', e.target.value)}
                        />
                        <p className={`${fieldErrors.label ? 'block error-msg' : 'hidden'}`}>Please type label</p>
                    </div>
                    <div className='editable-dropdown-container'>
                        <div className='editable-dropdown-buttons'>
                            <div className='editable-dropdown'>
                                <h3 className='label'>Flag Colors</h3>
                                <select
                                    placeholder='Select Color'
                                    className='dropdown-content'
                                    value={editFields['color']}
                                    onChange={(e) => handleEdit('color', e.target.value)}
                                >
                                    {colorToId.map((color, idx) => (
                                        <option key={idx} className='dropdown-content-option' value={idx}>{color}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='editable-btn-container'>
                                <button disabled={saveLoader} className='save' style={saveLoader ? { backgroundColor: '#73aebf' } : {}} onClick={() => handleSave(editFields)}>
                                    {
                                        saveLoader ? <SpinnerLoader /> : 'Save'
                                    }
                                </button>
                                <button disabled={saveLoader} style={saveLoader ? { cursor: 'not-allowed' } : {}} className='cancel' onClick={handleCancel}>Cancel</button>
                            </div>
                        </div>
                        <p className={`${fieldErrors.color ? 'block error-msg' : 'hidden'}`}>Please select color</p>
                    </div>
                </div>
            ) : (
                <Fragment>
                    <h5 className='content-content-label'>{item.label}</h5>
                    <div className='content-content-item'>
                        <h5 style={{ textTransform: 'capitalize' }}>{colorToId[item.color]}</h5>
                        <button className='btn' onClick={() => setIsEditing(true)}>Edit</button>
                    </div>
                </Fragment>
            )}
        </div>
    )
}

function reducer(state, action) {
    if (action.type === 'add') {
        const { payload } = action;
        if (state[0]) {
            return state;
        }
        return {
            [payload.id]: payload,
            ...state,
        }
    }
    if (action.type === 'remove') {
        const { payload } = action;
        const newState = Object.assign(state, {});
        delete newState[payload.id];
        return Object.assign({}, newState);
    }
    if (action.type === 'update') {
        const { payload } = action;
        const newState = Object.assign(state, {});
        if (state[0] && payload.removeZero) {
            delete newState[0];
            return {
                [payload.id]: {
                    id: payload.id,
                    color: payload.color,
                    label: payload.label,
                },
                ...newState
            }
        }
        newState[payload.id] = payload;
        return Object.assign({}, newState)
    }
}

const Account = ({ formId, discreteAccountId, discreteAccountName, labels: labelList = [] }) => {
    const [transformedLabelList, dispatch] = useReducer(reducer, {}, () => {
        if (labelList && labelList.length) {
            return labelList.reduce((acc, cur) => {
                return { ...acc, [cur.id]: cur }
            }, {});
        }
        return {}
    });

    const onSave = async (payload) => {

        return createOrSaveLabel({
            ...payload,
            formId,
            discreteAccountId
        }).then((response) => {
            const { data } = response;
            const newPayload = Object.assign({}, payload, {
                id: data.id,
                label: payload.label.trim(),
                removeZero: payload.id == 0
            })
            dispatch({ type: 'update', payload: newPayload});
        });
    };

    const sortedLabelList = useMemo(() => {
        let newLabelList = Object.values(transformedLabelList);
        if (transformedLabelList[0]) {
            newLabelList = newLabelList.slice(1);
        }
        return newLabelList.sort((a, b) => b.id - a.id);
    }, [transformedLabelList]);

    return (
        <div className='configuration-modal-item'>
            <div className='item-header'>
                <h3>{discreteAccountName}</h3>
            </div>
            <div className='item-content'>
                <div className='content-body'>
                    <button disabled={transformedLabelList[0]} type="button" className="btn" onClick={() => dispatch({
                        type: 'add',
                        payload: { id: 0, label: '', color: 0 }
                    })}>
                        <span style={{ fontSize: '20px', paddingBottom: '2px' }}>&#43;</span>
                        <span>Add New Labels</span>
                    </button>
                    <div className='content-content-container'>
                        {
                            transformedLabelList[0] && <ActionLabelItem
                                key={transformedLabelList[0].id}
                                item={transformedLabelList[0]}
                                onSave={onSave}
                                onCancel={() => {
                                    dispatch({ type: 'remove', payload: { id: 0 } });
                                }}
                            />
                        }
                        {
                            sortedLabelList.map((item, idx) => (
                                <ActionLabelItem
                                    key={item.id}
                                    item={item}
                                    onSave={onSave}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Account;
