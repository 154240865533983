import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';

import Tabs from './tabs';
import EditInsuredModal from '../modals/addInsuredModal';
import AddTagsToInsuredModal from './modals/AddTagsToInsuredModal';
import InsuredInfo from './InsuredInfo';

import * as commonActions from '../../common/actions';
import * as loginActions from '../../auth/login/actions';
import * as insuredViewActions from './actions';

import './InsuredView.css';
import DocumentProgressBar from "../documentProgessBar";

class InsuredView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: null,
    };
  }

  componentDidMount() {
    const { insuredId, holderId, holderName } = this.props.match.params;    
    this.props.actions.setHolderSelected({ 'id': holderId, 'name': holderName });
    this.props.actions.fetchInsured(insuredId);
    this.props.actions.fetchInsuredTags(insuredId);

    if (this.props.common.countries.length <= 0) {
      this.props.commonActions.fetchCountries();
    }
    this.props.loginActions.getProfile();

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentTabSelected) {
      this.setState({
        currentTab: nextProps.currentTabSelected
      });
    }
  }

  onClose = () => {
    this.setState({ showModal: null });
  }

  onCloseEditAndRefresh = () => {
    const { insuredId } = this.props.match.params;

    this.props.actions.fetchInsured(insuredId);

    this.setState({ showModal: null });
  }

  deleteTag = (tag) => {
    const { insuredId } = this.props.match.params;
    this.props.actions.sendTag(tag, insuredId, false);
  }

  openModal = (modal) => {
    this.setState({ showModal: modal });
  }

  renderModal = () => {
    const { showModal } = this.state;
    const { insuredId } = this.props.match.params;

    switch (showModal) {
      case 'tags':
        return <AddTagsToInsuredModal
          close={this.onClose}
          insuredId={insuredId}
        />;
      case 'edit':
        return <EditInsuredModal
          close={this.onCloseEditAndRefresh}
          onHide={this.onClose}
          profile={this.props.insuredDetails.insuredData}
        />;
      default:
        return null;
    }
  }

  render() {
    const fromInsuredList = (this.props.location && this.props.location.state) 
      ? this.props.location.state.fromInsuredList 
      : false;

    const fromProject = (this.props.location && this.props.location.state)
      ? this.props.location.state.fromProject
      : false;
    
    if (this.props.insuredDetails.fetching || !this.props.login.profile.Id) {
      return (
        <div className="spinner-wrapper">
          <div className="spinner mb-4" />
        </div>
      );
    }
    
    const modalClass = this.state.showModal === 'tags' ? 'add-item-modal add-entity-medium' : 'add-item-modal add-hc';
    
    return (
      <div className="insured-view">
        {/* MODAL */}
        <Modal
          show={this.state.showModal ? true : false}
          onHide={this.onClose}
          className={modalClass}>
          <Modal.Body className={this.state.showModal === 'edit' && 'add-item-modal-body mt-0'}>
            {this.renderModal()}
          </Modal.Body>
        </Modal>

        <div className="container-fluid">
        {!_.isEmpty(this.props.insuredDetails.insuredData) ? (
          <Fragment>
          <InsuredInfo
            insuredDetails={this.props.insuredDetails}
            deleteTag={this.deleteTag}
            openModal={this.openModal}
            fromInsuredList={fromInsuredList}
            fromProject={fromProject}
          />
          <Tabs 
            insuredData={this.props.insuredDetails.insuredData} 
            insuredArchived={this.props.insuredDetails.insuredData.archive}  
          />
          </Fragment>
        ):( 
          <div className="alert alert-danger" style={{ float: 'center', border: '1px solid #721c24' }}>
            <span><i className="linear-icon-warning" /></span> You do not have permission to access this insured.
          </div>
        )}
        </div>

        {
          this.props.processing.autoProcessingDocument === true ?
            (<DocumentProgressBar insuredId={this.props.match.params.insuredId}/>) : ''
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    insuredDetails: state.insuredDetails,
    local: state.localization,
    login: state.login,
    common: state.common,
    currentTabSelected: state.insuredDetails.tagSelected,
    processing: state.processing
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(insuredViewActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    loginActions: bindActionCreators(loginActions, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(InsuredView);
