import React from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import validate from './validation';
import './form.css'
import renderDropzoneInput from "../components/renderDropzoneInput";
import Api from "../../../../../lib/api";
import { CSVLink } from "react-csv";

class UpdateCustomFieldsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      projectData: []
    }
    this.csvLink = React.createRef();
  }

  render() {
    const {handleSubmit} = this.props;
    const {
      cancelButton,
      uploadButton,
      downloadTemplate
    } = this.props.local.strings.certFocusProjects.updateCustomFieldsModal;

    const getprojectData = async () => {
      await Api.get(
        `cf/holder/${this.props.holderId}/CustomFields/download`,
      ).then( (response) => {
        this.setState({projectData: response.data.data})
        this.csvLink.current.link.click()
      })
    }

    return (
      <form
        autoComplete="off"
        className="entity-info-form"
        onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h6>Upload file</h6>
              <p>To edit or update the custom field values the template provided below must be completed and updated as a .csv file</p>
            </div>
            <div className="col-12">
              <div className="csv-file-wrapper">
                <Field
                    name="csvCFDataFile"
                    id="csvCFDataFile"
                    component={renderDropzoneInput}
                />
              </div>
            </div>
          </div>
        </div>
        {
          this.props.processing && !this.props.holders.errorHolders &&
          <div className="text-center">
            <div className="spinner-wrapper">
              <div className="spinner"></div>
            </div>
          </div>
        }
        {
          this.props.holders.errorHolders &&
          <div className="error-item-form">
            { this.props.holders.errorHolders }
          </div>
        }
        <div className="add-item-bn">
          <button
            className="bn bn-small bg-green-dark-gradient create-item-bn icon-save"
            type="submit" >
            {uploadButton}
          </button>
          <button
              className="bn bn-small bg-grey-dark icon-cancel ml-2"
              type="button"
              onClick={this.props.close}>
            {cancelButton}
          </button>
          <button
            className="bn bn-small bg-blue-dark icon-download-template ml-2"
            type="button"
            onClick={getprojectData}>
            {downloadTemplate}
          </button>
          <CSVLink data={this.state.projectData}
           className='hidden'
           ref={this.csvLink}
           target='_blank'
           filename="custom_fields.csv" >
          </CSVLink>
        </div>

      </form>
    );
  }
};

UpdateCustomFieldsForm = reduxForm({
  form: 'UpdateCustomFieldsForm',
  validate,
})(UpdateCustomFieldsForm);

const mapStateToProps = (state, ownProps) => {
  return {
    local: state.localization,
    common: state.common,
    holders: state.holders
  };
};

export default connect(mapStateToProps)(UpdateCustomFieldsForm);
