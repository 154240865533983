import { isEmpty } from 'lodash';
import Utils from "../../../../lib/utils";

export const processNotesTaskFilterValues = ({
  fromSidebar,
  field = "dueDate",
  pageNumber = 1,
  orderDirection = "ASC",
  formValues = {},
  roleId,
  userId,
}) => {
  let query = Utils.getFetchQuery(field, pageNumber, orderDirection);
  let showAssignedToColumn = undefined;
  const filterBox = {
    searchTerm: formValues.keywords || "",
    assignedToUserId: formValues.assignedTo || userId || "",
    assignedToRoleId: formValues.assignedToRoleId || "",
    enteredDate: formValues.enteredDate || "",
    dueDate: formValues.dueDate || "",
    statusId: formValues.statusId || "",
    typeId: formValues.typeId || "",
    hiringClientId: formValues.hiringClient? formValues.hiringClient :null,
    tasksPriorityId: formValues.tasksPriorityId? formValues.tasksPriorityId :null,
    enteredByUserId: formValues.createdBy ? formValues.createdBy.value : null,
  };

  /**
   * Selected HC can be multiple, need to add them to query param
   * * this query currently looks like hiringClientId=1,2,3...
   * ! Due to implementation of `query` using class we can't map it like hiringClientId=1&hiringClientId=2&hiringClientId=3....
   * TODO (Future improvment): use react-query package to handle all of this query stuffs.
   */

  if(filterBox.hiringClientId) {
    if(filterBox.hiringClientId.lenght<1)
      filterBox.hiringClientId = null;
    else {
      const selectedHCs = filterBox.hiringClientId;
      filterBox.hiringClientId =[];
      selectedHCs.forEach(hc => {
        filterBox.hiringClientId.push(hc.value);
      });
    }
  }
  if(filterBox.tasksPriorityId) {
    if(filterBox.tasksPriorityId.lenght<1)
      filterBox.tasksPriorityId = null;
    else {
      const selectedHCs = filterBox.tasksPriorityId;
      filterBox.tasksPriorityId =[];
      selectedHCs.forEach(tp => {
        filterBox.tasksPriorityId.push(tp.value);
      });
    }
  }
  if (fromSidebar && !formValues.assignedToMe && formValues.assignedToMyRole) {
    filterBox.assignedToRoleId = roleId;
    showAssignedToColumn = "role";
  }
  query = Utils.addSearchFiltersToQuery(query, filterBox);
  if (fromSidebar && formValues.assignedToMe && !formValues.assignedToMyRole) {
    filterBox.assignedToUserId = userId;
    filterBox.omitAssignedToRole = true;
    query.omitAssignedToRole = true;
    showAssignedToColumn = "user";
  }
  return {
    filterBox,
    showAssignedToColumn,
    query,
  };
};

export const getQueryObjectForApi = ({
  origin,
  qry,
  accessPoint,
  nextPropId,
  hcIdFromFilter,
  consoleOn,
  fromHCtab,
  fromSidebar,
  subcontractorId,
  tableOrderActive,
  order,
  userId,
  hcId,
  hcIdFromSub,
}) => {
  let query,
    fHCtab = fromHCtab,
    sidebar = fromSidebar,
    neither = !fHCtab && !sidebar,
    subId = subcontractorId;

  // if no custom query was passed in, create standard query
  if (!qry) {
    query = Utils.getFetchQuery(
      tableOrderActive,
      1,
      sidebar ? order.dueDate : order.enteredDate
    );
  } else {
    query = qry;
  }

  // Customizing queries depending on how this component is accessed.  Whether the component was accessed from sidebar -- or rather, the bell-icon in the navbar -- or from the HC Profile page or from the SC profile.
  if (sidebar || accessPoint === "fromSidebar") {
    query.assignedToUserId = userId;
    query.statusId = "1";
    query.alltasks = "1";
    query.system = "pq";

    if (hcIdFromFilter) {
      query.hiringClientId = hcIdFromFilter;
    }
  } else if (fHCtab || accessPoint === "fromHCtab") {
    query.hiringClientId = hcId;
    query.assetId = hcId;
    query.assetTypeId = "1";
    query.system = "pq";
    query.omitAssignedToRole = true;
  } else if (neither || accessPoint === "fromSCtab") {
    //if (consoleOn) {
    //console.log("hcIdFromSub = ", hcIdFromSub);
    //console.log("nextPropId = ", nextPropId);
    //}

    query.hiringClientId =
      nextPropId && nextPropId !== hcIdFromSub
        ? nextPropId
        : hcIdFromSub;
    query.subcontractorId = isEmpty(subId) ? "" : subId;
    query.assetId = isEmpty(subId) ? "" : subId;
    query.assetTypeId = "2";
    query.system = "pq";
    query.omitAssignedToRole = true;
  }

  // Rather strict console.log diagnostic to catch badly defined queries:

  // if (consoleOn) {
  //   console.log(origin);
  //   console.log(
  //     `${
  //       fHCtab
  //         ? "fromHCtab"
  //         : sidebar
  //         ? "fromSidebar"
  //         : subId
  //         ? "from SCTab"
  //         : "no access point determined"
  //     }`
  //   );

    // if (neither && !subcontractorId && !accessPoint) {
    //   console.log("undetermined access point");
    // }

    Utils.checkObjForUndefinedOrNullProps(query);
  //}
  return query;
};
