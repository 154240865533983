import React from 'react';
import { Field, reduxForm, change, touch } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import renderField from '../../../../customInputs/renderField';
import renderSelect from '../../../../customInputs/renderSelect';
import renderRemovable from '../../../../customInputs/renderRemovable';
import renderTypeAhead from '../../../../customInputs/renderTypeAhead';

import * as commonActions from '../../../../common/actions';
import * as projectsActions from '../../../projects/actions';
import * as reqSetsActions from '../../../requirement-sets/actions';

import Utils from '../../../../../lib/utils';
// import asyncValidate from './asyncValidation';
import validate from './validation';
import  { isEmpty } from 'lodash';
import Select from 'react-select';
import {AsyncPaginate} from 'react-select-async-paginate';

import './projectInfo.css'
import Swal from "sweetalert2";
import * as loadApplyOptions from '../../../../common/countyDropdownActions/loadOptions'
import * as applyActions from '../../../../common/countyDropdownActions/actions'

const SelectAsyncOptions = ({ input, meta, dispatch, ...props }) => {
  const touchedError = meta.touched && meta.error;
  const name = input.name;
  const baseClassName = `${props.className || ''} select-${name}`;
  const errorClassName = `${touchedError ? `${baseClassName}-error` : ''}`;

  return (
    <React.Fragment>
      <AsyncPaginate
        {...input}
        {...props}
        openMenuOnClick={true}
        closeMenuOnScroll={true}
        cacheOptions={true}
        pageSize={10}
        additional={{ page: 1 }}
        classNamePrefix="vk"
        className={`${baseClassName} ${errorClassName}`}
        menuPlacement="auto"
        onFocus={(_) => { }}
        onBlur={() => meta.dispatch(touch('InsuredInfoForm', name))}
        onChange={(newValue) => input.onChange(newValue)}
        captureMenuScroll={true}
        closeMenuOnSelect={true}
        debounceTimeout={300}
        loadOptionsOnMenuOpen={true}
      />
      {touchedError && (
        <span className="select-error-message field-validation-message">
          {meta.error}
        </span>
      )}
    </React.Fragment>
  );
};
class ProjectInfoForm extends React.Component {
  constructor(props) {
		super(props);

		this.state = {
		  currentReqSets: [],
      requirementSetsPristine: true,
    }

		props.reqSetsActions.setReqSetsPossibleValues([]);

		this.state = {
		  currentReqSets: [],
      requirementSetsPristine: true,
    }
    this.state = {
      selectedState: this.props.project && this.props.project.StateId && this.props.project.StateId || '',
      selectedCountry: this.props.project && this.props.project.CFCountryId && this.props.project.CFCountryId || 1
    }

    const { project, fromHolderTab, holdersProfile } = props;

    if (!project && fromHolderTab) {
      const { profileData } = holdersProfile;
      props.dispatch(change('ProjectInfoForm', 'holderId', { value: profileData.id, label: profileData.name } || ""));
      props.projectsActions.fetchHolderCustomFields(profileData.id);
      props.reqSetsActions.fetchRequirementSetsPossibleValues({ holderId: profileData.id, archived: 0 });
    }
    if(!this.project){
        this.props.dispatch(change('ProjectInfoForm', 'CFCountryId',{value:1,label:"United States"}));
    }
    if (project) {
      let accountManagers= []
      if( this.props && this.props.accountManagers){
         accountManagers =  JSON.parse(this.props.accountManagers);
      }
      const { holderId, holderName, id } = project;
      const holderObj = {
        value: holderId,
        label: holderName
			};

      if (project.reqSets) {
        this.state.currentReqSets = _.uniqBy(JSON.parse(project.reqSets), 'value');
      }
      
      let selectedAccountManager = null
      if(project.accountManagers){
        const projectManagerId = project.manager
        const parsedData = JSON.parse(project.accountManagers)
        const accountManager = parsedData.filter(el => el.value == projectManagerId)
        selectedAccountManager = accountManager[0]
      }
      props.reqSetsActions.fetchRequirementSetsPossibleValues({ holderId: holderId, archived: 0, sumProjectId: id});
      props.projectsActions.fetchHolderCustomFields(holderId);

      props.dispatch(change('ProjectInfoForm', 'name', project.name || ""));
      props.dispatch(change('ProjectInfoForm', 'CFProjectName2', project.CFProjectName2 || ""));
      props.dispatch(change('ProjectInfoForm', 'holderId', holderObj || ""));
      props.dispatch(change('ProjectInfoForm', 'manager', selectedAccountManager || ""));
      props.dispatch(change('ProjectInfoForm', 'address1', project.address1 || ""));
      if (project.address2 !== "not available") {
        props.dispatch(change('ProjectInfoForm', 'address2', project.address2 || ""));
      }
      props.dispatch(change('ProjectInfoForm', 'city', project.city || ""));
      props.dispatch(change('ProjectInfoForm', 'stateId', {value:project.StateId,label:project.StateName} || ""));
      props.dispatch(change('ProjectInfoForm', 'zipCode', project.zipCode || ""));
      props.dispatch(change('ProjectInfoForm', 'CFCountryId',{value:project.CFCountryId,label:project.CountryName} || ""));
      props.dispatch(change('ProjectInfoForm', 'reqSet', project.RequirementSetID || ""));

      props.dispatch(change('ProjectInfoForm', 'note', project.CFNote || ""));
      // props.dispatch(change('ProjectInfoForm', 'statusId', project.statusId || ""));
      props.dispatch(change('ProjectInfoForm', 'CFContactName', project.CFContactName || ""));
      props.dispatch(change('ProjectInfoForm', 'CFContactPhone', project.CFContactPhone || ""));
      props.dispatch(change('ProjectInfoForm', 'CFProjectEmail', project.CFProjectEmail || ""));
      props.dispatch(change('ProjectInfoForm', 'number', project.number || ""));
      props.dispatch(change('ProjectInfoForm', 'customAttribute', project.customAttribute || ""));
      props.dispatch(change('ProjectInfoForm', 'description', project.description || ""));
      props.dispatch(change('ProjectInfoForm', 'projectReqSet', this.compactRequerimentSets(this.state.currentReqSets ) || ""));
      props.dispatch(change('ProjectInfoForm', 'aiWording', project.AIWording || ""));
      //SET CUSTOM FIELD VALUES
      const { projectCustomFields } = project;
      if (projectCustomFields && projectCustomFields.length) {
        projectCustomFields.forEach(field => {
          const { CustomFieldId, FieldValue, Archived } = field;
          if (!Archived) {
            const fieldName = `customField-${CustomFieldId}`;
            props.dispatch(change('ProjectInfoForm', fieldName, FieldValue || ""));
          }
        });
      }else{
        if( this.props && this.props.accountManagers){
          let accountManagers =  JSON.parse(this.props.accountManagers);
          if(accountManagers.length === 1){
            props.dispatch(change('ProjectInfoForm', 'manager', selectedAccountManager || ""));
          }
        }
      }
    }else{
      if( this.props && this.props.accountManagers){
        let accountManagers =  JSON.parse(this.props.accountManagers);
        if(accountManagers.length === 1){
          props.dispatch(change('ProjectInfoForm', 'manager', accountManagers[0].value || ""));
        }
      }
    }
  }

  compactRequerimentSets = (reqSets) => {
    if (!reqSets) return [];

    return reqSets.map(({value}) => value);
  }

  componentWillUnmount() {
    if (!this.props.fromProjectView) {
      this.props.projectsActions.setCustomFieldsList([]);
    }
  }

  onRemoveHolder = () => {
    
    this.props.commonActions.resetTypeAheadResults();
    this.props.projectsActions.setCustomFieldsList([]);
    this.props.reqSetsActions.setReqSetsPossibleValues([]);
  }

  onSelectHolder = (holder) => {
    const {
      typeAheadResults } = this.props.common;
      if(typeAheadResults && typeAheadResults.length > 0){
       let holderObj =  typeAheadResults.find(x => x.id === holder.value);
       if(holderObj.accountManagers){
         this.props.setAccountManger(holderObj.accountManagers);
         let managers = JSON.parse(holderObj.accountManagers);
         if(managers.length === 1){
          this.props.dispatch(change('ProjectInfoForm', 'manager', managers[0].value || ""));
         }
       }
      }
    this.props.projectsActions.fetchHolderCustomFields(holder.value);
    this.props.reqSetsActions.fetchRequirementSetsPossibleValues({ holderId: holder.value });
  }

  searchHolder = (filterTerm) => {
    this.props.commonActions.fetchTypeAhead({ nameTerm: filterTerm });
  }

  getCustomFieldOptions = (options) => {
    const arr = options.split(',');
    return [
      { label: '--select--', value: '' },
      ...arr.map((opt) => { return { label: opt, value: opt } })
    ];
  }
  
  handleCountryChange = (value)=>{
    if(value.value !== this.state.selectedCountry){
      this.props.dispatch(change('ProjectInfoForm', 'stateId', ""));
    }
      this.setState({selectedCountry:value.value})
  }

  renderFormField = (element, idx) => {  
    const { type, name, label, ph, options, conditional, show, loadOptions } = element;
    const fieldType = type || 'text';
    const style = {};
    if (conditional && !show) {
      style.display = 'none';
    }

    if (fieldType === 'typeAhead') {
      const { fetching, results, error, handleSearch, onSelect } = element;

      return (
        <div key={idx} className="wiz-field admin-form-field-wrapper" style={style}>
          <label htmlFor={name}>{`${label}:`}</label>
          <Field
            resetOnClick
            name={name}
            placeholder={ph}
            fetching={fetching}
            results={results}
            handleSearch={handleSearch}
            fetchError={error}
            component={renderTypeAhead}
            onSelect={onSelect}
          />
        </div>
      );
    } else if (fieldType === 'removable') {
      const { valueText, disabled, onRemove } = element;
      return (
        <div key={idx} className="wiz-field admin-form-field-wrapper" style={style}>
          <label htmlFor={name}>{`${label}:`}</label>
          <Field
            name={name}
            valueText={valueText}
            component={renderRemovable}
            onRemove={onRemove}
            disabled={disabled}
          />
        </div>
      );
    }
    return (
      <div key={idx} className="wiz-field admin-form-field-wrapper" style={style}>
        <label htmlFor={name}>{`${label}:`}</label>
        {
          options && name.includes('customField') ? (
            <div className="select-wrapper">
              <Field
                name={name}
                component={renderSelect}
                options={options}
                />
            </div>
          ) :
          options || loadOptions ?
            <div className="select-wrapper">
              <Field
                name={name}
                component={name == "manager " ? renderSelect:SelectAsyncOptions}
                options={name == "manager" ? options: null}
                loadOptions={ name == "manager" ? options:loadOptions}
                cacheUniqs={[this.state.selectedCountry]}
                className={'accountManager'}
                onChange={(value)=>name == "CFCountryId" ? this.handleCountryChange(value) : null}
                defaultValue={ name == 'CFCountryId' ? this.state.selectedCountry || null :
                 name == 'stateId' ? this.state.selectedState || null : null     }
                />
            </div>
            :
            <Field
              name={name}
              type={fieldType}
              placeholder={ph}
              component={renderField} />
        }
      </div>
    );
  }

  setRequirementSetInput = (values) => {
    const reqValues = this.compactRequerimentSets(values);
    this.setState({currentReqSets: values, requirementSetsPristine: false})
    this.props.dispatch(change('ProjectInfoForm', 'projectReqSet', reqValues || ''));
  }

  onReqSetChange = (inputValue, actionMeta) => {
    const {project} = this.props;
    switch (actionMeta.action) {
      case 'remove-value':
        if (inputValue.length == 0 && project) {
          Swal({
            title: `Removing Requirement Set`,
            text: `The project must have at least one requirement set`,
            type: 'error',
            confirmButtonColor: '#d33',
            confirmButtonText: 'Cancel'
          }).then((result) => {
            return false;
          });
        } else {
          const { holderRequirementSets, project } = this.props;
          const reqSet = holderRequirementSets.possibleValuesResults.filter( (el) => {
              return el.Id == actionMeta.removedValue.value
          });

          if (reqSet.length == 1 && reqSet[0].hasProjectInsured && reqSet[0].hasProjectInsured > 0) {
            Swal({
              title: `Removing Requirement Set`,
              text: `Can not remove the selected requirement set, it has insured(s) assigned, change their requirement set before.`,
              type: 'error',
              confirmButtonColor: '#d33',
              confirmButtonText: 'Cancel'
            }).then((result) => {
              return false;
            });
          } else {
            Swal({
              title: `Removing Requirement Set`,
              text: `Are you sure you want to remove ${actionMeta.removedValue.label} requirement set from this project?`,
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#2E5965',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes!'
            }).then((result) => {
              if (result.value) {
                this.setRequirementSetInput(inputValue);
              }
              return false;
            });
          }
        }

        break;
      default:
        this.setRequirementSetInput(inputValue);
        break;
    }
  }

  renderProjectReqSet = (projectReqSetOptions) => {
    return (
      <div className="wiz-field mb-4">
        <label className={'projectReqSetLabel'} htmlFor={'projectReqSet'}>{`Requirement Sets:`}
        </label>
        <Field
          component={renderField}
          type={'hidden'}
          name={'projectReqSet'}
          defaultValue={''}
        />
        <div className="select-wrapper w-100 projectReqSet">
                <Select
                  isMulti
                  value={this.state.currentReqSets}
                  isClearable={false}
                  isSearchable={true}
                  options={projectReqSetOptions}
                  placeholder={'Choose a Requirement Set'}
                  onChange={this.onReqSetChange}
                  className={'invalid'}
                />
        </div>
      </div>
    )
  }
 

  render() {
    const { addProjectCustomFields, addProjectCustomFieldsFetching } = this.props.projects;
    const { handleSubmit } = this.props;
    const {
      labelProjectName,
      labelProjectName2,
      labelHolder,
      labelAddress1,
      labelAddress2,
      labelCity,
      labelState,
      labelPostalCode,
      labelCountry,
      labelReqSet,
      labelProjectNote,
      labelContactName,
      labelContactPhone,
      labelProjectEmail,
      labelProjectNumber,
      labelProjectDescription,
      // labelStatus,
      cancel,
      saveButton,
      labelAccountManager,
      labelAiWording
    } = this.props.local.strings.hcProfile.projects.addProjectModal.projectTab;
    const {
      typeAheadResults,
      typeAheadFetching,
      typeAheadError } = this.props.common;
    const { fromHolderTab, holderRequirementSets, project } = this.props;
    // Filter RequirementSets without any RuleGroup defined
    const requirementSetFilteredOptions = holderRequirementSets.possibleValuesResults.filter((e => e.HasRuleGroup > 0));
    let projectReqSetOptions = requirementSetFilteredOptions.map( (elem) => {
      return  { value: elem.Id, label: elem.Name };
    })
    if (!projectReqSetOptions) projectReqSetOptions = []
    if (!project && projectReqSetOptions.length === 1 && this.state.currentReqSets && this.state.currentReqSets.length === 0 && this.state.requirementSetsPristine) {
      this.setRequirementSetInput(projectReqSetOptions);
    }
    const holderOptions = Utils.getOptionsList(null, typeAheadResults, 'name', 'id', 'name');
    const countryOptions = this.props.actions.loadCountries(this.props)
    const stateOptions = this.props.actions.loadStates(this.props,this.state.selectedCountry)
    const accountManagerOptions = this.props && this.props.accountManagers ? Utils.getOptionsList('--Select Account Manager--', JSON.parse(this.props.accountManagers), 'label', 'value','') : [];
    // const reqSetOptions = Utils.getOptionsList('--Select Req. Set.--', requirementSetFilteredOptions, 'Name', 'Id', 'Name');

    const holder = _.get(this.props, 'currentForm.ProjectInfoForm.values.holderId', null);
    const customFields = addProjectCustomFields.length > 0 ? addProjectCustomFields.map((item, index) => {
      const fieldType = parseInt(item.FieldTypeId, 10);
      if (fieldType === 2) {
        return {
          name: `customField-${item.CustomFieldId}`,
          label: item.CustomFieldName,
          options: this.getCustomFieldOptions(item.FieldOptions)
        };
      } else {
        return {
          name: `customField-${item.CustomFieldId}`,
          label: item.CustomFieldName,
          type: fieldType === 3 ? 'number' : 'text'
        };
      }
    }) : {};

    const leftFields = [
      { name: 'name', label: labelProjectName, ph: '--project name--' },
      { name: 'CFProjectName2', label: labelProjectName2, ph: '--optional name--' },
      {
        name: 'holderId', label: labelHolder, ph: '--search holder--', type: 'typeAhead',
        handleSearch: this.searchHolder, fetching: typeAheadFetching, results: holderOptions,
        onSelect: this.onSelectHolder, error: typeAheadError, conditional: true, show: !holder
      },
      {
        name: 'holderId', label: labelHolder, type: 'removable',
        valueText: holder ? holder.label : '', onRemove: this.onRemoveHolder,
        disabled: fromHolderTab, conditional: true, show: holder,
      },
      { name: 'manager', label: labelAccountManager,options: accountManagerOptions, defaultValue: ''},
      { name: 'address1', label: labelAddress1, ph: '--address line 1--' },
      { name: 'address2', label: labelAddress2, ph: '--address line 2--' },
      { name: 'city', label: labelCity, ph: '--city--' },
      // { name: 'state', label: labelState, ph: '--State--' },
      { name: 'stateId', label: labelState, loadOptions: stateOptions, defaultValue: '' },
      { name: 'CFCountryId', label: labelCountry, loadOptions: countryOptions, defaultValue: '' },
      { name: 'zipCode', label: labelPostalCode, ph: '--postal code--' },
		]
    const leftFieldsLast = [
      { name: 'note', label: labelProjectNote, ph: '', type: 'textarea' },
      { name: 'aiWording', label: labelAiWording, ph: '', type: 'textarea' },
		]
		let rightFields = [];
		if(isEmpty(customFields)) {
			rightFields = [
				// { name:'statusId', label:labelStatus, options: statusOptions },
				{ name: 'CFContactName', label: labelContactName, ph: '--contact name--' },
        { name: 'CFContactPhone', label: labelContactPhone, ph: '--phone number--' },
        { name: 'CFProjectEmail', label: labelProjectEmail, ph: '--email address--' },
				{ name: 'number', label: labelProjectNumber, ph: '--project number--' },
				{ name: 'description', label: labelProjectDescription, ph: '', type: 'textarea' },
			];
		} else {
			rightFields = [
				// { name:'statusId', label:labelStatus, options: statusOptions },
				{ name: 'CFContactName', label: labelContactName, ph: '--contact name--' },
        { name: 'CFContactPhone', label: labelContactPhone, ph: '--phone number--' },
        { name: 'CFProjectEmail', label: labelProjectEmail, ph: '--email address--' },
				{ name: 'number', label: labelProjectNumber, ph: '--project number--' },
        { name: 'description', label: labelProjectDescription, ph: '', type: 'textarea' },
        ...customFields,
      ];
		}

    if (addProjectCustomFieldsFetching) {
      return (
        <div className="spinner-wrapper">
          <div className="spinner" />
        </div>
      );
    }
    

    return (
      <form
        onSubmit={handleSubmit}
        className="entity-info-form wiz-form"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              {leftFields.map(this.renderFormField)}
              {this.renderProjectReqSet(projectReqSetOptions)}
              {leftFieldsLast.map(this.renderFormField)}
            </div>
            <div className="col-md-6 col-sm-12">
              {rightFields.map(this.renderFormField)}
            </div>
          </div>
        </div>
        <div className="wiz-buttons">
          <div>
            <a className="wiz-cancel-button" onClick={this.props.close}>{cancel}</a>
            <button className="wiz-continue-btn bg-sky-blue-gradient bn">{saveButton}</button>
          </div>
        </div>
      </form>
    );
  }
};

ProjectInfoForm = reduxForm({
  form: 'ProjectInfoForm',
  validate,
  initialValues: {
    projectReqSet: '',
  }
  //asyncValidate,
  //asyncBlurFields: ['projectName'],
})(ProjectInfoForm);

const mapStateToProps = (state) => {
  return {
    currentForm: state.form,
    local: state.localization,
    holders: state.holders,
    projects: state.holdersProjects,
    common: state.common,
    holdersProfile: state.holdersProfile,
    holderRequirementSets: state.holderRequirementSets,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      ...bindActionCreators(applyActions, dispatch),
      ...loadApplyOptions
    },
    commonActions: bindActionCreators(commonActions, dispatch),
    projectsActions: bindActionCreators(projectsActions, dispatch),
    reqSetsActions: bindActionCreators(reqSetsActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectInfoForm);
