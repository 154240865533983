import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as applyActions from './actions';
import * as loginActions from '../../auth/login/actions';

import RegisterSidebar from './RegisterSidebar';
import RegisterMain from './RegisterMain';
import FullScreenReCAPTCHA from '../../common/recaptcha/full-screen-recaptcha'

import './register.css';

const SHOW_RECAPTCHA = process.env.REACT_APP_SHOW_RECAPTCHA === 'true';

const Alerts = require ('../../alerts');
const spinnerContainerStyle = {
  position:'fixed',
  top: '0',
  left: '0',
  backgroundColor:'#80808087',
  width: '100%',
  height: '100%'
};

const spinnerStyle = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};
class Apply extends React.Component {
  constructor(props) {
    super(props);

    let hc = null;
    this.state = {
      isParent: false,
      showReCAPTCHA: SHOW_RECAPTCHA,
    }

    // const qs = new URLSearchParams(this.props.location.search);
    let forcedHC =  window.location.href.split('apply/')[1] || ""
    // const forcedHC = qs.get('hcURI');
    if (forcedHC) {
      hc = forcedHC;
    } else {
      hc = window.location.href.match('^(?:https?:)?(?:\/\/)?([^\/\?]+)')[1];
    }

    if (!hc) this.props.history.push('/login');
    props.actions.checkHC({registrationUrl: hc}, (error, data) => {
      if(!error && data && data.length > 0) {
        let hcId = data[0].Id;
        let hcName = data[0].Name;
        if (hcId && data[0].AllowApplications) {
          if (data[0].entityType === 2) {
            // If we don't have any valid child redirect to the login
            if (data[0].availableForms === 0) 
            return this.props.history.push('/login');
            this.setState({isParent: true});
          }
          this.props.actions.setHiringClientId(hcId);
          this.props.actions.setHiringClientName(hcName);
        } else {
          return this.props.history.push('/login');
        }
      } else {
        return this.props.history.push('/login');
      }
    });
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.apply.hiringClientId !== this.props.apply.hiringClientId) {
      if (! nextProps.apply.hiringClientId) {
        this.props.history.push('/login');
      } else {
        this.props.actions.fetchApplyResources(nextProps.apply.hiringClientId);
        this.props.actions.setHiringClientId(nextProps.apply.hiringClientId);
        this.props.actions.setHiringClientName(nextProps.apply.hiringClientName);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.reviewApplications.reviewApplicationsSuccess &&
      this.props.reviewApplications.reviewApplicationsSuccess != prevProps.reviewApplications.reviewApplicationsSuccess) {
      Alerts.showInformationAlert(
        'Success',
        this.props.reviewApplications.reviewApplicationsSuccess,
        'Accept',
        false,
        () => {
          window.localStorage.removeItem("auth-token")
          // login the user automatically after registration
          const credentials = {
            username: this.props.reviewApplications.subcontractorEmail,
            password: this.props.reviewApplications.subcontractorPassword
          };
          this.props.loginActions.sendCredentials(
            credentials,
            this.props.history,
            '/profile'
          );

          this.props.history.replace('/login');
        }
      );
    }

    if (
      this.props.reviewApplications.reviewApplicationsError &&
      this.props.reviewApplications.reviewApplicationsError !=
        prevProps.reviewApplications.reviewApplicationsError
    ) {
      Alerts.showInformationAlert(
        "Error",
        this.props.reviewApplications.reviewApplicationsError,
        "Accept",
        false,
        () => {
          if (this.props.reviewApplications.applicationErrorCode === 10021 || this.props.reviewApplications.applicationErrorCode === 10156 || this.props.reviewApplications.applicationErrorCode === 10157){
            window.localStorage.removeItem("auth-token")
            this.props.history.replace("/login");
          }
        }
      );
    }
  }

  onReCAPTCHAVerify = () => {
    this.setState({
      showReCAPTCHA: false
    })
  }

  render() {
    // {this.props.register.userExists ? <RegisterSecondary /> : <RegisterMain />}

    if (this.state.showReCAPTCHA) {
      return <FullScreenReCAPTCHA onVerify={this.onReCAPTCHAVerify} />
    }

    return (
      <div className="container-fluid">
        <div className="row">
          <RegisterSidebar hcLogo={this.props.apply.hcLogo}/>
          <RegisterMain
            isParent={this.state.isParent}
            onSubmit={this.onSubmit}
          />
        </div>
        {this.props.reviewApplications.processingEndpoint && (
          <div style={spinnerContainerStyle}>
            <div className="spinner-wrapper" style={spinnerStyle}>
              <div className="spinner" />
            </div>
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    apply: state.apply,
    reviewApplications: state.reviewApplications
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(applyActions, dispatch),
    loginActions: bindActionCreators(loginActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Apply);
