import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import UploadManuallyTab from './uploadManuallyTab';
import UploadFileTab from './uploadFileTab';
import Spinner from '../../common/spinner';

import * as hcActions from '../../hiringclients/actions';
import * as scActions from '../actions';

import SelectHC from './selectHC';

import '../subcontractors.css';

const Alerts = require ('../../alerts');

class AddSCModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: _.get(this.props.login, 'profile.Role.IsPrequalRole') ? 1 : 2
    };
  };

  componentDidMount() {
    const { hcId } = this.props.match.params;

    this.props.scActions.fetchAddSCModalHiringclientsList(hcId);
  }

  componentWillUnmount() {
    // Reset Both forms data in case they were previously initialized
    this.props.scActions.setListOfSCManually([]);
    this.props.scActions.setListOfSCFromParsedFile([]);
  }

  componentWillReceiveProps (nextProps) {
    const sendErrorAlert = () => {
      Alerts.showInformationAlert(
        'Error',
        nextProps.sc.errorMsg,
        'Accept',
        false,
        ()=>{
          this.props.scActions.setSendingScListError(null)
        }
      );
    }
    if (nextProps.sc.errorMsg) {
      sendErrorAlert()
    }
    if(this.props.sc.sendingScList && !nextProps.sc.sendingScList){
      if(nextProps.sc.errorMsg) {
        sendErrorAlert()
      } else if(nextProps.sc.successMsg) {
        Alerts.showInformationAlert(
          'Success',
          nextProps.sc.successMsg,
          'Accept',
          false,
          ()=>{
            this.props.scActions.setListOfSCManually([{}]);
            this.props.scActions.setListOfSCFromParsedFile([]);
          }
        );
      }
    }

    // TODO: I'm pretty sure this needs to be removed
    // if (!this.props.forms && nextProps.forms) {
    //   let hcId = this.props.match.params
    //   this.props.scActions.fetchHCforms(hcId)
    // }
  }

  onChangeSelectHC = event => {
    const { hcFormsFetching } = this.props.sc;

    if (!hcFormsFetching) {
      const hcId = event.target.value;

      this.props.scActions.fetchHCforms(hcId)
      this.props.scActions.setHCforAddSCmodal(hcId)

      // Reset Both forms data
      this.props.scActions.setListOfSCManually([]);
      this.props.scActions.setListOfSCFromParsedFile([]);
    }
  };

  setTab1 = () => {
    this.setState({
      tab: 1
    });
  };

  setTab2 = () => {
    this.setState({
      tab: 2
    });
  };

  renderSelectors() {
    const {
      uploadFileLabel,
      uploadManuallyLabel
    } = this.props.local.strings.subcontractors.addSCModal;

    let selectors = null;

    if (_.get(this.props.login, 'profile.Role.IsPrequalRole')) {
      selectors = (
        <ul className="add-sc-tabs">
          <li>
            <a
              className={`addsc-tab-link ${this.state.tab === 1 ? 'active' : ''}`}
              onClick={this.setTab1} >
              {uploadFileLabel}
            </a>
          </li>
          <li>
            <a
              className={`addsc-tab-link ${this.state.tab === 2 ? 'active' : ''}`}
              onClick={this.setTab2} >
              {uploadManuallyLabel}
            </a>
          </li>
        </ul>
      )
    }

    return selectors;
  }

  render() {
    const {
      title,
      chooseHCLabel,
    } = this.props.local.strings.subcontractors.addSCModal;

    const {
      hcListForAddSCModal,
      hcListForAddSCModalFetching,
      hcListForAddSCModalError,
      hcIdForAddSCmodal,
      sendingScList,
      hcFormsFetching,
      hcFormsError,
    } = this.props.sc;

    if (hcListForAddSCModalFetching) return <Spinner />;

    if (hcListForAddSCModalError) {
      return <div>{hcListForAddSCModalError}</div>;
    }

    return (
      <div className="add-sc-modal">
        <header>
          <h1 className="add-sc-title">{title}</h1>

          <label
            className='choose-hc'
            htmlFor="chooseHC">
            {chooseHCLabel}
          </label>

          <SelectHC
            options={hcListForAddSCModal}
            selectedHcId={hcIdForAddSCmodal}
            onChangeHCSelect={this.onChangeSelectHC}
          />
        </header>

        {this.renderSelectors()}

        {(sendingScList || hcFormsFetching) ? <Spinner /> :
          (hcFormsError ? (
            <div>{hcFormsError}</div>
          ) : (
            <div className="addsc-tab-bodies add-item-view">
              <div className={`tab-body add-item-form-subsection tab-1 ${this.state.tab === 1 ? 'active' : ''}`}>
                <UploadFileTab close={this.props.close} />
              </div>

              <div className={`tab-body add-item-form-subsection tab-2 ${this.state.tab === 2 ? 'active' : ''}`}>
                <UploadManuallyTab close={this.props.close} />
              </div>
            </div>
        ))}
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    sc: state.sc,
    hc: state.hc,
    local: state.localization,
    login: state.login
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    scActions: bindActionCreators(scActions, dispatch),
    hcActions: bindActionCreators(hcActions, dispatch),
    dispatch
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddSCModal));
