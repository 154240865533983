import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import NotesTasks from '../sc-profile/tabs/notesTasks';
import CFTasks from "../certfocus/tasks";
import SystemSwitch from "../auth/systemSwitch";
import * as commonActions from '../common/actions';

class Tasks extends React.Component {
  componentDidMount() {
    this.props.commonActions.setBreadcrumbItems([]);
  }

  render() {
    return (
      <React.Fragment>
        <SystemSwitch />
        {this.renderContent()}
      </React.Fragment>
    );
  }

  renderContent() {
    if (this.props.login.currentSystem === 'cf') {
      return <CFTasks fromGlobalModule />
    } else {
      const userId = _.get(this.props.login, 'profile.Id');

      return userId && <NotesTasks fromSidebar userId={userId} />;
    }

  }
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
    local: state.localization
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tasks));