import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import Accordion from './../../../../common/accordion';

import * as actions from './../../actions/actions';
import * as commonActions from './../../../../common/actions';
import * as certificateActions from './../../../../certfocus/certificates/actions';
import RolAccess from "../../../rolAccess";
import DisplayTextWithConditionalModal from '../../../displayTextWithConditionalModal';
import { TEXT_DISPLAY_LIMIT, PROJECT_AI_WORDING } from '../../../../../lib/appConstants';

class DeficiencesAccepted extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show: ""
    }
  }

  dispatchUndo(id) {
    this.props.commonActions.setLoading(true);
    let projectInsuredID = this.props.certificatesList[0].ProjectInsuredID;
    this.props.actions.fetchUndoWaiverDeficiences(id, projectInsuredID, () => {
      this.props.certificateActions.fetchCertificates(projectInsuredID);
      this.props.actions.fetchDeficiences(projectInsuredID);
      this.props.certificateActions.fetchProjectInsured(projectInsuredID);
      this.props.commonActions.setLoading(false);
    });
  }

  renderSectionContent(tableBody, hasValue) {
    let component = hasValue ? (
      <table className="table" style={{ fontSize: 13 }}>
        <thead>
          <tr>
            <th scope="col">Coverage</th>
            <th scope="col">Expire Date</th>
            <th scope="col">Attribute</th>
            <th scope="col">Required</th>
            <th scope="col">Provided</th>
            <RolAccess
                masterTab='certificates_insurance'
                sectionTab='edit_certificate'
                component={() => this.renderDeficiencyActionsHeader}>
            </RolAccess>
          </tr>
        </thead>
        <tbody>
          {tableBody}
        </tbody>
      </table>
    ) : (<span className="d-flex justify-content-center align-items-center mt-2">No results</span>);
    return component;
  }

  renderDeficiencyActionsHeader = () => {
    return (
        <React.Fragment>
          <th scope="col ml-2"></th>
        </React.Fragment>
    )
  }

  renderDeficiencyUndo = (def, isArchived) => {
    return (
      <td>
        {def.ProjectInsuredDeficiencyID && !isArchived && (
          <a style={{ color: "blue", cursor: "pointer" }} onClick={() => this.dispatchUndo(def.ProjectInsuredDeficiencyID)}>Undo</a>
        )}
      </td>
    )
  }

  render() {
    const { isArchived, AIWording } = this.props;
    const deficiencesAcepted = !this.props.data ? [] : this.props.data.filter(deficiences => deficiences.DeficiencyStatusID == 1 && deficiences.DefTypeID != 3);
    const currentDate = new Date().getTime();

    const { conditionPossibleValues } = this.props.holderRequirementSets;

    const tableBody = deficiencesAcepted.map((def, index) => {
      let defDate = (def.ExpirationDate) ? new Date(def.ExpirationDate).getTime() : null;
      let hasExpired = (defDate && (currentDate < defDate)) ? false : true;
      const condition = conditionPossibleValues.find(el => el.value === def.ConditionTypeID);
      return (
        <tr key={index}>
          <td>{def.CoverageType}</td>
          <td>
            <span style={{ color: (hasExpired) ? '#F04C41' : '#8CC739' }}>
              {(def.CoverageStatusID !== 1 && def.ExpirationDate) ? moment.utc(def.ExpirationDate).format('MM/DD/YYYY') : ''}
            </span>
          </td>
          <td>
            <div>{def.AttributeName}</div>
            <div style={{ fontSize: 10, marginTop: 4 }}>{
              (def.AttributeName && def.AttributeName.toLowerCase().includes(PROJECT_AI_WORDING.toLowerCase())) ? AIWording : def.DeficiencyText
            }</div>
          </td>
          <td>{<DisplayTextWithConditionalModal string={def.ConditionValue} displayTextLimit={TEXT_DISPLAY_LIMIT.HUNDRED} />}</td>
          <td>{<DisplayTextWithConditionalModal string={def.AttributeValue} displayTextLimit={TEXT_DISPLAY_LIMIT.HUNDRED} />}</td>
          <RolAccess
              masterTab='certificates_insurance'
              sectionTab='edit_certificate'
              component={() => this.renderDeficiencyUndo(def, isArchived)}>
          </RolAccess>
        </tr>
      );
    });

    const title = <span className="accordionHeader d-flex justify-content-center deficiency-menu-item">
        <span className="mt-2">
          <h5>Accepted</h5>
        </span>
      </span>;

    const data = [
      {
        title: title,
        content: this.renderSectionContent(tableBody, deficiencesAcepted.length > 0),
        isShown: this.props.showAll
      }
    ]

    const headerStyle = {
      background: 'linear-gradient(to bottom right, #7ED0BC, #29AEA2)',
      color: '#FFFFFF',
      paddingTop: '5px',
      paddingBottom: '5px',
    };

    return (
      <div className="p-2" style={{ width: '100%' }}>
        <Accordion
          data={data}
          headerStyle={headerStyle}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    certificatesList: state.certificates.certificatesList,
    showAll: state.deficiences.showAll,
    holderRequirementSets: state.holderRequirementSets,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    certificateActions: bindActionCreators(certificateActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeficiencesAccepted);
