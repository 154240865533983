import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';

import RegistrationForm from './form';
import * as commonActions from '../../common/actions';
import * as registrationActions from './actions';

import {
  PROJECT_INSURED_NON_COMPLIANT_STATUS_ID
} from '../../../lib/appConstants';

import './registration.css';
class Registration extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
   
  }
  
  onSubmit = (values) => {
  //console.log('onSubmit', values);
    const payload = {
      holderId: values.registrationCode,
      insuredName: values.companyName,
      legalName: values.companyName,
      taxId: values.taxId,
      address1: values.address,
      city: values.city,
      stateId: values.stateId.value,
      postalCode: values.zipCode,
      countryId: values.countryId.value,
      contactName: values.contactName,
      contactPhone: values.phone,
      contactEmail: values.email,
      contactFax: values.fax,
      insuranceAgencyCompanyName: values.insuranceAgencyCompanyName,
      insuranceAgencyContactPerson: values.insuranceAgencyContactPerson,
      insuranceAgencyPhone: values.insuranceAgencyPhone,
      insuranceAgencyEmail: values.insuranceAgencyEmail,
      typeId: 3,
      connectToProject: (values.holderData) ? values.holderData.CFConnectToProject : null,
      selectedProject: (values.holderData) ? values.holderData.CFSelectedProject : null,
      selectedRequirementSet: (values.holderData) ? values.holderData.CFSelectedRequirementSet : null,
    };
    
    this.props.registrationActions.createInsured(payload, (error, insuredId) => {
      if (error) {
        Swal({
          type: 'error',
          title: 'Register',
          text: 'Error when trying to register an insured. Please try again.',
          onClose: () => {
          }
        });
      } else {
      //console.log('insuredId', insuredId);
        if (payload.connectToProject && payload.selectedProject && payload.selectedRequirementSet) {
          const projectInsuredPayload = {
            insuredId: insuredId,
            projectId: payload.selectedProject,
            requirementSetId: payload.selectedRequirementSet,
            complianceStatusId: PROJECT_INSURED_NON_COMPLIANT_STATUS_ID
          };
          this.props.registrationActions.createProjectInsured(projectInsuredPayload, (error, certUploadHash) => {
            if (error) {
              Swal({
                type: 'error',
                title: 'Register',
                text: 'Error when trying to connect to a project.',
                onClose: () => {
                }
              });
            } else { 
              // redirect to cert upload page
              if (certUploadHash) {
                const redirectUrl = '/certfocus/certUpload/' + certUploadHash;
                this.props.history.push(redirectUrl);
              } else {
                Swal({
                  type: 'error',
                  title: 'Register',
                  text: 'Error when trying to redirect to the certificate upload page.',
                  onClose: () => {
                  }
                });
              }            
            }          
          });

        } else {
          Swal({
            type: 'success',
            title: 'Registration',
            text: 'Thank you for registering, within 24 hours you will receive an email with further instructions',
            onClose: () => { 
              const redirectUrl = '/login';
              this.props.history.push(redirectUrl);             
            }
          });
        }             
      }
    });
  };

  render() {
    return (
      <div className="add-item-view add-entity-form-small registration-container">
        <div className="add-item-header ml-0 registration-title">
          <h1>Registration</h1>
        </div>
      
        <section className="white-section">
          <div className="filter-form-field-wrapper">
            <RegistrationForm
              onSubmit={this.onSubmit}
            />
          </div>        
        </section>
      </div>     
    );
  }
};

const mapStateToProps = (state) => {
  return {
    local: state.localization,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
    registrationActions: bindActionCreators(registrationActions, dispatch),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Registration));