// sets
export const SET_TEMPLATES_LIST_ERROR = 'SET_TEMPLATES_LIST_ERROR';
export const SET_TEMPLATES_LIST = 'SET_TEMPLATES_LIST';
export const SET_FETCHING_TEMPLATES = 'SET_FETCHING_TEMPLATES';
export const SET_TOTAL_AMOUNT_OF_TEMPLATES = 'SET_TOTAL_AMOUNT_OF_TEMPLATES';
export const SET_TEMPLATES_LENGTH = 'SET_TEMPLATES_LENGTH';
export const SET_CURRENT_TEMPLATE= 'SET_CURRENT_TEMPLATE';
export const SET_POSSIBLE_COMM_TYPES = 'SET_POSSIBLE_COMM_TYPES';
export const SET_POSSIBLE_ACTIVITIES = 'SET_POSSIBLE_ACTIVITIES';
export const SET_TEMPLATE_EDITOR_STATE = 'SET_TEMPLATE_EDITOR_STATE';
