import { createMuiTheme } from '@material-ui/core';

class vertikalTheme {

  static getMuiTheme = () => {
    return createMuiTheme({
      overrides: {
        MuiGrid: {
          'spacing-xs-4': {
            '& > $item': {
              padding: 5
            }
          },
        },
        MuiTableCell: {
          head: {
            color: '#085DAD',
            fontSize: 14,
            textTransform: 'uppercase',
            padding: 10,
            fontWeight: 700,
          },
        },
        MUIDataTableFilter: {
          gridListTile: {
            marginTop: 0,
            paddingTop: 4,
            paddingBottom: 4,
          },
        },
        MuiTypography: {
          h6: {
            color: '#2E5965',
            fontSize: 18,
            textTransform: 'uppercase',
            fontWeight: 700,
          }
        },
        MUIDataTableBodyCell: {
          root: {
            '& > a': {
              cursor: 'pointer',
              display: 'block',
              color: '#8CC739',
              '&:hover': {
                color: '#8CC739',
                textDecoration: 'underline',
              },
              '& > svg': {
                fontSize: 16,
                marginRight: 5
              }
            },

          }
        },
        MUIDataTableSelectCell: {
          expandDisabled: {
            // Soft hide the button.
            visibility: 'hidden',
          },
        },
      },
    });
  }
}

export default vertikalTheme;