import Api from '../../../../lib/api';
import Utils from '../../../../lib/utils';

import * as types from './types';

// SETS
export const setProjectInsuredsListError = (error) => {
  return {
    type: types.SET_PROJECT_INSUREDS_LIST_ERROR,
    payload: error
  };
};
export const setProjectInsuredsList = (list, totalAmount) => {
  return {
    type: types.SET_PROJECT_INSUREDS_LIST,
    payload: {
      list,
      totalAmount,
    }
  };
};
export const setFetchingProjectInsureds = () => {
  return {
    type: types.SET_FETCHING_PROJECT_INSUREDS
  };
};


// FETCH
export const fetchProjectInsureds = (queryParams) => {
  return (dispatch, getState) => {
    const { login, localization } = getState();
    const { errorDefault, errorConnection } = localization.strings.projectInsureds.errors;
    const { authToken } = login;

    dispatch(setFetchingProjectInsureds());

    queryParams.getTotal = true;
    queryParams.archived = 0;
    
    let urlParameters = `?${Object.keys(queryParams).map(i => `${i}=${queryParams[i]}`).join('&')}`;
    const urlQuery = `cf/projectInsureds`;
    return Api.get(`${urlQuery}${urlParameters}`, authToken)
      .then(response => {
        const { success, projectInsureds, totalCount, totalProjectNonArchived } = response.data;
        if (success) {
          dispatch(setProjectInsuredsList(projectInsureds || [], totalCount || 0, totalProjectNonArchived || 0));
        }
        else {
          dispatch(setProjectInsuredsListError(errorDefault));
        }
      })
      .catch(error => {
        dispatch(setProjectInsuredsListError(errorConnection));
      });
  };
};

// COPY
export const copyCertificate = (payload, callback) => {
  return (dispatch, getState) => {
    const { login, localization } = getState();
    const { errorDefault, errorConnection } = localization.strings.projectInsureds.errors;
    const { authToken } = login;

    const urlQuery = `cf/certificates/copy`;
    return Api.post(`${urlQuery}`, payload, authToken)
      .then(response => {
        const { success, data } = response.data;
        if (success) {
          callback(null, data);
        }
        else {
          callback(errorDefault);
        }
      })
      .catch(error => {
        callback(errorConnection);
      });
  };
};