export const SET_FETCHING_FILES = 'SET_FETCHING_FILES';
export const SET_DOWNLOADING_FILE = 'SET_DOWNLOADING_FILE';
export const SET_DOWNLOADING_MULTIPLE_FILES = 'SET_DOWNLOADING_MULTIPLE_FILES';
export const SET_ERROR_DOWNLOADING_MULTIPLE_FILES = 'SET_ERROR_DOWNLOADING_MULTIPLE_FILES';
export const SET_FILES = 'SET_FILES';
export const SET_CONTRACTS_ERROR = 'SET_CONTRACTS_ERROR';
export const SET_TOTAL_AMOUNT_OF_FILES = 'SET_TOTAL_AMOUNT_OF_FILES';
export const SET_SAVING_FILE = 'SET_SAVING_FILE';
export const SET_DELETING_FILE = 'SET_DELETING_FILE';
export const DELETE_FILE = 'DELETE_FILE';
export const SET_FILE_URL = 'SET_FILE_URL';

export const SET_SAVING_MULTIPLE_FILES = 'SET_SAVING_MULTIPLE_FILES';
export const SET_SAVING_MULTIPLE_FILES_ERROR = 'SET_SAVING_MULTIPLE_FILES_ERROR';
export const SET_SAVING_MULTIPLE_FILES_SUCCESS = 'SET_SAVING_MULTIPLE_FILES_SUCCESS';
export const SET_HC_FILEEXPIRATION_TEMPLATE = 'SET_HC_FILEEXPIRATION_TEMPLATE';
export const HC_FILEEXPIRATION_WORKFLOW_ENABLED = 'HC_FILEEXPIRATION_WORKFLOW_ENABLED';
export const SET_PAGE_SIZE = 'SET_FILES_PAGE_SIZE';
