import React from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Exago from '../exago';
import CFExago from '../certfocus/exago';
import * as localActions from '../../localization/actions';
import * as commonActions from '../common/actions';

class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    const printReport = props.match.params.printReport;
    this.state = {
      printReport: printReport ? true : false
    }
    this.props.commonActions.setBreadcrumbItems([]);
  }

  changeLang = () => {
    this.props.actions.setLanguage('zeros');
  }

  render() {
    const { profile } = this.props.login;    
    let showExago = false;
    let showCFExago = false;
    
    if (profile.Role) {
      showExago = profile.ReportingRole && profile.ReportingRole !== '';
    }
    // enable CFExago reports
    if (profile.CFRole) {
      showCFExago = true;
    }

    return (
      <div className="dashboard">
        {
          showExago && !showCFExago
            ? <Exago
                printReport={this.state.printReport} 
                userProfile={this.props.login.profile} 
              />
            : null
        }
        {
          showCFExago
            ? <CFExago
                printReport={this.state.printReport} 
                userProfile={this.props.login.profile} 
              />
            : null
        }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    local: state.localization,
    login: state.login
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(localActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
