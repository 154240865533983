const validate = (values, props) => {
  const { requiredValidation } = props.local.strings.financialInfo.copySubmissionModal;

  const errors = {};

  if (!values.hiringClientId) {
    errors.hiringClientId = requiredValidation;
  }

  return errors;
};

export default validate;
