export const SET_ADD_REVIEW_APPLICATIONS_SUCCESS = 'SET_ADD_REVIEW_APPLICATIONS_SUCCESS';
export const SET_ADD_REVIEW_APPLICATIONS_ERROR = 'SET_ADD_REVIEW_APPLICATIONS_ERROR';
export const SET_FETCHING_REVIEW_APPLICATIONS = 'SET_FETCHING_REVIEW_APPLICATIONS';
export const SET_REVIEW_APPLICATIONS_LIST = 'SET_REVIEW_APPLICATIONS_LIST';
export const SET_REVIEW_APPLICATIONS_LIST_ERROR = 'SET_REVIEW_APPLICATIONS_LIST_ERROR';       
export const SET_REVIEW_APPLICATIONS_HIRING_CLIENT = 'SET_REVIEW_APPLICATIONS_HIRING_CLIENT';
export const SET_REVIEW_APPLICATIONS_HIRING_CLIENT_ERROR = 'SET_REVIEW_APPLICATIONS_HIRING_CLIENT_ERROR';
export const SET_APPROVE_APPLICATIONS = 'SET_APPROVE_APPLICATIONS';
export const SET_DECLINE_APPLICATIONS = 'SET_DECLINE_APPLICATIONS';
export const SET_APPLICATIONS_ERROR = 'SET_APPLICATIONS_ERROR';
export const SET_FETCHING_HC_BY_REGISTRATION_URL = 'SET_FETCHING_HC_BY_REGISTRATION_URL';
export const SET_SHOW_ANSWERS_MODAL = 'SET_SHOW_ANSWERS_MODAL';
export const SET_REVIEW_APPLICATIONS_SUBCONTRACTOR = 'SET_REVIEW_APPLICATIONS_SUBCONTRACTOR';
export const SET_PROCESSING_ADD_REVIEW_APPLICATION = 'SET_PROCESSING_ADD_REVIEW_APPLICATION';
