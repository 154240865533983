import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import renderField from '../../../../customInputs/renderField';
import renderSelect from '../../../../customInputs/renderSelect';
import renderRemovable from '../../../../customInputs/renderRemovable';
import renderTypeAhead from '../../../../customInputs/renderTypeAhead';
import Swal from 'sweetalert2';

import * as commonActions from '../../../../common/actions';
import * as holderActions from '../../../holders/actions';

import validate from './validation';

class CopyHolderForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkboxes: [],
    }
  }

  // componentDidMount() {
  //   const { profile } = this.props;
  //   this.props.holderActions.fetchHolderChildren(profile.id);    
  // }

  renderFormField = (element, idx) => {
    const { type, name, label, ph, options, conditional, show, defaultValue, checked, disabled } = element;
    const fieldType = type || 'text';
    const style = {};
    if (conditional && !show) {
      style.display = 'none';
    }

    if (fieldType === 'typeAhead') {
      const { fetching, results, error, handleSearch } = element;

      return (
        <div key={idx} className="wiz-field admin-form-field-wrapper" style={style}>
          <label htmlFor={name}>{`${label}:`}</label>
          <Field
            resetOnClick
            name={name}
            placeholder={ph}
            fetching={fetching}
            results={results}
            handleSearch={handleSearch}
            fetchError={error}
            component={renderTypeAhead}
          />
        </div>
      );
    } else if (fieldType === 'removable') {
      const { valueText, disabled } = element;
      return (
        <div key={idx} className="wiz-field admin-form-field-wrapper" style={style}>
          <label htmlFor={name}>{`${label}:`}</label>
          <Field
            name={name}
            valueText={valueText}
            component={renderRemovable}
            disabled={disabled}
          />
        </div>
      );
    }

    return (
      <div key={idx} className="admin-form-field-wrapper" style={style}>
        <label htmlFor={name}>{`${label}:`}</label>
        {
          options ? (
            <div className="select-wrapper">
              <Field
                name={name}
                component={renderSelect}
                options={options} 
                defaultValue={defaultValue}
                disabled={disabled}
              />
            </div>
          ) : ((type === 'checkbox') ? (
              <Field
                name={name}
                type={fieldType}
                placeholder={ph}
                component={renderField}
                checked={checked}    
                disabled={disabled}        
              />
            ) : (
              <Field
                name={name}
                type={fieldType}
                placeholder={ph}
                component={renderField}
                checked={true}            
              />
            ) 
          )  
        }
      </div>
    );
  }

  renderHolderChildren = () => {
    const { holderChildren } = this.props.holders;
    const {
      copyColumn,
      holderNameColumn,
      optionsColumn
    } = this.props.local.strings.holders.copyHolders;

    return (
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>{copyColumn}</th>
            <th>{holderNameColumn}</th>
            <th>{optionsColumn}</th>
          </tr>
        </thead>
        <tbody>
        {holderChildren && holderChildren.length > 0 && (
          holderChildren.map((child, idx) => {
            const isHolderPresent =this.state.checkboxes.find(item => item.holderId === child.Id);
            return (
              <tr key={child.Id}>
                <td style={{ paddingTop: '2px', textAlign: '-webkit-center' }}>
                  <div className="custom-control custom-checkbox">
                    <input 
                      type="checkbox" 
                      className="custom-control-input" 
                      id={`customCheck_${idx}`}
                      onChange={(e) => this.handleChange(e, child.Id)} 
                    />
                    <label className="custom-control-label" htmlFor={`customCheck_${idx}`}></label>
                  </div>
                </td>
                <td>{child.Name}</td>
                <td>
                  <div
                    className="custom-checkbox-button"
                  >
                    <div className="custom-control custom-checkbox" >
                      <input 
                        onChange={(e) => this.handleDefaultReqSetChange(e, child.Id)}
                        disabled={!isHolderPresent || !isHolderPresent.holderId}
                        checked={ isHolderPresent && isHolderPresent.defaultReqSets}
                        type="checkbox" 
                        className="custom-control-input"
                        id={`defaultReqSets_${idx}`}
                      />
                      <label
                        htmlFor={`defaultReqSets_${idx}`} 
                        className={`custom-control-label ${ isHolderPresent && isHolderPresent.holderId ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                      />
                    </div>
                    <label
                      htmlFor={`defaultReqSets_${idx}`}
                      className={`${ isHolderPresent && isHolderPresent.holderId ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                    >
                      Default ReqSets
                    </label>
                  </div>
                </td>
              </tr>  
              );
            })
          )}
          </tbody>
        </table>
    );
  }

  handleChange = (e, holderId) => {
    const { checked } = e.target;
    if (!checked) {
      let filteredArray = this.state.checkboxes.filter(item => item.holderId !== holderId);
      this.setState({ checkboxes: filteredArray });
    } else {
      const newObj = { holderId, defaultReqSets: false};
      this.setState({
        checkboxes: this.state.checkboxes.concat([newObj]),
      });
    }
  };

  handleDefaultReqSetChange = (e, holderId) => {
    const { checked } = e.target;
    this.setState(prev => {
      const updatedCheckboxes = prev.checkboxes.map(item => 
        item.holderId === holderId
          ? { ...item, defaultReqSets: checked }
          : item
      );
  
      return { checkboxes: updatedCheckboxes };
    });
  };
  

  copyToSelected = () => {
    const {
      copyHolderMessage,
    } = this.props.local.strings.holders.copyHolders;
    const { checkboxes } = this.state;
    if (checkboxes.length > 0) {
      Swal({
        title: `Copy to Holders`,
        text: copyHolderMessage,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2E5965',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
      }).then((result) => {
        if (result.value) {
          this.props.commonActions.setLoading(true);
          const payload = {
            selectedHolders: this.state.checkboxes,
            holderProfile: this.props.profile,
          }
          this.props.holderActions.copyToHolders(payload, (error, data) => {
            this.props.commonActions.setLoading(false);
            if (!error) {              
              const textMessage = (data.updated.length > 0) 
                ? `${data.updated.length} holders were updated successfully.`
                : `No holders were updated. Please check your data.`

              Swal({
                type: 'success',
                title: 'Copy to Holders',
                text: textMessage,
                onClose: () => this.closeCopyHoldersModal()
              });
              this.setState({ checkboxes: [] });
            }
          });
        }else {
          this.setState({ checkboxes: [] });
        }
      });
    }
  }

  copyToAll = () => {
    const { holderChildren } = this.props.holders;
    const selectedCheckboxes = holderChildren.map(item => ({
      holderId: item.Id,
      defaultReqSets: false
    }));
    this.setState({ checkboxes: selectedCheckboxes });    
    
    Swal({
      title: `Copy to Holders`,
      text: `Are you sure you want to copy to all holders?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2E5965',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        this.props.commonActions.setLoading(true);
        const payload = {
          selectedHolders: this.state.checkboxes,
          holderProfile: this.props.profile,
        }
        this.props.holderActions.copyToHolders(payload, (error, data) => {
          this.props.commonActions.setLoading(false);
          if (!error) {
            const textMessage = (data.updated.length > 0) 
              ? `${data.updated.length} holders were updated successfully.`
              : `No holders were updated. Please check your data.`

            Swal({
              type: 'success',
              title: 'Copy to Holders',
              text: textMessage,
              onClose: () => this.closeCopyHoldersModal()
            });
            this.setState({ checkboxes: [] });  
          }
        });
      } else {
        this.setState({ checkboxes: [] });
      }
    });
  }

  closeCopyHoldersModal = () => {
    this.props.close();
  }

  render() {    
    const { 
      handleSubmit,
    } = this.props;
    
    const {
      copyToAllBtn,
      copyToSelectedBtn
    } = this.props.local.strings.holders.copyHolders;

    return (
      <form onSubmit={handleSubmit} className="entity-info-form">
        <div className="container-fluid" style={{ padding: 0 }}>
          <div className="copy-buttons-container">
            <div>
              <button
                onClick={() => this.copyToSelected()}
                className={`bn bn-small copy-to-selected-btn`}
                disabled={this.state.checkboxes.length === 0}
              >{copyToSelectedBtn}
              </button>
            </div>
            <div>
              <button
                onClick={() => this.copyToAll()}
                className={`bn bn-small copy-to-all-btn`}
              >{copyToAllBtn}
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            {this.renderHolderChildren()}
          </div>
        </div>
        { this.props.holders.errorCopyHolder &&
          <div className="error-item-form">
            { this.props.holders.errorCopyHolder }
          </div>
        }
      </form>
    );
  }
};

CopyHolderForm = reduxForm({
  form: 'CopyHolderForm',
  validate
})(CopyHolderForm);

const mapStateToProps = (state) => {
  return {
    currentForm: state.form,
    local: state.localization,
    login: state.login,
    common: state.common,
    holders: state.holders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
    holderActions: bindActionCreators(holderActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CopyHolderForm);