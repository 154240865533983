import Api from '../../../../../lib/api';
import { NOTES_TASK_FILTERS } from '../../../../../lib/FilterTags';

export const saveFilterApi = (payload, filterBoxTag) => {
    return Api.post('defaultFilters', {
        filterBoxTag,
        payload,
    });
};

export const fetchFilterApi = (filterBoxTag) => {
    return Api.get(`defaultFilters/${filterBoxTag}`);
};

export const deleteFilterApi = (filterBoxTag) => {
    return Api.delete(`defaultFilters/${filterBoxTag}`);
};
