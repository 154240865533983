import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import ChangeTradesForm from './changeTradesForm';
import Spinner from '../../../common/spinner';

import * as scProfileActions from '../../actions';

import './changeTradesModal.css';

class ChangeTradesModal extends Component {
  componentDidMount() {
    const { scProfileActions, hcId } = this.props;

    scProfileActions.setUpdateTradesError('');
    scProfileActions.fetchSCModalTradesList({ hiringClientId: hcId });
  }

  onSave = (data) => {
    const { hcId, scId, scProfileActions, forChangeHC } = this.props;
    const payload = {
      trades: {
        tradeId: (isNaN(data.tradeId) ? data.tradeId.value : data.tradeId) || 0,
        secondaryTradeId: (isNaN(data.secondaryTradeId) ? data.secondaryTradeId.value : data.secondaryTradeId) || 0,
        tertiaryTradeId: (isNaN(data.tertiaryTradeId) ? data.tertiaryTradeId.value : data.tertiaryTradeId) || 0,
        quaternaryTradeId: (isNaN(data.quaternaryTradeId) ? data.quaternaryTradeId.value : data.quaternaryTradeId) || 0,
        quinaryTradeId: (isNaN(data.quinaryTradeId) ? data.quinaryTradeId.value : data.quinaryTradeId) || 0
      },
      hiringClientId: hcId,
      subcontractorId: scId,
    };
    if(forChangeHC) {
      this.props.successCallback(payload);
    } else {
      scProfileActions.changeSubcontractorTrades(payload, success => {
        if (success) {
          this.props.successCallback(this.props.close);
        }
      });
    }
  }

  renderModalContent = () => {
    const { tradesListForChangeModalFetching, tradesListForChangeModalError, tradesListForChangeModal } = this.props.scProfile;
    const { selectedTrades } = this.props;
    let tradeListForModal = [{"label": "--- Please Select ---", "value": null}, ...tradesListForChangeModal]

    const selectedTradesValues = _.mapValues(selectedTrades, (trade) => trade ? trade.id : 0);

    if (tradesListForChangeModalFetching) return <Spinner />;
    if (tradesListForChangeModalError) return (
      <div className="text-center mb-2 text-danger">
        {tradesListForChangeModalError}
      </div>
    );

    return (
      <ChangeTradesForm
        initialValues={selectedTradesValues}
        close={this.props.close}
        onSubmit={this.onSave}
        tradeOptions={tradeListForModal}
        message={this.props.message}
      />
    );
  }

  render() {
    const { title } = this.props.local.strings.scProfile.changeTradesModal;

    return (
      <div className="sc-profile-change-trades-modal">
        <header className="sc-profile-change-trades-modal-header">
          {title}
        </header>

        {this.renderModalContent()}
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  local: state.localization,
  scProfile: state.SCProfile,
});

const mapDispatchToProps = (dispatch) => {
  return {
    scProfileActions: bindActionCreators(scProfileActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeTradesModal);
