import React , {useEffect, useState} from 'react';
import { Field, reduxForm, touch, change, reset} from 'redux-form';
import { connect } from 'react-redux';
import renderField from '../../../customInputs/renderField';
import FilterActions from '../../../common/filterActions/FilterActions'
import renderSelect from '../../../customInputs/renderSelect';
import Utils from '../../../../lib/utils';
import {AsyncPaginate} from 'react-select-async-paginate';
import { bindActionCreators } from 'redux';
import * as loadApplyOptions from '../../../common/countyDropdownActions/loadOptions'
import * as applyActions from '../../../common/countyDropdownActions/actions'
import _ from 'lodash';
import {Modal} from "react-bootstrap";
import UpdateCustomFieldsModal from "../../modals/updateCustomFieldsModal";
import * as filterActions from '../../filterbox/actions'
import * as actions from "../actions";



const SelectAsyncOptions = ({ input, meta, dispatch, ...props }) => {
  const touchedError = meta.touched && meta.error;
  const name = input.name;
  const baseClassName = `${props.className || ''} select-${name}`;
  const errorClassName = `${touchedError ? `${baseClassName}-error` : ''}`;

  return (
    <React.Fragment>
      <AsyncPaginate
        {...input}
        {...props}
        openMenuOnClick={true}
        closeMenuOnScroll={true}
        cacheOptions={true}
        pageSize={10}
        additional={{ page: 1 }}
        classNamePrefix="vk"
        className={`${baseClassName} ${errorClassName}`}
        menuPlacement="auto"
        onFocus={(_) => { }}
        onBlur={() => meta.dispatch(touch('FilterProjects', name))}
        onChange={(newValue) => input.onChange(newValue)}
        captureMenuScroll={true}
        closeMenuOnSelect={true}
        debounceTimeout={300}
        loadOptionsOnMenuOpen={true}
      />
      {touchedError && (
        <span className="select-error-message field-validation-message">
          {meta.error}
        </span>
      )}
    </React.Fragment>
  );
};

let FilterProjects = props => {

  const {
    title,
    projectLabel,
    stateLabel,
    holderLabel,
    myListLabel,
    keywordsLabel,
    archivedLabel,
  } = props.local.strings.certFocusProjects.projectsList.filter;

  const { handleSubmit, fromHolderTab, holderId } = props;
  const [selectedCountry,setselectedCountry] = useState(null)
  const [showModal,setShowModal] = useState(false)

  useEffect(() => {
    if (props.isProjectOnly) {
      fetchFilters();
    }
  }, []);

  const fetchFilters = () => {
    if (props.form === 'FilterProjects') {
      let query = Utils.getFetchQuery("name", 1, "ASC", 10);
      if (props.isProjectOnly) {
        let data = { tab: 'project' }
        data.module = 'GlobalProjects';
        data.objectId = null;
        props.filterActions.fetchDefaultFilters(data, 'FilterProjects', (data) => {
          if (data && typeof data == 'object') {
            query = Utils.addSearchFiltersToQuery(query, data);
          }
          if (!query.archived) {
            query.archived = 0;
          }
          props.projectActions.fetchProjects(query);
        });
      } else {
        if (!query.archived) {
          query.archived = 0;
        }
        if (!query.holderId && props.holderId) {
          query.holderId = props.holderId;
        }
        if (!query.insuredId && props.insuredId) {
          query.insuredId = props.insuredId;
        }
        props.projectActions.fetchProjects(query);
      }
    }
  }
  const filtersResetCallback = async(values) => {
    values.preventDefault()
    props.dispatch(reset(props.formName));
    if (props.isProjectOnly) {
      let saveFilter = { tab: 'project' }
      saveFilter.module = 'GlobalProjects';
      saveFilter.objectId = null;
      saveFilter.filterBox = '';
      await props.filterActions.saveDefaultFilters('FilterProjects', saveFilter);
      props.refreshLocalState();
    }
    fetchFilters()
  }
  const stateOptions = props.actions.loadStates(props,selectedCountry)

  const myListOptions = [
    {
      label: 'N/A',
      value: ''
    },
    {
      label: 'Only on my list',
      value: '1'
    },
    {
      label: 'Not on my list',
      value: '2'
    }
  ];

  const archivedOptions = [
    { label: 'N/A', value: '' },
    { label: 'True', value: 1 },
    { label: 'False', value: 0, selected: true },
  ];

  const resetStateValue = (value) => {
    props.dispatch(change(
      'FilterProjects',
      'country',
      {value:value.value, label:value.label}
    ));
    if(selectedCountry != value.value){
      props.dispatch(change(
        'FilterProjects',
        'stateId',
        {value:null, label:''}
      ));
    }
    setselectedCountry(value.value)
  };
  const countryId = _.get(props, 'currentForm.FilterProjects.values.countryId.value', null);

  useEffect(() => {
    setselectedCountry(countryId)
  }, [countryId])

  const closeModal = () => {
    setShowModal(false)
  }

  return (
      <>
        <form onSubmit={handleSubmit} className="list-view-filter-form">
          <div className="project-header">
            <h2 className="list-view-filter-title">{title}</h2>
            <div className="list-item">
              <button
                className="nav-btn nav-bn filter-button"
                type="button"
                onClick={() => setShowModal(true)}
              >
                Update Custom Fields
              </button>
            </div>
          </div>
          <div className="container-fluid filter-fields">
            <div className="row">

              <div className="col-md no-padd">
                <div className="admin-form-field-wrapper keywords-field">
                  <label htmlFor="searchTerm">{keywordsLabel}: </label>
                  <Field
                      name="searchTerm"
                      type="text"
                      placeholder={keywordsLabel}
                      component={renderField}
                      className="tags-input"
                  />
                </div>
              </div>

              <div className="col-md no-padd">
                <div className="admin-form-field-wrapper keywords-field">
                  <label htmlFor="name">{projectLabel}: </label>
                  <Field
                      name="name"
                      placeholder={projectLabel}
                      component={renderField}
                      className="tags-input"
                  />
                </div>
              </div>

              <div className="col-md no-padd">
                <div className="admin-form-field-wrapper keywords-field">
                  <label htmlFor="countryId">Country</label>
                  <Field
                      name="countryId"
                      component={SelectAsyncOptions}
                      placeholder='Select Country'
                      onChange={(values)=>resetStateValue(values)}
                      loadOptions={props.actions.loadCountries(props)}
                      className="tags-input"
                  />
                </div>
              </div>

              {countryId ?
                  <div className="col-md no-padd">
                    <div className="admin-form-field-wrapper keywords-field">
                      <label htmlFor="stateId">State</label>
                      <Field
                          name="stateId"
                          component={SelectAsyncOptions}
                          placeholder='Select State'
                          cacheUniqs={[selectedCountry]}
                          loadOptions={stateOptions}
                          className="tags-input"
                      />
                    </div>
                  </div>
                  :null}

              <div className="col-md no-padd">
                <div className="admin-form-field-wrapper keywords-field">
                  <label htmlFor="holderName">{holderLabel}: </label>
                  <Field
                      name="holderName"
                      type="text"
                      placeholder={holderLabel}
                      component={renderField}
                      className="tags-input"
                  />
                </div>
              </div>

              <div className="col-md no-padd">
                <div className="admin-form-field-wrapper keywords-field">
                  <label htmlFor="mylist">{myListLabel}: </label>
                  <div className="select-wrapper">
                    <Field
                        name="mylist"
                        placeholder={myListLabel}
                        component={renderSelect}
                        options={myListOptions}
                        className="tags-input"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md no-padd">
                <div className="admin-form-field-wrapper keywords-field">
                  <label htmlFor="archived">{archivedLabel}: </label>
                  <div className="select-wrapper">
                    <Field
                        name="archived"
                        component={renderSelect}
                        options={archivedOptions}
                    />
                  </div>
                </div>
              </div>

            </div>

            <div className="row">
              <div className="col-md-12 d-flex justify-content-end">
                <FilterActions
                    formName={props.form}
                    dispatch={props.dispatch}
                    filtersResetCallback={filtersResetCallback}
                />
              </div>
            </div>

          </div>
        </form>
        <Modal
            show={showModal}
            onHide={closeModal}
            className="add-item-modal add-entity-small"
        >
          <Modal.Body className="add-item-modal-body mt-0">
            <UpdateCustomFieldsModal
              onHide={closeModal}
              holderId={holderId}
            />
          </Modal.Body>
        </Modal>
      </>

  );
}

FilterProjects = reduxForm({
  form: 'FilterProjects',
  initialValues:{
    archived:0
  },
  enableReinitialize : true,
})(FilterProjects);

const mapStateToProps = (state) => {
  const { filterbox } = state.filterBox;
  let initialValues = { archived: 0 }
  if (filterbox && filterbox.FilterProjects) {
    initialValues = {
      ...filterbox.FilterProjects,
      name: filterbox.FilterProjects.projectName,
      mylist: filterbox.FilterProjects.myList,
      countryId: { value: filterbox.FilterProjects.CFCountryId, label: filterbox.FilterProjects.CFCountryLabel },
      stateId: { value: filterbox.FilterProjects.stateId, label: filterbox.FilterProjects.stateLabel }
    }
  }
  return {
    register: state.register,
    local: state.localization,
    common: state.common,
    currentForm: state.form,
    initialValues: initialValues
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      ...bindActionCreators(applyActions, dispatch),
      ...loadApplyOptions
    },
    filterActions: bindActionCreators(filterActions, dispatch),
    projectActions: bindActionCreators(actions, dispatch)
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(FilterProjects);
