import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import ProjectInsureds from '../../project-insureds';
import Attachments from '../../attachments';
import CertText from './cert-text';
import Requirements from './requirements';
import ProjectUsers from '../../project-users';
import Users from "../../../users";
import Tasks from "../../../certfocus/tasks";
import RolAccess from "../../../common/rolAccess";
import RequirementSets from "../../requirement-sets";
// import Users from '../../../users';

class Tabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: (props.reqSetId)? 1 : 0,
    };
  }

  setCurrentTab = (e, i) => {
    e.preventDefault();
    this.setState({
      currentTab: i
    });
  };

  renderTab = (tab, idx) => {
    return (
        <li className="tab-item" key={idx}>
          <a
              className={`tab-button ${this.state.currentTab === idx ? 'active' : ''}`}
              onClick={e => this.setCurrentTab(e, idx)}>{tab.tabLabel}</a>
        </li>
    )
  }

  renderTabContent = (tab, idx) => {
    return (
        <div
            className={`tab-pane ${this.state.currentTab === idx ? 'active' : ''}`}
            key={idx} >
          { tab.content }
        </div>
    );
  }

  render() {
    const { projectId } = this.props.match.params;
    const { holderId, projectHolderName, projectName, projectArchived } = this.props;
    const {
      insureds,
      requirements,
      users,
      attachments
    } = this.props.local.strings.certFocusProjects.projectView.tabs;

    const tabs = [
      {
        tabLabel: insureds,
        masterTab: 'insured',
        sectionTab: 'view_tab',
        content: <ProjectInsureds
                  showColumnInsuredView={false} 
                  projectId={projectId} 
                  projectArchived={projectArchived}
                  holderId={this.props.holderId}
                />
      },
      {
        tabLabel: requirements,
        masterTab: 'requirements',
        sectionTab: 'view_tab',
        content: <RequirementSets
                  projectId={projectId}
                  projectArchived={projectArchived}
                  holderId={this.props.holderId}
                  fromProjectTab
                />
      },
      {
        tabLabel: users,
        masterTab: 'project_users',
        sectionTab: 'view_tab',
        content: <ProjectUsers
                  projectId={projectId}
                  projectArchived={projectArchived}
                />
      },
      {
        tabLabel: attachments,
        masterTab: 'project_attachments',
        sectionTab: 'view_tab',
        content: <Attachments
                  projectId={projectId} 
                  projectArchived={projectArchived}
                  holderId={this.props.holderId}
                />
      },
      {
        tabLabel: 'tasks',
        masterTab: 'tasks',
        sectionTab: 'view_tab',
        content: <Tasks
                    fromProject
                    projectId={projectId}
                    holderId= {holderId}
                    projectName= {projectName}
                    holderName={projectHolderName}
                    projectArchived={projectArchived}
                  />
      },
    ];

    let renderedTabs = [];
    if (this.props.login.profile.CFRole) {
      const { CFRole } = this.props.login.profile;

      if (!CFRole) {
        return <Redirect push to="/profile" />;
      } else {
        renderedTabs = [ ...tabs ];
      }
    }

    return (
      <div className="tab-frame">
        <ul className="profile-tabs nav nav-tabs">
          {
            renderedTabs.map((tab,idx) => {
              return (
                  <RolAccess
                      key={tab.tabLabel+'-tab'}
                      masterTab={tab.masterTab}
                      sectionTab={tab.sectionTab}
                      component={() => this.renderTab(tab,idx)}>
                  </RolAccess>
              );
            })
          }
        </ul>
        <div className="tab-content">
          {
            renderedTabs.map((tab,idx) => {
              return (
                  <RolAccess
                      key={tab.tabLabel+'-content'}
                      masterTab={tab.masterTab}
                      sectionTab={tab.sectionTab}
                      component={() => this.renderTabContent(tab,idx)}>
                  </RolAccess>
              );
            })
          }
        </div>
      </div>

    );
  }
};

const mapStateToProps = (state) => {
  return {
    local: state.localization,
    login: state.login,
  };
};

export default withRouter(connect(mapStateToProps)(Tabs));
