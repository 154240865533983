import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";

import validate from './validate';
import renderField from "../../../../customInputs/renderField";

class EditWaivedForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dueDate: null,
    }
  }

  render() {
    const {handleSubmit, onHide, data, locale} = this.props;
    if (data) {
      const {coverageName,attributeName,conditionValue} = this.props.data;

      return (
        <form onSubmit={handleSubmit}>
          <div className="container">
            <div className="row mt-2">
              <div className="col-4">
                <label>{locale.holder.label}</label>
                <Field
                  name="holderName"
                  type="text"
                  component={renderField}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="col-2">
                <label>{locale.projectNumber.label}</label>
                <Field
                  name="projectNumber"
                  type="text"
                  component={renderField}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="col-3">
                <label>{locale.projectName.label}</label>
                <Field
                  name="projectName"
                  type="text"
                  component={renderField}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="col-3">
                <label>{locale.insured.label}</label>
                <Field
                  name="insuredName"
                  type="text"
                  component={renderField}
                  className="form-control"
                  disabled
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-3">
                <label>{locale.reqSetName.label}</label>
                <Field
                  name="requirementSetName"
                  type="text"
                  component={renderField}
                  className="form-control"
                  disabled
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-3">
                <label>{locale.waiverSetBy.label}</label>
                <Field
                  name="requirementUser"
                  type="text"
                  component={renderField}
                  className="form-control"
                  disabled
                />
              </div>

              <div className="col-3">
                <label>{locale.waiverStart.label}</label>
                <Field
                  name="waiverStartDate"
                  type="date"
                  component={renderField}
                  className="form-control"
                  disabled
                />
              </div>

              <div className="col-3">
                <label>{locale.waiverEnd.label}</label>
                <Field
                  name="waiverEndDate"
                  type="date"
                  component={renderField}
                  className="tags-input"
                />
                <Field name={'newDate'}
                       component={'date'} />
              </div>

              <div className="col-3">
                <label>{locale.userApproved.label}</label>
                <Field
                  name="approvedUser"
                  type="text"
                  component={renderField}
                  className="form-control"
                  disabled
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-12">
                <table className="table table-hover">
                  <thead>
                  <tr>
                    <th scope="col">{locale.coverageName}</th>
                    <th scope="col">{locale.coverageAttribute}</th>
                    <th scope="col">{locale.coverageValue}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{coverageName}</td>
                    <td>{attributeName}</td>
                    <td>{conditionValue}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-sm">
                <label>Note</label>
                <Field
                  name="note"
                  type="textarea"
                  component={renderField}
                  className={'form-control'}
                  disabled
                />
              </div>
            </div>

            <div className="row mt-3 mb-3 float-right">
              <div className="col-sm">
                <button type="submit" className="btn btn-primary">{locale.btnSave}</button>
                <button type="button" onClick={onHide} className="btn btn-default">{locale.btnCancel}</button>
              </div>
            </div>

          </div>
        </form>
      );
    }

    return false;

  }
}

EditWaivedForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  data: PropTypes.object,
  onHide: PropTypes.func.isRequired,
  locale: PropTypes.object.isRequired,
};

EditWaivedForm = reduxForm({
  form: 'EditWaivedForm',
  validate
})(EditWaivedForm)

const mapStateToProps = (state,props) => {
  const initialValues = {};

  if (props.data) {
    initialValues.note = props.data.note;
    initialValues.holderName = props.data.holderName;
    initialValues.insuredName = props.data.insuredName;
    initialValues.projectName = props.data.projectFullName;
    initialValues.approvedUser = props.data.approvedUser;
    initialValues.projectNumber = props.data.projectNumber;
    initialValues.requirementUser = props.data.requirementUser;
    initialValues.projectInsuredId = props.data.projectInsuredId;
    initialValues.requirementSetName = props.data.requirementSetName;
    initialValues.projectInsuredDeficiencyID = props.data.projectInsuredDeficiencyID;
    initialValues.waiverStartDate = moment.utc(props.data.waiverStartDate).format('YYYY-MM-DD');
    initialValues.waiverEndDate = moment.utc(props.data.waiverEndDate).format('YYYY-MM-DD');
  }

  return {
    initialValues
  }
}

export default connect(null, mapStateToProps)(EditWaivedForm);