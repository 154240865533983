import * as types from '../actions/types'

export default function updateExpiredFileReducer(state = {
    formIsSaving: false,
    saveSuccess: null,
    saveError: null,
    error: null
}, action) {
    switch (action.type) {
        case types.FILE_IS_SAVING:
            return Object.assign({}, state, {
                formIsSaving: true,
                saveSuccess: null,
                saveError: null,
                error: null
            })
        case types.FILE_SAVE_FAILED:
            return Object.assign({}, state, {
                formIsSaving: false,
                saveSuccess: null,
                saveError: true,
                error: action.error
            });
        case types.FILE_SAVE_SUCCESS:
            return Object.assign({}, state, {
                formIsSaving: false,
                saveError: null,
                error: null,
                saveSuccess: true
            });
        default:
            return state
    }
}