import Api from '../../../../lib/api';
import { setAuthToken, setIsLoggedIn } from '../../login/actions/index';
import { AUTH_TOKEN } from '../../../../lib/appConstants';
import { SET_REFRESH_TOKEN_INTERVAL } from './types';
import { removeTokenAndSession } from '../../../../utils/index';


const REFRESH_TOKEN_INTERVAL = process.env.REACT_APP_REFRESH_TOKEN_INTERVAL || 120 * 1000;

export const setRefreshTokenInterval = (refreshTokenInterval) => {
    return {
        type: SET_REFRESH_TOKEN_INTERVAL,
        payload: refreshTokenInterval,
    }
}

export const initRefreshTokenInterval = (callback) => {
    return (dispatch, getState) => {
        const interval = setInterval(callback, REFRESH_TOKEN_INTERVAL);
        dispatch(setRefreshTokenInterval(interval));
    }
} 

export const clearRefreshTokenInterval = () => {
    return (dispatch, getState) => {
        const interval = getState().refreshToken.refreshTokenInterval;
        if (interval) {
            clearInterval(interval);
            dispatch(setRefreshTokenInterval(null));
        }
    }
} 

export const refreshToken = () => {
    return (dispatch, getState) => {
        const token = getState().login.authToken;
        Api.post('users/refresh-token', token)
        .then((response) => {
            const { success, data } = response.data || {};
            if (success) {
                dispatch(setAuthToken(data.token));
                localStorage.setItem(AUTH_TOKEN, data.token);
            } else {
                removeTokenAndSession();
                dispatch(setIsLoggedIn(false));
                dispatch(setAuthToken(""));
            }
        })
        .catch((error) => {
            console.log(error);
        })        
    }
} 