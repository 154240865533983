import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _ from 'lodash';

import {addSerializedHcData} from '../../actions';
import ContactForm from './form';
import Utils from '../../../../lib/utils';

export const ContactInfoTab = (props) => {
  const onSubmit = (values = {}) => {
    if (values.tab === 'choose' && values.hcUser) {
      props.addSerializedHcData({
        contactInfo: {
          hcUser: values.hcUser.value
        }
      });
    } else {
      props.addSerializedHcData({
        contactInfo: {
          ..._.omit(values,'tab','retypePassword','hcUser','changeuponlogin'),
          phone: Utils.normalizePhoneNumber(values.phone),
          isContact: 1,
          isEnabled: 1,
          roleId: 3,
          mustRenewPass: values.changeuponlogin ? 1 : 0,
          timeZoneId: parseInt(values.timezone, 10) || 0,
        }
      });
    }
    props.continueHandler();
  };

  return (
    <section 
      className="wiz-step white-section contact-info-tab" 
      data-testid="tab-contact"
    >
      <div className="admin-form-field-wrapper">
        <h2 className="step-title" data-testid="tab-contact-title">
          {props.local.title}
        </h2>
        <ContactForm onClose={props.onClose} onSubmit={onSubmit} />
        <div className="save-hc-modal-error" data-testid="tab-contact-error">
          {props.hc.errorHC}
        </div>
      </div>
    </section>
  );
};

ContactInfoTab.propTypes = {
  local: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  addSerializedHcData: PropTypes.func.isRequired,
  continueHandler: PropTypes.func.isRequired,
  hc: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.shape({
      errorHC: PropTypes.string.isRequired
    })
  ]).isRequired
};

ContactInfoTab.defaultProps = {
  local: {},
  hc: {errorHC: null},
  onClose: () => {},
  addSerializedHcData: () => {},
  continueHandler: () => {},
};

const mapStateToProps = (state, ownProps) => ({
  local: _.get(
    state, 
    'localization.strings.hiringClients.addHCModal.contactTab', 
    {}
  ),
  hc: state.hc
});

const mapDispatchToProps = (dispatch) => ({
  addSerializedHcData: (serializedHcObj) => {
    dispatch(addSerializedHcData(serializedHcObj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfoTab);