import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';

import NoteEditorModal from '../../modals/noteEditor';
import ViewTaskModal from '../../../tasks/viewTaskModal';
import PTable from '../../../common/ptable';
import Filter from './filter';
import Utils from '../../../../lib/utils';
import * as actions from './actions';
import * as userActions from '../../../users/actions';
import RolAccess from "../../../common/rolAccess";
import { getQueryObjectForApi, processNotesTaskFilterValues } from './utils';
import { fetchFilterApi } from './api';
import { NOTES_TASK_FILTERS, NOTES_TASK_FILTERS_HC, NOTES_TASK_FILTERS_SC } from '../../../../lib/FilterTags';
import {isEmpty} from "lodash";
import { HIRING_CLIENT_FULL_ACCESS_ROLE_ID, HIRING_CLIENT_NON_FINANCIAL_ACCESS_ROLE_ID, PQ_TASK_TYPE_NOTE, SELF_SERVICE_ADMIN_ROLE_ID, SELF_SERVICE_ANALYST_ROLE_ID, SELF_SERVICE_OPERATOR_ROLE_ID, SUPER_HIRING_CLIENT_FULL_ACCESS_ID } from '../../../../lib/appConstants';
import DataTable from "../../../common/table";

const Alerts = require('../../../alerts');

const consoleOn = false;

class NotesTasks extends React.Component {
  constructor(props) {
    super(props);

    const subcontractorId = props.match.params.scId;

    this.state = {
      filter: {
        pageNumber: 1
      },
      filterBox: {
        searchTerm: '',
        userId: '',
        enteredDate: ''
      },
      tableOrderActive: 'dueDate',
      order: {
        name: 'desc',
        urgency: 'desc',
        enteredDate: 'desc',
        createdBy: 'desc',
        dueDate: 'asc',
        status: 'desc',
        assignedToUserId: 'desc',
        typeId: 'desc',
        contactType: 'desc',
        description: 'desc',
        subcontractor: 'desc',
        hiringClient: 'desc',
      },
      selectedNoteTask: null,
      showNoteEditor: false,
      showAssignedToColumn: undefined,
      modal: '',
      subcontractorId
    };

    if (props.fromSidebar) {
      this.state.order.dueDate = 'ASC';
      this.state.tableOrderActive = 'dueDate';
    } else {
      this.state.order.enteredDate = 'DESC';
      this.state.tableOrderActive = 'enteredDate';
    }

    if (props.fromSidebar) {
      this.state.filterBoxTag = NOTES_TASK_FILTERS;
    }
    if (props.fromHCtab) {
      this.state.filterBoxTag = NOTES_TASK_FILTERS_HC;
    }
    if (props.fromSCtab) {
      this.state.filterBoxTag = NOTES_TASK_FILTERS_SC;
    }

    this.onEditNoteTask = this.onEditNoteTask.bind(this);
    this.onCreateNoteTask = this.onCreateNoteTask.bind(this);
    this.showAlerts = this.showAlerts.bind(this);

    // if (!this.props.fromHCtab && !this.props.fromSidebar) {
    //   props.userActions.fetchUsers({ withoutPag: true, orderBy: 'name', subcontractorId });
    // }
  }

  routeTasks = (origin, qry, accessPoint, nextPropId, hcIdFromFilter) => {
    const query = getQueryObjectForApi({
      origin,
      qry,
      accessPoint,
      nextPropId,
      hcIdFromFilter,
      consoleOn,
      fromHCtab: this.props.fromHCtab,
      fromSidebar: this.props.fromSidebar,
      subcontractorId: this.state.subcontractorId,
      tableOrderActive: this.state.tableOrderActive,
      order: this.state.order,
      userId: this.props.userId,
      hcId: this.props.hcId,
      hcIdFromSub: this.props.hcIdFromSub,
    });
    this.props.actions.fetchTasks(query);
  }

  routeTaskUsers = (hcId, accessPoint) => {
    let fHCtab = this.props.fromHCtab,
        sidebar = this.props.fromSidebar,
        neither = !fHCtab && !sidebar,
        subId = this.state.subcontractorId;
    const query = {
      system: 'pq'
    }
    if(this.state.subcontractorId){
      query.subcontractorId = this.state.subcontractorId
    }
    if(hcId){
      query.hiringClientId = hcId
    }

    if (fHCtab || accessPoint === "fromHCtab") {
      query.hiringClientId = hcId;
      query.assetId = hcId;
      query.assetTypeId = "1";
      query.system = "pq";
    } else if (neither || accessPoint === "fromSCtab") {
      query.hiringClientId = hcId;
      query.subcontractorId = isEmpty(subId) ? "" : subId;
      query.assetId = isEmpty(subId) ? "" : subId;
      query.assetTypeId = "2";
      query.system = "pq";
    }
    this.props.actions.fetchTaskUsers(query);
  }

  getCreatedByList = () => {
    let query = {};
    query.assignedToUserId = this.props.userId;
    query.statusId = '1';
    query.alltasks = '1';
    query.system = 'pq';

    this.props.actions.fetchTaskCreatedByList(query);
  }
  
  componentWillMount() {
    this.props.actions.setShowFilterBox(false);
    this.props.actions.setHasSavedFilters(false);
    this.getFilterApi()
    this.getCreatedByList();
    this.props.actions.fetchRoles();
    // this.routeTaskUsers();
  }
   getFilterApi = () => {
    if(this.state.filterBoxTag) {
      fetchFilterApi(this.state.filterBoxTag)
      .then((response) => {
        if (response.data.data) {
          this.props.actions.setFetchedFormValues(response.data.data);
          this.props.actions.setShowFilterBox(true);
          this.props.actions.setHasSavedFilters(true);
          this.submitFilterForm(response.data.data);
        } else {
          this.routeTasks('origin: componentWillMount');
          this.routeTaskUsers(this.props.hcId)
        }
      })
      .catch(() => {
      //console.log('Error fetching filter api');
      });
    } else {
      this.routeTasks('origin: componentWillMount');
    }
  }
  componentWillReceiveProps(nextProps) {

    let
      fHCtab = this.props.fromHCtab,
      newFHCtab = nextProps.fromHCtab,
      sidebar = this.props.fromSidebar,
      newSidebar = nextProps.fromSidebar,
      hcIdFromSub = this.props.hcIdFromSub,
      newHcIdFromSub = nextProps.hcIdFromSub,
      subId = this.state.subcontractorId,
      undefHcIdFromSub = !fHCtab && !sidebar && subId && !hcIdFromSub,
      accessPoint = fHCtab ? 'fromHCtab' : (sidebar ? 'fromSidebar' : (subId ? 'fromSCtab' : null)),
      newAccessPoint = newFHCtab ? 'fromHCtab' : (newSidebar ? 'fromSidebar' : (newHcIdFromSub ? 'fromSCtab' : null))

    //if (consoleOn) {
    //console.log('hcIdFromSub = ', hcIdFromSub)
    //console.log('newHcIdFromSub = ', newHcIdFromSub)
    //console.log('undefHcIdFromSub = ', undefHcIdFromSub)
    //}

    if (newHcIdFromSub && hcIdFromSub !== newHcIdFromSub) {
      //if (consoleOn) {
      //console.log('first conditional accessed')
      //}
      accessPoint = accessPoint === newAccessPoint ? accessPoint : (newAccessPoint ? newAccessPoint : (accessPoint ? accessPoint : null))
      this.routeTasks('origin: componentWillReceiveProps, first conditional', null, accessPoint, newHcIdFromSub)
      this.routeTaskUsers(newHcIdFromSub, accessPoint);
    }

    if (!undefHcIdFromSub && newAccessPoint && accessPoint !== newAccessPoint) {
      this.routeTasks('origin: componentWillReceiveProps, second conditional', null, newAccessPoint)
      this.routeTaskUsers(newHcIdFromSub, newAccessPoint);
    }

    if (nextProps.notesTasks.savingTask) return;
    this.showAlerts(nextProps);

  }

  showAlerts(nextProps) {
    if (this.props.notesTasks.savingTask && !nextProps.notesTasks.savingTask) {
      if (nextProps.notesTasks.error) {
        Alerts.showInformationAlert(
          'Error',
          nextProps.notesTasks.error,
          'Accept',
          false,
          () => { }
        );
        this.props.actions.setError(null);
      } else {
        this.props.actions.setError(null);
        //Refresh handled on closeNoteEditorAndRefresh
        /*
        let query = Utils.getFetchQuery('name', 1, 'ASC');
        this.addAsset(query);
        this.props.actions.fetchTasks(query);
        */
      }
    }
  }

  clickOnColumnHeader = (e, field) => {
    if (field === 'editNoteTask' || field === 'viewNoteTask') return;
    // get base query
    const pageNumber = this.state.filter.pageNumber;
    const orderDirection = this.state.order[field] === 'asc' || this.state.order[field] === 'ASC' ? 'DESC' : 'ASC'

    // save new active tab and order
    let newState = {
      tableOrderActive: field,
      order: {
        name: field === 'name' ? 'asc' : 'desc',
        urgency: field === 'urgency' ? 'asc' : 'desc',
        enteredDate: field === 'enteredDate' ? 'desc' : 'asc',
        createdBy: field === 'createdBy' ? 'asc' : 'desc',
        dueDate: field === 'dueDate' ? 'asc' : 'desc',
        status: field === 'status' ? 'asc' : 'desc',
        assignedToUserId: field === 'assignedToUserId' ? 'asc' : 'desc',
        typeId: field === 'typeId' ? 'asc' : 'desc',
        contactType: field === 'contactType' ? 'asc' : 'desc',
        description: field === 'description' ? 'asc' : 'desc',
        hiringClient: field === 'hiringClient' ? 'asc' : 'desc',
        subcontractor: field === 'subcontractor' ? 'asc' : 'desc',
      }
    };
    newState.order[field] = this.state.order[field] === 'asc' || this.state.order[field] === 'ASC' ? 'desc' : 'asc';
    this.setState(newState);

    if (field === 'createdBy') {
      field = 'enteredByUser'
    }
    if (field === 'urgency') {
      field = 'tasksPriorityId'
    }

    let query = Utils.getFetchQuery(field, pageNumber, orderDirection);
    query = Utils.addSearchFiltersToQuery(query, this.state.filterBox);
    this.routeTasks('origin: clickOncolumnHeader', query)
  }

  setPageFilter = (e, pageNumber, force) => {
    if (force || this.state.filter.pageNumber !== pageNumber) {
      // get base query
      let field = this.state.tableOrderActive;
      const orderDirection = this.state.order[field] === 'asc' || this.state.order[field] === 'ASC' ? 'ASC' : 'DESC'
      if(field === 'urgency') {
        field = 'tasksPriorityId'
      }
      let query = Utils.getFetchQuery(field, pageNumber, orderDirection);
      // add search filters
      query = Utils.addSearchFiltersToQuery(query, this.state.filterBox);
      this.routeTasks('origin: setPageFilter', query)
      // console.log('filterbox = ', this.state.filterBox)
      // console.log('query w/ filterbox = ', query)
      // save page number
      this.setState({
        filter: {
          pageNumber
        }
      });

    }
  }

  submitFilterForm = (values) => {
    // get base query
    const field = this.state.tableOrderActive;
    const pageNumber = 1;
    const orderDirection = this.state.order[field] === 'asc' || this.state.order[field] === 'ASC' ? 'ASC' : 'DESC'
    const { filterBox, query, showAssignedToColumn } = processNotesTaskFilterValues({
      fromSidebar: this.props.fromSidebar,
      field,
      pageNumber,
      orderDirection,
      formValues: values,
      roleId: this.props.login.profile.RoleID,
      userId: this.props.userId,
    });
    this.setState({
      filterBox,
      filter: {
        pageNumber: 1
      },
      showAssignedToColumn,
    }, () => {
      this.routeTasks('origin: submitFilterForm', query, null, null)
    });
  }

  onCreateNoteTask = () => {
    this.setState({
      modal: 'new',
      selectedNoteTask: null,
      showNoteEditor: true
    });
  }

  onEditNoteTask = (task, modal) => {
    this.setState({
      modal,
      selectedNoteTask: task,
      showNoteEditor: true
    });
  }

  closeNoteEditor = () => {
    this.setState({
      showNoteEditor: false
    });
  }

  closeNoteEditorAndRefresh = () => {
    this.setPageFilter(null, 1, true);

    this.setState({
      showNoteEditor: false
    });
  }

  getSidebarTasksTable = () => {
    const {
      viewNotesTasks,
      headers,
    } = this.props.local.strings.scProfile.notesTasks;

    const { CFRole, Role } = this.props.login.profile;

    const {
      hcName,
      scName,
      taskName,
      createdBy,
      dueDate,
      enteredDate,
      holderName,
      insuredName,
      assignedTo,
      urgency,
    } = headers;

    const notesTasksTableMetadata = {
      fields: this.state.showAssignedToColumn ?
      [
        'hiringClient',
        'subcontractor',
        'name',
        'urgency',
        'assignedTo',
        'createdBy',
        'enteredDate',
        'dueDate',
        'viewNoteTask'
      ] : [
        'hiringClient',
        'subcontractor',
        'name',
        'urgency',
        'createdBy',
        'enteredDate',
        'dueDate',
        'viewNoteTask'
      ],
      header: {
        hiringClient: (CFRole && Role) ? `${hcName} / ${holderName}` : (Role ? hcName : holderName),
        subcontractor: (CFRole && Role) ? `${scName} / ${insuredName}` : (Role ? scName : insuredName),
        name: taskName,
        urgency: urgency,
        ...(this.state.showAssignedToColumn ? { assignedTo } : {}),
        createdBy,
        enteredDate: enteredDate,
        dueDate: dueDate,
        viewNoteTask: ''
      }
    };

    const notesTasksTableBody = this.props.notesTasks.tasks.map((task, idx) => {
      const {
        name,
        tasksPriority,
        dateDue,
        subcontractor,
        hiringClient,
        enteredByUser,
        enteredDate,
        HiringClientId,
        subcontractorId
      } = task;

      const { FirstName, LastName } = this.props.login.profile

      let assignedToValue = undefined;
      if (this.state.showAssignedToColumn === 'role') {
        assignedToValue = this.props.login.profile.Role.Name;
      }
      if (this.state.showAssignedToColumn === 'user') {
        assignedToValue = `${FirstName} ${LastName}`;
      }

      return {
        hiringClient: <Link to={`/hiringclients/${HiringClientId}`} className="pq-link">{hiringClient}</Link>,
        subcontractor: <Link to={`/subcontractors/${subcontractorId}`} className="pq-link">{subcontractor}</Link>,
        name,
        urgency: tasksPriority,
        ...(this.state.showAssignedToColumn ? { assignedTo: assignedToValue } : {}),
        createdBy: enteredByUser,
        enteredDate: enteredDate ? new Date(enteredDate).toLocaleString() : '',
        dueDate: dateDue ? new Date(dateDue).toLocaleString() : '',
        viewNoteTask: (
          <a
            onClick={() => this.onEditNoteTask(task, 'view')}
            className="cell-table-link icon-quick_view viewNotesTasks link-ada-color" >
            {viewNotesTasks}
          </a>
        ),
      };
    });
    return {
      fields: notesTasksTableMetadata.fields,
      header: notesTasksTableMetadata.header,
      body: notesTasksTableBody
    };
  }

  getTasksTable = () => {
    const { fromHolderTab } = this.props;

    const {
      viewNotesTasks,
      editNoteTask,
      headers
    } = this.props.local.strings.scProfile.notesTasks;

    const {
      noteTaskTitle,
      urgency,
      enteredDate,
      dueDate,
      status,
      assignedTo,
      noteOrTask,
      createdBy,
      contactType,
      comments
    } = headers;

    const holderFields = [
      'contactType',
      'description'
    ];

    const holderHeader = {
      contactType: contactType,
      description: comments
    };

    const hcFields = [
      'enteredDate',
      'createdBy',
      'typeId',
    ];

    const hcHeader = {
      enteredDate: enteredDate,
      createdBy,
      typeId: noteOrTask,
    };

    const notesTasksTableMetadata = {
      fields: [
        'name',
        'urgency',
        'dueDate',
        ...(fromHolderTab ? holderFields : hcFields),
        'status',
        'assignedToUserId',
        'viewNoteTask',
        'editNoteTask',
      ],
      header: {
        name: noteTaskTitle,
        urgency: urgency,
        dueDate: dueDate,
        ...(fromHolderTab ? holderHeader : hcHeader),
        status: status,
        assignedToUserId: assignedTo,
        viewNoteTask: '',
        editNoteTask: ''
      }
    };

    const notesTasksTableBody = this.props.notesTasks.tasks.map((task, idx) => {
      const {
        name,
        tasksPriority,
        enteredDate,
        dueDate,
        status,
        assignedToUser,
        enteredByUser,
        assignedToRole,
        typeId,
        assignedToUserId,
        assignedToRoleId,
        contactType,
        description,
        type } = task;
      const holderBody = {
        contactType: contactType,
        description: (
          <div className="limitedWidthText">
            {description}
          </div>
        )
      };

      let assignedTo = assignedToUser || assignedToRole;
      // Change label to 'Email', if the task was an email
      if (typeId && typeId === PQ_TASK_TYPE_NOTE && description && description.includes('Sent to')) {
        assignedTo = 'Email';
      }
      const hcBody = {
        enteredDate: new Date(enteredDate).toLocaleString() || "",
        createdBy: enteredByUser,
        typeId: typeId === 1 ? 'Note' : 'Task',
      };
      const PQroleId = [SELF_SERVICE_ADMIN_ROLE_ID,SELF_SERVICE_OPERATOR_ROLE_ID,SELF_SERVICE_ANALYST_ROLE_ID,HIRING_CLIENT_FULL_ACCESS_ROLE_ID,HIRING_CLIENT_NON_FINANCIAL_ACCESS_ROLE_ID,SUPER_HIRING_CLIENT_FULL_ACCESS_ID]
      const userId = this.props.login.profile.Id
      const loginUserRoleId = this.props.login.profile.Role.Id
      const checkPQroleId = PQroleId.includes(loginUserRoleId) 
      const pendingTask = 'Pending' 
      const taskType = "Task"
      return {
        name,
        urgency: tasksPriority,
        dueDate: new Date(dueDate).toLocaleString() || "",
        ...(fromHolderTab ? holderBody : hcBody),
        status: status,
        assignedToUserId: assignedTo,
        viewNoteTask: (
          checkPQroleId && type === taskType && (status === pendingTask) ? (
            (assignedToRoleId === loginUserRoleId || assignedToUserId === userId) ? (
              <a
                onClick={() => this.onEditNoteTask(task, 'view')}
                className="cell-table-link icon-quick_view link-ada-color" >
                {viewNotesTasks}
              </a>
            ) : ''
          )
          : 
        <a
          onClick={() => this.onEditNoteTask(task, 'view')}
          className="cell-table-link icon-quick_view link-ada-color" >
          {viewNotesTasks}
        </a>
        ),
        editNoteTask: status !== 'Completed' 
          ? (
            checkPQroleId && type === taskType && (status === pendingTask) ? (
              (assignedToRoleId === loginUserRoleId || assignedToUserId === userId) ? (
                <a
                  onClick={() => this.onEditNoteTask(task, 'edit')}
                  className="cell-table-link icon-edit link-ada-color" >
                  {editNoteTask}
                </a>
              ) : ''
            )
            :
            <a
              onClick={() => this.onEditNoteTask(task, 'edit')}
              className="cell-table-link icon-edit link-ada-color" >
              {editNoteTask}
            </a>
          )
          : '',
      };
    });
    return {
      fields: notesTasksTableMetadata.fields,
      header: notesTasksTableMetadata.header,
      body: notesTasksTableBody
    };
  }

  getTableColsConfig = () => {
    if (this.props.fromSidebar) {
      return null;
    }
    if (this.state.showAssignedToColumn) {
      return ['14%', '14%', '18%', '11%', '11%', '12%', '12%', '8%', '11%']
    }
    return ['14%', '14%', '18%', '11%', '12%', '12%', '8%', '11%'];
  }

  renderModalBody() {
    if (this.state.modal === 'view') {
      return (
        <ViewTaskModal
          closeAndRefresh={this.closeNoteEditorAndRefresh}
          close={this.closeNoteEditor}
          task={this.state.selectedNoteTask} />
      )
    } else if (this.state.modal === 'edit' || this.state.modal === 'new') {
      return (
        <NoteEditorModal
          closeAndRefresh={this.closeNoteEditorAndRefresh}
          close={this.closeNoteEditor}
          note={this.state.selectedNoteTask}
          subcontractorId={this.state.subcontractorId}
          fromHCtab={this.props.fromHCtab}
          fromSCtab={this.props.fromSCtab}
          hcId={this.props.hcId} />
      )
    }
  }

  renderButtonAddTask = () => {
    const {
      addNotesTasks
    } = this.props.local.strings.scProfile.notesTasks;
    return(
      <li>
        <a
          className="list-view-nav-link nav-bn icon-add no-overlapping"
          onClick={this.onCreateNoteTask}
        >
          {addNotesTasks}
        </a>
      </li>
    );
  }

  render() {
    const {
      filterNotesTasks,
      filterTasks,
    } = this.props.local.strings.scProfile.notesTasks;

    let { totalAmountOfTasks, tasksPerPage, fetchingTasks } = this.props.notesTasks;
    const paginationSettings = {
      total: totalAmountOfTasks,
      itemsPerPage: tasksPerPage,
      setPageHandler: (pageNumber, pageSize) => {
        this.props.pageSize(pageSize)
        this.setPageFilter('', pageNumber, true)
      },
      currentPageNumber: this.state.filter.pageNumber,
    };
    const tablePropData = {
      colsConfig: this.getTableColsConfig(),
      items: this.props.fromSidebar ? this.getSidebarTasksTable() : this.getTasksTable(),
    };

    return (
      <div className="list-view admin-view-body">
        <Modal
          show={this.state.showNoteEditor}
          onHide={this.closeNoteEditor}
          className="add-item-modal noteEditorModal" >
          <Modal.Body>
            {this.renderModalBody()}
          </Modal.Body>
        </Modal>
        <section className="list-view-header projects-view-header">
          <div className="row">
            <div className="col-sm-12">
              <nav className="list-view-nav">
                <ul>
                  <li className="">
                    <a
                      className="list-view-nav-link nav-bn icon-login-door no-overlapping"
                      onClick={() => this.props.actions.setShowFilterBox(!this.props.notesTasks.showFilterBox)}
                    >
                      {this.props.fromSidebar ? filterTasks : filterNotesTasks}
                    </a>
                  </li>

                  {
                    (!this.props.fromSidebar &&
                      <RolAccess
                        masterTab="tasks"
                        sectionTab="create_tasks"
                        component={() => this.renderButtonAddTask()}>
                      </RolAccess>
                      )
                  }

                </ul>
              </nav>
            </div>
          </div>
        </section>
        <section className="list-view-filters">
          <Filter
            onSubmit={this.submitFilterForm}
            fromSidebar={this.props.fromSidebar}
            fromHCtab={this.props.fromHCtab}
            filterValues={{ userId: this.props.userId }}
            visible={this.props.notesTasks.showFilterBox && !this.state.selectedProject}
            filterBoxTag={this.state.filterBoxTag}
            getFilterApi={this.getFilterApi}
          />
        </section>
        <DataTable
          sentFrom={'notesTasks'}
          name="Tasks"
          colsConfig={tablePropData.colsConfig}
          sorted={true}
          items={tablePropData.items}
          wrapperState={this.state}
          tableOrderActive={this.state.tableOrderActive}
          clickOnColumnHeader={this.clickOnColumnHeader}
          isFetching={fetchingTasks}
          customClass='projects-list'
          pagination={paginationSettings}
        />

      </div>
    )

  }
};

const mapStateToProps = (state) => {
  return {
    scProfile: state.SCProfile,
    hcIdFromSub: state.SCProfile.hcId,
    notesTasks: state.notesTasks,
    local: state.localization,
    login: state.login
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    pageSize: payload => dispatch(actions.setPageSize(payload)),
    actions: bindActionCreators(actions, dispatch),
    userActions: bindActionCreators(userActions, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotesTasks));
