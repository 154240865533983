import React from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { bindActionCreators } from "redux";
import Swal from "sweetalert2";
import _ from "lodash";
import SavedFormStatus from "./SavedFormStatus";
import FilterFormSubmissions from "../../../formSubmissions/filter";
import FinancialInfoRenewalModal from "../financialInfo/modals/renewalModal/financialInfoRenewalModal";
import FormLink from "../../../formLink";
import SCLogs from '../sc-logs';

import * as formSubmissionActions from "../../../formSubmissions/actions";
import * as copySubmissionActions from "./../../../copySubmissions/actions";
import * as registerActions from "../../../register/actions";
import * as builderActions from "../../../formBuilder/actions";
import * as commonActions from "../../../common/actions";
import * as scProfileActions from "../../actions";
import * as paymentsActions from "../../../payments/actions";
import * as formLinkActions from "../../../formLink/actions";
import * as finInfoActions from "../financialInfo/actions/";
import * as scLogsAction from '../sc-logs/actions';
import * as actions from '../../actions'
import PTable from "../../../common/ptable";
import Utils from "../../../../lib/utils";
import CopySubmissions from "./../../../copySubmissions/index";
import * as formIoActions from '../../../formio/actions';

import {
  showActionConfirmation,
  showInformationAlert,
} from "../../../../components/alerts";
import RolAccess from "../../../common/rolAccess";
import { PQ_ADMIN_ROLE_ID, PQ_FINANCIAL_ANALYST_ROLE_ID, PQ_OPERATOR_ROLE_ID } from "../../../../lib/appConstants";
const PQ_ROLES_AUTOFILL = [PQ_ADMIN_ROLE_ID,PQ_OPERATOR_ROLE_ID,PQ_FINANCIAL_ANALYST_ROLE_ID]
class Forms extends React.Component {
  constructor(props) {
    super(props);
    this.getSubmissionIdFromList = this.getSubmissionIdFromList.bind(this);

    const subcontractorId = props.match.params.scId;
    let currentHiringClientId = "";
    let submissionId = "";

    props.formSubmissionActions.fetchSavedFormsFilters();
    props.formIoActions.resetFormIoSubmission();
    props.scProfileActions.getLatestSavedForm(
      props.hcIdFromSub,
      subcontractorId
    );

    if (props.hcIdFromSub && props.hcIdFromSub !== "") {
      props.formSubmissionActions.fetchFormSubmissionLink(
        subcontractorId,
        props.hcIdFromSub
      );
      props.scProfileActions.fetchPaymentStatus(
        props.hcIdFromSub,
        subcontractorId
      );
      currentHiringClientId = Number(props.hcIdFromSub);

      // THIS SETTER MAY NEED TO BE REINSTATED AND REFORMULATED IN CASE FORMS TAB IS TO DISPLAY NOT ONE BUT SEVERAL FORMS
      // if (props.formSubmissions && props.formSubmissions.list && props.formSubmissions.list.length && props.formSubmissions.list.length > 0) {
      //   submissionId = this.getSubmissionIdFromList(currentHiringClientId, subcontractorId, props.formSubmissions.list)
      // }
    }

    const shouldResetHC =
      props.payments && props.payments.redirectHcId
        ? Number(props.payments.redirectHcId)
        : false;

    if (shouldResetHC) {
      currentHiringClientId = Number(shouldResetHC);
    }

    if (
      props.scProfile &&
      props.scProfile.paymentStatus &&
      props.scProfile.paymentStatus.paidOrToBePaidSavedFormId
    ) {
      submissionId = props.scProfile.paymentStatus.paidOrToBePaidSavedFormId;
    }

    const paymentStatus =
      props.scProfile && props.scProfile.paymentStatus
        ? props.scProfile.paymentStatus
        : null;

    this.state = {
      filter: {
        pageNumber: 1,
      },
      filterBox: {
        searchTerm: "",
        creatorUserId: "",
      },
      tableOrderActive: "id",
      order: {
        id: "desc",
        formName: "asc",
        startDate: "desc",
        submissionDate: "desc",
        status: "desc",
      },
      currentEditingForm: {},
      name: "",
      showFilterBox: false,
      currentHiringClientId,
      subcontractorId,
      showPaidForm: false,
      externalFormId: null,
      formioSubmissionId: null,
      selectedFormStatus: null,
      submissionId,
      submissionLinkHash: null,
      paymentStatus,
      showModalCopySubmissions: false,
      logsSavedFormId: null,
    };
  }

  componentDidMount() {
    const { lastPayment } = this.props.scProfile;
    const { hcIdFromSub } = this.props;
    this.props.finInfoActions.setEmptyAccountsList();
    if (!_.isEmpty(lastPayment) && hcIdFromSub && lastPayment.done) {
      if (lastPayment.savedFormId) {
        this.onViewSubmission(lastPayment.savedFormId);
      } else {
        this.submitPQData();
      }

      this.props.scProfileActions.setLastPayment({});
    }
  }

  componentWillUnmount() {
    this.props.scLogsAction.setFormLogsVisible(false);
    this.props.finInfoActions.setEmptyAccountsList();
  }

  getSubmissionIdFromList(hcId, subId, list) {
    let output = null;
    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      if (item.hiringClientId == hcId && item.subcontractorID == subId) {
        output = item.id;
      }
    }
    return output;
  }

  defineAndFetchQuery(hiringClientId, formId, origin) {
    let { subcontractorId } = this.state,
      query = {
        orderBy: "id",
        orderDirection: "DESC",
        subcontractorId,
        hiringClientId,
      };
    // this.props.formSubmissions.formId
    if (formId) {
      query.formId = formId;
      this.props.formSubmissionActions.setFormIdForFetching(null);
    }
    // console.log('query = ', query)
    if (subcontractorId && hiringClientId) {
      this.props.formSubmissionActions.fetchForms(query, origin);
    }
  }

  componentWillMount() {
    let { formId } = this.props.formSubmissions,
      hcId = this.props.hcIdFromSub;

    const shouldResetHC =
      this.props.payments && this.props.payments.redirectHcId
        ? Number(this.props.payments.redirectHcId)
        : false;

    const priorityHcId = shouldResetHC ? shouldResetHC : hcId;
    if (priorityHcId) {
      this.defineAndFetchQuery(priorityHcId, formId, "forms CWM");
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      name: `${nextProps.login.profile.FirstName} ${nextProps.login.profile.LastName}`,
    });

    const { submissionId, currentHiringClientId, paymentStatus } = this.state;

    const mostCurrentHcId = Number(nextProps.hcIdFromSub);
    const newShouldResetHC =
      nextProps.payments && nextProps.payments.redirectHcId
        ? Number(nextProps.payments.redirectHcId)
        : false;

    const hcId = newShouldResetHC ? newShouldResetHC : mostCurrentHcId;

    //const { formId } = nextProps.formSubmissions;

    if (hcId && hcId !== Number(currentHiringClientId)) {
      this.setState(
        {
          currentHiringClientId: hcId,
        },
        () => {
          this.props.formSubmissionActions.fetchFormSubmissionLink(
            this.state.subcontractorId,
            hcId
          );
          this.props.actions.fetchHeaderDetails(this.state.subcontractorId,hcId,'sc-profile constructor 1')

          //////////////////// this.state.formId DOES NOT EXIST! //////////////////
          // console.log('SUCCESS fetchForms -> /forms/index CWRP w/ hcId = ', hcId)
          // console.log('typeof hcId = ', typeof hcId)

          this.defineAndFetchQuery(hcId, this.state.formId, "forms CWRP");
          this.props.scProfileActions.fetchPaymentStatus(
            hcId,
            this.state.subcontractorId
          );
          this.props.scProfileActions.getLatestSavedForm(
            hcId,
            this.state.subcontractorId
          );
        }
      );
    } else {
      // console.log('FAIL fetchForms -> /forms/index CWRP w/ hcId = ', mostCurrentHcId)
    }

    const newPaymentStatus = nextProps.scProfile.paymentStatus;
    const shouldUpdatePaymentstatus =
      newPaymentStatus &&
      newPaymentStatus.paidOrYetToPayHcId &&
      (!paymentStatus ||
        !paymentStatus.paidOrYetToPayHcId ||
        newPaymentStatus.paidOrYetToPayHcId !=
          paymentStatus.paidOrYetToPayHcId);

    if (shouldUpdatePaymentstatus) {
      this.setState({
        paymentStatus: newPaymentStatus,
      });
    }

    let newSubmissionId =
      newPaymentStatus && newPaymentStatus.paidOrToBePaidSavedFormId
        ? newPaymentStatus.paidOrToBePaidSavedFormId
        : "";

    if (newSubmissionId && newSubmissionId != submissionId) {
      this.setState({
        submissionId: newSubmissionId,
      });
    }
  }

  clickOnColumnHeader = (e, field) => {
    if (field === "formSubmission") return;
    // get base query
    const pageNumber = this.state.filter.pageNumber;
    const orderDirection = this.state.order[field] === "asc" ? "DESC" : "ASC";
    let query = Utils.getFetchQuery(field, pageNumber, orderDirection);
    query.subcontractorId = this.state.subcontractorId;
    query.hiringClientId = this.props.hcIdFromSub;
    // add search filters
    query = Utils.addSearchFiltersToQuery(query, this.state.filterBox);
    // fetch using query
    this.props.formSubmissionActions.fetchForms(query);
    // save new active tab and order
    let newState = {
      tableOrderActive: field,
      order: {
        id: field === "id" ? "asc" : "desc",
        formName: field === "formName" ? "asc" : "desc",
        startDate: field === "startDate" ? "asc" : "desc",
        submissionDate: field === "submissionDate" ? "asc" : "desc",
        status: field === "status" ? "asc" : "desc",
      },
    };
    newState.order[field] = this.state.order[field] === "asc" ? "desc" : "asc";
    this.setState(newState);
  };

  setPageFilter = (e, pageNumber, force) => {
    if (force || this.state.filter.pageNumber !== pageNumber) {
      // get base query
      const field = this.state.tableOrderActive;
      const orderDirection = this.state.order[field] === "asc" ? "ASC" : "DESC";
      let query = Utils.getFetchQuery(field, pageNumber, orderDirection);
      query.subcontractorId = this.state.subcontractorId;
      query.hiringClientId = this.props.hcIdFromSub;
      // add search filters
      query = Utils.addSearchFiltersToQuery(query, this.state.filterBox);
      // fetch using query
      this.props.formSubmissionActions.fetchForms(query);
      // save page number
      this.setState({
        filter: {
          pageNumber,
        },
      });
    }
  };

  toggleFilterBox = () => {
    this.setState({ showFilterBox: !this.state.showFilterBox });
  };

  onViewSubmission = (savedFormId, externalFormId, formioSubmissionId, selectedFormStatus) => {
    // If user is subcontractor, check if there is a payment pending
    const showFormSubmissionSection = () => {
      this.setState({
        externalFormId,
        formioSubmissionId,
        selectedFormStatus,
        showPaidForm: true,
        submissionId: savedFormId,
        submissionLinkHash: null,
      });
    };

    const { paymentStatus, subcontractorId } = this.state;
    if (Number(this.props.login.profile.RoleID) === 4) {
      this.props.commonActions.setLoading(true);

      if (paymentStatus) {
        this.props.commonActions.setLoading(false);
        if (
          paymentStatus.MustPayRegistration ||
          paymentStatus.MustPayRenewal ||
          paymentStatus.mustPayFlag
        ) {
          this.props.history.push("/payments");
          this.props.scProfileActions.setLastPayment({
            hcId: this.props.hcIdFromSub,
            savedFormId,
          });
        } else {
         showFormSubmissionSection(); 
        }
      } else {
        Swal({
          type: "error",
          title: "Error",
          text: "Error checking the payment information",
        });
      }
    } else {
     showFormSubmissionSection();
    }
  };

  submitPQData = () => {
    const { submissionLink } = this.props.formSubmissions;
    const { paymentStatus, subcontractorId } = this.state;

    if (submissionLink) {
      const arr = submissionLink.split("/form-link/");

      // If user is subcontractor, check if there is a payment pending
      if (Number(this.props.login.profile.RoleID) === 4) {
        this.props.commonActions.setLoading(true);
        if (paymentStatus) {
          this.props.commonActions.setLoading(false);
          if (
            paymentStatus.MustPayRegistration ||
            paymentStatus.MustPayRenewal ||
            paymentStatus.mustPayFlag
          ) {
            this.props.history.push("/payments");
            this.props.scProfileActions.setLastPayment({
              hcId: this.props.hcIdFromSub,
            });
          } else {
            this.setState({
              showPaidForm: true,
              submissionId: null,
              submissionLinkHash: arr[1],
            });
          }
        } else {
          Swal({
            type: "error",
            title: "Error",
            text: "Error checking the payment information",
          });
        }
      } else {
        this.setState({
          showPaidForm: true,
          submissionId: null,
          submissionLinkHash: arr[1],
        });
      }

      //var win = window.open(submissionLink, '_blank');
      //win.focus();
    }
  };

  onCancelSubmission = () => {
    this.setPageFilter(null, 1, true);
    this.setState({ showPaidForm: false });
  };

  submitFilterForm = (values) => {
    // get base query
    const field = this.state.tableOrderActive;
    const pageNumber = 1;
    const orderDirection = this.state.order[field] === "asc" ? "ASC" : "DESC";
    let query = Utils.getFetchQuery(field, pageNumber, orderDirection);
    query.subcontractorId = this.state.subcontractorId;
    query.hiringClientId = this.props.hcIdFromSub;
    // add search filters
    const filterBox = {
      searchTerm: (values && values.keywords) || "",
      creatorUserId: (values && values.formCreator) || "",
    };
    query = Utils.addSearchFiltersToQuery(query, filterBox);
    // fetch using query
    this.props.formSubmissionActions.fetchForms(query);
    // save searchterm and pagenumber
    this.setState({
      filterBox,
      filter: {
        pageNumber: 1,
      },
    });
  };

  // table's row handlers
  renderHeader = (value) => {
    return value === "" ? null : <span className="col-th-arrow"></span>;
  };

  onAddNewForm = () => {
    this.setState({ showModalAddForm: true });
  };

  closeAddFormModal = () => {
    this.setState({ showModalAddForm: false });
  };

  saveForm = (hcId, formId) => {
    const { userId } = this.props.login;
    const { scId } = this.props.match.params;
    this.props.formLinkActions.saveForm(
      formId,
      userId,
      scId,
      hcId,
      (savedFormId, error) => {
        const result = this.props.forms.list.filter(
          (form) => form.id == formId
        );
        if (result && result[0] && result[0].subcontractorFee != null && result[0].subcontractorFee != 0) {
          showActionConfirmation(
            {
              title: "Set Must Pay Flag",
              text: "This form has a subcontractor fee. Do you want to set a payment?",
              btn_no: "No",
              btn_yes: "Yes",
            },
            (result) => {
              if (result) {
                this.props.formLinkActions.setMustPayFlag(
                  hcId,
                  scId,
                  true,
                  (success, data) => {
                    if (success & data) {
                      showInformationAlert(
                        "Information",
                        "The payment has been set for this form",
                        "Accept",
                        false,
                        () => {
                          this.closeAddFormModal();
                          this.props.formSubmissionActions.fetchForms({
                            hiringClientId: hcId,
                            orderBy: "formName",
                            orderDirection: "ASC",
                            subcontractorId: scId,
                          });
                        }
                      );
                    } else {
                      showInformationAlert(
                        "Error",
                        "There was an error trying to set the payment for the form",
                        "Accept",
                        false,
                        () => {
                          this.closeAddFormModal();
                          this.props.formSubmissionActions.fetchForms({
                            hiringClientId: hcId,
                            orderBy: "formName",
                            orderDirection: "ASC",
                            subcontractorId: scId,
                          });
                        }
                      );
                    }
                  }
                );
              } else {
                this.closeAddFormModal();
                this.props.formSubmissionActions.fetchForms({
                  hiringClientId: hcId,
                  orderBy: "formName",
                  orderDirection: "ASC",
                  subcontractorId: scId,
                });
              }
            }
          );
        } else {
          this.closeAddFormModal();
          this.props.formSubmissionActions.fetchForms({
            hiringClientId: hcId,
            orderBy: "formName",
            orderDirection: "ASC",
            subcontractorId: scId,
          });
        }
      }
    );
  };

  renderListView = (latestSavedForm) => {
    let {
      tableHeaderName,
      tableHeaderId,
      tableHeaderFinUpdate,
      tableFormSubmissionId,
      tableHeaderStartDate,
      tableHeaderSubmissionDate,
      tableHeaderStatus,
      tableItemViewSubmission,
      tableItemViewUsersLog
    } = this.props.local.strings.formList;

    const formsTableMetadata = {
      fields: [
        "formName",
        "formId",
        "fromFinUpdate",
        "id",
        "startDate",
        "submissionDate",
        "status",
        "formSubmission",
        "copy",
        "formLog",
        "deleteForm"
      ],
      header: {
        formName: tableHeaderName,
        formId: tableHeaderId,
        fromFinUpdate: tableHeaderFinUpdate,
        id: tableFormSubmissionId,
        startDate: tableHeaderStartDate,
        submissionDate: tableHeaderSubmissionDate,
        status: tableHeaderStatus,
        formSubmission: "",
        copy: "",
        formLog: "",
        deleteForm: "" 
      },
    };

    const renderViewSubmissionLink = (savedForm) => {
      return (
        <a
          className="icon-log cell-table-link link-ada-color"
          onClick={() => {
            this.onViewSubmission(
              savedForm.id,
              savedForm.externalFormId,
              savedForm.formioSubmissionId,
              savedForm.status
            );
          }}
        >
          {tableItemViewSubmission}
        </a>
      );
    };
    const {RoleID} = this.props.loginProfile
    const savedFormsTableBody = this.props.formSubmissions.list.map(
      (savedForm) => {
        const {
          formName,
          formId,
          fromFinUpdate,
          id,
          startDate,
          submissionDate,
          status,
          totalAvailableCopyFormHiringClients,
          isLocked,
          lastIncompletedSavedFormId,
          maxFormid,
          latestCompleteFormId,
          finIsComplete
        } = savedForm;

        const {
          statusLabel,
          cancelBtn,
          saveBtn,
          resetStatusMessage,
          resetStatusLabel
        } = this.props.local.strings.scProfile.changeStatusModal;
    
        return {
          formName,
          formId,
          fromFinUpdate: fromFinUpdate ? (<span className="icon-checkbox fin-update-check"></span>) : '',
          id,
          startDate: Utils.getFormattedDate(startDate, true),
          submissionDate: Utils.getFormattedDate(submissionDate, true),

          status: (
            <SavedFormStatus
              status={status}
              savedForm={savedForm}
              latestSavedForm={latestSavedForm}
              loginProfile={this.props.loginProfile}
              updateSavedFormStatus={
                this.props.formLinkActions.updateSavedFormStatus
              }
              markFinancialInfoAsIncompleted={
                this.props.finInfoActions.markFinancialInfoAsIncompleted
              }
              finIsComplete={this.props.financials.accountsListData.finIsComplete}
              fetchAccountsList={this.props.finInfoActions.fetchAccountsList}
              scProfile={this.props.scProfile.headerDetails}
              changeStatus = {this.props.actions.changeStatus}
              statusLabel = {statusLabel}
              resetStatusMessage = {resetStatusMessage}
              cancelBtn = {cancelBtn}
              saveBtn = {saveBtn}
              resetStatusLabel = {resetStatusLabel}
              fetchForms= {this.props.formSubmissionActions.fetchForms}
            />
          ),
          formSubmission:
            isLocked == 0 || isLocked == null ? (
              renderViewSubmissionLink(savedForm)
            ) : this.props.login.profile.Role.IsSCRole ? (
              <a className="icon-log cell-table-link disabled link-ada-color">
                {"Form Submission-Expired"}
              </a>
            ) : (
              renderViewSubmissionLink(savedForm)
            ),
          copy: status === "Incomplete" &&
            id === maxFormid && this.checkSCStatusAndFinInfo(latestCompleteFormId,finIsComplete) && (
              <RolAccess
              masterTab="form"
              sectionTab="autofill_form"
              component={() => (<a
                className="icon-log cell-table-link link-ada-color"
                onClick={() => this.onViewCopySubmission(savedForm)}
              >
                {"Auto-fill From"}
              </a>)}>
            </RolAccess>
            ),
          formLog: (
            <a
              className="icon-numbered-list cell-table-link link-ada-color"
              onClick={() => { this.openUserLog(savedForm.id) }}
            >
              {tableItemViewUsersLog}
            </a>
          ),
          deleteForm: RoleID === 1 && (
            <a
              className="cell-table-link icon-delete t-6 link-ada-color"
              onClick={() => { this.confirmDeleteForm(savedForm) }}
            >
            </a>
          )
        };
      }
    );

    const formsTableData = {
      fields: formsTableMetadata.fields,
      header: formsTableMetadata.header,
      body: savedFormsTableBody,
    };

    let { totalAmountOfForms, formsPerPage, fetchingForms } =
      this.props.formSubmissions;

    const paginationSettings = {
      total: totalAmountOfForms,
      itemsPerPage: formsPerPage,
      setPageHandler: this.setPageFilter,
      currentPageNumber: this.state.filter.pageNumber,
    };

    let isSubcontractor = true;
    let showButton = false;
    if (this.props.login.profile.Role) {
      isSubcontractor = this.props.login.profile.Role.IsSCRole;
      showButton =
        !isSubcontractor ||
        (isSubcontractor && this.props.formSubmissions.submissionLink);
    }

    return (
      <div>
        <Modal
          show={this.state.showModalCopySubmissions}
          onHide={this.closeModalCopySubmissions}
          className="add-item-modal noteEditorModal"
        >
          <Modal.Body>
            <CopySubmissions
              onHide={this.closeModalCopySubmissions}
            ></CopySubmissions>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showModalAddForm}
          onHide={this.closeAddFormModal}
          className="add-item-modal noteEditorModal"
        >
          <Modal.Body className="add-item-modal-body">
            <FinancialInfoRenewalModal
              closeAndRefresh={() => this.closeAddFormModal}
              onForce={this.saveForm}
              close={this.closeAddFormModal}
              hcId={this.props.hcIdFromSub}
              type="add"
            />
          </Modal.Body>
        </Modal>
        <section className="forms-view-header">
          <div className="row">
            <div className="col-sm-12">
              {showButton ? (
                <nav className="forms-nav">
                  <ul> 
                      <li>
                        <a
                          className="forms-nav-link nav-bn icon-search_icon"
                          onClick={this.toggleFilterBox}
                        >
                          Filter Submissions
                        </a>
                        {(this.props.login.profile.Role.Id == 1 ||
                          this.props.login.profile.Role.Id == 26) &&
                        ((latestSavedForm != null &&
                          ((latestSavedForm.isComplete == 1 &&
                            latestSavedForm.finIsComplete == null) ||
                            latestSavedForm.finIsComplete == 0)) ||
                          (latestSavedForm.isComplete == 0 &&
                            latestSavedForm.finIsComplete == null) ||
                          latestSavedForm.finIsComplete == 0) ? (
                          <a
                            className="forms-nav-link nav-bn icon-add"
                            onClick={this.onAddNewForm}
                          >
                            Add form
                          </a>
                        ) : null}
                      </li>
                  </ul>
                </nav>
              ) : null}
            </div>
          </div>
        </section>
        {this.state.showFilterBox ? (
          <section className="forms-view-filters">
            <FilterFormSubmissions
              onSubmit={this.submitFilterForm}
              hideSentTo={true}
            />
          </section>
        ) : (
          <div></div>
        )}

        <PTable
          sorted={true}
          items={formsTableData}
          wrapperState={this.state}
          tableOrderActive={this.state.tableOrderActive}
          clickOnColumnHeader={this.clickOnColumnHeader}
          isFetching={fetchingForms}
          customClass="forms-table"
          pagination={paginationSettings}
        />
      </div>
    );
  };

  closeModalCopySubmissions = () => {
    this.setState({ showModalCopySubmissions: false });
    this.submitFilterForm();
  };

  onViewCopySubmission = (savedForm) => {
    const formioSubmissionState = {
      formioSubmissionId: null,
      hcName: savedForm.hiringClientName,
      savedFormId: savedForm.id,
      selectedFormStatus: "Incomplete",
      subcontractorId: savedForm.subcontractorID,
    }

    this.props.copySubmissions.setSubContractorSelected({
      hiringClientId: savedForm.hiringClientId,
      subcontractorID: savedForm.subcontractorID,
      formIdIncomplete: savedForm.id,
      formId: savedForm.formId,
      formType: savedForm.externalFormId ? "formio" : "legacy",
      externalFormId: savedForm.externalFormId,
      formioSubmissionState
    });
    this.setState({ showModalCopySubmissions: true });
  };

  openUserLog = (savedFormId) => {
    this.setState({ logsSavedFormId: savedFormId });
    this.props.scLogsAction.setFormLogsVisible(true);
  }
  confirmDeleteForm = (savedForm) => {
    Swal({
      type: "question",
      title: "",
      text: "Are you sure you want to delete this form?",
      showCancelButton: true,
      confirmButtonColor: '#2E5965',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText : 'No'
      }).then((result) => {
        if (result.value) {
          this.props.formSubmissionActions.deletSavedFormById(savedForm,(isSuccess,message)=>{
            if(isSuccess){
              Swal({
                type: "success",
                title: "",
                text: "Form has been successfully deleted",          
                }).then((result) => {
                  window.location.reload();
                })
            }else{
              Swal({
                type: "error",
                title: "",
                text: "Error in deleting the form, please try later.",
                }).then((result) => {})
            }
          });
        }
      });
  }
  checkSCStatusAndFinInfo = (latestCompleteFormId,finIsComplete) => {
    let isRoleFound = PQ_ROLES_AUTOFILL.includes(this.props.loginProfile.RoleID);
    if(latestCompleteFormId && isRoleFound){
      return true
    }else if(latestCompleteFormId && finIsComplete === 1){
      return true
    }
    return false
  }
  render() {
  const { showPaidForm, externalFormId, submissionId, formioSubmissionId, selectedFormStatus, subcontractorId } = this.state;
  const { latestSavedForm,} = this.props.scProfile;
  let headerDetails;
  if (Object.keys(this.props.scProfile.headerDetails).length > 0) {
    headerDetails = this.props.scProfile.headerDetails
  } else {
    headerDetails = this.props.headerDetails
  }
  const { hiringClientsList, currentHiringClient: currentHCId } = headerDetails;
  if(hiringClientsList) {
    const currentHiringClientData = hiringClientsList.find(hiringClient => hiringClient.hiringClientId.toString() === currentHCId.toString());
    const encryptedFormId = Utils.encrypt(`${externalFormId},${submissionId}`)

    const showSubmissionSection = externalFormId ? (
      <Redirect
        push to={{
          pathname: formioSubmissionId ?
            (`/formio/forms/${externalFormId}/submission/${formioSubmissionId}/edit`)
            : (`/formio/forms/${encryptedFormId}`),
          state: {
            subcontractorId,
            hcId: currentHCId, 
            hcName: currentHiringClientData.name,
            savedFormId: submissionId,
            formioSubmissionId,
            selectedFormStatus,
            hideNavbar: true
          },
        }}
      />
    ) : (
      <FormLink
        fromTab
        savedFormId={this.state.submissionId}
        linkHash={this.state.submissionLinkHash}
        onCancel={this.onCancelSubmission}
      />
    );

    const renderFormView = () => (
      showPaidForm
        ? showSubmissionSection
        : this.renderListView(latestSavedForm)
    );

    return (
      <div className="forms-view list-view admin-view-body">
        {
          this.props.isFormLogVisible ? 
            <SCLogs savedFormId={this.state.logsSavedFormId} logType={2} />
            : renderFormView()
        }
      </div>
    );
  }else {
    return (
      <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className="spinner-wrapper" style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <div className="spinner" />
        </div>
      </div>
    )
  }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    hcName: state.HCProfile.profileData.name,
    hcIdFromSub: state.SCProfile.hcId,
    scProfile: state.SCProfile,
    common: state.common,
    formSubmissions: state.formSubmissions,
    login: state.login,
    local: state.localization,
    payments: state.payments,
    forms: state.forms,
    formLink: state.formLink,
    loginProfile: state.login.profile,
    headerDetails:state.SCProfile.headerDetails,
    isFormLogVisible: state.scLogs.isFormLogVisible,
    financials: state.financials,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    scProfileActions: bindActionCreators(scProfileActions, dispatch),
    formSubmissionActions: bindActionCreators(formSubmissionActions, dispatch),
    formLinkActions: bindActionCreators(formLinkActions, dispatch),
    paymentsActions: bindActionCreators(paymentsActions, dispatch),
    registerActions: bindActionCreators(registerActions, dispatch),
    builderActions: bindActionCreators(builderActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    copySubmissions: bindActionCreators(copySubmissionActions, dispatch),
    finInfoActions: bindActionCreators(finInfoActions, dispatch),
    scLogsAction: bindActionCreators(scLogsAction, dispatch),
    formIoActions: bindActionCreators(formIoActions, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Forms));
