import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as loginActions from '../login/actions';
import * as commonActions from '../../common/actions';

import SidePicture from '../common/SidePicture';
import AuthFormHeader from '../common/AuthFormHeader';
import Loader from '../../common/loader';

import keycloak from './../../../keycloak';

const KeycloakSSO = (props) => {
  const [location, setLocation] = useState({ from: { pathname: '/sso' } });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const history = useHistory();
  const locationState = useLocation();

  useEffect(() => {
    try {
      props.commonActions.setLoading(true);
      keycloak
        .init({
          onLoad: 'check-sso'
        })
        .then((authenticated) => {
          props.commonActions.setLoading(false);
          if (authenticated) {
            setIsAuthenticated(true);
            console.log('(keycloak.idTokenParsed):: ', keycloak.idTokenParsed);
          } else {
            setIsAuthenticated(false);
          }
        });
    } catch (err) {
      //console.log(err.error);
    }
  }, []);

  useEffect(() => {
    if (locationState && locationState.state && locationState.state.from.pathname !== '/') {
      setLocation(locationState.state);
    }
  }, [locationState]);

  const onClickContinue = async () => {
    props.commonActions.setLoading(true);
    const profile = keycloak.idTokenParsed;
    if (profile.email) {
      const credentials = {
        remember: true,
        username: profile.email,
        sso: profile.sub
      };

      await props.actions.sendCredentials(credentials, history, location.from);
      props.commonActions.setLoading(false);
    } else {
      props.commonActions.setLoading(false);
      console.log('Email does not exist.');
    }
  };

  const signOut = () => {
    keycloak.logout();
    history.replace('/v2/sso');
  };

  let loginStrings = props.local.strings.auth.login;
  return (
    <div className="container-fluid login">
      <Loader />
      <div className="row">
        <SidePicture />
        <div className="form-wrapper col-custom-padding col-md-4 col-sm-12">
          <AuthFormHeader subtitle={props.login.extraMessage || ''} />

          <div className="auth-form login-form">
            <div className="form-buttons">
              {!isAuthenticated && (
                <button
                  className="sign-in-icon bn icon-login-door"
                  onClick={() => {
                    // keycloak.login({idpHint: 'vertikal'})
                    keycloak.login({});
                  }}
                >
                  {loginStrings.signIn}
                </button>
              )}
              {isAuthenticated && (
                <div>
                  <div className="field-wrapper">
                    <label>{keycloak && keycloak.idTokenParsed && keycloak.idTokenParsed.email}</label>
                  </div>
                  <div className="form-buttons">
                    <button className="sign-in-icon bn icon-login-door" onClick={onClickContinue}>
                      Continue
                    </button>
                    <button className="cancel bn icon-login-door" onClick={signOut}>
                      Sign Out
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    login: state.login,
    local: state.localization,
    common: state.common
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(loginActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KeycloakSSO));
