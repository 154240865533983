import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import CompanyProfileTab from './companyProfileTab';
import UserInformationTab from './userInformationTab';

const RegisterMain = (props) => {
  const [step, setStep] = useState(1);

  const {
    step1Title,
    step2Title,
    title,
    instruction,
    suggestion,
  } = props.local.strings.register.registerMain;

  return (
    <div className="col-sm-12 col-md-8 register-main">
      <div className="register-main-header">
        <h1 className="register-title">{title}</h1>
        <p className="register-instr">{instruction}</p>
        <p className="register-instr">{suggestion}</p>
      </div>
      <div className="tabs-frame">
        <ul className="nav nav-tabs">
          <li className="nav-item tab-wrapper">
            <a 
              className={`step-nav ${step === 1 ? 'active' : ''}`} 
              onClick={() => setStep(1)}>
              {step1Title}
            </a>
          </li>
          <li className="nav-item tab-wrapper">
            <a className={`step-nav ${step === 2 ? 'active' : ''}`}>
              {step2Title}
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div className={`tab-pane step1 ${step === 1 ? 'active' : ''}`}>
            <UserInformationTab continueHandler={() => setStep(2)}/>
          </div>
          <div className={`tab-pane step2 ${step === 2 ? 'active' : ''}`}>
            <CompanyProfileTab />
          </div>
        </div>
      </div>
    </div>
  );
};

RegisterMain.propTypes = {local: PropTypes.object.isRequired};

const mapStateToProps = (state, ownProps) => ({local: state.localization});

const withRedux = connect(mapStateToProps, null);

export default withRedux(RegisterMain);