import * as types from '../actions/types';

export default function refreshTokenReducer(state = { refreshTokenInterval: null }, action) {
    switch(action.type) {
        case types.SET_REFRESH_TOKEN_INTERVAL:
            return {
                ...state,
                refreshTokenInterval: action.payload
            }
        default:
            return state;
    }
}