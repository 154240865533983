import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2'; 

import AddProjectInsuredForm from './form';
import * as projectInsuredsActions from '../../project-insureds/actions';
import * as commonActions from '../../../common/actions';
import * as loginActions from '../../../auth/login/actions';
import '../addEntityModal.css';

const {
  PROJECT_INSURED_NON_COMPLIANT_STATUS_ID,
} = require('../../../../lib/appConstants');
class AddProjectInsuredModal extends Component {
  
  componentDidMount() {
    if (this.props.common.complianceStatus.length <= 0) {
      this.props.commonActions.fetchComplianceStatus();
    }
  }
  
  send = async (values) => {
    const { projectInsured, holderId, holderRequirementSets } = this.props;
    const { changeReqSet } = this.props.locale.addProjectinsuredsModal.modal;
    const requirementSetsList = holderRequirementSets.list;
    const requirementSetId = parseInt(values.insuredReqSet, 10);
    let copiedRequirementSetId = null;
    let requirementSetEdition = false;

    const requirementSetIsDefault = requirementSetsList.filter(e => e.IsDefault === 1).some(i => i.Id === requirementSetId);
  //console.log('requirementSetIsDefault: ', requirementSetIsDefault);

    if (requirementSetIsDefault) {
      try {
        requirementSetEdition = true;
        copiedRequirementSetId = await this.props.projectInsuredsActions.createRequirementSetCopy({
          reqSetId: requirementSetId,
          holderId: holderId,
          projectId: this.props.projectId,
        });       
      } catch (e) {
        Swal({
          type: 'error',
          title: 'Default requirement set copy',
          text: e,
          onClose: () => { this.props.close(); }
        });          
      }   
    }

    const payload = {
      projectInsuredId: projectInsured ? projectInsured.ProjectInsuredID : undefined,
      projectId: this.props.projectId,
      insuredId: values.insured.value,
      requirementSetId: (copiedRequirementSetId) ? copiedRequirementSetId : requirementSetId,
      complianceStatusId: (projectInsured) ? values.status : PROJECT_INSURED_NON_COMPLIANT_STATUS_ID,
      requirementSetEdition: requirementSetEdition,
    }

    if (projectInsured && projectInsured.ProjectInsuredID) {
      Swal({
        title: changeReqSet.title,
        html: changeReqSet.body,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2E5965',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
      }).then((result) => {
        payload.selectedProjectInsureds = [{
            projectInsuredId: projectInsured.ProjectInsuredID ,
            holderId: this.props.holderId,
            projectId: this.props.projectId,
            requirementSetId: values.insuredReqSet,
          }];
        payload.copy = true;        
        if (result.value) this.sendAction(payload)
      });
    } else {
      this.sendAction(payload)
    }
    this.props.loginActions.getProfile();
  };

  sendAction = (payload) => {
    const { setLoading } = this.props.commonActions;
    const { postProjectInsured } = this.props.projectInsuredsActions;

    setLoading(true);
    postProjectInsured(payload, (success) => {
      setLoading(false);
      if (success) {
        (payload.requirementSetEdition) ? this.props.closeAndRedirect(payload.requirementSetId) : this.props.close();
      }  
    });
  }

  hideModal = () => {
    const { onHide, close } = this.props;
    if (onHide) onHide();
    else close();
  }

  render() {
    const {
      title,
      titleEdit
    } = this.props.local.strings.projectInsureds.addProjectinsuredsModal;

    const text = this.props.insured ? titleEdit : title;
    
    return (
      <div className="add-item-view add-entity-form-small">
        <div className="add-item-header">
          <h1>{text}</h1>
        </div>

        <section className="white-section">
          <div className="add-item-form-subsection">
            <AddProjectInsuredForm
              projectInsured={this.props.projectInsured}
              close={this.hideModal}
              onSubmit={this.send} 
              holderId={this.props.holderId}
            />
          </div>
        </section>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    local: state.localization,
    locale: state.localization.strings.projectInsureds,
    common: state.common,
    holderRequirementSets: state.holderRequirementSets,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    projectInsuredsActions: bindActionCreators(projectInsuredsActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    loginActions: bindActionCreators(loginActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProjectInsuredModal);
