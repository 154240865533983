import React, {useEffect,useRef, useState} from 'react';
import {Field, reduxForm, change, touch} from 'redux-form';
import {AsyncPaginate} from 'react-select-async-paginate';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _ from 'lodash';
import { uuid } from 'uuidv4';
import renderField from '../../../../customInputs/renderField';
import Utils from '../../../../../lib/utils';
import asyncValidate from './asyncValidate';
import { bindActionCreators } from 'redux';
import * as loadApplyOptions from '../../../../common/countyDropdownActions/loadOptions'
import * as applyActions from '../../../../common/countyDropdownActions/actions'
import validate from './validate';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import loadOptions from './loadOptions';

const notify = () => toast("Copied to clipboard!!");
const SelectEntity = ({input, placeholder, defaultValue, options}) => (
  <Select
    {...input}
    placeholder={placeholder}
    defaultValue={defaultValue}
    options={options}
    openMenuOnClick={true}
    openMenuOnFocus={true}
    closeMenuOnScroll={true}
    classNamePrefix="vk"
    className="select-entity-type"
    menuPlacement="auto"
    onFocus={() => {}}
    onChange={(value) => input.onChange(value)}
    onBlur={() => input.onBlur()}
    blurInputOnSelect={true}
  />
);

const SelectParent = ({input, meta, ...props}) => (
  <React.Fragment>
    <AsyncPaginate
      {...input}
      {...props}
      isSearchable={true}
      openMenuOnClick={true}
      openMenuOnFocus={true}
      closeMenuOnScroll={true}
      cacheOptions={true}
      pageSize={10}
      additional={{ page: 1 }}
      classNamePrefix="vk"
      className="select-parent"
      menuPlacement="top"
      onFocus={(_) => {}}
      onBlur={() => {}}
      onChange={(newValue) => input.onChange(newValue)}
      captureMenuScroll={true}
      closeMenuOnSelect={true}
      escapeClearsValue={true}
      isClearable={true}
      loadOptionsOnMenuOpen={true}
    />
    {(!input.value || (meta.touched && meta.error)) && (
      <span className="select-error-message field-validation-message">
        {meta.error}
      </span>
    )}
  </React.Fragment>
);

const SelectLogo = ({input, meta, onChangeLogo, ...props}) => {
  const { value: omitValue, onChange, onBlur, ...inputProps } = input;
  return (
    <div>
      <input
        type="file"
        accept="image/*"
        onChange={onChangeLogo(input.onChange)}
        {...props}
        {...inputProps}
      />
      <span className="field-validation-message">
        {meta.touched && meta.error && <span>{meta.error}</span>}
      </span>
    </div>
  );
};

const CompanyInfoForm = (props) => {
  const [regUrlSavePrompt, setRegUrlSavePrompt] = useState(props.profile ? true : false);
  const getRegistrationUrl = (companyName, local, props) => {
    if (props.hcprofile.profileData.registrationUrl) {
      const val = props.hcprofile.profileData.registrationUrl.split('apply/').pop();
      if (val.indexOf('/') > -1) {
        const baseURL = process.env.REACT_APP_FRONTEND_URL
        return `${baseURL}/apply/${val}`;
      }
    }
    const baseURL = process.env.REACT_APP_FRONTEND_URL
    const domain = baseURL.replace(/^https?\:\/\//i, "");
    const defaultSuffix = local.registrationUrlSufix;
    const baseHCUrl = _.get(companyName, 'profile.baseHCUrl', defaultSuffix);
    let value = companyName.replace(/[$&+,:;=?@#|'<>.^*()%! ]/gm, "-");
    const uid = uuid();
    if (regUrlSavePrompt) {
      setRegUrlSavePrompt(false);
      toast("Click save to update the new autogenerated Registration URL!!");
    }
    return `${domain}/apply/${value.toLowerCase()}/${uid}`;
  };

  const getEntityType = (props) => {
    const entity = _.toNumber(_.get(props, 'profile.entityType', 0));
    const values = _.get(props, 'currentForm.CompanyInfoForm.values');
    const value = _.toNumber(_.get(values, 'entityType.value', 0));
    const options = [
      {value: 0, label: 'NONE'},
      {value: 1, label: 'CHILD'},
      {value: 2, label: 'PARENT'}
    ];
    const defaultValue = options[entity >= 0 ? entity : value];
    return {options, value, defaultValue};
  };

  const getParentHiringClientDefaultValue = (props) => {
    const value = _.get(props, 'profile.parentHiringClientId');
    const label = _.get(props, 'profile.parentHiringClientName');
    return value >= 0 ? {value, label} : null;
  };

  const getSubmitButtonProps = (props) => {
    const attrs = {
      className: 'wiz-continue-btn bg-sky-blue-gradient bn',
      disabled: false,
      'data-testid': props.profile ? 'submit-button' : 'continue-button'
    };

    if (props.isSubmittingHC || props.isSubmittedHC) {
      attrs.className += ' wiz-submitting-btn wiz-continue-btn-disabled';
      attrs.disabled = true;
    }

    return attrs;
  };

  const getSubmitButtonTitle = (props) => {
    const title = props.profile ? local.saveButton : local.continueButton;
    const suffix = props.isSubmittingHC || props.isSubmittedHC ? '...' : '';
    return `${title}${suffix}`;
  };

  const getCompanyLogoLabel = (props) => {
    const form = _.get(props, 'currentForm.CompanyInfoForm');
    const {labelCompanyLogo: label} = props.local.strings
      .hiringClients
      .addHCModal
      .companyInfoTab;

    if (_.get(form, 'values.companyLogo.name', label) === label) {
      return label;
    } else if (_.get(form, 'syncErrors.companyLogo')) {
      return label;
    } else {
      return '';
    }
  };

  const copyClipboard = () => {
    navigator.clipboard.writeText(registration);
    notify();
  }

  const onChangeLogo = (delegate) => (event) => {
    const file = event.target.files[0];

    if (file) {
      const node = document.getElementById('upload-file-wrapper');
      node.style.background = `url(${URL.createObjectURL(file)})`;
      node.style.backgroundSize = 'contain';
      node.style.backgroundRepeat = 'no-repeat';
      node.style.backgroundPosition = 'center';
    }

    delegate(file);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const hasProfileDefaultValues = (props) => {
      const values = _.get(props, 'currentForm.CompanyInfoForm.values');
      const parentHC = _.get(values, 'parentHC.value', null);
      const node = document.getElementById('upload-file-wrapper');
      values.countryId = values.countryId && values.countryId.value
      values.stateId = values.stateId && values.stateId.value
      const file = values.companyLogo;
      if ((props.profile && values) &&
        (props.profile.name === values.companyName) &&
        (props.profile.address1 === values.companyAddress1) &&
        (props.profile.address2 === values.companyAddress2) &&
        (props.profile.city === values.city) &&
        (props.profile.countryId === values.countryId) &&
        (props.profile.StateId === values.StateId) &&
        (props.profile.zipCode === values.postalCode) &&
        (props.profile.phone === values.phone) &&
        (props.profile.phone2 === values.phone2) &&
        (props.profile.subdomain === values.subdomain) &&
        (props.profile.entityType === values.entityType.value) &&
        (props.profile.parentHiringClientId === parentHC) &&
        (node.style.background === `url(${URL.createObjectURL(file)})`)) {
        return true;
      }

      return false;
    };

    if (props.valid &&
      !props.asyncValidating &&
      !props.isSubmittingHC &&
      !hasProfileDefaultValues(props)) {
      props.onSubmit(props.currentForm.CompanyInfoForm.values);
      return;
    }

    props.dispatch(touch(
      'CompanyInfoForm',
      'companyLogo',
      'companyName',
      'companyAddress1',
      'city',
      'countryId',
      'stateId',
      'postalCode',
      'phone',
      'subdomain'
    ));
  };

  const refInputCompanyName = useRef(null);
  const refInputSubdomain = useRef(null);
  const local = props.local.strings.hiringClients.addHCModal.companyInfoTab;
  const entityType = getEntityType(props);
  const profileId = _.get(props, 'profile.id', null);

  const [state, setState] = useState({ companyName: "" })
  const [selectedCountry,setselectedCountry] = useState(1)

  useEffect(() => {
    props.dispatch(change(
      'CompanyInfoForm',
      'countryId',
      {value:1,label:"United States"},
      ));
    if (props.profile) {
      if (props.profile.name) {
        props.dispatch(change(
          'CompanyInfoForm',
          'companyName',
          props.profile.name
        ));
        setState({ companyName: props.profile.name })
      }
      if (props.profile.CountryId) {
        setselectedCountry(props.profile.CountryId)
        props.dispatch(change(
          'CompanyInfoForm',
          'countryId',
          {value:props.profile.CountryId,label:props.profile.countryName},
          ));
      }
      if (props.profile.StateId) {
        props.dispatch(change(
          'CompanyInfoForm',
          'stateId',
          {value:props.profile.StateId,label:props.profile.stateName}
        ));
      }
      if (props.profile.address1) {
        props.dispatch(change(
          'CompanyInfoForm',
          'companyAddress1',
          props.profile.address1
        ));
      }
      if (props.profile.address2 !== 'not available') {
        props.dispatch(change(
          'CompanyInfoForm',
          'companyAddress2',
          props.profile.address2
        ));
      }
      if (props.profile.city) {
        props.dispatch(change(
          'CompanyInfoForm',
          'city',
          props.profile.city
        ));
      }
      // if (props.profile.state) {
      //   props.dispatch(change(
      //     'CompanyInfoForm',
      //     'state',
      //     props.profile.state
      //   ));
      // }
      if (props.profile.zipCode) {
        props.dispatch(change(
          'CompanyInfoForm',
          'postalCode',
          props.profile.zipCode
        ));
      }
      if (props.profile.phone) {
        props.dispatch(change(
          'CompanyInfoForm',
          'phone',
          props.profile.phone
        ));
      }
      if (props.profile.phone2 !== 'not available') {
        props.dispatch(change(
          'CompanyInfoForm',
          'phone2',
          props.profile.phone2
        ));
      }
	   // Now handling it on Use Effect
      // if (props.profile.subdomain !== 'not available') {
      //   // props.dispatch(change(
      //   //   'CompanyInfoForm', 
      //   //   'subdomain', 
      //   //   props.profile.subdomain
      //   // ));
      // }
      if (props.profile.entityType >= 0) {
        props.dispatch(change(
          'CompanyInfoForm',
          'entityType',
          entityType.defaultValue
        ));
        if (props.profile.entityType === 1
          && props.profile.parentHiringClientId >= 0) {
          props.dispatch(change(
            'CompanyInfoForm',
            'parentHC',
            getParentHiringClientDefaultValue(props)
          ));
        }
      }
      if (props.profile.AllowApplications) {
        props.dispatch(change(
          'CompanyInfoForm',
          'allowApplications',
          true
        ));
      }
      if (props.profile.AutoApproveApplications) {
        props.dispatch(change(
          'CompanyInfoForm',
          'autoApproveApplications',
          true
        ));
      }
      if (props.profile.logo) {
        const base64 = `data:image/jpeg;base64,${props.profile.logo}`;
        const blob = Utils.base64ToBlob(base64);
        const file = Utils.blobToFile(blob, `${props.profile.id}`);
        const node = document.getElementById('upload-file-wrapper');
        node.style.background = `url(${URL.createObjectURL(file)})`;
        node.style.backgroundSize = 'contain';
        node.style.backgroundRepeat = 'no-repeat';
        node.style.backgroundPosition = 'center';
        props.dispatch(change('CompanyInfoForm', 'companyLogo', file));
      }
    } else {
      refInputCompanyName.current.focus();
    }
  }, [props.profile]);
  const [registration, setRegistrationUrl] = useState("")
  useEffect(() => {
    let regUrl = getRegistrationUrl(state.companyName, local, props)
    props.dispatch(change(
      'CompanyInfoForm',
      'subdomain',
      regUrl
    ));
    setRegistrationUrl(regUrl)
  }, [state.companyName])

  const SelectAsyncOptions = ({ input, meta, dispatch, ...props }) => {
    const touchedError = meta.touched && meta.error;
    const name = input.name;
    const baseClassName = `${props.className || ''} select-${name}`;
    const errorClassName = `${touchedError ? `${baseClassName}-error` : ''}`;
  
    return (
      <React.Fragment>
        <AsyncPaginate
          {...input}
          {...props}
          openMenuOnClick={true}
          closeMenuOnScroll={true}
          cacheOptions={true}
          pageSize={10}
          additional={{ page: 1 }}
          classNamePrefix="vk"
          className={`${baseClassName} ${errorClassName}`}
          menuPlacement="auto"
          onFocus={(_) => { }}
          onBlur={() => meta.dispatch(touch('CompanyInfoForm', name))}
          onChange={(newValue) => input.onChange(newValue)}
          captureMenuScroll={true}
          closeMenuOnSelect={true}
          debounceTimeout={300}
          loadOptionsOnMenuOpen={true}
        />
        {touchedError && (
          <span className="select-error-message field-validation-message">
            {meta.error}
          </span>
        )}
      </React.Fragment>
    );
  };
  const resetStateValue = (data) => {
    if(selectedCountry != data.value){
      props.dispatch(change(
        'CompanyInfoForm',
        'stateId',
        {value:null,label:''}
      ));
    }
    setselectedCountry(data.value)
  };
  const handleSelectState = (value) => {
    props.dispatch(change(
      'CompanyInfoForm',
      'stateId',value.value
    ));
  }

  return (
    <form
      onSubmit={onSubmit}
      data-testid="form-company"
      className="company-info-form wiz-form"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <div className="upload-file-wrapper">
              <label id="upload-file-wrapper" htmlFor="companyLogo">
                {getCompanyLogoLabel(props)}
              </label>
              <Field
                name="companyLogo"
                id="companyLogo"
                component={SelectLogo}
                onChangeLogo={onChangeLogo}
              />
            </div>
          </div>
          <div className="col-md-8 col-sm-12">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="wiz-field admin-form-field-wrapper">
                  <label htmlFor="companyName">
                    {`${local.labelCompanyName}:`}
                  </label>
                  <Field
                    name="companyName"
                    type="text"
                    onChange={event => setState({ companyName: event.target.value })}
                    placeholder={'--ACME CORP--'}
                    component={renderField}
                    getRef={refInputCompanyName}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="wiz-field admin-form-field-wrapper">
                  <label htmlFor="companyAddress1">
                    {`${local.labelAddress1}:`}
                  </label>
                  <Field
                    name="companyAddress1"
                    type="text"
                    placeholder={'--ACME CORP--'}
                    component={renderField}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="wiz-field admin-form-field-wrapper">
                  <label htmlFor="companyAddress2">
                    {`${local.labelAddress2}:`}
                  </label>
                  <Field
                    name="companyAddress2"
                    type="text"
                    placeholder={'--ACME CORP--'}
                    component={renderField}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="wiz-field admin-form-field-wrapper">
                  <label htmlFor="city">
                    {`${local.labelCity}:`}
                  </label>
                  <Field
                    name="city"
                    type="text"
                    placeholder={'--ACME CORP--'}
                    component={renderField}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="wiz-field admin-form-field-wrapper">
                  <label htmlFor="stateId">
                  State /  Province
                  </label>
                  <Field
                    name="stateId"
                    type="text"
                    required={true}
                    component={SelectAsyncOptions}
                    placeholder='Select State'
                    cacheUniqs={[selectedCountry]}
                    onChange={(value)=>handleSelectState(value)}
                    loadOptions={props.actions.loadStates(props,selectedCountry)}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="wiz-field admin-form-field-wrapper">
                  <label htmlFor="countryId">
                   Select Country
                  </label>
                  <Field
                    name="countryId"
                    onChange={(values)=>resetStateValue(values)}
                    type="text"
                    required={true}
                    component={SelectAsyncOptions}
                    placeholder='Select Country'
                    loadOptions={props.actions.loadCountries(props)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="wiz-field admin-form-field-wrapper">
                  <label htmlFor="postalCode">
                    {`${local.labelPostalCode}:`}
                  </label>
                  <Field
                    name="postalCode"
                    type="text"
                    placeholder={'--ACME CORP--'}
                    component={renderField}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="wiz-field admin-form-field-wrapper">
                  <label htmlFor="phone">
                    {`${local.labelPhone}:`}
                  </label>
                  <Field
                    name="phone"
                    type="text"
                    placeholder={'--ACME CORP--'}
                    component={renderField}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="wiz-field admin-form-field-wrapper">
                  <label htmlFor="phone2">
                    {`${local.labelPhone2}:`}
                  </label>
                  <Field
                    name="phone2"
                    type="text"
                    placeholder={'--ACME CORP--'}
                    component={renderField}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="wiz-field admin-form-field-wrapper">
                  <label htmlFor="subdomain">
                    {`${local.labelRegistrationUrl}: `}
                    <span style={{
                      fontSize: '12px',
                      color: 'black',
                      fontWeight: 400,
                      wordWrap:'break-word',
                      width: 150, 
                      overflow: 'hidden',
                    }}>
                      {registration}
                    </span>
                  </label>
                  <div
                    onClick={copyClipboard}>
                    <div className="input-group" style={{width:"100%"}}>
                    <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon">
                    <i className="fa fa-clipboard prefix"></i>
                    </span>
                    </div>
                    <div style={{flex:1}}>
                    <Field
                    name="subdomain"
                    type="text"
                    disabled
                    value={registration}
                    placeholder={'subDomain'}
                    component={renderField}
                    />
                      </div>
                  
                    <ToastContainer />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="wiz-field admin-form-field-wrapper">
                  <label htmlFor="entityType">
                    {`${local.labelEntity}:`}
                  </label>
                  <div className="select-wrapper">
                    <Field
                      name="entityType"
                      placeholder={local.labelEntity}
                      defaultValue={entityType.defaultValue}
                      options={entityType.options}
                      component={SelectEntity}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="wiz-field admin-form-field-wrapper">
                  <label htmlFor="allowApplications">
                    {`${local.allowApplications}: `}
                  </label>
                  <Field
                    id="allowApplications"
                    name="allowApplications"
                    type="checkbox"
                    component={renderField}
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="wiz-field admin-form-field-wrapper">
                  <label htmlFor="autoApproveApplications">
                    {`${local.autoApproveApplications}: `}
                  </label>
                  <Field
                    id="autoApproveApplications"
                    name="autoApproveApplications"
                    type="checkbox"
                    component={renderField}
                  />
                </div>
              </div>
            </div>
            {entityType.value === 1 && (
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="admin-form-field-wrapper wiz-field">
                    <label htmlFor="parentHC">
                      {`${local.labelParentHC}:`}
                    </label>
                    <div className="select-wrapper">
                      <Field
                        id="parentHC"
                        name="parentHC"
                        component={SelectParent}
                        placeholder={`--${local.parentHCPlaceholder}--`}
                        noOptionsMessage={() => local.parentHCNoOptionsMessage}
                        loadingMessage={() => local.parentHCLoadingMessage}
                        defaultValue={getParentHiringClientDefaultValue(props)}
                        loadOptions={loadOptions(
                          props.dispatch,
                          props.local.strings.hiringClients,
                          profileId
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="wiz-buttons">
        <a 
          className="wiz-cancel-button" 
          data-testid="cancel-button"
          onClick={props.onClose}
        >
          {local.cancel}
        </a>
        <button {...getSubmitButtonProps(props)}>
          {getSubmitButtonTitle(props)}
        </button>
      </div>
    </form>
  );
}

CompanyInfoForm.propTypes = {
  local: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  currentForm: PropTypes.object,
  profile: PropTypes.object,
  isSubmittingHC: PropTypes.bool.isRequired,
  hcprofile: PropTypes.object,
  actions: PropTypes.shape({
    loadOptions: PropTypes.func,
  }),
};

CompanyInfoForm.defaultProps = {
  onClose: () => {},
  dispatch: () => {},
  handleSubmit: () => {},
  valid: false,
  asyncValidating: false,
  local: {},
  currentForm: {},
  profile: null,
  isSubmittingHC: false,
  hcprofile: {},
  actions: {
    loadOptions: (dispatch, local) => {
      return async (currentValue, prevOptions, {page}) => ({
        options: prevOptions,
        hasMore: false,
        additional: {page}
      })
    }
  }
};

const withReduxForm = reduxForm({
  form: 'CompanyInfoForm',
  validate,
  asyncValidate,
  asyncBlurFields: ['companyName'],
  asyncChangeFields: ['companyName'],
  touchOnBlur: true,
  touchOnChange: true,
  initialValues: {
    allowApplications: false,
    autoApproveApplications: false,
    entityType: {label: 'NONE', value: 0},
  }
})(CompanyInfoForm);

const mapStateToProps = (state, ownProps) => ({
  currentForm: state.form,
  local: state.localization,
  isSubmittingHC: state.hc.isSubmittingHC,
  isSubmittedHC: state.hc.isSubmittedHC,
  hcprofile: state.HCProfile,
  loginProfile: state.login
});
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      ...bindActionCreators(applyActions, dispatch),
      ...loadApplyOptions
    }
  };
};
// const mapDispatchToProps = (dispatch) => ({actions: {loadApplyOptions}});

export default connect(mapStateToProps, mapDispatchToProps)(withReduxForm);