import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UpdateCustomFieldsForm from './form';
import * as holderActions from '../../holders/actions';
import * as commonActions from "../../../common/actions";
import '../addEntityModal.css';
import {showToastSuccessNotification} from "../../../alerts";
import {ToastContainer} from "react-toastify";

class UpdateCustomFieldsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false
    }
  }

  send = (values) => {
    const {
      successMessage
    } = this.props.local.strings.certFocusProjects.updateCustomFieldsModal;
    this.setState({processing: true})
    const payload = {
      file: values.csvCFDataFile[0],
      holderId: this.props.holderId
    }
    this.props.actions.updateCustomFields(payload, (response) => {
      showToastSuccessNotification(successMessage)
      setTimeout(() => {
        this.hideModal();
      }, 3000)
    });
  };

  hideModal = () => {
    const { onHide, close } = this.props;
    if(onHide)onHide();
    else close();
  }

  render() {
    return (
      <div className="add-item-view add-entity-form-small">
        <div className="add-item-header">
          <h1>Update Custom Fields</h1>
        </div>

        <section className="white-section">
          <div className="add-item-form-subsection">
            <UpdateCustomFieldsForm
              close={this.hideModal}
              onSubmit={this.send}
              holderId={this.props.holderId}
              processing={this.state.processing}
            />
          </div>
          <ToastContainer />
        </section>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    local: state.localization,
    common: state.common
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(holderActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCustomFieldsModal);
