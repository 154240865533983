import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NoteForm from './form';
import Utils from '../../../../lib/utils';

import * as actions from '../../notes/actions';
import * as commonActions from '../../../common/actions';

import './noteeditor.css';
class NotesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editingNoteId: false,
      projectList: [],
    };
  };

  componentDidMount() {
    const { insuredId } = this.props;
    this.props.actions.fetchProjectsByInsuredId(insuredId, (err, data) => {
      if(!err){
        this.setState({ projectList: data });
      }
    });
  }  

  saveNote = (values) => {
    let
      name = Utils.sanitizeQuotes(values.name),
      description =  Utils.sanitizeQuotes(values.description);

    const payload = {
      noteId: this.props.note ? this.props.note.Id : null,
      name,
      description,
      insuredId: this.props.insuredId,
      projectId: (values.projectId) ? values.projectId : null,
    };
    
    this.props.commonActions.setLoading(true);
    this.props.actions.saveNote(payload, (success) => {
      this.props.commonActions.setLoading(false);

      if (success) {
        this.props.closeAndRefresh();
      }
    });
  }

  render() {
    const {
      titleCreateNote,
      titleEditNote,
    } = this.props.local.strings.scProfile.notesTasks.modal;

    const { note } = this.props;

    const title = note ? titleEditNote : titleCreateNote;

    return (
      <div>
        <header>
          <div className="noteEditorTitle">{title}</div>
        </header>
        <NoteForm
          onSubmit={this.saveNote}
          dismiss={this.props.close}
          note={this.props.note}
          holderId={this.props.holderId}
          fromHolderTab={this.props.fromHolderTab}
          insuredId={this.props.insuredId}
          fromInsuredTab={this.props.fromInsuredTab}
          defaultValue={this.props.defaultValue}
          projectList={this.state.projectList}
        />
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    scProfile: state.SCProfile,
    local: state.localization,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotesModal);
