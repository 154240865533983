import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reset } from 'redux-form';
import { Link, Redirect } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import AddHCModal from './addHCModal';
import AddHolderModal from '../certfocus/modals/addHolderModal';
import PTable from '../common/ptable';

import Utils from '../../lib/utils';
import FilterHC from './filter';

import * as hcActions from './actions';
import * as hcProfileActions from '../hc-profile/actions';
import * as holderProfileActions from '../certfocus/holders-profile/actions';
import * as commonActions from '../common/actions';
import * as departmentsActions from '../certfocus/settings/departments/actions';
import RolAccess from './../common/rolAccess';
import DataTable from "../common/table";

import './hiringClients.css';

import Swal from 'sweetalert2';
import SystemSwitch from "../auth/systemSwitch";

import { deleteFilterApi } from '../sc-profile/tabs/notesTasks/api';
import * as filterActions from "../certfocus/filterbox/actions";

class HiringClients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {
        pageNumber: 1
      },
      filterBox: {
        searchTerm: '',
        contactNameTerm: '',
        nameTerm: '',
        archive: 0,
        hcTags: [],
        holderTags: []
      },
      tableOrderActive: 'name',
      showFilterBox: true,
      order: {
        id: 'desc',
        name: 'asc',
        parentHolder: 'desc',
        registrationUrl: 'desc',
        state: 'desc',
        portalURL: 'desc',
        contactName: 'desc',
        phone: 'desc'
      },
      currentHC: null,
      preQualView: false,
      certFocusView: false,
      fetched: false,
      cfRoleNotAllowed: false,
      snack: {
        show: false,
        snackMessage: '',
      }
    };

    this.restrictedCFRoles = [23, 25]; // Holder Auditor, Insured
  }

  componentDidMount() {
    // If the user profile is present, fetch the list
    const { CFRole, Role } = this.props.login.profile;
    // this.initialFetch(CFRole, Role);
    this.props.commonActions.setBreadcrumbItems([]);

    this.props.departmentsActions.getDepartments({
      orderBy: 'name',
      orderDirection: 'ASC',
      archived: 0,
    });

    let saveFilter = { tab: 'hiringClients' }
    saveFilter.module = 'GlobalHiringClients';
    saveFilter.objectId = null;
    this.props.filterActions.fetchDefaultFilters(saveFilter, 'FilterHiringClients', (filters) => {
      this.setState(prevState => ({
          filterBox: {...filters}
        }),
        () => {
          this.handleSystemChange(this.props.login.currentSystem, true)
        });
    })

  }

  componentWillReceiveProps(nextProps) {
    // If the user profile was yet not fetched, wait for it and fetch the list
    const { CFRole, Role } = this.props.login.profile;
    const newCFRole = nextProps.login.profile.CFRole;
    const newRole = nextProps.login.profile.Role;

    if ((newRole && newRole != Role) || (newCFRole && newCFRole != CFRole)) {
      const _Role = newRole && newRole != Role ? newRole : Role
      const _CFRole = newCFRole && newCFRole != CFRole ? newCFRole : CFRole
      // this.initialFetch(_CFRole, _Role);
    }

    if (CFRole && this.restrictedCFRoles.find(e => e === Number(CFRole.Id))) {
      this.props.commonActions.setLoading(false);
      this.setState({ cfRoleNotAllowed: true });
    }
  }
  
    initialFetch = (CFRole, Role) => {

    if ((Role || CFRole) && !this.state.fetched) {
      this.setState({ fetched: true }, () => {
        if (Role && CFRole) {
          const view = localStorage.getItem('HiringClientsHolderView');

          if (view === 'cf') {
            this.setState({ certFocusView: true });
            this.props.actions.fetchHolders({ archive: 0, orderBy: 'name', orderDirection: 'ASC' });
          } else {
            this.setState({ preQualView: true });
            this.props.actions.fetchHiringClients({ orderBy: 'name', orderDirection: 'ASC',archive: 0 });
          }
        } else if (Role) {
          this.setState({ preQualView: true });
          this.props.actions.fetchHiringClients({ orderBy: 'name', orderDirection: 'ASC' });
        } else if (CFRole) {
          this.setState({ certFocusView: true });
          this.props.actions.fetchHolders({ archive: 0, orderBy: 'name', orderDirection: 'ASC' });
        }
      });
    }
  }

  // User hc or holders EP depending on the selected system
  fetchHiringClientsBySystem = (query) => {
    const { preQualView, certFocusView } = this.state;

    if (preQualView) {
      query.system = 'pq';
      ['nameTerm', 'contactNameTerm', 'archived', 'holderTags', 'hcTags'].forEach((key) => { if(query[key]) query[key] = '' });
      this.props.actions.fetchHiringClients(query);
    } else if (certFocusView) {
      ['searchTerm', 'holderTags', 'hcTags'].forEach((key) => { if(query[key]) query[key] = '' });
      if(query.searchTerm) query.searchTerm = '';
      if(query.archive == 2) query.archive = '';
      this.props.actions.fetchHolders(query);
    }
  }

  // Reset al filters and table data when switching systems
  handleSystemChange = (system, initial = false) => {
    // if (!this.props.hc.fetchingHC) {

    const filterBox = (initial ? this.props.hc.initialFilterBox : this.state.filterBox) || {};
    const hcTags = this.state.filterBox.hcTags
    const holderTags = this.state.filterBox.holderTags

    let hiringClientsTags = system === "pq" ? hcTags : holderTags;

    hiringClientsTags = (hiringClientsTags && hiringClientsTags.length) ? hiringClientsTags.map(x => x.value) : '';

    this.props.dispatch(reset('FilterHC'));

    const newState = {
      filter: {
        pageNumber: 1
      },
      tableOrderActive: 'name',
      showFilterBox: true,
      order: {
        id: 'desc',
        name: 'asc',
        parentHolder: 'desc',
        registrationUrl: 'desc',
        state: 'desc',
        portalURL: 'desc',
        contactName: 'desc',
        phone: 'desc'
      },
      filterBox: this.state.filterBox
    };

    this.setState(newState);

    localStorage.setItem('HiringClientsHolderView', system);

    let filterbox = {
      ...this.state.filterBox,
      hiringClientsTags
    }

    if (system === 'pq') {
      delete filterbox.nameTerm
      filterbox.archive = 0
    }

    const query = Utils.addSearchFiltersToQuery({
      orderBy: 'name',
      orderDirection: 'ASC',
      archive: 0
    }, filterbox);

    if (system === 'pq') {
      this.setState({ certFocusView: false, preQualView: true }, () => {
        this.fetchHiringClientsBySystem(query);
      });
    } else if (system === 'cf') {
      this.setState({ certFocusView: true, preQualView: false }, () => {
        this.fetchHiringClientsBySystem(query);
      });
    }
    // }
  }

  clickOnColumnHeader = (e, field) => {
    if (field === 'view' || field === 'edit') return;

    const { hcTags, holderTags} = this.state.filterBox;
    const { currentSystem } = this.props.login;

    // get base query
    const pageNumber = this.state.filter.pageNumber;
    const orderDirection = this.state.order[field] === 'asc' ? 'DESC' : 'ASC'
    let query = Utils.getFetchQuery(field, pageNumber, orderDirection);

    let hiringClientsTags = currentSystem === "pq" ? hcTags : holderTags;

    hiringClientsTags = hiringClientsTags.length ? hiringClientsTags.map(tag => tag.value) : '';

    // add search filters
    query = Utils.addSearchFiltersToQuery(query, {...this.state.filterBox, hiringClientsTags});


    // fetch using query
    this.fetchHiringClientsBySystem(query);

    // save new active tab and order
    let newState = {
      tableOrderActive: field,
      order: {
        id: field === 'id' ? 'asc' : 'desc',
        name: field === 'name' ? 'asc' : 'desc',
        parentHolder: field === 'parentHolder' ? 'asc' : 'desc',
        registrationUrl: field === 'registrationUrl' ? 'asc' : 'desc',
        state: field === 'state' ? 'asc' : 'desc',
        portalURL: field === 'portalURL' ? 'asc' : 'desc',
        contactName: field === 'contactName' ? 'asc' : 'desc',
        phone: field === 'phone' ? 'asc' : 'desc',
      }
    };
    newState.order[field] = this.state.order[field] === 'asc' ? 'desc' : 'asc';
    this.setState(newState);
  }

  setPageFilter = (e, pageNumber, force) => {
    /*if (force || this.state.filter.pageNumber !== pageNumber) {
      // get base query
      const field = this.state.tableOrderActive;
      const orderDirection = this.state.order[field] === 'asc' ? 'ASC' : 'DESC'
      let query = Utils.getFetchQuery(field, pageNumber, orderDirection);

      // add search filters
      query = Utils.addSearchFiltersToQuery(query, this.state.filterBox);

      // fetch using query
      this.fetchHiringClientsBySystem(query);

      // save page number
      this.setState({
        filter: {
          pageNumber
        }
      });
    }*/


    const { hcTags, holderTags, searchTerm, contactNameTerm, nameTerm, archive } = this.state.filterBox;
    const { currentSystem } = this.props.login;
    let hiringClientsTags = currentSystem === "pq" ? hcTags : holderTags;

    hiringClientsTags = hiringClientsTags.length ? hiringClientsTags.map(tag => tag.value) : '';
    const filterBox = {
      searchTerm: searchTerm,
      contactNameTerm: contactNameTerm,
      nameTerm: nameTerm,
      archive: archive != 2? archive : '',
    };
    const field = this.state.tableOrderActive;
    const pageNum = pageNumber;
    const orderDirection = this.state.order[field] === 'asc' ? 'ASC' : 'DESC'
    let query = Utils.getFetchQuery(field, pageNumber, orderDirection);

    query = Utils.addSearchFiltersToQuery(query, {...filterBox, hiringClientsTags});

    // fetch using query
    this.fetchHiringClientsBySystem(query);
    
    // save searchterm and pagenumber
    this.setState({
      filterBox: {
        ...this.state.filterBox,
        hcTags, holderTags
      },
      filter: {
        pageNumber: pageNum
      }
    });
  }

  toggleFilterBox = () => {
    this.setState({ showFilterBox: !this.state.showFilterBox });
  }

  submitFilterForm = (values) => {
    // get base query

    const { currentSystem } = this.props.login;
    const hcTags = values.hcTags ? values.hcTags : [];
    const holderTags = values.holderTags ? values.holderTags : []

    let hiringClientsTags = currentSystem === "pq" ? hcTags : holderTags;

    hiringClientsTags = hiringClientsTags.length ? hiringClientsTags.map(x => x.value) : '';

    const field = this.state.tableOrderActive;
    const pageNumber = 1;
    const orderDirection = this.state.order[field] === 'asc' ? 'ASC' : 'DESC'
    let query = Utils.getFetchQuery(field, pageNumber, orderDirection);

    // add search filters
    let filterBox = {
      searchTerm: values.searchTerm || '',
      contactNameTerm: values.contactNameTerm || '',
      archive: currentSystem === "pq" ? 0 : (values.archive != 2 ? values.archive : ''),
    };

    if(currentSystem === "cf") {
      filterBox.nameTerm = values.nameTerm || ''
    }
    query = Utils.addSearchFiltersToQuery(query, {...filterBox, hiringClientsTags});

    if(currentSystem === "cf") {
      let saveFilter = { tab: 'hiringClients' }
      saveFilter.module = 'GlobalHiringClients';
      saveFilter.objectId = null;
      saveFilter.filterBox = {
        searchTerm: values.searchTerm || '',
        contactNameTerm: values.contactNameTerm || '',
        nameTerm: values.nameTerm || '',
        archive: values.archive,
        hcTags,
        holderTags
      };

      this.props.filterActions.saveDefaultFilters('FilterHiringClients', saveFilter);
    }

    // fetch using query
    this.fetchHiringClientsBySystem(query);

    filterBox = { ...filterBox, archive : values.archive }
    // save searchterm and pagenumber
    this.setState({
      filterBox: {
        ...filterBox,
        hcTags, holderTags
      },
      filter: {
        pageNumber: 1
      }
    });
  }

  openAddHCModal = (type) => {
    this.setState({ currentHC: null });

    if (type === 'hc') {
      this.props.actions.setShowModal(true);
    } else if (type === 'holders') {
      this.props.actions.setShowHoldersModal(true);
    }
  }

  closeAddHCModal = (type) => {
    if (type === 'hc') {
      this.props.actions.setShowModal(false);
    } else if (type === 'holders') {
      this.props.actions.setShowHoldersModal(false);
    }
  }

  closeAddHCModalAndRefresh = () => {
    this.props.actions.setShowModal(false);
    this.props.actions.setShowHoldersModal(false);
    this.setPageFilter(null, 1, true);
  }

  openEditHCModal = (hc, type) => {
    const { fetchHCProfile } = this.props.hcProfileActions;
    const { fetchHolderProfile } = this.props.holderProfileActions;
    const { setLoading } = this.props.commonActions;
    setLoading(true);

    if (type === 'hc') {
      fetchHCProfile(hc, (profile) => {
        setLoading(false);
        this.setState({ currentHC: profile || hc });
        this.props.actions.setShowModal(true);
      });
    } else if (type === 'holders') {
      fetchHolderProfile(hc, (profile) => {
        setLoading(false);
        this.setState({ currentHC: profile || hc });
        this.props.actions.setShowHoldersModal(true)
      });
    }
  }

  renderButtonAddHiringclient = () => {
    const {
      addButton,
    } = this.props.local.strings.hiringClients;

    return (
      <div>
        <a onClick={() => this.openAddHCModal('hc')}
          className="nav-btn nav-bn icon-add">
          {addButton}
        </a>
      </div>
    );
  }

  renderButtonAddHolder = () => {
    let component = (
      <div>
        <a onClick={() => this.openAddHCModal('holders')}
          className="nav-btn nav-bn icon-add">
          {this.props.local.strings.holders.holdersList.addHolderButton}
        </a>
      </div>
    );

    return component;
  }

  renderButtonCFRoleViewHolder = (hcId) => {
    let component = (
      <div>
        <Link
          to={`/certfocus/holders/${hcId}`}
          className='cell-table-link icon-quick_view'>
          {this.props.local.strings.holders.holdersList.viewHolders}
        </Link>
      </div>
    );

    return component;
  }

  renderButtonRoleViewHolder = (hcId) => {
    let component = (
      <div>
        <Link
          to={`/hiringclients/${hcId}`}
          className='cell-table-link icon-quick_view link-ada-color'
        >
          {this.props.local.strings.hiringClients.viewHC}
        </Link>
      </div>
    );

    return component;
  }

  renderButtonCFRoleEditHolder = (hcId) => {
    console.log('HERERERE CFFF')
    let component = (
      <div>
        <a
          className='cell-table-link icon-edit'
          onClick={() => this.openEditHCModal(hcId, 'holders')}
        >
          {this.props.local.strings.holders.holdersList.editHolders}
        </a>
      </div>
    )
    return component;
  }

  renderButtonRoleEditHolder = (hcId) => {
    console.log('EDIT PQ ')
    let component = (
      <div>
        <a
          className='cell-table-link icon-edit'
          onClick={() => this.openEditHCModal(hcId, 'hc')}
        >
          {this.props.local.strings.holders.holdersList.editHC}
        </a>
      </div>
    );

    return component;
  }

  renderButtonArchiveHolder (archived, hcId) {

    let {
      archiveHolder,
      unArchiveHolder
    } = this.props.local.strings.holders.holdersList;

    let component = (!archived) ? (
      <a
        onClick={(e) => this.archiveHolder(e, hcId, 1)}
        className="cell-table-link"
      >
        {archiveHolder}
      </a>
    ) : (
      <a
        onClick={(e) => this.archiveHolder(e,hcId, 0)}
        className="cell-table-link"
        style={{ color: '#F00' }}
      >
        {unArchiveHolder}
      </a>
    )

    return component;
  }

  archiveHolder = (e, holderId, newArchivedStatus) => {
    const { 
      title, 
      text, 
      unArchiveText, 
      archiveText, 
      unArchiveTitle, 
      archiveTitle
    } = this.props.local.strings.holders.archiveAlert;

    const archivedTitle = (newArchivedStatus === 1) ? archiveTitle : unArchiveTitle;
    const archivedText = (newArchivedStatus === 1) ? archiveText : unArchiveText;

    Swal({
      title: title.replace('$1', archivedTitle),
      text: text.replace('$1', archivedText).replace('$2', holderId),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2E5965',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        this.props.commonActions.setLoading(true);
        this.props.actions.holderArchive(holderId, newArchivedStatus, () => {
          this.props.commonActions.setLoading(false);
          this.setPageFilter(null, this.state.filter.pageNumber, true);
        });
      }
    });
  }

  setHiringClientTags = (tags) => {
    const { currentSystem } = this.props.login;

    if (currentSystem === "pq") {
      this.setState({
        filterBox: {
          ...this.state.filterBox,
          hcTags: tags
        }
      });
    } else if (currentSystem === "cf") {
      this.setState({
        filterBox: {
          ...this.state.filterBox,
          holderTags: tags
        }
      });
    }
  }

  setArchiveFilterTag = (event) => {
    if (event.target.value){
      if (event.target.value !=2){
        this.setState({
          filterBox: {
            ...this.state.filterBox,
            archive: event.target.value
          }
        });
      }
      else {
        this.setState({
          filterBox: {
            ...this.state.filterBox,
            archive: 2
          }
        });
      }
    }
  }

  handleCloseSnack = () => {
    this.setState({...this.state, snack: {show: false} });
  }

  filtersResetCallback = (value) => {
    value.preventDefault();
    let newFilterBox = {};
    if(this.props.login.currentSystem === "pq") {
      newFilterBox = {
        searchTerm: '',
        hcTags: []
      };
    } else {
      newFilterBox = {
        nameTerm: '',
        contactNameTerm: '',
        archive: 0,
        holderTags: []
      };
    };


    let filters = {
      tab: 'hiringClients',
      module: 'GlobalHiringClients',
      objectId: null,
      filterBox: {
        ...this.state.filterBox,
        ...newFilterBox
      }
    }
    if(this.props.login.currentSystem === "cf") {
      this.props.filterActions.saveDefaultFilters('FilterHiringClients', filters);
    }

    this.setState(prevState => ({
        filterBox: {
          ...this.state.filterBox,
          ...newFilterBox
        }
      }),
      () => {
        this.handleSystemChange(this.props.login.currentSystem, true)
      });
  };

  filtersSaveCallback = (values) => {

    const hcTags = values.hcTags ? values.hcTags : [];
    const holderTags = values.holderTags ? values.holderTags : []

    let saveFilter = { tab: 'hiringClients' }
    saveFilter.module = 'GlobalHiringClients';
    saveFilter.objectId = null;
    let filterBox = {
      searchTerm: values.searchTerm || '',
      contactNameTerm: values.contactNameTerm || '',
      nameTerm: values.nameTerm || '',
      archive: values.archive,
      hcTags,
      holderTags
    }
    saveFilter.filterBox = filterBox;
    this.setState({
      filterBox: {
        ...filterBox
      }
    });

    this.props.filterActions.saveDefaultFilters('FilterHiringClients', saveFilter)
    .then(result => {
      const alertOptions = {
        type: result ? 'success' : 'error',
        title: result ? 'Filter saved succesfully!' : 'Error saving filters'
      };

      Swal(alertOptions);
    });
  };

  filtersDeleteCallback = () => {
    let newFilterBox = {
      searchTerm: '',
      hcTags: []
    };
    let filters = {
      tab: 'hiringClients',
      module: 'GlobalHiringClients',
      objectId: null,
      filterBox: {
        ...this.state.filterBox,
        searchTerm: '',
        hcTags: []
      }
    }
    this.props.filterActions.saveDefaultFilters('FilterHiringClients', filters);

    this.setState(prevState => ({
        filterBox: {
          ...this.state.filterBox,
          searchTerm: '',
          hcTags: []
        },
        snack: {show: true, snackMessage: 'Filters deleted successfully'}
      }),
      () => {
        this.handleSystemChange(this.props.login.currentSystem, true)
      });
  };

  render() {
    const { preQualView, certFocusView, cfRoleNotAllowed } = this.state;
    const { CFRole } = this.props.login.profile;
    let profile;

    if (preQualView) {
      profile = { Role: true, CFRole: false };
    } else if (certFocusView) {
      profile = { Role: false, CFRole: true };
    } else {
      profile = { ...this.props.login.profile };
    }

    if (profile.Role) {
      const { Role } = profile;
      if (Role.IsSCRole) {
        // SC should not be here
        return <Redirect push to="/profile" />;
      }
    }

    if (cfRoleNotAllowed) {
      Swal({
        type: 'error',
        title: 'Permission Denied',
        text: `The ${CFRole.Name} role is not allowed to access this page `,
      });
      return <Redirect push to="/profile" />;
    }

    let {
      tableHeaderHcName,
      tableHeaderRegistrationUrl,
      tableHeaderContactName,
      tableHeaderHcPhone,
      tableHeaderHcState,
      filterHC
    } = this.props.local.strings.hiringClients;

    let {
      tableHeaderParentHolder,
      tableHeaderPortalUrl,
      archiveHolder,
      unArchiveHolder
    } = this.props.local.strings.holders.holdersList;

    const prequalFields = ['state'];

    const prequalHeaders = {
      state: tableHeaderHcState,
    };

    const certfocusFields = [
      'parentHolder',
      'portalURL',
      'edit',
    ];

    const certfocusHeaders = {
      parentHolder: tableHeaderParentHolder,
      portalURL: tableHeaderPortalUrl,
      edit: '',
    };

    const tableMetadata = {
      fields: [
        'name',
        ...(profile.Role ? ['registrationUrl'] : []),
        'contactName',
        'phone',
        ...Utils.returnOnRole(profile, prequalFields, certfocusFields),
        'view',
        'archive'
      ],
      header: {
        name: tableHeaderHcName,
        registrationUrl: tableHeaderRegistrationUrl,
        contactName: tableHeaderContactName,
        phone: tableHeaderHcPhone,
        ...Utils.returnOnRole(profile, prequalHeaders, certfocusHeaders),
        view: '',
        archive: ''
      }
    };

    const tableBody = this.props.hc.list.map((hc, idx) => {
      const prequalBody = {
        state: hc.state
      };

      const certfocusBody = {
        parentHolder: hc.parentHolder,
        portalURL: hc.portalURL && (
          <a
            className='hctable-link'
            target="_blank"
            href={`http://${hc.portalURL}.portal.cf.com`}>
            {`${hc.portalURL}.portal.cf.com`}
          </a>
        ),
      };

      return {
        name: hc.name,
        registrationUrl: (
          <a
            className='hctable-link'
            target="_blank"
            href={`${process.env.REACT_APP_FRONTEND_URL}/apply/${hc.registrationUrl}`}>
            {hc.registrationUrl}
          </a>
        ),
        contactName: hc.contactName,
        phone: hc.phone && hc.phone !== '' ? Utils.formatPhoneNumber(hc.phone) : '',
        ...Utils.returnOnRole(profile, prequalBody, certfocusBody),
        edit: (
          <Fragment>
            {profile.Role &&
              <RolAccess
                masterTab="hiring_clients"
                sectionTab="edit_holder"
                component={() => this.renderButtonRoleEditHolder(hc.id)}>
              </RolAccess>
            }
            {profile.CFRole && !hc.archive && (
              <RolAccess
                masterTab="hiring_clients"
                sectionTab="edit_holder"
                component={() => this.renderButtonCFRoleEditHolder(hc.id)}>
              </RolAccess>
            )}
          </Fragment>
        ),
        view: (
          <Fragment>
            {profile.Role &&
              <RolAccess
                masterTab="hiring_clients"
                sectionTab="view_holder"
                component={() => this.renderButtonRoleViewHolder(hc.id)}>
              </RolAccess>
            }
            {profile.CFRole &&
              <RolAccess
                masterTab="hiring_clients"
                sectionTab="view_holder"
                component={() => this.renderButtonCFRoleViewHolder(hc.id)}>
              </RolAccess>
            }
          </Fragment>
        ),
        archive: profile.CFRole && (
          <RolAccess
            masterTab="hiring_clients"
            sectionTab="archive_holder"
            component={() => this.renderButtonArchiveHolder(hc.archive, hc.id)}>
          </RolAccess> 
        )  
      };
    });

    const tableData = {
      fields: tableMetadata.fields,
      header: tableMetadata.header,
      body: tableBody
    };

    let { totalAmountOfHC, HCPerPage } = this.props.hc;

    const paginationSettings = {
      total: totalAmountOfHC,
      itemsPerPage: HCPerPage,
      setPageHandler: (pageNumber, pageSize) => {
        this.props.pageSize(pageSize)
        this.setPageFilter('', pageNumber, true)
      },
      currentPageNumber: this.state.filter.pageNumber,
    };
  //console.log("hc props =>>",this.props)
    const { title: holderTitle } = this.props.local.strings.holders;

    return (
      <div className="list-view admin-view-body hiring-clients-list">
        <Modal
          show={this.props.showModal}
          onHide={() => this.closeAddHCModal('hc')}
          className="add-item-modal add-hc" >
          <Modal.Body className="add-item-modal-body">
            <AddHCModal
              onClose={this.closeAddHCModalAndRefresh}
              profile={this.state.currentHC}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.props.showHoldersModal}
          onHide={() => this.closeAddHCModal('holders')}
          className="add-item-modal add-entity" >
          <Modal.Body className="add-item-modal-body mt-0">
            <AddHolderModal
              onHide={() => this.closeAddHCModal('holders')}
              close={this.closeAddHCModalAndRefresh}
              profile={this.state.currentHC}
            />
          </Modal.Body>
        </Modal>

        {(this.props.login.profile.Role && this.props.login.profile.CFRole) ?
          <SystemSwitch
            onChange={this.handleSystemChange}/>
          : null
        }

        <div className="hiring-clients-list-header">
          <div>
            <a onClick={this.toggleFilterBox}
              className="nav-btn icon-login-door">
              {filterHC}
            </a>
          </div>

          {profile.Role && (
            <RolAccess
              masterTab="hiring_clients"
              sectionTab="add_holder"
              component={() => this.renderButtonAddHiringclient()}>
            </RolAccess>
          )}

          {profile.CFRole && (
            <Fragment>
              <RolAccess
                masterTab="hiring_clients"
                sectionTab="add_holder"
                component={() => this.renderButtonAddHolder()}>
              </RolAccess>
            </Fragment>
          )}
        </div>

        {this.state.showFilterBox ?
          <section className="list-view-filters">
            <FilterHC
              setArchiveFilterTag={this.setArchiveFilterTag}
              setHiringClientTags={this.setHiringClientTags}
              filtersSaveCallback={this.filtersSaveCallback}
              filtersResetCallback={this.filtersResetCallback}
              filtersDeleteCallback={this.filtersDeleteCallback}
              filterBox={this.state.filterBox}
              profile={profile}
              onSubmit={this.submitFilterForm}
            />
              <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                open={this.state.snack.show}
                autoHideDuration={6000}
                onClose={this.handleCloseSnack}
                message={this.state.snack.snackMessage}
                ContentProps={{
                  classes: {
                    message: 'filter-actions-snackbar-text',
                  },
                }}
                action={
                  <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleCloseSnack}>
                    <CloseIcon fontSize="small" />
                    </IconButton>
                  </React.Fragment>
                }
              />
          </section> :
          <div />
        }

        <DataTable
          name={this.props.login.currentSystem === 'pq' ? 'HiringClients' : holderTitle}
          sorted={true}
          items={tableData}
          wrapperState={this.state}
          tableOrderActive={this.state.tableOrderActive}
          clickOnColumnHeader={this.clickOnColumnHeader}
          isFetching={this.props.hc.fetchingHC}
          customClass={profile.Role && profile.CFRole ? 'hc-list-both-roles' : 'hc-list'}
          pagination={paginationSettings}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { showModal, showHoldersModal } = state.hc;
  return {
    hc: state.hc,
    local: state.localization,
    login: state.login,
    showModal,
    showHoldersModal,
    filterForm: state.form.filterHC,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    pageSize: payload => dispatch(hcActions.setPageSize(payload)),
    actions: bindActionCreators(hcActions,dispatch),
    hcProfileActions: bindActionCreators(hcProfileActions, dispatch),
    holderProfileActions: bindActionCreators(holderProfileActions, dispatch),
    departmentsActions: bindActionCreators(departmentsActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HiringClients);
