import * as types from '../actions/types';

export default function HCReducer(state = {
  errorHC: '',
  list: [],
  initialFilterBox: {
    searchTerm: '',
    contactNameTerm: '',
    nameTerm: '',
    archive: '',
    hcTags: [],
    holderTags: []
  },
  totalAmountOfHC: 0,
  HCPerPage: 10,
  fetchingHC: true,
  fetchingFilters: true,
  savingFilters: false,
  isSubmittingHC: false,
  isSubmittedHC: false,
  hcUsers: [],
  showModal: false,
  showHoldersModal: false,
  associatingHCUser: false,
  associatingOperator: false,
  serializedHcObj:  {},
  unlinkedUsers: [],
}, action) {
  switch(action.type) {
    case types.SET_HC_LIST_ERROR:
      return Object.assign({}, state, {
        errorHC: action.error
      });
    case types.SET_HC_LIST:
      return Object.assign({}, state, {
        list: action.list
      });
    case types.SET_HC_USERS:
      return Object.assign({}, state, {
        hcUsers: action.list
      });
    case types.SET_FETCHING_HC:
      return Object.assign({}, state, {
        fetchingHC: action.isFetching
      });
    case types.SET_FETCHING_FILTERS:
      return Object.assign({}, state, {
        fetchingFilters: action.isFetching
      });
    case types.SET_SAVING_FILTERS:
      return Object.assign({}, state, {
        savingFilters: action.isSaving
      });
    case types.SET_FILTER_BOX:
      return Object.assign({}, state, {
        initialFilterBox: action.filterBox
      });
    case types.SET_SUBMITTING_HC:
      return Object.assign({}, state, {
        isSubmittingHC: action.isSubmittingHC
      });
    case types.SET_SUBMITTED_HC:
      return Object.assign({}, state, {
        isSubmittedHC: action.isSubmittedHC
      });
    case types.SET_TOTAL_AMOUNT_OF_HC:
      return Object.assign({}, state, {
        totalAmountOfHC: action.HClength
      });
    case types.SET_SHOW_MODAL:
      return Object.assign({}, state, {
        showModal: action.showModal
    });
    case types.SET_SHOW_HOLDERS_MODAL:
      return Object.assign({}, state, {
        showHoldersModal: action.showModal
    });
    case types.SET_ASSOCIATING_HCUSER:
      return Object.assign({}, state, {
        associatingHCUser: action.associating
    });
    case types.SET_ASSOCIATING_OPERATOR:
      return Object.assign({}, state, {
        associatingOperator: action.associating
    });
    case types.SET_PAGE_SIZE:
      return Object.assign({}, state, {
        HCPerPage: action.payload
    });
    case types.ADD_SERIALIZED_HC_DATA:
      return {
        ...state, 
        serializedHcObj: {
          ...state.serializedHcObj,
          ...action.serializedHcObj
        }
      };
    default:
      return state;
  }
};