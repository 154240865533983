export default (values, props) => {
  const {
    requiredValidation,
	} = props.local.strings.scProfile.notesTasks.modal;  
  const errors = {};

  if(!values.name) {
    errors.name = requiredValidation;
  }
  if(!values.description) {
    errors.description = requiredValidation;
  }

  return errors;
};