import _ from 'lodash';

import Api from '../../../../lib/api';
import Utils from '../../../../lib/utils';
import {setUserErrorMessage} from '../../actions';

const loadOptions = ({dispatch, local, tempToken}) => {
  return async (currentValue, prevOptions, {page}) => {
    const next = {
      options: prevOptions,
      hasMore: false,
      additional: {page}
    };

    try {
      const params = new URLSearchParams({title: currentValue, page});
      const response = await Api.get(`titles?${params}`, tempToken);
      const {results, counts} = response.data.data;
      const parse = ({title: label, id: value}) => ({label, value});
      next.options = results.map(parse);
      next.hasMore = counts.hasNextPage > 0 && results.length > 0;
      next.additional = {page: next.hasMore ? page + 1 : page};
    } catch (error) {
      if (_.get(error, 'response.data.data')) {
        dispatch(setUserErrorMessage(Utils.getErrorMessage(
          error.response.data, 
          local.actions
        )));
      } else {
        dispatch(setUserErrorMessage(local.actions.errorDefault));
      }
    }

    return next;
  };
};

export default loadOptions;