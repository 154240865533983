import * as types from './types';

const generFilterBoxReducer = (state = {
    loading: false,
    filterbox: {},
    error: null
}, action) => {
    switch (action.type) {
        case types.SET_GENERIAL_FETCHING_FILTER:
            return {
                ...state,
                loading: action.isFetching,
            }
        case types.SET_GENERIAL_FILTER_BOX_SUCCESS:
            return {
                error: null,
                filterbox: { ...state.filterbox, [action.filterBoxTag]: action.filterBox },
                loading: false
            };
        case types.SET_GENERIAL_FILTER_BOX_ERROR:
            return {
                filterbox: {},
                error: action.error,
                loading: false
            };
        default:
            return state

    }

}

export default generFilterBoxReducer;