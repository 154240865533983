import Api from "../../../../lib/api";

export const generateMissingDeficiencies = ({
  projectInsuredId,
  requirementSetId,
  certificateId,
}) => {
  return Api.post(`cf/certificates/deficiencies/generateMissingDeficiencies`, {
    projectInsuredId,
    requirementSetId,
    certificateId,
  });
};