import * as types from '../actions/types';

const INITIAL_STATE = {
  fetchingRegistrationSettings: false,
  fetchRegistrationSettingsError: '',
  postRegistrationSettingsError: '',
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case types.SET_FETCHING_REGISTRATION_SETTINGS:
      return { ...state, fetchingRegistrationSettings: action.payload, postRegistrationSettingsError: '', fetchRegistrationSettingsError: '' };

    case types.SET_FETCH_REGISTRATION_SETTINGS_ERROR:
        return { ...state, fetchingRegistrationSettings: false, fetchRegistrationSettingsError: action.payload };
    
    case types.SET_POST_REGISTRATION_SETTINGS_ERROR:
      return { ...state, postRegistrationSettingsError: action.payload, fetchingRegistrationSettings: false };

    default:
      return state;
  }
};
