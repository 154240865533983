import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { searchInsureds } from '../../../certfocus/insureds/actions'
import { withRouter } from "react-router-dom";

class SearchBar extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      searchTerm: '',
      searchPlaceholder: `Insured Search`
    }
    this.handleInput = this.handleInput.bind(this);
  }

  handleInput(e, click) {
    if (click || e.key === 'Enter') {

      if (this.state.searchTerm.trim() === '' ) {
        this.setState({
          searchPlaceholder: ' Input is needed',
          searchTerm: ''
        })
      } else {
        this.setState({
          searchPlaceholder: `Insured Search`,
        }, () => {
          this.props.searchInsureds(this.state.searchTerm)
          this.setState({searchTerm: ''})
          this.props.history.push('/certfocus/insureds')
        })
      }
    }
  }

  render() {
    let { searchPlaceholder } = this.state
    const userMode = localStorage.getItem("currentSystem")
    if(userMode === 'cf'){
      return (
        <div className="searchWrapper">
        <input
          name="subcontractorSearch"
          type="text"
          value={this.state.searchTerm}
          onChange={(e)=>this.setState({ searchTerm: e.target.value })}
          placeholder={searchPlaceholder}
          onKeyPress={this.handleInput}
          className="search_subcontractors" />
        <div className='magnifier' 
        onClick={e => this.handleInput(e,'click')}
        >
          <span className='linear-icon-magnifier-alt2'></span>
        </div>
      </div>
    );
  } 
  return <Fragment/>
  };
};


const mapStateToProps = (state) => {
  return {
    login: state.login
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchInsureds: bindActionCreators(searchInsureds, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchBar));
