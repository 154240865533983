import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import Utils from '../../../../../../lib/utils';

import renderField from '../../../../../customInputs/renderField';
import renderSelect from '../../../../../customInputs/renderSelect';
import validate from './validation';
import * as registerActions from '../../../../../register/actions';
import InsurersSuggestions from '../../../DataEntry/insurersSuggestions';

class InsurerForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      createInsurer: false,
      AMBestInsurer: {
        rating: '',
        financial: ''
      }
    };
  }

  componentDidMount() {
    const { insurer, dispatch } = this.props;    
    this.props.registerActions.fetchGeoStates();

    if (insurer) {
      dispatch(change('InsurerForm', 'insurerName', insurer.Name || ''));
      dispatch(change('InsurerForm', 'NAICCompanyNumber', insurer.NAICCompanyNumber || ''));
    }
  }

  renderFormField(element, idx) {
    const {
      name,
      label,
      ph,
      type,
      conditional,
      show,
      options,
      disabled,
    } = element;

    const style = {};
    if (conditional && !show) {
      style.display = 'none';
    }

    return (
      <div className="col-md no-padd" key={idx} style={style}>
        <div className="admin-form-field-wrapper keywords-field">
          <label htmlFor={name}>{`${label}:`}</label>
          {
          options?
          <div className="select-wrapper">
            <Field
              name={name}
              component={renderSelect}
              options={options}
              disabled={disabled}
            />
          </div>
          :
          <Field
            name={name}
            type="text"
            placeholder={ph}
            disabled={disabled}
            component={renderField}
          />
        }
        </div>
      </div>
    );
  }

  render() {
    const { 
      handleSubmit,
      register
    } = this.props;

    const {
      cancelButton,
      saveButton
    } = this.props.local.strings.processing.dataEntry;

    const {
      nameLabel,
      NAICCompanyNumberLabel,
    } = this.props.local.strings.processing.dataEntry.insurer;

    const fields = [
      { name: 'NAICCompanyNumber', label: NAICCompanyNumberLabel, ph: `-- ${NAICCompanyNumberLabel} --`, disabled: !this.state.createInsurer },
      { name: 'insurerName', label: nameLabel, ph: `-- ${nameLabel} --`, disabled: !this.state.createInsurer },
    ];

    const enableCreateInsurer = () => {
      setInsurer({});
      this.setState({
        createInsurer: true,
      })
    }


    const setInsurer = (data) => {
    //console.log('saving insurer: ',data);

      const { dispatch } = this.props;
      dispatch(change('InsurerForm', 'AMBestRating', data.rating || ''));
      dispatch(change('InsurerForm', 'AMBestFinancial', data.financial || ''));
      dispatch(change('InsurerForm', 'insurerName', data.Name || ''));
      dispatch(change('InsurerForm', 'NAICCompanyNumber', data.NAIC || ''));
      dispatch(change('InsurerForm', 'AMBestCompanyNumber', data.AMBestNumber || ''));
    }

    return (
      <form
        autoComplete="off"
        className="entity-info-form"
        onSubmit={handleSubmit}
      >
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-12">
              <InsurersSuggestions
                handleInsurer={setInsurer}
                suggestions={this.props.insurersSuggestions}
                handleEnableNewInsurer={enableCreateInsurer}
              />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-12">
              <hr/>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-12">
              {fields.map(this.renderFormField)}
            </div>
          </div>

        </div>

        {this.props.processing.addInsurerError &&
          <div className="error-item-form">
            {this.props.processing.addInsurerError}
          </div>
        }
        <div className="add-item-bn">
          <button
            className="bn bn-small bg-green-dark-gradient create-item-bn icon-save"
            type="submit" >
            {saveButton}
          </button>
          <a
            className="cancel-add-item"
            onClick={this.props.close} >
            {cancelButton}
          </a>
        </div>

      </form>
    );
  }
};

InsurerForm = reduxForm({
  form: 'InsurerForm',
  validate,
})(InsurerForm);

const mapStateToProps = (state) => {
  return {
    local: state.localization,
    common: state.common,
    processing: state.processing,
    register: state.register,
    insurersSuggestions: state.processing.insurersSuggestions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerActions: bindActionCreators(registerActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InsurerForm);
