// sets
export const SET_SAVED_FORMS_LIST_ERROR = 'SET_SAVED_FORMS_LIST_ERROR';
export const SET_SAVED_FORMS_LIST = 'SET_SAVED_FORMS_LIST';
export const SET_FETCHING_SAVED_FORMS = 'SET_FETCHING_SAVED_FORMS';
export const SET_TOTAL_AMOUNT_OF_SAVED_FORMS = 'SET_TOTAL_AMOUNT_OF_SAVED_FORMS';
export const SET_SAVED_FORMS_LENGTH = 'SET_SAVED_FORMS_LENGTH';
export const SET_SAVED_FORM_CREATOR_USERS = 'SET_SAVED_FORM_CREATOR_USERS';
export const SET_SAVED_FORM_SC_SENT_TO = 'SET_SAVED_FORM_SC_SENT_TO';
export const SET_FORM_ID_FOR_FETCHING = 'SET_FORM_ID_FOR_FETCHING';
export const SET_FORM_SUBMISSION_LINK = 'SET_FORM_SUBMISSION_LINK';
export const SET_FETCH_FORM_DISCREET_ACCOUNT = 'SET_FETCH_FORM_DISCREET_ACCOUNT';
export const SET_FORM_DISCREET_ACCOUNT_SELECTED = 'SET_FORM_DISCREET_ACCOUNT_SELECTED';
