import React from 'react';
import {connect} from 'react-redux';
import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import LogoButton from './logoButton';
import { bindActionCreators } from 'redux';
import * as actions from '../../../certfocus/holders-profile/tabs/custom-fields/actions';
import Utils from '../../../../lib/utils';
import { useParams, withRouter } from 'react-router-dom';

const toolbar = {
  options: ['inline', 'list', 'textAlign', 'link', 'image',],
  inline: {
    inDropdown: false,
    className: 'editor-btn-group',
    component: undefined,
    dropdownClassName: undefined,
    options: ['bold', 'italic', 'underline',],
    bold: { className: 'editor-btn-left' },
    italic: { className: 'editor-btn' },
    underline: { className: 'editor-btn-right' }
  },
  list: {
    inDropdown: false,
    className: 'editor-btn-group',
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered', 'ordered', 'indent', 'outdent'],
    unordered: { className: 'editor-btn-left' },
    ordered: { className: 'editor-btn  icon-numbered-list' },
    indent: { className: 'editor-btn icon-increase-indent' },
    outdent: { className: 'editor-btn-right icon-decrease-indent' },
  },
  textAlign: {
    inDropdown: false,
    className: 'editor-btn-group',
    component: undefined,
    dropdownClassName: undefined,
    options: ['left', 'center', 'right', 'justify'],
    left: { className: 'editor-btn-left' },
    center: { className: 'editor-btn' },
    right: { className: 'editor-btn' },
    justify: { className: 'editor-btn-right' },
  },
  link: {
    inDropdown: false,
    className: 'editor-btn-group ',
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: '_self',
    options: ['link'],
    link: { className: 'editor-btn-alone icon-link' }
  },
  image: {
    className: 'editor-action-btn icon-img',
    component: undefined,
    popupClassName: undefined,
    urlEnabled: true,
    uploadEnabled: false,
    alignmentEnabled: true,
    uploadCallback: undefined,
    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
    alt: { present: false, mandatory: false },
    defaultSize: {
      height: 'auto',
      width: 'auto',
    },
  }
};

class TemplateEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: ''
    };
    this.setDomEditorRef = ref => this.domEditor = ref;
    let editorState;

    const tempUrlPlaceholder = "https://temp-url-placeholder.com";

    if (props.templateBuilder.template.bodyHTML) {
      let updatedBodyHTML = props.templateBuilder.template.bodyHTML.replace(
        "<%= url_registration %>",
        tempUrlPlaceholder
      );
      const blocksFromHTML = convertFromHTML(updatedBodyHTML);
      if (blocksFromHTML.contentBlocks) {
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        editorState = EditorState.createWithContent(state);
      } else {
        editorState = EditorState.createEmpty();
      }
    } else {
      editorState = EditorState.createEmpty();
    }    

    props.setTemplateEditorState(editorState);

    this.onChange = (editorState) => {
      props.setTemplateEditorState(editorState);
    };
    let query = Utils.getFetchQuery('customFieldName', 1, 'ASC');
    query.holderId = props.match.params.id;
    query.withoutPag = true
    props.actions.fetchCustomFields(query);
  };

  componentDidMount(){
    this.domEditor.focusEditor();
  };

  focus = () => {
    this.domEditor.focusEditor();
  }

  onSearchTextChange = (e) => {
    const newState = this.state;
    newState.searchText = e.target.value;
    this.setState(newState);
  }

  renderPlaceholder = (ph, idx) => {
    const onDragStart = function(e) {
      let txt = `<%= ${ph.placeholder} %>`;
      e.dataTransfer.setData("text", txt);
    };

    return <a key={idx} className='side-menu-btn' draggable="true" onDragStart={onDragStart} >{ph.name}</a>
  }

  getSuggestedPlaceholders = () => {
    const suggestions = Utils.searchItems(this.props.templateBuilder.commPlaceholders, this.state.searchText);
    return suggestions.slice(0,9);
  }

  getCustomFieldsPlaceholders = () => {
    const {list} = this.props.customFields;
    let customFieldsCode = [];
    if(list && list.length > 0 ){
      list.map((field) => {
        customFieldsCode.push({id : field.CustomFieldId, name : field.CustomFieldName, placeholder: field.CustomFieldName.replace(/\s/g, '_')})
      })
    }
    const suggestions = Utils.searchItems(customFieldsCode, this.state.searchText);
    return suggestions.slice(0,9);
  }

  render() {
    const placeholders = this.getSuggestedPlaceholders();
    const customCodePlaceholders = this.getCustomFieldsPlaceholders();

    return (
      <div className="editorContainer">
        <div className="editor-column">
          <div className="editor" onClick={this.focus} >
            <Editor
              editorState={this.props.templateBuilder.editorState}
              toolbarClassName="editorHeader"
              wrapperClassName="wrapperClassName"
              editorClassName="editor-class"
              onEditorStateChange={this.onChange}
              toolbar={toolbar}
              toolbarCustomButtons={[<LogoButton />]}
              ref={this.setDomEditorRef}
            />
          </div>
        </div>
        <div className="editor-side-menu">
          <p className="side-menu-subtitle">INSERT SHORT CODE:</p>
          <input
            value={this.state.searchText}
            placeholder="Search Short Code"
            onChange={this.onSearchTextChange}
          />
          <p>System Short Codes:</p>
          <div className="placeholdersContainer">
            { placeholders.map(this.renderPlaceholder) }
          </div>
          <p>Add Custom Short Codes:</p>
          <div className="placeholdersContainer">
            { customCodePlaceholders.map(this.renderPlaceholder) }
          </div>
        </div>
      </div>
    );
  };
};

const mapStateToProps = (state, ownProps) => {
  const { templateBuilder } = state;
  return {
    templateBuilder,
    customFields: state.customFields,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
};
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(TemplateEditor));
