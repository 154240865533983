import { FORM_IO_INCOMPLETE_FORM_STATUS } from "../../../lib/appConstants";

export default class FormIOUtils {
  static getFormButtonSettings(role, formStatus) {
    let readOnly = true;
    let hideSubmit = false;
    let hideSave = false;
  
    if (role && role.IsSCRole) {
      if (formStatus === FORM_IO_INCOMPLETE_FORM_STATUS) {
        readOnly = false;
      } else {
        hideSubmit = true;
        hideSave = true;
      }
    } else if (role && role.IsHCRole) {
      readOnly = true;
      hideSubmit = true;
      hideSave = true;
    } else {
      if (formStatus === FORM_IO_INCOMPLETE_FORM_STATUS) {
        readOnly = false;
      } else {
        readOnly = false;
        hideSubmit = true;
      }
    }
    return { readOnly, hideSubmit, hideSave };
  }
}
