import React, { useEffect, useState } from 'react';
import { Field, reduxForm ,touch, change, reset} from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as commonActions from '../../../common/actions';
import Utils from '../../../../lib/utils';
import renderField from '../../../customInputs/renderField';
import renderSelect from '../../../customInputs/renderSelect';
import FilterActions from '../../../common/filterActions/FilterActions';
import {AsyncPaginate} from 'react-select-async-paginate';
import * as loadApplyOptions from '../../../common/countyDropdownActions/loadOptions'
import * as applyActions from '../../../common/countyDropdownActions/actions'
import _ from 'lodash';
import * as filterBoxActions from '../../filterbox/actions';
import * as actions from '../actions';


const SelectAsyncOptions = ({ input, meta, dispatch, ...props }) => {
  const touchedError = meta.touched && meta.error;
  const name = input.name;
  const baseClassName = `${props.className || ''} select-${name}`;
  const errorClassName = `${touchedError ? `${baseClassName}-error` : ''}`;

  return (
    <React.Fragment>
      <AsyncPaginate
        {...input}
        {...props}
        openMenuOnClick={true}
        closeMenuOnScroll={true}
        cacheOptions={true}
        pageSize={10}
        additional={{ page: 1 }}
        classNamePrefix="vk"
        className={`${baseClassName} ${errorClassName}`}
        menuPlacement="auto"
        onFocus={(_) => { }}
        onBlur={() => meta.dispatch(touch('FilterProjects', name))}
        onChange={(newValue) => input.onChange(newValue)}
        captureMenuScroll={true}
        closeMenuOnSelect={true}
        debounceTimeout={300}
        loadOptionsOnMenuOpen={true}
      />
      {touchedError && (
        <span className="select-error-message field-validation-message">
          {meta.error}
        </span>
      )}
    </React.Fragment>
  );
};

let FilterInsureds = props => {
  const {
    title,
    insuredLabel,
    projectLabel,
    holderLabel,
    stateLabel,
    countryLabel,
    vendorNumberLabel,
    tagsLabel,
  } = props.local.strings.insured.insuredList.filter;

  const { handleSubmit } = props;

  const archivedOptions = [
    { label: '-- Select option --', value: '' },
    { label: 'False', value: 0, selected: true },
    { label: 'True', value: 1 },
    { label: 'N/A', value: '' },
  ];
  const [selectedCountry,setselectedCountry] = useState(null)
  
  useEffect(() => {
    preloadFilters()
  }, []);

  const countryId = _.get(props, 'currentForm.FilterInsureds.values.country.value', null);

  useEffect(() => {
    setselectedCountry(countryId)
  }, [countryId]);

  const preloadFilters = () => {
    let saveFilter = { tab: 'insureds' }
    saveFilter.module = 'GlobalInsureds';
    saveFilter.objectId = null;
    props.filterBoxActions.fetchDefaultFilters(saveFilter, 'FilterInsureds', (filters) => {
      let query = { ...filters };
      if (filters.countryId) {
        query.countryId = filters.countryId.value;
        setselectedCountry(filters.countryId.value);
      }
      if (filters.stateId) {
        query.stateId = filters.stateId.value;
      }
      if (!filters.archive) {
        query.archive = 0
      }
      props.fetchInsureds(props.pageNumber, props.pageSize, props.orderBy, props.orderDirection, query);
    });
  }

  const filtersResetCallback = async (values) => {
    values.preventDefault()
    const saveFiltersPromise = new Promise(async (res, rej) => {
      props.dispatch(reset(props.formName));
      let filters = {
        tab: 'insureds',
        module: 'GlobalInsureds',
        objectId: null,
        filterBox: ''
      }
      return res(await props.filterBoxActions.saveDefaultFilters('FilterInsureds', filters));
    }).then(async () => {
      await preloadFilters()
    });

    await Promise.all([saveFiltersPromise])
  }

  const resetStateValue = (data) => {
    if(selectedCountry != data.value){
      props.dispatch(change(
        'FilterInsureds',
        'country',
        {value:null, label:''}
      ));
    }

    if(selectedCountry != data.value){
      props.dispatch(change(
        'FilterInsureds',
        'state',
        {value:null, label:''}
      ));
    }
    setselectedCountry(data.value)
  };

  const stateOptions = props.actions.loadStates(props,selectedCountry);

  return (
    <form onSubmit={handleSubmit} className="list-view-filter-form">
      <h2 className="list-view-filter-title">{title}</h2>
      <div className="container-fluid filter-fields">
        <div className="row">
          <div className="col-md no-padd">
            <div className="admin-form-field-wrapper keywords-field">
              <label htmlFor="insuredName">{insuredLabel}: </label>
              <Field
                name="insuredName"
                placeholder={insuredLabel}
                component={renderField}
                className="tags-input"
              />
            </div>
          </div>
          <div className="col-md no-padd">
            <div className="admin-form-field-wrapper keywords-field">
              <label htmlFor="projectName">{projectLabel}: </label>
              <Field
                name="projectName"
                placeholder={projectLabel}
                component={renderField}
                className="tags-input"
              />
            </div>
          </div>
          <div className="col-md no-padd">
            <div className="admin-form-field-wrapper keywords-field">
              <label htmlFor="holderName">{holderLabel}: </label>
              <Field
                name="holderName"
                placeholder={holderLabel}
                component={renderField}
                className="tags-input"
              />
            </div>
          </div>
          <div className="col-md no-padd">
            <div className="admin-form-field-wrapper keywords-field">
              <label htmlFor="country">{countryLabel}: </label>
              <Field
                name="country"
                component={SelectAsyncOptions}
                placeholder='Select Country'
                onChange={(values)=>resetStateValue(values)}
                loadOptions={props.actions.loadCountries(props, selectedCountry)}
                className="tags-input"
              />
            </div>
          </div>
          {countryId ? 
          <div className="col-md no-padd">
            <div className="admin-form-field-wrapper keywords-field">
              <label htmlFor="state">{stateLabel}: </label>
              <Field
                name="state"
                component={SelectAsyncOptions}
                placeholder='Select State'
                cacheUniqs={[selectedCountry]}
                loadOptions={stateOptions}
                className="tags-input"
                />
            </div>
          </div>
          :null}
          <div className="col-md no-padd">
            <div className="admin-form-field-wrapper keywords-field">
              <label htmlFor="vendor">{vendorNumberLabel}: </label>
              <Field
                name="vendor"
                placeholder={vendorNumberLabel}
                component={renderField}
                className="tags-input"
              />
            </div>
          </div>
          <div className="col-md no-padd">
            <div className="admin-form-field-wrapper keywords-field">
              <label htmlFor="archived">{'Archive'}: </label>
              <div className="select-wrapper">
                <Field
                  name="archive"
                  component={renderSelect}
                  options={archivedOptions}
                />
              </div>
            </div>
          </div>
          <div className="col-md no-padd">
            <div className="admin-form-field-wrapper keywords-field">
              <label htmlFor="tags">{tagsLabel}: </label>
              <Field
                name="tags"
                placeholder={tagsLabel}
                component={renderField}
                className="tags-input"
              />
            </div>
          </div>

        </div>

        <div className="row">
          <div className="col-md-12 d-flex justify-content-end">
            <FilterActions
              formName={props.form}
              dispatch={props.dispatch}
              filtersResetCallback={filtersResetCallback}
            />
          </div>
        </div>

      </div>
    </form>

  );
}

FilterInsureds = reduxForm({
  form: 'FilterInsureds',
  enableReinitialize : true,
})(FilterInsureds);

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      ...bindActionCreators(applyActions, dispatch),
      ...loadApplyOptions
    },
    commonActions: bindActionCreators(commonActions, dispatch),
    filterBoxActions : bindActionCreators(filterBoxActions, dispatch),
    insuredActions: bindActionCreators(actions, dispatch),
  }
}

const mapStateToProps = (state) => {
  const { filterbox } = state.filterBox;
  return {
    register: state.register,
    local: state.localization,
    common: state.common,
    currentForm: state.form,
    initialValues: ((filterbox && filterbox.FilterInsureds && typeof filterbox.FilterInsureds === 'object') ? {
      ...filterbox.FilterInsureds,
      state: filterbox.FilterInsureds.stateId,
      country: filterbox.FilterInsureds.countryId,
    } : {
      archive: "0"
    })
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterInsureds);