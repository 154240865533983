import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, TableCell, TableRow } from '@material-ui/core';
import * as actions from './actions';
import MUIDataTable from 'mui-datatables';
import Utils from '../../../lib/utils';
import './ambestImportHistory.css';
import AMBestImportHistoryToolbar from './AMBestImportHistoryToolbar';

const AMBestImportHistory = props => {
  const {handleClose, currentImport : {ID, ImportDate, Mail}, authToken} = props;

  const [importDetail, setImportDetail] = useState([]);


  useEffect( () => {
    (async function loadData () {
      try {
        const response = await actions.getImportHistory(ID, authToken);
        const { success, data } = response.data;
        if (success) setImportDetail(data);
      } catch (e) {
        console.error('Could not load AM Best import history');
      }
    })();
  }, []);

  const { headers, title } = props.locale.table;

  const columns = [
    { name: 'Action', label: headers.importAction, options: { filterType: 'dropdown'} },
    { name: 'NAICCompanyNumber', label: headers.naicNumber },
    {name: 'AMBestRatingAlpha', label: headers.rating, options: {filterType: 'dropdown'}},
    {name: 'AMBestFinancialAlpha', label: headers.financial, options: {filterType: 'dropdown'}},
    {name: 'EffectiveDate', label: headers.effectiveDate,
      options: {
        customBodyRender: value => formatDate(value),
      }
    },
    {name: 'CompanyName', label: headers.insuredName},
  ];
  let options = Utils.MUITableDefaultConfig(columns);
  options = {...options,
    download: true,
    expandableRows:true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    isRowExpandable: (dataIndex, expandedRows) => {
      if (!importDetail[dataIndex].OriginalData) return false;

      return true;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const { NAICCompanyNumber, AMBestRatingAlpha, AMBestFinancialAlpha, EffectiveDate, CompanyName } = importDetail[rowMeta.dataIndex].OriginalData;
      return (
        <TableRow className={'originalData'}>
          <TableCell colSpan={2}></TableCell>
          <TableCell className={(NAICCompanyNumber != importDetail[rowMeta.dataIndex].NAICCompanyNumber)? 'hasChanged' : null} >{NAICCompanyNumber}</TableCell>
          <TableCell className={(AMBestRatingAlpha != importDetail[rowMeta.dataIndex].AMBestRatingAlpha)? 'hasChanged' : null} >{AMBestRatingAlpha}</TableCell>
          <TableCell className={(AMBestFinancialAlpha != importDetail[rowMeta.dataIndex].AMBestFinancialAlpha)? 'hasChanged' : null} >{AMBestFinancialAlpha}</TableCell>
          <TableCell className={(formatDate(EffectiveDate) != formatDate(importDetail[rowMeta.dataIndex].EffectiveDate))? 'hasChanged' : null} >{formatDate(EffectiveDate)}</TableCell>
          <TableCell className={(CompanyName != importDetail[rowMeta.dataIndex].CompanyName)? 'hasChanged' : null} >{CompanyName}</TableCell>
        </TableRow>
      );
    },
    customToolbar: () => {
      return (
        <AMBestImportHistoryToolbar
          handleCloseClick={handleClose}
        />
      );
    }
  }

  const formatDate = (date) => new Date(date).toLocaleDateString('en-us', {year: 'numeric', month: '2-digit', day: '2-digit' })

  return (
      <Grid container alignItems={'center'} style={{padding: 8}}>
        <Grid item xs={12}>
          <MUIDataTable
            title={title.replace('$1',ImportDate).replace('$2',Mail)}
            data={importDetail}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
  );
};

AMBestImportHistory.propTypes = {
  handleClose: PropTypes.func.isRequired,
  authToken: PropTypes.string.isRequired,
  currentImport: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
};

export default AMBestImportHistory;