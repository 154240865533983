import React from 'react';
import { withRouter } from 'react-router-dom';
import { FormEdit as FormEditComponent, Errors } from '@formio/react';
import _ from 'lodash';
import Utils from '../../../../../../lib/utils';
import * as formioActions from '../../../../actions';
import * as formsActions from '../../../../../formBuilder/actions';
import * as commonActions from '../../../../../common/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FormTemplateProperties from './FormTemplateProperties';
import FormNavbar from './FormNavbar';

class FormEdit extends React.Component {
  constructor(props) {
    super(props);
    
    const locationState = props.location.state || {};

    this.state = {
      id: null,
      hiringClientId: '',
      subcontractorFee: '',
      description: '',
      needsUpdate: true,
      previousUrl: locationState.hcId && `/hiringclients/${locationState.hcId}`
    };
  }

  componentDidMount() {
    this.props.formioActions.getForm(this.props.match.params.formId);
    this.props.commonActions.fetchUserHiringClients();

    const selectedForm = this.props.forms.list.find(form => form.formId === this.props.match.params.formId);

    if (selectedForm) {
      const { id, hiringClientId, subcontractorFee, description } = selectedForm;
      const formattedSubcontractorFee = subcontractorFee && Utils.formatCurrency(subcontractorFee);
      this.setState({ id, hiringClientId, subcontractorFee: formattedSubcontractorFee, description });
    };
  }

  onInputChange = (_type, _value) => {
    var tempForm = {...this.state};

    if (_type === 'subcontractorFee') {
      this.setState({[_type]: Utils.formatCurrency(_value)})
    } else {
      tempForm[_type] = _value;
      this.setState({[_type]: _value})
    }
  }

  render() {
    const {
      id,
      hiringClientId,
      subcontractorFee,
      description
    } = this.state;

    const onSaveForm = (form) => this.props.formioActions.saveForm(form, (err, form) => {
      if (!err) {
        if (this.state.id) {
          let tempForm = {...this.state};
          tempForm.name = form.title;
          tempForm.formId = form._id;

          this.props.formsActions.saveForm(tempForm);
          this.props.history.push(`/hiringclients/${tempForm.hiringClientId}`);
        } else {
          alert("Form saved");
        }
      }
    });
    return (
      <div className="container" id="main">
        <FormNavbar formId={this.props.formioForms.form._id} previousUrl={this.state.previousUrl} hcId={this.locationState && this.locationState.hcId && this.locationState.hcId || ''} {...this.props}/>
        <h2>Edit {this.props.formioForms.form.title} Form</h2>
        <hr />
        <Errors errors={[this.props.formioForms.error]} />
        {id && <FormTemplateProperties
          disableHcDropdown
          onInputChange={this.onInputChange}
          userHiringClients={this.props.common.userHiringClients}
          form={{ hiringClientId, subcontractorFee, description }}
        />}
        <FormEditComponent form={_.cloneDeep(this.props.formioForms.form)} saveText="Save Form" saveForm={onSaveForm} />
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
    forms: state.forms,
    formioForms: state.formioForms,
    profile: state.login.profile
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    formioActions: bindActionCreators(formioActions, dispatch),
    formsActions: bindActionCreators(formsActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormEdit));
