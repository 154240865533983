import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../../actions';

import './changeStatusModal.css';
import _ from 'lodash'
import { SELF_SERVICE_ANALYST, SELF_SERVICE_OPERATOR, SUBCONTRATOR_STATUS_MIGRATION_TEMP, SUBCONTRATOR_STATUS_NONE, SUBCONTRATOR_STATUS_PENDING_CONVID_FORM, SUBCONTRATOR_STATUS_PENDING_CONVID_UPDATE, SUBCONTRATOR_STATUS_PENDING_INVITATION_REMEDIATION, SUBCONTRATOR_STATUS_PREQUALIFICATION_COMPLETED } from '../../../../lib/appConstants';

class ChangeStatusModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      statusId: this.props.currentStatus || '',
      processing: false,
      subcontratorStatusPermissions : [
        {roleId : SELF_SERVICE_OPERATOR , disabledSubcontratorStatus : [
          SUBCONTRATOR_STATUS_NONE, 
          SUBCONTRATOR_STATUS_PREQUALIFICATION_COMPLETED, 
          SUBCONTRATOR_STATUS_PENDING_INVITATION_REMEDIATION, 
          SUBCONTRATOR_STATUS_MIGRATION_TEMP, 
          SUBCONTRATOR_STATUS_PENDING_CONVID_FORM, 
          SUBCONTRATOR_STATUS_PENDING_CONVID_UPDATE ]},
        {roleId : SELF_SERVICE_ANALYST , disabledSubcontratorStatus : [
          SUBCONTRATOR_STATUS_NONE, 
          SUBCONTRATOR_STATUS_PREQUALIFICATION_COMPLETED, 
          SUBCONTRATOR_STATUS_PENDING_INVITATION_REMEDIATION, 
          SUBCONTRATOR_STATUS_MIGRATION_TEMP, 
          SUBCONTRATOR_STATUS_PENDING_CONVID_FORM, 
          SUBCONTRATOR_STATUS_PENDING_CONVID_UPDATE ]}
        ]
    }

    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onSave = this.onSave.bind(this);
  };

  onChangeStatus(e) {
    this.setState({statusId: e});
  }

  onSave() {
    this.setState({ processing: true });
    const 
      { hcId, scId, userId } = this.props,
      { statusId } = this.state;
    
    const payload = {
      hiringClientId: hcId,
      subcontractorId: scId,
      subcontractorStatusId: statusId,
      userId
    }

    this.props.actions.changeStatus(payload, (success) => {
      if (success) {
        this.props.successCallback(this.props.close);
      } else {
        this.setState({ processing: false });
      }
    });

  }

  render() {
    const {
      title,
      statusLabel,
      cancelBtn,
      saveBtn
    } = this.props.local.strings.scProfile.changeStatusModal;
    const loggedinUserRole = this.props.loggedInUser.profile.RoleID
    const userPermissionsRestrictions = _.find(this.state.subcontratorStatusPermissions, { 'roleId': loggedinUserRole })

    return (
      <div className="change-status-modal">
        <header className="change-status-modal-header">{title}</header>

        <div className="change-status-content container-fluid filter-fields">
          <div className="row">
            <div className="col-md-12">
              {
                this.state.processing?
                  <div className="spinner-wrapper">
                    <div className="spinner"></div>
                  </div>
                  :
                  <div className="statusContainer" >
                    <div>
                      <label>{statusLabel}:</label>
                    </div>
                    <select
                      value={this.state.statusId}
                      onChange={(e) => {this.onChangeStatus(e.target.value)}}>
                      {
                        this.props.sc.subcontratorStatus.map((item, idx) => {
                          if(userPermissionsRestrictions) {
                           const notSelectableStatusId = userPermissionsRestrictions.disabledSubcontratorStatus.includes(item.Id)
                           if(notSelectableStatusId && this.state.statusId !== Number(item.Id) ) return null
                          }
                          return <option key={idx} value={item.Id}>{item.Status}</option>
                        })
                      }
                    </select>
                  </div>
              }
            </div>
          </div>

          {
            !this.state.processing?
              <div className="text-right">
                <button className="bg-sky-blue-gradient bn" onClick={this.props.close}>{cancelBtn}</button>
                <button className="bg-sky-blue-gradient bn action-button" onClick={this.onSave}>{saveBtn}</button>
              </div>
              :
              null
          }

        </div>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    sc: state.sc,
    local: state.localization,
    loggedInUser: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeStatusModal);
