import React from 'react';
import { Field, change, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import Utils from '../../../lib/utils';
import FilterActions from '../../common/filterActions/FilterActions'
import renderField from '../../customInputs/renderField';
import renderSelect from '../../customInputs/renderSelect';
import SystemSwitch from "../../auth/systemSwitch";
import * as filterActions from '../../certfocus/filterbox/actions'
import { bindActionCreators } from 'redux';
import * as usersActions from '../actions'
import _ from 'lodash';



let FilterUsers = props => {

  let {
    title,
    keywordsLabel,
    keywordsPlaceholder,
    HClabel,
    HCPlaceholder,
    userTypeLabel,
    userTypePlaceholder
  } = props.local.strings.users.filter;
  const { PQRole, CFRole } = props;
  const { fromHCtab, fromSCtab, fromHolderTab, fromProjectTab, fromAdmin } = props;
  //const fromPQTab = fromHCtab || fromSCtab;
  //const fromCFTab = fromHolderTab || fromProjectTab;

  const pqRoleId = localStorage.getItem('pqRoleId');

  // hiring clients options
  const hiringClients = props.hiringClients.slice(1, props.hiringClients.length);
  const hcOptionsList = Utils.getOptionsList(HCPlaceholder, hiringClients, 'label', 'value', 'label') 
  // user types options
  const roleOptions = !props.isSelfService ?  props.register.roleOptions.slice(1, props.register.roleOptions.length) : props.register.ssRoleOptions;
  const cfRoleOptions = props.register.cfRoleOptions.slice(1, props.register.cfRoleOptions.length);
  const userTypeList = Utils.getOptionsList(userTypePlaceholder, roleOptions, 'label', 'value', 'label');
  const cfUserTypeList = Utils.getOptionsList(userTypePlaceholder, cfRoleOptions, 'label', 'value', 'label');

  const userStatusOptions = [
    { label: 'Unarchived', value: 'unarchived' },
    { label: 'Archived', value: 'archived' },
    { label: 'Both', value: 'all' },
  ];
  React.useEffect(() => {
    preloadFilters()
  },[])
  const preloadFilters = async () => {    
    let saveFilter = { tab: 'users' }
    if (props.fromAdmin) {
      saveFilter.module = 'GlobalUsers';
      saveFilter.objectId = null;
    }
  
    if (window.localStorage.currentSystem === 'cf' && !fromHCtab && !fromSCtab && !fromHolderTab && !fromProjectTab && fromAdmin) {
      await props.filterActions.fetchDefaultFilters(saveFilter, 'FilterUsers', async (filters) => {
        let value = {};
        if (filters && typeof filters == 'object') {
          value = { keywords: filters.searchTerm, userType: filters.roleId, CFUserType: filters.CFRoleId, hiringClient: filters.hiringClientId }
          props.dispatch(change('FilterUsers', 'keywords', filters.searchTerm));
          props.dispatch(change('FilterUsers', 'userType', filters.roleId));
          props.dispatch(change('FilterUsers', 'CFUserType', filters.CFRoleId));
          props.dispatch(change('FilterUsers', 'hiringClient', filters.hiringClientId));
        }
        await props.getIntialData({ notSaveFilters: true, ...value });
      });
    } else {
      const values = _.get(props, 'currentForm.FilterUsers.values', {});
      await props.getIntialData({ notSaveFilters: true,...values });
    }
  }
  const filtersResetCallback = async (values) => {
    if (values) {
      values.preventDefault()
    }
    props.dispatch(change('FilterUsers', 'keywords',''));
    props.dispatch(change('FilterUsers', 'userType', ''));
    props.dispatch(change('FilterUsers', 'CFUserType', ''));
    props.dispatch(change('FilterUsers', 'hiringClient', ''));
    let saveFilter = { tab: 'users' }
    if (props.fromAdmin) {
      saveFilter.module = 'GlobalUsers';
      saveFilter.objectId = null;
    }
    saveFilter.filterBox = '';
    if (window.localStorage.currentSystem === 'cf' && !fromHCtab && !fromSCtab && !fromHolderTab && !fromProjectTab && fromAdmin) {
      await props.filterActions.saveDefaultFilters('FilterUsers', saveFilter);
      await preloadFilters();
    }
    if (window.localStorage.currentSystem === 'pq') {
      props.filterActions.resetDefaultFilters('FilterUsers')
      await props.getIntialData({ notSaveFilters: true });
    }
   
  }
  const systemChangeHandle = async () => {
    if (window.localStorage.currentSystem === 'pq') {
      const values = _.get(props, 'currentForm.FilterUsers.values', {});
      await props.getIntialData({ notSaveFilters: true, ...values });
    }else {
      await preloadFilters()
    }
    
  }

  const {handleSubmit} = props;
  return (
    <form onSubmit={handleSubmit} className="list-view-filter-form">
      <div className="filter-header">
        <h2 className="list-view-filter-title">{title}</h2>
        {
          !fromHCtab && !fromSCtab && !fromHolderTab && !fromProjectTab && (
            <div className="switch">
              <SystemSwitch
                onChange={async (system) => {
                  await systemChangeHandle()
                }}
              />
            </div>
          )
        }
      </div>
      <div className="container-fluid filter-fields">
        <div className="row">
          <div className="col-md-5 col-sm-12 no-padd">
            <div className="admin-form-field-wrapper keywords-field">
              <label htmlFor="keywords">{keywordsLabel}: </label>
              <Field
                name="keywords"
                type="text"
                placeholder={`--${keywordsPlaceholder}--`}
                component={renderField}
                className="tags-input"
              ></Field>
            </div>
          </div>

          {
            !fromHCtab && !fromSCtab && !fromHolderTab && !fromProjectTab && (
              <div className="col-md-3 col-sm-12 no-padd" >
                <div className="admin-form-field-wrapper hiring-client-field">
                  <label htmlFor="hiringClient">{HClabel}: </label>
                  <div className="select-wrapper">
                    <Field
                      name="hiringClient"
                      component={renderSelect}
                      options={hcOptionsList}
                    ></Field>
                  </div>
                </div>
              </div>
            )
          }

          {
            !fromSCtab && PQRole &&
            <div className="col-md-3 col-sm-12 no-padd" >
              <div className="admin-form-field-wrapper hiring-client-field">
                <label htmlFor="userType">
                  {CFRole?'PQ':''} {userTypeLabel}
                </label>
                <div className="select-wrapper">
                  <Field
                    name="userType"
                    component={renderSelect}
                    options={userTypeList}
                  ></Field>
                </div>
              </div>
            </div>
          }

          {
            !fromSCtab && CFRole &&
            <div className="col-md-3 col-sm-12 no-padd" >
              <div className="admin-form-field-wrapper hiring-client-field">
                <label htmlFor="userType">
                  {PQRole?'CF':''} {userTypeLabel}
                </label>
                <div className="select-wrapper">
                  <Field
                    name="CFUserType"
                    component={renderSelect}
                    options={cfUserTypeList}
                  ></Field>
                </div>
              </div>
            </div>
          }

          {
            !fromHCtab && !fromSCtab && !fromHolderTab && !fromProjectTab &&(
            <div className="col-md-3 col-sm-12 no-padd" >
              <div className="admin-form-field-wrapper">
                <label htmlFor="userStatus">
                  User Status
                </label>
                <div className="select-wrapper">
                  <Field
                    name="userStatus"
                    component={renderSelect}
                    options={userStatusOptions}
                  />
                </div>
              </div>
            </div>
            )
          }

          <FilterActions
            formName={props.form}
            dispatch={props.dispatch}
            filtersResetCallback={filtersResetCallback}
            />

        </div>
      </div>
    </form>
  );
}

FilterUsers = reduxForm({
  form: 'FilterUsers',
  enableReinitialize: true,
})(FilterUsers);

const mapStateToProps = (state, ownProps) => {
  const { filterbox } = state.filterBox;
  let currentSystem = window.localStorage.getItem('currentSystem');
  return {
    register: state.register,
    local: state.localization,
    PQRole: state.login.profile.Role,
    CFRole: state.login.profile.CFRole,
    currentForm: state.form,
    initialValues: ((filterbox && filterbox.FilterUsers && typeof filterbox.FilterUsers === 'object') ? {
      keywords: filterbox.FilterUsers.searchTerm,
      hiringClient: filterbox.FilterUsers.hiringClientId,
      userType: filterbox.FilterUsers.roleId,
      CFUserType: filterbox.FilterUsers.CFRoleId,
      system: filterbox.FilterUsers.system,
      userStatus: filterbox.FilterUsers.userStatus
    } : {
      userStatus: 'unarchived',
      keywords: '',
      hiringClient: '',
      userType: '',
      CFUserType: '',
      system: currentSystem,
    })
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    filterActions: bindActionCreators(filterActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterUsers)
