export const SET_DOCUMENTS_LIST_ERROR = 'SET_DOCUMENTS_LIST_ERROR';
export const SET_NEXT_DOCUMENT_ERROR = 'SET_DOCUMENTS_LIST_ERROR';
export const SET_DOCUMENTS_LIST = 'SET_DOCUMENTS_LIST';
export const SET_NEXT_DOCUMENT = 'SET_NEXT_DOCUMENT';
export const SET_FETCHING_DOCUMENTS = 'SET_FETCHING_DOCUMENTS';
export const SET_SHOW_DOCUMENTS_MODAL = 'SET_SHOW_DOCUMENTS_MODAL';

export const SET_FETCHING_DOCUMENT_STATUS = 'SET_FETCHING_DOCUMENT_STATUS';
export const SET_DOCUMENT_STATUS_ERROR = 'SET_DOCUMENT_STATUS_ERROR';
export const SET_DOCUMENT_STATUS_LIST = 'SET_DOCUMENT_STATUS_LIST';

export const SET_FETCHING_DOCUMENT_TYPES = 'SET_FETCHING_DOCUMENT_TYPES';
export const SET_DOCUMENT_TYPES_ERROR = 'SET_DOCUMENT_TYPES_ERROR';
export const SET_ALL_DOCUMENT_TYPES_LIST = 'SET_ALL_DOCUMENT_TYPES_LIST';