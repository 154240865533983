import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as registerActions from './actions';
import * as loginActions from '../auth/login/actions';
import RegisterSidebar from './RegisterSidebar';
import RegisterMain from './RegisterMain';
import { showInformationAlert } from '../alerts';
import FullScreenReCAPTCHA from '../common/recaptcha/full-screen-recaptcha'

import './register.css';


const SHOW_RECAPTCHA = process.env.REACT_APP_SHOW_RECAPTCHA === 'true';

const Register = (props) => {

  const [showReCAPTCHA, setShowReCAPTCHA] = useState(SHOW_RECAPTCHA);

  useEffect(() => {
    const { hash } = props.match.params;
    const { history } = props;
    props.registerActions.fetchRegistrationResourcesByHash(hash, history);
  }, []);

  useEffect(() => {
    if (!props.register.processingEndpoint) {
      if (props.register.registrationError) {
        showInformationAlert(
          'Error',
          props.register.registrationError,
          'Accept',
          false,
          () => {
            if (props.register.redirectOnError) {
              props.history.push('/login');
            }
          }
        );
      } else if (props.register.registrationSuccess) {
        showInformationAlert(
          'Success',
          props.register.registrationSuccess,
          'Accept',
          false,
          () => {
            window.localStorage.removeItem("auth-token")
            // login the user automtically after registration
            const credentials = {
              username: props.register.userPayload.email,
              password: props.register.userPayload.password
            };

            props.loginActions.sendCredentials(
              credentials,
              props.history,
              '/profile'
            );

            props.history.replace('/login');
          }
        );
      }
    }
  }, [props.register.processingEndpoint]);

  const spinnerContainerStyle = {
    position: 'fixed',
    top: '0',
    left: '0',
    backgroundColor: '#80808087',
    width: '100%',
    height: '100%'
  };

  const spinnerStyle = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };

  const onReCAPTCHAVerify = () => {
    setShowReCAPTCHA(!showReCAPTCHA);
  };

  return (
    <>
      {showReCAPTCHA && <FullScreenReCAPTCHA onVerify={() => onReCAPTCHAVerify()} />}
      {
        !showReCAPTCHA && (
          <div className="container-fluid">
            <div className="row pq-register">
              <RegisterSidebar hcLogo={props.register.hcLogo} />
              <RegisterMain />
            </div>
            {props.register.processingEndpoint && (
              <div style={spinnerContainerStyle}>
                <div className="spinner-wrapper" style={spinnerStyle}>
                  <div className="spinner" />
                </div>
              </div>
            )}
          </div>
        )
      }
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({ register: state.register });

const mapDispatchToProps = (dispatch) => ({
  registerActions: bindActionCreators(registerActions, dispatch),
  loginActions: bindActionCreators(loginActions, dispatch)
});

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export default withRedux(Register);