/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import MultipleMail from "../../MultipleMail";

import PDFViewer from './PDFViewer';
import Deficiences from './../../common/deficiences';
import CopyCertificates from '../../certfocus/copy-certificates/index';

import * as certificateActions from './actions';
import * as commonActions from './../../common/actions';

import * as templateActions from "../../communication-templates/list/actions";
import Swal from "sweetalert2";
import RolAccess from "../../common/rolAccess";
import * as mailComposerActions from './../../mailComposer/actions/index';
import * as processingActions from './../../certfocus/processing/actions/index';
import * as attachmentActions from './../../certfocus/attachments/actions'
import * as deficiencyActions from '../../common/deficiences/actions/actions';
import { generateMissingDeficiencies } from "./api";
import {
  PROJECT_INSURED_EXPIRED_STATUS_ID
} from '../../../lib/appConstants';

import './certificates.css';

class Certificates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentCertificate: null,
      certificates: null,
      currentPosition: 0,
      disableBtnSaveEmailInsured: false,
      isArchived: false,
      isExempted: false,
      openReject: false,
      rejectData: {},
      showCopyCertificateModal: false,
      copyCertificateData: null,
    }
  }

  componentDidMount() {
    const { projectInsuredId } = this.props.match.params;
    this.props.certificateActions.fetchCertificates(projectInsuredId);    
    this.props.certificateActions.fetchProjectInsured(projectInsuredId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.certificates.projectInsured !== this.props.certificates.projectInsured) {
        const { HolderId } = nextProps.certificates.projectInsured;

        this.props.templateActions.fetchTemplates({
          orderBy: 'templateName',
          orderDirection: 'ASC',
          hiringClientId: HolderId,
          isBehalfHC: true
        })
        this.props.certificateActions.fetchUsers({ holderId: HolderId });

      if (nextProps.certificates.projectInsured.Archived) {
        this.setState({ isArchived: true });
      }
      if (nextProps.certificates.projectInsured.Exempt) {
        this.setState({ isExempted: true });
      }
    }
  }

  moveLeft = () => {
    let currentPosition = this.state.currentPosition;
    let nextPosition = 0;
    if (currentPosition > 0) {
      nextPosition = currentPosition - 1;
      this.setState({ currentPosition: nextPosition, disableBtnSaveEmailInsured: true, disableBtnSaveEmailProcedure: true });
    }
  }

  moveRight = () => {
    let currentPosition = this.state.currentPosition;
    let nextPosition = 0;
    if (currentPosition <= (this.props.certificates.certificatesAmount - 1)) {
      nextPosition = currentPosition + 1;
      this.setState({ currentPosition: nextPosition, disableBtnSaveEmailInsured: true, disableBtnSaveEmailProcedure: true });
    }
  }

  disableBtnBefore() {
    return this.state.currentPosition > 0;
  }

  disableBtnAfter() {
    return !(this.state.currentPosition == (this.props.certificates.certificatesAmount - 1));
  }

  renderLinkToCoverage() {
    let { projectInsuredId } = this.props.match.params;
    let requirementSetId = this.props.certificates.projectInsured ? this.props.certificates.projectInsured.RequirementSetId : null;

    let component = (
      <Link
        to={{
          pathname: `/certfocus/coverages/${projectInsuredId}/${requirementSetId}`
        }}
        target="_blank"
        className="bg-sky-blue-gradient bn bn-small"
      >
        {'VIEW COVERAGES'}
      </Link>
    );

    return component;
  }

  renderLinkToViewInsured() {
    let requirementSetId = this.props.certificates.projectInsured ? this.props.certificates.projectInsured.InsuredId : null;
    let component = (
      <Link
        to={{
          pathname: `/certfocus/insureds/${requirementSetId}`
        }}
        target="_blank"
        className="bg-sky-blue-gradient bn bn-small"
      >
        {'VIEW INSUREDS'}
      </Link>
    );

    return component;
  }

  renderLinkToDocumentInsured() {

    if (!this.props.certificates.projectInsured) return
    const {InsuredId, ProjectId} = this.props.certificates.projectInsured;
    let component = (
      <Link
        to={{
          pathname: `/certfocus/insureds/${InsuredId}/${ProjectId}`,
        }}
        target="_blank"
        className="bg-sky-blue-gradient bn bn-small"
      >
        {'DOCUMENT'}
      </Link>
    );

    return component;
  }

  eventSendEmail() {
    let url = this.encryptMailData(null,null, null)
    window.open(`/mail/${url}`, "_blank");
  }

  renderLinkSendEmail() {
    const { isArchived } = this.state;
    const component = (
      <button
        onClick={() => this.eventSendEmail()}
        disabled={isArchived}
        className={`bn bn-small bg-sky-blue-gradient ${(isArchived) ?  'disabledBtn': ''}`}>SEND EMAIL
      </button>
    );
    return component;
  }

  rejectCertificate = (data) => {
    if (!data.insuredTemplateId || !data.producerTemplateId) {
      Swal({
        title: 'Holder Incomplete',
        html: 'The holder has rejection templates configuration pending, could not complete the reject process',
        type: 'error',
      });
      return false;
    } else {
      let documentId = data.DocumentId
      let projectInsuredID = data.ProjectInsuredID
      let insuredID = data.InsuredID;
      let ruleGroupId = this.props.deficiences[0].ruleGroupId;
      let certificateId = data.CertificateID;
      let holderId = this.props.certificates.projectInsured.HolderId;
      let deficiences = this.props.deficiences.filter(x => x.ProjectInsuredID != null);
      let ruleId = this.props.deficiences[0].ruleId;

      let reject = { projectInsuredID, insuredID, documentId, deficiences, holderId, certificateId, ruleGroupId, ruleId };
      this.initLoadingSpinner();
      this.props.certificateActions.setfetchReject(reject, (error) => {
        this.closeLoadingSpinner();
        if (!error) {
          this.handleRejectOpen(data);
        }
      });
    }
  }

  encryptMailData = (extraEmail, templateId, certificateId = null) => {
    const {projectInsured} = this.props.certificates;
    const mailData = {
      holderId: projectInsured.HolderId,
      insuredId: projectInsured.InsuredId,
      projectId: projectInsured.ProjectId,
      certificateId: certificateId,
      recipients: extraEmail,
      templateId: templateId,
    }
    return window.btoa(JSON.stringify(mailData));
  }

  onHoldCertificate = (data) => {
    this.initLoadingSpinner();
    if (!data.isOnHold) {
      let onHold = { projectInsuredID: data.ProjectInsuredID, status: 16, lastStatus: data.complianceStatusID };
      this.props.certificateActions.setfetchOnHold(onHold, () => {
        this.closeLoadingSpinner();
        location.reload();
      });
    }
    else {
      let onHold = { projectInsuredID: data.ProjectInsuredID };
      this.props.certificateActions.setFetchRemoveOnHold(onHold, () => {
        this.closeLoadingSpinner();
        location.reload();
      });
    }
  }

  initLoadingSpinner = () => {
    this.props.commonActions.setLoading(true);
  }

  closeLoadingSpinner = () => {
    this.props.commonActions.setLoading(false);
  }

  confirmEditDataEntry = (certificateId) => {
    Swal({
      title: `Edit data entry`,
      text: `This action will reset the certificate state to Pending Processing, you have to finish the data entry process or certificate status wil be lost, do you want to proceed?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2E5965',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        this.props.history.push(`/certfocus/processing/${certificateId}`)
        // window.open(`/certfocus/processing/${certificateId}`, "_blank");
        // /certfocus/processing/${certificateId}
      }
    });
  }

  getUrlMailPage(holderId, projectId, insuredId, templateId, recipients) {
    let mailData = {
      holderId, projectId, insuredId, templateId, recipients
    };

    let urlmailPage = '/mail/' + window.btoa(JSON.stringify(mailData));
    return urlmailPage;
  }

  escalateCertificate(projectInsuredId) {
    this.initLoadingSpinner();
    let deficiences = this.props.deficiences.filter(x => x.ProjectInsuredID != null && (x.DefTypeID == 1 || x.DefTypeID == 2));
    this.props.certificateActions.setFetchEscalateCertificate({ deficiences, projectInsuredId }, () => {
      this.closeLoadingSpinner();
      location.reload();
    });
  }

  handleRejectOpen = (data) => {
    if (data) {
      const {certificatesList} = this.props.certificates;
      const { HolderId } = this.props.certificates.projectInsured;
      const {HolderName, InsuredName, ProjectNumber, ProjectFullName} = this.props.certificates.projectInsured || '';

      this.setState({
        openReject: true,
        rejectData: {
          certificatesList,
          certificateData: data,
          holderId: HolderId,
          holderName: HolderName,
          insuredName: InsuredName,
          projectNumber: ProjectNumber,
          projectName: ProjectFullName,
        }
      })
    }
  }


  recalculateCertificate = () => {
    const { ProjectInsuredID, CertificateID } =
      this.props.certificates.certificatesList &&
      this.props.certificates.certificatesList.length > 0
        ? this.props.certificates.certificatesList[0]
        : {};
    const { RequirementSetId } = this.props.certificates.projectInsured || "";
    Swal({
      type: "warning",
      title: "Refresh Certificate",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: "#2E5965",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
      text: "Are you sure you want to refresh?",
    })
      .then((result) => {
        if (result.value) {
          this.props.commonActions.setLoading(true);
          return generateMissingDeficiencies({
            projectInsuredId: ProjectInsuredID,
            requirementSetId: RequirementSetId,
            certificateId: CertificateID,
          });
        } else {
          return new Promise((resolve) => resolve('cancelled'));
        }
      })
      .then((result) => {
        if (result === 'cancelled') {
          return;
        }
        this.props.deficiencyActions.fetchDeficiences(ProjectInsuredID, () => {
          this.props.commonActions.setLoading(false);
          this.setState({
            showSnack: true,
            snackMessage: "Certificate refreshed successfully.",
          });
        });
      })
      .catch((e) => {
       //console.log(e);
        this.props.commonActions.setLoading(false);
        this.setState({
          showSnack: true,
          snackMessage: "Oops! Some error occurred.",
        });
      });
  };


  handleRejectClose = () => {
    this.setState({
      openReject: false,
    })
    location.reload();
  }

  Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  openCopyCertificateModal = (certificateId) => {
    this.initLoadingSpinner();
    this.props.processingActions.fetchCopyCertificateData(certificateId, (err, data) => {
      if (!err) {
        const certificateData = {
          certificateId: certificateId,
          documentId: data.DocumentId,
          fileName: data.FileName,
          documentUrl: data.DocumentUrl,
          holderId : data.HolderId,
          holderName: data.HolderName,
          projectId: data.ProjectId,
          projectName: data.ProjectName,
          insuredId: data.InsuredId,
          insuredName: data.InsuredName,
          projectInsuredId: data.ProjectInsuredId,
        }

        this.props.processingActions.fetchDataEntry(certificateId, (err, data) => {
          if (!err) {
            const copyCertificateData = {
              ...certificateData,
              ...data,
            }
            this.setState({
              showCopyCertificateModal: true,
              copyCertificateData: copyCertificateData
            });
          }
        });
      }
      this.closeLoadingSpinner();
    });
  }

  closeCopyCertificateModal = (success) => {
    if (success) {
      this.props.closeCopyCertificateModal(success);
    }
    this.setState({
      showCopyCertificateModal: false,
    });
  }

  renderModal = () => {
    const { copyCertificateData } = this.state;
    return (copyCertificateData) && (
      <CopyCertificates
        holderId={copyCertificateData.holderId}
        projectInsuredId={copyCertificateData.projectInsuredId}
        insuredId={copyCertificateData.insuredId}
        copyCertificateData={copyCertificateData}
        redirectTo={() => this.closeCopyCertificateModal()}
      />
    );
  }

  assignColorToProjectInsuredComplianceStatus = (statusId) => {
    switch (statusId) {
      case 1:
        return (<div className="compliance-status compliant">Compliant</div>);
      case 2:
        return (<div className="compliance-status escalated">Escalated</div>);
      case 6:
        return (<div className="compliance-status non-compliant">Non Compliant</div>);
      case 15:
        return (<div className="compliance-status minor">Compliant w/minor Deficiencies</div>);
      case 16:
        return (<div className="compliance-status on-hold">On Hold</div>);
      default:
        return (<div className="compliance-status">Pending</div>);
    }
  }

  renderEditCertificateActions = (data) => {
    const nameOnHoldBtn = data && data.disabledOnHoldBtn ? 'REMOVE HOLD' : 'ON-HOLD';
    const { isArchived } = this.state;
    const {canReject, canEscalate, complianceStatusId} = this.props.certificates.projectInsured || '';

    return (
        <React.Fragment>
          <div className="col-md-auto">
            <button
              onClick={() => this.recalculateCertificate(data)}
              className={`bn bn-small bg-sky-blue-gradient`}
            >
              REFRESH
            </button>
          </div>
          <div className="col-md-auto">
            <button
                onClick={(e) => this.openCopyCertificateModal(data.CertificateID, data.DocumentId, data.files)}
                //disabled={isArchived}
                className={`bn bn-small bg-sky-blue-gradient ${(isArchived) ?  'disabledBtn': ''}`}>COPY</button>
          </div>
          <div className="col-md-auto">
            <button
              onClick={(e) => this.confirmEditDataEntry(data.CertificateID)}
              className={`bn bn-small bg-sky-blue-gradient ${(isArchived) ?  'disabledBtn': ''}`}>EDIT DATA ENTRY</button>
          </div>
          <div className="col-md-auto">
            {this.renderLinkSendEmail()}
          </div>
          <div className="col-md-auto">
            <button
                onClick={() => this.onHoldCertificate(data)}
                disabled={isArchived}
                className={`bn bn-small bg-sky-blue-gradient ${(isArchived) ?  'disabledBtn': ''}`}>{nameOnHoldBtn}</button>
          </div>
          <div className="col-md-auto">
            <button
                onClick={() => this.rejectCertificate(data)}
                disabled={((canReject === 0 && complianceStatusId !== PROJECT_INSURED_EXPIRED_STATUS_ID) || isArchived)}
                className={`bn bn-small bg-sky-blue-gradient`}>REJECT</button>
          </div>
          <div className="col-md-auto">
            <button
              onClick={() => this.escalateCertificate(data && data.ProjectInsuredID)}
                disabled={complianceStatusId == 2 || canEscalate == 0 || isArchived}
                className={`bn bn-small bg-sky-blue-gradient`}>ESCALATE</button>
          </div>
        </React.Fragment>
    )
  }

  renderSaveEmailButton = (data, isArchived) => {
    return(
        <button
            className="btn btn-primary"
            disabled={(!data || data.EmailInsured.length == 0) || isArchived}
            onClick={() => this.saveEmailInsured(data)}
        >Save
        </button>
    )
  }

  viewComments = (e, data) => {
    e.preventDefault();
    if (data) {
      const html = `<table class="view-comments">
        <tbody>
        <tr><td><strong>First name: </strong></td><td>${(data.FirstName !== undefined) ? data.FirstName : ''}</td></tr>        
        <tr><td><strong>Last name: </strong></td><td>${(data.LastName !== undefined) ? data.LastName : ''}</td></tr>
        <tr><td><strong>Email: </strong></td><td>${(data.Email !== undefined) ? data.Email : ''}</td></tr>
        <tr><td><strong>Phone: </strong></td><td>${(data.Phone !== undefined) ? data.Phone : ''}</td></tr>
        <tr><td><strong>Comments: </strong></td><td>${(data.Comments !== undefined) ? data.Comments : ''}</td></tr>
        </tbody>
        </table>`;
     
      Swal({
        type: 'success',
        title: 'View Comments',
        html: html,
      })
    }   
  }

  render() {
    let emailInsured = null;
    let emailProducerContact = null;

    const data = this.props.certificates.certificatesList && this.props.certificates.certificatesList.length > 0
      ? this.props.certificates.certificatesList[this.state.currentPosition]
      : null;
    if (data) {
      emailInsured = data.EmailInsured;
      emailProducerContact = data.EmailProducerContact;
    }
    const opacityBtnBefore = { opacity: this.disableBtnBefore() ? '1' : '0.2' }
    const disableBtnBefore = this.disableBtnBefore();
    const opacityBtnAfter = { opacity: this.disableBtnAfter() ? '1' : '0.2' }
    const disableBtnAfter = this.disableBtnAfter();
    const opacityRejectBtn = { opacity: data && data.disabledRejectBtn ? '0.2' : '1' };
    const nameOnHoldBtn = data && data.disabledOnHoldBtn ? 'REMOVE HOLD' : 'ON-HOLD';
    const {HolderName, InsuredName, ProjectNumber, ProjectName, CFProjectName2, complianceStatusId,
      InsuredId, RequirementSetId, RequirementSetName, ProjectId, HolderId, AIWording} = this.props.certificates.projectInsured || '';
    const {
      isArchived,
      isExempted,
      showCopyCertificateModal
    } = this.state;

    if (isExempted) {
      Swal({
        type: 'error',
        title: 'Cert Upload',
        text: `This Project Insured is exempted.`,
        onClose: () => { this.props.history.push("/profile"); }
      });
    }

    return (
      <div className="certificates container-fluid">
        <section className="border p-1">
          <div className="row py-2 px-4">
              <div className="col-md-2 text-center">
                <p><span>Viewing {this.state.currentPosition + 1} of {this.props.certificates.certificatesAmount} documents</span></p>
                <button
                  className="documents-scroll linear-icon-chevron-left"
                  style={opacityBtnBefore}
                  disabled={!disableBtnBefore}
                  onClick={() => this.moveLeft()}
                />&nbsp;
                <button
                  className="documents-scroll linear-icon-chevron-right"
                  style={opacityBtnAfter}
                  disabled={!disableBtnAfter}
                  onClick={() => this.moveRight()}
                />
              </div>
              <div className={"col-md-3 text-center"}>
              {this.assignColorToProjectInsuredComplianceStatus(complianceStatusId)}
            </div>
              <div className="col-md-3 pt-2">
                <p className={'mb-2'}><strong>Holder: </strong>
                  <Link
                    to={{ pathname: `/certfocus/holders/${HolderId}` }}
                    target="_blank"
                  >{HolderName}</Link>
                </p>
                <p className={'mb-2'}><strong>Project: </strong>
                  <Link
                    to={{ pathname: `/certfocus/projects/${ProjectId}` }}
                    target="_blank"
                  >{ProjectNumber ? `#${ProjectNumber} - ` : ''}{ProjectName} 
                   {CFProjectName2 ? ` - ${CFProjectName2}` : ''}
                  </Link>
                </p>                
                <p className={'mb-2'}><strong>Requirement set: </strong>
                  <Link
                    to={{ pathname: `/certfocus/projects/${ProjectId}/${HolderId}/${RequirementSetId}` }}
                    target="_blank"
                  >{RequirementSetName}</Link>
                </p>
              </div>
              <div className="col-md-4 pt-2">
                <p className={'mb-2'}><strong>Insured: </strong>
                  <Link
                    to={{ pathname: `/certfocus/insureds/${InsuredId}` }}
                    target="_blank"
                  >{InsuredName}</Link>
                </p>
                <p className={'mb-2'}><strong>Insured email: </strong>
                  {emailInsured}
                </p>
                <p className={'mb-2'}><strong>Producer contact email: </strong>
                  {emailProducerContact}
                </p>
              </div>
            </div>
        </section>

        <section className="border">
          <div className="col-md-12">
            <div className="row buttons-section">
              <RolAccess
                  masterTab='certificates_insurance'
                  sectionTab='edit_certificate'
                  component={() => this.renderEditCertificateActions(data)}>
              </RolAccess>
              <div className="col-md-auto m-2">
                {this.renderLinkToCoverage()}
              </div>
              <div className="col-md-auto m-2">
                {this.renderLinkToDocumentInsured()}
              </div>
            </div>
          </div>
        </section>

        <section className="border">
          <div className="col-md-12">
            <div className="row info-section" style={{ justifyContent: 'space-between' }}>
              <div className="col-md-auto">
                <span>Current Document ID: </span>
                <span style={{ display: 'inline-block' }}>
                  <input type="text" className="sm-input" name="documentId" value={data && data.DocumentId} disabled />
                </span>
              </div>
              <div className="col-md-auto">
                <span>Current Document Status: </span>
                <span style={{ display: 'inline-block' }}>
                  <input type="text" className="sm-input" name="documentStatus" value={data && data.DocumentStatus} disabled />
                </span>
              </div>
              <div className="col-md-auto">
                <span>Coverages: </span>
                <span style={{ display: 'inline-block' }}>
                  <input type="text" className="sm-input" name="coverageTypes" value={data && data.Codes} disabled />
                </span>
              </div>
              <div className="col-md-auto">
                <button className="bn bn-small bg-green-dark-gradient" onClick={(e) => this.viewComments(e, data)}>VIEW COMMENTS</button>
              </div>
            </div>
          </div>
        </section>

        <section className="border">
          <div className="container-fluid" style={{ marginTop: '20px' }}>
            <div className="row">
              <div className="col-5">
                {data && data.UrlFile ? (
                  <PDFViewer
                    pdf={data && data.UrlFile}
                    height="75vh"
                    width="100%"
                    position="sticky"
                    top="10px"
                    frameBorder="1p solid #000"
                  />
                ) : (
                    <div className="spinner-wrapper">
                      <div className="spinner" />
                    </div>
                  )}
              </div>
              <div className="col-7">
                <Deficiences
                  AIWording={AIWording}
                  params={this.props.match.params}
                  isArchived={this.state.isArchived}
                />
              </div>
            </div>
          </div>
        </section>

        <Dialog style={{zIndex: 100}} fullScreen={true} open={this.state.openReject} onClose={this.handleRejectClose} TransitionComponent={this.Transition}>
          <MultipleMail
            locale={this.props.local.strings.multipleMail}
            rejectData={this.state.rejectData}
            onClose={this.handleRejectClose}
          />
        </Dialog>

        <Modal
          show={showCopyCertificateModal}
          onHide={this.closeCopyCertificateModal}
          className="add-item-modal add-hc">
          <Modal.Body className="add-item-modal-body large-modal">
            {this.renderModal()}
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    local: state.localization,
    certificates: state.certificates,
    deficiences: state.deficiences.deficiencesList
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    certificateActions: bindActionCreators(certificateActions, dispatch),
    templateActions: bindActionCreators(templateActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    mailComposerActions: bindActionCreators(mailComposerActions, dispatch),
    processingActions: bindActionCreators(processingActions, dispatch),
    attachmentActions: bindActionCreators(attachmentActions, dispatch),
    deficiencyActions: bindActionCreators(deficiencyActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Certificates);
