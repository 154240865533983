import React from 'react';
import PropTypes from 'prop-types';
import RenderAMBestDropzone from './renderAMBestDropzone';
import { Field, reduxForm, reset } from 'redux-form';
import validate from './validate';

let AMBestImportForm = props => {
  const {error, locale:{saveButton, cancelButton},handleSubmit, submitting} = props;

  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data">
      <div className="row">
        <div className="col-md-12">
          <Field
            name="files"
            component={RenderAMBestDropzone}
            accept='.csv'
          />
        </div>
      </div>
      <div className="row" style={{ textAlign: 'right' }}>
        <div className="col-md-12 p-0">
          <div className="text-danger">{error}</div>

          <div className="add-item-bn">
            <button
              disabled={submitting}
              className="bn bn-small bg-green-dark-gradient create-item-bn icon-save"
              type="submit"
            >
              {saveButton}
            </button>
            <a
              className="cancel-add-item"
              onClick={props.close} >
              {cancelButton}
            </a>
          </div>
        </div>
      </div>
    </form>
  );
};

const afterSubmit = (res, dispatch) => dispatch(reset('uploadAMBestFileForm'));

AMBestImportForm = reduxForm({
  form: 'uploadAMBestFileForm',
  validate,
  onSubmitSuccess: afterSubmit,
})(AMBestImportForm);

AMBestImportForm.propTypes = {

};

export default AMBestImportForm;