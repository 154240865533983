export const SET_CHECKING_AUTHORIZATIONS = 'SET_CHECKING_AUTHORIZATIONS';
export const SET_AUTHORIZATIONS = 'SET_AUTHORIZATIONS';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SET_FUNCTIONS = 'SET_FUNCTIONS';
export const SET_LOADING = 'SET_LOADING';
export const SET_HEADER_TITLE = 'SET_HEADER_TITLE';
export const SET_HEADER_NOTIFICATIONS = 'SET_HEADER_NOTIFICATIONS';
export const SET_FETCHING_USER_HCS = 'SET_FETCHING_USER_HCS';
export const SET_USER_HIRING_CLIENTS = 'SET_USER_HIRING_CLIENTS';
export const SET_TIMEZONES = 'SET_TIMEZONES';
export const SET_LAST_HIRING_CLIENT_ID = 'SET_LAST_HIRING_CLIENT_ID';
export const SET_LAST_SUBCONTRACTOR_ID = 'SET_LAST_SUBCONTRACTOR_ID';

export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_COUNTRY_STATES = 'SET_COUNTRY_STATES';
export const SET_US_STATES = 'SET_US_STATES';
export const SET_CANADIAN_PROVINCES_AND_TERRITORIES = 'SET_CANADIAN_PROVINCES_AND_TERRITORIES';
export const SET_COMMON_PROJECT_STATUS = 'SET_COMMON_PROJECT_STATUS';
export const SET_COMMON_COMPLIANCE_STATUS = 'SET_COMMON_COMPLIANCE_STATUS';
export const SET_COMMON_COVERAGE_ATTRIBUTE_STATUS = 'SET_COMMON_COVERAGE_ATTRIBUTE_STATUS';
export const SET_COMMON_HOLDER_CERTIFICATE_OPTIONS = 'SET_COMMON_HOLDER_CERTIFICATE_OPTIONS';
export const SET_COMMON_HOLDER_DATA_ENTRY_OPTIONS = 'SET_COMMON_HOLDER_DATA_ENTRY_OPTIONS';

// Type ahead and search
export const SET_TYPE_AHEAD_FETCHING = 'SET_TYPE_AHEAD_FETCHING';
export const SET_TYPE_AHEAD_SUCCESS = 'SET_TYPE_AHEAD_SUCCESS';
export const SET_TYPE_AHEAD_ERROR = 'SET_TYPE_AHEAD_ERROR';
export const RESET_TYPE_AHEAD_RESULTS = 'RESET_TYPE_AHEAD_RESULTS';

// Breadcrumb
export const SET_INIT_CURRENT_PAGE = 'SET_INIT_CURRENT_PAGE';
export const SET_CHILD_TO_PARENT = 'SET_CHILD_TO_PARENT';
export const SET_CHILD_TO_CHILD = 'SET_CHILD_TO_CHILD';

export const REMOVE_CHILD_TO_PARENT = 'REMOVE_CHILD_TO_PARENT';
export const REMOVE_CHILD_TO_CHILD = 'REMOVE_CHILD_TO_CHILD';
export const ADD_CURRENT_PAGE = 'ADD_CURRENT_PAGE';
export const SET_BREADCRUMB_ITEMS = 'SET_BREADCRUMB_ITEMS';
