import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import utils from '../../../../lib/utils'
import {showQuickConfirmation, showInformationAlert} from '../../../../../src/components/alerts';
import RenderSelect from '../../../../../src/components/customInputs/renderSelect';

import * as actions from '../../actions';

import './changeSubNameModal.css';
import {changeSCCompanyName} from "../../actions";

class ChangeSubNameModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subName: this.props.subName || '',
      processing: false,
      hiringClientList: this.props.hiringClientList,
      hiringClientId: Number(props.scProfile.hcId),
      submissionError: false,
      displayHCid: Number(props.scProfile.hcId) || '',
    }

    this.onChangeName = this.onChangeName.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  };

  onChangeName(value) {
    this.setState({subName: value});
  }

  setHiringClient(hcId) {
    this.setState({
      displayHCid: hcId,
    }, () => {
      this.props.actions.setHiringClientId(hcId);
    })
  }

  handleSelect(e) {
    const { value } = e.target;
    this.setState({hiringClientId: value});
  }

  handleSubmit() {
    const
      { scId } = this.props,
      { subName } = this.state,
      { hiringClientId } = this.state;
    if(hiringClientId){
      this.setState({ processing: true });
      this.setState({submissionError: false})
      const payload = {
        subcontractorId: scId,
        companyName: subName,
        hiringClientId
      }

      this.props.actions.changeSCCompanyName(payload, (success) => {
        if (success) {
          this.props.successCallback(this.props.close);
        } else {
          this.setState({ processing: false });
        }
      });
    } else {
      this.setState({submissionError: true})
    }

  }

// Start function to render hiring client dropdown

  renderHiringClient(labelHiringClient, displayHCid, redirectHCid) {
    const { hiringClientsList } = this.props.scProfile.headerDetails;
    const { multipleStrictHCs, RoleID } = this.props.login.profile

    const filterHiringClients = (subArray, userArray) => {
      const userSet = new Set(userArray)
      let output = []
      for (let i=0; i<subArray.length; i++) {
        const subHiringClientId = subArray[i].value
        if (userSet.has(subHiringClientId)) {
          output.push(subArray[i])
        }
      }
      return output
    }

    const reorderList = (redirectHCid, _hiringClientsList) => {
      let output = []

      for (let i=0; i<_hiringClientsList.length; i++) {
        let hcObj = _hiringClientsList[i]
        if (redirectHCid == hcObj.value) {
          output.push(hcObj)
        }
      }

      for (let i=0; i<_hiringClientsList.length; i++) {
        let hcObj = _hiringClientsList[i]
        if (redirectHCid != hcObj.value) {
          output.push(_hiringClientsList[i])
        }
      }

      return output
    }

    const replaceProps = (hiringClientsList) => {
      const output = []
      for (let i=0; i<hiringClientsList.length; i++) {
        let obj = hiringClientsList[i]
        let hcObj = {}
        hcObj.label = obj.name
        hcObj.value = obj.hiringClientId
        output.push(hcObj)
      }
      return output
    }

    const routeHCdisplayByRole = (RoleID, hiringClientsList, multipleStrictHCs) => {

      const canViewAssociatedHCs = utils.canPerformFunction('view associated hiring clients', this.props.login.profile.rolesFunctionsPermissions);

      if (canViewAssociatedHCs) {
        const _hiringClientsList = filterHiringClients(hiringClientsList, multipleStrictHCs)
        return _hiringClientsList
      } else {
        const _hiringClientsList = redirectHCid ? reorderList(redirectHCid, hiringClientsList) : hiringClientsList
        return _hiringClientsList
      }
    }

    const _hiringClientsList = routeHCdisplayByRole(
      RoleID,
      replaceProps(hiringClientsList),
      multipleStrictHCs
    );

    if (_hiringClientsList.length === 0) {
      this.props.history.push('/hiringclients');

      return (
        <div className="lineHeight vk_hc_sc_container">
          <span className="statusContainer">
            {labelHiringClient}
          </span>
          <span className="statusContainer">
            {"----"}
          </span>
        </div>
      );
    } else if (_hiringClientsList.length === 1) {

      return (null);
    } else if (_hiringClientsList.length > 1) {
      return (
        <div className="dropdown select-row vk_hc_sc_container">
          <span className="statusContainer label-hc">
            {labelHiringClient}
          </span>
          <span className="select specialSelect vk_hc_value">
            <RenderSelect
              options={_hiringClientsList}
              className="select"
              value={this.state.hiringClientId}
              input={{value: displayHCid, onChange: this.handleSelect}}
              meta={{touched: false, error: null, warning: null}}
            />
          </span>
        </div>
      );
    }

    return 'NONE';
  }
// End of the function to render the hiring client dropdown  

  render() {
    const {
      title,
      nameLabel,
      hiringClientLabel,
      cancelBtn,
      saveBtn
    } = this.props.local.strings.scProfile.changeSubNameModal;

    let displayName = utils.displayQuotes(this.state.subName)

    return (
      <div className="change-status-modal">
        <header className="change-status-modal-header">{title}</header>

        <div className="change-status-content container-fluid filter-fields">
          <div className="row">
            <div className="col-md-12">
              {
                this.state.processing?
                  <div className="spinner-wrapper">
                    <div className="spinner"></div>
                  </div>
                  :
                  <div className="statusContainer" >
                    <div>
                      <label>{nameLabel}:</label>
                    </div>
                      <input
                        value={this.state.subName}
                        onChange={(e) => this.onChangeName(e.target.value)}
                        type="text"
                      />
                  </div>
              }
            </div>
              <div className="col-md-12">
                {
                  <div className="statusContainer" >
                    <div>{this.renderHiringClient(hiringClientLabel, this.state.hiringClientId, this.props.redirectHCid)}</div>
                  </div>
                }
              </div>
            {this.state.submissionError && <div className="col-md-12 error">Please select hiring client id.</div>}

          </div>

          {
            !this.state.processing?
              <div className="text-right">
                <button className="bg-sky-blue-gradient bn" onClick={this.props.close}>{cancelBtn}</button>
                <button className="bg-sky-blue-gradient bn action-button" onClick={this.handleSubmit}>{saveBtn}</button>
              </div>
              :
              null
          }

        </div>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    scProfile: state.SCProfile,
    sc: state.sc,
    local: state.localization,
    login: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeSubNameModal);
