import React, { useRef } from 'react';

import ReCAPTCHA from "react-google-recaptcha"

import Api from "../../../lib/api";

import robot from '../../../assets/img/robot.svg';
import logo from '../../../assets/img/VertikalLogo.png';
import './full-screen-recaptcha.css';

export default function FullScreenReCAPTCHA({ onVerify }) {
  const refContainer = useRef(null)
  const captchaRef = useRef(null)

  const handleSubmit = async (data) =>{
    if (!data) return;
    
    const captchaValue = captchaRef.current.getValue();
    await Api.post("verify/recaptcha", { captchaValue })
    .then((response) => {
      const { data } = response
      if (typeof onVerify == 'function' && data.success) {
        refContainer.current.className = "main-container fadeOut"
        setTimeout(() => {
            onVerify(captchaValue);
          }, 1000);
        }
    })
    .catch(() => {});
  }

  return (
    <div ref={refContainer} className='main-container'>
      <div className='robot-verification-nav-bar'>
        <img src={logo} lat="logo" className='prequal-logo' />
      </div>
      <div className='robot-verification-container'>
        <div className='robot-section robot-section-right'>
          <img src={robot} alt="robot" className='robot-content-center robot-image robot-section-right' />
        </div>
        <div className='robot-section'>
          <div className='robot-content-center robot-section-left'>
            <div>
              <h3>Welcome to Vertikal</h3>
              <p>Prove you're not a robot by clicking this box and then shouting, I love CAPTCHAs!</p>
            </div>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} 
              ref={captchaRef}
              onChange={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  )
}