import React, {Component} from 'react';
import {connect} from "react-redux";
import * as loginActions from './components/auth/login/actions';
import {bindActionCreators} from "redux";
import {BrowserRouter as Router } from 'react-router-dom';
import getRoutes from './routes';
import IdleTime from './components/auth/idletime/IdleTime';
import { Route } from 'react-router-dom';
import PostHogSession from './components/posthog/PostHogSession';

class App extends Component {

	componentDidMount(){
		let currentSystem = localStorage.getItem('currentSystem');
		if (!currentSystem) {
			currentSystem = 'cf';
			localStorage.setItem('currentSystem', currentSystem);
		} 
		this.props.actions.setCurrentSystem(currentSystem);
	}
  
  render() {
    return (
      <PostHogSession userId={this.props.userId}>
        <div>
          <Router>
              { getRoutes(this.props.store) }
            <Route
              render={({ location }) => location.pathname !== "/login" && <IdleTime />}
            />
          </Router>
        </div>
      </PostHogSession>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.login.userId,
    local: state.localization,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(loginActions, dispatch)
  }
};

export default connect(mapStateToProps,mapDispatchToProps)(App);