import * as types from './types';
import Api from '../../../lib/api';
import Utils from '../../../lib/utils';

// sets

export const setHCListSuccess = (success) => {
  return {
    type: types.SET_HC_LIST_SUCCESS,
    success
  };
};

export const setHCListError = (error) => {
  return {
    type: types.SET_HC_LIST_ERROR,
    error
  };
};

export const setShowModal = (status) => {
  return {
    type: types.SET_SHOW_MODAL,
    showModal: status
  };
};

export const setPageSize = (payload) => {
  return {
    type: types.SET_PAGE_SIZE,
    payload
  };
};

export const setShowHoldersModal = (status) => {
  return {
    type: types.SET_SHOW_HOLDERS_MODAL,
    showModal: status
  };
};

export const setAssociatingHCUser = (associatingHCUser) => {
  return {
    type: types.SET_ASSOCIATING_HCUSER,
    associatingHCUser
  };
};

export const setFilterBox = (filterBox) => {
  return {
    type: types.SET_FILTER_BOX,
    filterBox
  };
};

export const setAssociatingOperator = (associatingOperator) => {
  return {
    type: types.SET_ASSOCIATING_OPERATOR,
    associatingOperator
  };
};

export const setHCList = (list) => {
  return {
    type: types.SET_HC_LIST,
    list
  };
};

export const setHCUsers = (list) => {
  return {
    type: types.SET_HC_USERS,
    list
  };
};

export const setFetchingHC = (isFetching) => {
  return {
    type: types.SET_FETCHING_HC,
    isFetching
  };
};

export const setFetchingFilters = (isFetching) => {
  return {
    type: types.SET_FETCHING_FILTERS,
    isFetching
  }
};

export const setSavingFilters = (isSaving) => {
  return {
    type: types.SET_SAVING_FILTERS,
    isSaving
  }
};

export const setIsSubmittingHC = (isSubmittingHC) => {
  return {
    type: types.SET_SUBMITTING_HC,
    isSubmittingHC
  };
};

export const setIsSubmittedHC = (isSubmittedHC) => {
  return {
    type: types.SET_SUBMITTED_HC,
    isSubmittedHC
  };
};

export const setTotalAmountOfHC = (HClength) => {
  return {
    type: types.SET_TOTAL_AMOUNT_OF_HC,
    HClength
  };
};

export const addSerializedHcData = (serializedHcObj) => {
  return {
    type: types.ADD_SERIALIZED_HC_DATA,
    serializedHcObj
  }
}

// fetchs

export const fetchHiringClients = (queryParams) => {
  return (dispatch, getState) => {
    const { login, localization } = getState();
    let {
      error10005,
      error10006,
      error10007,
      error10011,
      error10019,
      errorDefault,
      errorConnection,
    } = localization.strings.hiringClients.actions;
    const token = login.authToken;
    let urlParameters = '';

    dispatch(setFetchingHC(true));
    dispatch(setTotalAmountOfHC(0));

    const { HCPerPage } = getState().hc;
    if (queryParams) {
      if (queryParams.withoutPag) {
        delete queryParams.pageNumber;
        delete queryParams.pageSize;
        delete queryParams.withoutPag;
      }
      else {
        if (!queryParams.pageNumber) {
          queryParams.pageNumber = 1;
        }
        queryParams.pageSize = HCPerPage;
      }
    }
    else {
      queryParams = {
        pageNumber: 1,
        pageSize: HCPerPage
      };
    }

    let urlQuery = 'hiringclients';
    return Api.get(`users/profile`, token).then((response) => {
      const id = response.data.data.profile.Id;
      let additionalParams = ''
      if (!Utils.isObjEmpty(queryParams)) {
        additionalParams = `&${Object.keys(queryParams).map(i => `${i}=${queryParams[i]}`).join('&')}`
      }
      urlParameters = `?userId=${id}${additionalParams}`;
      const url = `${urlQuery}${urlParameters}`

      Api.get(url, token)
        .then(response => {
          const { success, data } = response.data;
          let errorMsg = '';
          if (success) {
            dispatch(setHCList(data.hiringClients ? data.hiringClients : []));
            dispatch(setTotalAmountOfHC(data.totalCount ? data.totalCount : 0));
          }
          else {
            switch (data.errorCode) {
              case 10005:
                errorMsg = error10005;
                break;
              case 10006:
                errorMsg = error10006;
                break;
              case 10007:
                errorMsg = error10007;
                break;
              case 10011:
                errorMsg = error10011;
                break;
              case 10019:
                errorMsg = error10019;
                break;
              default:
                errorMsg = errorDefault;
                break;
            }
            dispatch(setHCListError(errorMsg));
            dispatch(setTotalAmountOfHC(0));
            dispatch(setHCList([]));
          }
          dispatch(setFetchingHC(false));
        })
        .catch(error => {
          dispatch(setHCListError(errorConnection));
        });
    })
  };
};

export const fetchHolders = (queryParams) => {
  return (dispatch, getState) => {
    const {
      login: { authToken },
      localization,
      hc: { HCPerPage },
    } = getState();

    let {
      errorDefault,
      errorConnection,
    } = localization.strings.hiringClients.actions;

    dispatch(setFetchingHC(true));
    dispatch(setTotalAmountOfHC(0));

    if (queryParams) {
      if (queryParams.withoutPag) {
        delete queryParams.pageNumber;
        delete queryParams.pageSize;
        delete queryParams.withoutPag;
      } else {
        if (!queryParams.pageNumber) {
          queryParams.pageNumber = 1;
        }

        queryParams.pageSize = HCPerPage;
      }
    } else {
      queryParams = {
        pageNumber: 1,
        pageSize: HCPerPage,
      };
    }

    let urlQuery = 'cf/holders';
    return Api.get(`users/profile`, authToken).then((response) => {
      const { Id } = response.data.data.profile;
      const urlParameters = `?userId=${Id}&${Object.keys(queryParams).map(i => `${i}=${queryParams[i]}`).join('&')}`;

      Api.get(`${urlQuery}${urlParameters}`, authToken)
        .then(response => {
          const { success, data: { holders, totalCount } } = response.data;
          if (success) {
            dispatch(setHCList(holders ? holders : []));
            dispatch(setTotalAmountOfHC(totalCount ? totalCount : 0));
          } else {
            dispatch(setHCListError(errorDefault));
            dispatch(setTotalAmountOfHC(0));
            dispatch(setHCList([]));
          }
          dispatch(setFetchingHC(false));
        })
        .catch(error => {
          dispatch(setHCListError(errorConnection));
        });
    })
  };
};

export const fetchDefaultFilters = (filterBoxTag) => {
  return (dispatch, getState) => {
    const { login, localization } = getState();
    let {
      error10005,
      error10006,
      error10007,
      error10011,
      error10019,
      errorDefault,
      errorConnection,
    } = localization.strings.hiringClients.actions;
    const token = login.authToken;

    dispatch(setFetchingFilters(true));

    return Api.get(`defaultFilters/${filterBoxTag}`, token)
      .then((response) => {
        let errorMsg = '';
        const { success, data } = response.data;
        if (success) {
          dispatch(setFilterBox(data));
        } else {
          switch (data.errorCode) {
            case 10005:
              errorMsg = error10005;
              break;
            case 10006:
              errorMsg = error10006;
              break;
            case 10007:
              errorMsg = error10007;
              break;
            case 10011:
              errorMsg = error10011;
              break;
            case 10019:
              errorMsg = error10019;
              break;
            default:
              errorMsg = errorDefault;
              break;
          }
          dispatch(setHCListError(errorMsg));
        }
        dispatch(setFetchingFilters(false));
      })
      .catch((error) => {
        dispatch(setHCListError(errorConnection));
        dispatch(setFetchingFilters(false));
      });
  };
};

// submits

export const saveDefaultFilters = (filterBoxTag, filterBox) => {
  return (dispatch, getState) => {
    const { login, localization } = getState();
    let {
      error10005,
      error10006,
      error10007,
      error10011,
      error10019,
      errorDefault,
      errorConnection,
    } = localization.strings.hiringClients.actions;
    const token = login.authToken;

    dispatch(setSavingFilters(true));
    dispatch(setFetchingFilters(true));

    return Api.post('defaultFilters', {
      filterBoxTag,
      payload: filterBox
    }, token)
      .then((response) => {
        let errorMsg = '';
        const { success, data } = response.data;
        if (!success) {
          switch (data.errorCode) {
            case 10005:
              errorMsg = error10005;
              break;
            case 10006:
              errorMsg = error10006;
              break;
            case 10007:
              errorMsg = error10007;
              break;
            case 10011:
              errorMsg = error10011;
              break;
            case 10019:
              errorMsg = error10019;
              break;
            default:
              errorMsg = errorDefault;
              break;
          }
          dispatch(setHCListError(errorMsg));
        } else {
          dispatch(setFilterBox(filterBox));
        }
        dispatch(setSavingFilters(false));
        return !!success;
      })
      .catch((error) => {
        dispatch(setHCListError(errorConnection));
        dispatch(setSavingFilters(false));
        return false;
      });
  };
};

export const createHiringClient = (closeModal) => {
  return async (dispatch, getState) => {
    dispatch(setIsSubmittingHC(true));

    try {
      const {hc: {serializedHcObj}} = getState();

      const formData = new FormData();

      formData.append(
        'companyInfo', 
        JSON.stringify(serializedHcObj.companyInfo)
      );
      formData.append(
        'companyLogo', 
        serializedHcObj.companyLogo
      );
      formData.append(
        'contactInfo', 
        JSON.stringify(serializedHcObj.contactInfo)
      );

      await Api.post('hiringclients', formData);

      dispatch(setIsSubmittedHC(true));

      closeModal(true);
    } catch (error) {
      const {data: err} = error.response.data;
      if (err && err.errorCode && err.description) {
        dispatch(setHCListError(err.description));
      } else {
        const {localization: {strings: str}} = getState();
        dispatch(setHCListError(str.hiringClients.actions.errorConnection));
      }
    }

    dispatch(setIsSubmittingHC(false));
  };
};

export const updateHiringClient = (closeModal) => {
  return async (dispatch, getState) => {
    dispatch(setIsSubmittingHC(true));

    try {
      const {hc: {serializedHcObj}} = getState();

      const formData = new FormData();
      // sanitising subdomain to remove baseurl
      serializedHcObj.companyInfo.subdomain = serializedHcObj.companyInfo.subdomain.split('apply/').pop();

      formData.append(
        'companyInfo', 
        JSON.stringify(serializedHcObj.companyInfo)
      );
      formData.append(
        'companyLogo', 
        serializedHcObj.companyLogo
      );

      await Api.put(`hiringclients/${serializedHcObj.id}`, formData);

      dispatch(setIsSubmittedHC(true));

      closeModal(true);
    } catch (error) {
      const {data: err} = error.response.data;
      if (err && err.errorCode && err.description) {
        dispatch(setHCListError(err.description));
      } else {
        const {localization: {strings: str}} = getState();
        dispatch(setHCListError(str.hiringClients.actions.errorConnection));
      }
    }

    dispatch(setIsSubmittingHC(false));
  };
};

export const assignUserToHC = (hiringClientId, userId, closeModal, userType) => {
  return (dispatch, getState) => {
    const { login, localization } = getState();
    const {
      error10001,
      error10003,
      error10004,
      error10005,
      error10006,
      error10007,
      error10008,
      error10011,
      error10013,
      error10014,
      errorDefault,
      errorConnection,
    } = localization.strings.hiringClients.actions;
    let associatingAction;

    switch (userType) {
      case 'operator':
        associatingAction = setAssociatingOperator;
        break;
      case 'hiringclient':
        associatingAction = setAssociatingHCUser;
        break;
      default:
        break;
    }

    if (associatingAction) {
      dispatch(associatingAction(true));
    }

    const token = login.authToken;
    let method = 'post';
    dispatch(setHCListError(''));

    let payload = {
      hiringClientId,
      userId
    };

    return Api[method](
      `hiringclients/userrelation`,
      payload,
      token)
      .then(response => {
        const { success, data } = response.data;
        let errorMsg = '';
        if (success) {
          if (userType) {
            const { associatingHCUser, associatingOperator } = getState().hc;
            if (userType === 'operator' && !associatingHCUser) {
              closeModal();
            }
            else {
              if (userType === 'hiringclient' && !associatingOperator) {
                closeModal();
              }
            }
            dispatch(associatingAction(false));
          }
        }
        else {
          switch (data.errorCode) {
            case 10000:
            case 10001:
              errorMsg = error10001;
              break;
            case 10003:
              errorMsg = error10003;
              break;
            case 10004:
              errorMsg = error10004;
              break;
            case 10005:
              errorMsg = error10005;
              break;
            case 10006:
              errorMsg = error10006;
              break;
            case 10007:
              errorMsg = error10007;
              break;
            case 10008:
              errorMsg = error10008;
              break;
            case 10011:
              errorMsg = error10011;
              break;
            case 10013:
              errorMsg = error10013;
              break;
            case 10014:
              errorMsg = error10014;
              break;
            default:
              errorMsg = errorDefault;
              break;
          }
          dispatch(setHCListError(errorMsg));
        }
      })
      .catch(error => {
        dispatch(setHCListError(errorConnection));
      });
  };
};

const _log = (msg, data = ' ') => {
  const isDev = true;
  // if (isDev) {
  //   console.log(msg, data)
  // }
}

export const assignHCUser = (userId, hiringClientId) => {
  return (dispatch, getState) => {
    const { authToken } = getState().login;
    Api.post(`hiringclients/userrelation`, { userId, hiringClientId }, authToken).then((response) => {
      _log(response);
    })
  }
}

export const holderArchive = (holderId, archived, callback) => {
  return (dispatch, getState) => {
    const { authToken } = getState().login;
    Api.post(`cf/holders/archive`, { holderId, archived }, authToken).then((response) => {
      _log(response);
      if (callback) callback(true);
    })
  }
}