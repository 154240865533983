import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './IdleTime.css';

export const IdleTimeModal = ({ show, handleLogout, handleClose }) => {
    
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Session timeout</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            You have been inactive for 45 min.  Please  click “Continue” or click “Logout"
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleLogout} className="bg-danger border-danger">
                    Logout
                </Button>
                <Button onClick={handleClose} className="submit">
                    Continue
                </Button>
            </Modal.Footer>
        </Modal>
    )
}