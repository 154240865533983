import React from 'react';
import './index.css'

class NeedHelp extends React.Component {
  render() {
    return (
      <a href={process.env.REACT_APP_CUSTOMER_PORTAL_URL} className='need-help'>
        Need Help?
      </a>
    );
  };
};

export default NeedHelp;