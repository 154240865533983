import React from 'react';
import { FormEdit, Errors } from '@formio/react';
import * as formsActions from '../../../../../formBuilder/actions';
import * as formioActions from '../../../../actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as commonActions from '../../../../../common/actions';
import Utils from '../../../../../../lib/utils';
import FormTemplateProperties from './FormTemplateProperties';
const Alerts = require ('../../../../../alerts');

class FormCreate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      hiringClientId: '',
      subcontractorFee: '',
      description: '',
      needsUpdate: true,
      name: '',
      formId: null
    }
  }

  componentDidMount() {
    this.props.commonActions.fetchUserHiringClients();

    const hiringClientId = Number(localStorage.getItem('createFormHiringClientId'));
    localStorage.removeItem('createFormHiringClientId');

    if (hiringClientId) {
      this.setState({
        hiringClientId
      });
    }
  }

  onSaveForm = (form) => {
    if (!this.state.hiringClientId) {
      return Alerts.showInformationAlert(
        'Error',
        'Please select a Hiring Client',
        'Accept',
        false,
        () => {}
      );
    }
    
    this.sendCallToFormIO(form);
  };

  sendCallToFormIO = (form) =>  this.props.formioActions.saveForm(form, (err, form) => {
    if (!err) {
      var tempForm = {...this.state};
      tempForm.name = form.title;
      tempForm.formId = form._id;

      this.props.formsActions.saveForm(tempForm);
      this.props.history.push(`/hiringclients/${tempForm.hiringClientId}`)
    };
    return;
  });

  onInputChange = (_type, _value) => {
    var tempForm = {...this.state};

    if (_type === 'subcontractorFee') {
      this.setState({[_type]: Utils.formatCurrency(_value)})
    } else {
      tempForm[_type] = _value;
      this.setState({[_type]: _value})
    }
  }

  render() {
    const {
      hiringClientId,
      subcontractorFee,
      description
    } = this.state;

    return (
      <div className="container" id="main">
        <h2>Create Form</h2>
        <hr />
        <Errors errors={[this.props.formState.error]} />
        <FormTemplateProperties 
          onInputChange={this.onInputChange}
          userHiringClients={this.props.common.userHiringClients}
          form={{ hiringClientId, subcontractorFee, description }}
        />
        <FormEdit saveForm={this.onSaveForm} form={{ display: 'form' }} saveText='Create Form' />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    formState: state.formioForms,
    common: state.common,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    formsActions: bindActionCreators(formsActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    formioActions: bindActionCreators(formioActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FormCreate);