export const SET_ADD_DATA_ENTRY_SUCCESS = 'SET_ADD_DATA_ENTRY_SUCCESS';
export const SET_ADD_DATA_ENTRY_ERROR = 'SET_ADD_DATA_ENTRY_ERROR';
export const SET_OTHER_COVERAGES_SHOW_MODAL = 'SET_OTHER_COVERAGES_SHOW_MODAL';

export const SET_DATAENTRY_FETCHING_AGENCIES = 'SET_DATAENTRY_FETCHING_AGENCIES';
export const SET_DATAENTRY_AGENCIES_LIST = 'SET_DATAENTRY_AGENCIES_LIST';
export const SET_DATAENTRY_AGENCIES_LIST_ERROR = 'SET_DATAENTRY_AGENCIES_LIST_ERROR';

export const SET_DATAENTRY_SELECTED_AGENCY = 'SET_DATAENTRY_SELECTED_AGENCY';
export const SET_DATAENTRY_SELECTED_INSURED = 'SET_DATAENTRY_SELECTED_INSURED';

export const SET_INSURERS_TYPEAHEAD_FETCHING = 'SET_INSURERS_TYPEAHEAD_FETCHING';
export const SET_INSURERS_TYPEAHEAD_RESULTS = 'SET_INSURERS_TYPEAHEAD_RESULTS';
export const SET_INSURERS_TYPEAHEAD_ERROR = 'SET_INSURERS_TYPEAHEAD_ERROR';
export const SET_ADD_INSURER_ERROR = 'SET_ADD_INSURER_ERROR';

export const SET_PROCESSING_REQUIREMENT_SET_DETAIL_FETCHING = 'SET_PROCESSING_REQUIREMENT_SET_DETAIL_FETCHING';
export const SET_PROCESSING_REQUIREMENT_SET_DETAIL_SUCCESS = 'SET_PROCESSING_REQUIREMENT_SET_DETAIL_SUCCESS';
export const SET_PROCESSING_REQUIREMENT_SET_DETAIL_ERROR = 'SET_PROCESSING_REQUIREMENT_SET_DETAIL_ERROR';

export const SET_PROCESSING_AVAILABLE_COVERAGES_SUCCESS = 'SET_PROCESSING_AVAILABLE_COVERAGES_SUCCESS';
export const SET_PROCESSING_AVAILABLE_ENDORSEMENTS_SUCCESS = 'SET_PROCESSING_AVAILABLE_ENDORSEMENTS_SUCCESS';

export const SET_AGENCIES_TYPEAHEAD_FETCHING = 'SET_AGENCIES_TYPEAHEAD_FETCHING';
export const SET_AGENCIES_TYPEAHEAD_RESULTS = 'SET_AGENCIES_TYPEAHEAD_RESULTS';
export const SET_AGENCIES_TYPEAHEAD_ERROR = 'SET_AGENCIES_TYPEAHEAD_ERROR';

export const SET_INSUREDS_TYPEAHEAD_FETCHING = 'SET_INSUREDS_TYPEAHEAD_FETCHING';
export const SET_INSUREDS_TYPEAHEAD_RESULTS = 'SET_INSUREDS_TYPEAHEAD_RESULTS';
export const SET_INSUREDS_TYPEAHEAD_ERROR = 'SET_INSUREDS_TYPEAHEAD_ERROR';

export const SET_DATAENTRY_SELECTED_ENDORSEMENTS = 'SET_DATAENTRY_SELECTED_ENDORSEMENTS';

export const SET_AMBEST_SUGGESTIONS = 'SET_AMBEST_SUGGESTIONS';
export const SET_AUTO_PROCESSING_DOCUMENT = 'SET_AUTO_PROCESSING_DOCUMENT';
