let zeros = {
  auth: {
    common: {
      AuthFormHeader: {
        title: '00000',
        subtitle: '00000',
      }
    },
    login: {
      signIn: '00000',
      recoverPasswordLink: '00000',
      userNameRequired: '00000',
      passwordRequired: '00000',
      userPlaceholder: '00000',
      passwordPlaceholder: '00000',
      rememberMe: '00000',
      actions: {
        error10005: '00000',
        error10006: '00000',
        error10007: '00000',
        error10009: '00000',
        error10011: '00000',
        errorDefault: '00000',
        errorConnection: '00000',
      }
    },
    forgot: {
      userNameRequired: '00000',
      sendButtonText: '00000',
      placeholdUserName: '00000',
      subTitleHeader: '0000000',
      actions: {
        errorMsjBase: '00000',
        errorMsjPassword: '00000',
        errorMsjPlease: '00000',
        loginExtraMessage: '00000',
        error10003: '00000',
        error10008: '00000',
        error10011: '000000',
        error10013: '00000',
        errorDefault: '00000',
        errorConnection: '00000',
      }
    },
    reset: {
      passwordRequired: '00000',
      passwordBoth: '00000',
      subTitleHeader: '00000',
      newPasswordPlaceholder: '00000',
      retypePasswordPlaceholder: '00000',
      actions: {
        error10005: '00000',
        error10006: '00000',
        error10007: '00000',
        error10008: '00000',
        error10009: '00000',
        error10011: '00000',
        error10012: '00000',
        errorDefault: '00000',
        errorConnection: '00000',
      }
    }
  },
  register: {
    registerMain: {
      step1Title: '00000',
      step2Title: '00000',
      title: '00000',
      instruction: '00000',
      suggestion: '00000',
      accountAssociated: "00000"
    },
    registerSidebar: {
      slogan: '00000',
    },
    companyProfile: {
      requiredValidation: '00000',
      wrongEmailFormat: '00000',
      wrongTaxIdFormat: '00000',
      zipcodeErrorMessage: '00000',
      companyNameLabel: '00000',
      companyNamePlaceholder: '00000',
      dropdownPlaceholder: '00000',
      dropdownNoOptionsMessage: '00000',
      dropdownLoadingMessage: '00000',
      tradeLabel: '00000',
      secondaryTradeLabel: '00000',
      tertiaryTradeLabel: '00000',
      addressLabel: '00000',
      addressPlaceholder: '00000',
      cityLabel: '00000',
      cityPlaceholder: '00000',
      stateLabel: '00000',
      provinceLabel: '0000',
      statePlaceholder: '00000',
      zipCodeLabel: '00000',
      zipCodePlaceholder: '00000',
      mainEmailLabel: '00000',
      mainEmailPlaceholder: '00000',
      countryLabel: '00000',
      taxIdLabel: '00000',
      vatLabel: '0000',
      taxIdPlaceholder: '00000',
      mustAgree: '00000',
      userAgreementLinkText: '00000',
      before: '00000',
      agreeLabel: '00000',
      submitText: '00000',
      taxIdAlreadyTaken: '00000'
    },
    userInformation: {
      titlesDropdownPlaceholder: '00000',
      titlesDropdownNoOptionsMessage: '00000',
      titlesDropdownLoadingMessage: '00000',
      requiredValidation: '00000',
      phoneErrorMessage: '00000',
      wrongEmailFormat: '00000',
      passwordBoth: '00000',
      firstNameLabel: '00000',
      firstNamePlaceholder: '00000',
      lastNameLabel: '00000',
      lastNamePlaceholder: '00000',
      titleLabel: '00000',
      phoneLabel: '00000',
      phonePlaceholder: '00000',
      cellPhoneLabel: '00000',
      cellPhonePlaceholder: '00000',
      emailLabel: '00000',
      mainEmailPlaceholder: '00000',
      passwordLabel: '00000',
      retypePassword: '00000',
      passwordPlaceholder: '00000',
      timezoneLabel: '00000',
      userButtonText: '00000',
      emailAlreadyTaken: '00000'
    },
    actions: {
      error10001: '00000',
      error10003: '00000',
      error10004: '00000',
      error10005: '00000',
      error10006: '00000',
      error10007: '00000',
      error10008: '00000',
      error10011: '00000',
      error10013: '00000',
      error10014: '00000',
      error10155: '00000',
      errorDefault: '00000',
      errorConnection: '00000',
      invalidHash: '00000',
      registrationError: '00000',
      registrationEmailError: '00000',
      registrationSuccess: '00000',
      associatedSubExists: '00000',
      subAlreadyExists: '00000',
      emailHCFamilyTaken: '00000',
      taxIdHCFamilyTaken: '00000'
    },
    reducer: {
      allUsersLabel: '00000',
    }
  },
  dashboard: {
    title: '00000',
    formBuilderLink: '00000',
    formListLink: '00000',
    usersLink: '00000',
    registerLink: '00000',
    HCLink: '00000',
    HCProfileLink: '00000'
  },
  common: {
    sidebar: {
      navigation: [
        '00000',
        '00000',
        '00000',
        '00000',
        '00000'
      ]
    }
  },
  users: {
    title: 'Users',
    tableHeaderName: '00000',
    tableHeaderEmail: '00000',
    tableHeaderUserType: '00000',
    tableHeaderStatus: '00000',

    table: {
      quickViewLink: 'Q00000',
      edituserLink: '00000'
    },
    filterNameAll: '00000',
    filterNameAdmin: '00000',
    filterNameOperator: '00000',
    filterNameHC: '00000',
    filterNameSC: '00000',
    buttonFilterUser: '00000',
    buttonAddUser: '00000',
    buttonUserLog: '00000',
    noResults: '00000',
    addUserOverlay: {
      title: '00000',
      buttonAdmin: '00000',
      buttonOperator: '00000',
      buttonHC: '00000',
      buttonSC: '00000',
    },
    addUser: {
      requiredValidation: '00000',
      phoneErrorMessage: '00000',
      wrongEmailFormat: '00000',
      passwordBoth: '00000',
      ModalTitleAdmin: '00000',
      ModalTitleOperator: '00000',
      ModalTitleHC: '00000',
      ModalTitleSC: '00000',
      editUser: '00000',
      addUser: '00000',
      roleLabel: '00000',
      rolePlaceholder: '00000',
      nameLabel: '00000',
      namePlaceholder: '00000',
      lastNameLabel: '00000',
      lastNamePlaceholder: '00000',
      emailLabel: '00000',
      emailPlaceholder: '00000',
      phoneLabel: '00000',
      phonePlaceholder: '00000',
      associatedSCLabel: '00000',
      associatedSCPlaceholder: '00000',
      associatedHCLabel: '00000',
      associatedHCPlaceholder: '00000',
      passwordLabel: '00000',
      passwordPlaceholder: '00000',
      buttonGeneratePass: '00000',
      retypePasswordLabel: '00000',
      checkLabel: '00000',
      timeZoneLabel: '00000',
      saveUserButton: '00000',
      cancelButton: '00000',
    },
    rowPopOver: {
      HCTitle: '00000',
      SCTitle: '00000',
      buttonMoreLogs: '00000',
    },
    filter: {
      title: '00000',
      keywordsLabel: '00000',
      keywordsPlaceholder: '00000',
      HClabel: '00000',
      HCPlaceholder: '00000',
      userTypeLabel: '00000',
      userTypePlaceholder: '00000',
      buttonSearch: '00000',
    },
    reducer: {
      statusChangePassword: '00000',
      statusActive: '00000',
      statusInactive: '00000',
      allHC: '00000',
      allSC: '00000',
    },
    actions: {
      error10001: '00000',
      error10003: '00000',
      error10004: '00000',
      error10005: '00000',
      error10006: '00000',
      error10007: '00000',
      error10008: '00000',
      error10011: '00000',
      error10013: '00000',
      error10014: '00000',
      errorDefault: '00000',
      errorConnection: '00000',
    }
  },
  hiringClients: {
    title: '000000',
    listTitle: '000000',
    addButton: '00000',
    noResults: '00000',
    tableHeaderHcName: '00000',
    tableHeaderRegistrationUrl: '00000',
    tableHeaderCountry: '00000',
    tableHeaderContactName: '00000',
    tableHeaderHcPhone: '00000',
    tableHeaderHcState: '00000',
    tableHeaderArchive: '00000',
    viewHC: 'VIEW HC',
    filterHC: '00000',
    addHCModal: {
      title: '00000',
      companyInfoTab: {
        title: '00000',
        labelCompanyLogo: '00000',
        registrationUrlSufix: '.usa.prequal.com',
        labelRegistrationUrl: '00000',
        labelCompanyName: '00000',
        labelCompanyField: '00000',
        labelAddress1: '00000',
        labelAddress2: '00000',
        labelCity: '00000',
        labelState: '00000',
        labelPostalCode: '00000',
        labelPhone: '00000',
        labelFax: '00000',
        labelPhone2: '00000',
        labelEntity: '00000',
        entityTypeNone: '00000',
        entityTypeChild: '00000',
        entityTypeParent: '00000',
        labelParentHC: '00000',
        parentHCPlaceholder: '00000',
        parentHCLoadingMessage: '00000',
        parentHCNoOptionsMessage: '00000',
        nameAlreadyTaken: '00000',
        cancel: '00000',
        continueButton: '00000',
        saveButton: '00000',
        requiredValidation: '00000',
        phoneErrorMessage: '00000',
        fileTypeValidation: '00000',
        mustBeNumericValue: '00000'
      },
      contactTab: {
        title: '00000',
        tabCreate: '00000',
        tabChoose: '00000',
        labelName: '00000',
        labelLastName: '00000',
        labelEmail: '00000',
        labelPhone: '00000',
        labelPassword: '00000',
        labelRetypePassword: '00000',
        labelTimezone: '00000',
        emailAlreadyTaken: '00000',
        generatePasswordButton: '00000',
        chooseExistingHC: '00000',
        existingHCPlaceholder: '00000',
        chooseHCUserLoadingMessage: '00000',
        chooseHCUserNoOptionsMessage: '00000',
        cancel: '00000',
        continueButton: '00000',
        requiredValidation: '00000',
        phoneErrorMessage: '00000',
        wrongEmailFormat: '00000',
        userAlreadyExists: '00000',
        passwordBoth: '00000',
      }
    },
    filter: {
      title: '00000',
      keywordsLabel: '00000',
      keywordsPlaceholder: '00000',
      searchButton: '00000'
    },
    actions: {
      error10000: '00000',
      error10001: '00000',
      error10003: '00000',
      error10004: '00000',
      error10005: '00000',
      error10006: '00000',
      error10007: '00000',
      error10008: '00000',
      error10011: '00000',
      error10013: '00000',
      error10014: '00000',
      error10019: '00000',
      errorDefault: '',
      errorConnection: '00000',
    }
  },
  hcProfile: {
    tabs: {
      tradeCategories: '00000',
      communicationTemplates: '00000',
      forms: '00000',
      projects: '00000',
      language: '00000',
      workflow: '00000',
      subContractor: '00000',
      users: '00000',
      notesTasks: '00000'
    },
    template: {
      editTemplate: '00000',
      addNewTemplate: '00000',
      templateTitle: '00000',
      templateDescription: '00000'
    },
    projects: {
      viewProjects: '00000',
      editProject: '00000',
      editContract: '00000',
      filterProjects: '00000',
      addNewProject: '00000',
      projectView: {
        projectNumber: '00000',
        projectStatus: '00000',
        numberOfContracts: '00000',
        tradeTotal: '00000',
        contractsTitle: '00000',
        contractNumber: '00000',
        startDate: '00000',
        contractAmount: '00000',
        noContractsFound: '00000',
        scName: '00000',
        noScText: '00000'
      },
      filter: {
        title: '00000',
        keywordsLabel: '00000',
        keywordsPlaceholder: '00000',
        tradeLabel: '00000',
        tradePlaceholder: '00000',
      },
      headers: {
        projectName: '00000',
        tradeTotal: '00000',
        contractsAwarded: '00000',
      },
      addProjectModal: {
        title: '00000',
        titleEditProject: '00000',
        titleEditContract: '00000',
        AddContractsTab: {
          title: '00000',
          labelSubcontractor: '00000',
          labelAddButton: '00000',
          labelRemoveButton: '00000',
          labelContractNumber: '00000',
          labelContractAmount: '00000',
          labelStartDate: '00000',
          cancel: '00000',
          finishButton: '00000',
          saveButton: '00000',
          requiredValidation: '00000',
          subcontractorPlaceholder: '00000'
        },
        projectTab: {
          labelProjectName: '00000',
          labelProjectStatus: '00000',
          cancel: '00000',
          saveButton: '00000',
          continueButton: '00000',
          requiredValidation: '00000'
        }
      },
      actions: {
        error10001: '00000',
        error10003: '00000',
        error10004: '00000',
        error10005: '00000',
        error10006: '00000',
        error10007: '00000',
        error10008: '00000',
        error10011: '00000',
        error10013: '00000',
        error10014: '00000',
        error10019: '00000',
        errorDefault: '',
        errorConnection: '00000'
      }
    }
  },
  scProfile: {
    header: {
      selectMoveToHiringClient: {
        placeholder: '00000',
        noOptionsMessage: '00000',
        loadingMessage: '00000',
        errorOnMove: '00000',
        confirmationOnMove: '00000'
      }
    },
    tabs: {
      profile: '00000',
      tradeContracts: '00000',
      notesTasks: '00000',
      forms: '00000',
      files: '00000',
      financialInfo: '00000',
    },
    notesTasks: {
      editNoteTask: '00000',
      filterNotesTasks: '00000',
      addNotesTasks: '00000',
      modal: {
        titleCreate: '00000',
        titleEdit: '00000',
        labelTitle: '00000',
        labelType: '00000',
        labelAssign: '00000',
        labelPriority: '00000',
        labelDateDue: '00000',
        labelNote: '00000',
        typePlaceholder: '00000',
        assignedToPlaceholder: '00000',
        priorityPlaceholder: '00000',
        buttonCreate: '00000',
        buttonEdit: '00000',
        buttonCancel: '00000',
      },
    },
    formBuilder: {
      checkBoxLabel: '00000',
      elements: '00000',
      section: '00000',
      name: '00000',
      sectionDivider: '00000',
      subSectionDivider: '00000',
      checkboxOption: '00000',
      checkbox: '00000',
      radioOption: '00000',
      radioButton: '00000',
      datePicker: '00000',
      fileUpload: '00000',
      textbox: '00000',
      header: '00000',
      paragraph: '00000',
      dropDown: '00000',
      email: '00000',
      phone: '00000',
      currency: '00000',
      emailHolder: '00000',
      password: '00000',
      hiringClientLabel: '00000',
      formTitleLabel: '00000',
      formTitlePlaceholder: '00000',
      formDescriptionLabel: '00000',
      formDescriptionPlaceholder: '00000',
      newSectionLabel: '00000',
      subSectionLabel: '00000',
      textBoxPlaceholder: '00000',
      paragraphPlaceholder: '00000',
      phonePlaceholder: '00000',
      dropDownPlaceholder: '00000',
      sourceOfFinancialStatementPlaceholder: '00000',
      periodPlaceholder: '00000',
      companyTypePlaceholder: '00000',
      currencyPlaceholder: '00000',
      formCreated: '00000',
      formOwner: '00000',
      previewForm: '00000',
      saveForm: '00000',
      clearForm: '00000',
      Literal: "00000",
      Text: "00000",
      Currency: "00000",
      Date: "00000",
      CheckBox: "00000",
      FileUpload: "00000",
      Paragraph: "00000",
      Email: "00000",
      SectionDivider: "00000",
      DropDown: "00000",
      RadioButton: "00000",
      Phone: "00000",
      SubSectionDivider: "00000",
      Sourceoffinancialstatement: "00000",
      Financialstatementdate: "00000",
      Period: "00000",
      CompanyType: "00000",
    },
    formPreviewer: {
      title: '00000'
    },
    formList: {
      title: '00000',
      tableHeaderName: '00000',
      tableHeaderSubmittedBy: '00000',
      tableHeaderStartDate: '00000',
      tableHeaderSubmissionDate: '00000',
      tableHeaderStatus: '00000',
      tableItemViewSubmission: '00000',
      tableHeaderCreator: '00000',
      tableHeaderDateCreater: '00000',
      tableFormSubmissions: '00000',
      tableHeaderIsDefault: '00000',
      noResults: '00000',
      buttonFilterForm: '00000',
      buttonFormSubmissions: '00000',
      buttonAddForm: '00000',
      buttonOptions: '00000',
      buttonEditForm: '00000',
      buttonSendForm: '00000',
      filter: {
        title: '00000',
        keywordsLabel: '00000',
        keywordsPlaceholder: '00000',
        assignedToLabel: '00000',
        assignedToPlaceholder: '00000',
        dateLabel: '00000',
        datePlaceholder: '00000'
      },
      headers: {
        noteTaskTitle: '00000',
        date: '00000',
        assignedTo: '00000',
      },
    },
  },
  formBuilder: {
    checkBoxLabel: '00000',
    elements: '00000',
    section: '00000',
    name: '00000',
    sectionDivider: '00000',
    subSectionDivider: '00000',
    checkboxOption: '00000',
    checkbox: '00000',
    radioOption: '00000',
    radioButton: '00000',
    datePicker: '00000',
    fileUpload: '00000',
    textbox: '00000',
    header: '00000',
    paragraph: '00000',
    dropDown: '00000',
    email: '00000',
    phone: '00000',
    currency: '00000',
    emailHolder: '00000',
    password: '00000',
    hiringClientLabel: '00000',
    formTitleLabel: '00000',
    formTitlePlaceholder: '00000',
    formDescriptionLabel: '00000',
    formDescriptionPlaceholder: '00000',
    newSectionLabel: '00000',
    subSectionLabel: '00000',
    textBoxPlaceholder: '00000',
    paragraphPlaceholder: '00000',
    phonePlaceholder: '00000',
    dropDownPlaceholder: '00000',
    sourceOfFinancialStatementPlaceholder: '00000',
    periodPlaceholder: '00000',
    companyTypePlaceholder: '00000',
    currencyPlaceholder: '00000',
    formCreated: '00000',
    formOwner: '00000',
    previewForm: '00000',
    saveForm: '00000',
    clearForm: '00000',
    Literal: "00000",
    Text: "00000",
    Currency: "00000",
    Date: "00000",
    CheckBox: "00000",
    FileUpload: "00000",
    Paragraph: "00000",
    Email: "00000",
    SectionDivider: "00000",
    DropDown: "00000",
    RadioButton: "00000",
    Phone: "00000",
    SubSectionDivider: "00000",
    Sourceoffinancialstatement: "00000",
    Financialstatementdate: "00000",
    Period: "00000",
    CompanyType: "00000",
  },
  formPreviewer: {
    title: '00000'
  },
  formList: {
    title: '00000',
    tableHeaderName: '00000',
    tableHeaderSubmittedBy: '00000',
    tableHeaderSubmissionDate: '00000',
    tableHeaderStatus: '00000',
    tableItemViewSubmission: '00000',
    tableHeaderCreator: '00000',
    tableHeaderDateCreater: '00000',
    tableFormSubmissions: '00000',
    noResults: '00000',
    buttonFilterForm: '00000',
    buttonFormSubmissions: '00000',
    buttonAddForm: '00000',
    buttonOptions: '00000',
    buttonEditForm: '00000',
    buttonSendForm: '00000',
    filter: {
      title: '00000',
      keywordsLabel: '00000',
      keywordsPlaceholder: '00000',
      formCreatorLabel: '00000',
      formCreatorPlaceholder: '00000',
      formSCSentToPlaceholder: '00000',
      sentToLabel: '00000',
      buttonSearch: '00000h'
    },
    table: {
      sendForm: '00000',
      editForm: '00000m',
      formSubmissions: '00000'
    },
    actions: {
      error10003: '00000',
      error10005: '00000',
      error10006: '00000',
      error10007: '00000',
      error10011: '00000',
      errorDefault: '00000'
    }
  },
  templates: {
    title: '00000',
    filterCT: '00000',
    filter: {
      title: '00000',
      keywordsLabel: '00000',
      keywordsPlaceholder: '00000',
      searchButton: '00000'
    }
  },
  templateBuilder: {
    header: {
      nameLabel: "00000",
      subjectLabel: "00000",
      activityLabel: "00000",
      communicationTypeLabel: "00000",
      fromAddressLabel: "00000",
      emailPlaceHolder: "00000",
      ownerLabel: "00000",
      ownerPlaceholder: "00000",
      commTypePlaceholder: "00000",
      activityPlaceholder: "00000",
    },
    footer: {
      saveBtn: '00000',
      discardBtn: '00000',
      dateLabel: "00000",
      creatorLabel: "00000",
    }
  },
  mailComposer: {
    sendBtn: '00000',
    discardBtn: '00000',
    header: {
      hiringClientLabel: '00000',
      recipientsLabel: '00000',
      recipientsPlaceholder: '00000',
      pickHCFirstLabel: '00000',
      subjectLabel: '00000',
      subjectPlaceholder: '00000',
      selectHCHolder: '00000',
      subcontractorLabel: '00000',
      subcontractorPlaceholder: '00000',
      dueDateLabel: '00000',
      taskDetailLabel: '00000',
    }
  },
  logs: {
    filter: {
      title: '00000',
      keywordsLabel: '00000',
      keywordsPlaceholder: '00000',
      usernameLabel: '00000',
      usernamePlaceholder: '00000',
      systemModuleLabel: '00000',
      systemModulePlaceHolder: '00000',
      buttonSearch: '00000'
    }
  },
  profile: {
    updateButton: "00000",
    associatedHC: "00000",
    associatedSC: "00000",
    activityTitle: "00000",
  }
};

export default zeros;