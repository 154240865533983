import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from './store/configureStore';

import { Provider } from 'react-redux';
import { Formio, initAuth } from '@formio/react';
import {AppConfig} from './components/formio/config'
import PostHogProviderWrapper from './components/posthog/PostHogProviderWrapper';

import './index.css';
import './assets/icons/icons.css';
import './assets/icons/linearicons.css';
import './../node_modules/bootstrap/dist/css/bootstrap.min.css';

import App from './App';

Formio.setProjectUrl(AppConfig.projectUrl);
Formio.setBaseUrl(AppConfig.apiUrl);

const store = configureStore();

ReactDOM.render(
    <Provider store={store} >
      <PostHogProviderWrapper>
        <App store={store} />
      </PostHogProviderWrapper>
    </Provider>,
  document.getElementById('root')
);
