import React from 'react';
import './login.css';

import SidePicture from '../common/SidePicture';
import LoginForm from './LoginForm';

class Login extends React.Component {
  render() {
    const { authentication_token } = this.props.match.params;

    return (
      <div className="container-fluid login">
        <div className="row">
          <SidePicture />
          <LoginForm authentication_token={authentication_token} />
        </div>
      </div>
    );
  };
}

export default Login;