import React from "react";
import { Link } from 'react-router-dom'

class SubmissionNavbar extends React.Component {
    render() {
        return (
            <div>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <Link className="nav-link" to={`/formio/forms/${this.props.formId}/submission`}>
                            <i className="fa fa-chevron-left"></i>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`/formio/forms/${this.props.formId}/submission/${this.props.submissionId}`}>
                            <i className="fa fa-eye"></i> View
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`/formio/forms/${this.props.formId}/submission/${this.props.submissionId}/edit`}>
                            <i className="fa fa-edit"></i> Edit
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`/formio/forms/${this.props.formId}/submission/${this.props.submissionId}/delete`}>
                            <i className="fa fa-trash"></i> Delete
                        </Link>
                    </li>
                </ul>
            </div>
        )
    }
}
export default SubmissionNavbar;