import React, { useCallback, useEffect } from 'react';
import Utils from '../../lib/utils';

const CurrencyInput = ({ defaultValue, label, onChange, ...inputProps }) => {
    const [currencyValue, setCurrencyValue] = React.useState();
    const [error, setError] = React.useState(null);

    const handleChange = useCallback((value) => {
        const numericValue = getCurrencyNumber(value);
        const newError = validateInput(numericValue);
        setError(newError);
        if (onChange) {
            onChange(numericValue, newError);
        }
        setCurrencyValue(Utils.formatCurrency(numericValue));
    }, [onChange]);

    const getCurrencyNumber = (value) => {
        value = value.replace(/[^0-9.]/g, '');
        if (value) {
            const numericValue = parseFloat(value);
            return isNaN(numericValue) ? currencyValue : numericValue;
        }
        return 0;
    };

    const validateInput = (value) => {
        if (isNaN(value)) {
            return "Invalid input: must be a number";
        }
        if (value <= 0) {
            return "Value must be greater than zero.";
        }
        return null;
    };

    useEffect(() => {
        handleChange(defaultValue ? `${defaultValue}` : '250000')
    }, []);

    return (
        <div className='currency-input-field'>
            <div className='currency-input'>
                <div className='label'>{label}:&nbsp;</div>
                <div className="field-validation-message">
                    <input
                        style={error && { borderColor: '#e74c3c' }}
                        onFocus={(e) => {
                            if (error) {
                                e.target.style.borderColor = '#e74c3c';
                            }
                        }}
                        {...inputProps}
                        onChange={({ target }) => handleChange(target.value)}
                        type="text"
                        value={currencyValue}
                    />
                    {
                        error && (
                            <p>
                                {error}
                            </p>
                        )
                    }
                </div>
               
            </div>
        </div>
    );
};

export default CurrencyInput;
