import React from "react";
import * as formsActions from '../../../../actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SubmissionView from './SubmissionView';
import FormNavbar from '../../form/components/FormNavbar';
import SubmissionNavbar from "./SubmissionNavbar";
import FormioTableOfContents from "../../tableofcontent";

class viewSubmission extends React.Component {

    componentDidMount() {
        this.props.actions.getForm(this.props.match.params.formId)
        this.props.actions.getSubmission(this.props.match.params.submissionId, this.props.match.params.formId)
    }
    
    render() {
        const formId = this.props.match.params.formId;
        const submissionId = this.props.match.params.submissionId;

        return (
            <div>
                <div className="container">
                    <FormNavbar formId={this.props.formioForms.form._id} />
                    <SubmissionNavbar formId={formId} submissionId={submissionId} />
                    
                </div>
                <div className="container-fluid">
                    <div className="clearfix">
                        <FormioTableOfContents />
                        <div className="col-sm-6 col-md-9 float-right">
                            <SubmissionView form={this.props.formioForms} submission={this.props.formioSubmission} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        formioForms: state.formioForms,
        formioSubmission: state.formioSubmission,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(formsActions, dispatch)
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(viewSubmission);