import React, { Component } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';

import renderField from '../../../customInputs/renderField';
import renderSelect from '../../../customInputs/renderSelect';
import Utils from '../../../../lib/utils';

import validate from './validate';
class NoteForm extends Component {
  componentDidMount() {
    const { note } = this.props;

    if (note) {
      this.props.dispatch(change('HolderNoteForm', 'name', note.Name || ""));
      this.props.dispatch(change('HolderNoteForm', 'description', note.Description || ""));
      this.props.dispatch(change('HolderNoteForm', 'projectId', note.ProjectId || ""));
    }
  }

  render() {    
    const { handleSubmit, note, dismiss, projectList } = this.props;    
    const {
      labelNoteTitle,
      labelDescription,
      labelProject,
      projectPlaceholder,
      buttonCreateNote,
      buttonEdit,
      buttonCancel
    } = this.props.local.strings.scProfile.notesTasks.modal;

    const buttonText = note ? buttonEdit : buttonCreateNote;    
    const projectOptionsList = Utils.getOptionsList(projectPlaceholder, projectList, 'ProjectFullName', 'ProjectID', 'ProjectFullName');
    
    return (
      <form onSubmit={handleSubmit} className="list-view-filter-form noteForm">
        <div className="container-fluid filter-fields">
          <div className="row">

            <div className="col-sm-6 no-padd">
              <div className="admin-form-field-wrapper">
                <label htmlFor="name">
                  {labelNoteTitle}:
                </label>
                <Field
                  name="name"
                  type="text"
                  component={renderField}
                />
              </div>
            </div>            

            <div className="col-sm-6 no-padd">
              <div className="admin-form-field-wrapper pad-left">
                <label htmlFor="projectId">
                  {labelProject}:
                </label>
                <div className="select-wrapper">
                  <Field
                    name="projectId"
                    component={renderSelect}
                    options={projectOptionsList}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-sm-12 no-padd">
              <div className="admin-form-field-wrapper">
                <label htmlFor="description">
                  {labelDescription}:
                </label>
                <Field
                  name="description"
                  type="textarea"
                  component={renderField}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="noteEditorButtons">
          {this.props.notesTasks.savingTaskError}
          <div>
            <a className="bg-sky-blue-gradient bn" onClick={dismiss}>{buttonCancel}</a>
            <button className="bg-sky-blue-gradient bn" type="submit">{buttonText}</button>
          </div>
        </div>

      </form>
    );
  }
}

NoteForm = reduxForm({
  form: 'HolderNoteForm',
  validate,
})(NoteForm);

const mapStateToProps = (state, ownProps) => ({
  notesTasks: state.CFTasks,
  local: state.localization,
});

export default connect(mapStateToProps)(NoteForm);
