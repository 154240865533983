import * as regex from '../../../lib/regex';
import Utils from '../../../lib/utils';

const validate = (values, props) => {  
  const {
    requiredValidation,
    mustBeNumericValue,
    phoneErrorMessage,
    wrongEmailFormat,
  } = props.local.strings.hiringClients.addHCModal.companyInfoTab;
  const errors = {};
  
  const validateFilled = [
    'registrationCode', 
    'companyName',
    'address',
    'city',
    'stateId',
    'countryId',
    'zipCode',
    'contactName',
    'email',
    'phone',
    'recaptcha', 
  ];

  validateFilled.forEach(name => {
    if(!values[name]) {
      errors[name] = requiredValidation;
    }
  });

  if (values.registrationCode) {
    if (isNaN(values.registrationCode)) {
      errors.registrationCode = mustBeNumericValue;
    } 
  }

  if (values.phone) {
    if (values.phone.match(regex.PHONE)) {
      values.phone = Utils.formatPhoneNumber(values.phone, values.phone);
    } else if (Utils.isInternationalPhone(values.phone)){
      values.phone = Utils.normalizePhoneNumber(values.phone)
    } else {
      errors.phone = phoneErrorMessage;
    }
  }
  
  if (values.insuranceAgencyPhone) {
    if (values.insuranceAgencyPhone.match(regex.PHONE)) {
      values.insuranceAgencyPhone = Utils.formatPhoneNumber(values.insuranceAgencyPhone, values.insuranceAgencyPhone);
    } else if (Utils.isInternationalPhone(values.phone)){
      values.insuranceAgencyPhone = Utils.normalizePhoneNumber(values.insuranceAgencyPhone)
    } else {
      errors.insuranceAgencyPhone = phoneErrorMessage;
    }
  }

  if (values.email) {
    if(!values.email.match(regex.EMAIL)) {
      errors.email = wrongEmailFormat;
    }
  }

  if (values.insuranceAgencyEmail) {
    if(!values.insuranceAgencyEmail.match(regex.EMAIL)) {
      errors.insuranceAgencyEmail = wrongEmailFormat;
    }
  }

  //console.log('validate err: ', errors);  
  return errors;
};

export default validate;
