import React from "react";
const Library = ({ sessionToken }) => {
    const sw = 5;
    const sh = 195;
    const width =  window.innerWidth > window.screen.availWidth ? window.innerWidth - sw : window.screen.availWidth - sw; // adjust the scrollbar
    const height = window.innerHeight > window.screen.availHeight ? window.innerHeight - sh :window.screen.availHeight - sh; // adjust the additional height

    return (
        <iframe
            src={`${process.env.REACT_APP_YURBI_SERVICE_URL}/analytics/vrms_library_auth.html?s=${sessionToken}`}
            title="library"
            height={height}
            width={width}
            frameBorder="0"
        />
    );
};

export default Library;
