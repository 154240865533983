import Api from '../../../../lib/api';
import * as types from './types';
import * as customActions from "../../../../localization/custom";
import { DEFAULT_CERTIFICATE_DOCUMENT_TYPE_ID, MAGELLAN_QUEUE_ID, PENDING_PROCESSING_DOCUMENT_STATUS_ID, SENT_TO_PROCESSING_DOCUMENT_STATUS_ID } from '../../../../lib/appConstants';

export const validateHash = (hash, userHash, callback) => {
  return (dispatch, getState) => {
    const { login, localization } = getState();
    let {
      invalidHash,
      errorConnection,
    } = localization.strings.register.actions;

    const payload = { hash, userHash };
    
    return Api.post(`cf/certUpload/validateHash`, payload)
      .then(response => {
        const { success, data } = response.data;

        if (success) {
          dispatch(setCustomTerms(data[2]));
          if ((data[2] && data[2].length >0)) {
            dispatch(customActions.setCustomTerms(data[2]));
          }
          (data.length > 0) ? callback(true, data[0], data[1]) : callback(false, invalidHash);
        }
        else {
          callback(false, data.description);
        }
      })
      .catch(error => {
        callback(false, errorConnection);
      });
  };
};

export const setRequirementSetsError = (error) => {
  return {
    type: types.SET_REQUIREMENT_SETS_ERROR,
    payload: {
      error
    }
  };
};

export const setRequirementSets = (requirementSets) => {
  return {
    type: types.SET_REQUIREMENT_SETS,
    payload: {
      requirementSets
    }
  };
};

export const setAttachments = (attachments) => {
  return {
    type: types.SET_ATTACHMENTS,
    payload: {
      attachments
    }
  };
};

export const setEndorsements = (endorsements) => {
  return {
    type: types.SET_ENDORSEMENTS,
    payload: {
      endorsements
    }
  };
};

export const setAllEndorsements = (endorsements) => {
  return {
    type: types.SET_ALL_ENDORSEMENTS,
    payload: {
      endorsements
    }
  };
};

export const fetchRequirementSets = (query) => {
  return (dispatch, getState) => {
    const { login, localization } = getState();
    let {
      errorDefault,
      errorConnection,
    } = localization.strings.scProfile.files.actions;
    const token = login.authToken;

    dispatch(setFetchingRequirements(true));

    const urlParameters = `?${Object.keys(query).map(i => `${i}=${query[i]}`).join('&')}`;

    return Promise.all([
      // Fetch requirementSet details
      Api.get(`cf/certUpload/requirementSetsDetail${urlParameters}`, token),
      // Fetch requirementSet documents
      Api.get(`cf/certUpload/requirementSetsDocuments?requirementSetId=${query.requirementSetId}`, token),      
      // Fetch requirementSet endorsements
      Api.get(`cf/certUpload/requirementSetsEndorsements?requirementSetId=${query.requirementSetId}`, token),
      // Fetch all available endorsements
      Api.get(`cf/certUpload/endorsements?holderId=${query.holderId}`, token),
    ])
    .then((response) => {
      dispatch(setFetchingRequirements(false));
      // If one of the requests failed, return error
      if (response.find((res) => !res.data.success)) {
        dispatch(setRequirementSetsError(errorDefault));
      } else {
        response.forEach((res) => {          
          const { data, requirementSetsDocuments, requirementSetsEndorsements, endorsements } = res.data;
          if (data) {
            dispatch(setRequirementSets(data ? data : []))
          }else if (requirementSetsDocuments) {
            dispatch(setAttachments(requirementSetsDocuments ? requirementSetsDocuments : []));
          } else if (requirementSetsEndorsements) {
            dispatch(setEndorsements(requirementSetsEndorsements ? requirementSetsEndorsements : []));
          } else if (endorsements) {
            dispatch(setAllEndorsements(endorsements ? endorsements : []));
          }
        });
      }
    })
    .catch(() => {
      dispatch(setFetchingRequirements(false));
      dispatch(setRequirementSetsError(errorConnection));
    });
  };
};

export const setFetchingDocuments = (isFetching) => {
  return {
    type: types.SET_FETCHING_DOCUMENTS,
    isFetching
  };
};

export const setDocumentsError = (error) => {
  return {
    type: types.SET_DOCUMENTS_ERROR,
    payload: {
      error
    }
  };
};

export const setDocumentsList = (list) => {
  return {
    type: types.SET_DOCUMENTS_LIST,
    payload: {
      list
    }
  };
};

export const fetchDocuments = (query) => {
  return (dispatch, getState) => {
    const { login, localization } = getState();
    let {
      errorDefault,
      errorConnection,
    } = localization.strings.scProfile.files.actions;
    const token = login.authToken;

    dispatch(setFetchingDocuments(true));

    const urlQuery = 'cf/certUpload/projectInsuredDocuments';
    const urlParameters = `?${Object.keys(query).map(i => `${i}=${query[i]}`).join('&')}`;

    return Api.get(`${urlQuery}${urlParameters}`, token)
      .then(response => {
        const { success, projectInsuredDocuments } = response.data;
        dispatch(setFetchingDocuments(false));
        if (success) {
          dispatch(setDocumentsList(projectInsuredDocuments))
          return projectInsuredDocuments;
        } else {
          dispatch(setDocumentsError(errorDefault));
          return false;
        }
      })
      .catch(error => {
        dispatch(setDocumentsError(errorConnection));
      });
  };
};

export const uploadFiles = (payload, callback) => {
  return (dispatch, getState) => {
    const { localization } = getState();
    let {
      errorDefault,
      errorConnection,
    } = localization.strings.register.actions;
    const lastFileIdx = (payload.files) ? (payload.files.length - 1) : 0;

    dispatch(setFetchingDocuments(true));
    
    payload.files && payload.files.forEach((file, idx) => {
      const formData = new FormData();
      const filename = file.name.substr(0, file.name.lastIndexOf("."));
      formData.append('document', file);
      formData.append('name', filename);
      formData.append('ftpUpload', true);

      // add new documents data
      for (let item in payload) {
        formData.append(item, payload[item]);
      }
      let callbackResponseData;
      let linkPayload;
      let createDocumentResponseData;
      // Create Document
      Api.post('cf/certUpload/documents', formData, payload)
        .then(response => {        
          const { success, data } = response.data;

          if (success) {
            createDocumentResponseData = data;
            linkPayload = {
              projectInsuredId: payload.projectInsuredId,
              documentId: data.documentId,
            };
            // Link with projectInsuredDocuments
            return Api.post('cf/certUpload/projectInsuredDocuments', linkPayload)
          } else {
            throw errorDefault;
          }
        })
        .then(response => {
          const { success, data } = response.data;
          dispatch(setFetchingDocuments(false));
          if (success && idx === lastFileIdx) {
            // Set data that needs to be sent back after everything is done
            callbackResponseData = data;
            const documentStatusId = payload.allowDocumentAutoProcess ? SENT_TO_PROCESSING_DOCUMENT_STATUS_ID : PENDING_PROCESSING_DOCUMENT_STATUS_ID;
            // Update document with appropriate type and status
            const newPayload = {
              documentId: createDocumentResponseData.documentId,
              hiringClientId: payload.hiringClientId,
              projectId: payload.projectId,
              subcontractorId: payload.subcontractorId,
              documentStatusId,
              documentTypeId: DEFAULT_CERTIFICATE_DOCUMENT_TYPE_ID,
              garbage: 0,
              urgent: 0,
              queueId: MAGELLAN_QUEUE_ID,
            }
            return Api.put('cf/certUpload/documents', newPayload);
          } else {
            throw errorDefault;
          }
        })
        .then((response) => {
          const { success } = response.data;
          if (success) {
            // Create COI record
            callbackResponseData = {...callbackResponseData, documentUrl: createDocumentResponseData.url}
            return Api.post('cf/certUpload/certificateOfInsurance', {
              documentId: createDocumentResponseData.documentId,
              documentUrl: createDocumentResponseData.url,
              holderId: payload.hiringClientId,
              projectId: payload.projectId,
              insuredId: payload.subcontractorId,
              projectInsuredId: payload.projectInsuredId
            });
          } else {
            throw errorDefault;
          }
        })
        .then((response) => {
          const { success } = response.data;
          callbackResponseData = {...callbackResponseData, certificateId: response.data.certificateId}
          if (!success) {
            throw errorDefault;
          }
        })
        .then(() => {
          // If everything is ok, call callback with data
          callback(true, callbackResponseData);
        })
        // Global catch all
        .catch((e) => {
          callback(false, e || errorConnection);
        });
    });
  };
};

export const createTask = (payload, callback) => {
  return (dispatch, getState) => {
    const { localization } = getState();
    let {
      errorDefault,
      errorConnection,
    } = localization.strings.register.actions;
   //console.log('payload', payload);

    Api.post('cf/certUpload/tasks', payload)
      .then(response => {        
        const { success, data } = response.data;
       //console.log(response.data);
        
        dispatch(setFetchingDocuments(false));
        if (success) {
          callback(true, data);
        } else {
          callback(false, errorDefault);
        }
      })
      .catch(() => {
        callback(false, errorConnection);
      });
  };
};

export const setCustomerToken = (clientToken) => {
  return {
    type: types.SET_CUSTOMER_TOKEN,
    payload: clientToken
  };
};

export const getCustomerId = (profile) => {

  return dispatch => {
    Api.post(
      'braintree/ges/get-client-token',
      profile
    ).then(response => {
      const {success, data } = response.data;
      if (!success) {
        return false
      }
      dispatch(setCustomerToken(data.clientToken));
      return data.clientToken;
    }).catch(err => {
      throw Error(err.message);
    })

  }
}

export const pay = (values) => {
  return dispatch => {
    Api.post(
      'braintree/ges/checkout',
      values
    ).then(response => {
      const {success, data } = response.data;
      if (success) {
        return true;
      } else {
        return data.description
      }
    });
  }
}

export const setCustomTerms = (customTerms) => {
  return {
    type: types.SET_CUSTOM_TERMS,
    payload: customTerms
  };
};

export const checkIfArchivedOrExempted = (queryParams, callback) => {
  return (dispatch, getState) => {
    const { localization } = getState();
    let {
      errorDefault,
      errorConnection,
    } = localization.strings.register.actions;

    const urlParameters = `?${Object.keys(queryParams).map(i => `${i}=${queryParams[i]}`).join('&')}`;

    return Api.get(`cf/certUpload/checkIfArchivedOrExempted${urlParameters}`)
      .then(response => {
        const { success, isArchived, isExempted } = response.data;
        (success)
          ? callback(true, isArchived, isExempted)
          : callback(false, errorDefault);
      })
      .catch(error => {
        callback(false, errorConnection);
      });
  };
};

export const fetchOnlyRequirements = (queryParams, callback) => {
  return (dispatch, getState) => {
    const { localization } = getState();
    let {
      errorDefault,
      errorConnection,
    } = localization.strings.register.actions;

    dispatch(setFetchingRequirements(true));

    const urlParameters = `?${Object.keys(queryParams).map(i => `${i}=${queryParams[i]}`).join('&')}`;

    Api.get(`cf/certUpload/requirementSetsDetail${urlParameters}`)
      .then(response => {
        const { success, data } = response.data;
        dispatch(setFetchingRequirements(false));
        (success)
          ? callback(false, data)
          : callback(true, errorDefault);
      })
      .catch(error => {
        dispatch(setFetchingRequirements(false));
        callback(true, errorConnection);
      });
  };
};


export const setFetchingRequirements = (isFetching) => {
  return {
    type: types.SET_FETCHING_REQUIREMENTS,
    isFetching
  };
};
