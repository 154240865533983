export const SET_CONFIRM_ALL = 'SET_CONFIRM_ALL';
export const SET_WAIVE_ALL = 'SET_WAIVE_ALL';
export const SET_SHOW_ALL = 'SET_SHOW_ALL';
export const SET_HIDDE_ALL = 'SET_HIDDE_ALL';
export const SET_CONFIRM_BY_ID = 'SET_CONFIRM_BY_ID';
export const SET_WAIVE_BY_ID = 'SET_WAIVE_BY_ID';
export const SET_UNDO_BY_ID = 'SET_UNDO_BY_ID';

/** DEFICIENCES */
export const SET_FETCH_DEFICIENCES = 'SET_FETCH_DEFICIENCE';
export const SET_FETCH_DEFICIENCES_SUCCESS = 'SET_FETCH_DEFICIENCE_SUCCESS';
export const SET_FETCH_TOGGLE_DEFICIENCES_SUCCESS = 'SET_FETCH_TOGGLE_DEFICIENCE_SUCCESS';

export const CONFIRM_ALL = 'CONFIRM_ALL';
export const WAIVE_ALL = 'WAIVE_ALL';
export const CONFIRM_BY_ID = 'CONFIRM_BY_ID';
export const WAIVE_BY_ID = 'WAIVE_BY_ID';
export const UNDO_BY_ID = 'UNDO_BY_ID';
export const DEFICIENCES_UNDO = '0';
export const DEFICIENCES_MAYOR = '1';
export const DEFICIENCES_MINOR = '2';
export const DEFICIENCES_ENDORSEMENT = '3';
export const SET_SELECT_ALL_WAIVER = 'SET_SELECT_ALL_WAIVER';
export const SET_UNSELECTED_ALL_WAIVER = 'SET_UNSELECTED_ALL_WAIVER';
export const SET_SELECT_WAIVER = 'SET_SELECT_WAIVER';
export const SET_UNSELECTED_WAIVER = 'SET_UNSELECTED_WAIVER';
export const SET_FETCH_WAIVER_DEFICIENCES = 'SET_FETCH_WAIVER_DEFICIENCES';
export const SET_FETCH_WAIVER_DEFICIENCES_SUCCESS = 'SET_FETCH_WAIVER_DEFICIENCES_SUCCESS';
export const SET_FETCH_UNDO_WAIVER_DEFICIENCES = 'SET_FETCH_UNDO_WAIVER_DEFICIENCES';
export const SET_FETCH_UNDO_WAIVER_DEFICIENCES_SUCCESS = 'SET_FETCH_UNDO_WAIVER_DEFICIENCES_SUCCESS';

export const SET_SELECT_ALL_CONFIRM = 'SET_SELECT_ALL_CONFIRM';
export const SET_UNSELECTED_ALL_CONFIRM = 'SET_UNSELECTED_ALL_CONFIRM';
export const SET_SELECT_CONFIRM = 'SET_SELECT_CONFIRM';
export const SET_UNSELECTED_CONFIRM = 'SET_UNSELECTED_CONFIRM';