import _ from 'lodash';

import * as regex from '../../../../../lib/regex';

export default (values, props) => {
  const {
    phoneErrorMessage,
    postalCodeErrorMessage,
    requiredValidation,
  } = props.local.strings.hiringClients.addHCModal.companyInfoTab;

  const errors = {};

  if (_.isEmpty(_.get(values, 'companyName', '').trim())) {
    errors.companyName = requiredValidation;
  }

  if (_.isEmpty(_.get(values, 'companyAddress1', '').trim())) {
    errors.companyAddress1 = requiredValidation;
  }

  if (_.isEmpty(_.get(values, 'city', '').trim())) {
    errors.city = requiredValidation;
  }

  if (_.isEmpty(_.get(values, 'state', '').trim())) {
    errors.state = requiredValidation;
  }

  if (_.isEmpty(_.get(values, 'phone', '').trim())) {
    errors.phone = requiredValidation;
  } else if (!regex.PHONE.test(values.phone)) {
    errors.phone = phoneErrorMessage;
  }
  
  if (values.phone2 && !regex.PHONE.test(values.phone2)) {
    errors.phone2 = phoneErrorMessage;
  }

  if (_.isEmpty(_.get(values, 'subdomain', '').trim())) {
    errors.subdomain = requiredValidation;
  }

  if (_.isEmpty(_.get(values, 'postalCode', '').trim())) {
    errors.postalCode = requiredValidation;
  } else if (!regex.POSTAL_CODE.test(values.postalCode)) {
    errors.postalCode = postalCodeErrorMessage;
  }

  if (_.get(values, 'entityType.value', 0) === 1) {
    const parentHC = _.get(values, 'parentHC.value');
    if (!parentHC || isNaN(parentHC) || parentHC < 0) {
      errors.parentHC = requiredValidation;
    }
  }
  
  if (!values.companyLogo) {
    errors.companyLogo = requiredValidation;
  }

  return errors;
};