import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import Utils from '../../../../lib/utils';
import PTable from '../../../common/ptable';
import * as scLogsAction from './actions';
import {
  FORCE_FINANCIAL_UPDATE_FIN_INFO,
  FORCE_RENEWAL_FIN_INFO,
  MARK_INCOMPLETE_FIN_INFO,
  MARK_INCOMPLETE_FORM,
  PQ_FINANCIAL_INFO_LOG_TYPE,
  PQ_FORM_LOG_TYPE, SAVE_AND_SUBMIT_FIN_INFO,
  SAVE_AND_SUBMIT_FORM, SAVE_FIN_INFO,
  SAVE_FORM
} from "../../../../lib/appConstants";
import moment from "moment/moment";

const SCLogs = (props) => {

  useEffect (() => {
      props.actions.fetchLogs({ formId : props.savedFormId, logType: props.logType });
  },[]);

  const openFinancialsORForms = () => {
    if(props.logType === 1) {
       props.actions.setFinancialLogsVisible(false);
    } else {
       props.actions.setFormLogsVisible(false);
    }
  }

  // if (!props.common.checkingAuthorizations) {
  //   if(!props.common.usersAuth) {
  //     return <Redirect push to="/dashboard" />;
  //   }
  // }

  const {
    tableHeader
  } = props.local.strings.logs;

  const { UserName, Activity, DateLastModified, UserEmail } = tableHeader;

  const logsTableMetadata = {
    fields: [
      'name',
      'email',
      'readableDescription',
      'timeStamp'
    ],
    header: {
      name: UserName,
      email: UserEmail,
      readableDescription: Activity,
      timeStamp: DateLastModified,
    }
  };

  const renderLogMessage = (logType, eventType) => {
    switch (logType) {
      case PQ_FINANCIAL_INFO_LOG_TYPE:
        switch(eventType) {
          case SAVE_FIN_INFO:
            return 'Save Financial info'
          case SAVE_AND_SUBMIT_FIN_INFO:
            return 'Save and Submit Financial info'
          case MARK_INCOMPLETE_FIN_INFO:
            return 'Mark as Incomplete Financial info'
          case FORCE_RENEWAL_FIN_INFO:
            return 'Force Renewal Financial info'
          case FORCE_FINANCIAL_UPDATE_FIN_INFO:
            return 'Force Financial Update'
          default:
            return '';
        }
      case PQ_FORM_LOG_TYPE:
        switch(eventType) {
          case SAVE_FORM:
            return 'Save Form'
          case SAVE_AND_SUBMIT_FORM:
            return 'Save and Submit Form'
          case MARK_INCOMPLETE_FORM:
            return 'Mark as Incomplete Form'
          default:
            return ''
        }
      default:
        return '';
    }
  }

  const logsTableBody = props.logs.map((log) => {
    const activity =  (
      <div>
        <span className="light-blue">{log.userName} </span><span>{renderLogMessage(log.LogType, log.EventType)}</span>
      </div>
    );
    const formattedDate = moment(log.TimeStamp).utc().format('LL @ HH:mm:ss');
    return {
      name: log.userName,
      email: log.userEmail,
      readableDescription: activity,
      timeStamp: formattedDate
    }
  });

  const logsTableData = {
    fields: logsTableMetadata.fields,
    header: logsTableMetadata.header,
    body: logsTableBody
  };

  const paginationSettings = {
    total: props.logs.length,
  };

  const colsWidth = [
    '20%',
    '20%',
    '40%',
    '20%',
  ];

  return (
        <div className='admin-view-body' style={{ minHeight:200 }}>
           <div className="row">
              <div className="col-sm-12">
                  <nav className="finance-nav">
                    <ul>
                        <li>
                          <a
                            className="finance-nav-link nav-bn"
                            onClick={openFinancialsORForms}
                          >
                            Back
                          </a>
                        </li>
                    </ul>
                  </nav>
              </div>
          </div>
          <div style={{ marginTop:20 }}>
             <PTable
              sorted={true}
              items={logsTableData}
              isFetching={props.fetchingLogs}
              customClass='templates-list'
              pagination={paginationSettings}
              colsConfig={colsWidth}
             />
          </div>
         
      </div>
    );
}

const mapStateToProps = (state) => {
    const { fetchingLogs, logs, logsError} = state.scLogs;
    return {
    logs,
    fetchingLogs,
    logsError,
    local: state.localization,
    common: state.common
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(scLogsAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SCLogs);
