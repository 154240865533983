import * as types from "../actions/types";

const initialState = {
  dashboardFetchComplete: false,
  dashboards: {},
  sessionToken: null,
  sessionTimeout: null,
  error: null,
  errorMessage: null,
  isFetchApiCall: false,
  isDashboardApiCall: false,
}

export default function yurbiReducer(
  state = initialState,
  action
) {
    switch (action.type) {
      case types.SET_SESSION:
        return Object.assign({}, state, {
          sessionToken: action.sessionToken,
          sessionTimeout: action.sessionTimeout,
        });
      case types.SET_DASHBOARDS:
        return Object.assign({}, state, {
          dashboardFetchComplete: true,
          dashboards: action.dashboards,
        });
      case types.SET_ERROR:
        return Object.assign({}, state, {
          error: action.error,
          errorMessage: action.errorMessage
        });
      case types.RESET:
        return initialState;
      case types.SET_SESSION_API_CALL:
        return Object.assign({}, state, {
          isFetchApiCall: action.isFetchApiCall,
        });
      case types.SET_DASHBOARD_API_CALL:
        return Object.assign({}, state, {
          isDashboardApiCall: action.isDashboardApiCall,
        });
      default:
        return state;
    }
}
