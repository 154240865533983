import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Form, reduxForm, Field, change} from "redux-form";
import validate from './validate'
import {renderTextField, renderSelectField} from "./FieldsWrappers";
import {Button, Typography, Grid, FormControl} from "@material-ui/core";
import {connect} from "react-redux";
import Editor from '../../mailComposer/editor'
import * as mailActions from '../../mailComposer/actions';
import {bindActionCreators} from "redux";
import Swal from "sweetalert2";
import BlockUi from 'react-block-ui';
import './blockUI.css';
import MenuItem from "@material-ui/core/MenuItem";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  buttonPadding: {
    paddingBottom: 10,
    paddingTop: 10,
    paddingRight: 20,
    paddingLeft: 20
  },
}));

const FormSendMail = props => {
  const classes = useStyles();

  const {handleSubmit, submitting, invalid, onSendEmail, } = props;
  const [sent, setSent] = React.useState(props.sent);

  const sendEmail = (values) => {

    const {holderId, insuredId, projectId, locale: {mailSentOK, mailSentFail}, documents} = props;
    const payload = {
      system: "cf",
      taskDetail: "",
      dueDate: "",
      hiringClientId: holderId,
      projectId: projectId,
      subcontractorId: insuredId,
      body: props.mailComposer.mail.bodyHTML,
      emailsList: values.mailTo,
      subject: values.mailSubject,
      templateId: values.mailTemplate,
      documents: documents,
      rejectEmail: true
    }
    props.mailActions.sendMail(payload)
      .then( success => {
        if (success) {
          Swal({
            type: 'success',
            title: mailSentOK.title,
            text: mailSentOK.text.replace('#1', values.mailTo),
          });
          onSendEmail(values);
          setSent(true);
        } else {
          Swal({
            type: 'error',
            title: mailSentFail.title,
            text: mailSentFail.text,
          });
        }
      })
      .catch( err => {
      //console.log('err: ', err);
      })
    ;

    return false;
  }

  const handleChangeTemplate = (event) => {
    const id = event.target.value

    if (id && id > 0) {
      const currentTemplate = getTemplateData(id);
      props.mailActions.setMailTemplate(id, props.templates.list);
      props.dispatch(change('formSendMail', 'mailSubject', currentTemplate.subject));
    }
  }

  useEffect( () => {

    const {recipients, holderName, insuredName, projectName, templateId, subject, templates: {list}} = props;

    props.dispatch(change('formSendMail', 'mailTo', recipients));
    props.dispatch(change('formSendMail', 'holderName', holderName));
    props.dispatch(change('formSendMail', 'insuredName', insuredName));
    props.dispatch(change('formSendMail', 'projectName', projectName));
    if (templateId) {
      props.mailActions.setMailTemplate(props.templateId, list);
      props.dispatch(change('formSendMail', 'mailTemplate', templateId));
      props.dispatch(change('formSendMail', 'mailSubject', subject));
    }
    }, [])

  const getTemplateData = (id) => {
    const templates = props.templates.list;
    for (let i=0; i<templates.length; i++) {
      if (Number(id) === templates[i].id) {
        return templates[i]
      }
    }
  }

  const getTemplateOptions = (templateList) => {
    const selectOptions = [];
    selectOptions.push(<MenuItem value="0">None</MenuItem>);

    if (props.templates.list) {
      templateList.map((elem) => {
        selectOptions.push(<MenuItem
          key={'mailTemplate' + elem.id}
          value={elem.id}
        >{elem.templateName}</MenuItem>)
      })
    }
    return selectOptions;
  }

  const {templateId, templates: {list}} = props;
  const {locale: {fields: {insured, holder, recipients, project, subject, template}}} = props;
  const {locale: {buttons: {send}}} = props
  return (
    <React.Fragment>
      <BlockUi tag={"div"} blocking={sent}>
        <Form onSubmit={handleSubmit(sendEmail)}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Field name={"holderName"}
                     component={renderTextField}
                     label={holder.label}
                     disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field name={"insuredName"}
                     component={renderTextField}
                     label={insured.label}
                     disabled={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Field name={"mailTo"}
                     component={renderTextField}
                     label={recipients.label}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field name={"projectName"}
                     component={renderTextField}
                     label={project.label}
                     disabled={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Field name={"mailSubject"}
                     component={renderTextField}
                     label={subject.label}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="mailTemplate"
                label={template.label}
                component={renderSelectField}
                input={{name: 'mailTemplateInput', defaultValue: templateId, onChange: handleChangeTemplate}}
                children={getTemplateOptions(list)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Editor
                setMailEditorState={props.mailActions.setMailEditorState}
              ></Editor>
            </Grid>
          </Grid>
          <Grid container spacing={4} alignItems={"flex-start"} justify={"center"}>
            <Grid item>
              <Button
                className={classes.buttonPadding}
                variant={"contained"}
                color={"primary"}
                type={"submit"}
                disabled={submitting || invalid}
                disableElevation >
                <Typography>{send}</Typography>
              </Button>
            </Grid>
          </Grid>
        </Form>
      </BlockUi>
    </React.Fragment>
  )

};

FormSendMail.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const reduxFormSendMail = reduxForm({
  form: 'formSendMail',
  validate,
  required: [
    'mailTo',
    'mailSubject',
  ],
})(FormSendMail);

const mapDispatchToProps = (dispatch) => {
  return {
    mailActions: bindActionCreators(mailActions, dispatch),
  }
}

const mapStateToProps = (state) => {
  return {
    mailComposer: state.mailComposer,
    templates: state.templates,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxFormSendMail);