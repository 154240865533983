import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';

import AddHolderModal from '../modals/addHolderModal';
import HolderInfo from './HolderInfo';
import Tabs from './tabs';
import CopyHolderModal from '../modals/copyHolderModal';

import * as holdersProfileActions from './actions';
import * as registerActions from '../../register/actions';
import * as commonActions from '../../common/actions';
import * as holderActions from '../../certfocus/holders/actions';
import * as loginActions from '../../auth/login/actions';

import './holdersProfile.css';

class HoldersProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      showCopyHolderModal: false,
    };
  }

  componentDidMount() {
    const { holderId } = this.props.match.params;
    this.props.actions.fetchHolderProfile(holderId);
    this.props.holderActions.fetchHolderChildren(holderId);    
    this.props.registerActions.fetchResources();
    this.props.loginActions.getProfile();
    localStorage.setItem('currentSystem', 'cf');
    this.props.loginActions.setCurrentSystem('cf');
  }

  componentWillUnmount() {
    //this.props.commonActions.setHeaderTitle('');
  }

  componentDidUpdate() {
    if (this.props.holderProfile.profileData) {
      this.setBreadcrumb();
     }
  }
  
  onCloseEditProfile = (update) => {
    if(update){
      const { holderId } = this.props.match.params;
      this.props.actions.fetchHolderProfile(holderId);
    }

    this.setState({ showModal: false });
  }

  onEditProfile = () => {
    this.setState({ showModal: true });
  }

  onCopyHolder = () => {
    this.setState({ showCopyHolderModal: true });
  }

  onCloseCopyHolder = (update) => {
    this.setState({ showCopyHolderModal: false });
  }

  setBreadcrumb = () => {
    this.props.commonActions.setBreadcrumbItems([
      {
        pathName: this.props.holderProfile.profileData.name,
        hrefName: window.location.pathname
      }
    ]);
  }

  render() {    
    return (
      <div className="holders-profile">
        <Modal
          show={this.state.showModal}
          onHide={this.onCloseEditProfile}
          className="add-item-modal add-hc"
        >
          <Modal.Body className="add-item-modal-body mt-0">
            <AddHolderModal
              onHide={this.onCloseEditProfile}
              close={this.onCloseEditProfile}
              profile={this.props.holderProfile.profileData}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showCopyHolderModal}
          onHide={this.onCloseCopyHolder}
          className="add-item-modal add-entity-small"
        >
          <Modal.Body>
            <CopyHolderModal
              onHide={this.onCloseCopyHolder}
              close={this.onCloseCopyHolder}
              profile={this.props.holderProfile.profileData}
            />
          </Modal.Body>
        </Modal>

        <div className="container-fluid">
          <HolderInfo
            holderProfile={this.props.holderProfile}
            onEditProfile={this.onEditProfile}
            holderArchived={this.props.holderProfile.profileData.archived}
            onCopyHolder={this.onCopyHolder}
          />

          {!_.isEmpty(this.props.holderProfile.profileData) &&
            <Tabs 
              holderArchived={this.props.holderProfile.profileData.archived}
              holderProfile={this.props.holderProfile.profileData}
            />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    holderProfile: state.holdersProfile,
    local: state.localization,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(holdersProfileActions, dispatch),
    registerActions: bindActionCreators(registerActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    holderActions: bindActionCreators(holderActions, dispatch),
    loginActions: bindActionCreators(loginActions, dispatch),
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(HoldersProfile);
