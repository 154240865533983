import React, { Component } from 'react';
import Tabs from './tabs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import * as commonActions from '../../common/actions';
import {profileHolderAdminID, profileCFAdmin} from '../../../lib/appConstants'


class Settings extends Component {
  
  componentDidMount() {
    this.props.commonActions.setBreadcrumbItems([]);
  }

  render() {
    const { CFRoleId } = this.props.login.profile;

    return (CFRoleId && (CFRoleId !== profileCFAdmin && CFRoleId !== profileHolderAdminID))
      ? <Redirect push to="/profile" />
      : <Tabs />;
  }
};

const mapStateToProps = (state) => {
  return {
    local: state.localization,
    login: state.login,    
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
