import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
import Toggle from 'react-toggle';
import Swal from "sweetalert2";
import { showErrorAlert } from '../../../alerts/index';
import Select from 'react-select';
import * as listActions from '../../../formList/actions';
import * as formActions from '../../../formBuilder/actions';
import * as builderActions from '../../../formBuilder/actions';
import * as previewerActions from '../../../formPreviewer/actions'
import Utils from '../../../../lib/utils';
import PTable from '../../../common/ptable';
import FormOptions from './formOptions';
import HideScorecardsFieldsModal from './modals/HideScorecardsFieldsModal';
import {fetchAllForm} from '../../../formList/actions'
import './forms.css';
import CurrencyInput from '../../../customInputs/currencyInput';
import Account from './modalComponent/Account';
import SpinnerLoader from '../../../common/loader/SpinnerLoader';

const headerStyle = {
  height: '20px',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '10px',
  width: '100%'
}

const headerButtonStyle = {
  position: 'absolute',
  right: 0,
  padding: '5px 10px',
  borderRadius: '4px',
  backgroundColor: '#fff',
  border: '1px solid gray',
  transition: 'background-color 0.3s ease',
  display: 'flex',
  alignItems: 'center',
  color: '#2E5965',
  fontWeight: 500,
  gap: '3px'
}

class Forms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {pageNumber: 1},
      order: {name: 'asc', creatorFullName: 'desc'},
      hiringClientId: props.match.params.hcId,
      tableOrderActive: 'name',
      showModalDA: false,
      showEditConfiguration: false,
      formSelected: null,
      discreetAccountsSelected: [],
      discreetAccountByForm: [],
      oldDiscreetAccountByForm: [],
      aggregateExposureValue: null,
      accountDisplayTypeSelected: null,
      loadingDiscreteAccounts: false,
      showHideScorecardsFieldsModal: false,
      redirectToPreviewer: false,
      formIOPreviewId: undefined,
      archiveForm: null,
      formReplacementId: null,
      configLoader: false
    };
    props.previewerActions.fetchFormDropDownLists();

  }

  componentDidMount() {
    this.props.actions.fetchForms({
      hiringClientId: this.props.match.params.hcId, 
      orderBy: 'name', 
      orderDirection: 'ASC',
      justFormData: true,
      pageSize: 10,
      pageNumber: 1
    });
  }

  componentDidUpdate(prevProps) {
    const { list, discreetAccounts } = this.props.forms;
    const listChanged = list !== prevProps.forms.list;
    const discreetAccountsChanged = discreetAccounts !== prevProps.forms.discreetAccounts;

    if (listChanged || discreetAccountsChanged) {
    //console.log('SET DISCRETE ACCOUNTS');
      this.setDiscreetAccounts();
    }
  }

  handleMouseEnter = (e, userId) => {
    // console.log('mouse enter')
    // TO DO: set callback in the previous fetch resources actions so you can execute the resizing once the async was actually solved
  }

  handleMouseLeave = (e) => {
    // const sidebar = document.querySelector('.viewport .sidebar-col');
  }

  clickOnColumnHeader = (e, field) => {
    if (field === 'options') return;
    // get base query
    const pageNumber = this.state.filter.pageNumber;
    const orderDirection = this.state.order[field] === 'asc' ? 'DESC' : 'ASC'
    let query = Utils.getFetchQuery(field, pageNumber, orderDirection);
    query.hiringClientId = this.state.hiringClientId;
    query.justFormData = true;
    // fetch using query
    this.props.actions.fetchForms(query);
    // save new active tab and order
    let newState = {
      tableOrderActive: field,
      order: {
        name: field === 'name' ? 'asc' : 'desc',
        creatorFullName: field === 'creatorFullName' ? 'asc' : 'desc',
      }
    };
    newState.order[field] = this.state.order[field] === 'asc' ? 'desc' : 'asc';
    this.setState(newState);
  }

  handleDefaultForm = form => {
    const noDefaultSelected = this.props.forms.list.some(e => e.isDefault === 1);
    const hasDefault = this.props.forms.list.some(e => (e.isDefault === 1 && e.id !== form.id));
    if (noDefaultSelected && !hasDefault) {
      return false;
    }
    if (!form.archived) {
      this.props.actions.updateFormAsDefault(
        form,
        error => showErrorAlert('Error', error),
      );
    } else {
      showErrorAlert('Error', 'Form is archived can not set as default');
    }
    
  }

  setPageFilter = (e, pageNumber) => {
    if (this.state.filter.pageNumber !== pageNumber) {
      // get base query
      const field = this.state.tableOrderActive;
      const orderDirection = this.state.order[field] === 'asc' ? 'ASC' : 'DESC'
      const query = Utils.getFetchQuery(field, pageNumber, orderDirection);
      query.hiringClientId = this.state.hiringClientId;
      query.justFormData = true;

      // fetch using query
      this.props.actions.fetchForms(query);

      // save page number
      this.setState({filter: {pageNumber}});
    }
  }

  onAddNewForm = () => {
    // Allow user to choose between legacy and formio outside prod env.
    Swal({
      showConfirmButton: true,
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Legacy',
      cancelButtonText: 'Formio (Unstable)',
    })
      .then((result) => {
        this.props.builderActions.clearForm();
        localStorage.removeItem('formBuilderForm');
        localStorage.setItem('createFormHiringClientId', this.state.hiringClientId);
        if(result.value) {
          this.props.history.push('/forms/new');
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.props.history.push('/formio/forms/create');
        }
      })
      .catch(() => null);
  }

  onEditForm = (form) => {
    if (form.formId) {
      this.props.history.push(`/formio/forms/${form.formId}/edit`, {
        hcId: this.state.hiringClientId,
      });
    } else {
      this.props.builderActions.getFormById(form.id);
      this.props.history.push('/forms/edit/'+form.id);
    }
  }
  // edit configuration modal
  showEditConfigurationModal = () => {
    const { formSelected } = this.state;
    this.setState({configLoader: true});
    this.props.actions.fetchFormDiscreteAccountConfigurations({formId: formSelected}).then(()=> {
      this.setState({
        showEditConfiguration: true,
        showModalDA: false,
        configLoader: false,
      });
      
    });
    
  }

  staticLoader= () =>{
    return (
      <div className="spinner-wrapper" style={{margin: 0, display: 'flex',alignItems:'center', justifyContent: 'center', maxWidth: 'fit-content', position: 'relative'}}>
        <div className="spinner" style={{width: '20px', height: '20px', position: 'relative', borderWidth: '4px'}}></div>
      </div>
    )
  }

  showModalAssignAccount = (form) => {
    const { discreetAccountByForm } = this.state;

    const selectedForm = discreetAccountByForm.find(discreteForm => discreteForm.formId === form.id);

    this.setState({
      showModalDA: true,
      formSelected: form.id,
      accountDisplayTypeSelected: selectedForm.accountDisplayTypeId,
    }, () => this.renderDiscreetAccounts(form.id));
  }

  renderLinkAssingDiscreetAccounts = (form) => {
    let component = (
      <a
        onClick={() => this.showModalAssignAccount(form)}
        className="cell-table-link icon-edit link-ada-color"
      >
        Assign
      </a>
    );

    return component;
  }

  handleChange = (formId, value) => {
    const { discreetAccountByForm } = this.state;

    const updatedDiscreetAccountByForm = discreetAccountByForm.map(form => {
      if (form.formId === formId) {
        return {
          ...form,
          data: form.data.map(discreteAccount => {
            if (discreteAccount.daId === value) {
              return {
                ...discreteAccount,
                selected: !discreteAccount.selected,
                tcSelected: false,
              };
            } else {
              return discreteAccount;
            }
          }),
        };
      } else {
        return form;
      }
    });

    this.setState({ discreetAccountByForm: updatedDiscreetAccountByForm });
  }
  handleChangeTC = (formId, value) => {
    const { discreetAccountByForm } = this.state;

    const updatedDiscreetAccountByForm = discreetAccountByForm.map(form => {
      if (form.formId === formId) {
        return {
          ...form,
          data: form.data.map(discreteAccount => {
            if (discreteAccount.daId === value) {
              return {
                ...discreteAccount,
                tcSelected: !discreteAccount.tcSelected,
              };
            } else {
              return discreteAccount;
            }
          }),
        };
      } else {
        return form;
      }
    });

    this.setState({ discreetAccountByForm: updatedDiscreetAccountByForm });
  }

  handleAggregateExposureValue = ({value}) => {
    const { discreetAccountByForm, formSelected } = this.state;

    const updatedDiscreetAccountByForm = discreetAccountByForm.map(form => {
      if (form.formId === formSelected) {
        form.aggregateExposureValue = value;
        return form;
      }
      return form;
    });

    this.setState({ discreetAccountByForm: updatedDiscreetAccountByForm });
  }

  setDiscreetAccounts = () => {
    let resultlist = [];

    if (this.props.forms.list) {
      let selected = false;
      let oldSelected = false;
      let tcSelected = false;
      let dataList = [];
      this.props.forms.list.forEach(form => {
        this.props.forms.discreetAccounts.forEach(da => {
          selected = false;
          oldSelected = false;
          tcSelected = false;
          form.selected.forEach((element) => {
            if (element == da.Id) {
              selected = true;
              oldSelected = true;
            }
          })
          form.tcSelected.forEach((element) => {
            if (element == da.Id) {
              tcSelected = true;
            }
          })
          let data = {
            daId: da.Id,
            daName: da.Name,
            selected,
            oldSelected,
            tcSelected,
          };

          dataList.push({ ...data });
        });

        resultlist.push({
          formId: form.id,
          accountDisplayTypeId: form.accountDisplayTypeId,
          data: [...dataList],
          aggregateExposureValue: form.aggregateExposureValue
        });
        dataList = [];
      });
    }

    this.setState({
      discreetAccountByForm: resultlist,
    });
  }

  setAccountDisplayTypeValue = (val) => {
    const { discreetAccountByForm, formSelected } = this.state;

    if (val === 1) {
      const updatedDiscreetAccountByForm = discreetAccountByForm.map(form => {
        if (form.formId === formSelected) {
          return {
            ...form,
            data: form.data.map(discreteAccount => ({
              ...discreteAccount,
              selected: false,
            })),
          };
        } else {
          return form;
        }
      });

      this.setState({
        accountDisplayTypeSelected: val,
        discreetAccountByForm: updatedDiscreetAccountByForm,
      });
    } else if (val === 2) {
      const filteredForm = this.props.forms.list.filter((f) => f.id == formSelected);
      const form = filteredForm.length ? filteredForm[0] : {} ;

      const updatedDiscreetAccountByForm = discreetAccountByForm.map((f) => {
        if (f.formId === formSelected) {
          return {
            ...f,
            aggregateExposureValue: form.aggregateExposureValue
          };
        } else {
          return f;
        }
      })

      this.setState({ 
        accountDisplayTypeSelected: val,
        discreetAccountByForm: updatedDiscreetAccountByForm,
       });
    } else {
      this.setState({ accountDisplayTypeSelected: val });
    }
  }

  renderNewDiscreteAccount = () => {
    const { formSelected } = this.state;
    const formDiscreteAccountConfigurations = this.props.forms.discreetAccountConfigurations

    return (<Fragment>
          {
            formDiscreteAccountConfigurations.map((discreteAccount) =>
              (<Account
                key={discreteAccount.discreteAccountId}
                formId={formSelected}
                discreteAccountId={discreteAccount.discreteAccountId}
                discreteAccountName={discreteAccount.discreteAccountName}
                labels={discreteAccount.labels}
              />)
            )
          }
        </Fragment>
    )
  }

  renderDiscreetAccounts = () => {
    const { accountDisplayTypeSelected, loadingDiscreteAccounts } = this.state;

    const selectedForm = this.state.discreetAccountByForm.find(x => x.formId === this.state.formSelected);
    if (!selectedForm || (selectedForm && !selectedForm.data)) {
      return <></>
    }
    const formDiscreteAccountsChunks = _.chunk(selectedForm.data, 3);

    const disabledFields = (accountDisplayTypeSelected === 1) || loadingDiscreteAccounts;
    const discreteAccountsGrid = formDiscreteAccountsChunks.map((chunk, index) => (
      <div className="row mt-3" key={index}>
        {chunk.map(discreteAccount => (
          <div className="col col-4" key={discreteAccount.daId}>
            <div className='row'>
              <div className="col col-10">
                {discreteAccount.daName}
              </div>
              <div className="col col-2" style={{display: 'flex', gap: '5px'}}>
                <input
                  style={{margin:0}}
                  disabled={disabledFields}
                  type="checkbox"
                  onChange={() => this.handleChange(this.state.formSelected, discreteAccount.daId)}
                  checked={discreteAccount.selected}
                />
                {
                  (accountDisplayTypeSelected === 3 && ![16, 17,18,19].includes(discreteAccount.daId)) && (
                    <input
                      style={!discreteAccount.selected ? { cursor: "not-allowed", margin: 0 } : { margin: 0 }}
                      disabled={!discreteAccount.selected || loadingDiscreteAccounts}
                      type="checkbox"
                      onChange={() => this.handleChangeTC(this.state.formSelected, discreteAccount.daId)}
                      checked={discreteAccount.tcSelected}
                    />
                  )
                }
              </div>
            </div>
          </div>
        ))}
      </div>
    ));

    const aggregateExposureValue = selectedForm.aggregateExposureValue;
    
    return (
      <Fragment>
        <div className="row mt-3">
          <div className="col col-4 discrete-acounts-modal-header">
            <div className="row">
              <div className="col col-10 discrete-acounts-modal-header-title">
                Regular Accounts Only:
              </div>

              <div className="col col-2">
                <input
                  disabled={loadingDiscreteAccounts}
                  type="checkbox"
                  onChange={() => this.setAccountDisplayTypeValue(1)}
                  checked={accountDisplayTypeSelected === 1}
                />
              </div>
            </div>
          </div>
          <div className="col col-4 discrete-acounts-modal-header">
            <div className="row">
              <div className="col col-10 discrete-acounts-modal-header-title">
                Discrete Accounts Only:
              </div>

              <div className="col col-2">
                <input
                  disabled={loadingDiscreteAccounts}
                  type="checkbox"
                  onChange={() => this.setAccountDisplayTypeValue(2)}
                  checked={accountDisplayTypeSelected === 2}
                />
              </div>
            </div>
          </div>
          <div className="col col-4 discrete-acounts-modal-header">
            <div className="row">
              <div className="col col-10 discrete-acounts-modal-header-title">
                Both Accounts:
              </div>

              <div className="col col-2">
                <input
                  disabled={loadingDiscreteAccounts}
                  type="checkbox"
                  onChange={() => this.setAccountDisplayTypeValue(3)}
                  checked={accountDisplayTypeSelected === 3}
                />
              </div>
            </div>
          </div>
        </div>
        {
          accountDisplayTypeSelected === 3 && (
          <div className='row mt-3' style={{fontSize: '14px', color:'#2E5965', fontWeight: 500}}>
            <div className="col col-4" style={{textAlign: 'end'}}>TC</div>
            <div className="col col-4" style={{textAlign: 'end'}}>TC</div>
            <div className="col col-4" style={{textAlign: 'end'}}>TC</div>
          </div>   
          )
        }
        {
          accountDisplayTypeSelected === 2 && (
            <div 
              style={{display: "flex",alignItems: "top", justifyContent: "center", width: '100%', gap: '10px', outline:'none'}} 
              className='mt-3 row'>
              <CurrencyInput 
                label={"Aggregate Exposure"} 
                defaultValue={aggregateExposureValue} 
                onChange={(value, error) => this.handleAggregateExposureValue({ value, error })}
              />
            </div>
          )
        }
        {discreteAccountsGrid}
      </Fragment>
    );
  }

  closeModal = () => {
    this.setState({
      accountDisplayTypeSelected: null,
      showModalDA: false,
      showEditConfiguration: false,
    });
  }

  cancelClick = () => {
    const { formSelected, discreetAccountByForm } = this.state;

    this.closeModal();

    const updatedDiscreetAccountByForm = discreetAccountByForm.map(form => {
      if (form.formId === formSelected) {
        return {
          ...form,
          data: form.data.map(discreteAccount => ({
            ...discreteAccount,
            selected: discreteAccount.oldSelected ? true : false,
          })),
        };
      } else {
        return form;
      }
    });

    this.setState({
      discreetAccountByForm: updatedDiscreetAccountByForm,
    });
  }

  saveClick = () => {
    const {
      accountDisplayTypeSelected,
      discreetAccountByForm,
      formSelected,
    } = this.state;

    let form = discreetAccountByForm.find(x => x.formId == formSelected);
    
    if(form.aggregateExposureValue <= 0 && accountDisplayTypeSelected == 2){
      showErrorAlert('Error', 'The value of Aggregate Exposure must be greater than 0');
    }else {
      this.setState({ loadingDiscreteAccounts: true });
      this.props.actions.fetchSaveFormByDiscreetAccount({ ...form, accountDisplayTypeId: accountDisplayTypeSelected }, (error, response) => {
        this.setState({ loadingDiscreteAccounts: false });
  
        if (error) {
          this.cancelClick();
          showErrorAlert('Error', 'There was an error setting the discrete accounts');
        } else {
          this.setState({ loadingDiscreteAccounts: false });
  
          const modifiedDiscreetAccountByForm = discreetAccountByForm.map(discreetAccountsForm => {
            if (discreetAccountsForm.formId === form.formId) {
              return {
                ...discreetAccountsForm,
                accountDisplayTypeId: response.accountDisplayTypeId,
                data: discreetAccountsForm.data.map(discreetAccount => {
                  return { ...discreetAccount, oldSelected: !!discreetAccount.selected, tcSelected: !!discreetAccount.tcSelected }
                }),
              };
            }
  
            return discreetAccountsForm;
          });
  
          this.setState({ discreetAccountByForm: modifiedDiscreetAccountByForm });
          this.props.actions.updateDiscreteAccountsProps({ ...form, accountDisplayTypeId: accountDisplayTypeSelected });
          this.closeModal();
        }
      });
    }

  }

  showHideScorecardsFields = (form) => {
    this.setState({
      showHideScorecardsFieldsModal: true,
      formSelected: form.id,
    });
  }

  handleHiddenScorecardFieldsSave = (form) => {
    this.props.actions.fetchSaveFormHiddenScorecardField(form, () => {
      this.onHideScorecardsFieldsModal();
    });
  }

  onHideScorecardsFieldsModal = () => {
    this.setState({ showHideScorecardsFieldsModal: false, formSelected: null })
  }

  goToPreview = (e, form) => {
    e.preventDefault();
    this.setState({ formIOPreviewId: undefined });
    if (form.formId) {  
      this.setState({ redirectToPreviewer: true, formIOPreviewId: form.formId });
      return;
    }
    this.setState({redirectToPreviewer:true,formPreviewId:form.id});
  }

  archivedForm = async(e, form) => {
    e.preventDefault();
    if(form.isDefault) {
      showErrorAlert('Error', 'Default form can not archived');
      return;
    }
    const allFormList = await this.props.actions.fetchAllForm({
      hiringClientId: this.props.match.params.hcId,
      orderBy: 'name',
      orderDirection: 'ASC',
      justFormData: true,
    });
    this.setState({ allFormList: allFormList })
    Swal({
      showConfirmButton: true,
      showCancelButton: true,
      showCloseButton: true,
      title:"Archive Form Template",
      text:`Are you sure you want to archive form Template # ${form.name}?`,
      confirmButtonText: 'YES',
      cancelButtonText: 'CANCEL',
    })
      .then((result) => {
        if (result.value) {
          if (!form.archived) {
            this.setState({
              archiveForm: form,
              showSelectDefaultForm: true
            })
          } else {
            this.setState({
              archiveForm: form,
              showSelectDefaultForm: false
            },()=>{
              this.saveDefaultFormOptionChange();
            })
           
          }
          
          
        }
      })
      .catch(() => null);
  }
  saveDefaultFormOptionChange = () => {
    const archivedForm = this.state.archiveForm;
    let formReplacementId;
    if (this.state.formReplacementId) {
      formReplacementId = this.state.formReplacementId.value;
    }
   
   
    this.props.formActions.archivedForm(archivedForm, formReplacementId, () => {
      this.props.actions.fetchForms({
        hiringClientId: this.props.match.params.hcId,
        orderBy: 'name',
        orderDirection: 'ASC',
        justFormData: true,
        pageSize: 10,
        pageNumber: 1,
      });

    })
    this.closeShowSelectDefaultForm()
  } 
  handleDefaultFormOptionChange(formReplacement) {
    this.setState({ formReplacementId: formReplacement });
  }
  renderDefaultFormSelection() {
    if (this.state.showSelectDefaultForm) {

      const { allFormList } = this.state;
      const filterUnArchivedForms = allFormList.filter(f => !f.archived);
      const selectedForm = this.state.archiveForm;
      const filterCurrentArchivedForm = filterUnArchivedForms.filter(f => f.id !== selectedForm.id)
      const formIdOptions = filterCurrentArchivedForm.map(f => {
        return {
          value: f.id,
          label: f.name
        }
      });

      return (
        <div className="statusContainer">
          <Modal
            show={this.state.showSelectDefaultForm}
            onHide={this.closeShowSelectDefaultForm.bind(this)}
            size="lg"
            centered>
            <form onSubmit={(handleSubmit) => { }} className="list-view-filter-form">
              <div className="sc-financial-info-copy-submission-modal-content container-fluid filter-fields">
                <div className="sc-financial-info-copy-submission-modal-container" >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12 col-sm-12 step-col-right">
                        <div className="register-field-wrapper">
                          <label htmlFor="formId" className="required">
                            {"Select a form which will be used for this form replacement"}:
                          </label>

                          <div className="select-wrapper">
                            <Select
                              value={this.state.formReplacementId}
                              options={formIdOptions}
                              placeholder={'Choose a Replacement Form'}
                              onChange={this.handleDefaultFormOptionChange.bind(this)}
                              className={'invalid'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sc-financial-info-copy-submission-modal-buttons-container text-right m-2">
                  <button className="bg-sky-blue-gradient bn m-1" type="button" onClick={this.closeShowSelectDefaultForm.bind(this)}>
                    {'Close'}
                  </button>

                  <button className="bg-sky-blue-gradient bn action-button m-1" type="submit" onClick={this.saveDefaultFormOptionChange}>
                    Save
                  </button>
                </div>
              </div>
            </form>
          </Modal>
        </div>
      )
    } else {
      return null
    }
  }

  closeShowSelectDefaultForm() {
    this.setState({ archivedForm: null, showSelectDefaultForm: false, formReplacementId: null });
  }
  render() {
    let {
      // buttonFormSubmissions,
      // buttonEditForm,
      tableHeaderName,
      tableHeaderId,
      tableHeaderCreator,
      buttonOptions,
      // buttonSendForm,
      buttonAddForm,
      tableHeaderIsDefault
    } = this.props.local.strings.formList;
    
    if (this.state.redirectToPreviewer) {
      if (this.state.formIOPreviewId ) {
        return <Redirect push to={`/formio/forms/${this.state.formIOPreviewId}/pdf`} />;
      }
      if (this.state.formPreviewId) {
        return <Redirect push to={`/forms/preview/${this.state.formPreviewId}`} />;
      }
    }
    
    const formsTableMetadata = {
      fields: [
        "formId",
        'name',
        'creatorFullName',
        'isDefault',
        'view',
        'options',
        'assignAccount',
        'archived'
      ],
      header: {
        name: tableHeaderName,
        formId :tableHeaderId,
        creatorFullName: tableHeaderCreator,
        isDefault: tableHeaderIsDefault,
        view: '',
        options: '',
        assignAccount: '',
        archived:'',
      }
    };

    const canCreateForms = Utils.canPerformFunction('create a form including conditional fields/logic', this.props.profile.rolesFunctionsPermissions);
    const canSeeFormOptions = Utils.canPerformFunction('see and edit form options', this.props.profile.rolesFunctionsPermissions);
    const canAssignFinancialAccountsToForm = Utils.canPerformFunction('assign financial accounts to form', this.props.profile.rolesFunctionsPermissions);

    const isPQAdminUser = 
      this.props.profile.Role.Id === 1 && 
      this.props.profile.Role.System === 'pq';

    const formsTableBody = this.props.forms.list.map((form, idx) => {
      const { name, id, creator } = form;

      return {
        name,
        formId : id,
        creatorFullName: creator,
        isDefault: isPQAdminUser && (
          <label>
            <Toggle
              checked={!!form.isDefault}
              icons={false}
              onChange={(_) => this.handleDefaultForm(form)}
            />
          </label>
        ),
        view: <a  onClick={(e) => this.goToPreview(e,form)} className="cell-table-link icon-quick_view link-ada-color">View</a>,
        options: canSeeFormOptions && (
          <a
            className="cell-table-link icon-edit link-ada-color"
            onMouseEnter={(e) => { this.handleMouseEnter(e) }}
            onMouseLeave={(e) => { this.handleMouseLeave(e) }}
          >
            {buttonOptions}

            <FormOptions
              idx={idx}
              editForm={() => { this.onEditForm(form) }}
              hideScorecardsFields={() => this.showHideScorecardsFields(form)}
            />
          </a>
        ),
        assignAccount: canAssignFinancialAccountsToForm && (this.renderLinkAssingDiscreetAccounts(form)),
        archived: isPQAdminUser && (<a
          onClick={async (e) => {
            if (!form.archived) {
              await this.archivedForm(e, form)
            }
          }} className="cell-table-link disabled link-ada-color">
          {
            form.archived ? "" : "archive"
          }
        </a>),

      };
    });

    const formsTableData = {
      fields: formsTableMetadata.fields,
      header: formsTableMetadata.header,
      body: formsTableBody
    };

    let { totalAmountOfForms, formsPerPage, fetchingForms } = this.props.forms;
    const paginationSettings = {
      total: totalAmountOfForms,
      itemsPerPage: formsPerPage,
      setPageHandler: this.setPageFilter,
      currentPageNumber: this.state.filter.pageNumber,
    };

    const colsWidth = [
      '30%',
      '30%',
      '40%',
    ];

    const selectedForm = this.props.forms.list.find(form => form.id === this.state.formSelected)

    return (
      <div className="list-view admin-view-body hc-profile-forms-container">
        {/* Hide scorecard's fields modal */}
        <HideScorecardsFieldsModal
          show={this.state.showHideScorecardsFieldsModal}
          onHide={this.onHideScorecardsFieldsModal}
          scorecardsFields={this.props.forms.scorecardsFields}
          selectedFields={selectedForm && selectedForm.scorecardHiddenFields}
          selectedFormId={selectedForm && selectedForm.id}
          loading={this.props.forms.fetchingHiddenScorecardFields}
          handleHiddenScorecardFieldsSave={this.handleHiddenScorecardFieldsSave}
        />

        {/* Discrete accounts modal */}
        {/* TODO: move this modal to modals folder */}
        <Modal
          show={this.state.showModalDA}
          onHide={this.cancelClick}
          className="add-item-modal add-hc hc-profile-forms-modal"
        >
          <Modal.Body className="add-item-modal-body">
            <div className="newhc-form wiz-wrapper">
              <header style={headerStyle}>
                <div className='container modal-header-container'>
                  <h2 className="modal-title">
                        Assign Discrete Accounts
                  </h2>
                  <button disabled={this.state.configLoader || this.state.loadingDiscreteAccounts} style={headerButtonStyle} type='button' onClick={this.showEditConfigurationModal}>
                    {
                      this.state.configLoader && <SpinnerLoader/>
                    }
                    Edit Configurations
                  </button>
                </div>
              </header>
              <div className="steps-bodies add-item-view">
                <div className="container">
                  {this.state.formSelected && this.renderDiscreetAccounts()}
                </div>
              </div>
            </div>

            <div className="container">
              <div className='row mb-2 mt-3'>
                {this.state.loadingDiscreteAccounts ? (
                  <div className='col mb-2'>
                    <div className="spinner-wrapper">
                      <div className="spinner" />
                    </div>
                  </div>
                ) : (
                  <Fragment>
                    <div className='col'>
                      <button
                        disabled={ this.state.configLoader }
                        className="float-right bn bn-small bg-green-dark-gradient create-item-bn icon-save"
                        type="button"
                        onClick={() => this.saveClick()}
                      >
                        Save
                      </button>
                    </div>

                    <div className='col'>
                      <button
                        disabled={ this.state.configLoader }
                        type="button"
                        className="bn bn-small bg-green-dark-gradient create-item-bn icon-cancel"
                        onClick={() => this.cancelClick()}
                      >
                        Cancel
                      </button>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showEditConfiguration}
          onHide={this.cancelClick}
          className="add-item-modal add-hc hc-profile-forms-modal"
        >
          <Modal.Body className="add-item-modal-body">
            {/* paste will be there */}
            <div className="newhc-form wiz-wrapper">
              <header style={headerStyle} >
                <div className='container modal-header-container'>
                    <button
                      className="return-icon"
                      type="button"
                      onClick={() => this.setState({
                        showEditConfiguration: false,
                        showModalDA: true
                      })}
                    >
                      <span>Back</span>
                    </button>
                    <h2 className="modal-title">
                      Account Configurations
                    </h2>
                </div>
              </header>
              <div className="steps-bodies add-item-view" style={{ padding: '10px 0', overflowY: 'auto' }}>
                <div className="container configuration-modal-main-container">
                  <div className="configuration-modal-container">
                    {(this.state.showEditConfiguration && this.state.formSelected) && this.renderNewDiscreteAccount()}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {
          this.renderDefaultFormSelection()
        }
        {
          canCreateForms &&
          <section className="list-view-header templates-view-header">
          <div className="row">
            <div className="col-sm-6" />

            <div className="col-sm-6">
              <nav className="list-view-nav">
                <ul>
                  <li>
                    <a
                      className="list-view-nav-link nav-bn icon-add"
                      onClick={this.onAddNewForm}
                    >
                      {buttonAddForm}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </section>
        }
        <PTable
          sorted={true}
          items={formsTableData}
          wrapperState={this.state}
          tableOrderActive={this.state.tableOrderActive}
          clickOnColumnHeader={this.clickOnColumnHeader}
          isFetching={fetchingForms}
          customClass='templates-list'
          pagination={paginationSettings}
          colsConfig={colsWidth}
        />
      </div>
    );
  }
}


const mapStateToProps = (state, ownProps) => {

  return {
    local: state.localization,
    forms: state.forms,
    profile: state.login.profile,
    form: state.form
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(listActions, dispatch),
    formActions: bindActionCreators(formActions, dispatch),
    builderActions: bindActionCreators(builderActions, dispatch),
    previewerActions: bindActionCreators(previewerActions, dispatch),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Forms));
