import React from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  FormHelperText,
  InputLabel,
  FormControl,
  Select,
  Radio
} from "@material-ui/core";

export const renderTextField = ({label, input, meta: {touched, invalid, error}, ...custom}) => (
    <TextField
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      variant={"outlined"}
      fullWidth
      {...input}
      {...custom}
    />
);

export const renderCheckbox = ({input, label}) => (
  <div>
    <FormControlLabel
      control={
        <Checkbox
          checked={input.value ? true : false}
          onChange={input.onChange}
        />
      }
      label={label}
    />
  </div>
);

export const radioButton = ({input, ...rest}) => (
  <FormControl>
    <RadioGroup {...input} {...rest}>
      <FormControlLabel value="female" control={<Radio/>} label="Female"/>
      <FormControlLabel value="male" control={<Radio/>} label="Male"/>
      <FormControlLabel value="other" control={<Radio/>} label="Other"/>
    </RadioGroup>
  </FormControl>
);

export const renderFormHelper = ({touched, error}) => {
  if (!(touched && error)) {
    return
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>
  }
};

export const renderSelectField = ({input,
                              name,
                             label,
                             meta: { touched, error },
                             children,
                             ...custom
                           }) => (
<FormControl variant="outlined" fullWidth error={touched && error}>
  <InputLabel id={`select-outlined-label-${name}`}>{label}</InputLabel>
  <Select
    labelId="select-outlined-label"
    id={`select-outlined-${name}`}
    label={label}
    {...input}
  >
    {children}
  </Select>
  {renderFormHelper({ touched, error })}
</FormControl>

);