import React from 'react';
import { Link } from 'react-router-dom';
import FormView from './FormView';
import * as formsActions from '../../../../actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FormioTableOfContents from '../../tableofcontent'
import Utils from "../../../../../../lib/utils";
class FormNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formId: this.props.match.params.formId,
      locationState: this.props.location.state || {},
      preview: false,
      savedFormId: null
    };
  }

  financialForms(){
    const formProperties = this.props.formState.components
    const userRoleId = this.props.login.profile.RoleID;
    if(formProperties && userRoleId === 6){
      for (const key of Object.keys(formProperties)) {
        let data = (key, formProperties[key]);
        if(data.properties.isFinancialInformation === "true"){
          data.uploadOnly = true
        }
      }
    }
  }
  
  componentDidMount() {
    let decodedData = Utils.decrypt(this.props.match.params.formId)
    decodedData = decodedData ? decodedData.split(',') : [];
    if(decodedData.length === 2) {
      const formId = decodedData[0];
      const savedFormId = decodedData[1]
      this.props.actions.getForm(formId);
      const params = new URLSearchParams(window.location.search);
      const preview = params.get("preview");
      if (preview === "true") {
        this.setState({ preview: true });
      }
      this.setState({formId: formId, savedFormId: savedFormId})
    } else {
      this.props.history.push('/login');
    }
  }

  renderNavbar() {
    return (
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <Link className="nav-link" to="/formio/forms">
            <i className="fa fa-chevron-left"></i>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to={`/formio/forms/${this.state.formId}`}>
            <i className="fa fa-pencil"></i> Enter Data
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            to={`/formio/forms/${this.state.formId}/submission`}
          >
            <i className="fa fa-list-alt"></i> View Data
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            to={`/formio/forms/${this.state.formId}/edit`}
          >
            <i className="fa fa-edit"></i> Edit Form
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            to={`/formio/forms/${this.state.formId}/delete`}
          >
            <i className="fa fa-trash"></i> Delete Form
          </Link>
        </li>
      </ul>
    );
  }
  
  render() {
    this.financialForms()
    return (
      <div>
        <div className="container">
          {!this.state.preview || this.state.locationState.hideNavbar && this.renderNavbar()}
          {this.state.locationState.hideNavbar && <ul className="nav nav-tabs">
            <li className="nav-item">
            <Link className="nav-link"
              onClick={() => {
                this.props.history.goBack()
              }}>
              <i className="fa fa-chevron-left">&nbsp;<span>Back</span></i>
            </Link>
            </li>
          </ul>}
        </div>
        <div className="container-fluid" id="main">
          <div className="clearfix">
            <FormioTableOfContents />
            <div className="col-sm-6 col-md-9 float-right">
              {this.state.savedFormId && (
                <FormView
                  hcName={this.state.locationState.hcName}
                  savedFormId={this.state.savedFormId}
                  formId={this.state.formId}
                  hideSubmit={this.state.preview}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    login: state.login,
    formState: state.formioForms,
    formioForms: state.formioForms
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(formsActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FormNavigation);
