import React from 'react';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import renderField from '../../../../customInputs/renderField';
import renderSelect from '../../../../customInputs/renderSelect';
import Utils from '../../../../../lib/utils';
import _ from 'lodash';
import Swal from 'sweetalert2';

import * as commonActions from '../../../../common/actions';
import * as projectActions from '../../../projects/actions';
import * as actions from './actions';
import validate from './validation';

import './holderSettings.css';
class HolderRegistrationSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {
        registrationCode: props.holderId,
        connectToProject: 0,
        selectedProject: null,
        selectedRequirementSet: null,
      },
      requirementSetList: []
    };
  }

  componentDidMount() {
    const { holderId, projectActions, actions } = this.props;

    projectActions.fetchProjects({
      orderBy: 'name',
      orderDirection: 'ASC',
      holderId: holderId,
      archived: 0
    });

    actions.fetchRegistrationSettings(holderId, (settings) => {
      if (!_.isEmpty(settings)) {
        const { 
          CFRegistrationCode,
          CFSelectedProject,
          CFConnectToProject,
          CFSelectedRequirementSet,
        } = settings;
  
        this.props.dispatch(change('HolderRegistrationSettings', 'CFRegistrationCode', CFRegistrationCode || ''));
        this.props.dispatch(change('HolderRegistrationSettings', 'CFConnectToProject', CFConnectToProject || 0));        
        this.props.dispatch(change('HolderRegistrationSettings', 'CFSelectedProject', CFSelectedProject || ''));
        this.props.dispatch(change('HolderRegistrationSettings', 'CFSelectedRequirementSet', CFSelectedRequirementSet || ''));
                
        if (CFSelectedProject) {
          actions.fetchRequirementSets({ holderId: holderId, projectId: settings.CFSelectedProject, archived: 0, templates: 'master' }, (err, data) => {
            if (!err) {
              this.setState({ requirementSetList: data });
            }
          });
        }     
      }
    });    
    
    const havePermision = this.props.login.rolesAccessPermissions
      .find(x => x.MasterTab === 'holder_settings' && x.SectionTab === 'set_data_entry_rules');
    if (havePermision) {
      this.setState({ havePermision: true });
    }
  }

  onChangeSelectedProject = (e) => {
    const projectId = e.target.value;
    if (projectId) {
      this.fetchRequirementSets(projectId);
    }
  }

  fetchRequirementSets = (projectId) => {
    const { holderId, actions } = this.props;
    actions.fetchRequirementSets({ holderId: holderId, projectId: projectId, archived: 0, templates: 'master' }, (err, data) => {
      if (!err) {
        this.setState({ requirementSetList: data });
      }
    });
  }
  
  renderFormField = (element, idx) => {
    const { type, name, label, ph, options, conditional, show, onChange } = element;
    const fieldType = type || 'text';
    const style = {};
    if (conditional && !show) {
      style.display = 'none';
    }    

    return (
      <div key={idx} className="wiz-field admin-form-field-wrapper" style={style}>
        <label htmlFor={name}>{`${label}:`}</label>
        {
          options?
          <div className="select-wrapper">
            <Field
              name={name}
              component={renderSelect}
              options={options}
              onChange={onChange} />
          </div>
          :
          <Field
            name={name}
            type={fieldType}
            placeholder={ph}
            component={renderField} />
        }
      </div>
    );
  }

  onHandleSubmit = (values) => {
    const { holderId } = this.props;
    const payload = {
      holderId: parseInt(holderId, 10),
      CFRegistrationCode: parseInt(values.CFRegistrationCode, 10),
      CFConnectToProject: parseInt(values.CFConnectToProject, 10),
      CFSelectedProject: (parseInt(values.CFConnectToProject, 10) === 1) ? parseInt(values.CFSelectedProject, 10) : null,
      CFSelectedRequirementSet: (parseInt(values.CFConnectToProject, 10) === 1) ? parseInt(values.CFSelectedRequirementSet, 10) : null,
    };
    this.props.actions.sendRegistrationSettings(payload, (error) => {
      if (!error) {
        Swal({
          type: 'success',
          title: 'Register Settings',
          text: 'Settings were successfully updated.',
          onClose: () => {
            if (!values.CFSelectedProject) {
              this.fetchRequirementSets(values.CFSelectedProject);
            }
          }
        });
      }
    });
  }

  render() {
    const {
      handleSubmit,
      holderId,
      CFConnectToProjectCurrentValue,
      CFSelectedProjectCurrentValue,
    } = this.props;

    const {
      title,
      registrationCodeLabel,
      connectToProjectLabel,
      selectProjectLabel,
      selectRequirementSetLabel,
      saveButton,
    } = this.props.local.strings.holderRegistrationSettings;
    
    const registrationCodeOptions = [{ label: `- Select ${registrationCodeLabel} -`, value: null } , { label: holderId, value: holderId }];
    const connectToProjectOptions = [{ label: 'False', value: 0 }, { label: 'True', value: 1 }];
    const projectOptions = Utils.getOptionsList(`- ${selectProjectLabel} -`, this.props.projects.list, 'name', 'id', 'name');
    const requirementSetOptions = Utils.getOptionsList(`- ${selectRequirementSetLabel} -`, this.state.requirementSetList, 'Name', 'Id', 'Name');

    const fields = [
      {
        label: registrationCodeLabel, name: 'CFRegistrationCode', options: registrationCodeOptions
      },
      {
        label: connectToProjectLabel, name: 'CFConnectToProject', options: connectToProjectOptions
      },
      {
        label: selectProjectLabel, name: 'CFSelectedProject', options: projectOptions, 
        conditional: true, show: (parseInt(CFConnectToProjectCurrentValue, 10) === 1), onChange: (e) => this.onChangeSelectedProject(e)
      },
      {
        label: selectRequirementSetLabel, name: 'CFSelectedRequirementSet', options: requirementSetOptions, 
        conditional: true, show: (parseInt(CFConnectToProjectCurrentValue, 10) === 1) && (CFSelectedProjectCurrentValue !== null)
      }
    ];

    if (this.props.holderRegistrationSettings.fetchRegistrationSettingsError) {
      return (
        <div className="holder-settings mt-3">
          <div className="settings-error">
            {this.props.holderRegistrationSettings.fetchRegistrationSettingsError}
          </div>
        </div>
      );
    }

    return (
      <div className="add-item-view add-entity-form-small">
        <section className="white-section holder-settings">
          <div className="add-item-form-subsection">
            <div className="add-item-header">
              <h1>{title}</h1>
            </div>
            <div className="container-fluid">
              <form onSubmit={handleSubmit(values => this.onHandleSubmit(values))} className="entity-info-form">

              <div className="container-fluid">
                <div className="row">
                  <div className="col-5">
                    {fields.map(this.renderFormField)}
                  </div>
                </div>
              </div>

              {
                this.props.holderRegistrationSettings.postRegistrationSettingsErrors &&
                <div className="error-item-form">
                  { this.props.holderRegistrationSettings.postRegistrationSettingsError }
                </div>
              }

              <div className="add-item-bn">
                <button
                  className="bn bn-small bg-green-dark-gradient create-item-bn icon-save"
                  type="submit">
                  {saveButton}
                </button>
              </div>

              </form>
            </div>  
          </div>
        </section>
      </div>
    );
  }
};


HolderRegistrationSettings = reduxForm({
  form: 'HolderRegistrationSettings',
  validate
})(HolderRegistrationSettings);

const mapStateToProps = (state) => {
  return {
    holderRegistrationSettings: state.holderRegistrationSettings,
    projects: state.holdersProjects,
    local: state.localization,
    common: state.common,
    login: state.login,
    CFConnectToProjectCurrentValue: formValueSelector('HolderRegistrationSettings')(state, 'CFConnectToProject'),
    CFSelectedProjectCurrentValue: formValueSelector('HolderRegistrationSettings')(state, 'CFSelectedProject'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    projectActions: bindActionCreators(projectActions, dispatch), 
    commonActions: bindActionCreators(commonActions, dispatch),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HolderRegistrationSettings));