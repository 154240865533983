import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';

import * as registerActions from '../actions';
import UserInformationForm from './form';
import Utils from '../../../lib/utils';

export const UserInformationTab = (props) => {
  const onSubmit = (values) => {
    const userPayload = {
      firstName: values.firstName,
      lastName: values.lastName,
      titleId: values.title.value,
      phone: Utils.normalizePhoneNumber(values.phone),
      cellPhone: Utils.normalizePhoneNumber(values.cellPhone, ''),
      email: values.email,
      password: values.password
    };
    props.actions.setUserPayload(userPayload);
    props.continueHandler();
  };
  return (
    <React.Fragment>
      <UserInformationForm onSubmit={onSubmit}/>
      <div className="register-modal-error">
        {props.register.errorUserRegister}
      </div>
    </React.Fragment>
  );
};

UserInformationTab.propTypes = {
  local: PropTypes.object.isRequired,
  continueHandler: PropTypes.func.isRequired,
  register: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.shape({
      errorUserRegister: PropTypes.string
    })
  ]),
  actions: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.shape({
      setUserPayload: PropTypes.func.isRequired
    })
  ]).isRequired
};

UserInformationTab.defaultProps = {
  local: {},
  continueHandler: () => {},
  register: {errorUserRegister: null},
  actions: {setUserPayload: () => {}}
};

const mapStateToProps = (state, ownProps) => ({
  register: state.register,
  login: state.login
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(registerActions, dispatch)
});

export const withRedux = connect(mapStateToProps, mapDispatchToProps);

export const connectedToRedux = withRedux(UserInformationTab);

export default withRouter(connectedToRedux);