import React from 'react';
import {Field, reduxForm, change, touch} from 'redux-form';
import {AsyncPaginate} from 'react-select-async-paginate';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import _ from 'lodash';

import Utils from '../../../../lib/utils';
import renderField from '../../../customInputs/renderField';
import validate from './validate';
import * as actions from './loadOptions';

const SelectAsyncOptions = ({input, meta, dispatch, ...props}) => {
  const touchedError = meta.touched && meta.error;
  const name = input.name;
  const baseClassName = `${props.className || ''} select-${name}`;
  const errorClassName = `${touchedError ? `${baseClassName}-error` : ''}`;

  return (
    <React.Fragment>
      <AsyncPaginate
        {...input}
        {...props}
        openMenuOnClick={true}
        closeMenuOnScroll={true}
        cacheOptions={true}
        pageSize={10}
        additional={{ page: 1 }}
        classNamePrefix="vk"
        className={`${baseClassName} ${errorClassName}`}
        menuPlacement="auto"
        onFocus={(_) => {}}
        onBlur={() => meta.dispatch(touch('CompanyProfileForm', name))}
        onChange={(newValue) => input.onChange(newValue)}
        captureMenuScroll={true}
        closeMenuOnSelect={true}
        debounceTimeout={300}
        loadOptionsOnMenuOpen={true}
      />
      {touchedError && (
        <span className="select-error-message field-validation-message">
          {meta.error}
        </span>
      )}
    </React.Fragment>
  );
};

const CompanyProfileForm = (props) => {
  const {
    companyNameLabel,
    companyNamePlaceholder,
    tradeLabel,
    secondaryTradeLabel,
    tertiaryTradeLabel,
    quaternaryTradeLabel,
    quinaryTradeLabel,
    addressLabel,
    addressPlaceholder,
    cityLabel,
    cityPlaceholder,
    stateLabel,
    provinceLabel,
    statePlaceholder,
    zipCodeLabel,
    zipCodePlaceholder,
    countryLabel,
    taxIdLabel,
    gstLabel,
    taxIdPlaceholder,
    gstPlaceholder,
    mustAgree,
    userAgreementLinkText,
    before,
    agreeLabel,
    submitText,
    dropdownPlaceholder,
    dropdownNoOptionsMessage,
    dropdownLoadingMessage,
    companyNameSlug
  } = props.local.strings.register.companyProfile;

  const normalizeTaxId = (value, previousValue, allValues) => {
    if (!value) {
      return value;
    } else if (_.get(allValues, 'country.value', 0) === 1) {
      const removedDashes = value.replace(/-/gim, '').trim();
      if (removedDashes.length <= 2) {
        return removedDashes;
      } else {
        return `${removedDashes.slice(0, 2)}-${removedDashes.slice(2)}`;
      }
    } else {
      return value.trim();
    }
  };

  const getCountrySelected = (props) => {
    const values = _.get(props, 'currentForm.CompanyProfileForm.values');
    const value = +_.get(values, 'country.value', 0);
    return value;
  };
  const countrySelected = getCountrySelected(props) || 1
  const resetStateValue = (value) => {
    if(value.value !== countrySelected) {
      props.dispatch(change('CompanyProfileForm', 'state', ''));
    }
    props.dispatch(change('CompanyProfileForm', 'taxId', ''));
    document.querySelector(`[name="state"]`).focus();
  };

  const getButtonProps = (props) => {
    const attrs = {
      className: 'bn bg-blue-gradient',
      disabled: false,
      type: 'submit'
    };
    
    if (props.asyncValidating || props.register.processingEndpoint) {
      attrs.className += ' wiz-continue-btn-disabled';
      attrs.disabled = true;
    }

    return attrs;
  };

  const getButtonTitle = (props) => {
    const suffix = props.register.processingEndpoint ? '...' : '';
    return `${submitText}${suffix}`;
  };

  const onSubmit = (event) => {
    event.preventDefault();

    props.dispatch(touch(
      'CompanyProfileForm',
      'companyName',
      'trade',
      'secondarytrade',
      'tertiarytrade',
      'quaternaryTrade',
      'quinaryTrade',
      'country',
      'state',
      'city',
      'address',
      'zipCode',
      'taxId',
      'agree'
    ));

    if (props.valid) {
      props.onSubmit(props.currentForm.CompanyProfileForm.values);
    }
  };


  return (
    <form onSubmit={onSubmit} role="form">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-sm-12 step-col-left">
            <div className="register-field-wrapper">
              <label htmlFor="companyName" className="required">
                {companyNameLabel}
              </label>
              <Field
                name="companyName"
                type="text"
                placeholder={companyNamePlaceholder}
                component={renderField}
              />
              <p>{companyNameSlug}</p>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 step-col-right">
            <div className="wiz-field register-field-wrapper">
              <label htmlFor="trade" className="required">
                {tradeLabel}
              </label>
              <div className="register-select-wrapper">
                <Field
                  name="trade"
                  component={SelectAsyncOptions}
                  placeholder={dropdownPlaceholder}
                  noOptionsMessage={() => dropdownNoOptionsMessage}
                  loadingMessage={() => dropdownLoadingMessage}
                  loadOptions={props.actions.loadTrades(props)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 step-col-left">
            <div className="wiz-field register-field-wrapper">
              <label htmlFor="secondarytrade">
                {secondaryTradeLabel}
              </label>
              <Field
                name="secondarytrade"
                component={SelectAsyncOptions}
                placeholder={dropdownPlaceholder}
                noOptionsMessage={() => dropdownNoOptionsMessage}
                loadingMessage={() => dropdownLoadingMessage}
                loadOptions={props.actions.loadTrades(props)}
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12 step-col-right">
            <div className="wiz-field register-field-wrapper">
              <label htmlFor="tertiarytrade">
                {tertiaryTradeLabel}
              </label>
              <Field
                name="tertiarytrade"
                component={SelectAsyncOptions}
                placeholder={dropdownPlaceholder}
                noOptionsMessage={() => dropdownNoOptionsMessage}
                loadingMessage={() => dropdownLoadingMessage}
                loadOptions={props.actions.loadTrades(props)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 step-col-left">
            <div className="wiz-field register-field-wrapper">
              <label htmlFor="quaternaryTrade">
                {quaternaryTradeLabel}
              </label>
              <Field
                name="quaternaryTrade"
                component={SelectAsyncOptions}
                placeholder={dropdownPlaceholder}
                noOptionsMessage={() => dropdownNoOptionsMessage}
                loadingMessage={() => dropdownLoadingMessage}
                loadOptions={props.actions.loadTrades(props)}
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12 step-col-right">
            <div className="wiz-field register-field-wrapper">
              <label htmlFor="quinaryTrade">
                {quinaryTradeLabel}
              </label>
              <Field
                name="quinaryTrade"
                component={SelectAsyncOptions}
                placeholder={dropdownPlaceholder}
                noOptionsMessage={() => dropdownNoOptionsMessage}
                loadingMessage={() => dropdownLoadingMessage}
                loadOptions={props.actions.loadTrades(props)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 step-col-left">
            <div className="register-field-wrapper">
              <label htmlFor="state" className="required">
                {stateLabel}:
              </label>
                <div className="register-select-wrapper">
                  <Field
                    name="state"
                    component={SelectAsyncOptions}
                    placeholder={dropdownPlaceholder}
                    noOptionsMessage={() => dropdownNoOptionsMessage}
                    loadingMessage={() => dropdownLoadingMessage}
                    cacheUniqs={[countrySelected]}
                    loadOptions={props.actions.loadStates(
                      props, 
                      countrySelected
                    )}
                  />
                </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 step-col-right">
            <div className="register-field-wrapper">
              <label htmlFor="country" className="required">
                {countryLabel}
              </label>
              <div className="register-select-wrapper">
                <Field
                  name="country"
                  onChange={(value)=>resetStateValue(value)}
                  blurInputOnSelect={true}
                  openMenuOnFocus={true}
                  component={SelectAsyncOptions}
                  placeholder={dropdownPlaceholder}
                  noOptionsMessage={() => dropdownNoOptionsMessage}
                  loadingMessage={() => dropdownLoadingMessage}
                  loadOptions={props.actions.loadCountries(props)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 step-col-left">
            <div className="register-field-wrapper">
              <label htmlFor="city" className="required">
                {cityLabel}
              </label>
              <Field
                name="city"
                type="text"
                component={renderField}
                placeholder={cityPlaceholder}
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12 step-col-right">
            <div className="register-field-wrapper">
              <label htmlFor="address" className="required">
                {addressLabel}
              </label>
              <Field
                name="address"
                type="text"
                component={renderField}
                placeholder={addressPlaceholder}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 step-col-left">
            <div className="register-field-wrapper">
              <label htmlFor="zipCode" className="required">
                {zipCodeLabel}
              </label>
              <Field
                name="zipCode"
                type="text"
                component={renderField}
                placeholder={zipCodePlaceholder}
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12 step-col-right">
              <div className="register-field-wrapper">
                <label htmlFor="taxId" className="required">
                  {countrySelected === 34 ? gstLabel : taxIdLabel}
                </label>
                <Field
                  name="taxId"
                  type="text"
                  required={true}
                  component={renderField}
                  placeholder={countrySelected === 1 ? taxIdPlaceholder : countrySelected === 34 ? gstPlaceholder : ''}
                  normalize={normalizeTaxId}
                />
              </div>
            </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 step-col-left">
            <div className="register-field-wrapper agreement-container">
              <p className="agreement-label">
                {`${mustAgree} `}
                <a 
                  href="https://asset.prequalusa.com/general/prequal-eula-2015.pdf" 
                  target="_blank" 
                  rel="noopener noreferrer">
                  {userAgreementLinkText}
                </a>
                {` ${before}`}
              </p>
              <label htmlFor="agree">
                <Field
                  id="agree"
                  name="agree"
                  type="checkbox"
                  component={renderField}
                />
                <span className="check"></span>
                <span className="label">
                  {agreeLabel}
                </span>
              </label>
            </div>
          </div>
        </div>
        <div className="step-button">
          <button {...getButtonProps(props)}>
            {getButtonTitle(props)}
          </button>
        </div>
      </div>
    </form>
  );
}

const withReduxForm = reduxForm({
  form: 'CompanyProfileForm',
  validate,
  touchOnBlur: true,
  touchOnChange: true,
  enableReinitialize: true
});

const mapStateToProps = (state, ownProps) => {
  const {getValuesOnlyIfNotEmpty: onlyValues, toOption} = Utils;
  const fromState = onlyValues(state, 'register.companyPayload');
  const fromProps = onlyValues(ownProps, 'companyPayload');
  const payload = fromState || fromProps || {};
  const hiringClientId = state.register.hiringClientId || 
    ownProps.hiringClientId;

  return {
    register: state.register,
    local: state.localization,
    currentForm: state.form,
    hiringClientId: hiringClientId ? +hiringClientId : null,
    initialValues: {
      companyName: payload.companyName ? Utils.displayQuotes(payload.companyName) : '',
      trade: toOption(payload.tradeId, payload.tradeName),
      secondarytrade: toOption(payload.secTradeId, payload.secTradeName),
      tertiarytrade: toOption(payload.terTradeId, payload.terTradeName),
      quaternaryTrade: toOption(payload.quatTradeId, payload.quatTradeName),
      quinaryTrade: toOption(payload.quinTradeId, payload.quinTradeName),
      country: {value:1,label:"United States"},
      city: payload.city || '',
      address: payload.address || '',
      zipCode: payload.zipCode || '',
      taxId: payload.taxId || '',
    	agree: payload.agree || false
    }
  };
};

const mapDispatchToProps = (dispatch) => ({actions});

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(withRedux(withReduxForm(CompanyProfileForm)));