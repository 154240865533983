import * as types from './types';
import { Formio } from '@formio/react'
import { updateSubmissionApi, saveSubmissionApi } from '../api';
import Api from '../../../lib/api';

export const getForm = (id, name) => {
  return (dispatch, getState) => {
    const formPath = `/${id ? `form/${id}` : `${name}`}`;
    const path = `${Formio.getProjectUrl()}${formPath}`;
    const formio = new Formio(path);

    dispatch(requestForm(id, path));

    formio.loadForm()
      .then((result) => {
        dispatch(receiveForm(result));
      })
      .catch((result) => {
        dispatch(failForm(result));
      });
  }
};

export const saveForm = (form, done = () => {}) => {
  return (dispatch, getState) => {
    dispatch(sendForm(form));
    const id = form._id;
    const path = `${Formio.getProjectUrl()}/form${id ? `/${id}` : ''}`;
    const formio = new Formio(path);

    formio.saveForm(form)
      .then((result) => {
        const url = `${Formio.getProjectUrl()}/form/${result._id}`;
        dispatch(receiveForm(result, url));
        done(null, result);
      })
      .catch((result) => {
        dispatch(failForm(result));
        done(result);
      });
  }
};

const deleteTemplateAndSubmissions = async (id, name, done) => {
  const formPath = `/${id ? `form/${id}` : `${name}`}`;
  const path = `${Formio.getProjectUrl()}${formPath}`;
  const formio = new Formio(path);
  const url = `${Formio.getProjectUrl()}${formPath}/submission}`;
  const formioSubmissions = new Formio(url);

  await formioSubmissions.loadSubmissions().then(async (result) => {
    if (result && result.serverCount > 0) {
      const deleteSubmissionPromises = [];
      result.forEach((sub) => {
        const submissionId = sub._id;
        const submissionUrl = `${Formio.getProjectUrl()}${formPath}/submission/${submissionId}`;
        const formioSubmission = new Formio(submissionUrl);
        deleteSubmissionPromises.push(formioSubmission.deleteSubmission());
      })
      Promise.all(deleteSubmissionPromises).then((_)=> {});
    }
  }).then(async () => {
    formio.deleteForm()
      .then(() => {
        done(null, true);
      })
      .catch((result) => {
        done(result, null);
      });
  })

}

export const deleteForm = (id, name, done = () => { }) => {
  return async (dispatch, getState) => {
    const token = getState().login.authToken; 
    return Api.delete('/forms/formio', {id}, token)
      .then(async response => {
        const { success } = response.data;
        if (success) {
          await deleteTemplateAndSubmissions(id, name, () => {
            done(null, true)
          })
        } else {
          const { data } = response.data;
          let errorMsg;
          if (data.errorCode === 10001) {
            errorMsg = "Can not be deleted because form already submitted";
          }
          else {
            errorMsg = data.errorCode;
          }
          if (errorMsg) done(errorMsg, null);
        }
      })
      .catch(error => {
        if (done) done(error, null);
      });
    }
  };

const getRequestParams = (limit, query, sort, params, select, page) => {
  const requestParams = { ...query, ...params };

  // Ten is the default so if set to 10, don't send.
  if (limit !== 10) {
    requestParams.limit = limit;
  }
  else {
    delete requestParams.limit;
  }

  if (page !== 1) {
    requestParams.skip = (page - 1) * limit;
  }
  else {
    delete requestParams.skip;
  }

  if (select) {
    requestParams.select = select;
  }
  else {
    delete requestParams.select;
  }

  if (sort) {
    requestParams.sort = sort;
  }
  else {
    delete requestParams.sort;
  }

  return requestParams;
}

export const nonFinancialForms = ({ limit, query, select, sort }, page = 1, params = {}, done = () => {}) => {
  return (dispatch, getState) => {
    dispatch(requestForms(page, params));
    const formio = new Formio(`${Formio.getProjectUrl()}/form`);
    const requestParams = getRequestParams(limit, query, sort, params, select, page);
    formio.loadForms({ params: requestParams })
      .then((result) => {
        dispatch(receiveForms(result, limit));
      //console.log(result)
        done(null, result);
      })
      .catch((error) => {
        dispatch(failForms(error));
        done(error);
      });
  }
};


// For Submission Context Actions
/**
 * Get Submission for a form using submissions ID.
 *
 * @param   {string}  id        Submission ID
 * @param   {string}  formId    Form ID
 * @param   {string}  formName  Name of form. Either form Id or name should be provided.
 *
 * @return  {object}            Object containing submission data.
 */
export const getSubmission = (id, formId, formName) => {
  return (dispatch, getState) => {
    const formPath = `/${formId ? `form/${formId}` : `${formName}`}`;
    const url = `${Formio.getProjectUrl()}${formPath}/submission${id ? `/${id}` : ''}`;
    const formio = new Formio(url);

    dispatch(requestSubmission(id, formId, url));

    formio.loadSubmission()
      .then((result) => {
        if(result && result.data && result.data.access){
          dispatch(receiveSubmission(result.data));
        }else{
          dispatch(receiveSubmission(result));
        }
      })
      .catch((error) => {
        dispatch(failSubmission(error));
      });
  }
};

export const saveSubmission = (data, formId, formName, done = () => { }, {
  submissionId,
  draft
} = {}) => {
  return (dispatch, getState) => {
    const projectUrl = Formio.getProjectUrl();
    const formPath = `/${formId ? `form/${formId}` : `${formName}`}`;
    // dispatch(sendSubmission(data));
    if (submissionId) {
      return updateSubmissionApi(submissionId, formId, data, { draft })
        .then((submissionResult) => {
          // const url = `${projectUrl}${formPath}/submission/${submissionResult.data._id || submissionResult._id}`;
          // dispatch(receiveSubmission(submissionResult.data, url));
          Formio.clearCache()
          done(null, submissionResult.data);
        })
        .catch((error) => {
          dispatch(failSubmission(error));
          done(error, null);
        });
      }
      
      dispatch(sendSubmission(data));

    return saveSubmissionApi(formId, data, { draft })
      .then((result) => {
        const url = `${projectUrl}${formPath}/submission/${result._id || result.data._id}`;
        dispatch(receiveSubmission(result.data, url));
        dispatch(resetSubmissions('submission'));
        done(null,result.data);
      })
      .catch((error) => {
        dispatch(failSubmission(error));
        done(error, null);
      });
  }
};

export const deleteSubmission = (id, formId, formName, done = () => { }) => {
  return (dispatch, getState) => {
    const formPath = `/${formId ? `form/${formId}` : `${formName}`}`;
    const formio = new Formio(`${Formio.getProjectUrl()}${formPath}/submission/${id}`);

    return formio.deleteSubmission()
      .then(() => {
        dispatch(resetSubmission());
        done();
      })
      .catch((error) => {
        dispatch(failSubmission(error));
        done(error);
      });
  }
};

export const clearFormError = () => ({
  type: types.SET_FORM_CLEAR_ERROR,
});

export const requestForm = (id, url) => ({
  type: types.SET_FORM_REQUEST,
  id,
  url,
});

export const receiveForm = (form, url) => ({
  type: types.SET_FORM_SUCCESS,
  form,
  url,
});

export const failForm = (err) => ({
  type: types.SET_FORM_FAILURE,
  error: err,
});

export const resetForm = () => ({
  type: types.SET_FORM_RESET,
});

export const sendForm = (form) => ({
  type: types.SET_FORM_SAVE,
  form,
});

export const resetForms = () => ({
  type: types.SET_FORMS_RESET,
});

export const requestForms = (page, params) => ({
  type: types.SET_FORMS_REQUEST,
  page,
  params,
});

export const receiveForms = (forms, limit) => ({
  type: types.SET_FORMS_SUCCESS,
  forms,
  limit,
});

export const failForms = (error) => ({
  type: types.SET_FORMS_FAILURE,
  error,
});

// Form Submissions

export const clearSubmissionError = () => ({
type: types.SET_SUBMISSION_CLEAR_ERROR,
});

export const requestSubmission = (id, formId,  url) => ({
type: types.SET_SUBMISSION_REQUEST,
id,
formId,
url,
});

export const sendSubmission = (data) => ({
type: types.SET_SUBMISSION_SAVE,
});

export const receiveSubmission = (submission, url) => ({
type: types.SET_SUBMISSION_SUCCESS,
submission,
url,
});

export const failSubmission = (error) => ({
type: types.SET_SUBMISSION_FAILURE,
error,
});

export const resetSubmission = () => ({
type: types.SET_SUBMISSION_RESET,
});

export const resetSubmissions = () => ({
type: types.SET_SUBMISSIONS_RESET,
});

export const resetFormIoSubmission = () => {
  return (dispatch, getState) => {
    dispatch(resetForms())
    dispatch(resetSubmission())
  }
}