export const SET_COVERAGE_TYPES_ERROR = 'SET_COVERAGE_TYPES_ERROR';
export const SET_COVERAGE_TYPES_LIST = 'SET_COVERAGE_TYPES_LIST';
export const SET_TOTAL_AMOUNT_OF_COVERAGE_TYPES = 'SET_TOTAL_AMOUNT_OF_COVERAGE_TYPES';
export const SET_FETCHING_COVERAGE_TYPES = 'SET_FETCHING_COVERAGE_TYPES';
export const SET_COVERAGE_TYPES_SHOW_MODAL = 'SET_COVERAGE_TYPES_SHOW_MODAL';
export const SET_COVERAGE_TYPES_ADD_ERROR = 'SET_COVERAGE_TYPES_ADD_ERROR';
export const SET_COVERAGE_TYPES_ADD_SUCCESS = 'SET_COVERAGE_TYPES_ADD_SUCCESS';
export const SET_COVERAGE_TYPES_ADD_FETCHING = 'SET_COVERAGE_TYPES_ADD_FETCHING';

export const SET_TYPE_AHEAD_FETCHING_COVERAGE_TYPE = 'SET_TYPE_AHEAD_FETCHING_COVERAGE_TYPE';
export const SET_TYPE_AHEAD_SUCCESS_COVERAGE_TYPE = 'SET_TYPE_AHEAD_SUCCESS_COVERAGE_TYPE';
export const SET_TYPE_AHEAD_ERROR_COVERAGE_TYPE = 'SET_TYPE_AHEAD_ERROR_COVERAGE_TYPE';
export const RESET_TYPE_AHEAD_RESULTS_COVERAGE_TYPE = 'RESET_TYPE_AHEAD_RESULTS_COVERAGE_TYPE';