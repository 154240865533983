import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import InsuredInfoTab from './insuredInfoTab';

import * as insuredActions from '../../insureds/actions';
import * as commonActions from '../../../common/actions';
import Utils from '../../../../lib/utils';
import '../addEntityModal.css';
import * as holderActions from "../../holders/actions";

class AddInsuredModal extends Component {
  constructor(props) {
    super(props);

    if(props.common.countries.length <= 0){
      props.commonActions.fetchCountries();
    }

    this.state = {
      oldTaxId: '',
    };
  };

  componentDidMount() {
    const { profile } = this.props;
    if (profile) {
      this.setState({
        oldTaxId: profile.TaxID,
      });
    }
  }

  send = async (values) => {
    const { oldTaxId } = this.state;
    const userId = this.props.login.profile.Id;
    const userEmail = this.props.login.profile.Mail;
    if(values.holderId){
      await this.props.holderActions.fetchHolderChildren(values.holderId.value);
    }
    
    const payload = {
      insuredName: values.insuredName,
      legalName: values.legalName || '',
      taxId: values.taxId || '',
      oldTaxId: oldTaxId || '',
      address1: values.address1,
      address2: values.address2 || '',
      city: values.city,
      stateId: values.stateId && values.stateId.value || '',
      postalCode: values.postalCode,
      countryId: values.countryId && values.countryId.value,
      contactName: values.contactName,
      contactPhone: values.contactPhone || '',
      contactFax: values.contactFax || '',
      contactEmail: values.contactEmail || '',
      insuredDescription: values.insuredDescription || '',
      vendor: values.vendor || '',
      note: values.note || '',
      userId: userId || null,
      userEmail: userEmail || null,
    }
    let childHolderIds = [];
    let childHolderNames = [];
    if(this.props.holders.holderChildren && this.props.holders.holderChildren.length > 0) {
      this.props.holders.holderChildren.forEach((item) => {
        childHolderIds.push(item.Id)
        childHolderNames.push(item.Name)
      })
    }
    if(values.holderId) {
      payload.childHolderIds = childHolderIds;
      payload.childHolderNames = childHolderNames;
      payload.stateName = values.stateId && values.stateId.label || '';
      payload.holderName = (values.holderId) ? values.holderId.label : undefined;
    }
    let formattedPhone;
    if (values.contactPhone) {
      formattedPhone = Utils.normalizePhoneNumber(values.contactPhone);
      /*if(formattedPhone.length === 10){
        formattedPhone = `+1${formattedPhone}`
      }*/
    }
    payload.contactPhone = formattedPhone;
    payload.holderId = (values.holderId) ? values.holderId.value : undefined;
  //console.log('payload', payload);

    if (this.props.profile) {
      payload.insuredId = this.props.profile.Id;
      this.props.actions.sendInsureds(payload, (success) => {
        if (success) {
          this.props.close(false);
        }
      });
    } else {
      this.props.actions.sendInsureds(payload, (success, data) => {
        if (success) {
          this.props.close(true);
          if (this.props.onCreated && data) {
            this.props.onCreated({ insuredId: data.insuredId, insuredName: values.insuredName });
          }
        }
      });
    }

  };

  hideModal = () => {
    const { onHide, close } = this.props;
    if (onHide) onHide();
    else close();
  }

  render() {
    const {
      title
    } = this.props.local.strings.insured.addInsuredModal;

    return (
      <div className="new-entity-form wiz-wrapper">
        <header>
          <h2 className="modal-wiz-title">
            {title}
          </h2>
        </header>

        <div className="steps-bodies add-item-view">
          <div className='step-body add-item-form-subsection active'>
            <InsuredInfoTab
              close={this.hideModal}
              continueHandler={this.send}
              profile={this.props.profile}
            />
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    local: state.localization,
    common: state.common,
    holders: state.holders,
    login: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(insuredActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    holderActions: bindActionCreators(holderActions, dispatch),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddInsuredModal);
