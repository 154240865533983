import * as regex from '../../../../../lib/regex';
import Utils from '../../../../../lib/utils';

//This is the validation function for the form projectInfoTab
const validate = (values, props) => {
  const errors = {};
  const {
    phoneErrorMessage,
    requiredValidation,
    // wrongEmailFormat,
  } = props.local.strings.hiringClients.addHCModal.companyInfoTab;

  const validateFilled = [
    'name', 
    'holderId',
  ];
  validateFilled.forEach(name => {
    if(!values[name]) {
      errors[name] = requiredValidation;
    }
  });

  if (values.CFContactPhone) {
    if (values.CFContactPhone.match(regex.PHONE)) {
      values.CFContactPhone = Utils.formatPhoneNumber(values.CFContactPhone, values.CFContactPhone);
    } else if (Utils.isInternationalPhone(values.CFContactPhone)){
      values.CFContactPhone = Utils.normalizePhoneNumber(values.CFContactPhone)
    } else {
      errors.CFContactPhone = phoneErrorMessage;
    }
  }
  
  if (values.note) {
    if (values.note.length > 5000) {
      errors.note = 'The Note field accepts a maximum of 5000 characters.';
    }
  }

  if (values.description && values.description.length > 5000) {
    errors.description = "Description should not exceed 5000 characters.";
  }
  // if (values.CFProjectEmail) {
  //   if(!values.CFProjectEmail.match(regex.EMAIL)) {
  //     errors.CFProjectEmail = wrongEmailFormat;
  //   }
  // }

  return errors;
};

export default validate;
