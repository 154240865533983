export const SET_DOCUMENT_QUEUE_DEFINITIONS_ERROR = 'SET_DOCUMENT_QUEUE_DEFINITIONS_ERROR';
export const SET_DOCUMENT_QUEUE_DEFINITIONS_LIST = 'SET_DOCUMENT_QUEUE_DEFINITIONS_LIST';
export const SET_TOTAL_AMOUNT_OF_DOCUMENT_QUEUE_DEFINITIONS = 'SET_TOTAL_AMOUNT_OF_DOCUMENT_QUEUE_DEFINITIONS';
export const SET_FETCHING_DOCUMENT_QUEUE_DEFINITIONS = 'SET_FETCHING_DOCUMENT_QUEUE_DEFINITIONS';
export const SET_DOCUMENT_QUEUE_DEFINITIONS_SHOW_MODAL = 'SET_DOCUMENT_QUEUE_DEFINITIONS_SHOW_MODAL';
export const SET_DOCUMENT_QUEUE_DEFINITIONS_ADD_ERROR = 'SET_DOCUMENT_QUEUE_DEFINITIONS_ADD_ERROR';
export const SET_DOCUMENT_QUEUE_DEFINITIONS_ADD_SUCCESS = 'SET_DOCUMENT_QUEUE_DEFINITIONS_ADD_SUCCESS';
export const SET_DOCUMENT_QUEUE_DEFINITIONS_ADD_FETCHING = 'SET_DOCUMENT_QUEUE_DEFINITIONS_ADD_FETCHING';

export const SET_DOCUMENT_QUEUE_USERS_LIST_ERROR = 'SET_DOCUMENT_QUEUE_USERS_LIST_ERROR';
export const SET_DOCUMENT_QUEUE_USERS_LIST = 'SET_DOCUMENT_QUEUE_USERS_LIST';
export const SET_TOTAL_AMOUNT_OF_DOCUMENT_QUEUE_USERS = 'SET_TOTAL_AMOUNT_OF_DOCUMENT_QUEUE_USERS';
export const SET_FETCHING_DOCUMENT_QUEUE_USERS = 'SET_FETCHING_DOCUMENT_QUEUE_USERS';
export const SET_DOCUMENT_QUEUE_USERS_ADD_ERROR = 'SET_DOCUMENT_QUEUE_USERS_ADD_ERROR';
export const SET_DOCUMENT_QUEUE_USERS_ADD_SUCCESS = 'SET_DOCUMENT_QUEUE_USERS_ADD_SUCCESS';
export const SET_DOCUMENT_QUEUE_USERS_ADD_FETCHING = 'SET_DOCUMENT_QUEUE_USERS_ADD_FETCHING';
export const SET_SHOW_DOCUMENT_QUEUE_USERS_MODAL = 'SET_SHOW_DOCUMENT_QUEUE_USERS_MODAL';
export const SET_SHOW_ADD_DOCUMENT_QUEUE_USERS_MODAL = 'SET_SHOW_ADD_DOCUMENT_QUEUE_USERS_MODAL';

export const SET_FETCHING_DOCUMENT_QUEUE_ROLES = 'SET_FETCHING_DOCUMENT_QUEUE_ROLES';
export const SET_DOCUMENT_QUEUE_ROLES_LIST_ERROR = 'SET_DOCUMENT_QUEUE_ROLES_LIST_ERROR';
export const SET_DOCUMENT_QUEUE_ROLES_LIST = 'SET_DOCUMENT_QUEUE_ROLES_LIST';
