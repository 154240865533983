import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as financialActions from '../../actions';
import FinancialInfoCopySubmissionModalForm from './financialInfoCopySubmissionModalForm/financialInfoCopySubmissionModalForm';

import './financialInfoCopySubmissionModal.css';

class FinancialInfoCopySubmissionModal extends Component {
  onSubmit = (data) => {
    const { subcontractorId, hiringClientId } = this.props;

    const payload = {
      ...data,
      subcontractorId,
      hiringClientId,
    };

    this.props.financialActions.copyFinancialSubmission(payload, (response) => {
      if (response) {
        this.props.closeAndRefresh();
      }
    });
  }

  render() {
    const { close, hiringClients } = this.props;

    const { title } = this.props.local.strings.financialInfo.copySubmissionModal;

    const hiringClientsOptions = hiringClients.map(hc => ({ value: hc.hiringClientId, label: hc.name }));

    return (
      <div className="sc-financial-info-copy-submission-modal">
        <header className="sc-financial-info-copy-submission-modal-header">
          {title}
        </header>

        <FinancialInfoCopySubmissionModalForm
          close={close}
          onSubmit={this.onSubmit}
          hiringClientsOptions={hiringClientsOptions}
        />
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    local: state.localization,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    financialActions: bindActionCreators(financialActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinancialInfoCopySubmissionModal);
