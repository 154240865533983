import React from 'react';
import Select from 'react-select';

class renderSelectSearch extends React.Component {  
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this)
  }
  handleSelect(e) {
    this.props.input.onChange(e)
  }
  render = () => {
    const {
      meta: { touched, error },
      input,
      options
    } = this.props;

    return <div>
      <Select
        {...this.props}
        onChange={this.handleSelect}
        value={options.filter((currentOption) => currentOption.value === input.value)[0]}
        styles={{
          control: base => ({
            ...base,
            border: `1px solid ${touched && error ? 'red' : '#BABBBD'}`
          })
        }}
      />
      { 
        touched && error 
          ? <span className="field-validation-message">
              {error}
            </span>
          : null
      }
      </div>
  }  
}

export default renderSelectSearch;