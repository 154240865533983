import React from "react";
import { connect } from "react-redux";
import { reduxForm, Form, Field } from "redux-form";
import { bindActionCreators } from "redux";
import { showAlertMessage } from "../alerts";

import * as docActions from "./actions";
import renderField from "../customInputs/renderField";
import fileInput from "../customInputs/fileInput";
import validate from "./validation";
import FullScreenReCAPTCHA from '../common/recaptcha/full-screen-recaptcha'

import "./addFileModal.css";

const SHOW_RECAPTCHA = process.env.REACT_APP_SHOW_RECAPTCHA === 'true';

class EmailFileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      showReCAPTCHA: SHOW_RECAPTCHA,
    };
  }

  componentDidMount() {
    this.props.docActions.fetchFileInfoFromHash({
      hash: this.props.match.params.hash,
    });
  }

  addFile(e) {
    e.preventDefault();
    const fileInput = document.getElementById("documentFile");
    const descriptionField = document.getElementById("description");
    const formData = new FormData();
    formData.append(
      "subcontractorId",
      this.props.emailFileUpload.docInfo.subcontractorId
    );
    formData.append(
      "hiringClientId",
      this.props.emailFileUpload.docInfo.hiringClientId
    );
    formData.append("description", descriptionField.innerHTML);
    formData.append(
      "payloadId",
      this.props.emailFileUpload.docInfo.subcontractorId
    );
    formData.append("isWorkflowCreated", true);
    formData.append("fileTypeId", 2);
    formData.append("documentFile", fileInput.files[0]);

    this.props.docActions.saveFile(formData, (err) => {
      if (err) {
        showAlertMessage('error', 'File Upload Failed!');
      } else {
        showAlertMessage('success', 'File Uploaded!');
        setTimeout(() => window.close(), 2000);
      }
    });
  }

  onFileInputChange() {
    const fileInput = document.getElementById("documentFile");
    this.setState({ file: fileInput.files[0] });
  }

  onReCAPTCHAVerify = () => {
    this.setState({
      showReCAPTCHA: false
    })
  }

  render() {
    const { title, fileInputLabel, descriptionInputLabel, buttonText } =
      this.props.local.strings.scProfile.files.addModal;
    const { docInfo } = this.props.emailFileUpload;

    if(this.state.showReCAPTCHA) {
      return <FullScreenReCAPTCHA onVerify={this.onReCAPTCHAVerify} /> 
    }

    return (
      <Form className="email-file-upload" onSubmit={this.addFile.bind(this)}>
        <header className="email-file-upload-header">{title}</header>

        <div className="email-file-content container-fluid filter-fields">
          {docInfo && (
            <div>
              <p>Subcontractor: {docInfo.subContractor.Company}</p>
              <p>Hiring Client: {docInfo.hiringClient.name}</p>
              <p>File name: {docInfo.document.fileName}</p>
            </div>
          )}
          <div className="row">
            <div className="col-sm-12 no-padd">
              <div className="upload-file-wrapper">
                <label htmlFor="documentFile" className="file-input">
                  {(this.state.file && this.state.file.name) || fileInputLabel}
                </label>
                <Field
                  name="documentFile"
                  id="documentFile"
                  component={fileInput}
                  onChange={this.onFileInputChange.bind(this)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 no-padd">
              <div className="admin-form-field-wrapper">
                <div className="email-file-label">{descriptionInputLabel}:</div>
                <Field
                  type="textarea"
                  name="description"
                  id="description"
                  component={renderField}
                  className="description-input"
                />
              </div>
            </div>
          </div>

          <div className="wiz-buttons">
            <button
              disabled={this.props.isSubmitting ? "disabled" : null}
              className="wiz-continue-btn bg-sky-blue-gradient bn"
            >
              {buttonText}
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

EmailFileUpload = reduxForm({
  form: "EmailFileUploadForm",
  validate,
})(EmailFileUpload);

const mapStateToProps = (state) => {
  return {
    users: state.users,
    local: state.localization,
    loginProfile: state.login.profile,
    formSubmissions: state.formSubmissions,
    reduxForm: state.form,
    emailFileUpload: state.emailFileUpload,
  };
};

const mapDispatchToProps = (dispatch) => ({
  docActions: bindActionCreators(docActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailFileUpload);
