import * as types from '../actions/types';

export default function filesReducer(state = {
  error: '',
  list: [],
  totalAmountOfFiles: 0,
  filesPerPage: 10,
  fetchingFiles: false,
  downloadingFile: false,
  downloadingMultipleFile: false,
  downloadingMultipleFileError: false,
  viewFileUrl: '',
  savingMultipleFiles: false,
  savingMultipleFilesError: '',
  fileExTemplate: [],
  isFileExpirationWorkflowEnabled:0
}, action) {
  switch(action.type) {
    case types.SET_DOWNLOADING_FILE:
      return Object.assign({}, state, {
        downloadingFile: action.isDownloading
      });
    
    case types.SET_DOWNLOADING_MULTIPLE_FILES:
      return Object.assign({}, state, {
        downloadingMultipleFile: action.isDownloadingMultiple
      });

    case types.SET_FETCHING_FILES:
      return Object.assign({}, state, {
        fetchingFiles: action.isFetching
      });

    case types.SET_PAGE_SIZE:
      return Object.assign({}, state, {
        filesPerPage: action.payload
      });

    case types.SET_FILES:
      return Object.assign({}, state, {
        list: action.files
      });

    case types.SET_CONTRACTS_ERROR:
      return Object.assign({}, state, {
        error: action.error
      });

    case types.SET_ERROR_DOWNLOADING_MULTIPLE_FILES:
      return Object.assign({}, state, {
        downloadingMultipleFileError: action.isDownloadingMultipleError
      });

    case types.SET_TOTAL_AMOUNT_OF_FILES:
      return Object.assign({}, state, {
        totalAmountOfFiles: action.filesLength
      });
    case types.SET_FILE_URL:
      return Object.assign({}, state, {
        viewFileUrl: action.url
      })

    case types.SET_SAVING_MULTIPLE_FILES:
      return { ...state, savingMultipleFiles: true, savingMultipleFilesError: '' };
    case types.SET_SAVING_MULTIPLE_FILES_ERROR:
      return { ...state, savingMultipleFiles: false, savingMultipleFilesError: action.error };
    case types.SET_SAVING_MULTIPLE_FILES_SUCCESS:
      return { ...state, savingMultipleFiles: false };
    case types.DELETE_FILE:
      const newList = state.list.filter(x => x.id !== action.id)
      return Object.assign({}, state, {
        list: newList
      });
    case types.SET_HC_FILEEXPIRATION_TEMPLATE:
      return Object.assign({}, state, {
        fileExTemplate: action.templates
      });
    case types.HC_FILEEXPIRATION_WORKFLOW_ENABLED:
      return { ...state, isFileExpirationWorkflowEnabled: action.isEnabled};
    default:
      return state;
  }
};
