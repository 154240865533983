import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import ProjectInsureds from '../../project-insureds';
import Documents from '../../attachments';
import Contacts from '../../contacts';
import Finance from './finance';
import Tasks from '../../tasks';
import Notes from '../../notes';
import Waivers from './waivers';
import Agencies from './../../agencies';
import RolAccess from '../../../common/rolAccess';
// import MailActivity from '../../mailActivity';
import EmailActivity from './mailActivity';

class Tabs extends Component {
  constructor(props) {
    const { projectId } = props.match.params;

    super(props);

    this.state = {
      currentTab: projectId? 2 : 0
    };
  }

  setCurrentTab = (e, i) => {
    e.preventDefault();
    this.setState({
      currentTab: i
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentTabSelected) {
      this.setState({
        currentTab: nextProps.currentTabSelected
      });
    }
  }

  renderTab = (tab, idx) => {
    return (
      <li className="tab-item" key={idx}>
        <a
          className={`tab-button ${this.state.currentTab === idx ? 'active' : ''}`}
          onClick={e => this.setCurrentTab(e, idx)}>{tab.tabLabel}</a>
      </li>
    )
  }

  renderTabContent = (tab, idx) => {
    return (
      <div
        className={`tab-pane ${this.state.currentTab === idx ? 'active' : ''}`}
        key={idx}>

        {
          this.state.currentTab === idx ?
            tab.content :
            null
        }
      </div>
    );
  }

  render() {
    const { insuredId, projectId } = this.props.match.params;
    const { insuredData, insuredArchived } = this.props;
    const {
      coverages,
      waivers,
      tasks,
      notes,
      contacts,
      finance,
      projects,
      documents,
      agencies,
      mailActivity,
    } = this.props.local.strings.insured.insuredView.tabs;

    const tabs = [
      {
        tabLabel: projects,
        masterTab: 'projects',
        sectionTab: 'view_tab',
        content: <ProjectInsureds 
                  showColumnInsuredView={true} 
                  insuredId={insuredId}
                  insuredArchived={insuredArchived}
                />
      },
      {
        tabLabel: mailActivity,
        masterTab: 'mailActivity',
        sectionTab: 'view_tab',
        content: <EmailActivity
                  insuredId={insuredId}
                />
      },
      {
        tabLabel: documents,
        masterTab: 'InsuredDocuments',
        sectionTab: 'view_tab',
        content: <Documents
                  fromInsuredTab
                  insuredId={insuredId} 
                  insuredArchived={insuredArchived}
                  certProjectId={projectId}
                />
      },
      {
        tabLabel: tasks,
        masterTab: 'tasks',
        sectionTab: 'view_tab',
        content: <Tasks 
                  fromInsuredTab 
                  insuredId={insuredId} 
                  insuredName={this.props.insuredProfile.InsuredName}
                  insuredArchived={insuredArchived}
                />
      },
      {
        tabLabel: notes,
        masterTab: 'notes',
        sectionTab: 'view_tab',
        content: <Notes 
                  fromInsuredTab 
                  insuredId={insuredId} 
                  insuredArchived={insuredArchived}
                  projectId={projectId}
                />
      },
      {
        tabLabel: contacts,
        masterTab: 'InsuredContacts',
        sectionTab: 'view_tab',
        content: <Contacts 
                  isChild='true' 
                  insuredId={insuredId}
                  fromInsuredTab
                  insuredArchived={insuredArchived}
                />
      },
      {
        tabLabel: agencies,
        masterTab: 'producers',
        sectionTab: 'view_tab',
        content: <Agencies 
                  insuredId={insuredId} 
                  insuredData={insuredData}
                  fromInsuredTab 
                  insuredArchived={insuredArchived}
                />
      }
    ];

    let tabWaivers = {
      tabLabel: waivers,
      masterTab: 'Waivers',
      sectionTab: 'view_tab',
      content: <Waivers 
                insuredId={insuredId} 
                insuredArchived={insuredArchived}
              />
    }

    let tabFinance = {
      tabLabel: finance,
      masterTab: 'finance',
      sectionTab: 'view_tab',
      content: <Finance 
                insuredId={insuredId} 
                insuredArchived={insuredArchived}
              />
    }

    let hasPermission = this.props.login.rolesAccessPermissions.find(x => x.MasterTab == 'waivers' && x.SectionTab == 'view_waivers');

    if (hasPermission) {
      tabs.push(tabWaivers);
      tabs.push(tabFinance);
    } else {
      tabs.push(tabFinance);
    }

    let renderedTabs = [];
    if (this.props.login.profile.CFRole) {
      const { CFRole } = this.props.login.profile;
      if (!CFRole) {
        return <Redirect push to="/profile" />;
      } else {
        renderedTabs = [...tabs];
      }
    }

    return (
      <div className="tab-frame">
        <ul className="profile-tabs nav nav-tabs">
          {
            renderedTabs.map((tab, idx) => {
              return (
                <RolAccess
                  key={tab.tabLabel+'-tab'}
                  masterTab={tab.masterTab}
                  sectionTab={tab.sectionTab}
                  component={() => this.renderTab(tab,idx)}>
                </RolAccess>
              );
            })
          }
        </ul>
        <div className="tab-content">
          {
            renderedTabs.map((tab, idx) => {
              return (
                <RolAccess
                  key={tab.tabLabel+'-content'}
                  masterTab={tab.masterTab}
                  sectionTab={tab.sectionTab}
                  component={() => this.renderTabContent(tab,idx)}>
                </RolAccess>
              );
            })
          }
        </div>
      </div>

    );
  }
};

const mapStateToProps = (state) => {
  return {
    local: state.localization,
    login: state.login,
    currentTabSelected: state.insuredDetails.tagSelected,
    insuredProfile: state.insuredDetails.insuredData,
  };
};

export default withRouter(connect(mapStateToProps)(Tabs));
