import React, { Component } from 'react';

import './spinner.css'

class Spinner extends Component {
  render() {
    return  (
      <div className="spinner-component">
        <div className="spinner-wrapper">
          <div className="spinner"/>
        </div>
      </div>
    );
  }
}

export default Spinner;