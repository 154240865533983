import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import CopyHolderForm from './form';
import * as documentActions from './../../documents/actions';
import * as processingActions from './../../processing/actions';
import * as commonActions from '../../../common/actions';
import '../addEntityModal.css'

class CopyHolderModal extends Component {

  hideModal = () => {
    const { onHide, close } = this.props;
    if (onHide) onHide();
    else close();
  }

  onSubmit = (values) => {}
     
  render() {
    const {
      title,
    } = this.props.local.strings.holders.copyHolders;
    
    return (
      <div className="add-item-view add-entity-form-small">
        <div className="add-item-header">
          <h1>{title}</h1>
        </div>

        <section className="white-section">
          <div className="add-item-form-subsection">
            <CopyHolderForm
              profile={this.props.profile}
              close={this.hideModal}
              onSubmit={this.onSubmit}
            />
          </div>
        </section>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    local: state.localization,
    common: state.common,
    login: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    documentActions: bindActionCreators(documentActions, dispatch),
    processingActions: bindActionCreators(processingActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CopyHolderModal));