export const SET_COVERAGES_ERROR = 'SET_COVERAGES_ERROR';
export const SET_COVERAGES_LIST = 'SET_COVERAGES_LIST';
export const SET_TOTAL_AMOUNT_OF_COVERAGES = 'SET_TOTAL_AMOUNT_OF_COVERAGES';
export const SET_FETCHING_COVERAGES = 'SET_FETCHING_COVERAGES';
export const SET_COVERAGES_SHOW_MODAL = 'SET_COVERAGES_SHOW_MODAL';

export const SET_COVERAGES_ADD_ERROR = 'SET_COVERAGES_ADD_ERROR';

export const SET_FETCHING_COVERAGES_AGENCY = 'SET_FETCHING_COVERAGES_AGENCY';
export const SET_COVERAGES_AGENCY = 'SET_COVERAGES_AGENCY';
export const SET_COVERAGES_AGENCY_ERROR = 'SET_COVERAGES_AGENCY_ERROR';

export const SET_TOTAL_AMOUNT_OF_COVERAGES_LAYERS = 'SET_TOTAL_AMOUNT_OF_COVERAGES_LAYERS';
export const SET_FETCHING_COVERAGES_LAYERS = 'SET_FETCHING_COVERAGES_LAYERS';
export const SET_COVERAGES_LIST_LAYERS = 'SET_COVERAGES_LIST_LAYERS';
export const SET_COVERAGES_ERROR_LAYERS = 'SET_COVERAGES_ERROR_LAYERS';
