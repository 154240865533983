import React, {Component} from 'react';
import PropTypes from 'prop-types';
import EditWaivedForm from "./form";

class ViewWaivedModal extends Component {

  handleSubmit = (values) => {

  }

  render() {
    const {waivedData, onHide, handleSubmit, locale} = this.props;

    return (
      <div>
        <header>
          <div className="noteEditorTitle">Waiver</div>
        </header>
        <EditWaivedForm
          locale={locale}
          data={waivedData}
          onHide={onHide}
          onSubmit={handleSubmit}
        />
      </div>
    );
  }
}

ViewWaivedModal.propTypes = {
  waivedData: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  locale: PropTypes.object.isRequired,
};

export default ViewWaivedModal;