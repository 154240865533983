import axios from 'axios';
import httpAdapter from 'axios/lib/adapters/http';

const URL_API = process.env.REACT_APP_URL_API;
const FILE_SERVICE_URL = process.env.REACT_APP_FILE_SERVICE;


if (!URL_API) {
  throw new Error('URL_API is not defined.');
}

axios.defaults.host = process.env.REACT_APP_FRONTEND_URL;
axios.defaults.adapter = httpAdapter;
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('auth-token')
    || sessionStorage.getItem('auth-token');
  if (token) {
    config.headers['x-access-token'] = token;
  }
  return config;
});

class Api {

  static get(route, token, cancelToken) {
    if( token ) {
      return axios.get(`${URL_API}${route}`,
        {
          headers: { 'x-access-token': token },
          cancelToken
        }
      );
    }
    return axios.get(`${URL_API}${route}`);
  }

  static put(route, params, token) {
    if( token ) {
      return axios.put(`${URL_API}${route}`, params,
        {
          headers: { 'x-access-token': token }
        }
      );
    }
    return axios.put(`${URL_API}${route}`, params);
  }

  static post(route, params, token, extraHeaders) {
    if( token ) {
      return axios.post(`${URL_API}${route}`, params,
        {
          headers: {
            'x-access-token': token,
            ...extraHeaders
          }
        }
      );
    }
    return axios.post(`${URL_API}${route}`, params);
  }

  static getFile(route, params, token, extraHeaders) {
    if( token ) {
      return axios.get(`${FILE_SERVICE_URL}/v1/formio/${route}`, params,
        {
          headers: {
            'x-access-token': token,
            ...extraHeaders
          },
          responseType: 'blob',
        }
      );
    }
    return axios.post(`${FILE_SERVICE_URL}/${route}`, params);
  }

  static delete(route, params, token) {
    if( token ) {
      // use axios contructor to delete because there's a bug where the token is not passed
      return axios({
        method: 'delete',
        url: `${URL_API}${route}`,
        data: params,
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      });
    }
    return axios.delete(`${URL_API}${route}`, params);
  }
}

export default Api;
