import React from 'react';
import { Errors } from '@formio/react';
import { Confirm } from '../../../../common';
import * as formsActions from '../../../../actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FormNavbar from './FormNavbar';
import { showQuickConfirmation } from '../../../../../alerts';

class FormDelete extends React.Component {

  constructor(props) {
    super(props);

    const locationState = props.location.state || {};

    this.state = {
      previousUrl: locationState.previousUrl
    };
  }
  componentDidMount() {
    this.props.actions.getForm(this.props.match.params.formId)
  }
  // componentDidUpdate(){
  //   console.log("formstate",this.props.formioForms)
  // }
  render() {
    const message = `Are you sure you wish to delete the form '${this.props.formioForms.form.title}'?`;
    const onYes = () => {
      this.props.actions.deleteForm(this.props.match.params.formId,this.props.formioForms.form.name, (err,success) => {
        if (err) {
          showQuickConfirmation({
            title: `ERROR: ${err}`,
            timer: 3000
          })
        }
        if(success) {
          this.props.history.push(this.state.previousUrl);
        }
      });
    };
  
    const onNo = () => {  
      this.props.history.goBack()
    };
    return (
      <div className='container'>
        <FormNavbar formId={this.props.formioForms.form._id} previousUrl={this.state.previousUrl} {...this.props}/>
        <Errors errors={[this.props.formioForms.error]} />
        <Confirm message={message} onYes={onYes} onNo={onNo} />
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    formioForms: state.formioForms,
    profile: state.login.profile
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(formsActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FormDelete);