import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Swal from 'sweetalert2';
import moment from 'moment';

import PTable from '../../common/ptable';
import RolAccess from "../../common/rolAccess";
import AddProjectInsuredModal from '../modals/addProjectInsuredModal';
import UploadDocumentModal from '../modals/uploadDocumentModal';
import ProcessingModal from '../modals/processingModal';
import FilterProjectInsureds from './filter';
import Utils from '../../../lib/utils';

import * as actions from './actions';
import * as commonActions from '../../common/actions';
import * as requirementSetActions from '../requirement-sets/actions'

import './ProjectInsureds.css';

import {
  PROJECT_INSURED_ON_HOLD_STATUS_ID,
  HOLDER_CLERICAL_ROLE_ID
} from '../../../lib/appConstants';
class ProjectInsureds extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        pageNumber: 1
      },
      filterBox: {
        insuredName: '',
        complianceStatusId: '',
        projectName: '',
        holderName: '',
        mylist: '',
        coverageAttributeStatus: '',
        archived: 0,
      },
      tableOrderActive: 'insuredName',
      order: {
        insuredName: 'asc',
        complianceStatusId: 'desc',
        insuredState: 'desc',
        projectName: 'desc',
        holderName: 'desc',
        city: 'desc',
        projectState: 'desc',
        mylist: 'desc',
      },
      showFilterBox: true,
      currentProjectInsured: null,
      showUploadModal: false,
      currentUploadData: null,
      showProcessingModal: false,
      currentProjectId: this.props.projectId,
      currentHolderId: this.props.holderId,
    };
  }

  componentDidMount() {
    const { projectId, insuredId } = this.props;

    let query = Utils.getFetchQuery('insuredName', 1, 'ASC');

    if (projectId) {
      query.projectId = projectId;
    } else if (insuredId) {
      query.insuredId = insuredId;
    }

    query.archived = 0;
    this.props.actions.fetchProjectInsureds(query);

    this.props.commonActions.fetchComplianceStatus();
    this.props.commonActions.fetchCoverageAttributeStatus();
  }

  addId = (query) => {
    const { projectId, insuredId } = this.props;

    if (projectId) {
      query.projectId = projectId;
    } else if (insuredId) {
      query.insuredId = insuredId;
    }
  }

  clickOnColumnHeader = (e, field) => {
    if (field === 'edit' || field === 'view') return;
    // get base query
    const pageNumber = this.state.filter.pageNumber;
    const orderDirection = this.state.order[field] === 'asc' ? 'DESC' : 'ASC'
    let query = Utils.getFetchQuery(field, pageNumber, orderDirection);
    this.addId(query);
    // add search filters
    query = Utils.addSearchFiltersToQuery(query, this.state.filterBox);
    // fetch using query
    this.props.actions.fetchProjectInsureds(query);
    // save new active tab and order
    let newState = {
      tableOrderActive: field,
      order: {
        insuredName: field === 'insuredName' ? 'asc' : 'desc',
        complianceStatusId: field === 'complianceStatusId' ? 'asc' : 'desc',
        insuredState: field === 'insuredState' ? 'asc' : 'desc',
        projectName: field === 'projectName' ? 'asc' : 'desc',
        holderName: field === 'holderName' ? 'asc' : 'desc',
        city: field === 'city' ? 'asc' : 'desc',
        projectState: field === 'projectState' ? 'asc' : 'desc',
        mylist: field === 'mylist' ? 'asc' : 'desc',
      }
    };
    newState.order[field] = this.state.order[field] === 'asc' ? 'desc' : 'asc';
    this.setState(newState);
  }

  setPageFilter = async (e, pageNumber, force) => {
    if (force || this.state.filter.pageNumber !== pageNumber) {
      // get base query
      const field = this.state.tableOrderActive;
      const orderDirection = this.state.order[field] === 'asc' ? 'ASC' : 'DESC'
      let query = Utils.getFetchQuery(field, pageNumber, orderDirection);
      this.addId(query);
      // add search filters
      query = Utils.addSearchFiltersToQuery(query, this.state.filterBox);
      // fetch using query
      await this.props.actions.fetchProjectInsureds(query);
      this.props.commonActions.setLoading(false);
      // save page number
      this.setState({
        filter: {
          pageNumber
        }
      });
    }
  }

  submitFilterForm = (values) => {
    // get base query
    const field = this.state.tableOrderActive;
    const pageNumber = 1;
    const orderDirection = this.state.order[field] === 'asc' ? 'ASC' : 'DESC'
    let query = Utils.getFetchQuery(field, pageNumber, orderDirection);
    this.addId(query);
    // add search filters
    const filterBox = {
      insuredName: values.insuredName || "",
      projectName: values.projectName || "",
      holderName: values.holderName || "",
      mylist: values.myList || "",
      complianceStatusId: values.status || "",
      keywordName: values.keyword || "",
      archived: values.archived,
      coverageAttributeStatus: values.coverageAttributeStatus || "",
    };
    query = Utils.addSearchFiltersToQuery(query, filterBox);
    // fetch using query
    this.props.actions.fetchProjectInsureds(query);
    // save searchterm and pagenumber
    this.setState({
      filterBox,
      filter: {
        pageNumber: 1
      }
    });
  }

  openModal = () => {
    this.setState({ currentProjectInsured: null });
    this.props.actions.setShowModal(true);
  }

  closeModal = () => {
    this.props.actions.setShowModal(false);
  }

  closeModalAndRefresh = () => {
    this.props.actions.setShowModal(false);
    this.setPageFilter(null, 1, true);
  }

  closeModalAndRedirect = (requirementSetId) => {
    const { holderId, projectId } = this.props;
    const redirectUrl = `/certfocus/projects/${projectId}/${holderId}/${requirementSetId}`;
    this.props.actions.setShowModal(false);
    this.props.history.push(redirectUrl);
    window.location.reload();
  }

  editProjectInsured = (projectInsured, projectId, requirementSets) => {
    if (!requirementSets || (Array.isArray(requirementSets) && requirementSets.length === 0)) {
      Swal({
        type: 'error',
        title: 'Change Requirement Set',
        text: 'Error when trying to change the Requirement Set. Has not been set in the Project Insured relationship. Please contact the administrator.',
      });
    } else {
      const parsedRequirementSets = JSON.parse(requirementSets);
      this.props.requirementSetActions.setList(parsedRequirementSets);
      this.setState({
        currentProjectInsured: projectInsured,
        currentProjectId: projectId,
        currentHolderId: projectInsured.HolderID,
      }, () => {        
        this.props.actions.setShowModal(true);
      });
    }
  }

  openUploadModal = (e, data) => {
    const currentUploadData = { 
      holderId: data.HolderID,
      projectId: data.ProjectID,
      insuredId: data.InsuredID, 
      projectInsuredId: data.ProjectInsuredID      
    };
    this.setState({ 
      showUploadModal: true, 
      currentUploadData: currentUploadData,
      currentProcessingData: data,
    });
  }

  closeUploadModal = () => {
    this.setState({ showUploadModal: false });
  }

  onUpload = (payload) => {
    this.props.commonActions.setLoading(true);
    this.props.actions.uploadDocument(payload, (success, data) => {
    //console.log('success', success, data);
      this.setState({ showUploadModal: false })
      this.props.commonActions.setLoading(false);
      if (success) {
        this.openProcessingModal(null, null, data);
      } else {
        Swal({
          type: 'error',
          title: 'Document Upload',
          text: 'Error uploading file. Please try again.',
        });
      }        
    });    
  }

  openProcessingModal = (e, payload, documentData) => {   
    const data = (payload) ? payload : this.state.currentProcessingData;
    const currentProcessingData = { 
      ...data,
      DocumentID: documentData.documentId,
      DocumentUrl: documentData.url 
    };
  //console.log('currentProcessingData: ', currentProcessingData);    
    this.setState({ showProcessingModal: true, currentProcessingData: currentProcessingData });
  }

  closeProcessingModal = (e) => {
    this.setState({ showProcessingModal: false, currentProcessingData: null });
  }

  exemptProjectInsured = (e, projectInsured) => {
    let titleText = (projectInsured.Exempt) ? 'Unexempt' : 'Exempt';
    Swal({
      title: `${titleText} Project Insured`,
      text: `Are you sure you want to ${titleText} the project # ${projectInsured.ProjectNumber} for the insured # ${projectInsured.InsuredID}?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2E5965',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        this.props.commonActions.setLoading(true);
        const exempt = (projectInsured.Exempt) ? 0 : 1;
        const lastComplianceStatusId = (exempt === 1) ? projectInsured.ComplianceStatusID : null;
        this.props.actions.exemptProjectInsured(projectInsured.ProjectInsuredID, exempt, lastComplianceStatusId, () => {
          // Adding delay due to replica load issue
          setTimeout(() => {
            this.setPageFilter(null, 1, true);
          }, 2000)
        });
      }
    });
  }

  onToggleChange = (e, projectId) => {
    const { profile } = this.props.login;
    this.props.actions.sendProjectFavorite(e.target.checked, { projectId, userId: profile.Id });
  }

  assignColorToProjectInsuredComplianceStatus = (statusId) => {
    switch (statusId) {
      case 1:
        return (<div className="compliance-status compliant"></div>);
      case 2:
        return (<div className="compliance-status escalated"></div>);
      case 6:
        return (<div className="compliance-status non-compliant"></div>);
      case 15:
        return (<div className="compliance-status minor"></div>);  
      case 16:
        return (<div className="compliance-status on-hold"></div>);  
      default:
        return (<div className="compliance-status pending"></div>);
    }
  }

  archiveProject = (e, entity, projectNumber, projectId, insuredId, projectInsuredId, isCurrentArchived) => {
    let titleText = isCurrentArchived ? 'Unarchive' : 'Archive';
    let entityNumber = (entity === 'project') ? projectNumber : insuredId;
    Swal({
      title: `${titleText} Project Insured`,
      text: `Are you sure you want to ${titleText} the project insured # ${entityNumber}?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2E5965',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        this.props.commonActions.setLoading(true);
        let archived = isCurrentArchived ? 0 : 1;
        this.props.actions.archiveProject(projectId, insuredId, projectInsuredId, archived,entity, () => {
          // Adding delay due to replica load issue
          setTimeout(() => {
            this.setPageFilter(null, 1, true);
          }, 2000)
        });
      }
    });
  }

  renderButtonAddInsured() {
    const component = (
      <a onClick={this.openModal}
        className="list-view-nav-link nav-bn icon-add no-overlapping">
        {this.props.local.strings.projectInsureds.projectInsuredsList.addBtn}
      </a>
    );
    return component;
  }

  renderLinkExemptInsured(e, projectInsured) {
    const { nonExempted, exempted } = this.props.local.strings.projectInsureds.projectInsuredsList;
    const { Exempt } = projectInsured;
    const component = (
      <a
        onClick={(e) => this.exemptProjectInsured(e, projectInsured)}
        className="cell-table-link"
        style={{ color: (!Exempt) ? '#8CC739' : '#F00' }}
      >
        {(!Exempt) ? nonExempted : exempted}
      </a>
    );
    return component;
  }

  renderDefaultExemptInsured(e, projectInsured) {
    const { nonExempted, exempted } = this.props.local.strings.projectInsureds.projectInsuredsList;
    const { Exempt } = projectInsured;
    const component = (
      <span className={`status-cell ${(!Exempt) ? 'inactive' : 'active'}`}>
        {(!Exempt) ? nonExempted : exempted}
      </span>
    );
    return component;
  }

  renderLinkArchiveInsured(e, projectInsured) {
    const { unarchived, archived } = this.props.local.strings.projectInsureds.projectInsuredsList;
    const { ProjectNumber, ProjectID, InsuredID, ProjectInsuredID, Archived } = projectInsured;
    const component = (
      <a
       onClick={(e) => this.archiveProject(e,
        this.props.showColumnInsuredView ? 'project' : 'insured',
        ProjectNumber,
        ProjectID,
        InsuredID,
        ProjectInsuredID,
        (!Archived) ? false : true)
       }
       className="cell-table-link"
       style={{ color: (!Archived) ? '#8CC739' : '#F00' }}
     >
       {(!Archived) ? unarchived : archived}
     </a>
    );
    return component;
  }

  renderDefaultArchiveInsured(e, projectInsured) {
    const { unarchived, archived } = this.props.local.strings.projectInsureds.projectInsuredsList;
    const { Archived } = projectInsured;
    const component = (
      <span className={`status-cell ${(!Archived) ? 'inactive' : 'active'}`}>
        {(!Archived) ? unarchived : archived}
      </span>
    );
    return component;
  }

  renderUploadCOILink = (archived, projectInsured) => {
    if(archived) return null;

    const {
      upload,
    } = this.props.local.strings.projectInsureds.projectInsuredsList;
    return (
      <a
        onClick={(e) => this.openUploadModal(e, projectInsured)}
        className="cell-table-link"
      >
        {upload}
      </a>
    )
  }
  renderCopyUploadCOILink = (archived, projectInsured) => {

    if(archived) return null;

    const { projectArchived } = this.props;
    const { HolderName, CertUploadHash, Archived } = projectInsured;
    const { copyUrl } = this.props.local.strings.projectInsureds.projectInsuredsList;
    const originCertUploadURL = window.location.origin + '/certfocus/certUpload/';
    const originCertUploadURLError = window.location.origin + '/certfocus/certUploadError/';

    return (
      <CopyToClipboard
        text={(Archived || projectArchived) ? `${originCertUploadURLError}${HolderName}` : `${originCertUploadURL}${CertUploadHash}`}
        onCopy={() => {
          Swal({
            type: 'success',
            title: 'Certification Upload',
            text: 'Certification Upload URL was successful copied to clipboard.',
          });
        }}>
        <span className="copyToClip linear-icon-copy">{copyUrl}</span>
      </CopyToClipboard>
    )
  }

  escalateInsured = (e, projectInsured) => {
    let titleText = (projectInsured.ComplianceStatusID === 2) ? ' Un-Escalate ' : 'Escalate';
    Swal({
      title: `${titleText} Project Insured`,
      text: `Are you sure you want to ${titleText} the project # ${projectInsured.ProjectNumber} for the insured # ${projectInsured.InsuredID}?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2E5965',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        const escalate = (projectInsured.ComplianceStatusID === 6) ? 2 : 6;
        this.props.actions.escalateProjectInsured(projectInsured.ProjectInsuredID, escalate, () => {
          this.props.commonActions.setLoading(false);
          // this.setPageFilter(null, 1, true);
        });
      }
    });
  }

  renderEscalateProjectInsured = (projectInsured) => {
    const { Exempt, HasCertificate, ComplianceStatusID, } = projectInsured;
    if (!Exempt && !HasCertificate) {
      return (<a
        onClick={(e) => this.escalateInsured(e, projectInsured)}
        className="cell-table-link"
      >
        {ComplianceStatusID === 2 ? ' Un-Escalate ' : 'Escalate'}
      </a>)
    }
  }
  
  render() {
    const {
      headers,
      filterBtn,
      viewInsured,
      viewProject,
      viewCoverage,
      viewCerts,
    } = this.props.local.strings.projectInsureds.projectInsuredsList;
    const { insuredId, projectId, projectArchived } = this.props;
    const {
      projectInsuredIdColumn,
      insuredIdColumn,
      insuredColumn,
      statusColumn,
      stateColumn,
      earliestExpirationDate,
      projectNameColumn,
      projectNumberColumn,
      requirementSetNameColumn,
    } = headers;
    
    const showInsured = projectId ? true : false;
    const showProject = insuredId ? true : false;

    const { CFRoleId, CFRole } = this.props.login.profile; 
    const { Name } = CFRole;  
    const canChangeRequirementSet = (CFRoleId === HOLDER_CLERICAL_ROLE_ID) ? false : true;

    const fieldToInsured = [
      'color',
      'projectInsuredId',
      'projectNumber',
      'projectName',
      'projectState',
      'requirementType',
      'complianceStatusId',
      'view',
      'viewCoverages',
      'viewCerts',
      'earliestExpirationDate',
      'upload',
      'viewCertUploadURL',
      'exempt',
      'escalate',
      'archived'
    ];

    const fieldToProjects = [
      'color',
      'projectInsuredId',
      ...showInsured ? ['insuredId', 'insuredName', 'insuredState', 'requirementType'] : [],
      ...showProject ? ['projectName', 'holderName', 'city'] : [],
      'complianceStatusId',
      'view',
      'viewCoverages',
      'viewCerts',
      'earliestExpirationDate',
      'upload',
      'viewCertUploadURL',
      'exempt',
      'escalate',
      'archived'
    ];   

    const projectInsuredsTableMetadata = {
      fields: this.props.showColumnInsuredView ? fieldToInsured : fieldToProjects,
      header: {
        color: '',
        projectInsuredId: projectInsuredIdColumn,
        requirementType: requirementSetNameColumn,
        insuredId: insuredIdColumn,
        insuredName: insuredColumn,
        projectNumber: projectNumberColumn,
        projectName: projectNameColumn,
        complianceStatusId: statusColumn,
        insuredState: stateColumn,
        projectState: stateColumn,
        view: '',
        viewCoverages: '',
        viewCerts: '',
        earliestExpirationDate: earliestExpirationDate,
        upload: '',
        viewCertUploadURL: '',
        exempt: '',
        escalate: '',
        archived: ''
      }
    };

    const projectInsuredsTableBody = this.props.projectInsureds.list.map((projectInsured) => {
      const {
        InsuredID,
        InsuredName,
        ComplianceStatusName,
        EarliestExpirationDate,
        InsuredState,
        ProjectNumber,
        ProjectState,
        ProjectID,
        Archived,
        requirementSetId,
        ProjectInsuredID,
        HasCertificate,
        HasProcessedDocuments,
        requirementType,
        Vendor,
        Exempt,
        requirementSets,
        ProjectFullName,
      } = projectInsured;

      return {
        color: this.assignColorToProjectInsuredComplianceStatus(projectInsured.ComplianceStatusID),
        projectInsuredId: ProjectInsuredID,
        insuredId: Vendor,
        insuredName: InsuredName,
        projectNumber: ProjectNumber,
        projectName: ProjectFullName,
        complianceStatusId: ComplianceStatusName,
        earliestExpirationDate: EarliestExpirationDate ? moment.utc(EarliestExpirationDate).format('MM/DD/YYYY') : '--',
        requirementType: (canChangeRequirementSet && Name != "Holder View Only") ? (
            <a style={{marginLeft: 15}}
              onClick={() => this.editProjectInsured(projectInsured, ProjectID, requirementSets)}
              className="cell-table-link icon-edit" >
              {requirementType}
            </a>
          ) : requirementType,
        insuredState: InsuredState ,
        projectState: ProjectState,        
        view: (
          <Link
            to={{
              pathname: projectId ? `/certfocus/insureds/${InsuredID}` : `/certfocus/projects/${ProjectID}`,
              state: { fromProject: projectId ? { projectId: ProjectID, projectName: ProjectFullName } : null },
            }}
            className="cell-table-link icon-quick_view"
          >
            {projectId ? viewInsured : viewProject}
          </Link>
        ),
        viewCoverages: (
          (requirementSetId) ? (
            <Link
              to={{
                pathname: `/certfocus/coverages/${ProjectInsuredID}/${requirementSetId}`,
                state: { fromSection: projectId ? 'Project' : 'Insured' },
              }}
              className="cell-table-link icon-quick_view"
            >
              {viewCoverage}
            </Link>
          ) : 'No Coverages Provided'
        ),
        viewCerts: (!Exempt) && (
          (HasCertificate) ? 
            (HasProcessedDocuments) ? // Processing Complete / Pending Review status
              (
              <Link
                to={`/certfocus/certificates/${ProjectInsuredID}`}
                className="cell-table-link icon-quick_view"
              >
                {viewCerts}
              </Link>
            ) : 'Pending Processing'  
          : 'No Certs Provided'
        ),
        upload: (!Archived) && !(Exempt) && (
          <RolAccess
            masterTab="certificates_insurance"
            sectionTab="upload_coi"
            component={() => this.renderUploadCOILink(Archived, projectInsured)}
          />
        ),
        viewCertUploadURL: (!Archived) && !(Exempt) && (
          <RolAccess
            masterTab="certificates_insurance"
            sectionTab="upload_coi"
            component={() => this.renderCopyUploadCOILink(Archived, projectInsured)}
          />
        ),
        exempt: (
          (Archived) || (projectInsured.ComplianceStatusID === PROJECT_INSURED_ON_HOLD_STATUS_ID) ? (
            this.renderDefaultExemptInsured(null, projectInsured)
          ) : (
            <RolAccess
              masterTab="insured_details"
              sectionTab="operation_exempt_insured"
              component={(e) => this.renderLinkExemptInsured(e, projectInsured)}
              default={(e) => this.renderDefaultExemptInsured(e, projectInsured)}
            >
            </RolAccess>
          )
        ),
        escalate: (
          <RolAccess
            masterTab="insured"
            sectionTab="escalate_project_insured"
            component={() => this.renderEscalateProjectInsured(projectInsured)}
            default={() => this.renderEscalateProjectInsured(projectInsured)}
          /> 
        ),
        archived: (
          <RolAccess
            masterTab="insured"
            sectionTab="archive_insured"
            component={(e) => this.renderLinkArchiveInsured(e, projectInsured)}
            default={(e) => this.renderDefaultArchiveInsured(e, projectInsured)}
          >
          </RolAccess>         
        ),
      };
    });

    const templatesTableData = {
      fields: projectInsuredsTableMetadata.fields,
      header: projectInsuredsTableMetadata.header,
      body: projectInsuredsTableBody
    };

    let {
      totalAmountOfProjectInsureds, projectInsuredsPerPage,
      fetchingProjectInsureds, showModal
    } = this.props.projectInsureds;

    const paginationSettings = {
      total: totalAmountOfProjectInsureds,
      itemsPerPage: projectInsuredsPerPage,
      setPageHandler: this.setPageFilter,
      currentPageNumber: this.state.filter.pageNumber,
    };

    return (
      <div className="list-view admin-view-body">
        <Modal
          show={showModal}
          onHide={this.closeModal}
          className="add-item-modal add-entity-small">
          <Modal.Body>
            <AddProjectInsuredModal
              onHide={this.closeModal}
              close={this.closeModalAndRefresh}
              projectId={this.state.currentProjectId}
              projectInsured={this.state.currentProjectInsured}
              holderId={this.state.currentHolderId}
              closeAndRedirect={this.closeModalAndRedirect}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showUploadModal}
          onHide={this.closeUploadModal}
          className="add-item-modal add-entity-small">
          <Modal.Body>
            <UploadDocumentModal
              onHide={this.closeUploadModal}
              close={this.closeModalAndRefresh}
              currentUploadData={this.state.currentUploadData}
              onSubmit={this.onUpload}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showProcessingModal}
          onHide={this.closeProcessingModal}
          className="add-item-modal add-hc">
          <Modal.Body className="add-item-modal-body mt-0">
            <ProcessingModal
              onHide={this.closeProcessingModal}
              close={this.closeProcessingModal}
              document={this.state.currentProcessingData}
              currentUploadData={this.state.currentUploadData}
              onSubmit={this.onProcessing}
            />
          </Modal.Body>
        </Modal>

        <section className="list-view-header">
          <div className="row">
            <div className="col-sm-12">
              <nav className="list-view-nav">
                <ul>
                  <li>
                    <a
                      className="list-view-nav-link nav-bn icon-login-door no-overlapping"
                      onClick={() => { this.setState({ showFilterBox: !this.state.showFilterBox }) }}
                    >
                      {filterBtn}
                    </a>
                  </li>
                  {projectId && !projectArchived && (
                    <li>
                      <RolAccess
                        masterTab="insured"
                        sectionTab="add_insured"
                        component={() => this.renderButtonAddInsured()}>
                      </RolAccess>                      
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </section>

        {
          this.state.showFilterBox &&
          <section className="list-view-filters">
            <FilterProjectInsureds
              onSubmit={this.submitFilterForm}
              projectId={projectId}
              insuredId={insuredId}
            />
          </section>
        }

        <PTable
          sorted={true}
          items={templatesTableData}
          wrapperState={this.state}
          tableOrderActive={this.state.tableOrderActive}
          clickOnColumnHeader={this.clickOnColumnHeader}
          isFetching={fetchingProjectInsureds}
          customClass='projectInsureds-list'
          pagination={paginationSettings}
        />

      </div>
    )

  }
};

const mapStateToProps = (state) => {
  return {
    projectInsureds: state.projectInsureds,
    local: state.localization,
    login: state.login,
    profile: state.login.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    requirementSetActions: bindActionCreators(requirementSetActions, dispatch),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectInsureds));
