export const SET_MAIL_HC_ID = 'SET_MAIL_HC_ID';

export const SET_MAIL_EDITOR_STATE = 'SET_MAIL_EDITOR_STATE';
export const SET_MAIL_SUBJECT = 'SET_MAIL_SUBJECT';

export const SET_MAIL_POSSIBLE_RECIPIENTS = 'SET_MAIL_POSSIBLE_RECIPIENTS';
export const SET_MAIL_RECIPIENTS = 'SET_MAIL_RECIPIENTS';
export const SET_MAIL_MAILTO= 'SET_MAIL_MAILTO';
export const SET_MAIL_PROJECT = 'SET_MAIL_PROJECT';

export const SET_MAIL_POSSIBLE_SC = 'SET_MAIL_POSSIBLE_SC';
export const SET_MAIL_POSSIBLE_PROJECTS = 'SET_MAIL_POSSIBLE_PROJECTS';
export const SET_MAIL_SC = 'SET_MAIL_SC';
export const SET_MAIL_DUE_DATE = 'SET_MAIL_DUE_DATE';
export const SET_MAIL_TASK_DETAIL = 'SET_MAIL_TASK_DETAIL';

export const SET_SENDING_MAIL = 'SET_SENDING_MAIL';
export const SET_MAIL_ERROR_MSG = 'SET_MAIL_ERROR_MSG';
export const SET_MAIL_SUCCESS_MSG = 'SET_MAIL_SUCCESS_MSG';
export const SET_MAIL_TEMPLATES = 'SET_MAIL_TEMPLATES';
export const SET_TOTAL_AMOUNT_OF_TEMPLATES = 'SET_TOTAL_AMOUNT_OF_TEMPLATES';
export const SET_MAIL_TEMPLATE = 'SET_MAIL_TEMPLATE';
