import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import renderSelect from '../../../../../../customInputs/renderSelect';

class FinancialInfoRenewalModalForm extends Component {
  render() {
    const { handleSubmit, close, formIdOptions } = this.props;

    return (
      <form onSubmit={handleSubmit} className="list-view-filter-form">
        <div className="sc-financial-info-copy-submission-modal-content container-fluid filter-fields">
          <div className="sc-financial-info-copy-submission-modal-container" >
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-sm-12 step-col-right">
                  <div className="register-field-wrapper">
                    <label htmlFor="formId" className="required">{'Form Id'}:</label>

                    <div className="select-wrapper">
                      <Field
                        name="formId"
                        component={renderSelect}
                        options={formIdOptions}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sc-financial-info-copy-submission-modal-buttons-container text-right">
            <button className="bg-sky-blue-gradient bn" type="button" onClick={close}>
              {'Close'}
            </button>

            <button className="bg-sky-blue-gradient bn action-button" type="submit">
              {this.props.type == 'renewal' ? 'Force' : 'Add'}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.formId || values.formId == 0) {
    errors.formId = 'FormId is required';
  }

  return errors;
};

FinancialInfoRenewalModalForm = reduxForm({
  form: 'FinancialInfoRenewalModalForm',
  validate,
  enableReinitialize: true,
})(FinancialInfoRenewalModalForm);

const mapStateToProps = (state) => ({
  local: state.localization,
  financials: state.financials,
});

export default connect(mapStateToProps, null)(FinancialInfoRenewalModalForm);
