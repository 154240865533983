export const SET_INSUREDS_ERROR = 'SET_INSUREDS_ERROR';
export const SET_INSUREDS_LIST = 'SET_INSUREDS_LIST';
export const SET_TOTAL_AMOUNT_OF_INSUREDS = 'SET_TOTAL_AMOUNT_OF_INSUREDS';
export const SET_FETCHING_INSUREDS = 'SET_FETCHING_INSUREDS';
export const SET_INSUREDS_SHOW_MODAL = 'SET_INSUREDS_SHOW_MODAL';
export const SET_INSUREDS_ADD_ERROR = 'SET_INSUREDS_ADD_ERROR';
export const SET_INSUREDS_ADD_SUCCESS = 'SET_INSUREDS_ADD_SUCCESS';
export const SET_INSUREDS_ADD_FETCHING = 'SET_INSUREDS_ADD_FETCHING';

// Type ahead and search
export const SET_TYPE_AHEAD_INSURED_FETCHING = 'SET_TYPE_AHEAD_INSURED_FETCHING';
export const SET_TYPE_AHEAD_INSURED_SUCCESS = 'SET_TYPE_AHEAD_INSURED_SUCCESS';
export const SET_TYPE_AHEAD_INSURED_ERROR = 'SET_TYPE_AHEAD_INSURED_ERROR';
export const RESET_TYPE_AHEAD_INSURED_RESULTS = 'RESET_TYPE_AHEAD_INSURED_RESULTS';

// Search insured using navbar search
export const SET_SEARCH_INSURED_TRUE = 'SET_SEARCH_INSURED_TRUE';
export const SET_SEARCH_INSURED_FALSE = 'SET_SEARCH_INSURED_FALSE';
