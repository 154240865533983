import React, { Component } from 'react';
import { Field, reduxForm, touch, change } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';

import renderField from '../../customInputs/renderField';
import renderSelect from '../../customInputs/renderSelect';
import renderTypeAhead from '../../customInputs/renderTypeAhead';
import renderRemovable from '../../customInputs/renderRemovable';
import Utils from '../../../lib/utils';
import validate from './validation';
import asyncValidate from './asyncValidation';

import * as commonActions from '../../common/actions';
import * as registerActions from '../../register/actions';
import * as registrationActions from './actions';
import * as loadApplyOptions from '../../common/countyDropdownActions/loadOptions'
import * as applyActions from '../../common/countyDropdownActions/actions'
import {AsyncPaginate} from 'react-select-async-paginate';

import {
  GOOGLE_RECAPTCHA_SITE_KEY
} from '../../../lib/captcha';

const SelectAsyncOptions = ({ input, meta, dispatch, ...props }) => {
  const touchedError = meta.touched && meta.error;
  const name = input.name;
  const baseClassName = `${props.className || ''} select-${name}`;
  const errorClassName = `${touchedError ? `${baseClassName}-error` : ''}`;

  return (
    <React.Fragment>
      <AsyncPaginate
        {...input}
        {...props}
        openMenuOnClick={true}
        closeMenuOnScroll={true}
        cacheOptions={true}
        pageSize={10}
        additional={{ page: 1 }}
        classNamePrefix="vk"
        className={`${baseClassName} ${errorClassName}`}
        menuPlacement="auto"
        onFocus={(_) => { }}
        onBlur={() => meta.dispatch(touch('RegistrationForm', name))}
        onChange={(newValue) => input.onChange(newValue)}
        captureMenuScroll={true}
        closeMenuOnSelect={true}
        debounceTimeout={300}
        loadOptionsOnMenuOpen={true}
      />
      {touchedError && (
        <span className="select-error-message field-validation-message">
          {meta.error}
        </span>
      )}
    </React.Fragment>
  );
};
class RegistrationForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      holderData: null,
      submitted: false,
      stateList: [],
      selectedCountry:1
    };    
  }

  componentDidMount() {
    if(this.state.selectedCountry === 1){
      this.props.dispatch(change('RegistrationForm', 'countryId',{value:1,label:"United States"}));
    }
    this.props.registrationActions.fetchGeoStates((err, data) => {
      if (!err) {
        this.setState({ stateList: data });
      }
    });    
  }
  handleCountryChange = (value)=>{
    if(value.value !== this.state.selectedCountry){
      this.props.dispatch(change('RegistrationForm', 'stateId', ""));
    }
      this.setState({selectedCountry:value.value})
  }
  renderFormField = (element, idx) => {
    const {
      name,
      label,
      ph,
      type,
      conditional,
      show,
      options,
      onBlur,
      loadOptions
    } = element;

    const style = {};
    if (conditional && !show) {
      style.display = 'none';
    }

    if (type === 'typeAhead') {
      const { fetching, results, error, handleSearch, onSelect } = element;

      return (
        <div className="col-md no-padd" key={idx} style={style}>
          <div className="wiz-field admin-form-field-wrapper keywords-field">
            <label htmlFor={name}>{`${label}: `}</label>
            <Field
              resetOnClick
              name={name}
              placeholder={ph}
              fetching={fetching}
              results={results}
              handleSearch={handleSearch}
              fetchError={error}
              component={renderTypeAhead}
              onSelect={onSelect}
            />
          </div>
        </div>
      );
    } else if (type === 'removable') {
      const { valueText, disabled, onRemove } = element;
      return (
        <div className="col-md no-padd" key={idx} style={style}>
          <div className="wiz-field admin-form-field-wrapper keywords-field">
            <label htmlFor={name}>{`${label}:`}</label>
            <Field
              name={name}
              valueText={valueText}
              component={renderRemovable}
              onRemove={onRemove}
              disabled={disabled}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="col-md no-padd" key={idx} style={style}>
        <div className="admin-form-field-wrapper keywords-field">
          <label htmlFor={name}>{`${label}: `}</label>
          {
            loadOptions?
            <div className="w-100">
              <div className="ml-auto" style={{width: '250px'}}>
              <Field
                name={name}
                component={SelectAsyncOptions}
                loadOptions={loadOptions}
                onChange={(value)=>name == "countryId" ? this.handleCountryChange(value) : null}
                className={'accountManager'}
                cacheUniqs={[this.state.selectedCountry]}
              />
              </div>
            </div>
          :
          <Field
            name={name}
            type="text"
            placeholder={ph}
            component={renderField}
            onBlur={onBlur}
          />
        }
        </div>
      </div>
    );
  }

  renderRecaptchaField(field) {
    const { meta: { touched, error } } = field;
    return (
      <div>   
        <ReCAPTCHA 
          sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
          onChange={field.input.onChange}
        />
        <div><p className="text-danger">{ touched ? error : '' }</p></div>
      </div>
    );
  }

  fetchHolderData = (e) => {
    const holderId = e.target.value;
    this.props.registrationActions.fetchHolderData(holderId, (err, data) => {
      if (!err) {
        this.setState({ holderData: data[0] })
      }
    });
  }

  onSubmit = (values) => {
    this.setState({ submitted: true }, () => {
      this.props.onSubmit(values);
      setTimeout(() => {
        this.setState({ submitted: false });
      }, 1000);
    });
  }
 
  render() {
    const {
      handleSubmit,
    } = this.props;

    const {
      registrationCodeLabel,
      companyNameLabel,
      taxIdLabel,
      addressLabel,
      cityLabel,
      stateLabel,
      countryLabel,
      zipCodeLabel,
      contactNameLabel,
      emailLabel,
      phoneLabel,
      faxLabel,
      insuranceAgencyCompanyNameLabel,
      insuranceAgencyContactPersonLabel,
      insuranceAgencyPhoneLabel,
      insuranceAgencyEmailLabel,
    } = this.props.local.strings.registration;

    const countryOptions = this.props.actions.loadCountries(this.props)
    const stateOptions = this.props.actions.loadStates(this.props,this.state.selectedCountry)
    const leftFields = [
      { name: 'registrationCode', label: registrationCodeLabel, ph: ``, onBlur: (e) => this.fetchHolderData(e) },
      { name: 'companyName', label: companyNameLabel, ph: `` },
      { name: 'taxId', label: taxIdLabel, ph: `` },
      { name: 'address', label: addressLabel, ph: `` },
      { name: 'city', label: cityLabel, ph: `` },
      { name: 'stateId', label: stateLabel, ph: ``, type: 'select', loadOptions: stateOptions },
      { name: 'countryId', label: countryLabel, ph: ``, type: 'select', loadOptions: countryOptions },
      { name: 'zipCode', label: zipCodeLabel, ph: `` },
      { name: 'contactName', label: contactNameLabel, ph: `` },
    ];
    const rightFields = [      
      { name: 'email', label: emailLabel, ph: `` },
      { name: 'phone', label: phoneLabel, ph: `` },
      { name: 'fax', label: faxLabel, ph: `` }, 
      { name: 'insuranceAgencyCompanyName', label: insuranceAgencyCompanyNameLabel, ph: `` },
      { name: 'insuranceAgencyContactPerson', label: insuranceAgencyContactPersonLabel, ph: `` },
      { name: 'insuranceAgencyPhone', label: insuranceAgencyPhoneLabel, ph: `` },
      { name: 'insuranceAgencyEmail', label: insuranceAgencyEmailLabel, ph: `` },
    ];
    
    return (
      <form onSubmit={handleSubmit(values => this.onSubmit({...values, holderData: this.state.holderData }))} className="entity-info-form">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            {leftFields.map(this.renderFormField)}
          </div>
          <div className="col-md-6 col-sm-12">
            {rightFields.map(this.renderFormField)}
            <Field name="recaptcha" component={this.renderRecaptchaField} />
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12 d-flex justify-content-end">          
          {(this.state.submitted) ? (
            <div className="spinner-wrapper">
              <div className="spinner" />
            </div>
          ):(  
            <button
              className="bn bn-small bg-green-dark-gradient create-item-bn icon-save"
              type="submit">
              Submit
            </button>
          )}
          </div>
        </div>
      </div>
      </form>
    );
  }
};

RegistrationForm = reduxForm({
  form: 'RegistrationForm',
  validate,
  asyncValidate,
  asyncBlurFields: ['registrationCode'],
  initialValues: {
  },
})(RegistrationForm);

const mapStateToProps = (state) => {
  return {
    local: state.localization,
    register: state.register,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      ...bindActionCreators(applyActions, dispatch),
      ...loadApplyOptions
    },
    commonActions: bindActionCreators(commonActions, dispatch),
    registerActions: bindActionCreators(registerActions, dispatch), 
    registrationActions: bindActionCreators(registrationActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
