import React, { useState } from "react";
import { Modal } from 'react-bootstrap';
import { DEFULT_MODAL_HEIGHT, TEXT_DISPLAY_LIMIT, TEXT_LIMIT } from "../../../lib/appConstants";


const DisplayTextWithConditionalModal = ({ string, textLimit = TEXT_LIMIT, displayTextLimit = TEXT_DISPLAY_LIMIT.SEVEN}) => {
    const [showModal, setShowModal] = useState(false);

    const renderText = () => {
        if (string && string.length > textLimit && string.length > displayTextLimit && isNaN(string)) {
            return <>
                    <Modal
                        size="lg"
                        show={showModal}
                        onHide={setModalState}
                        className="text-modal"
                        >
                        <Modal.Header closeButton>
                            <Modal.Title>Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ margin: 0 }}>
                            <div style={{ padding: '0px 14px 20px 14px'}}>
                                {string}
                            </div>
                        </Modal.Body>
                    </Modal>
                    <span role='button' onClick={setModalState} >{string.slice(0, displayTextLimit) + "..."}</span>
                </> 
        }
        return string || null
    }

    const setModalState = () => {
        setShowModal(!showModal)
    }
    return renderText()
}
export default DisplayTextWithConditionalModal;