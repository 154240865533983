export const SET_REQUIREMENT_SETS = 'SET_REQUIREMENT_SETS';
export const SET_ATTACHMENTS = 'SET_ATTACHMENTS';
export const SET_ENDORSEMENTS = 'SET_ENDORSEMENTS';
export const SET_REQUIREMENT_SETS_ERROR = 'SET_REQUIREMENT_SETS_ERROR';
export const SET_FETCHING_DOCUMENTS = 'SET_FETCHING_DOCUMENTS';
export const SET_DOCUMENTS_LIST = 'SET_DOCUMENTS_LIST';
export const SET_DOCUMENTS_ERROR = 'SET_DOCUMENTS_ERROR';

/** CERTIFICATES */
export const SET_FETCH_CERTIFICATES = 'SET_FETCH_CERTIFICATES';
export const SET_FETCHING_CERTIFICATES = 'SET_FETCHING_CERTIFICATES';
export const SET_FETCH_CERTIFICATES_SUCCESS = 'SET_FETCH_CERTIFICATES_SUCCESS';
export const SET_FETCH_INSURED_EMAIL = 'SET_FETCH_INSURED_EMAIL';
export const SET_FETCH_PROCEDURE_EMAIL = 'SET_FETCH_PROCEDURE_EMAIL';
export const SET_FETCH_INSURED_EMAIL_SUCCESS = 'SET_FETCH_INSURED_EMAIL_SUCCESS';
export const SET_FETCH_PROCEDURE_EMAIL_SUCCESS = 'SET_FETCH_PROCEDURE_EMAIL_SUCCESS';
export const SET_FETCH_SEND_EMAIL = 'SET_FETCH_SEND_EMAIL';
export const SET_FETCH_SEND_EMAIL_SUCCESS = 'SET_FETCH_SEND_EMAIL_SUCCESS';
export const TOGGLE_INSURED_EMAIL = 'TOGGLE_INSURED_EMAIL';
export const TOGGLE_PROCEDURE_EMAIL = 'TOGGLE__PROCEDURE_EMAIL';

export const SET_INSURED_EMAIL = 'SET_INSURED_EMAIL';
export const SET_PROCEDURE_EMAIL = 'SET_PROCEDURE_EMAIL';

export const SET_FETCH_USERS_SUCCESS = 'SET_FETCH_USERS_SUCCESS';
export const SET_FETCH_USERS = 'SET_FETCH_USERS';
export const SET_FETCH_PROJECTINSURED_SUCCESS = 'SET_FETCH_PROJECTINSURED_SUCCESS';

export const SET_FETCH_REJECT = 'SET_FETCH_REJECT';
export const SET_FETCH_REJECT_SUCCESS = 'SET_FETCH_REJECT_SUCCESS';

export const SET_FETCH_ON_HOLD = 'SET_FETCH_ON_HOLD';
export const SET_FETCH_ON_HOLD_SUCCESS = 'SET_FETCH_ON_HOLD_SUCCESS';

export const SET_FETCH_REMOVE_ON_HOLD = 'SET_FETCH_REMOVE_ON_HOLD';
export const SET_FETCH_REMOVE_ON_HOLD_SUCCESS = 'SET_FETCH_REMOVE_ON_HOLD_SUCCESS';

export const SET_FETCH_ESCALETE_CERTIFICATE = 'SET_FETCH_ESCALETE_CERTIFICATE';
export const TOGGLE_PROCEDURE_EMAIL_BTN = 'TOGGLE_PROCEDURE_EMAIL_BTN';
