import moment from "moment";

export const validate = (values, props) => {
  const {
    requiredValidation,
  } = props.local.strings.processing.dataEntry;
  const errors = {};
  
  const validateFilled = ['dateCertificate', 'agency'];

  if (!values.unreadable) {
    validateFilled.forEach(name => {
      if(!values[name]) {
        errors[name] = requiredValidation;
      }
    });

    const currentDate = moment();
    const futureLimit = moment().add(10, 'y');
    const pastLimit = moment().subtract(10, 'y');

    const dateCertificate = moment(values.dateCertificate);
      
    if (dateCertificate.isAfter(futureLimit)) {
      errors.dateCertificate = "Should be within 10 years in past or future";
    } else if (dateCertificate.isBefore(pastLimit)) {
      errors.dateCertificate = "Should be within 10 years in past or future";
    }
    

    if (!values.coverages || values.coverages.length === 0) {
      errors.coverages = { _error: 'At least one coverage must be entered' };
    } else {
      const coveragesArrayErrors = [];
      values.coverages.forEach((coverage, coverageIndex) => {
        const coverageErrors = {};
        // if (!coverage || !coverage.insurer) {
        //   coverageErrors.insurer = requiredValidation;
        //   coveragesArrayErrors[coverageIndex] = coverageErrors;
        // }
        if (!coverage || !coverage.ruleGroupId) {
          coverageErrors.ruleGroupId = requiredValidation;
          coveragesArrayErrors[coverageIndex] = coverageErrors;
        }
        if (!coverage || !coverage.policyNumber) {
          coverageErrors.policyNumber = requiredValidation;
          coveragesArrayErrors[coverageIndex] = coverageErrors;
        }
        if (!coverage || !coverage.effectiveDate) {
          coverageErrors.effectiveDate = requiredValidation;
          coveragesArrayErrors[coverageIndex] = coverageErrors;
        } else {
          const effectiveDate = moment(coverage.effectiveDate);
          if (effectiveDate.isAfter(futureLimit)) {
            coverageErrors.effectiveDate = 'Should be within 10 years in future';
            coveragesArrayErrors[coverageIndex] = coverageErrors;
          } else {
            if (effectiveDate.isBefore(pastLimit)) {
              coverageErrors.effectiveDate = 'Should be within 10 years in past';
              coveragesArrayErrors[coverageIndex] = coverageErrors;
            }
          }
        }
        if (!coverage || !coverage.expirationDate) {
          coverageErrors.expirationDate = requiredValidation;
          coveragesArrayErrors[coverageIndex] = coverageErrors;
        } else {
          const expirationDate = moment(coverage.expirationDate);
          const effectiveDate = moment(coverage.effectiveDate);
          if (expirationDate.isAfter(futureLimit)) {
            coverageErrors.expirationDate = 'Should be within 10 years in future';
            coveragesArrayErrors[coverageIndex] = coverageErrors;
          } else {
            if (coverage.effectiveDate && expirationDate.isBefore(effectiveDate)) {
              coverageErrors.expirationDate = 'Should be after effective date';
              coveragesArrayErrors[coverageIndex] = coverageErrors;
            }
          }
        }

        // if (coverage && coverage.attributes && coverage.attributes.length) {
        //   const attributesArrayErrors = [];
        //   coverage.attributes.forEach((attribute, attrIndex) => {
        //     const attributeErrors = {};
        //     if (!attribute || !attribute.value) {
        //       attributeErrors.value = requiredValidation;
        //       attributesArrayErrors[attrIndex] = attributeErrors;
        //     }
        //   });

        //   if (attributesArrayErrors.length) {
        //     coverageErrors.attributes = attributesArrayErrors;
        //     coveragesArrayErrors[coverageIndex] = coverageErrors;
        //   }
        // }
      });

      if (coveragesArrayErrors.length) {
        errors.coverages = coveragesArrayErrors;
      }
    }
  }
  //console.log('validate err: ', errors);  
  return errors;
};

export const warn = (values) => {
  const warnings = {};

  const coveragesArrayWarnings = [];
  values.coverages.forEach((coverage, coverageIndex) => {
    if(coverage && coverage.expirationDate) {
      const coveragesWarnings = {};
      const expirationDate = moment(coverage.expirationDate);
      const currentDate = moment();
      if (expirationDate.isBefore(currentDate)) {
        if (coverage.effectiveDate && moment(coverage.effectiveDate).isAfter(expirationDate)) {
          coveragesWarnings.expirationDate = '';
          coveragesArrayWarnings[coverageIndex] = coveragesWarnings; 
        } else {
          coveragesWarnings.expirationDate = 'Should be in future';
          coveragesArrayWarnings[coverageIndex] = coveragesWarnings; 
        }
      }
    }
  });

  if (coveragesArrayWarnings.length) {
    warnings.coverages = coveragesArrayWarnings;
  }

  return warnings;
};
