import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchSession, fetchDashboards, reset, setSessionApiCallStatus, setDashboardApiCallStatus } from "./actions";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import utils from "../../lib/utils";
import PrequalDashboard from "./PrequalDashboard";
import CertfocusDashboard from "./CertfocusDashboard";
import Libary from "./Library";
import { Button } from 'react-bootstrap';
import moment from "moment";

import ScheduledReports from "./ScheduledReports";
import {HIRING_CLIENT_FULL_ACCESS_ROLE_ID,HIRING_CLIENT_NON_FINANCIAL_ACCESS_ROLE_ID,HOLDER_ADMIN_ROLE_ID,HOLDER_CLERICAL_ROLE_ID,HOLDER_EXECUTIVE_ROLE_ID,HOLDER_VIEW_ONLY_ROLE_ID,PQ_FINANCIAL_ANALYST_ROLE_ID,PQ_OPERATOR_ROLE_ID,SELF_SERVICE_ADMIN_ROLE_ID,SELF_SERVICE_ANALYST_ROLE_ID,SELF_SERVICE_FINANCIAL_ANALYST_ROLE_ID,SELF_SERVICE_OPERATOR_ROLE_ID,SUBCONTRACTOR_ROLE_ID,SUPER_HIRING_CLIENT_FULL_ACCESS_ID} from "../../lib/appConstants";
import Builder from "./Builder";

function Yurbi({ module, location, yurbi, fetchSession, fetchDashboards, reset, setSessionApiCallStatus, setDashboardApiCallStatus,login }) {
  const [context, setContext] = useState('dashboard');
  const history = useHistory();
  const { pathname } = location;
  const reloadPage = utils.usePageReload(history, pathname, "/");

  useEffect(() => {
    const {sessionToken, dashboardFetchComplete, sessionTimeout, isFetchApiCall, isDashboardApiCall } = yurbi;
    // Fetch new Session if current one expired 
    if(!isFetchApiCall ){
      setSessionApiCallStatus(true)
      fetchSession(sessionToken, sessionTimeout);
    }
    // Fetch dasboards if not yet fetched
    if(sessionToken && !isDashboardApiCall) {
      setDashboardApiCallStatus(true)
      var now  = moment();
      var yurbiExpiredSessionTime = moment(sessionTimeout);
      var remainingMs = moment(yurbiExpiredSessionTime).diff(moment(now))
      setTimeout(() => {
        reset();
      }, remainingMs)
      fetchDashboards(module, sessionToken);
    }
  }, [yurbi]);

  useEffect(() => {
    // Handle error
    const { error, errorMessage } = yurbi;
    if (error) {
      Swal({
        title: error,
        html: `${errorMessage}`,
        type: "error",
        confirmButtonText: "Reload",
      }).then((result) => {
        if(result.value){
          reset();
          reloadPage();
        }
      });
    }
  }, [yurbi.error]);

  const changeContext = (context) => {
    setContext(context);
  }

  const hasScheduledReportsAccess = () => {
    const hasAccessPQRole = [
      PQ_OPERATOR_ROLE_ID,
      HIRING_CLIENT_FULL_ACCESS_ROLE_ID,
      SUBCONTRACTOR_ROLE_ID,
      PQ_FINANCIAL_ANALYST_ROLE_ID,
      HIRING_CLIENT_NON_FINANCIAL_ACCESS_ROLE_ID,
      SUPER_HIRING_CLIENT_FULL_ACCESS_ID,
      SELF_SERVICE_ADMIN_ROLE_ID,
      SELF_SERVICE_OPERATOR_ROLE_ID,
      SELF_SERVICE_FINANCIAL_ANALYST_ROLE_ID,
      SELF_SERVICE_ANALYST_ROLE_ID
    ];

    const hasAccessCFRole = [
      HOLDER_ADMIN_ROLE_ID,
      HOLDER_EXECUTIVE_ROLE_ID,
      HOLDER_CLERICAL_ROLE_ID,
      HOLDER_VIEW_ONLY_ROLE_ID
    ]
    const { RoleID, CFRoleId } = login.profile;
    if (hasAccessPQRole.includes(RoleID) || hasAccessCFRole.includes(CFRoleId)){
      return true;
    }else{
      return false;
    }
  }
  const { dashboards, sessionToken, dashboardFetchComplete } = yurbi;
  const { certfocusDashboardId, prequalDashboardId } = dashboards;
  const hasAccess = hasScheduledReportsAccess();
  const { hasBuilderAccess } = login.profile;
  return (
    <>
      {/* Button for navigating between dashboard and library */}
      <div style={{marginLeft: "10px", marginBottom:"6px", marginTop:"6px"}}> 
        <Button variant="outline-dark" size="sm" active={context==='dashboard'} onClick={()=>changeContext('dashboard')}>
          Dashboard
        </Button>
        {' '}
        <Button variant="outline-dark" size="sm" active={context==='library'} onClick={()=>changeContext('library')}>
          Library
        </Button>
        {' '}
        {
          hasBuilderAccess && (
            <Button variant="outline-dark" size="sm" active={context==='builder'} onClick={()=>changeContext('builder')}>
              Builder
            </Button>
          )
        }
        {' '}
        {
          hasAccess && (
            <Button variant="outline-dark" size="sm" active={context==='scheduledReports'} onClick={()=>changeContext('scheduledReports')}>
              Scheduled Reports
            </Button>
          )
        }

      </div>

      {
        context === "dashboard" && dashboardFetchComplete ?
          ( module === "certfocus" ?
              <CertfocusDashboard dashboardId={certfocusDashboardId} sessionToken={sessionToken} /> :
              <PrequalDashboard dashboardId={prequalDashboardId} sessionToken={sessionToken} />
          ) : null
      }
      {
        context === "library" && <Libary sessionToken={sessionToken}/>
      }
      {
        ( hasBuilderAccess && context === "builder" ) && <Builder sessionToken={sessionToken}/>
      }
      {
        (hasAccess && context === "scheduledReports") && <ScheduledReports sessionToken={sessionToken}/>
      }
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    yurbi: state.yurbi,
    login: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDashboards: bindActionCreators(fetchDashboards, dispatch),
    fetchSession: bindActionCreators(fetchSession, dispatch),
    reset: bindActionCreators(reset, dispatch),
    setSessionApiCallStatus: bindActionCreators(setSessionApiCallStatus, dispatch),
    setDashboardApiCallStatus: bindActionCreators(setDashboardApiCallStatus, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Yurbi);
