import React from "react";
import * as formsActions from "../../../../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SubmissionView from "./SubmissionView";
import FormNavbar from "../../form/components/FormNavbar";
import SubmissionNavbar from "./SubmissionNavbar";
import { Link } from "react-router-dom";
import FormioTableOfContents from "../../tableofcontent";
class SubmissionEdit extends React.Component {
  componentDidMount() {
    this.props.actions.getForm(this.props.match.params.formId);
    this.props.actions.getSubmission(
      this.props.match.params.submissionId,
      this.props.match.params.formId
    );
  }

  render() {
    const formId = this.props.match.params.formId;
    const submissionId = this.props.match.params.submissionId;
    const locationState = this.props.location.state || {};

    return (
      <>
        <div className="container">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <Link
                className="nav-link"
                onClick={() => {
                  this.props.history.goBack();
                }}
              >
                <i className="fa fa-chevron-left">
                  &nbsp;<span>Back</span>
                </i>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to={`/formio/forms/${formId}/submission/${submissionId}/pdf`}
              >
                View PDF
              </Link>
            </li>
          </ul>
        </div>
        <div className="container-fluid" id="main">
          <div className="clearfix">
            <FormioTableOfContents />
            <div className="col-sm-6 col-md-9 float-right">
              <SubmissionView
                form={this.props.formioForms}
                submission={this.props.formioSubmission}
                hcName={locationState.hcName}
                savedFormId={locationState.savedFormId}
                profile={this.props.profile}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.login.profile,
    formioForms: state.formioForms,
    formioSubmission: state.formioSubmission,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(formsActions, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SubmissionEdit);
