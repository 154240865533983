import _ from 'lodash';
import * as regex from '../../../../lib/regex';

const validate = (values, props) => {
  const {
    requiredValidation,
    phoneErrorMessage,
    wrongEmailFormat,
    passwordBoth
  } = props.local.strings.register.userInformation;

  const errors = {};

  if (_.isEmpty(_.get(values, 'firstName', '').trim())) {
    errors.firstName = requiredValidation;
  }

  if (_.isEmpty(_.get(values, 'lastName', '').trim())) {
    errors.lastName = requiredValidation;
  }

  if (!values.title || values.title.value <= 0) {
    errors.title = requiredValidation;
  }
  
  if (_.isEmpty(_.get(values, 'phone', '').trim())) {
    errors.phone = requiredValidation;
  } else if (!(regex.PHONE_INTERNATIONAL.test(values.phone) || 
    regex.PHONE.test(values.phone))) {
    errors.phone = phoneErrorMessage;
  }

  if (values.cellPhone) {
    if (!(regex.PHONE_INTERNATIONAL.test(values.cellPhone) || 
      regex.PHONE.test(values.cellPhone))) {
      errors.cellPhone = phoneErrorMessage;
    }
  }

  if (_.isEmpty(_.get(values, 'email', '').trim())) {
    errors.email = requiredValidation;
  } else if (!regex.EMAIL.test(values.email) || values.email.length > 80) {
    errors.email = wrongEmailFormat;
  }

  if (_.isEmpty(_.get(values, 'password', '').trim())) {
    errors.password = requiredValidation;
  }

  if (_.isEmpty(_.get(values, 'retypePassword', '').trim())) {
    errors.retypePassword = requiredValidation;
  }

  if (values.password !== values.retypePassword) {
    errors.password = passwordBoth;
    errors.retypePassword = passwordBoth;
  }

  return errors;
};

export default validate;
