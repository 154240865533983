import Api from "../../../lib/api";
import * as types from "./types";

export const setSavingFile = (isSaving) => {
  return {
    type: types.SET_SAVING_FILE,
    isSaving,
  };
};

export const setDocInfo = (data) => {
  return {
    type: types.SET_DOC_INFO,
    data,
  };
};

export const setDocFetching = (isFetching) => {
  return {
    type: types.SET_DOC_FETCHING,
    isFetching,
  };
};

export const saveFile = (payload, callback) => {
  return (dispatch) => {
    dispatch(setSavingFile(true));

    Api.post(`files/email`, payload)
      .then((response) => {
        dispatch(setSavingFile(false));
        if (!response.data.success) {
          throw new Error("There was an error while saving, please try again");
        }
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        dispatch(setSavingFile(false));
        if (callback) {
          callback(error);
        }
      });
  };
};

export const fetchFileInfoFromHash = (payload) => {
  return (dispatch, getState) => {
    const token = getState().login.authToken;
    return Api.post(`hc_sc/document/decode_hash`, payload, token)
      .then((response) => {
        dispatch(setDocInfo(response.data));
        dispatch(setDocFetching(false));
      })
      .catch((error) => {
        //console.log(error)
        dispatch(setDocInfo([]));
        dispatch(setDocFetching(false));
      });
  };
};
