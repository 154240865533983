const validate = (values, props) => {
  const errors = {};
  const {
    requiredValidation,
    fileTypeValidation
  } = props.local.strings.certFocusProjects.updateCustomFieldsModal;
  
  const validateFilled = ['csvCFDataFile'];
  validateFilled.forEach(name => {
    if(!values[name]) {
      errors[name] = requiredValidation;
    }
  });

  if(values.csvCFDataFile) {
    const { type } = values.csvCFDataFile;
    const validFiles = ['application/pdf'];

    if (type) {
      if (!validFiles.includes(type)) {
        errors.csvCFDataFile = fileTypeValidation;
      }
    }
  }
  return errors;
};

export default validate;
