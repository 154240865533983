import React from 'react';
import { withRouter } from 'react-router-dom';
import Users from '../../../users';

class UsersTab extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      hcId : this.props.hcId,
    }
  };

  componentWillReceiveProps(nextProps) {
    if(typeof nextProps.hcId !== 'undefined' && nextProps.hcId !== this.state.hcId) {
      this.setState({
        hcId : nextProps.hcId
      })
    }
  }

  render() {
    const { hcId } = this.state;
    return (
      <Users fromSCtab scId={this.props.match.params.scId} hcId={hcId} />
    );
  }
}


export default withRouter(UsersTab);
