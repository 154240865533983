import React, { useEffect, useState } from 'react';
import * as actions from './actions';
import PropTypes from 'prop-types';
import { Dialog, MuiThemeProvider } from '@material-ui/core';
import vertikalTheme from '../../../lib/theme';
import Utils from '../../../lib/utils';
import MUIDataTable from 'mui-datatables';
import AMBestImportHistory from '../ambestImportHistory';
import { Visibility } from '@material-ui/icons';
import { Modal } from 'react-bootstrap';
import AMBestCustomToolbar from './ambestCustomToolbar';
import AMBestImportModal from '../modals/ambestImportModal';
import Swal from 'sweetalert2';
import DescriptionIcon from '@material-ui/icons/Description';

const AMBest = props => {
  const {locale, authToken} = props;

  const [AMBestImports, setAMBestImports] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [currentImport, setCurrentImport] = useState({});
  const [showUpload, setShowUpload] = useState(false)
  const [isFetching, setIsFetching] = useState(true);

  useEffect( () => {
    (async function loadData () {
        await _getImportsList();
    })();
  }, []);

  const {headers, rowActions, title} = locale.table;

  const columns = [
    { name: 'ImportDate', label: headers.importDate },
    { name: 'StartHour', label: headers.startHour, options: { filter: false } },
    { name: 'EndHour', label: headers.endHour, options: { filter: false } },
    { name: 'userName', label: headers.user },
    { name: 'Mail', label: headers.mail },
    { name: 'Inserts', label: headers.inserts, options: { filter: false } },
    { name: 'Updates', label: headers.updates, options: { filter: false } },
    { name: 'Total', label: headers.total, options: { filter: false } },
    { name: 'actions', label: headers.actions,
      options: {
        filter: false,
        customBodyRender: (values, tableMeta) => {
          const currentIndex = tableMeta.currentTableData[tableMeta.rowIndex].index;
          return (<React.Fragment>
            <a
              onClick={() => handleOpenHistory(AMBestImports[currentIndex])}
              className="cell-table-link"
              style={{ whiteSpace: 'nowrap' }}
            ><Visibility/>{rowActions.view}</a>
            {AMBestImports[tableMeta.rowIndex].FileName? <a
              href={AMBestImports[currentIndex].fileUrl}
              target={'_blank'}
              className="cell-table-link"
              style={{ whiteSpace: 'nowrap' }}
              ><DescriptionIcon/>Document</a>
            : null}
          </React.Fragment>)
        },
        download: false
      },
    },
    { name: 'ID', label: 'ambestImportID', options: {display: 'excluded', filter: false, download: false} },
  ];

  const handleOpenHistory = (data) => {
    setCurrentImport(data);
    setShowHistory(true);
  }
  const handleCloseHistory = () => {
    setShowHistory(false);
    setCurrentImport({});
  };

  const onUpload = async (values) => {
    const {authToken } = props;
    try {
      const result = await actions.uploadFile(values,authToken);
      const {success} = result.data;
      if (success) {
        await Swal({
          type: 'success',
          title: 'AM Best Import',
          text: 'The file is being processed, this may take a few minutes.',
        });
      } else {
        await Swal({
          type: 'error',
          title: 'Error',
          text: 'Error processing the file',
        });
      }
      await _getImportsList();
      closeUploadModal();
    } catch (e) {
      console.error('Could not upload AM Best file')
    }
  }

  const _getImportsList = async () => {
    try {
      const response = await actions.getImports(authToken);
      if (response) {
        const { success, data } = response.data;
        if (success && data) {
          setAMBestImports(data);
        } else {
          setAMBestImports([])
        }
        setIsFetching(false);
      }
    } catch (e) {
      console.error('Could not load AM Best imports');
    }
  }

  const openUploadModal = () => {
    setShowUpload(true);
  }
  const closeUploadModal = () => {
    setShowUpload(false);
  }

  let options = Utils.MUITableDefaultConfig(columns);
  options = {
    ...options,
    download: true,
    customToolbar: () => <AMBestCustomToolbar handleUploadOpen={openUploadModal} />
  }

  return (
    <div className="admin-view-body">
      <MuiThemeProvider theme={vertikalTheme.getMuiTheme()}>
        <Dialog open={showHistory} fullWidth={true} maxWidth={'xl'} style={{'padding': 8}}>
          <AMBestImportHistory
            handleClose={handleCloseHistory}
            currentImport={currentImport}
            authToken={authToken}
            locale={locale.importHistory}
          />
        </Dialog>

        <Modal
          show={showUpload}
          onHide={closeUploadModal}
          className="add-item-modal add-entity-small">
          <Modal.Body>
            <AMBestImportModal
              close={closeUploadModal}
              // currentUploadData={this.state.currentUploadData}
              onSubmit={onUpload}
            />
          </Modal.Body>
        </Modal>

        {isFetching ? (
          <div className="spinner-wrapper">
            <div className="spinner"></div>
          </div>
        ) : (
          <MUIDataTable title={title} data={AMBestImports} columns={columns} options={options} />
        )}
      </MuiThemeProvider>
    </div>
  );
};

AMBest.propTypes = {
  authToken: PropTypes.string.isRequired,
  locale: PropTypes.object.isRequired,
};

export default AMBest;