import React, {Component} from "react";
import {bindActionCreators} from "redux";
import * as actions from "./actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import './updateExpiredFile.css';
import {Button, Card} from "react-bootstrap";
import {CardActions, CardHeader, CircularProgress, TextField} from "@material-ui/core";
import * as Alerts from "../alerts";


class UpdateExpiredFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: false,
            fileInfo: null,
            expirationDate: null
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.updateExpiredFile.saveSuccess && this.props.updateExpiredFile.saveSuccess !== prevProps.updateExpiredFile.saveSuccess) {
            Alerts.showInformationAlert(
                'Success',
                'File was successfully updated',
                'Accept',
                false,
                ()=>{}
            );
        }
        if (this.props.updateExpiredFile.saveError && this.props.updateExpiredFile.saveError !== prevProps.updateExpiredFile.saveError) {
            Alerts.showInformationAlert(
                'Error',
                this.props.updateExpiredFile.error,
                'Accept',
                false,
                ()=>{}
            );
        }
    }

    componentDidMount() {
        const fileInfoHash = this.props.match.params.fileInfoHash;
        if (fileInfoHash) {
            const fileInfo = JSON.parse(atob(fileInfoHash))
            this.setState({fileInfo});
        }
    }

    onChangeFile = () => {
        const fileInput = document.getElementById("new-file");
        if (fileInput && fileInput.files && fileInput.files.length > 0) {
            this.setState({selectedFile: true})
        }
    }

    onChangeExpirationDate = () => {
        const newFileExpDate = document.getElementById("new-file-exp-date")
        const expDate = newFileExpDate.value
        if(expDate){
            this.setState({expirationDate: expDate})
        }

    }

    saveFile = () => {
        if (!this.state.selectedFile) {
            return;
        }
        const fileInput = document.getElementById("new-file");
        
        this.props.actions.saveFile(fileInput.files[0], 
                                    this.state.expirationDate, 
                                    this.state.fileInfo.fileId, 
                                    this.state.fileInfo.hiringClientId, 
                                    this.state.fileInfo.subContractorId,
                                    this.state.fileInfo.savedFormId,
                                    this.state.fileInfo.formSectionFieldId)
    }

    renderCardContent = () => {
        if (this.props.updateExpiredFile.saveSuccess) {
            return <div>Thank you! You can safely leave this page</div>
        }
        const {formIsSaving} = this.props.updateExpiredFile;
        const {fileInfo} = this.state;
        if (!fileInfo || formIsSaving) {
            return <CircularProgress/>;
        }
        return (
            <React.Fragment>
                <CardHeader title={'Upload new file for ' + fileInfo.internalName}/>
                <div className="updateExpiredFile__fileInputContainer">
                    <input
                        id="new-file"
                        type="file"
                        onChange={this.onChangeFile}
                        placeholder={"Test"}/>
                </div>
                <br/>
                <div>
                    <TextField 
                        id="new-file-exp-date"
                        label="File Expiration Date"
                        type="date"
                        onChange={this.onChangeExpirationDate}
                        InputLabelProps={{
                          shrink: true,
                        }}/>
                </div>
                <CardActions style={{display: "flex", justifyContent: "center"}}>
                    <Button onClick={this.saveFile} disabled={!this.state.selectedFile}>Confirm</Button>
                </CardActions>
            </React.Fragment>)
    }

    render() {
        return (
            <div className="updateExpiredFileContainer">
                <Card style={{padding: 10}}>
                    {this.renderCardContent()}
                </Card>
            </div>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        updateExpiredFile: state.updateExpiredFile
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateExpiredFile));
