import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as commonActions from './../../common/actions';
import Utils from '../../../lib/utils';
import { bindActionCreators } from 'redux';

import RolAccess from './../../common/rolAccess';

class HolderInfo extends Component {

  renderButtonRoleEditHolder () {
    const { onEditProfile } = this.props;
    const component = (
      <div className="d-flex">            
        <div>
          <button onClick={onEditProfile} className="header-primary-button">
            {this.props.local.strings.holders.holdersProfile.editBtn}
          </button>
        </div>        
      </div>
    );
    return component;
  }
  
  renderButtonRoleCopyHolder () {
    const { onCopyHolder } = this.props;
    const component = (
      <div className="d-flex">            
        <div>
          <button onClick={onCopyHolder} className="header-primary-button">
            {this.props.local.strings.holders.holdersProfile.copyHolderBtn}
          </button>
        </div>        
      </div>
    );
    return component;
  }

  render() {
    const { 
      holderProfile, 
      holderArchived,
      holders
    } = this.props;

    if (holderProfile.fetching) {
      return (
        <div className="spinner-wrapper">
          <div className="spinner mb-4"></div>
        </div>
      );
    }

    if (holderProfile.errorHolderProfile) {
      return (
        <div className="d-flex justify-content-center">
          {holderProfile.errorHolderProfile}
        </div>
      );
    }

    const {
      name,
      address1,
      address2,
      contactName,
      city,
      StateName,
      country,
      phone,
      zipCode,
      logo,
      parentName,
      accountManagerName,
      intOfficeID,
      initialFee,
      initialCredits,
      addlFee,
      addlCredits,
      departmentName,
      archived,
      emailAliasAddress,
      emailAliasName,
      note
    } = this.props.holderProfile.profileData;

    let {
      parentHolderLabel,
      addressLabel,
      intOfficeLabel,
      contactNameLabel,
      contactPhoneLabel,
      accountManagerLabel,
      initialFeeAndCreditsLabel,
      addlFeeAndCreditsLabel,
      departmentLabel,
      emailAliasAddressLabel,
      emailAliasNameLabel,
      noteLabel
    } = this.props.local.strings.holders.holdersProfile;

    return (
      <div>
        <div className="section-header my-3">
          <h2 className="profile-view-header-title" style={{ display: 'inline' }}>{name}</h2>
          {archived && (
            <div className="alert alert-danger" style={{ float: 'right', border: '1px solid #721c24' }}>
              <span><i className="linear-icon-warning" /></span> ARCHIVED
            </div>
          )}
          <div className="d-flex">
          {(holders.holderChildren && holders.holderChildren.length > 0) && (
            <RolAccess
              masterTab="hiring_clients"
              sectionTab="edit_holder"
              component={() => this.renderButtonRoleCopyHolder()}>
            </RolAccess>
          )}
          {(!holderArchived) && (
            <RolAccess
              masterTab="hiring_clients"
              sectionTab="edit_holder"
              component={() => this.renderButtonRoleEditHolder()}>
            </RolAccess>
          )}
          </div>
        </div>
        <div className="row mb-3">
          {logo &&
            <div className="col-sm-3 d-flex align-items-center justify-content-center">
              <img src={`data:image/jpeg;base64,${logo}`} alt="Logo" className="holder-logo" />
            </div>
          }
          <div className="col-sm mt-3">
            <table className="table table-hover profile-view-info-table">
              <tbody>
                <tr>
                  <td>{parentHolderLabel}:</td>
                  <td>{parentName}</td>
                </tr>
                <tr>
                  <td>{addressLabel}:</td>
                  <td>
                    {address1}<br />
                    {address2 || <span>&nbsp;</span>}<br />
                    {city}, {StateName}, {zipCode}<br />
                    {country}<br />
                  </td>
                </tr>
                <tr>
                  <td>{intOfficeLabel}:</td>
                  <td>{intOfficeID}</td>
                </tr>
                <tr>
                  <td>{emailAliasAddressLabel}:</td>
                  <td>{emailAliasAddress}</td>
                </tr>
                <tr>
                  <td>{emailAliasNameLabel}:</td>
                  <td>{emailAliasName}</td>
                </tr>
                <tr>
                  <td>{noteLabel}:</td>
                  <td>{note}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-sm mt-3">
            <table className="table table-hover profile-view-info-table">
              <tbody>
                <tr>
                  <td>{contactNameLabel}:</td>
                  <td>{contactName}</td>
                </tr>
                <tr>
                  <td>{contactPhoneLabel}:</td>
                  <td>{Utils.formatPhoneNumber(phone)}</td>
                </tr>
                <tr>
                  <td>{departmentLabel}:</td>
                  <td>{departmentName}</td>
                </tr>
                <tr>
                  <td>{accountManagerLabel}:</td>
                  <td>{accountManagerName? JSON.parse(accountManagerName).map((manager,idx)=>{
                    return <span key={idx}>
                    {manager.label},
                    </span>
                  }): null}</td>
                </tr>
                <tr>
                  <td>{initialFeeAndCreditsLabel}:</td>
                  <td>{initialFee ? `${Utils.formatCurrency(initialFee)} / ` : ''}{initialCredits ? Utils.formatCurrency(initialCredits) : ''}</td>
                </tr>
                <tr>
                  <td>{addlFeeAndCreditsLabel}:</td>
                  <td>{addlFee ? `${Utils.formatCurrency(addlFee)} / ` : ''}{addlCredits ? Utils.formatCurrency(addlCredits) : ''}</td>
                </tr>
                <tr>
                  <td>Total number of active projects:</td>
                  <td>{this.props.amountProjects}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    local: state.localization,
    amountProjects: state.holdersProjects.totalAmountProjectArchive,
    login: state.login,
    holders: state.holders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    commonActions: bindActionCreators(commonActions, dispatch)
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(HolderInfo);

