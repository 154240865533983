import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../../actions';

import './changeVendorModal.css';

class ChangeVendorModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      vendor: this.props.vendor || '',
      processing: false
    }

    this.onChangeVendor = this.onChangeVendor.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  };

  onChangeVendor(e) {
    this.setState({vendor: e});
  }

  handleSubmit() {
    this.setState({ processing: true });
    const 
      { hcId, scId } = this.props,
      { vendor } = this.state;
    
    const payload = {
      hiringClientId: hcId,
      subcontractorId: scId,
      vendor
    }

    this.props.actions.changeVendorId(payload, (success) => {
      if (success) {
        this.props.successCallback(this.props.close);
      } else {
        this.setState({ processing: false });
      }
    });

  }

  render() {
    const {
      title,
      label,
      cancelBtn,
      saveBtn
    } = this.props.local.strings.scProfile.changeVendorModal;

    let displayVendor = this.state.vendor

    return (
      <div className="change-status-modal">
        <header className="change-status-modal-header">{title}</header>

        <div className="change-status-content container-fluid filter-fields">
          <div className="row">
            <div className="col-md-12">
              {
                this.state.processing?
                  <div className="spinner-wrapper">
                    <div className="spinner"></div>
                  </div>
                  :
                  <div className="statusContainer" >
                    <div>
                      <label>{label}:</label>
                    </div>
                      <input
                        value={displayVendor}
                        onChange={(e) => this.onChangeVendor(e.target.value)} 
                        type="text"
                      />
                  </div>
              }
            </div>
          </div>

          {
            !this.state.processing?
              <div className="text-right">
                <button className="bg-sky-blue-gradient bn" onClick={this.props.close}>{cancelBtn}</button>
                <button className="bg-sky-blue-gradient bn action-button" onClick={this.handleSubmit}>{saveBtn}</button>
              </div>
              :
              null
          }

        </div>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    sc: state.sc,
    local: state.localization,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeVendorModal);
