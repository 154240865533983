import React from "react";
import { reset } from "redux-form";
import "./FilterActions.css";

class ResetFormButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleResetFilters = this.handleResetFilters.bind(this);
    this.handleSaveFilters = this.handleSaveFilters.bind(this);
    this.handleDeleteSavedFilters = this.handleDeleteSavedFilters.bind(this);
  }

  handleResetFilters(event) {
    const { filtersResetCallback } = this.props;
    typeof filtersResetCallback === "function" && filtersResetCallback(event);
    this.props.dispatch(reset(this.props.formName));
  }

  handleSaveFilters() {
    const { filtersSaveCallback } = this.props;
    typeof filtersSaveCallback === "function" && filtersSaveCallback();
  }

  handleDeleteSavedFilters(e) {
    // this.handleResetFilters(e);
    const { filtersDeleteCallback } = this.props;
    typeof filtersDeleteCallback === "function" && filtersDeleteCallback();
  }

  render() {
    const className = this.props.className
      ? this.props.className
      : "filter-actions";
    return (
      <React.Fragment>
        <div className={className} style={{ flex: "auto" }}>
          <div className="list">
            <div className="list-item">
              <button
                className="list-view-nav-link nav-bn filter-button"
                type="submit"
              >
                SEARCH
              </button>
            </div>
            <div className="list-item">
              <button
                className="list-view-nav-link nav-bn filter-button"
                type="submit"
                onClick={this.handleResetFilters}
              >
                RESET
              </button>
            </div>
            {this.props.filtersSaveCallback && (
              <div className="list-item">
                <button
                  className="list-view-nav-link nav-bn filter-button"
                  type="button"
                  onClick={this.handleSaveFilters}
                >
                  SAVE FILTERS
                </button>
              </div>
            )}
            {this.props.filtersDeleteCallback && (
              <div className="list-item">
                <button
                  className={`list-view-nav-link nav-bn filter-button ${
                    this.props.disableDeleteFilters ? "disabled-button" : ""
                  }`}
                  disabled={this.props.disableDeleteFilters}
                  type="button"
                  onClick={this.handleDeleteSavedFilters}
                >
                  DELETE SAVED FILTERS
                </button>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ResetFormButton;
