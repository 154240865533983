import React from 'react';
import { Field, reduxForm, formValueSelector, change, touch } from 'redux-form';
import { AsyncPaginate} from 'react-select-async-paginate';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';

import * as applyActions from '../actions';

import Utils from '../../../../lib/utils';
import renderField from '../../../customInputs/renderField';

import validate from './validate';
import * as loadApplyOptions from './loadOptions';
import _ from 'lodash'
import * as regex from '../../../../lib/regex';


const DropDownStyle = { opacity: 0, height: 0 , position:"absolute", marginTop:-40}
const SelectAsyncOptions = ({ input, meta, dispatch,required, ...props }) => {
  const touchedError = meta.touched && meta.error;
  const name = input.name;
  const baseClassName = `${props.className || ''} select-${name}`;
  const errorClassName = `${touchedError ? `${baseClassName}-error` : ''}`;
  
  return (
    <React.Fragment>
      <AsyncPaginate
        {...input}
        {...props}
        openMenuOnClick={true}
        closeMenuOnScroll={true}
        cacheOptions={true}
        pageSize={10}
        additional={{ page: 1 }}
        classNamePrefix="vk"
        className={`${baseClassName} ${errorClassName}`}
        menuPlacement="auto"
        onFocus={(_) => { }}
        onBlur={() => meta.dispatch(touch('ApplyRegisterForm', name))}
        onChange={(newValue) => input.onChange(newValue)}
        captureMenuScroll={true}
        closeMenuOnSelect={true}
        debounceTimeout={300}
        loadOptionsOnMenuOpen={true}
      />
      {!props.disabled && (
            <input
              tabIndex={-1}
              autoComplete="off"
              name={name}
              style={DropDownStyle}
              required={required}
              value={input.value}
            />
            )}
      {touchedError && (
        <span className="select-error-message field-validation-message">
          {meta.error}
        </span>
      )}
    </React.Fragment>
  );
};

const SelectHC = ({input, meta, dispatch,required, ...props}) => {
  const touchedError = meta.touched && meta.error;
  const name = input.name;
  const baseClassName = `${props.className || ''} select-${name}`;
  const errorClassName = `${touchedError ? `${baseClassName}-error` : ''}`;
  return (
    <React.Fragment>
      <AsyncPaginate
        {...input}
        {...props}
        openMenuOnClick={true}
        closeMenuOnScroll={true}
        cacheOptions={false}
        pageSize={10}
        additional={{ page: 1 }}
        classNamePrefix="vk"
        className={`${baseClassName} ${errorClassName}`}
        menuPlacement="auto"
        onFocus={(_) => { }}
        onBlur={() => meta.dispatch(touch('ApplyRegisterForm', name))}
        onChange={(option) => input.onChange(option)}
        captureMenuScroll={true}
        closeMenuOnSelect={true}
        debounceTimeout={300}
        loadOptionsOnMenuOpen={true}
      />
      {!props.disabled && (
            <input
              tabIndex={-1}
              autoComplete="off"
              name={name}
              style={DropDownStyle}
              required={required}
              value={input.value}
            />
            )}
      {touchedError && (
        <span className="select-error-message field-validation-message">
          {meta.error}
        </span>
      )}
    </React.Fragment>
  );
};

const getHiringClientName = (name, defaultName) => name || defaultName;

class ApplyRegisterForm extends React.Component {

  normalizeTaxId = (value, previousValue, allValues) => {
    if (!value) {
      return value;
    } else if (get(allValues, 'country.value', 0) === 1) {
      const removedDashes = value.replace(/-/gim, '').trim();
      if (removedDashes.length <= 2) {
        return removedDashes;
      } else {
        return `${removedDashes.slice(0, 2)}-${removedDashes.slice(2)}`;
      }
    } else {
      return value.trim();
    }
  };

  resetStateValue = () => {
    this.props.dispatch(change('ApplyRegisterForm', 'state', ''));
    this.props.dispatch(change('ApplyRegisterForm', 'taxId', ''));
    document.querySelector(`[name="state"]`).focus();
  };

  updateTargetHiringClient = ({ label: hiringClientName, value: hiringClientId, entityType}) => {

    try {
      this.props.dispatch(applyActions.fetchApplyResources(hiringClientId));
      this.props.actions.setHiringClientName(hiringClientName);
      this.props.dispatch(change('ApplyRegisterForm', 'primaryTrade', ''));
      this.props.dispatch(change('ApplyRegisterForm', 'secondaryTrade', ''));
      this.props.dispatch(change('ApplyRegisterForm', 'tertiaryTrade', ''));
      this.props.dispatch(change('ApplyRegisterForm', 'quaternaryTrade', ''));
      this.props.dispatch(change('ApplyRegisterForm', 'quinaryTrade', ''));
    } catch(e) {
      console.error('could not update Target HC Id and Name...', e);
    }
  };

  onSubmit = (event) => {
    event.preventDefault();

    this.props.dispatch(touch(
      'ApplyRegisterForm',
      'targetHiringClient',
      'firstName',
      'lastName',
      'phone',
      'email',
      'companyName',
      'primaryTrade',
      'secondaryTrade',
      'tertiaryTrade',
      'quaternaryTrade',
      'quinaryTrade',
      'title',
      'address',
      'city',
      'state',
      'country',
      'taxId',
      'zipCode',
      'agree',
      'password',
      'passwordAgain'
    ));
    const email = _.get(this.props, 'currentForm.ApplyRegisterForm.values.email', null);
    const taxId = _.get(this.props, 'currentForm.ApplyRegisterForm.values.taxId', null);
    const country = _.get(this.props, 'currentForm.ApplyRegisterForm.values.country.value', null);

    if (!regex.EMAIL.test(email) || email.length > 80) {
      window.scroll({top:0})
    }
    if(country && country === 1){
      if (!regex.TAX_ID.test(taxId)) {
        window.scroll({top:700})
      }
    }else if(country && country === 34){
      if(!regex.CANADIAN_TAX_FORMAT.test(taxId)){
        window.scroll({top:700})
      }
    }
    if (this.props.valid) {
      this.props.onSubmit(this.props.currentForm.ApplyRegisterForm.values);
    }
  };

  render() {
    const {
      firstNameLabel,
      firstNamePlaceholder,
      lastNameLabel,
      lastNamePlaceholder,
      titleLabel,
      phoneLabel,
      phonePlaceholder,
      emailLabel,
      mainEmailPlaceholder,
      passwordLabel,
      retypePassword,
      passwordPlaceholder,
      titlesDropdownPlaceholder,
      titlesDropdownNoOptionsMessage,
      titlesDropdownLoadingMessage
    } = this.props.local.strings.register.userInformation;

    const {
      companyNameLabel,
      companyNamePlaceholder,
      tradeLabel,
      secondaryTradeLabel,
      tertiaryTradeLabel,
      quaternaryTradeLabel,
      quinaryTradeLabel,
      addressLabel,
      addressPlaceholder,
      cityLabel,
      cityPlaceholder,
      stateLabel,
      provinceLabel,
      statePlaceholder,
      zipCodeLabel,
      zipCodePlaceholder,
      countryLabel,
      taxIdLabel,
      taxIdPlaceholder,
      gstLabel,
      gstPlaceholder,
      mustAgree,
      userAgreementLinkText,
      before,
      agreeLabel,
      submitText,
      dropdownPlaceholder,
      dropdownNoOptionsMessage,
      dropdownLoadingMessage,
      companyNameSlug
    } = this.props.local.strings.register.companyProfile;

    const {
      hasWorkedWithHCLabel,
      commentsLabel,
      previousProjectLabel,
      defaultHCLabel,
      projectManagerNameLabel,
      projectManagerNamePlaceholder,
      projectManagerEmailLabel,
      projectManagerEmailPlaceholder,
      selectHCPlaceholder,
      selectHCNoOptionsMessage,
      selectHCLoadingMessage
    } = this.props.local.strings.reviewApplications.applyForm;

    const countrySelected = get(this.props, 'currentCountry.value', 0);
    return (
      <form onSubmit={this.onSubmit} role="form">
        <div className="container-fluid">
          {/*if hiringClientEntityType === 2 the HC it's PARENT */}
          {(this.props.isParent) && (
            <div className="row align-center">
              <div className="col-md-6 col-sm-12">
                <div className="register-field-wrapper">
                  <div className="register-select-wrapper">
                    <Field
                      name="targetHiringClient"
                      component={SelectHC}
                      required={true}
                      id="targetHiringClient"
                      placeholder={`--${selectHCPlaceholder}--`}
                      noOptionsMessage={() => selectHCNoOptionsMessage}
                      loadingMessage={() => selectHCLoadingMessage}
                      loadOptions={this.props.actions.loadHiringClientsGroupOptions(
                        this.props
                      )}
                      onChange={this.updateTargetHiringClient}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-md-6 col-sm-12 step-col-left">
              <div className="register-field-wrapper">
                <label htmlFor="firstName" className="required">{firstNameLabel}:</label>
                <Field
                  name="firstName"
                  type="text"
                  placeholder={firstNamePlaceholder}
                  required={true}
                  component={renderField} />
              </div>
            </div>
            <div className="col-md-6 col-sm-12 step-col-right">
              <div className="register-field-wrapper">
                <label htmlFor="lastName" className="required">{lastNameLabel}:</label>
                <Field
                  name="lastName"
                  type="text"
                  required={true}
                  placeholder={lastNamePlaceholder}
                  component={renderField} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12 step-col-left">
              <div className="register-field-wrapper">
                <label htmlFor="title" className="required">
                  {titleLabel}
                </label>
                <div className="register-select-wrapper">
                  <Field
                    name="title"
                    required={true} 
                    component={SelectAsyncOptions}
                    placeholder={titlesDropdownPlaceholder}
                    noOptionsMessage={() => titlesDropdownNoOptionsMessage}
                    loadingMessage={() => titlesDropdownLoadingMessage}
                    loadOptions={this.props.actions.loadTitles(this.props)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 step-col-right">
              <div className="register-field-wrapper">
                <label htmlFor="phone" className="required">{phoneLabel}:</label>
                <Field
                  name="phone"
                  type="text"
                  required={true}
                  placeholder={phonePlaceholder}
                  component={renderField} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12 step-col-left">
              <div className="register-field-wrapper">
                <label htmlFor="email" className="required">{emailLabel}:</label>
                <Field
                  name="email"
                  required={true}
                  type="text"
                  placeholder={mainEmailPlaceholder}
                  component={renderField} />
              </div>
            </div>
            <div className="col-md-6 col-sm-12 step-col-right">
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-sm-12 step-col-left">
              <div className="register-field-wrapper">
                <label htmlFor="password" className="required">{passwordLabel}:</label>
                <Field
                  name="password"
                  required={true}
                  type="password"
                  placeholder={passwordPlaceholder}
                  component={renderField} />
              </div>
            </div>
            <div className="col-md-6 col-sm-12 step-col-right">
              <div className="register-field-wrapper">
                <label htmlFor="passwordAgain" className="required">{retypePassword}:</label>
                <Field
                  name="passwordAgain"
                  required={true}
                  type="password"
                  placeholder={passwordPlaceholder}
                  component={renderField} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12 step-col-left">
              <div className="register-field-wrapper">
                <label htmlFor="companyName" className="required">{companyNameLabel}:</label>
                <Field
                  name="companyName"
                  required={true}
                  type="text"
                  placeholder={companyNamePlaceholder}
                  component={renderField} />
              </div>
              <p>{companyNameSlug}</p>
            </div>
            <div className="col-md-6 col-sm-12 step-col-right">
              <div className="register-field-wrapper">
                <label htmlFor="primaryTrade" className="required">
                  {tradeLabel}
                </label>
                <div className="register-select-wrapper">
                  <Field
                    key={'primaryTrade-'+this.props.apply.hiringClientId}
                    name="primaryTrade"
                    component={SelectAsyncOptions}
                    placeholder={dropdownPlaceholder}
                    required={true}
                    noOptionsMessage={() => dropdownNoOptionsMessage}
                    loadingMessage={() => dropdownLoadingMessage}
                    loadOptions={this.props.actions.loadTrades(this.props)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12 step-col-left">
              <div className="register-field-wrapper">
                <label htmlFor="secondaryTrade">
                  {secondaryTradeLabel}
                </label>
                <div className="register-select-wrapper">
                  <Field
                    key={'secondaryTrade-'+this.props.apply.hiringClientId}
                    name="secondaryTrade"
                    component={SelectAsyncOptions}
                    placeholder={dropdownPlaceholder}
                    noOptionsMessage={() => dropdownNoOptionsMessage}
                    loadingMessage={() => dropdownLoadingMessage}
                    loadOptions={this.props.actions.loadTrades(this.props)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 step-col-right">
              <div className="register-field-wrapper">
                <label htmlFor="tertiaryTrade">
                  {tertiaryTradeLabel}
                </label>
                <div className="register-select-wrapper">
                  <Field
                    key={'tertiaryTrade-'+this.props.apply.hiringClientId}
                    name="tertiaryTrade"
                    component={SelectAsyncOptions}
                    placeholder={dropdownPlaceholder}
                    noOptionsMessage={() => dropdownNoOptionsMessage}
                    loadingMessage={() => dropdownLoadingMessage}
                    loadOptions={this.props.actions.loadTrades(this.props)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12 step-col-left">
              <div className="wiz-field register-field-wrapper">
                <label htmlFor="quaternaryTrade">
                  {quaternaryTradeLabel}
                </label>
                <div className="register-select-wrapper">
                  <Field
                    key={'quaternaryTrade-'+this.props.apply.hiringClientId}
                    name="quaternaryTrade"
                    component={SelectAsyncOptions}
                    placeholder={dropdownPlaceholder}
                    noOptionsMessage={() => dropdownNoOptionsMessage}
                    loadingMessage={() => dropdownLoadingMessage}
                    loadOptions={this.props.actions.loadTrades(this.props)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 step-col-right">
              <div className="wiz-field register-field-wrapper">
                <label htmlFor="quinaryTrade">
                  {quinaryTradeLabel}
                </label>
                <div className="register-select-wrapper">
                  <Field
                    key={'quinaryTrade-'+this.props.apply.hiringClientId}
                    name="quinaryTrade"
                    component={SelectAsyncOptions}
                    placeholder={dropdownPlaceholder}
                    noOptionsMessage={() => dropdownNoOptionsMessage}
                    loadingMessage={() => dropdownLoadingMessage}
                    loadOptions={this.props.actions.loadTrades(this.props)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12 step-col-left">
              <div className="register-field-wrapper state-field">
                <label className="required">{stateLabel}:</label>
                  <div className="register-select-wrapper">
                    <Field
                      name="state"
                      required={true}
                      component={SelectAsyncOptions}
                      placeholder={dropdownPlaceholder}
                      noOptionsMessage={() => dropdownNoOptionsMessage}
                      loadingMessage={() => dropdownLoadingMessage}
                      cacheUniqs={[countrySelected]}
                      loadOptions={this.props.actions.loadStates(
                        this.props,
                        countrySelected
                      )}
                    />
                  </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 step-col-right">
              <div className="register-field-wrapper">
                <label htmlFor="country" className="required">{countryLabel}:</label>
                <div className="select-wrapper">
                  <Field
                    name="country"
                    onChange={this.resetStateValue}
                    required={true}
                    blurInputOnSelect={true}
                    openMenuOnFocus={true}
                    component={SelectAsyncOptions}
                    placeholder={dropdownPlaceholder}
                    noOptionsMessage={() => dropdownNoOptionsMessage}
                    loadingMessage={() => dropdownLoadingMessage}
                    loadOptions={this.props.actions.loadCountries(this.props)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-sm-12 step-col-left">
              <div className="register-field-wrapper">
                <label htmlFor="city" className="required">{cityLabel}:</label>
                <Field
                  name="city"
                  placeholder={cityPlaceholder}
                  required={true}
                  type="text"
                  component={renderField} />
              </div>
            </div>
            <div className="col-md-6 col-sm-12 step-col-right">
              <div className="register-field-wrapper">
                <label htmlFor="address" className="required">{addressLabel}:</label>
                <Field
                  name="address"
                  type="text"
                  placeholder={addressPlaceholder}
                  required={true} 
                  component={renderField} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-sm-12 step-col-left">
              <div className="register-field-wrapper">
                <label htmlFor="zipCode" className="required">{zipCodeLabel}:</label>
                <Field
                  name="zipCode"
                  required={true}
                  placeholder={zipCodePlaceholder}
                  type="text"
                  component={renderField} />
              </div>
            </div>
            <div className="col-md-6 col-sm-12 step-col-right">
              <div className="register-field-wrapper">
                <label htmlFor="taxId" className="required">
                  {countrySelected === 34 ? gstLabel : taxIdLabel}
                </label>
                <Field
                  name="taxId"
                  type="text"
                  required={true}
                  component={renderField}
                  placeholder={countrySelected === 1 ? taxIdPlaceholder : countrySelected === 34 ? gstPlaceholder : ''}
                  normalize={this.normalizeTaxId}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-sm-12 step-col-left">
              <div className="register-field-wrapper agreement-container">
                <p className="agreement-label">
                  {mustAgree}<a href="https://asset.prequalusa.com/general/prequal-eula-2015.pdf" target="_blank" rel="noopener noreferrer"> {userAgreementLinkText} </a>{before}
                </p>
                <label htmlFor="agree">
                  <Field
                    id="agree"
                    name="agree"
                    type="checkbox"
                    component={renderField} />
                  <span className="check"></span>
                  <span className="label">{agreeLabel}</span>
                </label>
              </div>
            </div>
          </div>
          <div className="step-button">
            <button className="bn bg-blue-gradient" disabled={this.props.formSubmitting} type="submit">{submitText}</button>
          </div>
        </div>

      </form>
    );
  }
};

ApplyRegisterForm = reduxForm({
  form: 'ApplyRegisterForm',
  validate,
  touchOnBlur: true,
  touchOnChange: true,
  enableReinitialize: true
})(ApplyRegisterForm);

const mapStateToProps = (state, ownProps) => {
  const { applyPayload } = state.apply;
  const selector = formValueSelector('ApplyRegisterForm');

  const { getValuesOnlyIfNotEmpty: onlyValues, toOption } = Utils;
  const fromState = onlyValues(state, 'apply.applyPayload');
  const fromProps = onlyValues(ownProps, 'applyPayload');
  const payload = fromState || fromProps || {};
  const hiringClientId = state.apply.hiringClientId || 
    ownProps.hiringClientId;
  const hiringClientName = state.apply.hiringClientName || 
    ownProps.hiringClientName;

  return {
    apply: state.apply,
    local: state.localization,
    currentForm: state.form,
    hiringClientId: hiringClientId ? hiringClientId : null,
    hiringClientName: hiringClientName || null,
    currentCountry: selector(state, 'country') || '',
    initialValues: {
      targetHiringClient: toOption(payload.targetHiringClientId, payload.targetHiringClientName),
      firstName: applyPayload.firstName || '',
      lastName: applyPayload.lastName || '',
      phone: applyPayload.phone || '',
      email: applyPayload.email || '',
      companyName: applyPayload.companyName || '',
      
      title:null,
      primaryTrade: toOption(payload.primaryTradeId, payload.primaryTradeName),
      secondaryTrade: toOption(payload.secondaryTradeId, payload.secondaryTradeName),
      tertiaryTrade: toOption(payload.tertiaryTradeId, payload.tertiaryTradeName),
      quaternaryTrade: toOption(payload.quaternaryTradeId, payload.quaternaryTradeName),
      quinaryTrade: toOption(payload.quinaryTradeId, payload.quinaryTradeName),
      
      // HOT FIX FOR VE-686
      // country: toOption(payload.countryId, payload.countryName),
      country: toOption(1, "United States"),

      address: applyPayload.address || '',
      city: applyPayload.city || '',
      state: applyPayload.state || '',
      zipCode: applyPayload.zipCode || '',
      taxId: applyPayload.taxId || '',
      agree: applyPayload.agree || false,
      password: applyPayload.password || '',
      passwordAgain: applyPayload.password || ''
    }
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      ...bindActionCreators(applyActions, dispatch),
      ...loadApplyOptions
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplyRegisterForm));
