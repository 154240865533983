import React, { useEffect, useState } from "react";
import { Field, reduxForm, touch, change } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonActions from "../../../../common/actions";
import { AsyncPaginate } from "react-select-async-paginate";
import * as loadApplyOptions from "../../../../common/countyDropdownActions/loadOptions";
import * as applyActions from "../../../../common/countyDropdownActions/actions";
import _ from "lodash";
import "./CountryDropdown.css";

const SelectAsyncOptions = ({ input, meta, dispatch, ...props }) => {
  const touchedError = meta.touched && meta.error;
  const name = input.name;
  const baseClassName = `${props.className || ""} select-${name}`;
  const errorClassName = `${touchedError ? `${baseClassName}-error` : ""}`;

  return (
    <React.Fragment>
      <AsyncPaginate
        {...input}
        {...props}
        openMenuOnClick={true}
        closeMenuOnScroll={true}
        cacheOptions={true}
        pageSize={10}
        additional={{ page: 1 }}
        classNamePrefix="vk"
        className={`${baseClassName} ${errorClassName}`}
        menuPlacement="auto"
        onFocus={(_) => {}}
        onBlur={() => meta.dispatch(touch("FilterProjects", name))}
        onChange={(newValue) => input.onChange(newValue)}
        captureMenuScroll={true}
        closeMenuOnSelect={true}
        debounceTimeout={300}
        loadOptionsOnMenuOpen={true}
      />
      {touchedError && <span className="select-error-message field-validation-message">{meta.error}</span>}
    </React.Fragment>
  );
};

let CountryDropdown = (props) => {
  const [selectedCountry, setSelectedCountry] = useState(props.countryId);
  const [selectedState, setSelectedState] = useState(props.stateId);
  const [selectedStateLabel, setSelectedStateLabel] = useState(props.stateLabel);
  const [selectedCountryLabel, setSelectedCountryLabel] = useState(props.countryLabel);

  useEffect(() => {
    if (props.countryId) {
      props.dispatch(change("CountryDropdown", "country", { value: props.countryId, label: props.countryLabel }));
    }
    if (props.stateId) {
      props.dispatch(change("CountryDropdown", "state", { value: props.stateId, label: props.stateLabel }));
    }
  }, []);

  const handleStateChange = (data) => {
    setSelectedState(data.value);
    setSelectedStateLabel(data.label);
  };

  useEffect(() => {
    props.handleSubmit(selectedState, selectedStateLabel, selectedCountry, selectedCountryLabel);
  }, [selectedCountry, selectedState]);

  const handleCountryChange = (data) => {
    if (selectedCountry != data.value) {
      props.dispatch(change("CountryDropdown", "state", { value: "", label: "Select State" }));
    }
    setSelectedCountryLabel(data.label);
    setSelectedCountry(data.value);
  };

  const countryId = _.get(props, "currentForm.CountryDropdown.values.country.value", null);

  return (
    <form className="country-state-filter">
      <div className="">
        <div className="row">
          <div className="col-6 country">
            <div className="country-filter-field">
              <label htmlFor="country">Country </label>
              <Field
                name="country"
                component={SelectAsyncOptions}
                placeholder="Select Country"
                onChange={(values) => handleCountryChange(values)}
                loadOptions={props.actions.loadCountries(props)}
                className="tags-input"
              />
            </div>
          </div>
          {countryId || props.stateId ? (
            <div className="col-6 state">
              <div className="country-filter-field">
                <label htmlFor="state">State</label>
                <Field
                  name="state"
                  component={SelectAsyncOptions}
                  placeholder="Select State"
                  cacheUniqs={[selectedCountry]}
                  onChange={handleStateChange}
                  loadOptions={props.actions.loadStates(props, selectedCountry)}
                  className="tags-input"
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </form>
  );
};

CountryDropdown = reduxForm({
  form: "CountryDropdown",
  initialValues: {
    country: 1,
  },
})(CountryDropdown);

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      ...bindActionCreators(applyActions, dispatch),
      ...loadApplyOptions,
    },
    commonActions: bindActionCreators(commonActions, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    currentForm: state.form,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CountryDropdown);
