import Api from '../../../../lib/api';

export const fetchDataEntryRecords = async (authToken) => {
  const urlQuery = `cf/processing/dataEntryRecords`;
  try {
    const response = await Api.get(urlQuery, authToken);
    const { success, data } = response.data;
    let list = [];

    if (success && data) list = data;

    return list;
  } catch (e) {
    console.error('Could not fetch AM Best imports');
  }
}