import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import LocationForm from './form';
import * as actions from '../../actions';
import Utils from '../../../../lib/utils'
import './locationeditor.css';
import * as applyActions from '../../../reviewApplications/apply/actions';
import * as reviewApplicationsActions from '../../../reviewApplications/actions';


class LocationEditorModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editingNoteId: false,
    }

    this.saveLocation = this.saveLocation.bind(this);
    this.deleteLocation = this.deleteLocation.bind(this);
  };
  saveLocation(values) {
      const countriesWithSubsectors = new Set([1,34,'1','34'])
      const Address = values.Address
      const City = values.City
      const StateId = values.StateId && values.StateId.value
      const ZipCode = values.ZipCode
      const CountryID = values.CountryID && values.CountryID.value 
      const Comments = values.Comments ? values.Comments : null
      const Active = values.Active ? 1 : 0
      const Primary = values.Primary ? 1 : 0
      const Phone = values.Phone ? values.Phone : null
      const Fax = values.Fax ? values.Fax : null
      const ContactName = values.ContactName ? values.ContactName : null
      const ContactEmail = values.ContactEmail ? values.ContactEmail : null
      const HiringClientId = values.hiringClient ? values.hiringClient : null

    const payload = {
      LocationId: this.props.locationId ? this.props.locationId : 0,
      Address,
      City,
      StateId,
      ZipCode,
      CountryID,
      Comments,
      Active,
      Primary,
      Phone,
      Fax,
      ContactName,
      ContactEmail,
      userId: this.props.login && this.props.login.userId ? this.props.login.userId : null,
      SubcontractorID: this.props.subcontractorId,
      HiringClientId: HiringClientId
    };

    if ((this.props.modaltype && this.props.modaltype != 'edit' || payload.LocationId) && StateId != 'prohibited') {
      payload.modaltype = this.props.modaltype
      this.props.actions.saveLocation(payload, () => {
        this.props.callback() // calls scProfileActions.fetchLocations() with default query to refresh the page with all existing locations
      });
    } else {
      if (StateId = 'prohibited') {
        this.props.actions.setPrimaryMessage(`Please fill out the State your Company is located in`);
      }
    }

    this.props.closeAndRefresh();

  }

  deleteLocation(id) {
    const payload = {
      id: id,
      userId: (this.props.login && this.props.login.userId) ? this.props.login.userId : null,
    };
    this.props.actions.deleteLocation(payload)
    this.props.closeAndRefresh();
  }

  render() {
    const {
      titleCreate,
      titleEdit
    } = this.props.local.strings.scProfile.ChangeLocationsModal.modal;

    const title = this.props.location ? titleEdit : titleCreate;

    return (
      <div>
        <header>
          <div className="noteEditorTitle">{title}</div>
        </header>
        <LocationForm 
          onSubmit={this.saveLocation}
          onDelete={this.deleteLocation}
          provAndTerr={this.props.provAndTerr}
          states={this.props.states}
          dismiss={this.props.close} 
          location={this.props.location} 
          scId={this.props.subcontractorId}
          isPQuser={this.props.modaltype == 'edit' ? this.props.isPQuser : null} 
        />
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    scProfile: state.SCProfile,
    local: state.localization,
    login: state.login,
    isPQuser: state.login.profile.Role.IsPrequalRole
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    commonActions: bindActionCreators(applyActions, dispatch),
    reviewApplicationsActions: bindActionCreators(reviewApplicationsActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationEditorModal);
