import * as types from './types';

export const setApplyErrorMessage = (error) => {
  return {
    type: types.SET_APPLY_ERROR_MESSAGE,
    error
  };
};










