import React from 'react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react'

// This needs to be define in global space
posthog.init(
  process.env.REACT_APP_PUBLIC_POSTHOG_KEY,
  {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    disable_session_recording: process.env.REACT_APP_PUBLIC_POSTHOG_DISABLE_SESSION_RECORDING === 'true',
  }
);

const PostHogProviderWrapper = ({children}) => {
    return <PostHogProvider client={posthog}>
        {children}
    </PostHogProvider>
}

export default PostHogProviderWrapper;