import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormControl } from 'react-bootstrap';

import * as actions from '../../actions';
import * as regex from '../../../../lib/regex';

import './changeTaxIdModal.css';
import {CANADIAN_TAX_FORMAT} from "../../../../lib/regex";

class ChangeTaxIdModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      taxId: this.props.currentTaxId || '',
      incorrectTaxId: false,
      selectedCountryId: this.props.countryId || 1,
      processing: false,
      errorWhileMerging: false,
    };

    this.onChangeTaxId = this.onChangeTaxId.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.onSave = this.onSave.bind(this);
    this.shouldDisplayLoading = this.shouldDisplayLoading.bind(this);
    this.normalizeTaxId = this.normalizeTaxId.bind(this);
    this.renderTaxIdField = this.renderTaxIdField.bind(this);
    this.validateTaxId = this.validateTaxId.bind(this);
    this.renderErrorRepeatedTaxId = this.renderErrorRepeatedTaxId.bind(this);
    this.onMergeSubcontractors = this.onMergeSubcontractors.bind(this);
    this.renderErrorMergingSubcontractors = this.renderErrorMergingSubcontractors.bind(this);
  }

  componentDidMount() {
    this.props.actions.fetchStatesAndCountriesForRenderSelect();
  }

  onChangeTaxId(newTaxId) {
    const normalized = this.normalizeTaxId(newTaxId);
    this.validateTaxId(normalized);
    this.setState({ taxId: normalized });
  }

  onChangeCountry(newCountryId) {
    this.setState({ selectedCountryId: newCountryId }, () => {
      this.validateTaxId(this.state.taxId);
    });
  }

  normalizeTaxId(value, previousValue, allValues) {
    if (!value) return value;

    if (Number(this.state.selectedCountryId) === 1) {
      const removedDashes = value.replace(/-/, '').trim();

      if (removedDashes.length <= 2) {
        return removedDashes;
      }

      return `${removedDashes.slice(0, 2)}-${removedDashes.slice(2)}`;
    } else if(Number(this.state.selectedCountryId) === 34) {
      const removedDashes = value.replace(/RT/, '').trim();

      if (removedDashes.length <= 9) {
        return removedDashes;
      }

      return `${removedDashes.slice(0, 9)}RT${removedDashes.slice(9)}`;
    }

    return value.trim();
  }

  validateTaxId(value) {
    const { selectedCountryId } = this.state;
    let valid = true;
    if (!value || value === '' || value.length > 16) {
      valid = false;
    } else if (Number(selectedCountryId) === 1) {
      // If country is USA validate the tax id format
      if (!regex.TAX_ID.test(value)) {
        valid = false;
      }
    } else if (Number(selectedCountryId) === 34) {
      // If country is USA validate the tax id format
      if (!regex.CANADIAN_TAX_FORMAT.test(value)) {
        valid = false;
      }
    } else if (Number(selectedCountryId) != 1) {
      // For any other country, just check that this is a number
      if (Number.isNaN(Number(value))) {
        valid = false;
      }
    }
    this.setState({ incorrectTaxId: !valid });
  }

  onSave() {
    const { scId } = this.props;
    const { taxId, selectedCountryId, incorrectTaxId } = this.state;

    if (incorrectTaxId) {
      return;
    }

    this.setState({ processing: true });

    const payload = {
      subcontractorId: scId,
      taxId: taxId,
      countryCode: selectedCountryId,
    };

    this.props.actions.changeTaxId(payload, (success) => {
      if (success) {
        this.props.successCallback(this.props.close);
      } else {
        this.setState({ processing: false });
      }
    });
  }

  onMergeSubcontractors() {
    const { scId, hiringClientId, existingSubcontractorId } = this.props;
    this.setState({ errorWhileMerging: false });

    const payload = {
      requestingSubcontractorId: scId,
      hiringClientId,
      targetSubcontractorId: existingSubcontractorId,
    };

    this.props.actions.mergeSubcontractors(payload, (success) => {
      if (success) {
        this.props.successCallback(this.props.close);
      } else {
        this.setState({ processing: false, errorWhileMerging: true });
      }
    });
  }

  shouldDisplayLoading() {
    return this.state.processing || this.props.isMergingSubcontractors || !this.props.countries;
  }

  renderTaxIdField() {
    const { labelTaxId } = this.props.local.strings.scProfile.changeTaxIdModal;
    const { incorrectTaxId, taxId, selectedCountryId } = this.state;
    const { existingSubcontractorId } = this.props;
    return (
      <React.Fragment>
        <div>
          <label>{labelTaxId}</label>
        </div>
        <div className="form-group">
          <FormControl
            onChange={(e) => this.onChangeTaxId(e.target.value)}
            placeholder={`${selectedCountryId === 1 ? '00-0000000' : ''}`}
            type="text"
            value={taxId}
            isInvalid={incorrectTaxId}
            disabled={existingSubcontractorId}
          />
          <FormControl.Feedback type="invalid">Invalid value</FormControl.Feedback>
        </div>
      </React.Fragment>
    );
  }

  renderErrorRepeatedTaxId() {
    const { taxIdExists, existingSubcontractorName } = this.props;
    if (taxIdExists) {
      return (
        <React.Fragment>
          <div className="alert alert-warning">
            WARNING: The given Tax ID already exists in our system, it belongs to {existingSubcontractorName}. If you
            choose to proceed, we will merge the subcontractors. Be aware, this process <b>cannot be reverted</b>.
          </div>
          <button
            className="btn btn-warning"
            style={{ marginBottom: 10 }}
            onClick={() => {
              this.onMergeSubcontractors();
            }}
          >
            Merge subcontractors
          </button>
        </React.Fragment>
      );
    }
    return <div></div>;
  }

  renderErrorMergingSubcontractors() {
    const { errorWhileMerging } = this.state;
    if (errorWhileMerging) {
      return (
        <React.Fragment>
          <div className="alert alert-danger">
            ERROR: There was a problem trying to merge the subcontractors. Please contact support or try again later.
          </div>
        </React.Fragment>
      );
    }
    return <div></div>;
  }

  render() {
    const { title, labelCountry, cancelBtn, saveBtn } = this.props.local.strings.scProfile.changeTaxIdModal;
    const { countries, existingSubcontractorId } = this.props;
    const { selectedCountryId } = this.state;
    return (
      <div className="change-tax-id-modal">
        <header className="change-tax-id-modal-header">{title}</header>

        <div className="change-tax-id-content container-fluid filter-fields">
          <div className="row">
            <div className="col-md-12">
              {this.shouldDisplayLoading() ? (
                <div className="spinner-wrapper">
                  <div className="spinner"></div>
                </div>
              ) : (
                <div className="tax-id-form-container">
                  {this.renderErrorMergingSubcontractors()}
                  {this.renderErrorRepeatedTaxId()}
                  <div>
                    <label>{labelCountry}</label>
                  </div>
                  <select
                    className="custom-select"
                    value={selectedCountryId}
                    disabled={existingSubcontractorId}
                    onChange={(e) => {
                      this.onChangeCountry(e.target.value);
                    }}
                  >
                    {countries.map((item, idx) => (
                      <option key={idx} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {this.renderTaxIdField()}
                </div>
              )}
            </div>
          </div>

          {!this.shouldDisplayLoading() ? (
            <div className="text-right">
              <button
                className="bg-sky-blue-gradient bn"
                onClick={() => {
                  this.props.actions.setTaxIdExists(false);
                  this.props.close();
                }}
              >
                {cancelBtn}
              </button>
              {!existingSubcontractorId && (
                <button className="bg-sky-blue-gradient bn action-button" onClick={this.onSave}>
                  {saveBtn}
                </button>
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    sc: state.sc,
    local: state.localization,
    countries: state.SCProfile.countries,
    taxIdExists: state.SCProfile.taxIdExists,
    isMergingSubcontractors: state.SCProfile.isMergingSubcontractors,
    mergeSubcontractorsSucceeded: state.SCProfile.mergeSubcontractorsSucceeded,
    existingSubcontractorId: state.SCProfile.existingSubcontractorId,
    existingSubcontractorName: state.SCProfile.existingSubcontractorName,
    hiringClientId: state.SCProfile.hcId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeTaxIdModal);
