import moment from "moment";
import {connect} from 'react-redux';
import {Form} from "react-bootstrap";
import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import {Col, Modal} from 'react-bootstrap';
import Swal from "sweetalert2";
import { isArray } from "lodash";

import Utils from '../../../lib/utils';
import PTable from '../../common/ptable';
import RolAccess from '../../common/rolAccess';

import Filter from './filter';
import Totalizers from "./totalizers";
import * as actions from './actions';
import * as userActions from '../../users/actions';
import * as commonActions from '../../common/actions';
import * as loginActions from '../../auth/login/actions';
import * as contactActions from '../contacts/actions';
import * as documentsActions from '../documents/actions';
import * as departmentActions from "../settings/departments/actions";
import * as projectsInsuredsActions from '../project-insureds/actions';
import CFTaskEditorModal from "../modals/CFTaskEditorModal";
import CFTaskDetailModal from "../modals/CFTaskDetailModal";
import ProcessingModal from "../modals/processingModal";
import {
  CERTFOCUS_TASK_FINISHED_ID,
} from '../../../lib/appConstants';
import * as filterActions from '../filterbox/actions'
import './Tasks.css';
import DataTable from "../../common/table";

class CFTasks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {
        pageNumber: 1,
      },
      filterBox: {
        searchTerm: '',
        assignedToUserId: this.props.login.userId,
        assignedToRoleId: '',
        dateDue: moment().format('YYYY-MM-DD'),
        dateFrom: moment().format('YYYY-MM-DD'),
        ContactTypeId: '',
        holderId: this.props.holderId || '',
        insuredId: this.props.insuredId || '',
        projectId: this.props.projectId || '',
      },
      tableOrderActive: 'tasksPriorityId',
      order: {
        dateDue: 'asc',
        assignedToUser: 'asc',
        assignedToRole: 'asc',
        modifyByUser: 'asc',
        tasksPriorityId: 'asc',
        holderName: 'asc',
        projectNumber: 'asc',
        projectName: 'asc',
        type: 'asc',
        modifiedDate: 'desc',
        dueDate: 'desc',
        insuredName: 'desc',
      },
      showFilterBox: true,
      selectedNoteTask: null,
      showNoteEditor: false,
      modal: '',
      currentTask: {},
      currentTaskHistory: [],
      currentProjectHistory: [],
      showTaskDetail: false,
      document: {},
      showProcessingModal: false,
      canSeeUsers: true,
    };

    const {fromProject, fromInsuredTab, fromHolderTab} = this.props;
    if (!fromProject && !fromInsuredTab && !fromHolderTab) {
      this.state.filterBox.notStatusId = CERTFOCUS_TASK_FINISHED_ID
    } else {
      this.state.filterBox.assignedToUserId = ''
    }
  }

  async componentDidMount() {
    let preQuery = Utils.getFetchQuery(this.state.tableOrderActive, 1, 'ASC');
    const query = Utils.addSearchFiltersToQuery(preQuery, this.state.filterBox);
    this.props.actions.fetchRoles();
    this.props.actions.fetchUsersWithTask();
    this.props.departmentActions.getDepartments({
      orderBy: 'name',
      orderDirection: 'ASC',
      archived: 0,
    });
  }

  clickOnColumnHeader = (e, field) => {
    if (field === 'bulkAction' || field === 'done' || field === 'actions' || field === 'description') return;
    // get base query
    const pageNumber = this.state.filter.pageNumber;
    const orderDirection = this.state.order[field] === 'asc' ? 'DESC' : 'ASC'

    // save new active tab and order
    let newState = {
      tableOrderActive: field,
      order: {
        tasksPriorityId: field === 'tasksPriorityId' ? 'asc' : 'desc',
        assignedToUser: field === 'assignedToUser' ? 'asc' : 'desc',
        assignedToRole: field === 'assignedToRole' ? 'asc' : 'desc',
        modifyByUser: field === 'modifyByUser' ? 'asc' : 'desc',
        dueDate: field === 'dueDate' ? 'asc' : 'desc',
        modifiedDate: field === 'modifiedDate' ? 'asc' : 'desc',
        holderName: field === 'holderName' ? 'asc' : 'desc',
        projectNumber: field === 'projectNumber' ? 'asc' : 'desc',
        projectName: field === 'projectName' ? 'asc' : 'desc',
        type: field === 'type' ? 'asc' : 'desc',
        insuredName: field === 'insuredName' ? 'asc' : 'desc',
      },
    };
    newState.order[field] = this.state.order[field] === 'asc' ? 'desc' : 'asc';
    this.setState(newState);

    let preQuery = Utils.getFetchQuery(field, pageNumber, orderDirection);
    const query = Utils.addSearchFiltersToQuery(preQuery, this.state.filterBox);

    // const query = this.addId(preQuery);

    if(this.props.fromHolderTab) {
      this.props.actions.fetchTasksFromHolder(query);
    } else {
      this.props.actions.fetchTasks(query);
    }
  }

  setPageFilter = (e, pageNumber, force) => {
    if (force || this.state.filter.pageNumber !== pageNumber) {
      // get base query
      const field = this.state.tableOrderActive;
      const orderDirection = this.state.order[field] === 'asc' ? 'ASC' : 'DESC'
      let preQuery = Utils.getFetchQuery(field, pageNumber, orderDirection);
      // add search filters
      const query = Utils.addSearchFiltersToQuery(preQuery, this.state.filterBox);

      // const query = this.addId(preQuery);
      if(this.props.fromHolderTab) {
        this.props.actions.fetchTasksFromHolder(query);
      } else {
        this.props.actions.fetchTasks(query);
      }

      // Save page number
      this.setState({
        filter: {
          pageNumber
        }
      });
    }
  }

  checkCanSeeUsersList = async () => {
    if (!this.props.login.profile.rolesFunctionsPermissions) {
      //fetch profile
      await this.props.loginActions.getProfile();
    }
    const canSeeAllUsers = (Utils.canPerformFunction('can see all tasks', this.props.login.profile.rolesFunctionsPermissions)) ? true : false;
    return canSeeAllUsers;
  }

  submitFilterForm = async (values) => {
    // Get base query
    const field = this.state.tableOrderActive;
    const pageNumber = 1;
    const orderDirection = this.state.order[field] === 'asc' ? 'ASC' : 'DESC';
    let preQuery = Utils.getFetchQuery(field, pageNumber, orderDirection);

    const {  fromProject, fromInsuredTab, fromHolderTab} = this.props;

    if (!values.dateFrom) values.dateFrom = moment().format('YYYY-MM-DD');

    if ((values.dateFrom && !values.dateTo) || (!values.dateFrom)) {
      values.dateDue = values.dateFrom;
    }

    if (values.dateTo && !values.dateFrom) values.dateFrom = values.dateTo;

    if (values.dateFrom && values.dateTo) {
      const date1 = moment(values.dateFrom).format('YYYY-MM-DD');
      const date2 = moment(values.dateTo).format('YYYY-MM-DD');
      values.dateDue = '';

      if (!moment(date2).isAfter(date1, 'day')) {
        values.dateFrom = date2;
        values.dateTo = date1;
      }
    }

    const filterBox = {
      searchTerm: values.keywords || '',
      assignedToUserId: values.hasOwnProperty('reset') ? this.getDefaultUserFilter() : (values.assignedTo || ''),
      assignedToRoleId: values.assignedToRoleId || '',
      dateFrom: values.dateFrom || '',
      dateTo: values.dateTo || '',
      dateDue: values.dateDue || '',
      typeId: values.typeId || '',
      departmentId: values.departmentId || '',
      tasksPriorityId: values.tasksPriorityId || '',
      unassigned: values.unassigned || '',
    };

    if (this.props.insuredId) {
      filterBox.insuredId = this.props.insuredId;
    } else {
      filterBox.insuredKeyword = values.insuredKeyword || '';
    }

    if (this.props.holderId) {
      filterBox.holderId = this.props.holderId;
    } else {
      filterBox.holderKeyword = values.holderKeyword || '';
    }
    if (this.props.projectId) {
      filterBox.projectId = this.props.projectId;
    }

    if (!values.statusId) {
      if (!fromProject && !fromInsuredTab && !fromHolderTab) {
        filterBox.notStatusId = CERTFOCUS_TASK_FINISHED_ID;
      }
    } else {
      filterBox.statusId = values.statusId;
    }

    if (this.props.fromHolderTab && this.props.holderId) {
      filterBox.holderId = this.props.holderId
    }

    const query = Utils.addSearchFiltersToQuery(preQuery, filterBox);
    if (this.props.fromGlobalModule && !fromProject && !fromInsuredTab && !fromHolderTab && !values.notSaveFilters) {
      let saveFilter = { tab: 'task' }
      saveFilter.module = 'GobalTasks'
      saveFilter.objectId = null
      saveFilter.filterBox = values.hasOwnProperty('reset') ? '' : filterBox;
      this.props.filterActions.saveDefaultFilters('FilterCFTasks', saveFilter);
    }

    // const query = this.addId(preQuery);

    // save searchterm and pagenumber
    this.setState({
      filterBox,
      filter: {
        pageNumber: 1
      },
    }, () => {
      if(this.props.fromHolderTab) {
        this.props.actions.fetchTasksFromHolder(query);
      } else {
        this.props.actions.fetchTasks(query);
      }
    });
  }

  onEditNoteTask = (task, modal) => {
    this.setState({
      modal,
      selectedNoteTask: task,
      showNoteEditor: true
    });
  }

  closeEditor = () => {
    this.clearState( () => {
      this.props.documentsActions.setDocumentsList([], 0);
      this.props.projectsInsuredsActions.setProjectInsuredsList([], 0);
    })
  }

  closeEditorAndRefresh = () => {
    this.clearState( () => {
      this.props.documentsActions.setDocumentsList([], 0);
      this.props.projectsInsuredsActions.setProjectInsuredsList([], 0);
    });
  }

  renderCheckBox = () => {
    return <Form.Check type="checkbox"/>
  }

  renderDetailLink = (task) => {
    return (
      <React.Fragment>
        <span><a
            onClick={() => this.onViewTask(task)}
            className="cell-table-link icon-quick_view">
            {this.props.locale.viewDetail.actionLinks.detail}
          </a>
        </span>
        {(parseInt(task.assetTypeId, 10) === 8 && task.certificateId) ?
          <span><a
            target={'_blank'}
            href={`/certfocus/certificates/${task.ProjectInsuredId}`}
            className="cell-table-link icon-link task-certificate-link">
            {this.props.locale.viewDetail.actionLinks.certificate}
          </a></span>
          : null}
        {(task.exempt)
          ? <span className={`status-cell inactive`}>Exempted</span> 
          : (task.DocumentId && task.typeId === 40) ?
            <span>
              <a
                onClick={(e) => this.openProcessingModal(task)}
                className="cell-table-link icon-edit"
                style={{whiteSpace: 'nowrap'}}
              >Process</a>
            </span>
          : null}
      </React.Fragment>
    );
  }

  onViewTask = async (task) => {
    const queryParams = {
      orderBy: 'firstName',
      orderDirection: 'ASC',
    };
    const taskParams = {
      taskId: task.id
    }
    if (task.ProjectId) taskParams.projectId = task.ProjectId;

    this.props.actions.fetchTaskHistory(taskParams, (success, taskHistory, projectHistory) => {
      if (success) {
        this.setState({
          currentTaskHistory: taskHistory,
          currentProjectHistory: projectHistory,
        })
      }
    });
    if (task.InsuredId) {
      queryParams.insuredId = task.InsuredId;
    } else if (task.ProjectInsuredId) {
      queryParams.insuredId = task.ProjectInsuredId;
    } else {
      queryParams.holderId = task.HolderId;
    }

    this.props.contactActions.fetchContacts(queryParams);

    if (task.DocumentId && task.ProjectId) {
      const documentQuery = {
        projectId: task.ProjectId,
        documentId: task.DocumentId,
        withoutPagination: true,
      }
      await this.props.documentsActions.fetchDocumentData(documentQuery, (err, document) => {
        this.setState({
          document: document,
        });
      });
    }

    if (task.ProjectInsuredId) {
      const projectInsuredParams = {
        withoutPagination: true,
        insuredId: task.ProjectInsuredId,

      }
      if (task.ProjectId) {
        projectInsuredParams.projectId = task.ProjectId;
      }
      await this.props.projectsInsuredsActions.fetchProjectInsureds(projectInsuredParams);
    }

    this.setState({
      currentTask: task,
      showTaskDetail: true,
    })
  }

  handleCloseViewTask = () => {
    this.clearState(() => {
      this.props.documentsActions.setDocumentsList([], 0);
      this.props.projectsInsuredsActions.setProjectInsuredsList([], 0);
      this.setState({
        filterBox: {
          dateFrom: moment().format('YYYY-MM-DD'),
          dateTo: moment().format('YYYY-MM-DD'),
        }
      })
    })

    this.setState({
      currentTask: {},
      document: {},
      showTaskDetail: false,
      filterBox: {
        dateFrom: moment().format('YYYY-MM-DD'),
        dateTo: moment().format('YYYY-MM-DD'),
      }
    })
  }

  handleCloseAndNewTask = () => {
    this.clearState(() => {
      this.setState({
        showNoteEditor: true,
      })
    })
  }

  clearState = (callback) => {
    this.setState({
      currentTask: {},
      document: {},
      showTaskDetail: false,
      showNoteEditor: false,
    }, callback())
  }


  getTasksTable = () => {
    const {
      headers
    } = this.props.locale.table;    
    const {
      assignedToRole,
      assignedToUser,
      assignedFrom,
      dateCreated,
      dueDate,
      holder,
      insured,
      description,
      taskType,
      project,
      projectNumber,
    } = headers;

    const notesTasksTableMetadata = {
      fields: [
        // 'bulkAction',
        'tasksPriorityId',
        'assignedToUser',
        'assignedToRole',
        'modifyByUser',
        'dueDate',
        'modifiedDate',
        'holderName',
        'insuredName',
        'projectNumber',
        'projectName',
        'type',
        'description',
        // 'done',
        'actions',
      ],
      header: {
        // bulkAction: this.renderCheckBox(),
        tasksPriorityId: 'Urgent',
        assignedToUser: assignedToUser,
        assignedToRole: assignedToRole,
        modifyByUser: assignedFrom,
        dueDate: dueDate,
        modifiedDate: dateCreated,
        holderName: holder,
        insuredName: insured,
        projectNumber: projectNumber,
        projectName: project,
        type: taskType,
        description: description,
        actions: '',
      }
    };

    const notesTasksTableBody = this.props.notesTasks.tasks.map((task, idx) => {
      const {
        tasksPriorityId,
        assignedToUser,
        assignedToRole,
        modifyByUser,
        dateDue,
        modifiedDate,
        description,
        type,
        holderName,
        projectFullName,
        projectNumber,
        insuredName,
        status
      } = task;
      return {
        // bulkAction: this.renderCheckBox(),
        tasksPriorityId: (parseInt(tasksPriorityId, 10) === 1) ? 'Urgent' : '-',
        assignedToUser: assignedToUser,
        assignedToRole: assignedToRole,
        modifyByUser: modifyByUser || 'System',
        dueDate: moment(dateDue).format('MM/DD/YYYY'),
        modifiedDate: moment(modifiedDate).utc().format('MM/DD/YYYY hh:mm:ss A'),
        description: description,
        holderName: holderName,
        insuredName: insuredName,
        projectNumber: projectNumber,
        projectName: projectFullName,
        type: `${status} - ${type}`,
        actions: this.renderDetailLink(task),
      };
    });

    return {
      fields: notesTasksTableMetadata.fields,
      header: notesTasksTableMetadata.header,
      body: notesTasksTableBody
    };
  }

  renderModalBody = () => {
    return <CFTaskEditorModal
      closeAndRefresh={this.closeEditorAndRefresh}
      fromHolderTab ={this.props.fromHolderTab}
      holderName = { this.props.holderName }
      holderId ={this.props.holderId}
      fromInsuredTab ={this.props.fromInsuredTab}
      insuredName = { this.props.insuredName }
      insuredId ={this.props.insuredId}
      projectId={this.props.projectId}
      fromProject={this.props.fromProject}
      projectName={this.props.projectName}
      close={this.closeEditor}
    />
  }

  renderButtonAddTask() {
    let component = (
      <li>
        <a
          className="list-view-nav-link nav-bn icon-add no-overlapping"
          onClick={this.onCreateNoteTask}
        >
          {this.props.local.strings.scProfile.notesTasks.addTasks}
        </a>
      </li>
    );
    return component;
  }

  onCreateNoteTask = () => {
    this.setState({
      modal: 'new',
      selectedNoteTask: null,
      showNoteEditor: true,
    });
  }

  handleTotalizerLink = (urgent, unassigned) => {
    const filterBox = this.state.filterBox;
    if (urgent) filterBox.tasksPriorityId = 1;
    if (unassigned) filterBox.unassigned = 1;
    this.submitFilterForm(filterBox)
  }

  handleTaskSubmit = (values) => {
    const queryParams = {
      contactType: this.state.currentTask.typeId,
      contactUser: values.contactUser,
      description: values.contactSummary,
      taskId: this.state.currentTask.id,
      completed: true,
    };
    this.props.actions.saveNoteTask(queryParams, () => {
      this.handleCloseViewTask();
    });
  }

  handleCommentSubmit = (values) => {
    const {id, enteredByUserId, statusId, typeId, assignedToUserId} = this.state.currentTask;
    const queryParams = {
      taskId: id,
      description: values.comment,
      statusId: statusId,
      contactType: typeId,
    }
    switch (values.action) {
      case 'reassign':
        queryParams.assignedToUserId = values.toUser.value;
        queryParams.enteredByUserId = this.props.login.userId;
        break;
      case 'postpone':
        queryParams.dateDue = `${values.toDate} 23:59:59.000`;
        queryParams.assignedToUserId = assignedToUserId
        break;
      default:
        //contactId: this.props.login.userId; 
        queryParams.isRead = 0;
        queryParams.assignedToUserId = enteredByUserId;
        queryParams.enteredByUserId = this.props.login.userId;
        break;

    }
    this.props.actions.saveNoteTask(queryParams, () => {
      this.handleCloseViewTask();
      //todo: show alert saying that the task was closed and refresh.

    });
  }

  openProcessingModal = async (task) => {
    if (task.DocumentId && task.ProjectId) {
      const documentQuery = {
        projectId: task.ProjectId,
        documentId: task.DocumentId,
        withoutPagination: true,
      }
      await this.props.documentsActions.fetchDocumentData(documentQuery, (err, document) => {
        document.HolderID = task.HolderId;
        document.HolderName = task.holderName;
        document.ProjectID = task.ProjectId;
        document.ProjectName = task.projectName;
        document.InsuredID = document.SubcontractorID;
        document.InsuredName = document.insuredName;
        this.setState({
          showProcessingModal: true,
          document: document,
        });
      });
    }
  }

  closeProcessingModal = () => {
    this.setState({ showProcessingModal: false, document: null });
  }

  onProcessingModalSubmit = (values) => {
    const { setLoading } = this.props.commonActions;
    const { document } = this.props;
    // Document Status
    switch (values.submitType) {
      case 'sendToProcessing':
        values.documentStatusId = 5; break; // Sent to OCR
      case 'sendToDataEntry':
        values.documentStatusId = 4; break; // Pending Data Entry
      case 'doDataEntry':
        values.documentStatusId = 10; break; // Data Entry In Progress
      default:
        values.documentStatusId = values.documentStatusId;
    }

    const payload = {
      documentId: document ? document.DocumentID : undefined,
      hiringClientId: values.holderId ? values.holderId.value : '',
      projectId: values.projectId ? values.projectId.value : '',
      subcontractorId: values.insuredId ? values.insuredId.value : '',
      documentStatusId: values.documentStatusId
        ? Number(values.documentStatusId)
        : Number(values.documentTypeId !== 1) ? 14 : undefined
      ,
      documentTypeId: values.documentTypeId ? Number(values.documentTypeId) : 0,
      garbage: (values.garbage) ? 1 : 0,
      urgent: (values.urgent) ? 1 : 0,
      queueId: (values.queueId) ? values.queueId : undefined,
      documentDate: (values.documentDate) ? values.queueId : undefined,
      expireDate: (values.expireDate) ? values.queueId : undefined,
    };
    setLoading(true);

    this.props.documentActions.postDocument(payload, (err, data) => {
      setLoading(false);
      if (!err) {
        this.props.close();

        if ((values.submitType === 'doDataEntry') || (values.submitType === 'sendToDataEntry')) {
          // create certificate
          const certificateData = {
            certificateId: (document && document.CertificateID) ? document.CertificateID : undefined,
            documentId: document.DocumentID,
            documentUrl: document.DocumentUrl,
            holderId : document.HolderID,
            projectId: document.ProjectID,
            insuredId: document.InsuredID,
            projectInsuredId: document.ProjectInsuredID,
          }

          this.props.processingActions.addCertificate(certificateData, (err, certificateId) => {
            if (!err) {
              // redirect to DataEntry page
              if (values.submitType === 'doDataEntry') {
                this.openDataEntry(`/certfocus/processing/${certificateId}`)
              } else {
                Swal({
                  type: 'success',
                  title: '',
                  text: 'The Document has successfully been sent to Data Entry for processing',
                });
              }
            }
          });

        } else if (values.submitType === 'sendToProcessing') {
          Swal({
            type: 'success',
            title: '',
            text: 'The Document has successfully been sent to OCR for processing',
          });
        } else {
          Swal({
            type: 'success',
            title: '',
            text: 'The Document has successfully been saved',
          });
        }

      } else {
        Swal({
          type: 'error',
          title: '',
          text: 'Error uploading files. Please try again.',
        });
      }
    });
  };

  getDefaultUserFilter = (assignedTo) => {
    const { login: {userId}, users, fromProject, fromInsuredTab, fromHolderTab } = this.props;
    const {canSeeUsers} = this.state;
  
    let assignedToUserId = '';
    // previously it was default assignedToUserId = userId even if there is no user in the assign list 
    // now we are setting userId if it is in the assigned list  
    const hasUserInTheAssignToList = isArray(users) ? users.some((user) => user.id == userId) : false;
    if (hasUserInTheAssignToList){
      assignedToUserId = userId;
    }

    if (assignedTo) {
      assignedToUserId = assignedTo;
    } else {
      if ( (fromProject || fromInsuredTab || fromHolderTab) && (canSeeUsers) ) {
        assignedToUserId = ''
      }
    }
    //console.log('default user filter:', assignedToUserId);
    return assignedToUserId;
  }

  render() {
    const {
      filterTasks,
    } = this.props.local.strings.scProfile.notesTasks;

    const { 
      insuredName, 
      fromInsuredTab, 
      fromHolderTab, 
      holderName, 
      fromProject, 
      projectId, 
      projectArchived,
      insuredArchived,
      holderArchived,
    } = this.props;

    let {totalAmountOfTasks, tasksPerPage, fetchingTasks, totalUrgentTasks, totalUnassigned, totalUrgentUnassignedTasks} = this.props.notesTasks;

    const paginationSettings = {
      total: totalAmountOfTasks,
      itemsPerPage: tasksPerPage,
      setPageHandler: (pageNumber, pageSize) => {
        this.props.pageSize(pageSize)
        this.setPageFilter('', pageNumber, true)
      },
      currentPageNumber: this.state.filter.pageNumber,
    };

    return (
      <div className="list-view admin-view-body tasks-list">
        <Modal
          show={this.state.showNoteEditor}
          onHide={this.closeEditor}
          className="taskEditor">
          <Modal.Body>
            {this.renderModalBody()}
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showTaskDetail}
          onHide={this.closeEditor}
          className={'taskDetail'}>
          <Modal.Body>
            <CFTaskDetailModal
              document={this.state.document}
              task={this.state.currentTask}
              taskHistory={this.state.currentTaskHistory}
              projectHistory={this.state.currentProjectHistory}
              closeAndNew={this.handleCloseAndNewTask}
              handleTaskSubmit={this.handleTaskSubmit}
              handleCommentSubmit={this.handleCommentSubmit}
              onHide={this.closeEditor}
            />
          </Modal.Body>
        </Modal>
        <section className="list-view-header projects-view-header">
          <div className="row">
            <Col sm={12} lg={2}>
              {/*  Bulk action select*/}
            </Col>
            <Col sm={12} lg={6} className={'text-center no-margin'}>
              {(!fromHolderTab && !fromInsuredTab && !fromProject)?
                <Totalizers
                  totalCount={totalAmountOfTasks}
                  urgentCount={totalUrgentTasks}
                  unassignedCount={totalUnassigned}
                  urgentUnassignedCount={totalUrgentUnassignedTasks}
                  handleLink={this.handleTotalizerLink}
                  locale={this.props.locale.totalizers}
                />
                : null}

            </Col>
            <Col sm={12} lg={4}>
              <nav className="list-view-nav">
                <ul>
                  <li>
                    <a
                      className="list-view-nav-link nav-bn icon-login-door no-overlapping"
                      onClick={() => this.setState({showFilterBox: !this.state.showFilterBox})}
                    >
                      {filterTasks}
                    </a>
                  </li>
                {(!holderArchived) && (!projectArchived) && (!insuredArchived) && (
                  <RolAccess
                    masterTab="tasks"
                    sectionTab="create_tasks"
                    component={() => this.renderButtonAddTask()}>
                  </RolAccess>
                )}  
                </ul>
              </nav>
            </Col>
          </div>
        </section>

        <Modal
          show={this.state.showProcessingModal}
          onHide={this.closeProcessingModal}
          className="add-item-modal add-hc">
          <Modal.Body className="add-item-modal-body mt-0">
            <ProcessingModal
              onHide={this.closeProcessingModal}
              close={this.closeProcessingModal}
              document={this.state.document}
              currentUploadData={{
                holderId: this.props.holderId,
                projectId: this.props.projectId,
                insuredId: this.props.insuredId,
              }}
              onSubmit={this.onProcessingModalSubmit}
            />
          </Modal.Body>
        </Modal>


        {this.state.showFilterBox &&
        <section className="list-view-filters">
          <Filter
            userId={this.getDefaultUserFilter()}
            canSeeUserList={this.state.canSeeUsers}
            fromInsuredTab={fromInsuredTab}
            insuredName={insuredName}
            holderName={holderName}
            holderId={this.props.holderId}
            fromProject={fromProject}
            fromInsureds={this.props.fromInsureds}
            projectId={projectId}
            insuredId ={this.props.insuredId}
            fromHolderTab={fromHolderTab}
            fromGlobalModule={this.props.fromGlobalModule}
            onSubmit={this.submitFilterForm}
            getInitialData={this.submitFilterForm}
          />
        </section>
        }

        <DataTable
          name="Tasks"
          sorted
          items={this.getTasksTable()}
          wrapperState={this.state}
          tableOrderActive={this.state.tableOrderActive}
          clickOnColumnHeader={this.clickOnColumnHeader}
          isFetching={fetchingTasks}
          customClass='tasks-list-table'
          pagination={paginationSettings}
        />
      </div>
    )
  }
};

const mapStateToProps = (state) => {
  return {
    login: state.login,
    notesTasks: state.CFTasks,
    local: state.localization,
    locale: state.localization.strings.CFTasks,
    contacts: state.contacts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    pageSize: payload => dispatch(actions.setPageSize(payload)),
    userActions: bindActionCreators(userActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    contactActions: bindActionCreators(contactActions, dispatch),
    documentsActions: bindActionCreators(documentsActions, dispatch),
    departmentActions: bindActionCreators(departmentActions, dispatch),
    projectsInsuredsActions: bindActionCreators(projectsInsuredsActions, dispatch),
    loginActions: bindActionCreators(loginActions, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CFTasks));
