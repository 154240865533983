import Validations from "../../../lib/validations";

const validate = (values, props) => {
  const errors = {};
  const requiredFields = props.required || [];

  requiredFields.forEach(field => {
    if (Validations.isEmpty(values[field])) {
      errors[field] = 'Required'
    }
  });

  return errors;
}

export default validate;