import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import * as applyActions from '../actions';
import * as reviewApplicationsActions from '../../actions';

import ApplyRegisterForm from './form';
import Utils from '../../../../lib/utils';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import * as commonActions from "../../../common/actions";

class ApplyRegister extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submittingForm: false
    }
  }
  onSubmit = values => {
    this.setState({submittingForm: true})
    const applyPayload = {
      hiringClientId: Number(this.props.apply.hiringClientId),
      hiringClientName: this.props.apply.hiringClientName,
      subcontractorContactName: values.firstName + ' ' + values.lastName,
      subcontractorContactPhone: Utils.normalizePhoneNumber(values.phone),
      subcontractorContactEmail: values.email,
      subcontractorName: values.companyName,
      primaryTrade: get(values, 'primaryTrade.value', 0),
      secondaryTrade: get(values, 'secondaryTrade.value', 0),
      tertiaryTrade: get(values, 'tertiaryTrade.value', 0),
      quaternaryTrade: get(values, 'quaternaryTrade.value', 0),
      quinaryTrade: get(values, 'quinaryTrade.value', 0),
      address: values.address,
      city: values.city,
      stateId: isObject(values.state)
        ? get(values, 'state.value', 0)
        : get(values, 'state', ''),
      zipcode: values.zipCode,
      countryId: get(values, 'country.value', 0),
      subcontractorTaxId: values.taxId,
      agree: values.agree,
      password: values.password,
    }

    // console.log('ApplyRegiste::onSubmit values', values);
    // console.log('ApplyRegiste::onSubmit applyPayload', applyPayload);

    this.props.reviewApplicationsActions.setAddReviewApplications(applyPayload, (success) => {
      this.setState({submittingForm: false})
    });
  };

  render() {
    return (
      <ApplyRegisterForm
        isParent = {this.props.isParent}
        onSubmit={this.onSubmit}
        formSubmitting={this.state.submittingForm}
      />
    );
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    apply: state.apply
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(applyActions, dispatch),
    reviewApplicationsActions: bindActionCreators(reviewApplicationsActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplyRegister));
