import * as types from '../actions/types';

export default function registerReducer(state = {
    logs: [],
    fetchingLogs:false,
    logsError: null,
    isFinancialLogVisible: false,
    isFormLogVisible: false

  }, action) {
  switch(action.type) {
    case types.SET_FINANCIAL_LOGS_VISIBLE:
      return Object.assign({}, state, {
        isFinancialLogVisible: action.visible,
      });

    case types.SET_FORM_LOGS_VISIBLE:
      return Object.assign({}, state, {
        isFormLogVisible: action.visible,
      });

    case types.SET_LOGS:
      return Object.assign({},state,{
        logs: action.logs
      });

    case types.SET_LOGS_ERROR:
      return Object.assign({},state,{
        logsError: action.error
      });

     case types.SET_FETCHING_LOGS:
      return Object.assign({}, state, {
        fetchingLogs: action.isFetching
      });

    default:
      return state;
  }

}
