import _ from 'lodash';

import Api from '../../../../lib/api';
import Utils from '../../../../lib/utils';
import { setApplyErrorMessage } from '../actions';

export const loadOptions = (props) => {
  return async (name, prevOptions, {page}) => {
    const next = {
      options: prevOptions, 
      hasMore: false, 
      additional: {page}
    };

    try {
      const endpoint = props.getEndpoint(name, page);
      const tempToken = props.tempToken;
      const response = await Api.get(endpoint, tempToken);
      const {results, counts} = response.data.data;
      next.options = props.getOptions(results);
      if(page === 1 && endpoint.includes("trades?")){
          next.options.unshift({
              "label": "--- Please Select ---",
              "value": null
          })
      }
      next.hasMore = counts.hasNextPage > 0 && results.length > 0;
      next.additional = {page: next.hasMore ? page + 1 : page};
    } catch (error) {
      if (_.get(error, 'response.data.data')) {
        props.dispatch(setApplyErrorMessage(Utils.getErrorMessage(
          error.response.data, 
          props.local.actions
        )));
      } else {
        props.dispatch(setApplyErrorMessage(
          props.local.actions.errorDefault
        ));
      }
    }

    return next;
  };
};

export const loadTitles = (props) => {
  const { tempToken } = props.apply;

  return props.actions.loadOptions({
    dispatch: props.dispatch, 
    local: props.local.strings.apply,
    tempToken,
    getEndpoint: (title, page) => {
      const params = new URLSearchParams({
        title,
        page
      });
      return `titles?${params}`;
    },
    getOptions: (results) => {
      const toLabel = (results) => ({
        label: results.title,
        value: results.id
      });
      const noSelect = (title) => 
        title.label.toUpperCase() !== '--SELECT--' && title;
      return results.map(toLabel).map(noSelect);
    },
  });
};

export const loadTrades = (props) => {
  const { tempToken, hiringClientId } = props.apply;

  return props.actions.loadOptions({
    dispatch: props.dispatch, 
    local: props.local.strings.apply,
    tempToken,
    getEndpoint: (description, page) => {
      let params = new URLSearchParams({
        description, 
        page,
        hiringClientId
      });
      return `trades?${params}`;
    },
    getOptions: (results) => {
      const toLabel = (results) => ({
        label: results.description,
        value: results.id
      });
      const noSelect = (trade) => 
        trade.label.toUpperCase() !== '--SELECT--' && trade;
      return results.map(toLabel).map(noSelect).filter(v => v);
    },
  });
};

export const loadStates = (props, countrySelected) => {
  return props.actions.loadOptions({
    getEndpoint: (name, page) => {
      const params = new URLSearchParams({name, page});
      return `countries/${countrySelected}/states?${params}`;
    },
    getOptions: (results) => results.map(country => ({
      value: country.id,
      label: country.name
    }))
  });
};

export const loadCountries = (props) => {
  return props.actions.loadOptions({
    getEndpoint: (name, page) => {
      const params = new URLSearchParams({name, page});
      return `countries?${params}`;
    },
    getOptions: (results) => results.map(country => ({
      value: country.id,
      label: country.name
    }))
  });
};

export const loadHiringClientsGroupOptions = props => {
  const ENTITY_CHILD = 1;
  const { hiringClientId, hiringClientEntityType } = props.apply;
  const tempToken = window.localStorage.getItem('auth-token');
  return props.actions.loadOptions({
    dispatch: props.dispatch, 
    local: props.local.strings.apply,
    tempToken,
    getEndpoint: (name, page) => {
      const params = {
        hiringClientId,
        name,
        page
      };

      if (hiringClientEntityType === ENTITY_CHILD) {
        params.entity = hiringClientEntityType;
      }
      
      const urlSearchParams = new URLSearchParams(params);

      return `hiringclients/${hiringClientId}/children?${urlSearchParams}`;
    },
    getOptions: (results) => {
    //   console.log('loadHiringClientsGroupOptions getOptions', results);
      const toLabel = (results) => ({
        label: results.name,
        value: results.id,
        entityType: results.entityType
      });
      const noSelect = (option) => 
        option.label.toUpperCase() !== '--SELECT--' && option;
      return results.map(toLabel).map(noSelect);
    },
  });
};

export default loadOptions;
