export const SET_DEPARTMENT_LIST = 'SET_DEPARTMENT_LIST';
export const SET_DEPARTMENT_LIST_ERROR = 'SET_DEPARTMENT_LIST_ERROR';
export const SET_DEPARTMENT_LIST_FETCHING = 'SET_DEPARTMENT_LIST_FETCHING';
export const SET_DEPARTMENT_LIST_TOTAL = 'SET_DEPARTMENT_LIST_TOTAL';
export const SET_DEPARTMENT_SHOW_MODAL = 'SET_DEPARTMENT_SHOW_MODAL';
export const SET_DEPARTMENT_SHOW_USERS_MODAL = 'SET_DEPARTMENT_SHOW_USERS_MODAL';
export const SET_DEPARTMENT_ERROR = 'SET_DEPARTMENT_ERROR';
export const SET_DEPARTMENT_ROLES = 'SET_DEPARTMENT_ROLES';
export const SET_DEPARTMENT_USERS = 'SET_DEPARTMENT_USERS';
export const SET_DEPARTMENT_AVAILABLE_ROLES = 'SET_DEPARTMENT_AVAILABLE_ROLES';
