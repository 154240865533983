import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import Utils from '../../../lib/utils';

import AddRequirementSetsModal from '../modals/addRequirementSetModal';
import RequirementGroupForm from '../requirement-sets/details/forms/RequirementGroupForm';
import RequirementForm from '../requirement-sets/details/forms/RequirementForm';
import AttachmentForm from './../requirement-sets/details/forms/AttachmentForm';
import EndorsementForm from './../requirement-sets/details/forms/EndorsementForm';

import * as reqSetsActions from '../requirement-sets/actions';
import * as commonActions from '../../common/actions';
import * as actions from './actions';

import './RequirementSetsView.css';
import RolAccess from "../../common/rolAccess";
import DisplayTextWithConditionalModal from '../../common/displayTextWithConditionalModal';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from 'lodash';
import {Prompt} from "react-router-dom";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


class RequirementSetsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      activeModal: null,
      currentData: null,
      items: [],
      rulesGroupState:[]
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    const { rulesGroups } = this.props.holderRequirementSets;
    const { rules } = this.props.holderRequirementSets;
    this.setState({rulesGroupState:rulesGroups})
    let items = []
       rulesGroups.map((group) => {
        const groupRules = rules.filter(el => el.RuleGroupID === group.RuleGroupID);
          items.push(groupRules)
      })
      this.setState({ items: [...items] })
      window.addEventListener("beforeunload", async (ev) => {
        if(this.props.holderRequirementSets.hasTmpRequirementGroup){
          ev.preventDefault();
          ev.returnValue = 'Are you sure you want to exit? Requirement groups without attribute will not be saved.'
          return ev;
        }
      });

    }

  async componentWillUnmount() {
    if(this.props.holderRequirementSets.hasTmpRequirementGroup){
      this.props.reqSetsActions.setTmpRequirementGroupFlag({flag: false})
    }
  }

    async componentDidUpdate(prevProp) {
      const { rulesGroups } = this.props.holderRequirementSets;
      const { rules } = this.props.holderRequirementSets;
      if(!_.isEqual(rulesGroups,prevProp.holderRequirementSets.rulesGroups)){
        if(this.state.rulesGroupState.length >= rulesGroups.length){
          let filteredRulesGroup = await Promise.all(this.state.rulesGroupState.map(async el => {
            const ruleGroup = await rulesGroups.find(e => e.CoverageTypeID === el.CoverageTypeID)
            if(ruleGroup){
              return ruleGroup
            } else return el
          }))
          this.setState({rulesGroupState:filteredRulesGroup})
          let items = []
          filteredRulesGroup.map((group) => {
            const groupRules = rules.filter(el => el.RuleGroupID === group.RuleGroupID);
            items.push(groupRules)
          })
          this.setState({ items: [...items] })
        }else if(this.state.rulesGroupState.length < rulesGroups.length){
          let filteredRulesGroup = rulesGroups.filter(el => !this.state.rulesGroupState.find(e => e.RuleGroupID === el.RuleGroupID))
          this.setState({rulesGroupState: [...this.state.rulesGroupState , ...filteredRulesGroup]})
        }
      }
      if(!_.isEqual(rules,prevProp.holderRequirementSets.rules) ){
        let items = []
        let updateItem = [...this.state.items]
        rulesGroups.map((group) => {
          const groupRules = rules.filter(el => el.RuleGroupID === group.RuleGroupID);
          items.push(groupRules)
        })
        if(_.flatten(this.state.items).length > _.flatten(items).length){
        let {index, itemIndex} = Utils.getDifference(prevProp.holderRequirementSets.rules,rules,'RuleID',this.state.items)
          updateItem[index].splice(itemIndex, 1);
          this.setState({items: [...updateItem]})
        }else if(_.flatten(this.state.items).length < _.flatten(items).length){
          let {index,changedData} = Utils.getDifference(rules,prevProp.holderRequirementSets.rules,'RuleGroupID',this.state.items)
          if(!updateItem[index] || updateItem[index].length == 0){
            let indexOfRules = this.state.rulesGroupState.findIndex(el => el.RuleGroupID === changedData[0].RuleGroupID)
            updateItem[indexOfRules] = [changedData[0]]
            this.setState({items: [...updateItem]})
          }else{
            updateItem[index].push(changedData[0])
            this.setState({items: [...updateItem]}) 
          }
        }else{
          let {index,itemIndex,changedData} = Utils.getDifference(rules,prevProp.holderRequirementSets.rules,'RuleID',this.state.items)
          updateItem[index][itemIndex]=changedData[0]
          this.setState({items: [...updateItem]}) 
        }
      }
    }
    
  onDragEnd(result,i) {
    if (!result.destination) {
      return;
    }
    const updatedItems = reorder(
      this.state.items[i],
      result.source.index,
      result.destination.index
      );
      let items = this.state.items
      items[i] = updatedItems
      if(updatedItems){
        this.setState({items:items});
      }

      this.props.actions.updateRuleGroups(updatedItems, ()=>{})

  }
  openModal = (modal, data) => {
    this.setState({
      showModal: true,
      activeModal: modal,
      ...(data ? { currentData: data } : {}),
    });
  }

  closeModal = (success) => {
    if (success) {
      this.props.closeModal(success);
    }

    this.setState({
      showModal: false,
      activeModal: null,
      currentData: null,
    });
  }

  renderModal = () => {
    const { activeModal } = this.state;
    const { endorsements } = this.props.holderRequirementSets;
    const { requirementSet } = this.props;    

    const filteredNonVisibleEndorsements = endorsements 
      ? endorsements.filter(e => e.AlwaysVisible === false) 
      : [];

    switch (activeModal) {
      case 'basicInfo':
        return (
          <AddRequirementSetsModal
            requirementSet={this.props.requirementSet}
            close={this.closeModal}
            holderId={this.props.requirementSet.HolderId}
          />
        );
      case 'attachment':
        return (
          <AttachmentForm
            onSubmit={this.onSubmitAttachment}
            close={this.closeModal}
            isDefaultReqSet={this.state.requirementSet.IsDefault}
          />
        );
      case 'requirementGroup':
        const holderId = (requirementSet.Template !== 1) 
          ? (this.props.holderId) ? this.props.holderId : requirementSet.HolderId 
          : undefined;
        return (
          <RequirementGroupForm
            onSubmit={this.onSubmitRequirementGroup}
            close={this.closeModal}
            group={{...this.state.currentData, isDefaultReqSet: this.state.requirementSet.IsDefault}}
            holderId={holderId}
            isTemplate={(requirementSet.Template === 1) ? true : false}
          />
        );
      case 'requirement':
        return (
          <RequirementForm
            requirement={{...this.state.currentData, isDefaultReqSet: this.state.requirementSet.IsDefault}}
            onSubmit={this.onSubmitRequirement}
            close={this.closeModal}
          />
        );
      case 'endorsements':
        return (
          <EndorsementForm
            onSubmit={this.onSubmitEndorsement}
            close={this.closeModal}
            filteredEndorsements={{...filteredNonVisibleEndorsements, isDefaultReqSet: this.state.requirementSet.IsDefault}}
            holderId={this.props.holderId}
          />
        );  
      default:
        return null;
    }
  }

  onSubmitRequirementGroup = (data) => {
    const RuleGroupID = this.state.currentData ? this.state.currentData.RuleGroupID : undefined;

    const ruleGroup = {
      RuleGroupID: RuleGroupID ? RuleGroupID: "TMP_ID",
      CoverageTypeID: Number(data.coverageType.value),
      RequirementSetID: Number(this.props.requirementSet.Id),
      RuleGroupName: data.ruleGroupName,
      CoverageTypeName: data.coverageType.label,
    }

    let rulesGroups = [...this.state.rulesGroupState, ruleGroup]
    let existRuleGroup = this.state.rulesGroupState.find(item => item.CoverageTypeID === ruleGroup.CoverageTypeID)
    if(!existRuleGroup){
      const hasTmpRuleGroup = rulesGroups.find(item => item.RuleGroupID === "TMP_ID")
      this.props.reqSetsActions.setTmpRequirementGroupFlag({flag: hasTmpRuleGroup && hasTmpRuleGroup.RuleGroupID ? true : false})
      this.setState({rulesGroupState: rulesGroups})
    }
    this.setState({
      showModal: false,
      activeModal: null,
      currentData: null,
    });
  }

  

  onDeleteRequirementGroup = (id, totalActiveProjects, ruleGroup) => {
    const { rules } = this.props.holderRequirementSets;
    const groupRules = rules.filter(el => el.RuleGroupID === id).length;
    totalActiveProjects = Number(totalActiveProjects);

    // IF THERE ARE NO RULES JUST DELETE THE GROUP, IF THERE ARE RULES SHOW A CONFIRMATION PROMPT
    if (groupRules > 0) {
      let msg = `This group has ${groupRules} rule/s`
      if (totalActiveProjects > 0) msg = msg + ` and ${totalActiveProjects} active projects`;
      Swal({
        title: 'Are you sure?',
        text: msg,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2E5965',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
      }).then((result) => {
        if (result.value) {
          this.doDeleteRuleGroup(id, true, ruleGroup.CoverageTypeID);
        }
      });
    } else {
      if (totalActiveProjects > 0) {
        Swal({
          title: 'Are you sure?',
          text: `This rule has ${totalActiveProjects} active projects`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#2E5965',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes!'
        }).then((result) => {
          if (result.value) {
            this.doDeleteRuleGroup(id, false, ruleGroup.CoverageTypeID);
          }
        });
      } else {
        this.doDeleteRuleGroup(id, false, ruleGroup.CoverageTypeID);
      }
    }
  };


  doDeleteRuleGroup (ruleGroupId, deleteAllRules = false, coverageTypeID) {
    let hasTmpRuleGroup = false;
    const ruleGroups = this.state.rulesGroupState.filter(item => {
      if(hasTmpRuleGroup === false){
        hasTmpRuleGroup = item.RuleGroupID === "TMP_ID" && item.CoverageTypeID !== coverageTypeID
      }
      return item.CoverageTypeID !== coverageTypeID
    })
    this.props.reqSetsActions.setTmpRequirementGroupFlag({flag: hasTmpRuleGroup && hasTmpRuleGroup.RuleGroupID ? true : false})
    this.setState({rulesGroupState: ruleGroups})
    if(ruleGroupId !== 'TMP_ID') {
      const payload = { ruleGroupId, deleteAllRules };
      this.props.commonActions.setLoading(true);
      this.props.reqSetsActions.deleteRuleGroup(payload, () => {
        this.props.commonActions.setLoading(false);
      });
    }
  }

  onSubmitRequirement = async (data) => {
    const { RuleGroupID, RuleID, CoverageTypeID } = this.state.currentData;

    let conditionValue = data.conditionValue;
    if (data.conditionValue && (Number(data.conditionTypeId) > 3) && (Number(data.conditionTypeId) <= 7)) {
      conditionValue = Utils.normalizeCurrency(data.conditionValue);
    }

    const payload = {
      ruleGroupId: RuleGroupID ? RuleGroupID : undefined,
      attributeId: data.attribute.value,
      attributeName: data.attribute.label,
      conditionTypeId: data.conditionTypeId,
      conditionValue: conditionValue,
      deficiencyTypeId: data.deficiencyTypeId,
      deficiencyText: data.deficiencyText,
      ruleId: RuleID ? RuleID : undefined,
    };

    this.props.commonActions.setLoading(true);
    if(RuleGroupID === "TMP_ID"){
      // Create reqGroup
      const ruleGroup = this.state.rulesGroupState.find(item => item.CoverageTypeID == CoverageTypeID)
      const ruleGroupPayload = {
        RuleGroupID: undefined,
        ruleGroupName: ruleGroup.RuleGroupName,
        requirementSetId: this.props.requirementSet.Id,
        coverageTypeId: ruleGroup.CoverageTypeID,
        coverageTypeName: ruleGroup.CoverageTypeName
      }
      const rulesGroup = await this.state.rulesGroupState.filter(item => item.CoverageTypeID !== CoverageTypeID)
      const hasTmpRuleGroup = rulesGroup.find(item => item.RuleGroupID === "TMP_ID")
      this.props.reqSetsActions.setTmpRequirementGroupFlag({flag: hasTmpRuleGroup && hasTmpRuleGroup.RuleGroupID ? true : false})
      this.props.reqSetsActions.sendRuleGroup(ruleGroupPayload, (data) => {
        if (data) {
          this.props.reqSetsActions.sendRule({...payload, ruleGroupId: data.RuleGroupID}, (success) => {
            this.props.commonActions.setLoading(false);
            if (success) {
              this.setState({
                showModal: false,
                activeModal: null,
                currentData: null,
              });
            }
          });
        }
      });
    } else {
      const hasTmpRuleGroup = this.state.rulesGroupState.find(item => item.RuleGroupID === "TMP_ID")
      this.props.reqSetsActions.setTmpRequirementGroupFlag({flag: hasTmpRuleGroup && hasTmpRuleGroup.RuleGroupID ? true : false})
      this.props.reqSetsActions.sendRule(payload, (success) => {
        this.props.commonActions.setLoading(false);
        if (success) {
          this.setState({
            showModal: false,
            activeModal: null,
            currentData: null,
          });
        }
      });
    }
  }

  onDeleteRequirement = (data) => {
    const { rules } = this.props.holderRequirementSets;
    const groupRulesCount = rules.filter(el => el.RuleGroupID === data.RuleGroupID && el.RuleID !== data.RuleID).length;
    if(groupRulesCount > 0) {
      this.props.commonActions.setLoading(true);
      this.props.reqSetsActions.deleteRule(data.RuleID, () => {
        this.props.commonActions.setLoading(false);
      });
    } else {
      // DELETE rule group with rule
      const ruleGroup = this.state.rulesGroupState.find(item => item.RuleGroupID === data.RuleGroupID)
      console.log('ruleGroup:: ',ruleGroup)
      if(ruleGroup) {
        if (ruleGroup.totalActiveProyects > 0) {
          Swal({
            title: 'Are you sure?',
            text: `This rule group has ${ruleGroup.totalActiveProyects} active projects`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2E5965',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!'
          }).then((result) => {
            if (result.value) {
              this.doDeleteRuleGroup(ruleGroup.RuleGroupID, true, ruleGroup.CoverageTypeID);
            }
          });
        } else {
          this.doDeleteRuleGroup(ruleGroup.RuleGroupID, true, ruleGroup.CoverageTypeID);
        }
      }
    }
  }
  
  downloadAttachment = (link) => {
    window.open(link, '_blank');
  }
  
  onSubmitAttachment = (data) => {
    const payload = {
      ...data,
      requirementSetId: this.props.requirementSet.Id,
    };

    this.props.commonActions.setLoading(true);
    this.props.reqSetsActions.sendAttachment(payload, (success) => {
      this.props.commonActions.setLoading(false);

      if (success) {
        this.setState({
          addAttachmentModal: false,
          activeModal: null,
          currentData: null,
        });
      }
    });
  }

  deleteAttachment = (id, documentId) => {
    this.props.commonActions.setLoading(true);
    this.props.reqSetsActions.deleteAttachment(id, documentId, (success) => {
      this.props.commonActions.setLoading(false);

      if (success) {
        this.setState({
          addAttachmentModal: false,
          activeModal: null,
          currentData: null,
        });
      }
    });
  }

  renderAttachments = (attachments) => {
    return (
      <section className="list-view-table">
        <div className="table-wrapper">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th scope="col"><span className="col-th-wrapper">Name</span></th>
                <th scope="col"><span className="col-th-wrapper">Actions</span></th>
              </tr>
            </thead>
            <tbody>
            { attachments.map((attachment) => (
              <tr key={attachment.RequirementSets_DocumentID}>
                <td>{attachment.FileName}</td> 
                <td className="attachments-icons">
                  <span
                    className="linear-icon-download"
                    title="Download Attachment"
                    onClick={() => this.downloadAttachment(attachment.Url)}
                  />
                  <span
                    className="linear-icon-cross"
                    title="Remove Attachment"
                    onClick={() => this.deleteAttachment(attachment.RequirementSets_DocumentID, attachment.DocumentID)}
                  />
                </td>
              </tr>
            ))}     
            </tbody>
          </table>
        </div>
      </section>
    );
  }

  onSubmitEndorsement = (data) => {
    const endorsementId = parseInt(data.endorsement, 10);
    const payload = {
      endorsementId: endorsementId,
      requirementSetId: this.props.requirementSet.Id,
      holderId: this.props.holderId,
      checked: true
    };

    if (this.props.holderRequirementSets.reqSetEndorsements.some((e) => e.EndorsementID === endorsementId)) {
      Swal({
        title: 'Are you sure?',
        text: `This endorsement has already been selected`,
        type: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#2E5965',
        confirmButtonText: 'Ok!'
      })
      return false;
    }   
    
    const selectedEndorsement = this.props.holderRequirementSets.endorsements.find((e) => e.Id === endorsementId);
    // console.log('selected', selectedEndorsement);
    if(selectedEndorsement) {
      payload.name = selectedEndorsement.Name;
      payload.alwaysVisible = false;
    } else {
      return false;
    }
    // console.log(payload);
    this.props.commonActions.setLoading(true);
    this.props.reqSetsActions.sendEndorsement(payload, (success) => {
      this.props.commonActions.setLoading(false);
      if (success) {
        this.setState({
          addEndorsementModal: false,
          activeModal: null,
          currentData: null,
        });
      }
    });
  }

  onDeleteEndorsement = (data) => {
    const {endorsementsList} = this.props.local.strings.endorsements;
    const payload = {
      endorsementId: data.Id,
      requirementSetId: this.props.requirementSet.Id,
      requirementSetEndorsementId: data.requirementSetEndorsementId,
      holderId: this.props.holderId,
      checked: false
    };
    // console.log(payload);

    if (!isNaN(Number(data.totalActiveProyects)) && Number(data.totalActiveProyects) !== 0) {
      Swal({
        title: endorsementsList.editAnchor,
        html: `Are you sure you want to delete the endorsement "<b>${data.Name}</b>"?<br/>`
          + `It has <b>${data.totalActiveProyects}</b> active projects related.`,
        // `<p>Are you sure you want to edit the endorsement "<b>${data.Name}</b>"?</p>. `,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2E5965',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
      }).then((result) => {
        if (result.value) {
          this.props.commonActions.setLoading(true);
          this.props.reqSetsActions.sendEndorsement(payload, (success) => {
            this.props.commonActions.setLoading(false);
            if (success) {
              this.setState({
                addEndorsementModal: false,
                activeModal: null,
                currentData: null,
              });
            }
          });
        }
      });
    } else {
      this.props.commonActions.setLoading(true);
      this.props.reqSetsActions.sendEndorsement(payload, (success) => {
        this.props.commonActions.setLoading(false);
        if (success) {
          this.setState({
            addEndorsementModal: false,
            activeModal: null,
            currentData: null,
          });
        }
      });
    }
  };

  renderEndorsements = (endorsements) => {
    return endorsements.map((endorsement) => {
      return (
        <div key={endorsement.Id}>
          <div className="card p-2">
            <div className="card-block">
              <div className="row mb-2 d-flex align-items-center">
                <div className="col-md-11">{endorsement.Name}</div>
                {!endorsement.AlwaysVisible && (
                  <div className="col-md-1 requirements-icons">
                    <span className="linear-icon-cross" onClick={() => this.onDeleteEndorsement(endorsement)}/>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    });
	}

  renderRequirementsGroupsActions = (group,provided) => {
    const {
      addRequirementBtn,
    } = this.props.local.strings.holderRequirementSets.details;
    const { fromDownload, holderArchived } = this.props;
    return (
        <div className="row mb-2 d-flex align-items-center">
          {!fromDownload && !holderArchived && (
              <div className="col-md-1">
                <span className="linear-icon-cross" onClick={() => this.onDeleteRequirementGroup(group.RuleGroupID, group.totalActiveProyects, group)}/>
              </div>
          )}
          <div className="col-md-9">
            <h5 className="mb-0">{group.RuleGroupName}</h5>
          </div>
          <div className="col-md-2 d-flex justify-content-end">
            {!fromDownload && !holderArchived && (
                <button
                    className="header-primary-button"
                    onClick={() => this.openModal('requirement', {
                      RuleGroupID: group.RuleGroupID,
                      CoverageTypeID: group.CoverageTypeID,
                    })}
                >
                  {addRequirementBtn}
                </button>
            )}
          </div>
        </div>
    )
  }
	renderRequirementsGroups = () => {
    const { conditionPossibleValues } = this.props.holderRequirementSets;
    const { fromDownload, holderArchived, fromSettingsTab } = this.props;
    return (
      <div>
        <div>
          {this.state.rulesGroupState.map((group,i) => (
            <div>
              <div className="card p-2 mb-2">
                <div className="card-block">
                    {(fromSettingsTab) ? (
                      <RolAccess
                        masterTab="settings"
                        sectionTab="edit_requirements"
                        component={() => this.renderRequirementsGroupsActions(group)}>
                      </RolAccess>
                    ):(  
                      <RolAccess
                        masterTab="requirements"
                        sectionTab="edit_requirements"
                        component={() => this.renderRequirementsGroupsActions(group)}>
                      </RolAccess>
                    )} 
                </div> 
                <div>
                  <DragDropContext onDragEnd={(result)=>this.onDragEnd(result,i)}>
                    <Droppable droppableId="droppable2">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                            ref={provided.innerRef}
                            >
                          {this.state.items[i] && this.state.items[i].map((item, index) => (
                            <Draggable key={`${item.RuleID}`} draggableId={`${item.RuleID}`} index={index}>
                              {(provided) => (
                                <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                    <div className="row align-items-center" key={item.RuleID}>
                                        {!fromDownload && (
                                          <div className="col-md-1" />
                                        )}
                                        <div className={`col-md-${fromDownload ? '4' : '3'}`}>
                                          {item.AttributeName}
                                        </div>
                                        <div className="col-md-1 requirement-condition">
                                          {conditionPossibleValues.find(el => el.value === item.ConditionTypeID).label.slice(0, 2)}
                                        </div>
                                        <div className={`col-md-${fromDownload ? '2' : '1'}`}>
                                          {((Number(item.ConditionTypeID) > 3) && (Number(item.ConditionTypeID) < 8))
                                            ? Utils.formatCurrency(item.ConditionValue) 
                                            : <DisplayTextWithConditionalModal string={item.ConditionValue} />
                                          }
                                        </div>
                                        <div className="col-md-4">
                                          {item.DeficiencyText}
                                        </div>
                                        <div className="col-md-1">
                                          {Number(item.DeficiencyTypeID) === 1 ? 'Major' : 'Minor'}
                                        </div>
                                        {(fromSettingsTab) ? (
                                            <RolAccess
                                              masterTab="settings"
                                              sectionTab="edit_requirements"
                                              component={() => this.renderRequirementsRuleActions(item,provided)}>
                                            </RolAccess>
                                          ):(!fromDownload && !holderArchived && (
                                            <RolAccess
                                              masterTab="requirements"
                                              sectionTab="edit_requirements"
                                              component={() => this.renderRequirementsRuleActions(item,provided)}>
                                            </RolAccess>
                                          ))}
                                    </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext> 
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      );
  }

  renderRequirementsRuleActions = (requirement,provided) => {
    return (
        <div className="col-md-1 requirements-icons">
          <span className='linear-icon-list mr-2' {...provided.dragHandleProps} />
          <span className="linear-icon-edit mr-2" onClick={() => this.openModal('requirement', requirement)} />
          <span className="linear-icon-cross" onClick={() => this.onDeleteRequirement(requirement)}/>
        </div>
    )
  }

  renderRequirement = (requirement) => {
    const { conditionPossibleValues } = this.props.holderRequirementSets;
    const condition = conditionPossibleValues.find(el => el.value === requirement.ConditionTypeID);
    const { fromDownload, holderArchived, fromSettingsTab } = this.props;

    return (
      <div className="row align-items-center" key={requirement.RuleID}>
        {!fromDownload && (
          <div className="col-md-1" />
        )}
        <div className={`col-md-${fromDownload ? '4' : '3'}`}>
          {requirement.AttributeName}
        </div>
        <div className="col-md-1 requirement-condition">
          {condition.label.slice(0, 2)}
        </div>
        <div className={`col-md-${fromDownload ? '2' : '1'}`}>
          {((Number(requirement.ConditionTypeID) > 3) && (Number(requirement.ConditionTypeID) < 8))
            ? Utils.formatCurrency(requirement.ConditionValue) 
            : <DisplayTextWithConditionalModal string={requirement.ConditionValue} />
          }
        </div>
        <div className="col-md-4">
          {requirement.DeficiencyText}
        </div>
        <div className="col-md-1">
          {Number(requirement.DeficiencyTypeID) === 1 ? 'Major' : 'Minor'}
        </div>
        {(fromSettingsTab) ? (
          <RolAccess
            masterTab="settings"
            sectionTab="edit_requirements"
            component={() => this.renderRequirementsRuleActions(requirement)}>
          </RolAccess>
        ):(!fromDownload && !holderArchived && (
          <RolAccess
            masterTab="requirements"
            sectionTab="edit_requirements"
            component={() => this.renderRequirementsRuleActions(requirement)}>
          </RolAccess>
        ))}
      </div>
    );
  }

  renderButtonAddRequirementGroup() {
    const {addRequirementGroupBtn} = this.props.local.strings.holderRequirementSets.details;
    const component = (
        <button className="header-primary-button" onClick={() => this.openModal('requirementGroup')}>
          {addRequirementGroupBtn}
        </button>
    );
    return component;
  }

  renderButtonEditBasicInfo() {
    const { editInformationBtn } = this.props.local.strings.holderRequirementSets.details;
    const component = (
      <button className="header-primary-button" onClick={() => this.openModal('basicInfo')}>
        {editInformationBtn}
      </button>
    );
    return component;
  }

  renderButtonAddEndorsement() {
    const { addEndorsementBtn } = this.props.local.strings.holderRequirementSets.details;
    const component = (
      <button className="header-primary-button" onClick={() => this.openModal('endorsements')}>
        {addEndorsementBtn}
      </button>
    );
    return component;
  }

  renderButtonAddAttachment() {
    const { addAttachmentBtn } = this.props.local.strings.holderRequirementSets.details;
    const component = (
      <button className="header-primary-button" onClick={() => this.openModal('attachment')}>
        {addAttachmentBtn}
      </button>
    );
    return component;
  }

  backToReqSet() {
    if(this.props.holderRequirementSets.hasTmpRequirementGroup){
      if(window.confirm('Are you sure you want to exit? Requirement groups without attribute will not be saved.')) {
        this.props.closeDetails()
      }
    } else this.props.closeDetails()
  }

  render() {
    const { showModal } = this.state;
    const { fromDownload, fromHolderTab, requirementSet, holderArchived,fromSettingsTab } = this.props;
    const { totalOfActiveProyects } = requirementSet;
    const isTemplate = requirementSet.Template === 1 ? true : false;    
    const {
      attachments, 
      endorsements, 
      reqSetEndorsements,
      rulesGroups,
    } = this.props.holderRequirementSets;    
    const {
      backToList,
      nameLabel,
      descriptionLabel,
      systemLabel,
      editInformationBtn,
      requirementsTitle,
      addEndorsementBtn,
      attachmentsTitle,
      addAttachmentBtn,
      endorsementsTitle,
      noEndorsements,
      noAttachment,
      noRequirementGroup,
    } = this.props.local.strings.holderRequirementSets.details;

    const endorsementList = [];

    if (reqSetEndorsements.length > 0) {
      reqSetEndorsements.forEach((e) => {
        if (! endorsementList.some((f) => f.Id === e.Id)) {
          endorsementList.push({
            Id: e.EndorsementID,
            Name: e.EndorsementName,
            requirementSetEndorsementId: e.RequirementSet_EndorsementID,
            AlwaysVisible: e.AlwaysVisible,
            totalActiveProyects: e.totalActiveProyects
          });
        }        
      });
    }
    
    if (endorsements.length > 0) {
      endorsements.forEach((e) => {
        if ((e.AlwaysVisible === true) && (! endorsementList.some((f) => f.Id === e.Id))) {
          endorsementList.unshift(e);
        }
      });
    }

    return (
      <div className="list-view admin-view-body holder-requirement-sets-list-view">
        <Prompt
          when={this.props.holderRequirementSets.hasTmpRequirementGroup}
          message={() =>
            'Are you sure you want to exit? Requirement groups without attributes will not be saved.'
          }
        />
        <Modal
          show={showModal}
          onHide={this.closeModal}
          className="add-item-modal add-entity-small"
        >
          <Modal.Body>
            {this.renderModal()}
          </Modal.Body>
        </Modal>

        <div className="back-to-header">
          <button className="header-primary-button" onClick={() => this.backToReqSet()}>
            {backToList}
          </button>
        </div>

        <div className="card mb-2 p-2">
          <div className="card-block">
            <div className="row">
              <div className="col-6">
                <p><strong>{nameLabel}:</strong>&nbsp; {this.props.requirementSet.Name}</p>
                <p><strong>{descriptionLabel}:</strong>&nbsp; {this.props.requirementSet.Description}</p>
              </div>
              <div className="col-6">
                <p>
                  <strong>{systemLabel}:</strong>&nbsp;
                </p>
                {(fromSettingsTab) ? (
                  <RolAccess
                    masterTab="settings"
                    sectionTab="edit_requirements"
                    component={() => this.renderButtonEditBasicInfo()}>
                  </RolAccess>
                ):(!fromHolderTab && !holderArchived && (
                  <button className="header-primary-button" onClick={() => this.openModal('basicInfo')}>
                    {editInformationBtn}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
        
        <div className="row mb-2 mt-3">
          <div className="col-12 d-flex align-items-center">
            <h4 className="mb-0 mr-3">{requirementsTitle}</h4>
            {(fromSettingsTab) ? (
              <RolAccess
                masterTab="settings"
                sectionTab="edit_requirements"
                component={() => this.renderButtonAddRequirementGroup()}>
              </RolAccess>
            ):(!holderArchived) && (
              <RolAccess
                masterTab="requirements"
                sectionTab="edit_requirements"
                component={() => this.renderButtonAddRequirementGroup()}>
              </RolAccess>
            )}  
          </div>
        </div>
        <div className="row">
          <div className="col-12 requirements-groups">
            {this.state.rulesGroupState.length > 0 ?
              this.renderRequirementsGroups(rulesGroups) :
              <h6>{noRequirementGroup}</h6>
            }
          </div>
        </div>

        {!isTemplate && (
          <div className="row">

            <div className="col-12">

              <div className="row mb-2 mt-3">
                <div className="col-6 d-flex align-items-center">
                  <h4 className="mb-0 mr-3">{endorsementsTitle}</h4>
                  {(fromSettingsTab) ? (
                    <RolAccess
                      masterTab="settings"
                      sectionTab="edit_requirements"
                      component={() => this.renderButtonAddEndorsement()}>
                    </RolAccess>
                  ):(!fromHolderTab && !isTemplate && (
                    <button className="header-primary-button" onClick={() => this.openModal('endorsements')}>
                      {addEndorsementBtn}
                    </button>
                  ))}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {endorsementList.length > 0 
                    ? this.renderEndorsements(endorsementList) 
                    : <h6>{noEndorsements}</h6>
                  }
                </div>
              </div>     

              <div className="row mb-2 mt-3">
                <div className="col-6 d-flex align-items-center">
                  <h4 className="mb-0 mr-3">{attachmentsTitle}</h4>
                  {(fromSettingsTab) ? (
                    <RolAccess
                      masterTab="settings"
                      sectionTab="edit_requirements"
                      component={() => this.renderButtonAddAttachment()}>
                    </RolAccess>
                  ):(!fromHolderTab && !isTemplate && (  
                    <button className="header-primary-button" onClick={() => this.openModal('attachment')}>
                      {addAttachmentBtn}
                    </button>
                  ))}  
                </div>
              </div>
              <div className="row">              
                <div className="col-6">
                  {attachments.length > 0 
                    ? this.renderAttachments(attachments)
                    : <h6>{noAttachment}</h6>
                  }
                </div>
              </div>

            </div>
          </div>          
        )}
        
      </div>
    );
  }
  
};

const mapStateToProps = (state) => {
  return {
    holderRequirementSetsView: state.holderRequirementSetsView,
    holderRequirementSets: state.holderRequirementSets,
    local: state.localization,
    login: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    reqSetsActions: bindActionCreators(reqSetsActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequirementSetsView);
