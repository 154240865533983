import auth0 from 'auth0-js';
import { DEFAULT_CONNECTION_NAME_KEY } from '../../../lib/appConstants';

// the following three lines MUST be updated
const FRONTEND_URL=process.env.REACT_APP_FRONTEND_URL;
const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;

// Secondary auth0 for production specific SAML connections
const SECONDARY_AUTH0_DOMAIN = process.env.REACT_APP_SECONDARY_AUTH0_DOMAIN;
const SECONDARY_AUTH0_AUDIENCE_DOMAIN = process.env.REACT_APP_SECONDARY_AUTH0_AUDIENCE_DOMAIN;
const SECONDARY_AUTH0_CLIENT_ID = process.env.REACT_APP_SECONDARY_AUTH0_CLIENT_ID;
const SECONDARY_AUTH0_CONNECTIONS = process.env.REACT_APP_SECONDARY_AUTH0_CONNECTIONS;
const SECONDARY_AUTH0_CONNECTION_QUERY_NAME = process.env.REACT_APP_SECONDARY_AUTH0_CONNECTION_QUERY_NAME || 'connection';
const CONNECTIONS = SECONDARY_AUTH0_CONNECTIONS !== undefined ? SECONDARY_AUTH0_CONNECTIONS
  .split(',')
  .filter((connection) => connection)
  .map((connection) => connection.trim().toLowerCase()) : undefined;

const getConnectionQueryParam = () => {
  if (window && window.location) {
    const queryParams = new URLSearchParams(window.location.search);
    return `${queryParams.get(SECONDARY_AUTH0_CONNECTION_QUERY_NAME)}`
      .trim()
      .toLowerCase();
  }
  return null;
}

const getDefaultConnection = () => {
  if (localStorage) {
    return localStorage.getItem(DEFAULT_CONNECTION_NAME_KEY);
  }
}

const storeDefaultConnection = () => {
  const connection = getConnectionQueryParam();
  if (localStorage && CONNECTIONS && CONNECTIONS.includes(connection)) {
    localStorage.setItem(DEFAULT_CONNECTION_NAME_KEY, connection);
  }
}

const removeDefaultConnection = () => {
  if (localStorage) {
    localStorage.removeItem(DEFAULT_CONNECTION_NAME_KEY);
  }
}

// This method is used to get auth0 config parameters based on the connection type
const getConfigParams = () => {
  const getAudience = (domain) => `https://${domain}/userinfo`;
 
  try {
    const connection = getConnectionQueryParam();
    const savedConnection = getDefaultConnection();

    if (CONNECTIONS && (CONNECTIONS.includes(connection) || CONNECTIONS.includes(savedConnection))) {
      return {
        domain: SECONDARY_AUTH0_DOMAIN,
        clientID: SECONDARY_AUTH0_CLIENT_ID,
        audience: getAudience(SECONDARY_AUTH0_AUDIENCE_DOMAIN)
      }
    }
  } catch (ex) {
    console.error(ex);
  }

  return {
    domain: AUTH0_DOMAIN,
    clientID: CLIENT_ID,
    audience: getAudience(AUTH0_DOMAIN)
  }
}

class Auth {
  constructor() {
    const configParams = getConfigParams();
    this.auth0 = new auth0.WebAuth({
      ...configParams,
      redirectUri: `${FRONTEND_URL}/callback`,
      responseType: 'token id_token',
      scope: 'openid profile'
    });

    this.clientID = configParams.clientID;
    this.getProfile = this.getProfile.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  getProfile() {
    return this.profile;
  }

  getIdToken() {
    return this.idToken;
  }

  isAuthenticated() {
    return new Date().getTime() < this.expiresAt;
  }

  signIn() {
    storeDefaultConnection();
    this.auth0.authorize();
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash({__enableIdPInitiatedLogin: true}, (err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        this.setSession(authResult);
        resolve();
      });
    })
  }

  setSession(authResult) {
    this.idToken = authResult.idToken;
    this.profile = authResult.idTokenPayload;
    // set the time that the id token will expire at
    this.expiresAt = authResult.idTokenPayload.exp * 1000;
  }

  signOut() {
    this.auth0.logout({
      returnTo: `${FRONTEND_URL}/sso`,
      clientID: this.clientID,
    });
    removeDefaultConnection();
  }

  silentAuth() {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        //console.log('authResult', authResult);
        if (err) {
          removeDefaultConnection();
          return reject(err);
        };
        this.setSession(authResult);
        resolve();
      });
    });
  }
}

const auth0Client = new Auth();

export default auth0Client;