import axios from "axios";

const FILE_SERVICE_URL = process.env.REACT_APP_FILE_SERVICE;

export const getMultipleFilesApi = (docs, s3) => {
  return axios.post(
    `${FILE_SERVICE_URL}/v1/files/bulkDownload`,
    {
      // Parameters have been spread because file service has validation to throw error on empty arrays
      ...(docs.length ? { docs } : {}),
      ...(s3.length ? { s3 } : {}),
    },
    {
      responseType: "blob",
    }
  );
};
