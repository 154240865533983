import _ from "lodash";
import Api from "../../../../lib/api";
const loadOptions = async (
  currentValue,
  prevOptions,
  { page },
  hiringClientId
) => {
  const next = {
    options: prevOptions,
    hasMore: false,
    additional: { page },
  };

  try {
    const searchParams = {
      byId: true,
      pageNumber: page,
      pageSize: 10,
      hiringClientId,
    };
    if (currentValue) searchParams.idLike = currentValue;
    const params = new URLSearchParams(searchParams);
    const response = await Api.get(`users?${params}`);
    const { data } = response.data;
    const { results, counts } = response.data.data;
    next.options = results;
    next.hasMore = counts.hasNextPage > 0 && results.length > 0;
    next.additional = { page: next.hasMore ? page + 1 : page };
  } catch (error) {
    console.error(error);
  }
  return next;
};

export default loadOptions;
