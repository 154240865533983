export const SET_HC_LIST_SUCCESS = 'SET_HC_LIST_SUCCESS';
export const SET_HC_LIST_ERROR = 'SET_HC_LIST_ERROR';
export const SET_SHOW_MODAL = 'SET_SHOW_MODAL';
export const SET_ASSOCIATING_HCUSER = 'SET_ASSOCIATING_HCUSER';
export const SET_ASSOCIATING_OPERATOR = 'SET_ASSOCIATING_OPERATOR';
export const SET_HC_LIST = 'SET_HC_LIST';
export const SET_HC_USERS = 'SET_HC_USERS';
export const SET_FETCHING_HC = 'SET_FETCHING_HC';
export const SET_SUBMITTING_HC = 'SET_SUBMITTING_HC';
export const SET_SUBMITTED_HC = 'SET_SUBMITTED_HC';
export const SET_TOTAL_AMOUNT_OF_HC = 'SET_TOTAL_AMOUNT_OF_HC';
export const ADD_SERIALIZED_HC_DATA = 'ADD_SERIALIZED_HC_DATA';
export const SET_SHOW_HOLDERS_MODAL = 'SET_SHOW_HOLDERS_MODAL';
export const SET_FETCHING_FILTERS = 'SET_FETCHING_FILTERS';
export const SET_SAVING_FILTERS = 'SET_SAVING_FILTERS';
export const SET_FILTER_BOX = 'SET_FILTER_BOX';
export const SET_PAGE_SIZE = 'SET_HC_PAGE_SIZE';
