import { getYurbiUserInfo } from "../api";
import Swal from "sweetalert2";
import en from "../../../localization/languages/en"
const YURBI_SERVICE_URL = process.env.REACT_APP_YURBI_SERVICE_URL;

/**
 * Get yurbi user data from backend and sign-in user using the session token,
 * uses this sessionToken to login users to yurbi
 * Opens a new tab with the sessionToken as params
 */
export const openYurbi = async (authToken, biAccess) => {
    try {
      const yurbiUserInfo = await getYurbiUserInfo(authToken);
      const { isVRMSAdmin, yurbi } = yurbiUserInfo;
      const { SessionToken } = yurbi;

      if(!biAccess){
        throw Error(en.yurbi.redirectErrorMessage);
      }

      if (!isVRMSAdmin || !SessionToken )  {
        throw Error(en.yurbi.redirectErrorMessage);
      }
      // This would open a new window with user logged it to Yurbi
      window.open(`${YURBI_SERVICE_URL}/analytics/vrms_auth.html?s=${SessionToken}`);

    } catch (error) {
      Swal({
        title: en.yurbi.authorizationErrorMessage,
        html: `${error}`,
        type: 'error',
      });
    }
};
