import React, { useCallback, useRef } from 'react';
import { Field, reduxForm, change ,touch , formValueSelector} from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { AsyncPaginate } from 'react-select-async-paginate';
import renderField from '../../../customInputs/renderField';
import * as regex from '../../../../lib/regex';
import * as applyActions from '../../../common/countyDropdownActions/actions'
import * as loadApplyOptions from '../../../common/countyDropdownActions/loadOptions'
import renderSelect from '../../../customInputs/renderSelect'
import Utils from '../../../../lib/utils';

const validate = (values, props) => {

  const {
    requiredValidation,
    wrongEmailFormat,
    wrongPhoneFormat
  } = props.local.strings.scProfile.ChangeLocationsModal.modal;


  const errors = {};
  if(!values.Address) {
    errors.Address = requiredValidation;
  }
  if(!values.City) {
    errors.City = requiredValidation;
  }
  if(values.CountryID && !values.StateId) {
    errors.State = requiredValidation;
  }
  if(!values.ZipCode) {
    errors.ZipCode = requiredValidation;
  }
  if(!values.CountryID) {
    errors.CountryID = requiredValidation;
  }
  if(values.ContactEmail && !values.ContactEmail.match(regex.EMAIL)) {
    errors.ContactEmail = wrongEmailFormat;
  }
  if (values.Phone && !regex.PHONE.test(values.Phone)) {
    errors.Phone = wrongPhoneFormat;
  }
  if (values.Fax && !regex.PHONE.test(values.Fax)) {
    errors.Fax = wrongPhoneFormat;
  }
  if(!values.hiringClient) {
    errors.hiringClient = requiredValidation;
  }

  return errors;
};
const SelectAsyncOptions = ({ input, meta, dispatch, ...props }) => {
  const touchedError = meta.touched && meta.error;
  const name = input.name;
  const baseClassName = `${props.className || ''} select-${name}`;
  const errorClassName = `${touchedError ? `${baseClassName}-error` : ''}`;
  const ref = useRef({ tabClick: false, selection: false });

  const onKeyDown = useCallback((e) => {
    const TabCode = 9;
    if (e.keyCode !== TabCode) {
      ref.current.selection = true;
    }
    else if (e.keyCode === TabCode) {
      ref.current.tabClick = true;
    }
  }, []);

  const onFocus = useCallback(() => {
    ref.current.selection = false; 
    ref.current.tabClick = false;
  }, []);

  const onChange = useCallback((newValue) => {
    if (
      ref.current.selection || 
      (!ref.current.selection && !ref.current.tabClick) ||
      (newValue && newValue.value !== "" && input.value === "")
      ) {
      input.onChange(newValue)
    }
  }, [input]);

  return (
    <React.Fragment>
      <AsyncPaginate
        {...input}
        {...props}
        openMenuOnClick={true}
        closeMenuOnScroll={true}
        cacheOptions={true}
        pageSize={10}
        additional={{ page: 1 }}
        classNamePrefix="vk"
        className={`${baseClassName} ${errorClassName}`}
        menuPlacement="auto"
        onFocus={onFocus}
        onBlur={() => meta.dispatch(touch('LocationForm', name))}
        onKeyDown={onKeyDown}
        onChange={onChange}
        captureMenuScroll={true}
        closeMenuOnSelect={true}
        debounceTimeout={300}
        loadOptionsOnMenuOpen={true}
      />
      {touchedError && (
        <span className="select-error-message field-validation-message">
          {meta.error}
        </span>
      )}
    </React.Fragment>
  );
};

class LocationForm extends React.Component {
  constructor(props) {
    super(props);

    const { location } = this.props;
    const { hiringClientsList } = this.props.scProfile.headerDetails;
    props.dispatch(change('LocationForm', 'hiringClient', hiringClientsList.length > 1 ? '' : hiringClientsList[0].hiringClientId));
    if(!location){
      props.dispatch(change('LocationForm', 'CountryID', {value:1,label:"United States"}));
    }
    this.state = {
      selectedCountry: location && location.CountryID || 1
    }
    if (location) {
      this.setState({selectedCountry:location.CountryID})
      props.dispatch(change('LocationForm', 'Address', location.Address ||""));
      props.dispatch(change('LocationForm', 'City', location.City ||""));
      props.dispatch(change('LocationForm', 'StateId', {value:location.StateId,label:location.StateName} || "" ));
      props.dispatch(change('LocationForm', 'ZipCode', location.ZipCode != 'null' ? location.ZipCode : ""));
      props.dispatch(change('LocationForm', 'CountryID', {value:location.CountryID,label:location.CountryName} ||""));
      props.dispatch(change('LocationForm', 'Comments', location.Comments && location.Comments != 'null' ? location.Comments : ""));
      props.dispatch(change('LocationForm', 'Active', location.Active ? true : false));
      props.dispatch(change('LocationForm', 'Primary', location.PrimaryLocation ? true : false));
      props.dispatch(change('LocationForm', 'Phone', location.Phone != 'null' ? location.Phone : ""));
      props.dispatch(change('LocationForm', 'Fax', location.Fax != 'null' ? location.Fax : ""));
      props.dispatch(change('LocationForm', 'ContactName', location.ContactName && location.ContactName != 'null' ? location.ContactName : ""));
      props.dispatch(change('LocationForm', 'ContactEmail', location.ContactEmail != 'null' ? location.ContactEmail : ""));
      props.dispatch(change('LocationForm', 'hiringClient', location.HiringClientID ? location.HiringClientID : "" ));

    }
    this.onCheckPrimary = this.onCheckPrimary.bind(this)

  }

  onCheckPrimary(input) {
    if (input) {
      this.props.dispatch(change('LocationForm', 'Active', true))
    }
  }

  render() {
    const {handleSubmit, dismiss} = this.props;

    const {
      labelAddress,
      labelCity,
      labelZipCode,
      labelCountry,
      labelComments,
      labelActive,
      labelPrimary,
      labelPhone,
      labelFax,
      labelContactName,
      labelContactEmail,
      buttonSave,
      buttonDelete,
      buttonCancel,
      emailPlaceholder,
      countriesPlaceHolder,
      labelHiringClient,
      hiringClientPlaceholder
    } = this.props.local.strings.scProfile.ChangeLocationsModal.modal;

   
    const { hiringClientsList } = this.props.scProfile.headerDetails;
    // const countriesList = Utils.getOptionsList(countriesPlaceHolder, countries, 'name', 'id', 'name');
    const {
      labelState,
      statesPlaceHolder,
    } = this.props.local.strings.scProfile.ChangeLocationsModal.modal;

    const hiringClientsOptions = Utils.getOptionsList(hiringClientPlaceholder, hiringClientsList, 'name', 'hiringClientId', 'name');

    const twoPerColLabel = 4
    const twoPerColField = 8 
    const onePerColLabel = 2
    const onePerColField = 10
    return (
      <form onSubmit={handleSubmit} className="list-view-filter-form noteForm">
        <div className="container-fluid filter-fields">
          <div className="row">

            <div className="col-sm-12 no-padd">
              <div className="admin-form-field-wrapper select-hc">
                <label className={`location col-sm-${onePerColLabel} no-padd`} htmlFor="Address">
                  {labelHiringClient}:
                </label>
                <Field
                    className={`col-sm-${onePerColField} no-padd`}
                    name="hiringClient"
                    component={renderSelect}
                    options={hiringClientsOptions}
                />
              </div>
            </div>

          </div>
          <div className="row">

            <div className="col-sm-12 no-padd">
              <div className="admin-form-field-wrapper">
                <label className={`location col-sm-${onePerColLabel} no-padd`} htmlFor="Address">
                  {labelAddress}:
                </label>
                <Field
                  className={`floatRight col-sm-${onePerColField} no-padd`}
                  name="Address"
                  type="text"
                  component={renderField}
                />
              </div>
            </div>

          </div>
          <div className="row">

            <div className="col-md-6 col-sm-12 no-padd">
              <div className="admin-form-field-wrapper">
                <label className={`location col-sm-${twoPerColLabel} no-padd`} htmlFor="City">
                  {labelCity}:
                </label>
                <Field
                  className={`floatRight col-sm-${twoPerColField} no-padd`}
                  name="City"
                  type="text"
                  component={renderField}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12 no-padd">
                  <div className="admin-form-field-wrapper pad-left">
                      <label className={`location col-sm-${twoPerColLabel} no-padd`} htmlFor="StateId">
                        {labelState}:
                      </label>
                      <div className={`select-wrapper floatRight col-sm-${twoPerColField} no-padd`}>
                        <Field
                          blurInputOnSelect={true}
                          openMenuOnFocus={true}
                          placeholder={statesPlaceHolder}
                          name="StateId"
                          component={SelectAsyncOptions}
                          cacheUniqs={[this.state.selectedCountry]}
                          loadOptions={this.props.actions.loadStates(this.props,this.state.selectedCountry)}
                        />
                      </div>
                    </div>
            </div>
            

          </div>
          <div className="row">

            <div className="col-md-6 col-sm-12 no-padd">
              <div className="admin-form-field-wrapper">
                <label className={`location col-sm-${twoPerColLabel} no-padd`} htmlFor="ZipCode">
                  {labelZipCode}:
                </label>
                <Field
                  className={`floatRight col-sm-${twoPerColField} no-padd`}
                  name="ZipCode"
                  type="text"
                  component={renderField}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12 no-padd">
              <div className="admin-form-field-wrapper pad-left">
                <label className={`location col-sm-${twoPerColLabel} no-padd`} htmlFor="CountryID">
                    {labelCountry}:
                </label>
                <div className={`select-wrapper floatRight col-sm-${twoPerColField} no-padd`}>
                  <Field
                    blurInputOnSelect={true}
                    openMenuOnFocus={true}
                    required={false}
                    placeholder={countriesPlaceHolder}
                    name="CountryID"
                    onChange={
                      (e)=>{this.setState({selectedCountry:e.value});
                      if(this.state.seletedCountry !== e.value ){this.props.dispatch(change('LocationForm', 'StateId', "" ))}}
                    }
                    component={SelectAsyncOptions}
                    loadOptions={this.props.actions.loadCountries(this.props)}
                  />
                </div>
              </div>
            </div>

          </div>
          <div className="row">

            <div className="col-md-6 col-sm-12 no-padd">
              <div className="admin-form-field-wrapper">
                <label className={`location col-sm-${twoPerColLabel} no-padd`} htmlFor="Phone">
                  {labelPhone}:
                </label>
                <Field
                  className={`floatRight col-sm-${twoPerColField} no-padd`}
                  name="Phone"
                  type="text"
                  component={renderField}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12 no-padd">
              <div className="admin-form-field-wrapper pad-left">
                <label className={`location col-sm-${twoPerColLabel} no-padd`} htmlFor="Fax">
                  {labelFax}:
                </label>
                <Field
                  className={`floatRight col-sm-${twoPerColField} no-padd`}
                  name="Fax"
                  type="text"
                  component={renderField}
                />
              </div>
            </div>

          </div>
          <div className="row">

            <div className="col-sm-12 no-padd">
              <div className="admin-form-field-wrapper">
                <label className={`location col-sm-${onePerColLabel} no-padd`} htmlFor="ContactName">
                  {labelContactName}:
                </label>
                <Field
                  className={`floatRight col-sm-${onePerColField} no-padd`}
                  name="ContactName"
                  type="text"
                  component={renderField}
                />
              </div>
            </div>

          </div>
          <div className="row">

            <div className="col-sm-12 no-padd">
              <div className="admin-form-field-wrapper">
                <label className={`location col-sm-${onePerColLabel} no-padd`} htmlFor="ContactEmail">
                  {labelContactEmail}:
                </label>
                <Field
                  className={`floatRight col-sm-${onePerColField} no-padd`}
                  name="ContactEmail"
                  placeholder={emailPlaceholder}
                  type="text"
                  component={renderField}
                />
              </div>
            </div>

          </div>
          <div className="row">

          <div className="col-md-2 col-sm-0 no-padd"></div>

          {this.props.location && this.props.location.PrimaryLocation ? null :
            <div className="col-md-5 col-sm-12 no-padd">
              <div className="admin-form-field-wrapper">
                <Field
                  className='locationCheckBox col-sm-1'
                  name="Primary"
                  type="checkbox"
                  callback={(input) => { this.onCheckPrimary(input) }}
                  component={renderField}
                />
                <label className="add-file-label custLabel col-sm-11" htmlFor="Primary">
                  {labelPrimary}
                </label>
              </div>
            </div>
          }

            <div className="col-md-5 col-sm-12 no-padd">
              <div className="admin-form-field-wrapper">
                <Field
                  className='locationCheckBox col-sm-1'
                  name="Active"
                  type="checkbox"
                  disabled={ this.props.primary ? true : false}
                  component={renderField}
                />
                <label className="add-file-label custLabel col-sm-11" htmlFor="Active">
                  {labelActive}
                </label>
              </div>
            </div>

          </div>
          <div className="row">

            <div className="col-sm-12 no-padd">
              <div className="admin-form-field-wrapper">
                <label className={`location col-sm-${onePerColLabel} no-padd`} htmlFor="Comments">
                  {labelComments}:
                </label>
                <Field
                  className={`floatRight col-sm-${onePerColField} no-padd`}
                  name="Comments"
                  type="textarea"
                  component={renderField}
                />
              </div>
            </div>

          </div>

        </div>

        

        <div className="noteEditorButtons">
          <a className="bg-sky-blue-gradient bn" onClick={dismiss}>{buttonCancel}</a>
          <button className="bg-sky-blue-gradient bn" type="submit">{buttonSave}</button>
          {   this.props.isPQuser
              ? <a className="bg-sky-blue-gradient redCancel marg bn" onClick={ () => {this.props.onDelete(this.props.location.Id)} }>{buttonDelete}</a>
              : null
          }
        </div>

      </form>
    );
  }
}

LocationForm = reduxForm({
  form: 'LocationForm',
  validate
})(LocationForm);

const selector = formValueSelector('LocationForm'); // <-- same as form name


const mapStateToProps = (state) => {
  const primary = selector(state, 'Primary');
   return ({ scProfile: state.SCProfile,
    local: state.localization,
    primary
})};

const mapDispatchToProps = (dispatch) => ({
    actions: {
      ...bindActionCreators(applyActions, dispatch),
      ...loadApplyOptions
    }

  });

export default connect(mapStateToProps, mapDispatchToProps)(LocationForm);
