import axios from 'axios';
import * as types from './types';
const URL_API = process.env.REACT_APP_URL_API;

export const saveFile = (file, expDate, oldFileId, hcId, scId, savedFormId, formSectionFieldId) => {
    return (dispatch, getState) => {
        const { login } = getState();
        const token = login.authToken;
        dispatch(setFileIsSaving());
        let formData = new FormData();
        formData.append("hiringClientId", hcId);
        formData.append("subContractorId", scId);
        formData.append("savedFormId", savedFormId);
        formData.append("formSectionFieldId", formSectionFieldId);
        formData.append("oldFileId", oldFileId);
        formData.append("newFileName", file.name);
        formData.append("expDate", expDate);
        formData.append("file", file);
        axios({
            method: 'post',
            url: `${URL_API}forms/update-expired-file`,
            data: formData,
            headers: { 'x-access-token': token, 'Content-Type': 'multipart/form-data' }
        })
            .then(res => {
                dispatch(setFileSaveSuccess());
            })
            .catch(err => {
                const errorMsg = err.response.data.message
                dispatch(setFileSaveFailed(errorMsg));
            });
    }
}

const setFileIsSaving = () => {
    return {
        type: types.FILE_IS_SAVING
    }
}

const setFileSaveSuccess = () => {
    return {
        type: types.FILE_SAVE_SUCCESS
    }
}

const setFileSaveFailed = (errMsg) => {
    return {
        type: types.FILE_SAVE_FAILED,
        error: errMsg
    }
}
