import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select'
import Utils from '../../../../lib/utils';

import renderField from '../../../customInputs/renderField';
import renderSelect from '../../../customInputs/renderSelect';
import renderRemovable from '../../../customInputs/renderRemovable';
import renderTypeAhead from '../../../customInputs/renderTypeAhead';

import * as commonActions from '../../../common/actions';
import * as registerActions from '../../../register/actions';
import FilterActions from '../../../common/filterActions/FilterActions';

class FilterProjectInsureds extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedRequiredCoverages: [],
    }
  };

  componentDidMount() {
    this.props.commonActions.fetchCoverageTypesTypeAhead({});
    this.props.registerActions.fetchGeoStates();
  }

  renderFormField = (element, idx) => {
    const {
      name,
      label,
      ph,
      type,
      conditional,
      show,
      options,
    } = element;

    const style = {};
    if (conditional && !show) {
      style.display = 'none';
    }

    if (type === 'typeAhead') {
      const { fetching, results, error, handleSearch, onSelect } = element;

      return (
        <div className="col-md no-padd" key={idx} style={style}>
          <div className="admin-form-field-wrapper filter-field">
            <label htmlFor={name} style={{ margin: '2px' }}>{`${label}:`}</label>
            <Field
              resetOnClick
              name={name}
              placeholder={ph}
              fetching={fetching}
              results={results}
              handleSearch={handleSearch}
              fetchError={error}
              component={renderTypeAhead}
              onSelect={onSelect}
            />
          </div>
        </div>
      );
    } else if (type === 'removable') {
      const { valueText, disabled, onRemove } = element;
      return (
        <div className="col-md no-padd" key={idx} style={style}>
          <div className="admin-form-field-wrapper filter-field">
            <label htmlFor={name} style={{ margin: '2px' }}>{`${label}:`}</label>
            <Field
              name={name}
              valueText={valueText}
              component={renderRemovable}
              onRemove={onRemove}
              disabled={disabled}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="col-md no-padd" key={idx} style={style}>
        <div className="admin-form-field-wrapper filter-field">
          <label htmlFor={name} style={{ margin: '2px' }}>{`${label}`}: </label>
          {
          options?
          <div className="select-wrapper">
            <Field
              name={name}
              component={renderSelect}
              options={options}
            />
          </div>
          :
          <Field
            name={name}
            type="text"
            placeholder={ph}
            component={renderField}
          />
        }
        </div>
      </div>
    );
  }

  renderCoverageTypesOptions = () => {
    const options = [];
    const {
      typeAheadResults
    } = this.props.common;

    typeAheadResults && typeAheadResults.map((item, idx) => (
      options.push({ value: item.Code, label: item.Code })
    ));
    return options;
  }

  handleChange = (e) => {    
    this.setState({
      selectedRequiredCoverages: e && e.length > 0 ? e.map(x => x.value) : []
    });
  }  

  onHandleSubmit = (values) => {
    const { selectedRequiredCoverages } = this.state;
    values.requiredCoverages = (selectedRequiredCoverages.length > 0)
      ? selectedRequiredCoverages.join(',')
      : "";

    this.props.onSubmit(values);
  }

  render() {
    const {
      projectNumberLabel,
      projectNameLabel,
      holderNameLabel,
      requiredCoveragesLabel,
      stateLabel
    } = this.props.local.strings.copyCertificate.insuredList.filter;
    
    const { 
      handleSubmit,
      register 
    } = this.props;    
    
    const stateOptionsList = Utils.getOptionsList(`-- Select a ${stateLabel} --`, register.geoStates, 'Name', 'ShortName', 'Name');
    
    const fields = [
      { name: 'projectNumber', label: projectNumberLabel, ph: `-- ${projectNumberLabel} --` },
      { name: 'projectName', label: projectNameLabel, ph: `-- ${projectNameLabel} --` },
      { name: 'holderName', label: holderNameLabel, ph: `-- ${holderNameLabel} --` },
    ];
    
    return (
      <form onSubmit={handleSubmit(values => this.onHandleSubmit(values))} style={{ padding: '2px' }}>
        <div className="container-fluid filter-fields">
          <div className="row">
            {fields.map(this.renderFormField)}
          </div>

          <div className="row">
            <div className="col-md-4 no-padd">
              <div className="admin-form-field-wrapper filter-field">
                <label htmlFor="state">{stateLabel}: </label>
                <div className="select-wrapper">
                  <Field
                    name="state"
                    component={renderSelect}
                    options={stateOptionsList}
                    className="input-sm"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 no-padd">
              <div className="admin-form-field-wrapper filter-field">
                <label htmlFor="requiredCoverages">{requiredCoveragesLabel}: </label>
                <div className="select-wrapper-for-multiple">
                  <Select
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    name="requiredCoverages"
                    options={this.renderCoverageTypesOptions()}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
            <FilterActions
              formName={this.props.form}
              dispatch={this.props.dispatch}
              className='copy-certificate-filter-actions'
            />
          </div>
        </div>
      </form>
    );
  }
}

FilterProjectInsureds = reduxForm({
  form: 'FilterProjectInsureds',
})(FilterProjectInsureds);

const mapStateToProps = (state) => {
  return {
    register: state.register,
    local: state.localization,
    common: state.common,
    initialValues: {
      projectNumber: '',
      projectName: '',
      holderName: '',
      requiredCoverages: '',
    }
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
    registerActions: bindActionCreators(registerActions, dispatch),  
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterProjectInsureds);
