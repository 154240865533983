import React from 'react';
import { connect } from 'react-redux';
import  Loading  from '../../../../common/spinner';
import {Accordion, Card} from "react-bootstrap";

class FormioTableOfContents extends React.Component {
  constructor(props){
      super(props);
  }

  Headings = ( item, idx ) => {
    const accordianBg = {backgroundColor: '#085DAD'}
    return (<div key={idx} className='table-of-contents-item'>
          <Accordion key="1" defaultActiveKey="1" className='table-of-contents-item page-item'>
            <Accordion.Toggle as={Card.Header} eventKey="1" style={accordianBg}>
            <a href={`#${item.pageTitle}`}
            onClick={(e) => {
              e.preventDefault();
              if(document.getElementsByClassName('pagination').length){
                document.getElementsByClassName('pagination')[0].children[idx].children[0].click()
              }
              if (item.queryId && document.querySelector(`#${item.queryId}`)) {
                document.querySelector(`#${item.queryId}`).scrollIntoView({
                  behavior: "smooth"
                });
              }
            }}
            className='table-of-contents-item page-item'
            > {item.pageTitle ?`${item.pageTitle}`: ''}</a>
            <li className='table-of-contents-item'>
              <a href={`#${item.pageContent}`}
              onClick={(e) => {
                e.preventDefault();
                if(document.getElementsByClassName('pagination').length){
                  document.getElementsByClassName('pagination')[0].children[idx].children[0].click()
                }
                if (document.querySelector(`#${item.queryId}`)) {
                  document.querySelector(`#${item.queryId}`).scrollIntoView({
                    behavior: "smooth"
                  });
                }
              }}
              className='table-of-contents-item page-item'
              > {item.pageContent ?`- ${item.pageContent}`: ''}</a>
            </li>
            </Accordion.Toggle>
            {item.child.length > 0 && (
            <Accordion.Collapse eventKey="1">
              <Card.Body className='p-1'>
                <ul>
                  {item.child.map((child, index) => {
                    return (
                      <li key={index}>
                        <a href={`#${child.key}`}
                          onClick={(e) => {
                            e.preventDefault();
                            if(document.getElementsByClassName('pagination').length){
                              document.getElementsByClassName('pagination')[0].children[idx].children[0].click()
                            }
                            if (document.querySelector(`#${child.id}`)) {
                              document.querySelector(`#${child.id}`).scrollIntoView({
                                behavior: "smooth"
                              });
                            }
                          }}
                        className='table-of-contents-item page-item'
                        >-{child.legend}</a>
                        {item.child2?<ul>
                          {item.child2[0].map((nestedChild, nestedIdx) => {
                            return(
                              <li className='table-of-contents-item'>
                                <a href={`#${nestedChild.legend}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  if(document.getElementsByClassName('pagination').length){
                                    document.getElementsByClassName('pagination')[0].children[idx].children[0].click()
                                  }
                                  if (document.querySelector(`#${nestedChild.id}`)) {
                                    document.querySelector(`#${nestedChild.id}`).scrollIntoView({
                                      behavior: "smooth"
                                    });
                                  }
                                }}
                                className='table-of-contents-item page-item'>-{nestedChild.legend}</a>
                              </li>
                            )
                          })}
                        </ul> : ''}
                      </li>
                    )
                  })}
                </ul>
              </Card.Body>
            </Accordion.Collapse>)}
          </Accordion>
      </div>
    )
  };
    
  getFieldSet(form) {
    if (form && form.components) {
      var fieldsLabel = [];
      let tempFieldsLabel;
      for (let compIndex in form.components) {
        let level2 = [];
        let level3 = [];
        if (form.components[compIndex] && form.components[compIndex].components ) {
          const pageTitle = form.components[compIndex].title ? form.components[compIndex].title: form.components[compIndex].legend;
          // Get All Pages Data
          tempFieldsLabel = form.components[compIndex].components.filter(x => x.type === "fieldset")[0];
          if (tempFieldsLabel && tempFieldsLabel.components.length > 0) {
            // Parse Pages Data
            tempFieldsLabel.components.map(item => {
              if (item.type === "fieldset") {
                level3.push(item.components.filter(x => x.type === "fieldset"));
                level2.push(item);
              }
            })
          }
          if (!tempFieldsLabel) {
            if(form.components[compIndex].type === "fieldset"){
              tempFieldsLabel = form.components[compIndex];
            }
           }
          const fieldLabelLegend =  tempFieldsLabel ? tempFieldsLabel.legend : '';
          fieldsLabel.push({ pageTitle: pageTitle === fieldLabelLegend ? '': pageTitle, pageContent: fieldLabelLegend, queryId: tempFieldsLabel? tempFieldsLabel.id : '', child: level2, type: form.display, child2: level3 });
        }
      }
      return fieldsLabel;
    }
    return false;
  }

  render() {
    const tabStyle = { height: '100vh', overflow:'scroll' }
    const contentItems = this.getFieldSet(this.props.formioForms.form)
    if (contentItems) {
      return (
        <div className="position-sticky fixed-top col-sm-6 col-md-3 bg-blue-gradient form-table-of-contents float-left" style={tabStyle}>
          <div className="table-of-contents-title">
            Table of Contents
          </div>
          <div className="table-of-contents-item title-item">
            {`${this.props.formioForms.form.title}`}
          </div>
          {contentItems.length > 0 ?
            <div className="table-of-contents-list">
              {contentItems.map(this.Headings)}
            </div> : null
          }
        </div>
      );
    } else {
      return (null);
    }
  }
}

const mapStateToProps = (state) => {
  return {
    formioForms: state.formioForms
  }
};

export default connect(mapStateToProps)(FormioTableOfContents);
