import React from "react";
import PropTypes from 'prop-types';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import RolAccess from '../../common/rolAccess';

const AMBestCustomToolbar = props => {
  const { classes, handleUploadOpen } = props;

  const renderUploadAction = () => {
    return (
      <Tooltip title={"Import"}>
        <IconButton className={classes.iconButton} onClick={handleUploadOpen}>
          <CloudUploadIcon />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <RolAccess
      masterTab={'AMBest'}
      sectionTab={'ambest_import'}
      component={renderUploadAction}>
    </RolAccess>
  );
};

AMBestCustomToolbar.propTypes = {
  handleUploadOpen: PropTypes.func.isRequired,
};

export default withStyles(null, { name: "AMBestToolbar" })(AMBestCustomToolbar);
