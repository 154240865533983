export const SET_FETCHING_SC_HEADER = 'SET_FETCHING_SC_HEADER';
export const SET_SC_HEADER_DETAILS = 'SET_SC_HEADER_DETAILS';
export const SET_UPDATE_SC_STATUS = 'SET_UPDATE_SC_STATUS';
export const SET_FETCHING_SC_HIRINGCLIENTS = 'SET_FETCHING_SC_HIRINGCLIENTS';
export const SET_SC_HIRINGCLIENTS = 'SET_SC_HIRINGCLIENTS';
export const SET_SC_HC_ID = 'SET_SC_HC_ID';
export const SET_SC_PAYMENT_STATUS = 'SET_SC_PAYMENT_STATUS';
export const SET_SC_PAYMENT_STATUS_ERROR = 'SET_SC_PAYMENT_STATUS_ERROR';
export const SET_SC_LAST_PAYMENT = 'SET_SC_LAST_PAYMENT';

export const SET_FETCHING_LOCATIONS = 'SET_FETCHING_LOCATIONS';
export const SET_TOTAL_AMOUNT_OF_LOCATIONS = 'SET_TOTAL_AMOUNT_OF_LOCATIONS';
export const SET_LOCATIONS_LIST = 'SET_LOCATIONS_LIST';
export const SET_LOCATIONS_LIST_ERROR = 'SET_LOCATIONS_LIST_ERROR';

export const SET_US_STATES_LIST = 'SET_US_STATES_LIST';
export const SET_CANADIAN_PROVINCES_AND_TERRITORIES_LIST = 'SET_CANADIAN_PROVINCES_AND_TERRITORIES_LIST';
export const SET_COUNTRIES_LIST = 'SET_COUNTRIES_LIST';
export const SET_LOCATIONS_ERROR = 'SET_LOCATIONS_ERROR';
export const SET_SAVING_LOCATION = 'SET_SAVING_LOCATION';
export const SET_PRIMARY_MESSAGE = 'SET_PRIMARY_MESSAGE';
export const SET_CHANGING_PRIMARY = 'SET_CHANGING_PRIMARY';

export const SET_LASTEST_SAVED_FORM = 'SET_LASTEST_SAVED_FORM';
export const SET_LASTEST_SAVED_FORM_ERROR= 'SET_LASTEST_SAVED_FORM_ERROR';

// Fetch Subcontractors Trades for the change trades modal
export const SET_CHANGE_TRADES_MODAL_LIST_FETCHING = 'SET_CHANGE_TRADES_MODAL_LIST_FETCHING';
export const SET_CHANGE_TRADES_MODAL_LIST_SUCCESS = 'SET_CHANGE_TRADES_MODAL_LIST_SUCCESS';
export const SET_CHANGE_TRADES_MODAL_LIST_ERROR = 'SET_CHANGE_TRADES_MODAL_LIST_ERROR';

// Update subcontractors trades
export const SET_UPDATE_TRADES_MODAL_FETCHING = 'SET_UPDATE_TRADES_MODAL_FETCHING';
export const SET_UPDATE_TRADES_MODAL_SUCCESS = 'SET_UPDATE_TRADES_MODAL_SUCCESS';
export const SET_UPDATE_TRADES_MODAL_ERROR = 'SET_UPDATE_TRADES_MODAL_ERROR';

export const SET_TAX_ID_EXISTS = 'SET_TAX_ID_EXISTS';
export const SET_MERGE_SUBCONTRACTOTRS_SUCCEEDED = 'SET_MERGE_SUBCONTRACTORS_SUCCEEDED';
export const SET_IS_MERGING_SUBCONTRACTORS = 'SET_IS_MERGING_SUBCONTRACTORS';

export const SET_MOVING_SC_TO_HIRINGCLIENT = 'SET_MOVING_SC_TO_HIRINGCLIENT';
export const SET_IS_SUBCONTRACTOR_MOVED = 'SET_IS_SUBCONTRACTOR_MOVED';
export const SET_SUBCONTRACTOR_MOVE_FAILED = 'SET_SUBCONTRACTOR_MOVE_FAILED';
export const SET_SUBCONTRACTOR_HAS_HC_SIBLINGS = 'SET_SUBCONTRACTOR_HAS_HC_SIBLINGS';
export const SET_HAS_TEMPLATE_DISCONNET = 'SET_HAS_TEMPLATE_DISCONNET';