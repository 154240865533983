import React, { useEffect, useState } from "react";
import moment from "moment";
import Alert from "react-bootstrap/Alert";

const getAnnouncementConfig = () => {
    const requestConfig = {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    };
    return fetch(
        `${process.env.PUBLIC_URL}/config/announcement.json`,
        requestConfig
    ).then((response) => response.json());
};

const showAnnouncement = (config) => {
    const { show, message, shouldExpire, expiryDate } = config;
    if (!show || !message) {
        return false;
    }

    if (shouldExpire) {
        const now = moment();
        const expiry = { ...expiryDate };
        // decrease month by one as month is zero based in moment object parser
        expiry.month--;
        // Don't show the Announcement if already expired
        if (now.isAfter(expiry)) {
            return false;
        }
    }
    return true;
};

const getVariant = (type) => {
    const allowedTypes = [
        "primary",
        "secondary",
        "success",
        "danger",
        "warning",
        "info",
        "light",
        "dark"
    ];
    return allowedTypes.includes(type)? type : "warning";
};

const AnnouncementBar = () => {
    const [show, setShow] = useState(true);
    const [announcementConfig, setAnnouncementConfig] = useState({});

    useEffect(() => {
        getAnnouncementConfig().then((data) => {
            setAnnouncementConfig(data);
        });
    }, []);

    const { type, heading, message } = announcementConfig;

    return show && showAnnouncement(announcementConfig) ? (
        <Alert variant={getVariant(type)} onClose={() => setShow(false)} dismissible>
            <Alert.Heading style={{textAlign: "center"}}>  {heading}  </Alert.Heading>
            <p style={{textAlign: "center"}}> {message} </p>
        </Alert>
    ) : null;
}

export default AnnouncementBar;
