import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';

import ProcessingForm from './form';
import * as documentActions from './../../documents/actions';
import * as processingActions from './../../processing/actions';
import * as commonActions from '../../../common/actions';
import '../addEntityModal.css';
import Api from "../../../../lib/api";

const {
	CF_ADMIN_ROLE_ID,
  DEPARTMENT_MANAGER_ROLE_ID,
  ACCOUNT_MANAGER_ROLE_ID,
  PROCESSOR_ROLE_ID,
  DEFAULT_DATA_ENTRY_QUEUE_ID,
  PENDING_PROCESSING_DOCUMENT_STATUS_ID,
  SENT_TO_PROCESSING_DOCUMENT_STATUS_ID,
  PROCESSING_IN_PROGRESS_DOCUMENT_STATUS_ID,
  PENDING_REVIEW_DOCUMENT_STATUS_ID,
  HOLDER_CLERICAL_ROLE_ID,
  MAGELLAN_QUEUE_ID,
  HOLDER_ADMIN_ROLE_ID,
  HOLDER_EXECUTIVE_ROLE_ID,
  INSURED_ROLE_ID,
  APPROVAL_QUEUE_ID
} = require('../../../../lib/appConstants');

class ProcessingModal extends Component {

  onSubmit = (values) => {
    const { setLoading } = this.props.commonActions;
    const { document } = this.props;
    const { CFRoleId } = this.props.login.profile;
    const availableRoles = [
      CF_ADMIN_ROLE_ID, 
      DEPARTMENT_MANAGER_ROLE_ID,
      ACCOUNT_MANAGER_ROLE_ID,
      PROCESSOR_ROLE_ID
    ];
    const dataEntryQueueDisabledRoles = [
      HOLDER_CLERICAL_ROLE_ID,
      HOLDER_ADMIN_ROLE_ID,
      HOLDER_EXECUTIVE_ROLE_ID,
      INSURED_ROLE_ID,
    ];
    const disabledByRole = (availableRoles.find(e => e === Number(CFRoleId))) ? false : true;
    const disableDataEntryQueueByRoleId = (dataEntryQueueDisabledRoles.find(e => e === Number(CFRoleId))) ? true : false;

    if (disabledByRole) {
      values.queueId = DEFAULT_DATA_ENTRY_QUEUE_ID; // Data Entry default queue
    }
    if(disableDataEntryQueueByRoleId){
      values.queueId = MAGELLAN_QUEUE_ID;
    }
    
    // Document Status
    switch (values.submitType) {
      case 'sendToProcessing':
        values.documentStatusId = SENT_TO_PROCESSING_DOCUMENT_STATUS_ID; break;
      case 'sendToDataEntry':
        values.documentStatusId = PENDING_PROCESSING_DOCUMENT_STATUS_ID; break;
      case 'doDataEntry':
        values.documentStatusId = PROCESSING_IN_PROGRESS_DOCUMENT_STATUS_ID; break;
      default:
        values.documentStatusId = values.documentStatusId;
    }
    
    const payload = {
      documentId: document ? document.DocumentID : undefined,
      hiringClientId: values.holderId ? values.holderId.value : '',
      projectId: values.projectId ? values.projectId.value : '',
      subcontractorId: values.insuredId ? values.insuredId.value : '',
      documentStatusId: values.documentStatusId 
        ? Number(values.documentStatusId) 
        : Number(values.documentTypeId !== 1) ? PENDING_REVIEW_DOCUMENT_STATUS_ID : undefined
      ,
      documentTypeId: values.documentTypeId ? Number(values.documentTypeId) : 0,
      garbage: (values.garbage) ? 1 : 0,
      urgent: (values.urgent) ? 1 : 0,
      queueId: (values.queueId) ? values.queueId : undefined,
      documentDate: (values.documentDate) ? values.documentDate : undefined,
      expireDate: (values.expireDate) ? values.expireDate : undefined,
    };

    setLoading(true);
    
    this.props.documentActions.postDocument(payload, (err, data) => {
      setLoading(false);      
      if (!err) {
        if (
          values.submitType === "doDataEntry" ||
          values.submitType === "sendToDataEntry"
        ) {
          // create certificate
          const certificateData = {
            certificateId:
              document && document.CertificateID
                ? document.CertificateID
                : undefined,
            documentId: document.DocumentID,
            documentUrl: document.DocumentUrl,
            holderId: document.HolderID,
            projectId: document.ProjectID,
            insuredId: document.InsuredID,
            projectInsuredId: document.ProjectInsuredID,
          };

          this.props.processingActions.addCertificate(
            certificateData,
            (err, certificateId) => {
              //console.log('addCertificate:', err, certificateId);
              if (!err) {
                // redirect to DataEntry page
                if (values.submitType === "doDataEntry") {
                  this.props.close();
                  this.openDataEntry(`/certfocus/processing/${certificateId}`);
                } else {
                  if((([PENDING_PROCESSING_DOCUMENT_STATUS_ID, SENT_TO_PROCESSING_DOCUMENT_STATUS_ID].includes(payload.documentStatusId) && payload.queueId === MAGELLAN_QUEUE_ID && document.AllowDocumentAutoProcess)
                    || (SENT_TO_PROCESSING_DOCUMENT_STATUS_ID === payload.documentStatusId))){
                    this.sendDocumentToIDP({...document, CertificateID: certificateId})
                  }
                  // Would Show success Alert and close the Modal for `sendToDataEntry`
                  Swal({
                    type: "success",
                    text: "The Document has successfully been sent to Data Entry for processing",
                    onClose: () => this.props.close(),
                  });
                }
              }
            }
          );
        } else if (values.submitType === "sendToProcessing") {
          // create certificate
          const certificateData = {
            certificateId:
              document && document.CertificateID
                ? document.CertificateID
                : undefined,
            documentId: document.DocumentID,
            documentUrl: document.DocumentUrl,
            holderId: document.HolderID,
            projectId: document.ProjectID,
            insuredId: document.InsuredID,
            projectInsuredId: document.ProjectInsuredID,
          };

          this.props.processingActions.addCertificate(
            certificateData,
            (err, certificateId) => {
              if (!err) {
                if(([PENDING_PROCESSING_DOCUMENT_STATUS_ID, SENT_TO_PROCESSING_DOCUMENT_STATUS_ID].includes(payload.documentStatusId) && payload.queueId === MAGELLAN_QUEUE_ID && document.AllowDocumentAutoProcess)
                  || (SENT_TO_PROCESSING_DOCUMENT_STATUS_ID === payload.documentStatusId)){
                  this.sendDocumentToIDP({...document, CertificateID: certificateId})
                }
                Swal({
                  type: "success",
                  text: "The Document has successfully been sent to Einstein for processing",
                  onClose: () => this.props.close(),
                });
              }
            }
          )
        } else {
          if(([PENDING_PROCESSING_DOCUMENT_STATUS_ID, SENT_TO_PROCESSING_DOCUMENT_STATUS_ID].includes(payload.documentStatusId) && payload.queueId === MAGELLAN_QUEUE_ID && document.AllowDocumentAutoProcess)
            || (SENT_TO_PROCESSING_DOCUMENT_STATUS_ID === payload.documentStatusId)){
            this.sendDocumentToIDP(document)
          }
          Swal({
            type: "success",
            text: "The Document has successfully been saved",
            onClose: this.props.close(),
          });
        }
      } else {
        Swal({
          type: "error",
          text: "Error uploading files. Please try again.",
          onClose: this.props.close(),
        });
      } 
    });
  };

  sendDocumentToIDP = (document) => {
    if(process.env.REACT_APP_ALLOW_DOCUMENT_AUTO_PROCESS === 'true') {
      const { authToken } = this.props.login;
      const body = {
        document_url: document.DocumentUrl,
        holder_id: document.HolderID,
        project_id: document.ProjectID,
        project_insured_id: document.ProjectInsuredID,
        requirements_set_id: document.requirementSetId,
        document_id: document.DocumentID,
        certificate_id: document.CertificateID ? document.CertificateID : '',
      }
      Api.post(`cf/documents/send-to-idp`, body, authToken).then(() => {
        this.props.processingActions.setAutoProcessingDocument({autoProcessingDocument: true})
      });
    }
  }

  openDataEntry = (link) => {
    window.open(link, '_blank');
  }

  hideModal = () => {
    const { onHide, close } = this.props;
    if (onHide) onHide();
    else close();
  }

  render() {
    const {
      title,
      titleEdit
    } = this.props.local.strings.documents.addDocumentModal;

    const text = this.props.document ? titleEdit : title;
    
    return (
      <div className="add-item-view add-entity-form-small">
        <div className="add-item-header">
          <h1>{text}</h1>
        </div>

        <section className="white-section">
          <div className="add-item-form-subsection">
            <ProcessingForm
              document={this.props.document}
              currentUploadData={this.props.currentUploadData}
              close={this.hideModal}
              onSubmit={this.onSubmit} />
          </div>
        </section>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    local: state.localization,
    common: state.common,
    login: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    documentActions: bindActionCreators(documentActions, dispatch),
    processingActions: bindActionCreators(processingActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProcessingModal));
