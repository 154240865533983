export const GET_FORM = 'GET_FORM';

export const SET_BUILDER_LOADING = 'SET_BUILDER_LOADING';
export const ASSIGN_BUILDER_FORM = 'ASSIGN_BUILDER_FORM';
export const CLEAR_BUILDER_FORM = 'CLEAR_BUILDER_FORM';
export const CREATE_FORM_START = 'CREATE_FORM_START';
export const CREATE_FORM_FAILED = 'CREATE_FORM_FAILED';
export const CREATE_FORM_SUCCESS = 'CREATE_FORM_SUCCESS';

export const SECTION_DELETE_SUCCESS = 'SECTION_DELETE_SUCCESS';
export const SECTION_DELETE_FAILED = 'SECTION_DELETE_FAILED';
export const FIELD_DELETE_SUCCESS = 'FIELD_DELETE_SUCCESS';
export const FIELD_DELETE_FAILED = 'FIELD_DELETE_FAILED';

export const SET_AVAILABLE_FILE_WORKFLOWS = 'SET_AVAILABLE_FILE_WORKFLOWS';
export const SET_WORKFLOWS_LOADING = 'SET_WORKFLOWS_LOADING';

export const SET_ARCHIVED_FORM_SUCCESS = 'SET_ARCHIVED_FORM_SUCCESS';
export const SET_ARCHIVED_FORM_FAILED = 'SET_ARCHIVED_FORM_FAILED';

