import React from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm, touch} from 'redux-form';
import {AsyncPaginate} from 'react-select-async-paginate';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import _ from 'lodash';

import Utils from '../../../../lib/utils';
import renderField from '../../../customInputs/renderField';
import validate from './validate';
import loadOptions from './loadOptions';

const SelectTitle = ({input, meta, dispatch, ...props}) => {
  const touchedError = meta.touched && meta.error;
  const baseClassName = `${props.className || ''} select-title`;
  const errorClassName = `${touchedError ? `${baseClassName}-error` : ''}`;

  return (
    <React.Fragment>
      <AsyncPaginate
        {...input}
        {...props}
        isSearchable={true}
        openMenuOnClick={true}
        openMenuOnFocus={true}
        closeMenuOnScroll={true}
        cacheOptions={true}
        pageSize={10}
        additional={{ page: 1 }}
        classNamePrefix="vk"
        className={`${baseClassName} ${errorClassName}`}
        menuPlacement="auto"
        onFocus={() => {}}
        onBlur={() => meta.dispatch(touch('UserInformationForm', 'title'))}
        onChange={(newValue) => input.onChange(newValue)}
        captureMenuScroll={true}
        closeMenuOnSelect={true}
        escapeClearsValue={true}
        isClearable={true}
        loadOptionsOnMenuOpen={true}
      />
      {touchedError && (
        <span className="select-error-message field-validation-message">
          {meta.error}
        </span>
      )}
    </React.Fragment>
  );
};

const UserInformationForm = (props) => {
  const {
    firstNameLabel,
    firstNamePlaceholder,
    lastNameLabel,
    lastNamePlaceholder,
    titleLabel,
    phoneLabel,
    phonePlaceholder,
    cellPhoneLabel,
    cellPhonePlaceholder,
    emailLabel,
    mainEmailPlaceholder,
    passwordLabel,
    retypePassword,
    passwordPlaceholder,
    userButtonText,
    titlesDropdownPlaceholder,
    titlesDropdownNoOptionsMessage,
    titlesDropdownLoadingMessage
  } = props.local.strings.register.userInformation;

  const getContinueButtonProps = (props) => {
    const attrs = {
      className: 'bn bg-blue-gradient',
      disabled: false,
      type: 'submit',
      'data-testid': 'continue-button'
    };
    
    if (props.asyncValidating) {
      attrs.className += ' wiz-continue-btn-disabled';
      attrs.disabled = true;
    }

    return attrs;
  };

  const onSubmit = (event) => {
    event.preventDefault();

    props.dispatch(touch(
      'UserInformationForm', 
      'firstName',
      'lastName',
      'title',
      'phone',
      'email',
      'password',
      'retypePassword',
    ));
    
    if (props.valid) {
      props.onSubmit(props.currentForm.UserInformationForm.values);
    }
  };

  return (
    <form 
      className="user-information-form"
      data-testid="user-information-form"
      onSubmit={onSubmit}
      role="form"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-sm-12 step-col-left">
            <div className="register-field-wrapper">
              <label htmlFor="firstName" className="required">
                {firstNameLabel}
              </label>
              <Field
                name="firstName"
                type="text"
                placeholder={firstNamePlaceholder}
                component={renderField}
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12 step-col-right">
            <div className="register-field-wrapper">
              <label htmlFor="lastName" className="required">
                {lastNameLabel}
              </label>
              <Field
                name="lastName"
                type="text"
                placeholder={lastNamePlaceholder}
                component={renderField}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 step-col-left">
            <div className="register-field-wrapper">
              <label htmlFor="title" className="required">
                {titleLabel}
              </label>
              <div className="register-select-wrapper">
                <Field
                  name="title"
                  required={true}
                  component={SelectTitle}
                  placeholder={titlesDropdownPlaceholder}
                  noOptionsMessage={() => titlesDropdownNoOptionsMessage}
                  loadingMessage={() => titlesDropdownLoadingMessage}
                  loadOptions={props.actions.loadOptions({
                    dispatch: props.dispatch,
                    local: props.local.strings.register,
                    tempToken: props.register.tempToken
                  })}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 step-col-right">
            <div className="register-field-wrapper">
              <label htmlFor="phone" className="required">
                {phoneLabel}
              </label>
              <Field
                name="phone"
                type="text"
                placeholder={phonePlaceholder}
                component={renderField}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 step-col-left">
            <div className="register-field-wrapper">
              <label htmlFor="cellPhone">
                {cellPhoneLabel}
              </label>
              <Field
                name="cellPhone"
                type="text"
                placeholder={cellPhonePlaceholder}
                component={renderField}
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12 step-col-right">
            <div className="register-field-wrapper">
              <label htmlFor="email" className="required">
                {emailLabel}
              </label>
              <Field
                name="email"
                type="text"
                placeholder={mainEmailPlaceholder}
                component={renderField}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12 step-col-left">
            <div className="register-field-wrapper">
              <label htmlFor="password" className="required">
                {passwordLabel}
              </label>
              <Field
                name="password"
                type="password"
                placeholder={passwordPlaceholder}
                component={renderField}
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12 step-col-right">
            <div className="register-field-wrapper">
              <label htmlFor="retypePassword" className="required">
                {retypePassword}
              </label>
              <Field
                name="retypePassword"
                type="password"
                placeholder={passwordPlaceholder}
                component={renderField}
              />
            </div>
          </div>
        </div>
        <div className="step-button">
          <button {...getContinueButtonProps(props)}>
            {userButtonText}
          </button>
        </div>
      </div>
    </form>
  );
};

UserInformationForm.propTypes = {
  local: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentForm: PropTypes.object,
  userPayload: PropTypes.object,
  actions: PropTypes.shape({
    loadOptions: PropTypes.func
  })
};

UserInformationForm.defaultProps = {
  dispatch: () => {},
  onSubmit: () => {},
  valid: false,
  asyncValidating: false,
  local: {},
  currentForm: {},
  userPayload: {},
  actions: {},
  register: {}
};

const withReduxForm = reduxForm({
  form: 'UserInformationForm',
  validate,
  touchOnBlur: true,
  touchOnChange: true,
  enableReinitialize: true
});

const mapStateToProps = (state, ownProps) => {
  const {getValuesOnlyIfNotEmpty: onlyValues, toOption} = Utils;
  const fromState = onlyValues(state, 'register.userPayload');
  const fromProps = onlyValues(ownProps, 'userPayload');
  const payload = fromState || fromProps || {};

  return {
    currentForm: state.form,
    local: state.localization,
    register: state.register,
    initialValues: {
      firstName: payload.firstName || '',
      lastName: payload.lastName || '',
      title: toOption(payload.titleId, payload.titleName),
      phone: payload.phone || '',
      cellPhone: payload.cellPhone || '',
      email: payload.email || '',
      password: payload.password || '',
      retypePassword: payload.password || '',
    }
  };
};

const mapDispatchToProps = (dispatch) => ({actions: {loadOptions}});

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(withRedux(withReduxForm(UserInformationForm)));