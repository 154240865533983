import React from "react";
import PropTypes from 'prop-types';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const defaultToolbarStyles = {
  iconButton: {
  },
};

class AMBestImportHistoryToolbar extends React.Component {

  render() {
    const { classes, handleCloseClick } = this.props;

    return (
      <React.Fragment>
        <Tooltip title={"Close"}>
          <IconButton className={classes.iconButton} onClick={handleCloseClick}>
            <HighlightOffIcon />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }

}

AMBestImportHistoryToolbar.propTypes = {
  handleCloseClick: PropTypes.func.isRequired,
}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(AMBestImportHistoryToolbar);
