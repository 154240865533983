import React, {Component} from 'react';
import IdleTimer from 'react-idle-timer';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {IdleTimeModal}  from './IdleTimeModal';
import { AUTH_TOKEN } from '../../../lib/appConstants';
import * as refreshTokenAction from '../refreshToken/actions'


class IdleTime extends Component {

  constructor(props) {
    super(props)

    this.state = {
      timeout: 1000 * 60 * 45,
      show: false
    }

    this.idleTimer = null
    this.logoutTimer = null
    this._OnActive = this._OnActive.bind(this)
    this._OnIdle = this._OnIdle.bind(this)
    this._OnActive = this._OnActive.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  _OnActive = () => {
    if (this.logoutTimer) {
      clearTimeout(this.logoutTimer);
      this.logoutTimer = null;
    }
    this.idleTimer.reset();
    this.setState({show: true})
  }

  _OnIdle = () => {
    //const isTimed = this.state.isTimed
    this.setState({show: true});
    this.logoutTimer = setTimeout(() => {
      this.handleLogout();
      this.props.actions.clearRefreshTokenInterval();
    }, 1000 * 45 * 1);
  }

  handleClose = () => {
    this.setState({show: false});
  }

  handleLogout = () => {
    this.setState({show: false});
    localStorage.removeItem(AUTH_TOKEN);
    sessionStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem('SSO');
    let protocol = window.location.protocol;
    let url = window.location.href.split("/")[2];
    window.location.href = `${protocol}//${url}/login`;
  }

  render() {
    return (
      <div>
        <IdleTimer
          crossTab={{ emitOnAllTabs: true }}
          ref={ref => { this.idleTimer = ref }}
          timeout={this.state.timeout}
          onActive={this._OnActive}
          onIdle={this._OnIdle}
        />
        <IdleTimeModal 
          show={this.state.show}
          handleClose={this.handleClose}
          handleLogout={this.handleLogout}
        />
      </div>
    )
  }

}


const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(refreshTokenAction, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(IdleTime);
