import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import html2pdf from 'html2pdf.js';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';

import PTable from '../../common/ptable';
import AddRequirementSetsModal from '../modals/addRequirementSetModal';
import FilterRequirementSets from './filter';
import Utils from '../../../lib/utils';
import RequirementSetDetails from './details';
import RequirementSetView from '../requirement-sets-view';
import RolAccess from './../../common/rolAccess';

import * as commonActions from '../../common/actions';
import * as usersActions from '../../users/actions';
import * as actions from './actions';
import * as projectDetailsActions from '../project-view/actions'

import './RequirementSets.css';
import AddReqSetFromProyectModal from "../modals/addReqSetFromProyectModal";

import {profileHolderAdminID} from '../../../lib/appConstants';

class RequirementSets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentHolders: null,
      filter: {
        pageNumber: 1
      },
      filterBox: {
        name: '',
        description: '',
        holderId: '',
        archived: '0',
        holderSetId: ''
      },
      tableOrderActive: 'name',
      order: {
        name: 'asc',
        description: 'desc',
        holderName: 'desc',
        holderSetId: 'desc',
        archived: 'desc'
      },
      currentModalSet: null,
      currentSet: null,
      showFilterBox: true,
      downloadData: false,
      isCurrentSetTemplate: false,
    };

    // TODO: REMOVE IF NOT NECESSARY
    if (!props.fromHolderTab) props.usersActions.fetchHiringClients();
  }

  async componentDidMount () {
    const { actions, match } = this.props;

    const query = this.addId({
      orderBy: 'name',
      orderDirection:'ASC',
      archived: '0',
    });

    const {CFRoleId, multipleHiringClients} = this.props.login.profile;
    let holderIds = '';

    if (CFRoleId == profileHolderAdminID) {
      if (match.params && match.params.holderId) {
        holderIds = parseInt(match.params.holderId)
      } else {
        holderIds = multipleHiringClients;
        holderIds.reduce( (acc, curr) => acc+', '+curr)
        query.holderId = holderIds
      }
      this.setState({filterBox: {holderId: holderIds}, currentHolders: holderIds});
    };

    await actions.fetchRequirementSets(query).then( () => {
      const {reqSetId, holderId} = match.params;
      if (reqSetId && Number(reqSetId) > 0 && holderId && Number(holderId) > 0 ) {
        this.openDetails({ Id: Number(reqSetId), HolderId: Number(holderId) });
      }
    });
    actions.fetchHolderSetIdsPossibleValues({ holderIds: holderIds });

  }

  addId = (query) => {
    const { holderId, projectId } = this.props;

    if (holderId) {
      query = { ...query, holderId };
    }
    if (projectId) {
      query = { ...query, projectId };
    }

    return query;
  }

  clickOnColumnHeader = (e, field) => {
    if (field === 'edit'  || field === 'view' || field === 'download' || field === 'copy') {
      return;
    }

    // get base query
    const pageNumber = this.state.filter.pageNumber;
    const orderDirection = this.state.order[field] === 'asc' ? 'DESC' : 'ASC';
    let preQuery = Utils.getFetchQuery(field, pageNumber, orderDirection);

    // add search filters
    preQuery = Utils.addSearchFiltersToQuery(preQuery, this.state.filterBox);

    const query = this.addId(preQuery);

    // fetch using query
    this.props.actions.fetchRequirementSets(query);

    // save new active tab and order
    let newState = {
      tableOrderActive: field,
      order: {
        name: field === 'name' ? 'asc' : 'desc',
        description: field === 'description' ? 'asc' : 'desc',
        holderName: field === 'holderName' ? 'asc' : 'desc',
        holderSetId: field === 'holderSetId' ? 'asc' : 'desc',
        archived: field === 'archived' ? 'asc' : 'desc',
      },
    };

    newState.order[field] = this.state.order[field] === 'asc' ? 'desc' : 'asc';
    this.setState(newState);
  }

  setPageFilter = (e, pageNumber, force) => {
    if(force || this.state.filter.pageNumber !== pageNumber) {

      // get base query
      const field = this.state.tableOrderActive;
      const orderDirection = this.state.order[field] === 'asc' ? 'ASC' : 'DESC';
      let preQuery = Utils.getFetchQuery(field, pageNumber, orderDirection);

      // add search filters
      preQuery = Utils.addSearchFiltersToQuery(preQuery, this.state.filterBox);

      const query = this.addId(preQuery);

      // fetch using query
      this.props.actions.fetchRequirementSets(query);

      // save page number
      this.setState({
        filter: {
          pageNumber,
        }
      });
    }
  }

  submitFilterForm = (values)=> {
    // get base query
    const field = this.state.tableOrderActive;
    const pageNumber = 1;
    const orderDirection = this.state.order[field] === 'asc' ? 'ASC' : 'DESC';
    let preQuery = Utils.getFetchQuery(field, pageNumber, orderDirection);

    // add search filters
    const filterBox = {
      name: values.name || "",
      description: values.description || "",
      holderId: values.holderId || "",
      archived: values.archived,
      holderSetId: values.holderSetId || "",
    };

    if (!values.holderId && this.state.currentHolders) {
      filterBox.holderId = this.state.currentHolders;
    }

    preQuery = Utils.addSearchFiltersToQuery(preQuery, filterBox);

    const query = this.addId(preQuery);

    // fetch using query
    this.props.actions.fetchRequirementSets(query);

    // save searchterm and pagenumber
    this.setState({
      filterBox,
      filter: {
        pageNumber: 1,
      },
    });
  }

  openDetails = (data) => {
    this.props.commonActions.setLoading(true);
    // Fetch set groups and then fetch all the rules and documents
    this.props.actions.fetchRuleGroup(data, (success) => {
      if (success) {
        const groupsId = this.props.holderRequirementSets.rulesGroups.map(el => el.RuleGroupID);
          return this.props.actions.fetchMultipleRulesAndDocuments(groupsId, data.Id, data.HolderId, (success) => {
            this.props.commonActions.setLoading(false);
            if (success) {
              this.setState({
                currentSet: data.Id,
                isCurrentSetTemplate: data.HolderId == null ? true : false,
              });
            }
          });
      } else {
        this.props.commonActions.setLoading(false);
      }
    });
  }

  openSettingsDetails = (data) => {
    // Fetch set groups and then fetch all the rules and documents
    this.props.actions.fetchRuleGroup(data, (success) => {
      if (success) {
        const groupsId = this.props.holderRequirementSets.rulesGroups.map(el => el.RuleGroupID);
        return this.props.actions.fetchMultipleRulesAndDocumentsForSettings(groupsId, data.Id, (success) => {
          this.props.commonActions.setLoading(false);
          if (success) {
            this.setState({
              currentSet: data.Id,
            });
          }
        });  
      } else {
        this.props.commonActions.setLoading(false);
      }      
    });
  }

  closeDetails = () => {
    this.setState({
      currentSet: null,
    });
  }

  openModal = () => {
    this.props.actions.setShowModal(true);
  }

  closeModal = (update) => {
    if (update) {
      this.props.actions.setShowModal(false);
      this.setPageFilter(null, this.state.filter.pageNumber || 1, true);
      this.setState({
        currentModalSet: null,
      });
    }
  }

  hideModal = () => {
    this.props.actions.setShowModal(false);

    this.setState({
      currentModalSet: null,
    });
  }

  onCopy = (reqSet) => {
    this.setState({
      currentModalSet: reqSet,
    });
    const { holderId } = this.props;
    const {
      title,
      description,
      confirmButton,
      cancelButton
    } = this.props.local.strings.holderRequirementSets.list.duplicate;

    Swal({
      title: title,
      text: description,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2E5965',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmButton
    }).then((result) => {
      // console.log('ALERT:', result);
      if (result.value) {
        this.props.actions.duplicateReqSet({ holderId: holderId, reqSetId: this.state.currentModalSet.Id }, (success) => {
          this.closeModal(true);
        });
      }
    });
  }  

  onDownload = (data) => {
    this.props.commonActions.setLoading(true);

    // Fetch set groups and then fetch all the rules and documents
    this.props.actions.fetchRuleGroup(data, (success) => {
      if (success) {
        const groupsId = this.props.holderRequirementSets.rulesGroups.map(el => el.RuleGroupID);

        return this.props.actions.fetchMultipleRulesAndDocuments(groupsId, data.Id, this.props.holderId, (success) => {
          this.props.commonActions.setLoading(false);

          if (success) {
            this.setState({
              downloadData: true,
              currentSet: data.Id,
            }, () => {
              const input = document.getElementById('divToPrint');
              const pdfOptions = {
                margin:       0.1,
                filename:     'myfile.pdf',
                image:        { type: 'jpeg', quality: 0.5 },
                html2canvas:  { scale: 1 },
                jsPDF:        { unit: 'in', orientation: 'portrait', format: 'letter' },
                pagebreak:    { mode: 'avoid-all' }
              };

              html2pdf().set(pdfOptions).from(input).save();
            });
          }
        });
      } else {
        this.props.commonActions.setLoading(false);
      }
    });
  }

  onSetAsDefault = (reqSet, isDefault) => {
    this.setState({
      currentModalSet: reqSet,
    });
    const { holderId } = this.props;
    const {
      title,
      description,
      unsetTitle,
      unsetDescription
    } = this.props.local.strings.holderRequirementSets.list.setAsDefault;

    Swal({
      title: (isDefault === 1) ? title : unsetTitle,
      text: (isDefault === 1) ? description : unsetDescription,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2E5965',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.value) {
        this.props.commonActions.setLoading(true);
        this.props.actions.setAsDefault({ holderId: holderId, requirementSetId: this.state.currentModalSet.Id, isDefault: isDefault }, (success) => {
          this.props.commonActions.setLoading(false);
          if (success) {
            this.closeModal(true)
          } else {
            Swal({
              type: 'error',
              title: title,
              text: 'Cannot set this requirement as default. Please contact the administrator.'
            });
            return false;
          }
        });
      }
    });

  }

  renderButtonAddRequirement() {
    const component = (
      <a onClick={this.openModal}
        className="nav-btn nav-bn icon-add"
      >
        {this.props.local.strings.holderRequirementSets.list.addBtn}
      </a>
    );
    return component;
  }

  renderButtonEditRequirement(reqSet) {
    const { fromSettingsTab } = this.props;
    const component = (
      <a
        className="cell-table-link icon-quick_view"
        onClick={fromSettingsTab ? () => this.openSettingsDetails(reqSet) : () => this.openDetails(reqSet)}
      >
        {this.props.local.strings.holderRequirementSets.list.viewLinkColumn}
      </a>
    );
    return component;
  }
  
  renderButtonCopyRequirement(reqSet) {
    const component = (
      <a
        className="cell-table-link icon-duplicate"
        onClick={() => this.onCopy(reqSet)}
      >
        {this.props.local.strings.holderRequirementSets.list.copyLinkColumn}
      </a>     
    );
    return component;
  }

  renderButtonSetAsDefaultRequirement(reqSet) {
    const component = (reqSet.IsDefault === 0) 
      ? (
        <a className="set-default icon-edit" onClick={() => this.onSetAsDefault(reqSet, 1)}>
          {this.props.local.strings.holderRequirementSets.list.setAsDefaultColumn}
        </a>
      ):(
        <a className="unset-default icon-delete" onClick={() => this.onSetAsDefault(reqSet, 0)}>
          {this.props.local.strings.holderRequirementSets.list.unsetDefaultColumn}
        </a>        
      );
    return component;
  }

  renderButtonAddProjectRequirement = () => {
    const component = (
      <button className="header-primary-button" onClick={this.openProjectRequirementSetModal}>
        Add Requirement Set
      </button>
    );
    return component;
  }

  renderPauseRequestProcessCheckbox (reqSet) {
    const { label } = this.props.local.strings.holderRequirementSets.list.pauseRequestProcess;
    return (
      <div className="col-md-auto mt-1" style={{display:'flex', alignItems: 'center'}}>            
        <div style={{ width: '200px' }}>
          <input
            type="checkbox"
            checked={reqSet.PauseRequestProcess}
            className="pretty-checkbox checkbox-workflow"
            onChange={(e) => this.togglePauseRequestProcess(e, reqSet)}
          />&nbsp;
          <span>{label}</span>
        </div>
      </div>
    )
  }

  togglePauseRequestProcess = (e, item) => {
    const { checked } = e.target;
    const { title, startText, pauseText, text } = this.props.local.strings.holderRequirementSets.list.pauseRequestProcess;
    const toggleText = (checked) ? pauseText : startText;
    const pauseRequestProcess = (checked) ? 1 : 0;

    Swal({
      title: title,
      text: text.replace('$1', toggleText),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2E5965',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        this.props.commonActions.setLoading(true);
        this.props.actions.pauseRequestProcess({ requirementSetId: item.Id, pauseRequestProcess: pauseRequestProcess }, (err) => {
          this.props.commonActions.setLoading(false);
          if (err) {
            Swal({
              type: 'error',
              title: title,
              text: 'Failed to change pause request process. Please try again or contact the administrator.',
            });
          } else {
            this.closeModal(true);
          }
        });
      }
    });
  }

  openProjectRequirementSetModal = () => {
    this.setState({openProjectRequirementSetModal: true});
  }

  closeProjectRequirementSetModal = () => {
    this.setState({openProjectRequirementSetModal: false});
  }

  onCloseProjectRequirementSetModalAndRefresh = async () => {
    const { actions, projectDetailsActions, projectId} = this.props;
    const query = this.addId({
      orderBy: 'name',
      orderDirection:'ASC',
      archived: '0',
    });
    await actions.fetchRequirementSets(query);
    await projectDetailsActions.fetchProject(projectId);
    this.setState({openProjectRequirementSetModal: false});
  }

  render() {
    const {
      nameColumn,
      descriptionColumn,
      downloadLinkColumn,
      holderColumn,
      holderSetIdColumn,
      archivedColumn,
      filterBtn,
    } = this.props.local.strings.holderRequirementSets.list;
    const { fromHolderTab, fromSettingsTab, fromProjectTab, projectId, holderId, holderArchived} = this.props;
    let fields = [
      'name',
    ]

    if (fromHolderTab) {
      fields = fields.concat([
        'description',
        'archived',
        'view',
        'download',
        'copy',
        'setAsDefault',
        'pauseRequestProcess',
      ])
    } else if (fromProjectTab) {
      fields = fields.concat([
        'description',
        'archived',
        'view',        
      ])
    } else {
      fields = fields.concat([
        'holderName',
        'holderSetId',
        'archived',
        'view',
      ])
    }

    let header = {
      name: nameColumn,
    }

    if (fromHolderTab) {
      Object.assign(header, {
        description: descriptionColumn,
        archived: archivedColumn,
        view: '',
        download: '',
        copy: '',
        setAsDefault: '',
        pauseRequestProcess: '',
      })
    } else if (fromProjectTab) {
      Object.assign(header, {
        description: descriptionColumn,
        archived: archivedColumn,
        view: '',        
      })
    } else {
      Object.assign(header, {
        holderName: holderColumn,
        holderSetId: holderSetIdColumn,
        archived: archivedColumn,
        view: '',
      })
    }
    const TableMetadata = {
        fields: fields,
        header: header,
      };
    const TableBody = this.props.holderRequirementSets.list.map((reqSet) => {
      const {
        Name,
        Description,
        HolderName,
        HolderSetID,
        Archived,
        Template,
      } = reqSet;

      let tableData = {
        name: Name,
      }

      if (fromHolderTab) {
        Object.assign(tableData, {
          description: Description,
          archived: Archived ? 'True' : 'False',
          view: this.renderButtonEditRequirement(reqSet),
          download: (
            <a
              className="cell-table-link icon-save"
              onClick={() => this.onDownload(reqSet)}
            >
              {downloadLinkColumn}
            </a>
          ),
          copy: (
            <RolAccess
              masterTab="requirements"
              sectionTab="copy_requirements"
              component={() => this.renderButtonCopyRequirement(reqSet)}>
            </RolAccess>
          ),
          setAsDefault: (Template !== 1) && (
            <RolAccess
              masterTab="requirements"
              sectionTab="set_as_default"
              component={() => this.renderButtonSetAsDefaultRequirement(reqSet)}>
            </RolAccess>
          ),
          pauseRequestProcess: (Template !== 1) && (
            <RolAccess
              masterTab="requirements"
              sectionTab="view_requirements"
              component={() => this.renderPauseRequestProcessCheckbox(reqSet)}>
            </RolAccess>
          )
        })
      } else if (fromProjectTab) {
        Object.assign(tableData, {
          description: Description,
          archived: Archived ? 'True' : 'False',
          view: (
            <RolAccess
              masterTab="requirements"
              sectionTab="view_requirements"
              component={() => this.renderButtonEditRequirement(reqSet)}>
            </RolAccess>
          )
         })
      } else {
        // From Settings Tab
        Object.assign(tableData, {
          holderName: HolderName,
          holderSetId: HolderSetID,
          archived: Archived ? 'True' : 'False',
          view: (
            <RolAccess
              masterTab="requirements"
              sectionTab="edit_requirements"
              component={() => this.renderButtonEditRequirement(reqSet)}>
            </RolAccess>
          ),
        })
      }

      return tableData;

    });

    const templatesTableData = {
      fields: TableMetadata.fields,
      header: TableMetadata.header,
      body: TableBody
    };

    let {
      totalAmountOfRequirementSets,
      requirementSetsPerPage,
      fetching,
      showModal,
    } = this.props.holderRequirementSets;

    const paginationSettings = {
      total: totalAmountOfRequirementSets,
      itemsPerPage: requirementSetsPerPage,
      setPageHandler: this.setPageFilter,
      currentPageNumber: this.state.filter.pageNumber,
    };

    const { currentSet, downloadData, isCurrentSetTemplate } = this.state;
    const currentResult = this.props.holderRequirementSets.list.filter(el => el.Id === currentSet);

    if (currentSet && !this.props.holderRequirementSets.fetching && !downloadData) {
      if (fromSettingsTab || isCurrentSetTemplate) {
        return (
          <RequirementSetView
            requirementSet={currentResult[0]}
            closeDetails={this.closeDetails}
            closeModal={this.closeModal}
            fromHolderTab={this.props.fromHolderTab}
            holderArchived={holderArchived}
            fromSettingsTab={this.props.fromSettingsTab}
          />
        );
      } else {
        return (
          <RequirementSetDetails
            requirementSet={currentResult[0]}
            fromHolderTab={this.props.fromHolderTab}
            closeDetails={this.closeDetails}
            holderId={this.props.holderId}
            closeModal={this.closeModal}
          />
        );
      }
    }

    return (
      <div className="list-view admin-view-body holder-requirement-sets-list">

        { !fromProjectTab
        ? (
          <div>
            <Modal
              show={showModal}
              onHide={this.hideModal}
              className="add-item-modal add-entity-small"
            >
              <Modal.Body>
                <AddRequirementSetsModal
                  fromHolderTab={this.props.fromHolderTab}
                  requirementSet={this.state.currentModalSet}
                  close={this.closeModal}
                  onHide={this.hideModal}
                  holderId={this.props.holderId}
                />
              </Modal.Body>
            </Modal>

            <div className="holder-requirement-sets-list-header">
              <div>
                <a
                  onClick={() => this.setState({ showFilterBox: !this.state.showFilterBox })}
                  className="nav-btn icon-login-door"
                >
                  {filterBtn}
                </a>
              </div>

              <div>
                {(!holderArchived) && (
                  <RolAccess
                    masterTab="requirements"
                    sectionTab="add_requirements"
                    component={() => this.renderButtonAddRequirement()}>
                  </RolAccess>
                )}
              </div>
            </div>

            {this.state.showFilterBox &&
            <section className="list-view-filters">
              <FilterRequirementSets
                hiringClients={this.props.users.hiringClientsOptions}
                fromHolderTab={this.props.fromHolderTab}
                onSubmit={this.submitFilterForm}
                availableHolderSetIds={this.props.holderRequirementSets.holderSetIdsPossibleValuesResults || []}
              />
            </section>
            }
          </div>
        ) : (
          <div className="holder-requirement-sets-list-header">
            <RolAccess
              masterTab="requirements"
              sectionTab="edit_requirements"
              component={() => this.renderButtonAddProjectRequirement()}>
            </RolAccess>
            <Modal
              show={this.state.openProjectRequirementSetModal}
              onHide={this.closeProjectRequirementSetModal}
              className="add-item-modal add-hc">
              <Modal.Body className="add-item-modal-body mt-0">
                <AddReqSetFromProyectModal
                  closeAfterSubmit={this.onCloseProjectRequirementSetModalAndRefresh}
                  close={this.closeProjectRequirementSetModal}
                  projectId={projectId}
                  holderId={holderId}
                />
              </Modal.Body>
            </Modal>
          </div>

          )}

        <PTable
          sorted={true}
          items={templatesTableData}
          wrapperState={this.state}
          tableOrderActive={this.state.tableOrderActive}
          clickOnColumnHeader={this.clickOnColumnHeader}
          isFetching={fetching}
          pagination={paginationSettings}
        />

        {/* HIDDEN DIV FOR HTML2CANVAS */}
        <div style={{ overflow: 'hidden', height: 0}}>
          <div id="divToPrint" className="mt4" style={{
            backgroundColor: '#f5f5f5',
            width: '210mm',
            minHeight: '297mm',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}>
            {downloadData &&
              <RequirementSetDetails
                requirementSet={currentResult[0]}
                fromHolderTab={this.props.fromHolderTab}
                fromDownload
                closeDetails={this.closeDetails}
                holderId={this.props.holderId}
                closeModal={this.closeModal}
              />
            }
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    holderRequirementSets: state.holderRequirementSets,
    local: state.localization,
    login: state.login,
    users: state.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    projectDetailsActions: bindActionCreators(projectDetailsActions, dispatch),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RequirementSets));
