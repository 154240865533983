import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
import { Button, Spinner } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaDownload } from "react-icons/fa";
import { HelpOutlineSharp } from '@material-ui/icons';
import { Tooltip } from "@material-ui/core";

import FilterFiles from './filter';
import * as filesActions from './actions';
import PTable from '../../../common/ptable';
import Utils from '../../../../lib/utils';
import AddFileModal from './addFileModal';
import CheckBox from './component/Checkbox';
import * as formSubmissionActions from "../../../formSubmissions/actions";
import RolAccess from '../../../common/rolAccess';
import DatePicker from 'react-date-picker';
import moment from "moment"
import ManageFileExpiration from './manageFileExpiration';
import DataTable from "../../../common/table";

const Alerts = require('../../../alerts');

class Files extends React.Component {
  constructor(props) {
    super(props);
    const subcontractorId = props.match.params.scId;
    this.state = {
      filter: {
        pageNumber: 1,
      },
      filterBox: {
        searchTerm: '',
        dateFrom: '',
        dateTo: '',
      },
      tableOrderActive: 'fileName',
      order: {
        fileName: 'asc',
        fileDescription: 'desc',
        uploadDate: 'desc',
        fileTypeId: 'desc',
        formID: 'desc',
        expirationDate: 'desc',
      },
      showFilterBox: false,
      showModal: false,
      subcontractorId,
      filesPerPage: 10,
      totalAmountOfFiles: '',
      selectedFiles: [],
      selectedRows: [],
      selectedFile: null,
      exDateShowModal:false,
      onSubmit: true,
    };

    this.downloadFile = this.downloadFile.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.addFile = this.addFile.bind(this);
    this.downloadMultipleFile = this.downloadMultipleFile.bind(this);
  }
  componentDidMount(){
    this.props.formSubmissionActions.fetchForms({
      hiringClientId: this.props.hcId,
      orderBy: "formName",
      orderDirection: "ASC",
      subcontractorId: this.state.subcontractorId,
    });
    this.props.filesActions.getFileEXTemplate(this.props.hcId);
    this.props.filesActions.checkFileExpirationWoarkflowEnabled(this.props.hcId)
   
  }
  defineAndFetchQuery(hiringClientId, roleId) {

    const pageNumber = 1;
    const filesPerPage = this.props.files.filesPerPage;
    const field = this.state.tableOrderActive;
    const orderDirection = this.state.order[field] === 'asc' ? 'ASC' : 'DESC';
    const { subcontractorId } = this.state;

    const query = Utils.getFetchQuery(
      field,
      pageNumber,
      orderDirection,
      filesPerPage
    );

    query.subcontractorId = subcontractorId;
    query.hiringClientId = hiringClientId;
    query.roleId = roleId;

    this.props.filesActions.fetchFiles(query);
    this.props.filesActions.getFileEXTemplate(hiringClientId);
    this.props.filesActions.checkFileExpirationWoarkflowEnabled(hiringClientId)
  }

  clickOnColumnHeader = (e, field) => {
    if (field === 'viewFile' || field === 'checkBox') return;
    // get base query
    const pageNumber = this.state.filter.pageNumber;
    const filesPerPage = this.props.files.filesPerPage;
    const orderDirection = this.state.order[field] === 'asc' ? 'DESC' : 'ASC';

    let query = Utils.getFetchQuery(
      field,
      pageNumber,
      orderDirection,
      filesPerPage
    );
    query.subcontractorId = this.state.subcontractorId;
    query.hiringClientId = this.props.hcId;
    query.roleId = this.props.loginProfile.RoleID;
    // add search filters
    query = Utils.addSearchFiltersToQuery(query, this.state.filterBox);
    // fetch using query
    this.props.filesActions.fetchFiles(query);
    this.props.filesActions.getFileEXTemplate(this.props.hcId);
    this.props.filesActions.checkFileExpirationWoarkflowEnabled(this.props.hcId)
    // save new active tab and order
    const newState = {
      tableOrderActive: field,
      order: {
        fileName: field === 'fileName' ? 'asc' : 'desc',
        fileDescription: field === 'fileDescription' ? 'asc' : 'desc',
        uploadDate: field === 'uploadDate' ? 'asc' : 'desc',
        fileTypeId: field === 'fileTypeId' ? 'asc' : 'desc',
        formID: field === 'formID' ? 'asc' : 'desc',
        expirationDate: field === 'expirationDate' ? 'asc' : 'desc',
      },
      selectedFiles: [],
      selectedRows: []
    };
    newState.order[field] = this.state.order[field] === 'asc' ? 'desc' : 'asc';
    this.setState(newState);
  };

  setPageFilter = (e, pageNumber, force) => {
    if (force || this.state.filter.pageNumber !== pageNumber) {
      // get base query
      const filesPerPage = this.props.files.filesPerPage;
      const field = this.state.tableOrderActive;
      const orderDirection = this.state.order[field] === 'asc' ? 'ASC' : 'DESC';

      let query = Utils.getFetchQuery(
        field,
        pageNumber,
        orderDirection,
        filesPerPage
      );

      query.subcontractorId = this.state.subcontractorId;
      query.hiringClientId = this.props.hcId;
      query.roleId = this.props.loginProfile.RoleID;
      // add search filters
      query = Utils.addSearchFiltersToQuery(query, this.state.filterBox);
      // fetch using query
      this.props.filesActions.fetchFiles(query);
      this.props.filesActions.getFileEXTemplate(this.props.hcId);
      this.props.filesActions.checkFileExpirationWoarkflowEnabled(this.props.hcId)
      // save page number
      this.setState({
        filter: {
          pageNumber,
        },
        selectedFiles: [],
        selectedRows: []
      });
    }
  };

  toggleFilterBox = () => {
    this.setState({ showFilterBox: !this.state.showFilterBox });
  };

  submitFilterForm = values => {
    // get base query
    const filesPerPage = this.props.files.filesPerPage;
    const field = this.state.tableOrderActive;
    const pageNumber = 1;
    const orderDirection = this.state.order[field] === 'asc' ? 'ASC' : 'DESC';

    let query = Utils.getFetchQuery(
      field,
      pageNumber,
      orderDirection,
      filesPerPage
    );

    query.subcontractorId = this.state.subcontractorId;
    query.hiringClientId = this.props.hcId;
    query.roleId = this.props.loginProfile.RoleID;
    // add search filters
    const filterBox = {
      searchTerm: values.keywords || '',
      dateFrom: values.dateFrom || '',
      dateTo: values.dateTo || '',
    };
    query = Utils.addSearchFiltersToQuery(query, filterBox);
    // fetch using query
    this.props.filesActions.fetchFiles(query);
    this.props.filesActions.getFileEXTemplate(this.props.hcId);
    this.props.filesActions.checkFileExpirationWoarkflowEnabled(this.props.hcId)
    // save searchterm and pagenumber
    this.setState({
      filterBox,
      filter: {
        pageNumber: 1,
      },
    });
  };

  // table's row handlers
  renderHeader = value => value === '' ? null : <span className="col-th-arrow" />;

  componentWillMount() {
    const hcId = this.props.hcId;
    const roleId = this.props.loginProfile.RoleID;

    if (hcId) {
      this.defineAndFetchQuery(hcId, roleId);
    }
  }

  componentWillReceiveProps(nextProps) {
    const newHcId = nextProps.hcId;
    const oldHcId = this.props.hcId;
    const newRoleId = nextProps.loginProfile.RoleID;
    const oldRoleId = this.props.loginProfile.RoleID;
    const roleId = newRoleId ? newRoleId : oldRoleId;
    const oldTotal = this.props.files.totalAmountOfFiles;
    const totalAmountOfFiles = nextProps.files.totalAmountOfFiles;
    const fetchingFiles = nextProps.files.fetchingFiles;
    const totalAmountOfFilesState = this.state.totalAmountOfFiles;

    if (newHcId && newHcId !== oldHcId) {
      this.defineAndFetchQuery(newHcId, roleId);
    }
    // console.log('this.props.files = ', this.props.files)
    if (
      (
        oldTotal !== totalAmountOfFiles || 
        (totalAmountOfFiles !== totalAmountOfFilesState && !fetchingFiles)
      ) &&
      typeof totalAmountOfFiles !== 'undefined'
    ) {
      this.setState({
        totalAmountOfFiles,
      });
    }
    this.setState({selectedFiles: []});
  }
  expireDateClickEvent = (file) => {
    const {isFileExpirationWorkflowEnabled} = this.props.files
    if(isFileExpirationWorkflowEnabled){
      this.setState({
        selectedFile:file,
        exDateShowModal:true
      })
    }
  }
  renderButtonCFRoleEditExpireDate = (file) => {
    let component = (
      <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
         <a
          className='cell-table-link icon-edit icon-with-text link-ada-color'
          onClick={() => this.expireDateClickEvent(file)}
        >
        </a>
        {
          file && file.hasTemplateDisconnect && file.hasTemplateDisconnect == 1 ? (
            <div className='tooltip-template-missing'>
              <Tooltip title="Template Missing.">
                <HelpOutlineSharp/>
              </Tooltip>
            </div>
          ): (<></>)
        }
      </div>
    )
    return component;
  }


  renderButtonCFRoleDeleteExpireDate = (file) => {
    const {isFileExpirationWorkflowEnabled} = this.props.files;
    let component = (
        <a
          className='cell-table-link icon-delete icon-with-text link-ada-color'
          onClick={() => {
            if(isFileExpirationWorkflowEnabled){
              const alertContent = {
                title: 'Delete Expiration Date',
                text: 'Are you sure you want to remove Expiration Date?',
                btn_no: 'Cancel',
                btn_yes: 'Accept'
              }
              Alerts.showActionConfirmation(alertContent, (confirmed) => this.delteExpireDate(confirmed, file.id));
            }
          }}
        >
        </a>
    )
    return component;
  }
  renderButtonCFRoleAddExpireDate = (file) => {
    let component = (
      <div>
        <a
          className='cell-table-link icon-add icon-with-text link-ada-color pl-2 pr-2'
          onClick={() => this.expireDateClickEvent(file)}
        >
        </a>
      </div>
    )
    return component;
  }
  closeExDateModal = () => {
    const hcId = this.props.hcId;
    const roleId = this.props.loginProfile.RoleID;
    if (hcId) {
      this.defineAndFetchQuery(hcId, roleId);
    }
    this.setState({
      exDateShowModal:false
    })
  }
  delteExpireDate = async (confirmed, fileId) => {
    if(confirmed){
      await this.props.filesActions.deletefileExpirationDate(fileId, this.props.hcId, this.state.subcontractorId, (err, result) =>{
        if(err){
  
        }
        else{
          const hcId = this.props.hcId;
          const roleId = this.props.loginProfile.RoleID;
      
          if (hcId) {
            this.defineAndFetchQuery(hcId, roleId);
          }
        }
     })
    }else{
      this.setState({
        selectedFile : null
      })
    }
  }
  onDateChange = (date) => {
    let selectedFile = {...this.state.selectedFile};
    selectedFile.expirationDate = moment(date).format("YYYY/MM/DD");
    this.setState({
      selectedFile
    })
  }
  onTempalteChange = (element) => {
    let selectedFile = {...this.state.selectedFile};
    selectedFile.templateId = element.target.value;
    this.setState({
      selectedFile
    })
  }
  render() {
    const { fetchingFiles, list, fileExTemplate
    } = this.props.files;
    const renderViewFile = (url, fileUrl, viewFile,fileName) => {

      if (this.props.files.downloadingFile) {
        return <div className="download-file">
          <div className="spinner-wrapper">
            <div className="spinner" />
          </div>
        </div>
      }
      else {
        return <a
          className="cell-table-link icon-quick_view link-ada-color"
          onClick={() => {
            this.downloadFile(url, fileUrl,fileName);
          }}
        >
          {viewFile}
        </a>
      }
    }

    const renderCheckbox = (file) => {
        /**
         * Check if the file is already selected
         * @param {Object} file object to check if it's already in state
         * @returns {Boolean} true | false  
         */
        const isChecked = (file) => {
            const rowSelected = this.state.selectedFiles;
            // return true if the file exists in state, else return false
            return (!!(rowSelected.includes(file)));
        }

        /**
         * Toggle the select box
         * @param {Object} file 
         */
        const toggleChecked = (file) => {
            const prevSelected = this.state.selectedFiles;
            // Check if object was already selected
            if (!!isChecked(file)) {
                const currSelected = _.reject(prevSelected, (selectedFile) => selectedFile === file);
                this.setState({selectedFiles: currSelected});
            }
            else {
                const currSelected = [...prevSelected, file];
                this.setState({selectedFiles: currSelected});
            }
        }

        return (
          <CheckBox
            name={file.url}
            handleClick={()=>toggleChecked(file)}
            isChecked={this.state.selectedFiles.includes(file)} 
          />
        );
    }
    const renderSelectAllCheckbox = (fileList) => {
        /**
         * Check if all file is already selected
         * @param {Object} file object to check if it's already in state
         * @returns {Boolean} true | false  
         */
        const isAllChecked = (file) => {
            const rowSelected = this.state.selectedFiles;
            // return true if all files are selected, else return false
            return (!!( fileList.length && fileList.length === rowSelected.length));
        }

        /**
         * Toggle the select box
         * @param {Object} file 
         */
        const toggleChecked = (file) => {
            if (!!isAllChecked(file)) {
                const currSelected = [];
                this.setState({selectedFiles: currSelected});
            }
            else {
                const currSelected = fileList;
                this.setState({selectedFiles: currSelected});
            }
        }

        return (
          <CheckBox
            name="select_all"
            handleClick={()=>toggleChecked(fileList)}
            isChecked={fileList.length && fileList.length === this.state.selectedFiles.length }
          />
        );
    }
    const renderExpirationDate = (file) => {
      const { expirationDate } = file;
      const { isFileExpirationWorkflowEnabled } = this.props.files;
      
      if(expirationDate){
        return <div style={{ gap: '5px' }} className='d-flex justify-content-center align-items-center'>
                  {Utils.getFormattedDate(expirationDate, true)}
                  {isFileExpirationWorkflowEnabled ?
                    <div style={{ gap: '5px', alignItems: 'center' }} className='d-flex justify-content-between align-items-center'>
                      <RolAccess
                        masterTab="files"
                        sectionTab="edit_expire_date"
                        component={() => this.renderButtonCFRoleEditExpireDate(file)}
                      ></RolAccess>
                      <RolAccess
                        masterTab="files"
                        sectionTab="delete_expire_date"
                        component={() => this.renderButtonCFRoleDeleteExpireDate(file)}
                      ></RolAccess>
                    </div>
                  : null}
        </div>
      }else{
        return <div className='d-flex justify-content-center align-items-center'>
          N/A
          {isFileExpirationWorkflowEnabled ? 
            <RolAccess
                      masterTab="files"
                      sectionTab="add_expire_date"
                      component={() => this.renderButtonCFRoleAddExpireDate(file)}
            ></RolAccess>
          : null}
        </div>
      }
    }
    const {
      headers,
      viewFile,
      filterFiles,
      uploadFile,
    } = this.props.local.strings.scProfile.files;

    const loginProfile = this.props.loginProfile

    const {
      fileName,
      fileDescription,
      uploadDate,
      expirationDate,
      uploadedFrom,
      formID,
      isConfidential
    } = headers;

    const tableMetadata = {
      fields: [
        // 'checkBox',
        'fileName',
        'fileDescription',
        'uploadDate',
        'expirationDate',
        'fileTypeId',
        'isConfidential',
        'formID',
        'viewFile',
        'deleteFile'
      ],
      header: {
        // checkBox: renderSelectAllCheckbox(list),
        fileName,
        fileDescription,
        uploadDate,
        expirationDate,
        fileTypeId: uploadedFrom,
        isConfidential: loginProfile.Role.IsPrequalRole || loginProfile.Role.IsHCRole ? isConfidential : '',
        formID: formID,
        viewFile: '',
        deleteFile: ''
      },
    };
    const IsPrequalRole = _.get(this.props.loginProfile, 'Role.IsPrequalRole');

    const tableBody = list.map(file => {
      const { fileName, fileUrl, fileDescription, uploadDate, payloadId, fileTypeId, type, url, isConfidential, expirationDate } = file;
      const isConfidentialCheck = isConfidential ? isConfidential : false;
      return {
        // checkBox: renderCheckbox(file),
        fileName,
        fileDescription,
        uploadDate: Utils.getFormattedDate(uploadDate),
        fileTypeId: type,
        isConfidential: loginProfile.Role.IsPrequalRole || loginProfile.Role.IsHCRole ? 
          <CheckBox isChecked={isConfidentialCheck} /> :
          <div></div>,
        formID: fileTypeId == 2? "NULL": payloadId, 
        expirationDate: renderExpirationDate(file),
        viewFile: renderViewFile(url, fileUrl, viewFile,fileName),
        // When a HC User Type or a SS User Type  uploads a file and checks the “Internal/Confidential” box, then HC User Cannot Delete File
        deleteFile: isConfidentialCheck ? (
          <div>
            {
              IsPrequalRole &&
              <a className="cell-table-link icon-delete link-ada-color" style={{ bottom: '5px' }}
                onClick={() => {
                  const alertContent = {
                    title: 'Are you sure you want to remove this file?',
                    text: 'CAUTION: if you delete this file, a new file must be re-uploaded to the form, if the upload type of this file is "Form".',
                    btn_no: 'Cancel',
                    btn_yes: 'Accept'
                  }
                  Alerts.showActionConfirmation(alertContent, (confirmed) => this.onDeleteFile(confirmed, file.id));
                }}>
              </a>
            }
          </div>
        ) : (
          <div>
            {
              IsPrequalRole && <a className="cell-table-link icon-delete link-ada-color" style={{ bottom: '5px' }}
                onClick={() => {
                  const alertContent = {
                    title: 'Are you sure you want to remove this file?',
                    text: 'CAUTION: if you delete this file, a new file must be re-uploaded to the form, if the upload type of this file is "Form".',
                    btn_no: 'Cancel',
                    btn_yes: 'Accept'
                  }
                  Alerts.showActionConfirmation(alertContent, (confirmed) => this.onDeleteFile(confirmed, file.id));
                }}>
              </a>
            }
          </div>
        )
      };
    });

    const tableData = {
      fields: tableMetadata.fields,
      header: tableMetadata.header,
      body: tableBody,
    };
    let { totalAmountOfFiles, filesPerPage } = this.props.files;

    const paginationSettings = {
      total: totalAmountOfFiles,
      itemsPerPage: filesPerPage,
      setPageHandler: async (pageNumber, pageSize) => {
        await this.props.pageSize(pageSize)
        this.setPageFilter(null, pageNumber, true)
      },
      currentPageNumber: this.state.filter.pageNumber,
    };

    // console.log('paginationSettings = ', paginationSettings)

    const handleRowSelection = async (selectedDataIndexes) => {
      if(selectedDataIndexes.length > 0){
        const selectedData = await this.props.files.list.filter((item, index) => selectedDataIndexes.includes(index))
        this.setState({selectedFiles: selectedData, selectedRows: selectedDataIndexes});
      } else {
        this.setState({selectedFiles: [], selectedRows: []});
      }
    }

    return (
      <div className="files-list forms-view list-view admin-view-body">
        <Modal
          show={this.state.showModal}
          onHide={this.closeModal}
          className="add-item-modal noteEditorModal"
        >
          <Modal.Body>
            <AddFileModal onSubmit={this.addFile} />
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.exDateShowModal}
          onHide={this.closeExDateModal}
          className="add-item-modal noteEditorModal"
        >
          <Modal.Body>
            <ManageFileExpiration subcontractorId={this.state.subcontractorId} fileExTemplate={fileExTemplate} selectedFile={this.state.selectedFile} closeExDateModal={this.closeExDateModal} onDateChange={this.onDateChange} onTempalteChange={this.onTempalteChange}/>
          </Modal.Body>
        </Modal>

        <section className="forms-view-header">
          <div className="row">

            <div className="col-sm-12">
              <nav className="forms-nav">
                <ul>
                  {
                    // Download Button
                    (
                      <li>
                       <Button
                        className="forms-nav-link nav-bn"
                        onClick={this.downloadMultipleFile}
                        disabled={!this.state.selectedFiles.length}
                        > 
                        {
                          (this.props.files.downloadingMultipleFile) && 
                          <Spinner
                            as="span"
                            variant="light"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border" 
                          >
                          </Spinner>
                        }
                        {
                          this.props.files.downloadingMultipleFile?
                              " Downloading"
                            : (<div><FaDownload/> Download</div>)
                        }
                       </Button>
                      </li>
                    
                    )
                  }
                  <li>
                    <a
                      className="forms-nav-link nav-bn icon-search_icon"
                      onClick={this.toggleFilterBox}
                    >
                      {filterFiles}
                    </a>
                  </li>

                  <li>
                    <a
                      className="forms-nav-link nav-bn  icon-add"
                      onClick={this.openModal}
                    >
                      {uploadFile}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </section>

        {
          this.state.showFilterBox
            ? (
              <section className="forms-view-filters">
                <FilterFiles onSubmit={this.submitFilterForm} hideSentTo={true} />
              </section>
            )
            : <div />
        }

        {
          this.props.files.downloadingMultipleFileError &&
          Alerts.showErrorAlert("Download Error","One or Multiple files not found, please retry!", ()=> this.clearDownloadErrorMassage())
        }

        <DataTable
          sorted
          name="Files"
          rowSelection={true}
          items={tableData}
          wrapperState={this.state}
          tableOrderActive={this.state.tableOrderActive}
          clickOnColumnHeader={this.clickOnColumnHeader}
          isFetching={fetchingFiles}
          customClass="forms-table"
          pagination={paginationSettings}
          onRowSelection={handleRowSelection}
          selectedRows={this.state.selectedRows}
        />
      </div>
    );
  }

  onDeleteFile(confirmed, id) {
    if (confirmed) {
      this.props.filesActions.deleteFile(id);
    }
  }

  clearDownloadErrorMassage() {
    this.props.filesActions.setDownloadError(false);
  }

  downloadFile(fileName, fileUrl,orignalFileName) {
    const payload = {
      fileName,
      fileUrl,
      orignalFileName
    };
    this.props.filesActions.viewFileLink(payload);
    // this.props.filesActions.downloadFile(payload);
  }

  openModal() {
    this.setState({
      showModal: true,
    });
  }

  closeModal(err) {
    // if (err) {
    //   console.log('Error uploading file into Files tab: ', err)
    // } else {
    //   console.log('File uploaded successfully')
    // }
    this.setState(
      {
        showModal: false,
      },
      () => this.setPageFilter(null, 1, true)
    );
  }

  addFile(formValues) {
    const fileInput = document.getElementById('documentFile');
    const formData = new FormData();
    const finBoolean = formValues.FinancialDataFlag ? 1 : null;
    const confidentialDataFlag = formValues.confidentialDataFlag ? 1 : null;
    formData.append('subcontractorId', this.state.subcontractorId);
    formData.append('description', formValues.description);
    formData.append('FinancialDataFlag', finBoolean);
    formData.append('isWorkflowCreated', true);
    if (formValues.SavedFormID) {
      formData.append('payloadId', formValues.SavedFormID);
      formData.append('fileTypeId', 3);
    }
    else {
      formData.append('payloadId', this.state.subcontractorId);
      formData.append('fileTypeId', 2);
    }
    formData.append('documentFile', fileInput.files[0]);
    formData.append('hiringClientId', this.props.hcId);
    formData.append('isConfidential', confidentialDataFlag);//For Internal / Confidential checkbox
    formData.append('uploadUserTypeID', this.props.loginProfile.Role.Id)

    // for (let key of formData.keys()) {
    //   console.log(`${key}: ${formData.get(key)}`)
    // }

    try {
      if(this.state.onSubmit){
        this.setState({onSubmit: false});
        this.props.filesActions.saveFile(formData, this.closeModal);
      }
    } catch (error) {
      this.setState({onSubmit: true})
    }
  }
  
  downloadMultipleFile = () => {
    const selectedFiles = this.state.selectedFiles;
    var groupedFiles = _.groupBy(selectedFiles, file => file.fileName);
    var newFileList = [];
    for (const property in groupedFiles) {
      for(let i = 0; i < groupedFiles[property].length; i ++){
          var file = groupedFiles[property][i]
          var orignalFileName = file.fileName;
          file['totalCopies'] = i;
          var stringArray = orignalFileName.split(".", orignalFileName.length);
          if(i === 0){
            file['updateFileName'] = orignalFileName;
          }else{
            file['updateFileName'] = stringArray[0] + "("+ file['totalCopies'] +")" + "." + stringArray[1];
        }
          newFileList.push(file);
      }
    }
    this.props.filesActions.downloadMultipleFiles(newFileList);  } 
}

const mapStateToProps = state => ({
  hcId: state.SCProfile.hcId,
  common: state.common,
  files: state.files,
  formSubmissions: state.formSubmissions,
  loginProfile: state.login.profile,
  local: state.localization,
});

const mapDispatchToProps = dispatch => ({
  filesActions: bindActionCreators(filesActions, dispatch),
  pageSize: payload => dispatch(filesActions.setPageSize(payload)),
  formSubmissionActions: bindActionCreators(formSubmissionActions, dispatch),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Files)
);
