import Api from '../../../../lib/api';
import * as types from './types';

export const setNotesError = (error) => {
  return {
    type: types.SET_CERTFOCUS_NOTES_ERROR,
    payload: error
  };
};

export const setSavingError = (error) => {
  return {
    type: types.SET_CERTFOCUS_SAVING_NOTE_ERROR,
    payload: error,
  };
};

export const setFetching = () => {
  return {
    type: types.SET_CERTFOCUS_FETCHING_NOTES,
  };
};

export const setNotes = (list, totalAmount) => {
  return {
    type: types.SET_CERTFOCUS_NOTES_LIST,
    payload: {
      list,
      totalAmount,
    }
  }  
};

export const setNotesRolesPossibleValues = (roles) => {
  return {
    type: types.SET_CERTFOCUS_NOTES_ROLES_POSSIBLE_VALUES,
    payload: roles,
  };
};

export const fetchNotes = (queryParams) => {
  return (dispatch, getState) => {
    const { CFNotes: { notesPerPage }, login: { authToken }} = getState();

    dispatch(setFetching());

    if (queryParams) {
      if (queryParams.withoutPag) {
        delete queryParams.pageNumber;
        delete queryParams.pageSize;
      } else {
        if (!queryParams.pageNumber) {
          queryParams.pageNumber = 1;
        }
        queryParams.pageSize = notesPerPage;
      }
    } else {
      queryParams = {
        pageNumber: 1,
        pageSize: notesPerPage,
      };
    }

    let urlQuery = 'cf/notes';
    const urlParameters = `?${Object.keys(queryParams).map(i => `${i}=${queryParams[i]}`).join('&')}`;
    return Api.get(`${urlQuery}${urlParameters}`, authToken)
      .then(response => {
        const { success, data } = response.data;
        if (success) {
          const { notes, totalCount } = data;

          dispatch(setNotes(notes || [], totalCount || 0));
        } else {
          dispatch(setNotesError('Request error'));
        }
      })
      .catch(() => {
        dispatch(setNotesError('Connection error'));
      });
  };
};

export const saveNote = (payload, callback) => {
  return (dispatch, getState) => {
    const token = getState().login.authToken;

    dispatch(setSavingError(''));

    let apiAction = 'post';
    if (payload.noteId) {
      apiAction = 'put';
    }

    Api[apiAction](`cf/notes`, payload, token)
      .then(response => {
        const { success } = response.data;
        if (success) {
          callback(true);
        } else {
          dispatch(setSavingError("There was an error while saving, please try again"));
          callback(false);
        }
      }).catch(() => {
        dispatch(setSavingError("There was an error while saving, please try again"));
        callback(false);
      });
  };
}

export const fetchProjectsByInsuredId = (insuredId, callback) => {
  return (dispatch, getState) => {
    const { login, localization } = getState();
    const { errorDefault, errorConnection } = localization.strings.projectInsureds.errors;
    const { authToken } = login;

    const urlQuery = `cf/projectInsureds?insuredId=${insuredId}`;
    return Api.get(`${urlQuery}`, authToken)
      .then(response => {
        const { success, projectInsureds } = response.data;
        if (success) {
          callback(false, projectInsureds);
        }
        else {
          callback(true, errorDefault);
        }
      })
      .catch(error => {
        callback(true, errorConnection);
      });
  };
};