import React, { Component } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';

import validate from './validation';
import renderSelectSearch from '../../../../customInputs/renderSelectSearch';
import Spinner from '../../../../common/spinner';

class ChangeTradesForm extends Component {

  componentDidMount() {
    const { initialValues } = this.props;
    this.props.dispatch(change('ChangeTradesForm', 'tradeId', initialValues.tradeId));
    this.props.dispatch(change('ChangeTradesForm', 'secondaryTradeId', initialValues.secondaryTradeId));
    this.props.dispatch(change('ChangeTradesForm', 'tertiaryTradeId', initialValues.tertiaryTradeId));
    this.props.dispatch(change('ChangeTradesForm', 'quaternaryTradeId', initialValues.quaternaryTradeId));
    this.props.dispatch(change('ChangeTradesForm', 'quinaryTradeId', initialValues.quinaryTradeId));
  }

  removeUnusedOption = (tradeList) => {
    return tradeList.filter((trade) => {
      return trade.label.toUpperCase() !== '--SELECT--';
    });
  }

  render() {
    const { handleSubmit, tradeOptions, close, message } = this.props;
    const { tradesUpdateModalFetching, tradesUpdateModalError } = this.props.scProfile;
    const {
      tradeLabel,
      secondaryTradeLabel,
      tertiaryTradeLabel,
      quaternaryTradeLabel,
      quinaryTradeLabel,
      cancelBtn,
      saveBtn,
    } = this.props.local.strings.scProfile.changeTradesModal;

    return (
      <form onSubmit={handleSubmit} className="list-view-filter-form">
        <div className="sc-profile-change-trades-modal-content container-fluid filter-fields">
          <div className="sc-profile-change-trades-modal-container" >
            <div className="container-fluid">
              {
                message && message !== '' && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 step-col-right">
                      {message}
                    </div>
                  </div>
                )

              }
              <div className="row">
                <div className="col-md-12 col-sm-12 step-col-right">
                  <div className="register-field-wrapper">
                    <label htmlFor="tradeId" className="required">{tradeLabel}:</label>
                    <div className="select-wrapper">
                      <Field
                        id="tradeId"
                        name="tradeId"
                        component={renderSelectSearch}
                        options={this.removeUnusedOption(tradeOptions)} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-12 step-col-left">
                  <div className="register-field-wrapper">
                    <label htmlFor="secondaryTradeId">{secondaryTradeLabel}:</label>
                    <div className="select-wrapper">
                      <Field
                        id="secondaryTradeId"
                        name="secondaryTradeId"
                        component={renderSelectSearch}
                        options={this.removeUnusedOption(tradeOptions)} />
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-12 step-col-right">
                  <div className="register-field-wrapper">
                    <label htmlFor="tertiaryTradeId">{tertiaryTradeLabel}:</label>
                    <div className="select-wrapper">
                      <Field
                        id="tertiaryTradeId"
                        name="tertiaryTradeId"
                        component={renderSelectSearch}
                        options={this.removeUnusedOption(tradeOptions)} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-12 step-col-left">
                  <div className="register-field-wrapper">
                    <label htmlFor="quaternaryTradeId">{quaternaryTradeLabel}:</label>
                    <div className="select-wrapper">
                      <Field
                        id="quaternaryTradeId"
                        name="quaternaryTradeId"
                        component={renderSelectSearch}
                        options={this.removeUnusedOption(tradeOptions)} />
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-12 step-col-right">
                  <div className="register-field-wrapper">
                    <label htmlFor="quinaryTradeId">{quinaryTradeLabel}:</label>
                    <div className="select-wrapper">
                      <Field
                        id="quinaryTradeId"
                        name="quinaryTradeId"
                        component={renderSelectSearch}
                        options={this.removeUnusedOption(tradeOptions)} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {tradesUpdateModalError && (
            <div className="text-center mb-2 text-danger">
              {tradesUpdateModalError}
            </div>
          )}

          {tradesUpdateModalFetching ? (
            <Spinner />
          ) : (
            <div className="sc-profile-change-trades-modal-buttons-container text-right">
              <button className="bg-sky-blue-gradient bn" type="button" onClick={close}>
                {cancelBtn}
              </button>

              <button className="bg-sky-blue-gradient bn action-button" type="submit">
                {saveBtn}
              </button>
            </div>
          )}
        </div>
      </form>
    );
  }
}

ChangeTradesForm = reduxForm({
  form: 'ChangeTradesForm',
  validate,
  enableReinitialize: true,
})(ChangeTradesForm);

const mapStateToProps = (state) => ({
  local: state.localization,
  scProfile: state.SCProfile,
});

export default connect(mapStateToProps, null)(ChangeTradesForm);
