import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import logoPrequal from '../../assets/img/logo-nav.svg';
import vertikalLogo from '../../assets/img/VertikalLogo.png';

const RegisterSidebar = ({hcLogo, local}) => {
  const {slogan} =  local.strings.register.registerSidebar;
  const logo = hcLogo ? `data:image/jpeg;base64,${hcLogo}` : null;
  const hcLogoStyle = {
    maxWidth: '40%',
    width: 'auto',
    height: 'auto'
  };

  return (
    <div className="col-sm-12 col-md-4 bg-blue-gradient register-sidebar">
      <div className="vertikal-logo-wrapper">
        <img className="vertikal-img register-sidebar-img" src={vertikalLogo} alt="Vertikal"/>
      </div>
        <div className="logos-wrapper">
          <div className="side-by-side-logos">
            <img className="prequal-img register-sidebar-img mb-4" src={logoPrequal} alt="Prequal" />
            {logo && (
              <img
                className="skender-img register-sidebar-img"
                style={hcLogoStyle}
                src={logo} alt="Skender"
              />
            )}
          </div>
      </div>
      <p className="register-slogan">{slogan}</p>
    </div>
  );
};

RegisterSidebar.propTypes = {
  local: PropTypes.object.isRequired,
  hcLogo: PropTypes.string
};

RegisterSidebar.defaultProps = {
  local: {strings: {register: {registerSidebar: '00000'}}},
  hcLogo: null
};

const mapStateToProps = (state) => ({local: state.localization});

export default connect(mapStateToProps)(RegisterSidebar);