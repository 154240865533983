import React from 'react';
import Utils from '../../../../lib/utils';
import DisplayTextWithConditionalModal from '../../../common/displayTextWithConditionalModal';

const RequirementSetsTable = (props) => (
  <div className="requirement-sets-table">
  <table className="table table-bordered table-striped">
    <thead>
      <tr>
        <th>Status</th>
        <th>Coverage</th>
        <th>Attribute</th>
        <th>Value</th>
        <th>Requirements</th>
      </tr>
    </thead>
    <tbody>
    {props.requirementSets.length > 0 ? (
      props.requirementSets.map(requirement => {
        return (
          <tr key={requirement.ruleId}>
            <td>{requirement.status}</td>
            <td>{requirement.ruleGroupName}</td>
            <td>{requirement.attributeName}</td>
            <td>{(Number(requirement.conditionTypeId) > 3 && (Number(requirement.conditionTypeId) < 8))
                ? Utils.formatCurrency(requirement.conditionValue) 
                : <DisplayTextWithConditionalModal string={requirement.conditionValue} />}
            </td>
            <td>{requirement.deficiencyText}</td>
          </tr>
        )
      })
    ):(
      <tr>
        <td colSpan={5}>Requirement Sets Met. Please click show all requirements to view requirements.</td>
      </tr>
    )}
    </tbody>
  </table>
  </div>
);

const RequirementSetsList = (props) => {
  return (
    <div className="row">      
      <div className="col-md-12" style={{ minWidth: '860px' }}>
      {(props.isFetching) ? (
        <div className="spinner-wrapper">
          <div className="spinner" />
        </div>
      ):(
        <RequirementSetsTable requirementSets={props.requirementSetsList} />
      )}  
      </div>
    </div>
  )
};

export default RequirementSetsList;