import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import renderField from '../../../../../customInputs/renderField';
import FilterActions from '../../../../../common/filterActions/FilterActions'

import moment from "moment";

class FilterWaivers extends React.Component {

  render() {
    const { title, holderName, projectName, waiverDate, } = this.props.locale;

    const { handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit} className="list-view-filter-form">
        <h2 className="list-view-filter-title">{title}</h2>
        <div className="container-fluid filter-fields">
          <div className="row">

            <div className="col-md-5 col-sm-12 no-padding">
              <div className="admin-form-field-wrapper keywords-field">
                <label htmlFor="holderName">{holderName.label}: </label>
                  <Field
                    name="holderName"
                    type="text"
                    placeholder={`-- ${holderName.ph} --`}
                    component={renderField}
                    className="tags-input"
                  />
              </div>
            </div>
            <div className="col-md-5 col-sm-12 no-padding">
              <div className="admin-form-field-wrapper keywords-field">
                <label htmlFor="projectName">{projectName.label}: </label>
                  <Field
                    name="projectName"
                    type="text"
                    placeholder={`-- ${projectName.ph} --`}
                    component={renderField}
                    className="tags-input"
                  />
              </div>
            </div>
            <div className="col-md-2 col-sm-12 no-padding">
              <div className="admin-form-field-wrapper keywords-field">
                <label htmlFor="waiverDate">{waiverDate.label}: </label>
                <Field
                  name="waiverEndDate"
                  type="date"
                  defaultValue={moment().format('MM/DD/YYYY')}
                  component={renderField}
                  className="tags-input"
                />
              </div>
            </div>

          </div>

          <div className="row">
            <div className="col-md-12 d-flex justify-content-end">
              <FilterActions
                formName={this.props.form}
                dispatch={this.props.dispatch}
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

FilterWaivers = reduxForm({
  form: 'FilterWaivers',
})(FilterWaivers);

const mapStateToProps = (state) => {
  return {
    locale: state.localization.strings.waivers.filter,
  }
};

export default connect(mapStateToProps)(FilterWaivers);
