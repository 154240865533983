import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getWorkFlow,
  updateWorkFlow,
  getWorkFlowComponents,
  saveWorkFlowComponents,
  resetWorkFlowComponents,
} from "./service";
import "./workflow.css";
import * as usersActions from "../../../users/actions";
import Utils from "../../../../lib/utils";
import PTable from "../../../common/ptable";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import loadOptions from "./loadOptions";
import swal from "sweetalert2";
import {
  ADD_WAITING_TASK_WF_COMPONENT_ID,
  PQ_ADMIN_ROLE_NAME,
  PQ_FROM_EMAIL_ADDRESS,
  PQ_FROM_EMAIL_NAME,
  PQ_MAIL_TEMPLATE,
  PQ_SEND_EMAIL,
} from "../../../../lib/appConstants";

const Alerts = require("../../../alerts");
const _ = require("lodash");

class Workflow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableOrderActive: "description",
      filter: {
        pageNumber: 1,
      },
      order: {
        detail: "desc",
        description: "asc",
      },
      loading: true,
      isTableVisible: true,
      currentWorkflow: {},
      workflows: [],
      components: [],
      showRoles: [],
      showUsers: [],
      caseTagsStyles: {
        multiValue: (base, state) => {
          return state.data.isFixed
            ? { ...base, backgroundColor: "gray" }
            : base;
        },
        multiValueLabel: (base, state) => {
          return state.data.isFixed
            ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
            : base;
        },
        multiValueRemove: (base, state) => {
          return state.data.isFixed ? { ...base, display: "none" } : base;
        },
      },
      roleTags: {},
      userTags: {},
      users: [],
      roles: [],
      validationState: []
    };

    this.onViewWorkflow = this.onViewWorkflow.bind(this);
    this.onAddComponent = this.onAddComponent.bind(this);
    this.onSaveChanges = this.onSaveChanges.bind(this);
    this.onResetWorkflow = this.onResetWorkflow.bind(this);
    this.clickOnColumnHeader = this.clickOnColumnHeader.bind(this);
    this.setPageFilter = this.setPageFilter.bind(this);
    this.renderParameter = this.renderParameter.bind(this);
    this.renderComponent = this.renderComponent.bind(this);
    this.buildComponents = this.buildComponents.bind(this);
    this.buildComponentParams = this.buildComponentParams.bind(this);
    this.buildPayload = this.buildPayload.bind(this);
    this.handleTagsInput = this.handleTagsInput.bind(this);
    this.populateRoleAndUsersValues =
      this.populateRoleAndUsersValues.bind(this);
  }

  componentWillMount() {
    getWorkFlow(
      this.props.global.workflow.hcId,
      this.props.userProfile.authToken,
      (err, data) => {
        this.setState({
          loading: false,
          workflows: data.map((x) => {
            x.loadingEnabled = false;
            x.loadingAuto = false;
            return x;
          }),
        });
      }
    );

    const { ssRoleOptions, roleOptions } = this.props.register;

    const isSelfService = ssRoleOptions.find(
      (role) => role.value === parseInt(this.props.roleId)
    );

    const roles = isSelfService ? ssRoleOptions : roleOptions.slice(1);
    const roleOptionsList = Utils.getOptionsList(
      "",
      roles,
      "label",
      "value",
      "label",
      0
    );
    this.setState({ roles: roleOptionsList.filter((i) => i.value) });
  }

  componentDidUpdate() {
    const tempArr = [];
    if (this.props.users.list.length && this.state.users.length === 0) {
      this.props.users.list.forEach((x) => {
        tempArr.push({ value: x.id, label: x.id, isFixed: false });
      });

      this.setState({ users: tempArr });
    }
  }

  clickOnColumnHeader(e, field) {
    if (field === "view") return;
    let newState = {
      tableOrderActive: field,
      order: {
        detail: field === "detail" ? "asc" : "desc",
        description: field === "description" ? "asc" : "desc",
      },
    };
    newState.order[field] = this.state.order[field] === "asc" ? "desc" : "asc";
    this.setState(newState);
  }

  setPageFilter(e, pageNumber) {
    if (this.state.filter.pageNumber !== pageNumber) {
      // save page number
      this.setState({
        filter: {
          pageNumber,
        },
      });
    }
  }

  onViewWorkflow(workflow) {
    if (this.state.loading || !workflow.workflowId) return;

    this.setState({ loading: true });

    getWorkFlowComponents(
      workflow.workflowId,
      this.props.userProfile.authToken,
      (err, data) => {
        if (!data || !data.possible_values) return;
        const components = this.buildComponents(
          data.components,
          data.possible_values
        );
        this.populateRoleAndUsersValues(components);
        this.setState({
          loading: false,
          isTableVisible: false,
          currentWorkflow: workflow,
          components,
          possible_values: data.possible_values,
        });
      }
    );
  }

  async populateRoleAndUsersValues(components) {
    let tempRoleTags = [],
      tempUserTags = [],
      tempShowUsers = [],
      tempShowRoles = [];
    const componentsPromises = components.map((_component, _index) => {
      let comName = _component.current_value.name;
      let showRoleAndIds = false;
      if (
        comName === "Send Email" ||
        comName === "Add Non-Waiting Task" ||
        comName === "Add Waiting Task"
      ) {
        showRoleAndIds = true;
      }
      if (showRoleAndIds) {
        const assignToType = _component.current_parameters.filter(
          (x) => x.name.toLowerCase() === "Assign To Type".toLowerCase()
        );

        if (
          assignToType &&
          assignToType.length &&
          assignToType[0].value === "Role"
        ) {
          const assignToRoleValues = _component.current_parameters.filter(
            (x) => x.name.toLowerCase() === "Assign To Role".toLowerCase()
          );

          if (
            assignToRoleValues &&
            assignToRoleValues[0].value &&
            assignToRoleValues[0].value !== ""
          ) {
            const rolesArray = assignToRoleValues[0].value.split(",");
            const rolesPayloadArray = [];
            if (rolesArray && rolesArray.length > 0) {
              rolesArray.forEach((roleVal) => {
                let current_tag = this.state.roles.filter(
                  (_role) => _role.value == roleVal
                );
                if (current_tag && current_tag.length > 0) {
                  rolesPayloadArray.push({
                    value: roleVal,
                    label: current_tag[0].label,
                  });
                }
              });
            }
            tempShowRoles.push(_index);
            tempRoleTags.push([...rolesPayloadArray]);
            tempUserTags.push([]);
          } else {
            tempRoleTags.push([]);
            tempUserTags.push([]);
          }
        } else if (
          assignToType &&
          assignToType.length &&
          assignToType[0].value === "User"
        ) {
          const assignToUserValues = _component.current_parameters.filter(
            (x) => x.name.toLowerCase() === "Assign To ID".toLowerCase()
          );
          if (
            assignToUserValues &&
            assignToUserValues[0].value &&
            assignToUserValues[0].value !== ""
          ) {
            const usersArray = assignToUserValues[0].value.split(",");
            const usersPayloadArray = [];
            if (usersArray && usersArray.length > 0) {
              usersArray.forEach((userVal) => {
                usersPayloadArray.push({
                  value: userVal,
                  label: userVal,
                });
              });
            }
            tempShowUsers.push(_index);
            tempUserTags.push([...usersPayloadArray]);
            tempRoleTags.push([]);
          } else {
            tempRoleTags.push([]);
            tempUserTags.push([]);
          }
        } else {
          tempRoleTags.push([]);
          tempUserTags.push([]);
        }
      } else {
        tempRoleTags.push([]);
        tempUserTags.push([]);
      }
    });
    this.setState({
      userTags: tempUserTags,
      roleTags: tempRoleTags,
      showUsers: tempShowUsers,
      showRoles: tempShowRoles,
    });
    const componentsPromisesResolved = await Promise.all(componentsPromises);
    return componentsPromisesResolved;
  }

  async onSaveChanges() {
    const payload = await this.buildPayload();
    let invalidationState = this.state.validationState.find(f => f.status === false);
    if (payload && !invalidationState) {
      this.setState({ loading: true, isTableVisible: true });
      saveWorkFlowComponents(
        payload,
        this.props.userProfile.authToken,
        (err, data) => {
          this.setState({ loading: false, isTableVisible: false });
        }
      );
    } else {
      const { wfIncompleteText, wfIncompleteTitle } = this.props.local.strings.workflows;
      swal({
        title: wfIncompleteTitle,
        text: wfIncompleteText,
        type: "warning",
        showCancelButton: false,
        showConfirmButton: false,
        timer: 3000
      });
      
    }
  }

  onResetWorkflow() {
    const onResetConfirmation = (confirmed) => {
      if (!confirmed) return;
      this.setState({ loading: true, isTableVisible: true });
      resetWorkFlowComponents(
        this.state.currentWorkflow.workflowId,
        this.props.userProfile.authToken,
        (err, data) => {
          getWorkFlow(
            this.props.global.workflow.hcId,
            this.props.userProfile.authToken,
            (err, data) => {
              if (data) {
                this.setState({
                  workflows: data.map((x) => {
                    x.loadingEnabled = false;
                    x.loadingAuto = false;
                    return x;
                  }),
                });
              }
            }
          );
          if (!data || !data.possible_values) return;
          const components = this.buildComponents(
            data.components,
            data.possible_values
          );
          this.setState({
            loading: false,
            isTableVisible: false,
            components,
            possible_values: data.possible_values,
          });
        }
      );
    };

    const alertContent = {
      title: "Reset To Default",
      text: "Are you sure you want to reset this workflow?",
      btn_no: "Cancel",
      btn_yes: "Accept",
    };
    Alerts.showActionConfirmation(alertContent, onResetConfirmation);
  }

  onAddComponent() {
    const newComponents = this.state.components;
    newComponents.push({
      positionIndex: this.state.components.length + 1,
      current_value: { componentId: "" },
      current_parameters: [],
    });
    this.setState({ components: newComponents });
  }

  async validateComponentParamterValue(components, parameter) {
    let checkValidation = false;
    const parameterType = 'assign to type';
    const assignToType = components.current_parameters.find(f => f.name.toLowerCase() === parameterType);
    if (assignToType && assignToType.value === "User") {
      if (parameter.name.toLowerCase() === "assign to id") {
        checkValidation = true;
      }
    }
    else if (assignToType && assignToType.value === "Role") {
      if (parameter.name.toLowerCase() === "assign to role") {
        checkValidation = true;
      }
    }
    if([PQ_FROM_EMAIL_NAME, PQ_FROM_EMAIL_ADDRESS, PQ_MAIL_TEMPLATE].includes(parameter.componentParameterId)) checkValidation = true;

    if (checkValidation) {
      let value = true;
      const currentParams = parameter;
      if (currentParams && currentParams.value) {
        const currentParamsValue = currentParams.value;
        if (Array.isArray(currentParamsValue)) {
          value = currentParamsValue.length > 0 ? true : false
        }
        else {
          value = currentParamsValue === '' ? false : true
        }
      } else {
        value = false;
      }
      // validate email address
      if(currentParams &&  currentParams.componentParameterId === PQ_FROM_EMAIL_ADDRESS  ){
        value = Utils.isEmailValid(currentParams.value)
      }
      if (!value) {
        await this.setState((prevState) => {
          let tempValidation = prevState.validationState;
          tempValidation = [...tempValidation, { parameterId : parameter.componentParameterId , fieldName: parameter.name, status: value, positionIndex: components.positionIndex }]
          return { ...prevState, validationState: tempValidation };
        })
      }
      return value;
    }
    return true;
  }

  async buildPayload() {
    await this.setState({ validationState: [] });
    const payload = {
      hiringClientId: this.props.global.workflow.hcId,
      workflowTypeId: this.state.currentWorkflow.id,
      components: [],
    };
    const components = this.state.components;
    for (var i = 0; i < components.length; i++) {
      // create the component
      const component = {
        positionIndex: components[i].positionIndex,
        componentId: components[i].current_value.componentId,
        parameters: [],
      };

      // get components parameters
      const parameters = components[i].current_parameters;
      for (var j = 0; j < parameters.length; j++) {

        const validfield = await this.validateComponentParamterValue(components[i], parameters[j]);
        const parameter = {
          componentParameterId: parameters[j].componentParameterId,
          value: parameters[j].value,
          validfield
        };

        component.parameters.push(parameter);
      }

      payload.components.push(component);
    }
    return payload;
  }

  buildComponents(components, possible_values) {
    for (var i = 0; i < components.length; i++) {
      // look for all possible params for the current components
      let parameters = [];
      for (var j = 0; j < possible_values.length; j++) {
        if (
          components[i].current_value.componentId.toString() ===
          possible_values[j].id.toString()
        ) {
          components[i].current_value.name = possible_values[j].name;
          parameters = possible_values[j].parameters;
          break;
        }
      }
      components[i].current_parameters = this.buildComponentParams(
        components[i].current_parameters,
        parameters
      );
    }
    return components;
  }

  buildComponentParams(currentParams, allParameters) {
    const newParams = [];
    for (var i = 0; i < allParameters.length; i++) {
      // create a new possible param for this component
      const newParam = {
        componentParameterId: allParameters[i].id,
        name: allParameters[i].name,
        value: "",
      };
      //check if there's an stored value
      for (var j = 0; j < currentParams.length; j++) {
        if (currentParams[j].componentParameterId === allParameters[i].id) {
          newParam.value = currentParams[j].value;
          break;
        }
      }
      //add new param
      newParams.push(newParam);
    }
    return newParams;
  }

  renderParameter(component, parameter, idx, parentId) {
    const index = _.findIndex(component.current_parameters, function (o) {
      return o.componentParameterId.toString() === parameter.id.toString();
    });
    const currentParam = component.current_parameters[index];
    if(currentParam.name.toLowerCase() === "Days to complete".toLowerCase() && currentParam.value === ""){
      currentParam.value = "1";
    }
    // create the list of options
    const possibleValues = Utils.getOptionsList(
      "Select an option",
      parameter.possible_values,
      "value",
      "value",
      "value"
    );

    const onParamChange = (e) => {
      // modify the component's current parameter value
      const componentParams = component.current_parameters;
      let removeUnusedParamTypeRole = 'assign to role';
      let removeUnusedParamTypeUser = 'assign to id';
      let checkAssignToType = 'assign to type';
      if (componentParams[index].name.toLowerCase() === checkAssignToType) {
        for (let i = 0; i < componentParams.length; i++) {
          let currentParameter = componentParams[i].name.toLowerCase();
          if (currentParameter === removeUnusedParamTypeUser || currentParameter === removeUnusedParamTypeRole) {
            componentParams[i].value = "";
          }
        }
      }
      componentParams[index].value = e.target.value;
      // look for the component and asign the new params
      const newComponents = this.state.components.map((a, index) =>
        a.positionIndex === component.positionIndex
          ? {
              ...a,
              current_parameters: componentParams,
            }
          : a
      );
      let comName = component.current_value.name;
      let showRoleAndIds = false;
      if (
        comName === "Send Email" ||
        comName === "Add Non-Waiting Task" ||
        comName === "Add Waiting Task"
      ) {
        showRoleAndIds = true;
      }

      if (
        showRoleAndIds &&
        parameter.name.toLowerCase() === "Assign To Type".toLowerCase()
      ) {
        if (e.target.value === "Role") {
          const tempRoles = [...this.state.showRoles];
          const tempUsers = [...this.state.showUsers];

          tempRoles.push(parentId);
          const index = tempUsers.indexOf(parentId);
          if (index > -1) {
            tempUsers.splice(index, 1);
          }
          let tempUserTags = this.state.userTags;
          tempUserTags[parentId] = [];
          this.setState({
            showRoles: tempRoles,
            showUsers: tempUsers,
            userTags: tempUserTags,
          });
        } else if (e.target.value === "User") {
          const tempRoles = [...this.state.showRoles];
          const tempUsers = [...this.state.showUsers];
          tempUsers.push(parentId);
          const index = tempRoles.indexOf(parentId);
          if (index > -1) {
            tempRoles.splice(index, 1);
          }
          let tempRoleTags = this.state.roleTags;
          tempRoleTags[parentId] = [];
          this.setState({
            showRoles: tempRoles,
            showUsers: tempUsers,
            roleTags: tempRoleTags,
          });
        } else {
          const tempRoles = [...this.state.showRoles];
          const tempUsers = [...this.state.showUsers];

          let index = tempRoles.indexOf(parentId);
          if (index > -1) {
            tempRoles.splice(index, 1);
          }

          index = tempUsers.indexOf(parentId);
          if (index > -1) {
            tempUsers.splice(index, 1);
          }

          this.setState({ showRoles: tempRoles, showUsers: tempUsers });
        }
      }
      this.setState({ components: newComponents });
    };
    return (
      <div key={idx} className="row parameters-row">
        <div className="col col-lg-3 parameter-col">
          <div className="param-name">{parameter.name}:</div>
        </div>
        <div
          className="col col-lg-9 parameter-col"
          style={{ paddingLeft: "15px" }}
        >
          {parameter.possible_values.length > 0 ? (
            <select
              className="param-select"
              onChange={onParamChange}
              value={currentParam.value}
            >
              {possibleValues.map((item, idx) => {
                if( (parameter.name === 'Days to complete' || parameter.name === 'Number of Iterations') && !item.value) return
                return (
                  <option key={idx} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </select>
          ) : (
            <input
              className="param-select"
              type="text"
              value={currentParam.value}
              onChange={onParamChange}
            />
          )}
          {this.renderInvalidField(component, parameter)}
        </div>
      </div>
    );
  }

  handleTagsInput(type, tags, idx, component, parameter) {
    var tempTags = { ...this.state[type] };
    tempTags[idx] = tags;
    this.setState({ [type]: tempTags });
    if (parameter && parameter.id) {
      const index = _.findIndex(component.current_parameters, function (o) {
        return o.componentParameterId.toString() === parameter.id.toString();
      });
      const componentParams = component.current_parameters;
      if (tags.value) {
        componentParams[index].value = tags.value;
      } else {
        componentParams[index].value = tags.map((tag) => tag.value);
      }
      const newComponents = this.state.components.map((item, index) =>
        item.positionIndex === component.positionIndex
          ? {
              ...item,
              current_parameters: componentParams,
            }
          : item
      );
      const invalidStateFilter = this.state.validationState.filter(f => f.positionIndex !== component.positionIndex);
      this.setState({ components: newComponents, validationState: invalidStateFilter });
    }
  }

  renderInvalidField(component, parameter) {
    const invalidationState = this.state.validationState ? this.state.validationState : null;
    let invalidCurrentField;
    if (invalidationState.length > 0) {
      invalidCurrentField = invalidationState.find(f =>
        // match the componentPositon with invalid parameter component position
        f.positionIndex === component.positionIndex
        // check if parameter has error
        && (!parameter || (parameter && parameter.id == f.parameterId ) )
        );
        if (invalidCurrentField) {
          let errorMessage = "";
      if (invalidCurrentField.fieldName.toLowerCase() === 'assign to id') errorMessage = `Please select atleast one User ID`;
      if (invalidCurrentField.fieldName.toLowerCase() === 'assign to role') errorMessage = `Please select atleast one Role`;
      if (invalidCurrentField.parameterId == PQ_FROM_EMAIL_ADDRESS ) errorMessage = `Please select valid Email Address`;
      if (invalidCurrentField.parameterId == PQ_FROM_EMAIL_NAME ) errorMessage = `Please select Email Name`;
      if (invalidCurrentField.parameterId == PQ_MAIL_TEMPLATE ) errorMessage = `Please select valid Email template`;
      
      return (<div className="text-danger">{errorMessage}</div>)
    }
    else {
      return null
    }
  }
    
  }

  renderComponent(component, idx) {
    const positionIndex = component.positionIndex;
    const currentValueId = component.current_value.componentId || "";
    const { removeWorkflowActionLabel } = this.props.local.strings.workflows;
    let isPQAdmin =
      this.props.userProfile.profile.Role.Name === PQ_ADMIN_ROLE_NAME;
    let initialShowUsers = false;
    let initialShowRoles = false;

    // TODO: FIX: HACK: Workflows are not designed to receive dynamic data nor form compound fields, so this patch is applied to a feature that adds static capabilities.
    const dynamicParameter = component.current_parameters.find(
      (x) => x.name === "Assign To Type"
    );

    if (dynamicParameter && ["User", "Role"].includes(dynamicParameter.value)) {
      if (dynamicParameter.value === "User") initialShowUsers = true;
      else initialShowRoles = true;
    }

    // get all selected component's possible parameters
    let parameters = [];

    if (currentValueId !== "") {
      const index = _.findIndex(this.state.possible_values, function (o) {
        return o.id.toString() === currentValueId.toString();
      });
      parameters = this.state.possible_values[index].parameters;
    }

    // create the list of options
    const possibleValues = Utils.getOptionsList(
      "Select an action",
      this.state.possible_values,
      "name",
      "id",
      "name"
    );
    let currentComponentName = component.current_value.name;
    const onComponentChange = (e) => {

      // If MailTemplats are not configured, show message to configure it first 
      if(e.target.value === PQ_SEND_EMAIL.toString()){
        const sendMailValue = this.state.possible_values.find(possibleValue => possibleValue.id === PQ_SEND_EMAIL )
        const mailTemplateValues = sendMailValue.parameters.find(sendMailParameters => sendMailParameters.id === PQ_MAIL_TEMPLATE  )
        if(mailTemplateValues.possible_values.length === 0) return swal.fire({
          icon: 'error',
          title: 'Failed to Load Send Email Component',
          text: 'Please configure Email Templates to use Send Mail component',
        })

      }
      //look for the modified component and change the current_value.componentId
      let tempUserTags = this.state.userTags;
      tempUserTags[idx] = [];
      let tempRoleTags = this.state.roleTags;
      tempRoleTags[idx] = [];
      let tempShowUsers = this.state.showUsers;
      let tempShowRoles = this.state.showRoles;
      if (tempShowUsers && tempShowUsers.length > 0) {
        let index = tempShowUsers.indexOf(idx);
        if (index > -1) {
          tempShowUsers.splice(index, 1);
        }
      }
      if (tempShowRoles && tempShowRoles.length > 0) {
        let index = tempShowRoles.indexOf(idx);
        if (index > -1) {
          tempShowRoles.splice(index, 1);
        }
      }
      this.setState({
        userTags: tempUserTags,
        roleTags: tempRoleTags,
        showUsers: tempShowUsers,
        showRoles: tempShowRoles,
      });
      let newComponents = this.state.components;
      for (var i = 0; i < newComponents.length; i++) {
        if (newComponents[i].positionIndex === positionIndex) {
          newComponents[i].current_value.componentId = e.target.value;
          break;
        }
      }
      //build components to get new current_value.name and parameters
      newComponents = this.buildComponents(
        newComponents,
        this.state.possible_values
      );
      // save the modified component list
      this.setState({ components: newComponents });
    };

    return (
      <div key={idx} className="row component-row">
        <div className={isPQAdmin ? "col col-lg-1 cell" : "col col-lg-2 cell"}>
          <span className="component-line-id">{positionIndex}</span>
        </div>
        <div
          className={
            isPQAdmin
              ? "col col-lg-3 cell dashed-border-left"
              : "col col-lg-5 cell dashed-border-left"
          }
        >
          <select
            className="component-select"
            onChange={onComponentChange}
            value={currentValueId}
          >
            {possibleValues.map((item, idx) => {
              return (
                <option key={idx} value={item.value}>
                  {item.label}
                </option>
              );
            })}
          </select>
        </div>
        <div
          className="col col-lg-5 cell dashed-border-left"
          style={{ display: "flex", flexDirection: "column" }}
        >
          {(initialShowRoles || this.state.showRoles.includes(idx)) && (
            <div className="row parameters-row">
              <div className="col col-lg-3 parameter-col">
                <div className="param-name">Assign To Role:</div>
              </div>
              <div
                className="col col-lg-9 parameter-col"
                style={{ paddingLeft: "15px" }}
              >
                <Select
                  isMulti={
                    currentComponentName === "Add Waiting Task" ? false : true
                  }
                  name="colors"
                  options={this.state.roles}
                  className="param-select"
                  classNamePrefix="select"
                  isClearable={false}
                  defaultValue={this.state.roleTags[idx]}
                  onChange={(tags) =>
                    this.handleTagsInput(
                      "roleTags",
                      tags,
                      idx,
                      component,
                      parameters.filter(
                        (p) =>
                          p.name.toLowerCase() ===
                          "Assign To Role".toLowerCase()
                      )[0]
                    )
                  }
                  styles={this.state.caseTagsStyles}
                />
                {
                  // 84 is the id of PQ_ASSIGN_TO_ROLE
                  this.renderInvalidField(component, {id : 84})
                }
              </div>
            </div>
          )}
          {(initialShowUsers || this.state.showUsers.includes(idx)) && (
            <div className="row parameters-row">
              <div className="col col-lg-3 parameter-col">
                <div className="param-name">Assign To ID:</div>
              </div>
              <div
                className="col col-lg-9 parameter-col"
                style={{ paddingLeft: "15px" }}
              >
                <AsyncPaginate
                  isMulti={
                    currentComponentName === "Add Waiting Task" ? false : true
                  }
                  isSearchable
                  openMenuOnClick
                  openMenuOnFocus
                  closeMenuOnScroll
                  loadOptionsOnMenuOpen
                  cacheOptions
                  pageSize={10}
                  additional={{ page: 1 }}
                  classNamePrefix="vk"
                  className="select-parent"
                  menuPlacement="bottom"
                  defaultValue={this.state.userTags[idx]}
                  getOptionLabel={(el) => el.value}
                  onChange={(tags) =>
                    this.handleTagsInput(
                      "userTags",
                      tags,
                      idx,
                      component,
                      parameters.filter(
                        (p) =>
                          p.name.toLowerCase() === "Assign To ID".toLowerCase()
                      )[0]
                    )
                  }
                  loadOptions={(value, prevOptions, additional) =>
                    loadOptions(
                      value,
                      prevOptions,
                      additional,
                      this.props.global.workflow.hcId
                    )
                  }
                />
                {
                  // 85 is the id of PQ_ASSIGN_TO_USERID
                  this.renderInvalidField(component, {id : 85})
                }
              </div>
            </div>
          )}

          {parameters.map((param, index) => {
            if (
              !(
                (currentComponentName === "Send Email" ||
                  currentComponentName === "Add Waiting Task" ||
                  currentComponentName === "Add Non-Waiting Task") &&
                (param.name.toLowerCase() === "Assign To ID".toLowerCase() ||
                  param.name.toLowerCase() === "Assign To Role".toLowerCase())
              )
            ) {
              return this.renderParameter(component, param, index, idx);
            }
          })}
        </div>
        {isPQAdmin && (
          <div className="col col-lg-3 cell dashed-border-left">
            <a
              onClick={(e) => this.deleteWorkflowAction(e, positionIndex)}
              className="cell-table-link icon-delete"
            >
              {removeWorkflowActionLabel}
            </a>
          </div>
        )}
      </div>
    );
  }

  deleteWorkflowAction = (e, positionIndex) => {
    const { removeWorkflowActionLabel, removeWorkflowActionText } =
      this.props.local.strings.workflows;
    const { components, currentWorkflow } = this.state;
    swal({
      title: removeWorkflowActionLabel,
      text: removeWorkflowActionText.replace("$1", currentWorkflow.description),
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2E5965",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.value) {
        //console.log('components:', components);
        const indexToRemove = components.findIndex(
          (e) => e.positionIndex === positionIndex
        );
        if (components[indexToRemove]) {
          const componentId =
            components[indexToRemove].current_value.componentId;
          if (parseInt(componentId, 10) === ADD_WAITING_TASK_WF_COMPONENT_ID) {
            this.setState({
              stepsToRemoveAssocTasks:
                this.state.stepsToRemoveAssocTasks.concat({
                  workflowId: currentWorkflow.workflowId,
                  stepIndex: positionIndex,
                }),
            });
          }
        }

        components.splice(indexToRemove, 1);
        const reorderedComponents = components.map((e, i) => {
          e.positionIndex = i + 1;
          return e;
        });

        let tempUserTags = this.state.userTags;
        if (tempUserTags[indexToRemove]) {
          tempUserTags.splice(indexToRemove, 1);
        }
        let tempRoleTags = this.state.roleTags;
        if (tempRoleTags[indexToRemove]) {
          tempRoleTags.splice(indexToRemove, 1);
        }
        this.setState({
          userTags: tempUserTags,
          roleTags: tempRoleTags,
          components: reorderedComponents,
        });
        this.populateRoleAndUsersValues(reorderedComponents);
      }
    });
  };

  render() {
    if (!this.state.workflows) {
      return null;
    }

    let isPrequalRole = false,
      isPQAdmin = false;
    if (this.props.userProfile.profile.Role) {
      isPrequalRole = this.props.userProfile.profile.Role.IsPrequalRole;
      isPQAdmin =
        this.props.userProfile.profile.Role.Name === PQ_ADMIN_ROLE_NAME;
    }

    const handleUpdateWorkflow = (item) => {
      const payload = {
        hcId: this.props.global.workflow.hcId,
        enable: item.isEnabled,
        auto: item.isAuto,
      };
      updateWorkFlow(
        item.workflowId,
        payload,
        this.props.userProfile.authToken,
        () => {
          getWorkFlow(
            this.props.global.workflow.hcId,
            this.props.userProfile.authToken,
            (err, data) => {
              this.setState({
                workflows: data.map((x) => {
                  x.loadingEnabled = false;
                  x.loadingAuto = false;
                  return x;
                }),
              });
            }
          );
        }
      );
    };

    const workflowsTableMetadata = {
      fields: ["description", "detail", "view", "active", "auto"],
      header: {
        description: "Type",
        detail: "Description",
        view: "",
        active: "",
        auto: "",
      },
    };

    const field = this.state.tableOrderActive;
    const direction = this.state.order[field];
    const orderedLanguagesList = _.orderBy(
      this.state.workflows,
      [(o) => (o[field] ? o[field].toLowerCase() : "")],
      direction
    );
    const workflowsTableBody = orderedLanguagesList.map((item) => {
      const style = item.workflowId ? {} : { color: "red" };
      return {
        description: item.description,
        detail: item.detail,
        view: (
          <a
            className="cell-table-link icon-quick_view link-ada-color"
            style={style}
            onClick={(e) => {
              this.onViewWorkflow(item);
            }}
          >
            VIEW WORKFLOW
          </a>
        ),
        active: (
          <div
            className="col-md-auto mt-1"
            style={{ display: "flex", alignItems: "center" }}
          >
            {isPrequalRole &&
              item &&
              item.workflowId &&
              (item.loadingEnabled ? (
                <div className="spinner-wrapper spinner-workflow">
                  <div className="spinner"></div>
                </div>
              ) : (
                <div>
                  <input
                    type="checkbox"
                    checked={item.isEnabled}
                    className="pretty-checkbox checkbox-workflow"
                    onClick={() => {
                      this.setState({
                        workflows: this.state.workflows.map((x) => {
                          if (x.workflowId === item.workflowId) {
                            x.loadingEnabled = true;
                          }
                          return x;
                        }),
                      });
                      item.isEnabled = !item.isEnabled;
                      handleUpdateWorkflow(item);
                    }}
                    disabled={!item.workflowId ? true : false}
                  />
                  &nbsp;<span>Enable</span>
                </div>
              ))}
          </div>
        ),
        auto: (
          <div
            className="col-md-auto mt-1"
            style={{ display: "flex", alignItems: "center" }}
          >
            {isPrequalRole &&
              item &&
              item.workflowId &&
              (item.loadingAuto ? (
                <div className="spinner-wrapper spinner-workflow">
                  <div className="spinner"></div>
                </div>
              ) : item.isEnabled ? (
                <div>
                  <input
                    type="checkbox"
                    checked={item.isAuto}
                    className="pretty-checkbox checkbox-workflow"
                    onClick={() => {
                      this.setState({
                        workflows: this.state.workflows.map((x) => {
                          if (x.workflowId === item.workflowId) {
                            x.loadingAuto = true;
                          }
                          return x;
                        }),
                      });
                      item.isAuto = !item.isAuto;
                      handleUpdateWorkflow(item);
                    }}
                    disabled={
                      item.description === "File Upload" || !item.workflowId
                        ? true
                        : false
                    }
                  />
                  &nbsp;<span>Auto</span>
                </div>
              ) : null)}
          </div>
        ),
      };
    });

    const start = (this.state.filter.pageNumber - 1) * 10;
    const end = start + 10;
    const workflowsTableData = {
      fields: workflowsTableMetadata.fields,
      header: workflowsTableMetadata.header,
      body: workflowsTableBody.slice(start, end),
    };
    const paginationSettings = {
      total: this.state.workflows.length, //total elements rendered after search is applied
      itemsPerPage: 10,
      setPageHandler: this.setPageFilter,
      currentPageNumber: this.state.filter.pageNumber,
    };

    return (
      <div className="list-view admin-view-body">
        {!this.state.isTableVisible ? (
          <section className="list-view-header projects-view-header">
            <div className="row" style={{ justifyContent: "space-between" }}>
              <nav className="list-view-nav">
                <ul>
                  <li>
                    <a
                      className="list-view-nav-link nav-bn icon-login-door no-overlapping"
                      onClick={() => this.setState({ isTableVisible: true })}
                    >
                      BACK TO WORKFLOWS
                    </a>
                  </li>
                </ul>
              </nav>
              <nav className="list-view-nav" style={{ marginRight: "20px" }}>
                <ul>
                  <li>
                    <a
                      className="list-view-nav-link nav-bn icon-log no-overlapping"
                      onClick={this.onResetWorkflow}
                    >
                      {"RESET TO DEFAULT"}
                    </a>
                  </li>
                  <li>
                    <a
                      className="list-view-nav-link nav-bn icon-save no-overlapping"
                      onClick={this.onSaveChanges}
                    >
                      {"SAVE CHANGES"}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </section>
        ) : null}

        {this.state.isTableVisible ? (
          <div className="list-view admin-view-body">
            <PTable
              sorted={true}
              items={workflowsTableData}
              wrapperState={this.state}
              tableOrderActive={this.state.tableOrderActive}
              clickOnColumnHeader={this.clickOnColumnHeader}
              isFetching={this.state.loading}
              customClass="templates-list"
              pagination={paginationSettings}
            />
          </div>
        ) : null}

        {!this.state.isTableVisible ? (
          <div className="edit-workflow-container">
            <div className="workflow-title">
              {this.state.currentWorkflow.description}
            </div>
            <div className="workflow-components">
              <div className="row component-row">
                <div
                  className={
                    isPQAdmin ? "col cell col-lg-1" : "col cell col-lg-2"
                  }
                ></div>
                <div
                  className={
                    isPQAdmin
                      ? "col cell dashed-border-left col-lg-3"
                      : "col cell dashed-border-left col-lg-5"
                  }
                >
                  <span className="component-title">WORKFLOW ACTION</span>
                </div>
                <div className="col col-lg-5 cell dashed-border-left">
                  <span className="component-title">ACTION PARAMETERS</span>
                </div>
                {isPQAdmin && (
                  <div className="col col-lg-3 cell dashed-border-left"></div>
                )}
              </div>
              {this.state.components.map(this.renderComponent)}
            </div>
            <div className="add-component-btn-container">
              <button
                className="add-component-btn"
                onClick={this.onAddComponent}
              >
                Add Action
              </button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    global: state,
    userProfile: state.login,
    local: state.localization,
    register: state.register,
    roleId: state.login.profile.RoleID,
    users: state.users,
    login: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(usersActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Workflow);
