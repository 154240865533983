import { Formio } from '@formio/react';

const FORMIO_SERVICE_URL = process.env.REACT_APP_FORMIO_SERVICE_URL
/**
 * For custom delpoyment, Formio has a JWT based SSO method for authentication
 * [Detailed Doc](https://help.form.io/developers/auth#custom-jwt-authentication)
 * 
 * How to Create this Token?
 * [Formi DOC](https://apidocs.form.io/?version=latest#03acc709-aaba-b066-c69b-fb7ab92a48d0)
 * POST correct credentials to formio service which will send back x-jwt-token in header
 */

// !  This is a hardcoded JWT with a long expiration date (Epoch timestamp - 4765132800).
// TODO: JWT should be maintained by a RBAC middleware setting between frontend and formio service
// Our own RBAC will take care of Form auth operation. Hardcoding JWT for complying with OTTB formio implementation

const FORMIO_TOKEN = process.env.REACT_APP_FORMIO_TOKEN

export const AppConfig = {
  projectUrl: FORMIO_SERVICE_URL,
  apiUrl: FORMIO_SERVICE_URL
};

export const AuthConfig = {
  anonState: '/auth',
  authState: '/',
  login: {
    form: 'user/login'
  },
  register: {
    form: 'user/register'
  }
};

Formio.setProjectUrl(AppConfig.projectUrl);
Formio.setBaseUrl(AppConfig.apiUrl);
localStorage.setItem('formioToken', FORMIO_TOKEN);

