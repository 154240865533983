import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Form, Field } from 'redux-form';
import _ from 'lodash';
import Spinner from '../../../../common/spinner';

import renderField from '../../../../customInputs/renderField';
import fileInput from '../../../../customInputs/fileInput';
import Utils from '../../../../../lib/utils';
import validate from './validation';
import './addFileModal.css';

class AddFileModal extends React.Component {
  render() {
    const {
      title,
      fileInputLabel,
      descriptionInputLabel,
      formSelectLabel,
      buttonText,
      checkboxLabel,
      checkBoxConfidentialLabel
    } = this.props.local.strings.scProfile.files.addModal;
    const fileLabel = _.get(this.props, 'reduxForm.AddFileModalForm.values.documentFile.name')
    this.props.formSubmissions.list.sort(function(a,b){
      return new Date(b.startDate) - new Date(a.startDate);
    });

    const checkBoxwithPermission = () => {
      const loginProfile = this.props.loginProfile
      if (loginProfile.Role.IsPrequalRole || loginProfile.Role.IsHCRole) {
        return <div className="row checkboxRow">
          <div className="col-sm-12 no-padd">
            <div className="admin-form-field-wrapper">
              <div className="add-file-label custLabel">{checkBoxConfidentialLabel}:</div>
              <Field
                className="finCheckBox"
                type="checkbox"
                name="confidentialDataFlag"
                component={renderField}
                defaultValue={1}
                defaultChecked={true}
              />
            </div>
          </div>
        </div >
      }
    }
    return (
      <Form className="add-file-modal" onSubmit={this.props.handleSubmit}>
        <header className="add-file-modal-header">{title}</header>

        <div className="add-file-content container-fluid filter-fields">
          <div className="row">
            <div className="col-sm-12 no-padd">
              <div className="upload-file-wrapper">
                <label htmlFor="documentFile" className='file-input'>
                  {fileLabel || fileInputLabel}
                </label>
                <Field
                  name="documentFile"
                  id="documentFile"
                  component={fileInput}
                  onChange={this.onFileInputChange}
                />
              </div>
            </div>
          </div>

          <div className="row checkboxRow">
            <div className="col-sm-12 no-padd">
              <div className="admin-form-field-wrapper">
                <div className="add-file-label custLabel">    {checkboxLabel}:</div>
                <Field
                  className="finCheckBox"
                  type="checkbox"
                  name="FinancialDataFlag"
                  component={renderField}
                />
              </div>
            </div>
          </div >
          {checkBoxwithPermission()}

          <div className="row mb-4">
            <div className="col-sm-12 no-padd">
              <div className="admin-form-field-wrapper">
                <div className="add-file-label">{formSelectLabel}:</div>
                <div className="select-wrapper">
                  <Field
                    style={{ height: '30px' }}
                    className="w-100 border border-info bg-white"
                    name="SavedFormID"
                    id="SavedFormID"
                    component="select">
                    <option values="null" disabled>{` --Select Form-- `}</option>
                    <option value="">{`No Form`}</option>
                    {this.props.formSubmissions.list.map((item) => {
                      return item.submissionDate ? <option value={item.id}>{item.formName} - {Utils.getFormattedDate(item.submissionDate, true)}</option> : <option value={item.id}>{item.formName} - Pending</option>
                    })}
                  </Field>
                </div>
              </div>
            </div>
          </div >
          <div className="row">
            <div className="col-sm-12 no-padd">
              <div className="admin-form-field-wrapper">
                <div className="add-file-label">{descriptionInputLabel}:</div>
                <Field
                  type="textarea"
                  name="description"
                  component={renderField}
                  className="description-input"
                />
              </div>
            </div>
          </div >

          <div className="wiz-buttons">
            <button disabled={this.props.isSubmitting ? 'disabled' : null} className="wiz-continue-btn bg-sky-blue-gradient bn">{buttonText}</button>
          </div>
        </div >

      </Form >
    );
  }
};

AddFileModal = reduxForm({
  form: 'AddFileModalForm',
  validate
})(AddFileModal);

const mapStateToProps = (state) => {
  return {
    users: state.users,
    local: state.localization,
    loginProfile: state.login.profile,
    formSubmissions: state.formSubmissions,
    reduxForm: state.form
  };
};

export default connect(mapStateToProps)(AddFileModal);
