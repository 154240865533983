import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import {Link, Redirect} from 'react-router-dom';
import Swal from 'sweetalert2';
import AddInsuredModal from '../modals/addInsuredModal';
import FilterInsureds from './filter';
import Utils from '../../../lib/utils';
import QuickView from './quickview';

import * as commonActions from '../../common/actions';
import * as actions from './actions';
import RolAccess from './../../common/rolAccess';

import './Insureds.css';
import MUIDataTable from "mui-datatables";
import {
  Button,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core";
import Api from "../../../lib/api";

import CountryDropdown from "./filter/CountryDropdown";
import * as filterActions from '../filterbox/actions';


class Insureds extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterBox: {
        insuredName: '',
        projectName: '',
        holderName: '',
        countryId: '',
        countryLabel: '',
        stateId: '',
        stateLabel: '',
        archive: 0,
        vendor: '',
        tags: '',
      },
      orderBy: 'insuredName',
      orderDirection: 'asc',
      currentInsured: null,
      showFilterBox: true,
      count: 0,
      data: [],
      urlQuery: 'cf/insureds',
      pageNumber: 1,
      pageSize: 10,
      countries: []
    };
  }

  componentDidMount() {

    this.props.commonActions.setBreadcrumbItems([]);
  }

  componentDidUpdate() {
    if(this.props.insureds.searchInsured){
      this.fetchInsureds(this.state.pageNumber, this.state.pageSize, this.state.orderBy, this.state.orderDirection, {...this.state.filterBox, insuredName: this.props.insureds.insuredSearchText});
      this.props.actions.stopSearchingInsureds()
    }
  }

  fetchInsureds = (pageNumber, pageSize, orderBy = this.state.orderBy, orderDirection = this.state.orderDirection, filterBox = this.state.filterBox) => {
    // get base query
    this.props.commonActions.setLoading(true)

    let query = Utils.getFetchQuery(orderBy, pageNumber, orderDirection, pageSize);

    // add search filters
    query = Utils.addSearchFiltersToQuery(query, filterBox);

    let urlParameters = `?${Object.keys(query).map(i => `${i}=${query[i]}`).join('&')}`;

    const token = this.props.login.authToken;

    Api.get(`${this.state.urlQuery}${urlParameters}`, token)
      .then(response => {
        let { success, totalCount, data } = response.data;
        this.props.commonActions.setLoading(false)
        let errorMsg = '';
        data = data.map(v => {
          if (!v) return;

          let holderId = v && v.HolderId ? v.HolderId[0] : null;
          let holderName = v && v.HolderName ? v.HolderName[0] : null;
          v['View'] = `${process.env.REACT_APP_FRONTEND_URL}/certfocus/insureds/${v.Id}/${holderId}/${holderName}`
          return v;
        })
        if (success) {
          this.setState({
            count: totalCount,
            data: data ? data : [],
            pageNumber: pageNumber,
            pageSize: pageSize,
            orderBy: orderBy,
            orderDirection: orderDirection,
            filterBox: filterBox
          })
        } else {
          // TODO:: Show error
        }
      })
      .catch((e) => {
        this.props.commonActions.setLoading(false)
        console.log('Error: ',e)
      });
  }

  submitFilterForm = (values) => {
    // add search filters
    const filterBox = {
      insuredName: values.insuredName || "",
      projectName: values.projectName || "",
      holderName: values.holderName || "",
      stateId: values.state && values.state.value || "",
      countryId: values.country && values.country.value || "",
      archive: values.archive || 0,
      vendor: values.vendor || '',
      tags: values.tags || '',
    };
    let saveFilter = { tab: 'insureds' }
    saveFilter.module = 'GlobalInsureds';
    saveFilter.objectId = null;
    saveFilter.filterBox = {
      insuredName: values.insuredName || "",
      projectName: values.projectName || "",
      holderName: values.holderName || "",
      stateId: values.state && values.state || "",
      countryId: values.country && values.country || "",
      archive: values.archive || 0,
      vendor: values.vendor || '',
      tags: values.tags || '',
    };


    this.props.filterActions.saveDefaultFilters('FilterInsureds', saveFilter);

    // fetch using query
    this.fetchInsureds(1, this.state.pageSize, this.state.orderBy, this.state.orderDirection, filterBox)

  }

  editInsured = (insured) => {
    this.setState({
      currentInsured: insured
    }, this.openModal);
  }

  openModal = () => {
    this.props.actions.setShowModal(true);
  }

  closeModal = () => {
    this.props.actions.setShowModal(false);

    this.setState({
      currentInsured: null
    });
  }

  closeModalAndRefresh = (update) => {
    this.props.actions.setShowModal(false);
    this.fetchInsureds(1, this.state.pageSize);
    this.setState({
      currentInsured: null
    });
  }

  renderButtonAddinsured() {
    let component = (
      <a onClick={this.openModal}
        className="nav-btn nav-bn icon-add"
      >
        {this.props.local.strings.insured.insuredList.addBtn}
      </a>
    );

    return component;
  }

  renderButtonEditInsured(insured) {
    let component = (
      <a
        onClick={() => this.editInsured(insured)}
        className="cell-table-link icon-edit"
      >
        {this.props.local.strings.insured.insuredList.editInsured}
      </a>
    );

    return component;
  }

  renderButtonArchiveInsured(e, insured) {
    const {archive, unArchive} = this.props.local.strings.insured.insuredList.archiveAction
    if (!insured.archive) {
      return <a
        onClick={(e) => this.archiveInsured(e, insured.Id, 1)}
        className="cell-table-link"
      >
        {unArchive}
      </a>
      } else {
      return <a
        onClick={(e) => this.archiveInsured(e, insured.Id, 0)}
        className="cell-table-link"
        style={{ color: '#F00' }}
      >
        {archive}
      </a>
    }
  }
  
  renderDefaultArchiveInsured(e, insured) {
    const {archive, unArchive} = this.props.local.strings.insured.insuredList.archiveAction;
    const component = (
      <span className={`status-cell ${(insured.archive) ? 'inactive' : 'active'}`}>
        {(!insured.archive) ? unArchive : archive}
      </span>
    );
    return component;
  }

  renderButtonViewInsured(insured) {    
    let holderId = insured && insured.HolderId ? insured.HolderId[0] :null;
    let holderName = insured && insured.HolderName ? encodeURIComponent(insured.HolderName[0]) : null;

    let component = (
      <Link
        to={{ 
          pathname: `/certfocus/insureds/${insured.Id}/${holderId}/${holderName}`,
          state: { fromInsuredList: true }
        }}
        className="cell-table-link icon-quick_view"
      >
        {this.props.local.strings.insured.insuredList.viewInsured}
      </Link>
    );
    return component;
  }

  archiveInsured = (e, insuredId, newArchivedStatus) => {
    const {title, text, description, titleText} = this.props.local.strings.insured.insuredList.archiveAlert

    const archivedTitle = (newArchivedStatus === 1) ? title.archive : title.unArchive;
    const archivedText = (newArchivedStatus === 1) ? text.archive : text.unArchive;

    Swal({
      title: titleText.replace('$1', archivedTitle),
      text: description.replace('$1', archivedText).replace('$2',insuredId) ,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2E5965',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        this.props.commonActions.setLoading(true);
        this.props.actions.archiveInsured(insuredId, newArchivedStatus, () => {
          this.props.commonActions.setLoading(false);
        });
      }
    });
  }

  render() {
    const {
      nameColumn,
      vendorColumn,
      projectColumn,
      holderColumn,
      stateColumn,
      viewProjects,
      viewHolders,
      filterBtn,
    } = this.props.local.strings.insured.insuredList;

    let {
      showModal,
    } = this.props.insureds;

    const getMuiTheme = () =>
      createMuiTheme({
        overrides: {
          MuiTable: {
            root: {
              position: 'relative',
              zIndex: '0'
            }
          },
          MuiGrid: {
            'spacing-xs-4': {
              '& > $item': {
                padding: 5
              }
            },
            root: {
              width: '100% !important',
              margin: '0 !important'
            }
          },
          MuiTableCell: {
            head: {
              color: '#085DAD',
              fontSize: 14,
              textTransform: 'uppercase',
              padding: 10,
              fontWeight: 700,
            },
          },
          MUIDataTableFilter: {
            root: {
              padding: '24px 8px 36px 8px'
            },
            gridListTile: {
              marginTop: 0,
              paddingTop: 4,
              paddingBottom: 4,
            },
          },
          MuiTypography: {
            h6: {
              color: '#2E5965',
              fontSize: 18,
              textTransform: 'uppercase',
              fontWeight: 700,
            }
          },
          MUIDataTableBodyCell: {
            root: {
              '& > a': {
                cursor: 'pointer',
                display: 'block',
                color: '#8CC739',
                position: 'relative',
                '&:hover': {
                  textDecoration: 'underline',
                },
                '& > svg': {
                  fontSize: 16,
                  marginRight: 5
                },
                '&:before': {
                  color: '#8CC739',
                  margin: '5px'
                }
              },

            }
          },
          MUIDataTableHeadCell: {
            data: {
              color: '#085dad !important',
              fontSize: '14px !important',
              textTransform: 'uppercase !important',
              fontWeight: 'bold'
            }
          }
        },
      });

    const columns = [
      {
        name: 'InsuredName',
        label: nameColumn,
        options: {}
      },
      {
        name: 'Vendor',
        label: vendorColumn,
        options: {}
      },
      {
        name: 'ProjectName',
        label: projectColumn,
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              (Array.isArray(value) && value.length > 1) ? (
                <a className='icon-quick_view cell-table-link'>
                  {viewProjects}
                  <QuickView column="project" row={value} idx={tableMeta.rowIndex}/>
                </a>
              ) : ((Array.isArray(value) && value[0]) || value)
            );
          }
        }
      },
      {
        name: 'HolderName',
        label: holderColumn,
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              (Array.isArray(value) && value.length > 1) ? (
                <a className='icon-quick_view cell-table-link'>
                  {viewHolders}
                  <QuickView column="holder" row={value} idx={tableMeta.rowIndex}/>
                </a>
              ) : ((Array.isArray(value) && value[0]) || value)
            );
          }
        },
      },
      {
        name: 'Tags',
        label: 'Tags',
        options: {
          display: false
        }
      },
      {
        name: 'Archive',
        label: 'Archive',
        options: {
          display: false,
          filterType: 'dropdown',
          filterOptions: {
            names: ['false', 'true']
          },
        }
      },
      {
        name: 'stateName',
        label: stateColumn,
        options: {
          filter: false,
          filterType: 'custom',
          customFilterListOptions: {
            render: v => v,
            update: (filterList, filterPos, index) => {
              filterList[index].splice(filterPos, 1);
              let filterObj = parseFilterObj(columns, filterList);
              this.fetchInsureds(1, this.state.pageSize, this.state.orderBy, this.state.orderDirection, filterObj)
              return filterList;
            }
          },
          filterOptions: {
            fullWidth: true,
            display: (filterList, onChange, index, column) => {
              const handleSubmit = (stateId, selectedStateLabel, countryId, countryLabel) => {
                if(stateId){
                  let filterObj = {
                    ...this.state.filterBox,
                    stateId: stateId,
                    stateLabel: selectedStateLabel,
                    countryId: countryId,
                    countryLabel: countryLabel
                  }
                  this.setState({filterBox: filterObj})
                }
              }
              return (
                <>
                  <CountryDropdown
                    handleSubmit={handleSubmit}
                    stateId={this.state.filterBox.stateId}
                    stateLabel={this.state.filterBox.stateLabel}
                    countryId={this.state.filterBox.countryId}
                    countryLabel={this.state.filterBox.countryLabel}
                  />
                </>
              );
            }
          }
        }
      },
      {
        name: 'View',
        label: 'View',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <RolAccess
                masterTab="insured"
                sectionTab="view_insured"
                component={() => this.renderButtonViewInsured(this.state.data[tableMeta.rowIndex])}>
              </RolAccess>
            );
          }
        }
      },
      {
        name: '',
        label: 'Edit',
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <RolAccess
                masterTab="insured"
                sectionTab="edit_insured"
                component={() => this.renderButtonEditInsured(this.state.data[tableMeta.rowIndex])}>
              </RolAccess>
            );
          }
        }
      },
      {
        name: '',
        label: 'Action',
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <RolAccess
                masterTab="insured"
                sectionTab="archive_insured"
                component={(e) => this.renderButtonArchiveInsured(e, this.state.data[tableMeta.rowIndex])}
                default={(e) => this.renderDefaultArchiveInsured(e, this.state.data[tableMeta.rowIndex])}>
              </RolAccess>
            );
          }
        }
      },
    ];

    let handleFilterTimeout = null;

    const applyFilter = applyNewFilters => {
      let filterList = applyNewFilters();
    }

    const parseFilterObj = (columns, filterList = []) => {
      const filterObj = {};
      columns.forEach((item, key) => {
        if(filterList[key].length > 0){
          const value = filterList[key][0];
          if(item.name === 'InsuredName'){
            filterObj.insuredName = value
          } else if(item.name === 'Vendor'){
            filterObj.vendor = value
          } else if(item.name === 'ProjectName'){
            filterObj.projectName = value
          } else if(item.name === 'HolderName'){
            filterObj.holderName = value
          } else if(item.name === 'Tags'){
            filterObj.tags = value
          } else if(item.name === 'Archive'){
            filterObj.archive = value === 'true' ? 1 : 0
          }else if(item.name === 'stateName'){
            filterObj.countryId = this.state.filterBox.countryId;
            filterObj.countryLabel = this.state.filterBox.countryLabel
            filterObj.stateId = this.state.filterBox.stateId
            filterObj.stateLabel = this.state.filterBox.stateLabel
          } else {
            filterObj[item.name] = filterList[key][0]
          }
        }
      })
      return filterObj;
    }

    const options = {
      download: true,
      filter: false,
      filterType: 'textField',
      selectableRowsHeader: false,
      selectableRowsHideCheckboxes: true,
      print: false,
      count: this.state.count,
      page: this.state.pageNumber - 1,
      responsive: 'standard',
      fixedHeader: true,
      tableBodyMaxHeight: '',
      jumpToPage: true,
      confirmFilters: true,
      serverSide: true,
      viewColumns: false,
      search: false,
      rowsPerPageOptions: [10, 25, 50, 100, 500],
      downloadOptions: {
        filename: 'insureds.csv',
        separator: ';',
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        }
      },
      setTableProps: () => {
        return {
          size: 'small',
        };
      },
      onColumnSortChange: (changedColumn, direction) => {
        this.fetchInsureds(this.state.pageNumber, this.state.pageSize, changedColumn, direction)
      },
      setFilterChipProps: (colIndex, colName, filterValue) => {
        return { label: `${columns[colIndex]['label'].toUpperCase()}: ${filterValue}` };
      },
      onFilterConfirm: (filterList) => {
        if(this.state.filterBox.stateId) {
          filterList[6][0] = this.state.filterBox.stateLabel
        }
        let filterObj = parseFilterObj(columns, filterList);

        this.fetchInsureds(1, this.state.pageSize, this.state.orderBy, this.state.orderDirection, filterObj)
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{ marginTop: '40px' }}>
            <Button variant="contained" onClick={() => applyFilter(applyNewFilters)}>Apply Filters</Button>
          </div>
        );
      },
      onFilterChange: (changedColumn, filterList, type) => {
        clearTimeout(handleFilterTimeout);
        handleFilterTimeout = setTimeout( () => {
          if(type === 'chip'){
            let filterObj = parseFilterObj(columns, filterList);
            this.fetchInsureds(1, this.state.pageSize, this.state.orderBy, this.state.orderDirection, filterObj)
          }
        },500);
      },

      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changePage':
            this.fetchInsureds(tableState.page + 1, tableState.rowsPerPage)
            break;
          case 'changeRowsPerPage':
            let maxPage = 0;
            if(this.state.count !== 0){
              maxPage = Math.ceil(this.state.count / tableState.rowsPerPage)
            }
            const pageNo = maxPage !== 0 && this.state.pageNumber > maxPage ? maxPage : this.state.pageNumber;
            this.fetchInsureds(pageNo, tableState.rowsPerPage)
            break;
          default:

        }
      }
    };

    return (
      <div className="list-view admin-view-body insureds-list">
        {(this.props.login && this.props.login.profile.length > 0) && <RolAccess
          masterTab="insured"
          sectionTab="list_insured"
          component={() => null}
          default={() => <Redirect push to="/profile" />}
        >
        </RolAccess>}
        <Modal
          show={showModal}
          onHide={this.closeModal}
          className="add-item-modal add-hc"
        >
          <Modal.Body className="add-item-modal-body mt-0">
            <AddInsuredModal
              profile={this.state.currentInsured}
              close={this.closeModalAndRefresh}
              onHide={this.closeModal}
            />
          </Modal.Body>
        </Modal>

        <div className="insureds-list-header">
          <div>
            <a
              onClick={() => this.setState({ showFilterBox: !this.state.showFilterBox })}
              className="nav-btn icon-login-door"
            >
              {filterBtn}
            </a>
          </div>

          <div>
            <RolAccess
              masterTab="insured"
              sectionTab="add_insured"
              component={() => this.renderButtonAddinsured()}>
            </RolAccess>
          </div>
        </div>

        {this.state.showFilterBox &&
          <section className="list-view-filters">
            <FilterInsureds
              onSubmit={this.submitFilterForm}
              pageNumber={this.state.pageNumber}
              pageSize={this.state.pageSize}
              orderBy={this.state.orderBy}
              orderDirection={this.state.orderDirection}
              fetchInsureds={this.fetchInsureds}
            />
          </section>
        }

        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title=""
            data={this.state.data}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </div>
    );
  }
};

const mapStateToProps = (state) => {

  return {
    insureds: state.insureds,
    local: state.localization,
    login: state.login
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Insureds);
