import * as regex from '../../../../../lib/regex';
import Utils from '../../../../../lib/utils';

const validate = (values, props) => {
  const errors = {};
  const {
    mustBeNumericValue,
    phoneErrorMessage,
    wrongEmailFormat,
    wrongTaxIdFormat,
    postalCodeErrorMessage,
    requiredValidation,
  } = props.local.strings.hiringClients.addHCModal.companyInfoTab;

  const validateFilled = [
    'insuredName', 
    'address1', 
    'city', 
    'state', 
    'postalCode', 
    'country',
    'contactName', 
    'countryId',
    'holderId',
    'taxId',
  ];
  validateFilled.forEach(name => {
    if (!values[name]) {
      errors[name] = requiredValidation;
    }
  });

  const checkNumber = [];
  checkNumber.forEach(name => {

    if (values.countryId !== 1 || values.countryId !== "1") {
      if (values[name] && isNaN(values[name])) {
        errors[name] = mustBeNumericValue;
      }
    } else if (values.countryId === 1 || values.countryId === "1") {
      // check tax id only when country is USA
      if (!values[name]) {
        errors[name] = requiredValidation;
      } 
    }
  });

  return errors;
};

export default validate;