import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import validate from './validation';
import renderSelect from '../../../../../../customInputs/renderSelect';
import Spinner from '../../../../../../common/spinner';

class FinancialInfoCopySubmissionModalForm extends Component {
  render() {
    const { handleSubmit, close, hiringClientsOptions } = this.props;
    const { copyFinancialSubmissionFetching, copyFinancialSubmissionError } = this.props.financials;

    const {
      hiringClientLabel,
      cancelBtn,
      copyBtn,
    } = this.props.local.strings.financialInfo.copySubmissionModal;

    return (
      <form onSubmit={handleSubmit} className="list-view-filter-form">
        <div className="sc-financial-info-copy-submission-modal-content container-fluid filter-fields">
          <div className="sc-financial-info-copy-submission-modal-container" >
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-sm-12 step-col-right">
                  <div className="register-field-wrapper">
                    <label htmlFor="copyHiringClientId" className="required">{hiringClientLabel}:</label>

                    <div className="select-wrapper">
                      <Field
                        name="copyHiringClientId"
                        component={renderSelect}
                        options={hiringClientsOptions}
                        onChange={this.handleHiringClientChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {copyFinancialSubmissionError && (
            <div className="text-center mb-2 text-danger">
              {copyFinancialSubmissionError}
            </div>
          )}

          {copyFinancialSubmissionFetching ? (
            <Spinner />
          ) : (
            <div className="sc-financial-info-copy-submission-modal-buttons-container text-right">
              <button className="bg-sky-blue-gradient bn" type="button" onClick={close}>
                {cancelBtn}
              </button>

              <button className="bg-sky-blue-gradient bn action-button" type="submit">
                {copyBtn}
              </button>
            </div>
          )}
        </div>
      </form>
    );
  }
}

FinancialInfoCopySubmissionModalForm = reduxForm({
  form: 'FinancialInfoCopySubmissionModalForm',
  validate,
  enableReinitialize: true,
})(FinancialInfoCopySubmissionModalForm);

const mapStateToProps = (state, ownProps) => ({
  local: state.localization,
  financials: state.financials,
  initialValues: {
    copyHiringClientId: ownProps.hiringClientsOptions[0].value,
  },
});

export default connect(mapStateToProps, null)(FinancialInfoCopySubmissionModalForm);
