import React from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {AsyncPaginate} from 'react-select-async-paginate';

import Tabs from './tabs';
import Utils from '../../lib/utils';
import loadOptions from './loadOptions/loadSiblingsHC';

import ChangeSubNameModal from './modals/changeSubName'
import ChangeVendorModal from './modals/changeVendor';
import ChangeStatusModal from './modals/changeStatus';
import ChangeTradesModal from './modals/changeTrades';
import ChangeTierModal from './modals/changeTier';
import ChangeBasicDataModal from './modals/changeBasicData';
import ChangeTaxIdModal from './modals/changeTaxIdModal';
import RolAccess from '../common/rolAccess';

import * as actions from './actions';
import * as scActions from '../subcontractors/actions';
import * as commonActions from '../common/actions';
import * as financialInfoActions from './tabs/financialInfo/actions/index'
import { setRedirectHcId } from '../payments/actions'
import {showQuickConfirmation, showInformationAlert} from '../alerts';
import './scprofile.css';
import RenderSelect from '../customInputs/renderSelect'
import swal from 'sweetalert2';
import { PQ_ADMIN_ROLE_ID, SELF_SERVICE_OPERATOR_ROLE_ID, SELF_SERVICE_ANALYST_ROLE_ID, SELF_SERVICE_OPERATOR_ROLE_NAME, SELF_SERVICE_ANALYST_TURNER_ROLE_NAME, SUBCONTRACTOR_ROLE_ID } from '../../lib/appConstants';


const SelectHiringClientSiblings = (props) => {
  const {local, subContractorId, hiringClientId, ...extra} = props;

  return (
    <AsyncPaginate
      {...extra}
      openMenuOnClick={true}
      closeMenuOnScroll={true}
      cacheOptions={true}
      pageSize={10}
      additional={{ page: 1 }}
      classNamePrefix="vk_move_sc"
      menuPlacement="auto"
      placeholder="Move"
      captureMenuScroll={true}
      closeMenuOnSelect={true}
      debounceTimeout={300}
      loadOptionsOnMenuOpen={true}
      placeholder={local.placeholder}
      noOptionsMessage={() => local.noOptionsMessage}
      loadingMessage={() => local.loadingMessage}
      loadOptions={loadOptions(subContractorId, hiringClientId)}
      blurInputOnSelect={true}
    />
  );
};

class SCProfile extends React.Component {
  constructor(props) {
    super(props);
    const { scId } = props.match.params;

    this.changeHiringClient = this.changeHiringClient.bind(this);
    this.setHiringClient = this.setHiringClient.bind(this)
    this.renderHeader = this.renderHeader.bind(this);
    this.renderSCHeader = this.renderSCHeader.bind(this);
    this.renderFullHeader = this.renderFullHeader.bind(this);
    this.handleCopyClipboard = this.handleCopyClipboard.bind(this);
    this.renderSubcontractorName = this.renderSubcontractorName.bind(this)
    this.renderDisabledNotice = this.renderDisabledNotice.bind(this)

    props.commonActions.setLastSubcontractor(scId);
    props.commonActions.setLastHiringClient(props.scProfile.hcId);
    props.scActions.fetchSCStatus();
    props.scActions.fetchSCTierRates();
    props.actions.getHiringClientIds(scId);

    if (props.scProfile.hcId && props.scProfile.hcId !== "") {
      const hcId = props.scProfile.hcId
      props.financialInfoActions.fetchSubmissionDates(hcId, scId);

      props.financialInfoActions.fetchPrequalDates(hcId, scId);
      props.actions.fetchHeaderDetails(scId, hcId, 'sc-profile constructor 1');
    } else if (!props.scProfile.hcId) {
      props.actions.fetchHeaderDetails(scId, null, 'sc-profile constructor 2');
    }

    this.state = {
      showScoreCardModal: false,
      showChangeStatusModal: false,
      showChangeTradesModal: false,
      showChangeLocationModal: false,
      showChangeVendorModal: false,
      showChangeTierModal: false,
      showChangeBasicDataModal: false,
      showChangeSubNameModal: false,
      showChangeTaxIdModal: false,
      hcListInited: false,
      scId,
      hasRegistrationLinkBeenCopied: false,
      displaySCname: '',
      displaySCstreet: '',
      displaySCaddress: '',
      displaySCphone: '',
      displaySCVendorId: '',
      displaySCTaxId: '',
      displayCountryId: '',
      displayHCid: Number(props.scProfile.hcId) || '',
      displaySCstatusId: '',
      displaySCstatus: '',
      displaySCtrade: '',
      displayTierRating: '',
      displaySPL: '',
      displayAPL: '',
      displaySCtype: '',
      displaySCprqDate: '',
      displaySClastModifiedDate : '',
      savedFormId: '',
      hasLandedOnRedirectedHC: false,
      redirectHCid: '',
      showChangeTradesModalForChangeHC: false,
      assignToHCId: null,
      assignToHCName: null,
      currentHcName: null,
      defaultTrades: {}
    };
  }

  componentDidMount() {
    const { hcId } = this.props.scProfile;
    const { scId } = this.state;

    if (hcId && scId) {
      this.props.actions.fetchHeaderDetails(scId, hcId, 'sc-profile componentDidMount');
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const { hcId } = this.props.scProfile
    const newHcId  = nextProps.scProfile.hcId

    if(newHcId && newHcId !== hcId){
      this.props.commonActions.setLastHiringClient(newHcId);

      this.props.financialInfoActions.fetchSubmissionDates(newHcId, this.state.scId);

      this.props.financialInfoActions.fetchPrequalDates(newHcId, this.state.scId);
    }

    // CLIPBOARD SUPPORT
    let oldCopy = this.state.hasRegistrationLinkBeenCopied
    let newCopy = nextState.hasRegistrationLinkBeenCopied
    if (newCopy && oldCopy !== newCopy) {
      showQuickConfirmation(
        {
          title: 'Registration Link Copied to Clipboard!',
          timer: 1000
        }
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    const newHcId              = Number(nextProps.scProfile.hcId)
    const oldHcId              = this.state.displayHCid
    const { scId }             = this.state;
    const oldPrimaryWasChanged = this.props.primaryWasChanged
    const newPrimaryWasChanged = nextProps.primaryWasChanged

    if ( newHcId && (newPrimaryWasChanged && newPrimaryWasChanged != oldPrimaryWasChanged)) {
      this.props.actions.fetchHeaderDetails(scId, newHcId, 'sc-profile componentWillRecieveProps');
      this.props.actions.setChangingPrimary(false);
      return;
    }

    const newFin     = nextProps.scProfile.headerDetails.FinIsComplete
    const oldFin     = this.props.scProfile.headerDetails.FinIsComplete
    const newSCname  = nextProps.scProfile.headerDetails.name
    const oldSCname  = this.props.scProfile.headerDetails.name
    const newSCaddr  = nextProps.scProfile.headerDetails.address
    const oldSCaddr  = this.props.scProfile.headerDetails.address
    const newSCcity  = nextProps.scProfile.headerDetails.city
    const oldSCcity  = this.props.scProfile.headerDetails.city
    const newSCstate = nextProps.scProfile.headerDetails.state
    const oldSCstate = this.props.scProfile.headerDetails.state
    const newSCzip   = nextProps.scProfile.headerDetails.zipCode
    const oldSCzip   = this.props.scProfile.headerDetails.zipCode
    const newSCphone = nextProps.scProfile.headerDetails.phone
    const oldSCphone = this.props.scProfile.headerDetails.phone
    const newSCVendorId = nextProps.scProfile.headerDetails.SourceSystemID
    const oldSCVendorId = this.props.scProfile.headerDetails.SourceSystemID
    const newSCstat  = nextProps.scProfile.headerDetails.subcontratorStatus
    const oldSCstat  = this.props.scProfile.headerDetails.subcontratorStatus
    const newStatId  = nextProps.scProfile.headerDetails.subcontratorStatusId
    const oldStatId  = this.props.scProfile.headerDetails.subcontratorStatusId
    const newSCtrade = nextProps.scProfile.headerDetails.trade
    const oldSCtrade = this.props.scProfile.headerDetails.trade
    const newSCrate  = nextProps.scProfile.headerDetails.tierRating
    const oldSCrate  = this.props.scProfile.headerDetails.tierRating
    const newSPL     = nextProps.scProfile.headerDetails.singleProjectLimit
    const oldSPL     = this.props.scProfile.headerDetails.singleProjectLimit
    const newAPL     = nextProps.scProfile.headerDetails.aggregateProjectExposure
    const oldAPL     = this.props.scProfile.headerDetails.aggregateProjectExposure
    const newSCtype  = nextProps.scProfile.headerDetails.companyType
    const oldSCtype  = this.props.scProfile.headerDetails.companyType
    const newPrqDate = nextProps.scProfile.headerDetails.dateOfPrequal
    const oldPrqDate = this.props.scProfile.headerDetails.dateOfPrequal
    const newTaxId   = nextProps.scProfile.headerDetails.taxId ||  nextProps.scProfile.headerDetails.TaxID
    const oldTaxId   = this.props.scProfile.headerDetails.taxId || this.props.scProfile.headerDetails.TaxID
    const newCountryId = nextProps.scProfile.headerDetails.CountryId
    const oldCountryId = this.props.scProfile.headerDetails.CountryId
    const newFetch   = nextProps.scProfile.fetchingHeader
    const oldFetch   = this.props.scProfile.fetchingHeader
    const newLastModifiedDate = nextProps.scProfile.headerDetails.dateLastModified
    const oldLastModifiedDate = this.props.scProfile.headerDetails.dateLastModified

    if ((newFin || newFin == null) && newFin != oldFin) {
      this.setState({
        FinIsComplete: newFin ? newFin : ''
      })
    }

    if ((newSCname || newSCname == null) && newSCname != oldSCname) {
      this.setState({
        displaySCname: newSCname ? newSCname : ''
      })
    }
    if ((newSCaddr || newSCaddr == null) && newSCaddr != oldSCaddr) {
      this.setState({
        displaySCstreet: newSCaddr ? newSCaddr : ''
      })
    }
    if ((newSCcity || newSCcity == null)  && newSCcity  != oldSCcity  &&
        (newSCstate || newSCstate == null) && newSCstate != oldSCstate &&
        (newSCzip || newSCzip == null)   && newSCzip   != oldSCzip) {
      this.setState({
        displaySCaddress: `${newSCcity ? newSCcity : ''}, ${newSCstate ? newSCstate : ''}, ${newSCzip ? newSCzip : ''}`
      })
    }
    if ((newSCphone || newSCphone == null) && newSCphone != oldSCphone) {
      this.setState({
        displaySCphone: Utils.formatPhoneNumber(newSCphone)
      })
    }
    if ((newSCVendorId || newSCVendorId == null) && newSCVendorId != oldSCVendorId) {
      this.setState({
        displaySCVendorId: newSCVendorId ? newSCVendorId : ''
      })
    }
    if ((newSCstat || newSCphone == null) && newSCstat != oldSCstat) {
      this.setState({
        displaySCstatus: newSCstat ? newSCstat : ''
      })
    }
    if ((newStatId || newStatId == null) && newStatId != oldStatId) {
      this.setState({
        displaySCstatusId: newStatId ? newStatId : ''
      })
    }
    if ((newSCtrade || newSCtrade == null) && newSCtrade != oldSCtrade) {
      this.setState({
        displaySCtrade: newSCtrade ? newSCtrade : ''
      })
    }
    if ((newSCrate || newSCrate == null) && newSCrate != oldSCrate) {
      this.setState({
        displayTierRating: newSCrate ? newSCrate : ''
      })
    }
    if ((newSPL || newSPL == null) && newSPL != oldSPL) {
      this.setState({
        displaySPL: newSPL ? this.getLimitValue(newSPL) : ''
      })
    }
    if ((newAPL || newAPL == null) && newAPL != oldAPL) {
      this.setState({
        displayAPL: newAPL ? this.getLimitValue(newAPL) : ''
      })
    }
    if ((newSCtype || newSCtype == null) && newSCtype != oldSCtype) {
      this.setState({
        displaySCtype: newSCtype ? newSCtype : ''
      })
    }
    if ((newPrqDate || newPrqDate == null) && newPrqDate != oldPrqDate) {
      this.setState({
        displaySCprqDate: newPrqDate ? new Date(newPrqDate).toLocaleString() : ''
      })
    }
    if ((newLastModifiedDate || newLastModifiedDate == null) && newLastModifiedDate != oldLastModifiedDate){
      this.setState({
        displaySClastModifiedDate : newLastModifiedDate ? new Date(newLastModifiedDate).toLocaleString() : ''
      })
    }
    if (newFetch == false && oldFetch !== newFetch) {
      this.setState({
        fetchingHeader: newFetch
      })
    }
    if ((newTaxId || newTaxId == null) && newTaxId !== oldTaxId) {
      this.setState({
        displaySCTaxId: newTaxId ? newTaxId : ''
      })
    }
    if ((newCountryId || newCountryId == null) && newCountryId !== oldCountryId) {
      this.setState({
        displayCountryId: newCountryId ? newCountryId : ''
      })
    }
    const newShouldResetHC =    nextProps.payments
                             && nextProps.payments.redirectHcId
                             ?  Number(nextProps.payments.redirectHcId)
                             :  false

    if (!this.state.hasLandedOnRedirectedHC && newShouldResetHC && newShouldResetHC != newHcId) {
      this.setState({
        redirectHCid: newShouldResetHC
      }, () => {
        this.setHiringClient(newShouldResetHC)
      })
    }  else {
      if (newHcId && newHcId != oldHcId) {
        this.setState({
          displayHCid: newHcId
        })
      }
    }

    // In case there's an error when moving the SC to another HC:
    if ((nextProps.scProfile.scMoveError !== this.props.scProfile.scMoveError) && 
      nextProps.scProfile.scMoveError) {
      showInformationAlert(
        'Error',
        nextProps.scProfile.scMoveError,
        'Accept',
        false,
        () => {}
      );
    }

    // Reload page after moving the SC to another HC:
    if ((nextProps.scProfile.isSCMoved !== this.props.scProfile.isSCMoved) && 
      nextProps.scProfile.isSCMoved) {
        if(nextProps.scProfile.hasTemplateDisconnect){
          swal({
            showConfirmButton: true,
            type: 'warning',
            text:"One or multiple files are assigned an email template that cannot be found in the target Hiring Client. Please check your files and select a new template once this record is moved to the target Hiring Client.",
            confirmButtonText: 'OK',
            confirmButtonColor: '#2E5965',
          })
            .then((result) => {
              if(result.value){
                window.location.reload();
              }
            })
        }else{
          window.location.reload();
        }
    }
  }

  componentWillUnmount() {
    this.props.actions.setHiringClientId("");
  }

  renderDisabledNotice(isEnabled) {
    const disabled = (
      <div className="row noTopMarPad rowHeight">
        <div className='col col-lg-12'>
          <p className="sc-financial-recommend notice">THIS SUBCONTRACTOR IS DISABLED</p>
        </div>
      </div>
    )
    let output = isEnabled == 0 ? disabled : null
    return output
  }

  handleCopyClipboard() {
    this.setState({
      hasRegistrationLinkBeenCopied: true
    })
  }

  openScoreCardModal = (e) => {
    this.setState({showScoreCardModal: true});
  }
  closeScoreCardModal = (e) => {
    this.setState({showScoreCardModal: false});
  }

  openChangeStatusModal = () => {
    this.setState({showChangeStatusModal: true});
  }

  openChangeTradesModal = () => {
    this.setState({ showChangeTradesModal: true });
  }

  openChangeSubNameModal = () => {
    this.setState({showChangeSubNameModal: true});
  }

  closeChangeSubNameModal = () => {
    this.setState({showChangeSubNameModal: false});
  }

  openChangeLocationModal = () => {
    this.setState({showChangeLocationModal: true});
  }

  closeChangeLocationModal = () => {
    this.setState({showChangeLocationModal: false});
  }

  openChangeVendorModal = () => {
    this.setState({showChangeVendorModal: true});
  }

  closeChangeVendorModal = () => {
    this.setState({showChangeVendorModal: false});
  }

  closeChangeStatusModal = () => {
    this.setState({showChangeStatusModal: false});
  }

  closeChangeTradesModal = () => {
    this.setState({ showChangeTradesModal: false });
  }

  openChangeTierModal = () => {
    this.setState({ showChangeTierModal: true });
  }

  closeChangeTierModal = () => {
    this.setState({ showChangeTierModal: false });
  }

  openChangeBasicDataModal = () => {
    this.setState({ showChangeBasicDataModal: true });
  }

  closeChangeBasicDataModal = () => {
    this.setState({ showChangeBasicDataModal: false });
  }

  openChangeTaxIdModal = () => {
    this.setState({ showChangeTaxIdModal: true });
  }

  closeChangeTaxIdModal = () => {
    this.setState({ showChangeTaxIdModal: false });
  }

  openChangeTradesModalForChangeHC = () => {
    this.setState({ showChangeTradesModalForChangeHC: true });
  }

  closeChangeTradesModalForChangeHC = () => {
    this.setState({ showChangeTradesModalForChangeHC: false });
  }

  openRegisterLink = () => {
    const link = _.get(this.props.scProfile, 'headerDetails.registrationUrl');

    if (link) {
      const win = window.open(link, '_blank');
      win.focus();
    }
  }

  getLimitValue(value) {
    let unFormattedValue = value ? value.replace('$', '') : 0

    return `$ ${Utils.formatNumberWithCommas(unFormattedValue)}`;
  }

  refreshHeader = (closeCallback) => {
    // this.props.actions.fetchHeaderDetails(scId, hcId, closeCallback);
    closeCallback()
    window.location.reload(); // TODO: We should try to avoid unnecesary reloads on the screen
  }

  onAddSubcontractor = (e) => {
    this.props.scActions.setAddSCShowModal(true);
    this.props.history.push('/subcontractors/');
  }

  setHiringClient(hcId) {
    this.setState({
      displayHCid: hcId,
      hasLandedOnRedirectedHC: true
    }, () => {
      this.props.actions.setHiringClientId(hcId);
      this.props.commonActions.setLastHiringClient(hcId);
      this.props.actions.fetchHeaderDetails(this.state.scId, hcId, 'sc-profile changeHiringClient');
      this.props.financialInfoActions.fetchPrequalDates(hcId, this.state.scId);
    })
  }

  changeHiringClient(event) {
    const hcId = event.target.value;

    const oldResetHcId =     this.props.payments
                          && this.props.payments.redirectHcId
                          ?  Number(this.props.payments.redirectHcId)
                          :  false



    if (oldResetHcId) {
      this.props.setRedirectHcId(null)
    }
    if (hcId !== "") {
      this.setHiringClient(hcId)
    }
  }

  handleOnAssignSubmit = (trades) => {
    this.setState({ showChangeTradesModalForChangeHC: false })
    const title = 'Information';
    const message = this.props.local.strings
      .scProfile
      .header
      .selectMoveToHiringClient
      .confirmationOnMove;
    showInformationAlert(title, message, 'Accept', true, (accepted) => {
      if (accepted) {
        this.props.actions.moveSubContractorToHiringClient({
          subContractorId: +this.props.match.params.scId,
          source: this.state.displayHCid,
          target: this.state.assignToHCId,
          trades: trades.trades
        });
      }
    });
  }

  renderHiringClient(labelHiringClient, displayHCid, redirectHCid) {
    const { hiringClientsList } = this.props.scProfile.headerDetails;
    const { multipleStrictHCs, RoleID } = this.props.login.profile

    const filterHiringClients = (subArray, userArray) => {
      const userSet = new Set(userArray)
      let output = []
      for (let i=0; i<subArray.length; i++) {
        const subHiringClientId = subArray[i].value
        if (userSet.has(subHiringClientId)) {
          output.push(subArray[i])
        }
      }
      return output
    }

    const reorderList = (redirectHCid, _hiringClientsList) => {
      let output = []

      for (let i=0; i<_hiringClientsList.length; i++) {
        let hcObj = _hiringClientsList[i]
        if (redirectHCid == hcObj.value) {
          output.push(hcObj)
        }
      }

      for (let i=0; i<_hiringClientsList.length; i++) {
        let hcObj = _hiringClientsList[i]
        if (redirectHCid != hcObj.value) {
          output.push(_hiringClientsList[i])
        }
      }

      return output
    }

    const replaceProps = (hiringClientsList) => {
      const output = []
      for (let i=0; i<hiringClientsList.length; i++) {
        let obj = hiringClientsList[i]
        let hcObj = {}
        hcObj.label = obj.name
        hcObj.value = obj.hiringClientId
        output.push(hcObj)
      }
      return output
    }

    const routeHCdisplayByRole = (RoleID, hiringClientsList, multipleStrictHCs) => {

      const canViewAssociatedHCs = Utils.canPerformFunction('view associated hiring clients', this.props.login.profile.rolesFunctionsPermissions);

      if (canViewAssociatedHCs) {
        const _hiringClientsList = filterHiringClients(hiringClientsList, multipleStrictHCs)
        return _hiringClientsList
      } else {
        // console.log('route 1,2,4,5,7 hiringClientsList = ', hiringClientsList)
        // const _hiringClientsList = redirectHCid ? reorderList(redirectHCid, hiringClientsList) : hiringClientsList
        const _hiringClientsList = redirectHCid ? reorderList(redirectHCid, hiringClientsList) : hiringClientsList
        return _hiringClientsList
      }
    }

    const onAssign = async (element) => {
      const { actions } = this.props;
      const { hiringClientsList, selectedTrades } = this.props.scProfile.headerDetails;
      await actions.fetchSCModalTradesList({ hiringClientId: element.value });
      let isTradesMismatched = false;

      const currentHC = await hiringClientsList.find(v => v.hiringClientId == displayHCid);
      const { tradesListForChangeModal } = this.props.scProfile;

      const defaultTrades = {
        "tradeId": {
          "id": 0,
          "value": "",
          "description": "",
          "orderIndex": 0
        },
        "secondaryTradeId": {
          "id": 0,
          "value": "",
          "description": "",
          "orderIndex": 0
        },
        "tertiaryTradeId": {
          "id": 0,
          "value": "0",
          "description": "",
          "orderIndex": 0
        },
        "quaternaryTradeId": {
          "id": 0,
          "value": "",
          "description": "",
          "orderIndex": 0
        },
        "quinaryTradeId": {
          "id": 0,
          "value": "",
          "description": "",
          "orderIndex": 0
        }
      };

      // Match selected trades with target HC
      Object.keys(selectedTrades).forEach((key) => {
        if(selectedTrades[key] && selectedTrades[key].id && selectedTrades[key].id !== 0){
          const tradeName = selectedTrades[key].description.trim();
          const isExist = tradesListForChangeModal.find((item) => item.label.trim() === tradeName)

          if(isExist) {
            defaultTrades[key].id = isExist.value
            defaultTrades[key].value = isExist.value
            defaultTrades[key].description = isExist.label
          }

          if(!isTradesMismatched && !isExist){
            isTradesMismatched = true
          }
        }
      })

      this.setState(
        {
          assignToHCId: element.value,
          assignToHCName: element.label,
          currentHcName: currentHC ? currentHC.name : '',
          defaultTrades: defaultTrades
        }
      )
      if(isTradesMismatched) {
        this.openChangeTradesModalForChangeHC()
      } else {
        const trades = {
          tradeId: defaultTrades.tradeId.id ? defaultTrades.tradeId.id : 0,
          secondaryTradeId: defaultTrades.secondaryTradeId.id ? defaultTrades.secondaryTradeId.id : 0,
          tertiaryTradeId: defaultTrades.tertiaryTradeId.id ? defaultTrades.tertiaryTradeId.id : 0,
          quaternaryTradeId: defaultTrades.quaternaryTradeId.id ? defaultTrades.quaternaryTradeId.id : 0,
          quinaryTradeId: defaultTrades.quinaryTradeId.id ? defaultTrades.quinaryTradeId.id : 0
        };
        const title = 'Information';
        const message = this.props.local.strings
          .scProfile
          .header
          .selectMoveToHiringClient
          .confirmationOnMove;
        showInformationAlert(title, message, 'Accept', true, (accepted) => {
          if (accepted) {
            this.props.actions.moveSubContractorToHiringClient({
              subContractorId: +this.props.match.params.scId,
              source: displayHCid,
              target: element.value,
              trades: trades
            });
          }
        });
      }

    };

    const _hiringClientsList = routeHCdisplayByRole(
      RoleID,
      replaceProps(hiringClientsList),
      multipleStrictHCs
    );

    const local = this.props.local.strings
      .scProfile
      .header
      .selectMoveToHiringClient;

    const onAssignToMenuIsOpen = (
      this.props.scProfile.isMoving || 
      this.props.scProfile.isMoved
    ) ? false : null;

    if (_hiringClientsList.length === 0) {
      this.props.history.push('/hiringclients');

      return (
        <div className="lineHeight vk_hc_sc_container">
          <span className="sc-financial-label labelPad vk_hc_label">
            {labelHiringClient}
          </span>
          <span className="sc-financial-value vk_hc_value">
            {"----"}
          </span>
          {this.props.scProfile.scHasHCSiblings && (
            <div className="vk_sc_move">
              <SelectHiringClientSiblings
                local={local}
                subContractorId={+this.props.match.params.scId}
                hiringClientId={displayHCid}
                onChange={onAssign}
                menuIsOpen={onAssignToMenuIsOpen}
              />
            </div>
          )}
        </div>
      );
    } else if (_hiringClientsList.length === 1) {

      return (
        <div className="lineHeight vk_hc_sc_container">
          <span className="sc-financial-label labelPad vk_hc_label">
            {labelHiringClient}
          </span>
          <span className="sc-financial-value vk_hc_value_single">
            {_hiringClientsList[0].label}
          </span>
          {this.props.scProfile.scHasHCSiblings && (
            <div className="vk_sc_move">
              <SelectHiringClientSiblings
                local={local}
                subContractorId={+this.props.match.params.scId}
                hiringClientId={displayHCid}
                onChange={onAssign}
                menuIsOpen={onAssignToMenuIsOpen}
              />
            </div>
          )}
        </div>
      );
    } else if (_hiringClientsList.length > 1) {
      return (
        <div className="changeHcSelect lineHeight row vk_hc_sc_container">
          {
            RoleID != SUBCONTRACTOR_ROLE_ID &&
              <span className="sc-financial-label labelPad pull-left vk_hc_label">
                {labelHiringClient}
              </span>
          }
          <span className="select-wrapper specialSelect vk_hc_value">
            <RenderSelect
              options={_hiringClientsList}
              className={`hcSelect ${RoleID == SUBCONTRACTOR_ROLE_ID && 'sc_role_dropdown'}`}
              input={{value: displayHCid, onChange: this.changeHiringClient}}
              meta={{touched: false, error: null, warning: null}}
            />
          </span>
          {this.props.scProfile.scHasHCSiblings && (
            <div className="vk_sc_move">
              <SelectHiringClientSiblings 
                local={local}
                subContractorId={+this.props.match.params.scId}
                hiringClientId={displayHCid}
                onChange={onAssign}
                menuIsOpen={onAssignToMenuIsOpen}
              />
            </div>
          )}
        </div>
      );
    }

    return 'NONE';
  }

  renderSubcontractorName(changeSubName, labelSubcontractor, displaySCname) {
    return (
      <div className="row no-marPad noTopBottomMarPad">
        <div className="col col-lg-12 pull-left no-marPad">
          <div className="sc-financial-value no-right-marPad">
            <div className="lineHeight d-flex sc-name">
              <span className="sc-financial-label labelPad">{labelSubcontractor}</span>
              <span className="sc-financial-value">
                {displaySCname || "----"}
                <RolAccess
                  masterTab="subcontractors"
                  sectionTab="update_subcontractor_name"
                  component={() => (
                    <span className="linear-icon-pencil-edit" onClick={this.openChangeSubNameModal}></span>
                  )}>
                </RolAccess>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSCVendorId(vendorIdLabel, changeVendorId) {
    const { Role } = this.props.login.profile
     const populateFromFormUsersList = [ SELF_SERVICE_OPERATOR_ROLE_NAME, SELF_SERVICE_ANALYST_TURNER_ROLE_NAME];
     let checkVendorEditButtonPermission;
     if (Role.Name) {
       checkVendorEditButtonPermission = populateFromFormUsersList.find(f => f === Role.Name)
     }
    const { hiringClientsList } = this.props.scProfile.headerDetails;
    const displaySCVendorId = this.state.displaySCVendorId;
    let component = null;
    if (!_.isEmpty(hiringClientsList)) {
      component = (
        <div className="row no-marPad noTopBottomMarPad">
          <div className="col col-lg-12 pull-left no-marPad">
            <div className="sc-financial-value no-right-marPad">
              <div className="lineHeight">
                <span className="sc-financial-label labelPad">{vendorIdLabel}</span>
                <span className="sc-financial-value">{displaySCVendorId  || "----"}</span>
                {
                changeVendorId && (
                  !checkVendorEditButtonPermission ? <RolAccess
                    masterTab="subcontractors"
                    sectionTab="modify_vendor_id"
                    component={() => (
                      <span className="linear-icon-pencil-edit" onClick={this.openChangeVendorModal} />
                    )}>
                  </RolAccess> : null
                )   
              }
              </div>
            </div>
          </div>
        </div>
      );
    }
    return component;
  }

  renderTaxId(labelTaxId) {
    const taxId = this.state.displaySCTaxId;
    const { RoleID } = this.props.login.profile;

    return (
      <RolAccess
        masterTab="subcontractors"
        sectionTab="view_tax_id"
        component={() => (
          <div className="row no-marPad noTopBottomMarPad">
          <div className="col col-lg-12 pull-left no-marPad">
            <div className="sc-financial-value no-right-marPad">
              <div className="lineHeight">
                <span className="sc-financial-label labelPad">{labelTaxId}</span>
                <span className="sc-financial-value">{taxId  || "----"}</span>
                <RolAccess
                  masterTab="subcontractors"
                  sectionTab="edit_tax_id"
                  component={() => (
                    <span className="linear-icon-pencil-edit" onClick={this.openChangeTaxIdModal}></span>
                  )}>
                </RolAccess>
              </div>
            </div>
          </div>
        </div>
        )}>
      </RolAccess>
    );
  }

  renderHeader () {
    let isSubcontractor = true;
    isSubcontractor = this.props.login.profile.RoleID == 4;
    const isEnabled = this.props.scProfile.headerDetails.enabled

    if(isSubcontractor) {
      return this.renderSCHeader(isEnabled);
    } else {
      return this.renderFullHeader(isEnabled);
    }
  }

  renderSCHeader(isEnabled) {
    // const { headerDetails } = this.props.scProfile;

    const {
      labelTrade,
      labelHiringClient,
      labelSubcontractor,
      labelSubAddress,
      changeSubName,
      labelPhone,
    } = this.props.local.strings.scProfile.header;

    // general info
    const {
      displaySCname,
      displaySCstreet,
      displaySCaddress,
      displaySCphone,
      displaySCtrade,
      displayHCid,
      redirectHCid
    } = this.state
    // const scName = displaySCname;
    // const scStreet = displaySCstreet;
    // const scAddress = displaySCaddress;
    // const scPhone = Utils.formatPhoneNumber(displaySCphone);
    // financial info
    // const scTrade = displaySCtrade;

    const leftColumn = `3`
    const centerLeftColumn = `4`
    const centerrightColumn = `4`
    const rightColumn = `1`
    const { RoleID } = this.props.login.profile;
    return (
      <div className="row sc-header-info">
        <div className="col col-lg-12 sc-financial-info no-marPad">
          {
            RoleID === SUBCONTRACTOR_ROLE_ID && (
              <div style={{height: '30px'}} className="row noTopBottomMarPad">
                <div className={`col col-lg-${leftColumn}`}/>
                <div className={`col col-lg-${centerLeftColumn}`}/>
                <div className={`sc-financial-label col col-lg-${centerrightColumn}`}>Select a Hiring Client</div>
                <div className={`col col-lg-${rightColumn}`}/>
              </div>
            ) 
          }
          <div className="row noTopBottomMarPad rowHeight">
            <div className={`col col-lg-${leftColumn}`}></div>

            <div className={`col col-lg-${centerLeftColumn}`}>
              {this.renderSubcontractorName(changeSubName, labelSubcontractor, displaySCname)}
            </div>

            <div className={`col col-lg-${centerrightColumn}`}>
              {this.renderHiringClient(labelHiringClient, displayHCid, redirectHCid)}
            </div>

            <div className={`col col-lg-${rightColumn}`}></div>
          </div>
          <div className="row noTopBottomMarPad rowHeight">
            <div className={`col col-lg-${leftColumn}`}></div>

            <div className={`col col-lg-${centerLeftColumn}`}>
              <div className="lineHeight">
                <span className="sc-financial-label labelPad">{labelSubAddress}</span>
                <span className="sc-financial-value">{displaySCstreet || "----"}</span>
              </div>
            </div>

            <div className={`col col-lg-${centerLeftColumn}`}>
              <div className="sc-financial-value lineHeight sc-header-main-trade">
                <span className="sc-financial-label labelPad">{labelTrade}</span>
                <span className="sc-financial-value">{displaySCtrade || "----"}</span>

                <RolAccess
                  masterTab="subcontractors"
                  sectionTab="modify_trades"
                  component={() => <span className="linear-icon-pencil-change" onClick={this.openChangeTradesModal} />}
                ></RolAccess>
              </div>
            </div>

            <div className={`col col-lg-${rightColumn}`}></div>
          </div>
          <div className="row noTopBottomMarPad rowHeight">
            <div className={`col col-lg-${leftColumn}`}></div>

            <div className={`col col-lg-${centerLeftColumn}`}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span className="sc-financial-value">{displaySCaddress}</span>
            </div>

            <div className={`col col-lg-${centerrightColumn}`}></div>
            <div className={`col col-lg-${rightColumn}`}></div>
          </div>
          <div className="row noTopMarPad rowHeight">
            <div className={`col col-lg-${leftColumn}`}></div>

            <div className={`col col-lg-${centerLeftColumn}`}>
              <div className="lineHeight">
                <span className="sc-financial-label labelPad">{labelPhone}</span>
                <span className="sc-financial-value">{displaySCphone || "----"}</span>
              </div>
            </div>

            <div className={`col col-lg-${centerrightColumn}`}></div>
            <div className={`col col-lg-${rightColumn}`}></div>
          </div>
          <div className="row noTopMarPad rowHeight">
            <div className={`col col-lg-${leftColumn}`}></div>

            <div className={`col col-lg-${centerrightColumn}`}></div>
            <div className={`col col-lg-${rightColumn}`}></div>
          </div>
          {this.renderDisabledNotice(isEnabled)}
        </div>
      </div>
    );
  }

  renderFullHeader(isEnabled) {
    const { headerDetails } = this.props.scProfile;
    const { labelTaxId } = this.props.local.strings.scProfile.header;

    const {
      displaySCname,
      displaySCstreet,
      displaySCaddress,
      displaySCphone,
      displaySCVendorId,
      displaySCstatus,
      displaySCtrade,
      displayTierRating,
      displaySPL,
      displayAPL,
      displaySCtype,
      displaySCprqDate,
      displaySClastModifiedDate,
      displayHCid,
      redirectHCid,
      FinIsComplete,
      displaySCstatusId
    } = this.state

    const {
      labelTrade,
      labelPrequalDate,
      labelStatus,
      labelTierRating,
      riskRecommend,
      labelSingleProjectLimit,
      labelAggregateProjectExposure,
      labelCorpType,
      viewRegistrationLinkBtn,
      changeSubName,
      changeBasicDataBtn,
      labelHiringClient,
      labelSubcontractor,
      labelSubAddress,
      labelPhone,
      labelVendorId,
      changeVendorId,
      dateLastModified
    } = this.props.local.strings.scProfile.header;

    /**
     * VE-6016
     * 'Self Service Operator' (RoleId 27) and 'Self-Service Analyst (Turner)' (RoleId 30) are not allowed to change subcontractor status
     * these self service roles are under IsPrequalRole = 1 flag, unlike HC and SC roles, restriction would be added using isChangeStatusRestricted flag
     * isChangeStatusRestricted flag would be used to restrict change status button for those roles
     */

    const { Id: RoleID } = this.props.login.profile.Role;
    let ChangeStatusRestrictedForRole = [SELF_SERVICE_OPERATOR_ROLE_ID, SELF_SERVICE_ANALYST_ROLE_ID];
    const isChangeStatusRestricted = ChangeStatusRestrictedForRole.includes(RoleID);

    let isPrequalRole = false;
    if (this.props.login.profile.Role) {
      isPrequalRole = this.props.login.profile.Role.IsPrequalRole;
    }

    const registrationUrl = _.get(this.props.scProfile, 'headerDetails.registrationUrl')

    const leftColumn = `4`
    const centerColumn = `4`
    const rightColumn = `4`

    return (
      <div className="row sc-header-info">

        <div className="col col-lg-12 sc-financial-info no-marPad">
          <div className='row noTopBottomMarPad'>

            <div className={`col col-lg-${leftColumn}`}>
              {
                this.renderSubcontractorName(changeSubName, labelSubcontractor, displaySCname)
              }
            </div>

            <div className={`col col-lg-${centerColumn}`}>
              {
                this.renderHiringClient(labelHiringClient, displayHCid, redirectHCid)
              }
            </div>

            <div className={`col col-lg-${rightColumn} text-left`}>
              <div className="row no-marPad noTopBottomMarPad sc-financial-value">
                <div className="col col-lg-12 no-marPad pull-left lineHeight">
                  <span className="sc-financial-label labelPad rightCol">{labelTierRating}</span>
                  <span className="sc-financial-value">{displayTierRating || "----"}</span>
                  {
                    FinIsComplete === 1
                    &&
                      <RolAccess
                        masterTab="subcontractors"
                        sectionTab="edit_tier_rating"
                        component={() => (
                          <span className="linear-icon-pencil-change" onClick={this.openChangeTierModal} />
                        )}>
                      </RolAccess>
                  }
                </div>
                {
                  (displayTierRating === 3 || displayTierRating === '3' || displayTierRating === '3-High Risk') && FinIsComplete === 1
                    ? <div className="sc-financial-recommend rightCol">{riskRecommend}</div>
                    : ''
                }
              </div>
            </div>

          </div>
          <div className='row noTopBottomMarPad rowHeight'>

            <div className={`col col-lg-${leftColumn}`}>
              <div className="lineHeight">
                <span className="sc-financial-label labelPad">{labelSubAddress}</span>
                <span className="sc-financial-value">{displaySCstreet || "----"}</span>
              </div>
            </div>

            <div className={`col col-lg-${centerColumn}`}>
              <div className="row no-marPad noTopBottomMarPad">
                <div className="col col-lg-12 pull-left no-marPad">
                  <div className="sc-financial-value lineHeight">
                    <span className="sc-financial-label labelPad">{labelStatus}</span>
                    <span className="sc-financial-value">{displaySCstatus || "----"}</span>
                    {
                      isPrequalRole && !isChangeStatusRestricted
                        ? <span className="linear-icon-pencil-change" onClick={this.openChangeStatusModal}></span>
                        :null
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className={`col col-lg-${rightColumn} text-left`}>
              <div className="sc-financial-value rightCol">
                <div className="lineHeight">
                  <span className="sc-financial-label labelPad">{labelSingleProjectLimit}</span>
                  <span className="sc-financial-value">{displaySPL || "----"}</span>
                </div>
              </div>
            </div>

          </div>
          <div className="row noTopBottomMarPad rowHeight">

            <div className={`col col-lg-${leftColumn}`}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span className="sc-financial-value">{displaySCaddress}</span>
            </div>

            <div className={`col col-lg-${centerColumn}`}>
              <div className="sc-financial-value lineHeight sc-header-main-trade">
                <span className="sc-financial-label labelPad">{labelTrade}</span>
                <span className="sc-financial-value">{displaySCtrade || "----"}</span>

                <RolAccess
                  masterTab="subcontractors"
                  sectionTab="modify_trades"
                  component={() => (
                    <span className="linear-icon-pencil-change" onClick={this.openChangeTradesModal} />
                  )}>
                </RolAccess>
              </div>
            </div>

            <div className={`col col-lg-${rightColumn} text-left`}>
              <div className="sc-financial-value lineHeight rightCol">
                <span className="sc-financial-label labelPad">{labelAggregateProjectExposure}</span>
                <span className="sc-financial-value">{displayAPL || "----"}</span>
              </div>
            </div>

          </div>
          <div className="row noTopBottomMarPad rowHeight">

            <div className={`col col-lg-${leftColumn}`}>
              <div className="lineHeight">
                <span className="sc-financial-label labelPad">{labelPhone}</span>
                <span className="sc-financial-value">{displaySCphone || "----"}</span>
              </div>
            </div>
            <div className={`col col-lg-${centerColumn}`}>
            </div>
            <div className={`col col-lg-${rightColumn} text-left`}>
              <div className="sc-financial-value lineHeight rightCol">
                <span className="sc-financial-label labelPad">{labelCorpType}</span>
                <span className="sc-financial-value">{displaySCtype || "----"}</span>
              </div>
            </div>

          </div>
          <div className="row noTopMarPad rowHeight">

            <div className={`col col-lg-${leftColumn}`}>
              {
                this.renderSCVendorId(labelVendorId, changeVendorId)
              }
            </div>

            <div className={`col col-lg-${centerColumn}`}>
            </div>

            <div className={`col col-lg-${rightColumn}`}>
              <div className="sc-financial-value lineHeight rightCol">
                <span className="sc-financial-label labelPad">{labelPrequalDate}</span>
                <span className="sc-financial-value">{displaySCprqDate || "----"}</span>

              </div>

            </div>

          </div>
          <div className="row noTopBottomMarPad rowHeight">
            <div className={`col col-lg-${leftColumn}`}>
              {
                this.renderTaxId(labelTaxId)
              }
            </div>
            <div className={`col col-lg-${centerColumn}`}>
            </div>
            <div className={`col col-lg-${rightColumn}`}>
              <div className="sc-financial-value lineHeight rightCol">
                <span className="sc-financial-label labelPad">{dateLastModified}</span>
                <span className="sc-financial-value">{displaySClastModifiedDate || "----"}</span>
              </div>
              

            </div>

          </div>
          { this.renderDisabledNotice(isEnabled) }
          {
            isPrequalRole && (displaySCstatusId === 2 || displaySCstatusId === 3 ) ?
              <div className="row noTopMarPad rowHeight">
                <div className={`col col-lg-${leftColumn}`}>
                  <button className="change-status-btn no-margin-left profileBtn" onClick={this.openChangeBasicDataModal}>
                    {changeBasicDataBtn}
                  </button>
                  <CopyToClipboard
                    onCopy={this.handleCopyClipboard}
                    text={process.env.REACT_APP_FRONTEND_URL + registrationUrl}
                  >
                    <button className="change-status-btn profileBtn">
                      {viewRegistrationLinkBtn}
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
              :
              null
          }
        </div>
      </div>
    );
  }

  render() {
    const { headerDetails } = this.props.scProfile;
    const {
      fetchingHeader,
      scId,
      displayHCid,
      displaySCname,
      displaySCstatusId,
      displayAPL,
      displaySPL,
      displaySCVendorId,
      displaySCTaxId,
      displayCountryId
    } = this.state;
    const { tierRating, selectedTrades } = headerDetails;

    let hasRole = false;
    if (this.props.login.profile.Role) {
      hasRole = true;
    }
    
    return (
      <div className="scprofile-container">
        <Modal
          show={this.state.showChangeBasicDataModal}
          className="add-item-modal noteEditorModal" >
          <Modal.Body className="add-item-modal-body">
            <ChangeBasicDataModal
              close={this.closeChangeBasicDataModal}
              successCallback={this.refreshHeader}
              hcId={displayHCid}
              scId={scId}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showChangeVendorModal}
          className="add-item-modal noteEditorModal" >
          <Modal.Body className="add-item-modal-body">
            <ChangeVendorModal
              close={this.closeChangeVendorModal}
              successCallback={this.refreshHeader}
              vendor={displaySCVendorId}
              hcId={displayHCid}
              scId={scId} />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showChangeStatusModal}
          className="add-item-modal noteEditorModal" >
          <Modal.Body className="add-item-modal-body">
            <ChangeStatusModal
              close={this.closeChangeStatusModal}
              successCallback={this.refreshHeader}
              currentStatus={displaySCstatusId}
              hcId={displayHCid}
              userId={this.props.login.userId}
              scId={scId} />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showChangeTradesModal}
          className="add-item-modal noteEditorModal"
          onHide={this.closeChangeTradesModal}
        >
          <Modal.Body className="add-item-modal-body">
            <ChangeTradesModal
              selectedTrades={selectedTrades}
              close={this.closeChangeTradesModal}
              successCallback={this.refreshHeader}
              hcId={displayHCid}
              scId={scId}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showChangeSubNameModal}
          className="add-item-modal noteEditorModal" >
          <Modal.Body className="add-item-modal-body">
            <ChangeSubNameModal
              close={this.closeChangeSubNameModal}
              successCallback={this.refreshHeader}
              subName={displaySCname}
              hcId={displayHCid}
              scId={scId}
              hiringClientList={this.props.scProfile.headerDetails.hiringClientsList}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showChangeTierModal}
          className="add-item-modal noteEditorModal" >
          <Modal.Body className="add-item-modal-body">
            <ChangeTierModal
              close={this.closeChangeTierModal}
              successCallback={this.refreshHeader}
              currentTier={tierRating}
              hcId={displayHCid}
              scId={scId}
              aggregateProjectExposure={displayAPL}
              singleProjectLimit={displaySPL} />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showChangeTaxIdModal}
          className="add-item-modal noteEditorModal" >
          <Modal.Body className="add-item-modal-body">
            <ChangeTaxIdModal
              close={this.closeChangeTaxIdModal}
              successCallback={this.refreshHeader}
              currentTaxId={displaySCTaxId}
              hcId={displayHCid}
              userId={this.props.login.userId}
              scId={scId}
              countryId={displayCountryId}/>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showChangeTradesModalForChangeHC}
          className="add-item-modal noteEditorModal"
          onHide={this.closeChangeTradesModalForChangeHC}
        >
          <Modal.Body className="add-item-modal-body">
            <ChangeTradesModal
              selectedTrades={this.state.defaultTrades}
              close={this.closeChangeTradesModalForChangeHC}
              successCallback={this.handleOnAssignSubmit}
              forChangeHC={true}
              hcId={this.state.assignToHCId}
              scId={scId}
              message={`Trades selected in ${this.state.currentHcName} don't match trades in ${this.state.assignToHCName}, Please update your trades.`}
            />
          </Modal.Body>
        </Modal>

        {this.props.scProfile.isSCMoving && (
          <div style={{position:'fixed', top: '0', left: '0', backgroundColor:'#80808087', width: '100%', height: '100%', zIndex: 10}}>
            <div className="spinner-wrapper" style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <div className="spinner" />
            </div>
          </div>
        )}

        {
          fetchingHeader ? (
            <div style={{ width:'100%', height:'200px', display: 'flex',
            alignItems: 'center', justifyContent: 'center' }}>
              <div className="spinner-wrapper">
                <div className="spinner"></div>
              </div>
            </div>
          ) : (
            <div>
              {
                headerDetails.name && hasRole?
                  this.renderHeader():null
              }
            </div>
          )
        }
        <Tabs status={headerDetails.subcontratorStatusId} headerDetails={headerDetails} scId={this.state.scId} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    scProfile: state.SCProfile,
    primaryWasChanged: state.SCProfile.primaryWasChanged,
    hc: state.hc,
    local: state.localization,
    login: state.login,
    payments: state.payments,
    countries: state.countries
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    scActions: bindActionCreators(scActions, dispatch),
    financialInfoActions: bindActionCreators(financialInfoActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    setRedirectHcId: bindActionCreators(setRedirectHcId, dispatch)
  }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SCProfile));
