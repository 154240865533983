import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm, change, touch} from 'redux-form';
import {connect} from 'react-redux';
import {AsyncPaginate} from 'react-select-async-paginate';

import renderField from '../../../../customInputs/renderField';
import validate from './validate';
import asyncValidate from './asyncValidate';
import loadOptions from './loadOptions';

const SelectUser = ({input, meta, ...props}) => (
  <React.Fragment>
    <AsyncPaginate
      {...input}
      {...props}
      isSearchable={true}
      openMenuOnClick={true}
      openMenuOnFocus={true}
      closeMenuOnScroll={true}
      cacheOptions={true}
      pageSize={10}
      additional={{ page: 1 }}
      classNamePrefix="vk"
      className="select-user"
      menuPlacement="auto"
      onFocus={(_) => {}}
      onBlur={() => {}}
      onChange={(newValue) => input.onChange(newValue)}
      captureMenuScroll={true}
      closeMenuOnSelect={true}
      escapeClearsValue={true}
      isClearable={true}
      loadOptionsOnMenuOpen={true}
    />
    {(!input.value || (meta.touched && meta.error)) && (
      <span 
        className="select-error-message field-validation-message"
        data-testid="select-error-message"
      >
        {meta.error}
      </span>
    )}
  </React.Fragment>
);

const CreateUserForm = (props) => {
  const ref = useRef(null);

  useEffect(() => ref.current.focus(), [ref]);

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="wiz-field admin-form-field-wrapper">
            <label htmlFor="firstName">
              {`${props.local.labelFirstName}:`}
            </label>
            <Field
              name="firstName"
              type="text"
              placeholder={'John'}
              component={renderField}
              getRef={ref}
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="wiz-field admin-form-field-wrapper">
            <label htmlFor="lastName">
              {`${props.local.labelLastName}:`}
            </label>
            <Field
              name="lastName"
              type="text"
              placeholder={'Does'}
              component={renderField}
            />
          </div>
        </div>
      </div>
  
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="wiz-field admin-form-field-wrapper">
            <label htmlFor="email">
              {`${props.local.labelEmail}:`}
            </label>
            <Field
              name="email"
              type="text"
              placeholder={'example@example.com'}
              component={renderField}
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="wiz-field admin-form-field-wrapper">
            <label htmlFor="phone">
              {`${props.local.labelPhone}:`}
            </label>
            <Field
              name="phone"
              type="text"
              placeholder={'+0 000 000 0000'}
              component={renderField}
            />
          </div>
        </div>
      </div>
  
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="wiz-field admin-form-field-wrapper">
            <label htmlFor="password">
              {`${props.local.labelPassword}:`}
            </label>
            <Field
              name="password"
              type="password"
              placeholder={'**************'}
              component={renderField}
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="wiz-field admin-form-field-wrapper">
            <label htmlFor="retypePassword">
              {`${props.local.labelRetypePassword}:`}
            </label>
            <Field
              name="retypePassword"
              type="password"
              placeholder={'**************'}
              component={renderField}
            />
          </div>
        </div>
      </div>
  
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="wiz-field admin-form-field-wrapper">
            <button 
              className="bn bg-green-dark-gradient bn-generate-pw" 
              data-testid="bn-generate-pw"
              onClick={props.onGeneratePassword}>
              {props.local.generatePasswordButton}
            </button>
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="wiz-field admin-form-field-wrapper">
            <label htmlFor="changeuponlogin">
            <Field
              id="changeuponlogin"
              className="changeuponlogin"
              name="changeuponlogin"
              component={renderField}
              type="checkbox"
            />
            <span className="check"/>
            <span className="label">
              {'Ask to change password after login'}
            </span>
          </label>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const ContactForm = (props) => {
  const local = props.local.strings.hiringClients.addHCModal.contactTab;
  const {tabCreate, tabChoose, cancel, finishButton} = local;
  const [tab, setTab] = useState('create');

  useEffect(() => {
    props.dispatch(change('ContactForm', 'tab', tab));
  }, [tab]);

  const setTabCreate = (event) => {
    event.preventDefault();
    setTab('create');
    props.dispatch(change('ContactForm', 'tab', 'create'));
  };

  const setTabChoose = (event) => {
    event.preventDefault();
    setTab('choose');
    props.dispatch(change('ContactForm', 'tab', 'choose'));
  };

  const generatePassword = (event) => {
    event.preventDefault();
    const pw = Math.random().toString(36).substr(2, 8);
    props.dispatch(change('ContactForm', 'password', pw));
    props.dispatch(change('ContactForm', 'retypePassword', pw));
    props.dispatch(change('ContactForm', 'changeuponlogin', true));
  };

  const getTableClassName = (tab, name) => {
    return `wiz-tab-link ${name} ${tab === name ? 'active' : ''}`;
  };

  const getTabContainerClassName = (tab, name) => {
    const prefix = `create-tab-container wiz-tab-container`;
    return `${prefix} ${tab === name ? 'active' : ''}`;
  };

  const getSubmitButtonProps = (props) => {
    const attrs = {
      className: 'wiz-continue-btn bg-sky-blue-gradient bn',
      disabled: false,
      'data-testid': 'submit-button'
    };

    if (props.isSubmittingHC || props.isSubmittedHC) {
      attrs.className += ' wiz-submitting-btn wiz-continue-btn-disabled';
      attrs.disabled = true;
    }

    return attrs;
  };

  const getSubmitButtonTitle = (props) => {
    const suffix = props.isSubmittingHC || props.isSubmittedHC ? '...' : '';
    return `${finishButton}${suffix}`;
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (props.valid && !props.asyncValidating && !props.isSubmittingHC) {
      props.onSubmit(props.currentForm.ContactForm.values);
      return;
    }

    if (tab === 'create') {
      props.dispatch(touch(
        'ContactForm',
        'firstName', 
        'lastName', 
        'email', 
        'phone',
        'password',
        'retypePassword',
      ));
    } else {
      props.dispatch(touch('ContactForm', 'hcUser'));
    }
  };

  return (
    <form 
      className="contact-info-form wiz-form"
      data-testid="contact-info-form"
      onSubmit={onSubmit} 
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <div 
              className="person-contact-tabs"
              data-testid="person-contact-tabs"
            >
              <a
                className={getTableClassName(tab, 'create')}
                onClick={setTabCreate}
                data-testid="tab-link-create"
              >
                {tabCreate}
              </a>
              <a
                className={getTableClassName(tab, 'choose')}
                onClick={setTabChoose}
                data-testid="tab-link-choose"
              >
                {tabChoose}
              </a>
            </div>
          </div>
          <div className="col-md-8 col-sm-12">
            {tab === 'create' && (
              <div className={getTabContainerClassName(tab, 'create')}>
                <CreateUserForm
                  local={local}
                  onGeneratePassword={generatePassword}
                />
              </div>
            )}
            {tab === 'choose' && (
              <div className={getTabContainerClassName(tab, 'choose')}>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="wiz-field admin-form-field-wrapper">
                      <label htmlFor="hcUser">
                        {`${local.chooseExistingHC}:`}
                      </label>
                      <div className="select-wrapper">
                        <Field
                          name="hcUser"
                          component={SelectUser}
                          placeholder={`--${local.existingHCPlaceholder}--`}
                          noOptionsMessage={() => local.chooseHCUserNoOptionsMessage}
                          loadingMessage={() => local.chooseHCUserLoadingMessage}
                          loadOptions={loadOptions(
                            props.dispatch,
                            props.local.strings.users.actions,
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="wiz-buttons">
        <a 
          className="wiz-cancel-button" 
          data-testid="cancel-button"
          onClick={props.onClose}
        >
          {cancel}
        </a>
        <button {...getSubmitButtonProps(props)}>
          {getSubmitButtonTitle(props)}
        </button>
      </div>
    </form>
  );
};

ContactForm.propTypes = {
  local: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmittingHC: PropTypes.bool.isRequired,
};

ContactForm.defaultProps = {
  onClose: () => {},
  dispatch: () => {},
  handleSubmit: () => {},
  valid: false,
  asyncValidating: false,
  local: {},
  isSubmittingHC: false,
};

const WithReduxForm = reduxForm({
  form: 'ContactForm',
  validate,
  asyncValidate,
  asyncBlurFields: ['email'],
  asyncChangeFields: ['email'],
  touchOnBlur: true,
  touchOnChange: true,
})(ContactForm);

const mapStateToProps = (state, ownProps) => ({
  local: state.localization,
  currentForm: state.form,
  isSubmittingHC: state.hc.isSubmittingHC,
  isSubmittedHC: state.hc.isSubmittedHC
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WithReduxForm);