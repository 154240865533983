// sets

export const SET_USERS_LIST_ERROR = 'SET_USERS_LIST_ERROR';
export const SET_USERS_LIST = 'SET_USERS_LIST';
export const SET_USERS_N_PQ_LIST = 'SET_USERS_N_PQ_LIST';
export const SET_HIRING_CLIENTS_OPTIONS = 'SET_HIRING_CLIENTS_OPTIONS';
export const SET_SUBCONTRACTOR_OPTIONS = 'SET_SUBCONTRACTOR_OPTIONS';
export const SET_POPOVER_HIRINGCLIENTS = 'SET_POPOVER_HIRINGCLIENTS';
export const SET_POPOVER_SUBCONTRACTORS = 'SET_POPOVER_SUBCONTRACTORS';
export const SET_POPOVER_LOGS = 'SET_POPOVER_LOGS';
export const SET_HIRING_CLIENTS_TAGS = 'SET_HIRING_CLIENTS_TAGS';
export const SET_SUBCONTRACTORS_TAGS = 'SET_SUBCONTRACTORS_TAGS';
export const SET_FETCHING_USERS = 'SET_FETCHING_USERS';
export const SET_FETCHING_POPOVER_HC = 'SET_FETCHING_POPOVER_HC';
export const SET_FETCHING_POPOVER_SC = 'SET_FETCHING_POPOVER_SC';
export const SET_FETCHING_POPOVER_LOGS = 'SET_FETCHING_POPOVER_LOGS';
export const SET_TOTAL_AMOUNT_OF_USERS = 'SET_TOTAL_AMOUNT_OF_USERS';
export const SET_LOG_USERS = 'SET_LOG_USERS';
export const SET_LOG_MODULES = 'SET_LOG_MODULES';
export const SET_LOGS = 'SET_LOGS';
export const SET_TOTAL_LOGS = 'SET_TOTAL_LOGS';
export const SET_FETCHING_LOGS = 'SET_FETCHING_LOGS';
export const SET_SHOW_OVERLAY_ADDUSER = 'SET_SHOW_OVERLAY_ADDUSER';
export const SET_CURRENT_EDITING_USER = 'SET_CURRENT_EDITING_USER';
export const SET_HCS_FOR_SC_PROFILE = 'SET_HCS_FOR_SC_PROFILE';
export const SET_ACTIVE_SUB_USERS = 'SET_ACTIVE_SUB_USERS';
export const SET_PAGE_SIZE = 'SET_USERS_PAGE_SIZE';
// add and delete

export const ADD_SUBCONTRACTOR_TAG = 'ADD_SUBCONTRACTOR_TAG';
export const ADD_HIRINGCLIENT_TAG = 'ADD_HIRINGCLIENT_TAG';
export const DELETE_SUBCONTRACTOR_TAG = 'DELETE_SUBCONTRACTOR_TAG';
export const DELETE_HIRINGCLIENT_TAG = 'DELETE_HIRINGCLIENT_TAG';

// FETCH
export const FETCH_EMAIL_FLAGS_SUCCESS = 'FETCH_EMAIL_FLAGS_SUCCESS';
export const FETCH_EMAIL_FLAGS_ERROR = 'FETCH_EMAIL_FLAGS_ERROR';

//True-False
export const IS_SC_ID_CHANGE_IN_EDITION = 'IS_SC_ID_CHANGE_IN_EDITION';
