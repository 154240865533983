import React, {useEffect} from 'react';
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import MailPanel from "./MailPanel";
import {Button, Grid} from "@material-ui/core";
import Swal from "sweetalert2";

import * as mailActions from '../mailComposer/actions'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import './multipleMail.css';

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const MultipleMail = (props) => {
  const [currentTab, setCurrentTab] = React.useState(0);
  const [tabs, setTabs] = React.useState([]);

  const handleSendEmail = (values) => {
    const currentMail = tabs[currentTab];

    if (currentMail) {
      currentMail.sent = true;
    }
  }

  const processRejectData = (rejectData) => {
    const {EmailInsured, insuredTemplateId, producerTemplateId, CertificateId, InsuredID, ProjectID} = rejectData.certificateData;
    const {certificatesList, holderId, holderName, insuredName, projectName} = rejectData;
    let tabList = [];

    const baseObj = {
      holderId: holderId,
      projectId: ProjectID,
      insuredId: InsuredID,
      sent: false,
      holderName,
      insuredName,
      projectName,
    }

    if (EmailInsured && insuredTemplateId) {

      let documentList = [];
      if (certificatesList.length) {
        certificatesList.map(cert => {
          documentList.push(cert.DocumentId);
        });
      }

      const item = Object.assign({}, baseObj, {
        label: 'Insured',
        certificateId: CertificateId,
        recipients: EmailInsured,
        templateId: insuredTemplateId,
        template: getTemplateData(insuredTemplateId),
        documents: documentList,
      });

      tabList.push(item);
    }

    if (certificatesList.length > 0 && producerTemplateId) {
      certificatesList.map(cert => {
        if ((cert.amountOfDeficiencies > 0)) {
          let recipients = null;
          if (cert.EmailProducerContact) {
            recipients = cert.EmailProducerContact;
          }

          if (recipients) {
            const item = Object.assign({}, baseObj, {
              label: `Cov: ${cert.Codes} - Cert: ${cert.CertificateID} - Doc: ${cert.DocumentId} \n ${recipients}`,
              certificateId: cert.CertificateID,
              recipients,
              templateId: producerTemplateId,
              template: getTemplateData(producerTemplateId),
              documents: [cert.DocumentId],
            })
            tabList.push(item);
          }
        }
      })
    }
    return tabList;
  }

  useEffect(() => {
    const loadedTabs = processRejectData(props.rejectData);
    if (loadedTabs.length > 0) {
      setTabs(loadedTabs);
    } else {
      props.onClose();
    }

  }, [])

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const getTemplateData = (id) => {
    const templates = props.templates.list;
    for (let i=0; i<templates.length; i++) {
      if (Number(id) === templates[i].id) {
        return templates[i]
      }
    }
  }

  const getMailData = (tab, currentTab, idx) => {
    const {locale: {mailForm}} = props;
    return <TabPanel key={`panel-${tab.label}`} value={currentTab} index={idx}>
      { ( (currentTab == idx))
        ? <MailPanel
          mailForm={mailForm}
          data={tab}
          onSendEmail={handleSendEmail}
          subject={tab.template.subject}
        />
        : null
      }
    </TabPanel>
  }

  const getMailToList = (mailList) => {
    let result = '<ul>';

    mailList.map( (data) => {
      // console.log('mail data: ', data);
      result += `<li>${data.template.subject} to ${data.recipients}</li>`
    })
    result += '</ul>';

    return result;
  }

  const handleExit = () => {
    const {onClose, locale: {sendDefaultMail}} = props;
    if (tabs.length > 0) {
      const mailsNotSent = tabs.filter( (data) => (data.sent === false && data.templateId))
      if (mailsNotSent.length > 0) {
        // const mailTo = getMailToList(mailsNotSent);
        // console.log('mailTo: ', mailTo);
        Swal({
          title: sendDefaultMail.title,
          html: sendDefaultMail.html,
          type: 'warning',
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: '#2E5965',
          cancelButtonColor: '#d33',
          confirmButtonText: sendDefaultMail.confirmButton,
          cancelButtonText: sendDefaultMail.cancelButton,
        }).then((result) => {
          if (result.value) {
            mailsNotSent.map( (data) => {
              const payload = {
                system: "cf",
                taskDetail: "",
                dueDate: "",
                hiringClientId: data.holderId,
                projectId: data.projectId,
                subcontractorId: data.insuredId,
                emailsList: data.recipients,
                subject: data.template.subject,
                body: data.template.bodyHTML,
                templateId: data.templateId,
                documents: data.documents,
                rejectEmail: true
              }
              props.mailActions.sendMail(payload);
            })
            if (onClose) onClose();
          } else {
            if (result.dismiss === 'cancel' && onClose) {
              onClose();
            }
          }
        });
      } else {
        if (onClose) onClose();
      }
    }

  }

  return (
    <div>
      <AppBar position={"static"}>
        <Grid container alignItems={"center"}>
          <Grid item xs={11}>
            <Tabs
              indicatorColor="secondary"
              value={currentTab}
              onChange={handleChangeTab}
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabs.map((tab, idx) => {
                return <Tab key={`tab-${tab.label}`} label={`${tab.label}`} {...a11yProps({idx})}></Tab>
              })
              }
            </Tabs>
          </Grid>
          <Grid item style={{margin: "auto"}}>
            <Button onClick={handleExit} color={"secondary"} variant={"contained"} disableElevation>
              EXIT
            </Button>
          </Grid>
        </Grid>
      </AppBar>
      {tabs.map((tab, idx) => {
        return getMailData(tab, currentTab, idx);
      })
      }
    </div>
  );
};

const mapStatToProps = (state) => {
  return {
    templates: state.templates,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    mailActions: bindActionCreators(mailActions, dispatch),
  }
}

export default connect(mapStatToProps, mapDispatchToProps)(MultipleMail);