import Api from '../../../../lib/api';

export const getImports = async (authToken) => {
  const urlQuery = `cf/ambest/imports`;
  try {
    return await Api.get(urlQuery, authToken);
  } catch (e) {
    console.error('Could not fetch AM Best imports');
  }
}

export const uploadFile = async (payload, authToken) => {
  const urlQuery = `cf/ambest/imports`;
  const formData = new FormData();
  formData.append('document', payload.files[0]);

  try {
    return await Api.post(urlQuery, formData, authToken);
  } catch (e) {
    console.error('Could not upload AM Best file');
  }
}