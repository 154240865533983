import React, { useState } from 'react';
import Utils from '../../../../../../lib/utils';

export default ({ form, userHiringClients, onInputChange, disableHcDropdown }) => {

  const {
    hiringClientId,
    subcontractorFee,
    description,
  } = form;

  return (
    <div style={{ marginBottom: '3%' }} className="builder">
      <div className="form">
        <div className="formHeader">
          <div className="headerContainer">
            <div className="item fieldContainer">
              <span className="italic item2">Hiring Client * </span>
              <select
                disabled={disableHcDropdown}
                style={{ marginBottom: '2%' }} 
                className="item2" 
                value={hiringClientId} 
                onChange={(e) => onInputChange('hiringClientId', e.target.value)}
               >
                <option value="">-- Select a Hiring Client --</option>
                {
                  userHiringClients.map((item) => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))
                }
              </select>

              <span className="italic item2">Subcontractor Fee </span>
              <input style={{ marginBottom: '2%' }} type="text" value={subcontractorFee} onChange={(e) => onInputChange('subcontractorFee', e.target.value)} className="formTitle item2" />

              <span className="italic">Form description </span>
              <textarea value={description} onChange={(e) => onInputChange('description', e.target.value)} className="description" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
