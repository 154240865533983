export const SET_TASKS_ERROR = 'SET_TASKS_ERROR';
export const SET_SAVING_TASK = 'SET_SAVING_TASK';
export const SET_FETCHING_TASKS = 'SET_FETCHING_TASKS';
export const SET_TOTAL_AMOUNT_OF_TASKS = 'SET_TOTAL_AMOUNT_OF_TASKS';
export const SET_TASK_TYPES_POSSIBLE_VALUES = 'SET_TASK_TYPES_POSSIBLE_VALUES';
export const SET_TASK_PRIORITY_POSSIBLE_VALUES = 'SET_TASK_PRIORITY_POSSIBLE_VALUES';
export const SET_TASKS_LIST = 'SET_TASKS_LIST';
export const SET_TASK_USERS_LIST = 'SET_TASK_USERS_LIST';
export const SET_ROLES_POSSIBLE_VALUES = 'SET_ROLES_POSSIBLE_VALUES';
export const SET_CONTACTS_TYPES_POSSIBLE_VALUES = 'SET_CONTACTS_TYPES_POSSIBLE_VALUES';
export const SET_STATUS_POSSIBLE_VALUES = 'SET_STATUS_POSSIBLE_VALUES';
export const SET_CREATED_BY_POSSIBLE_VALUES = 'SET_CREATED_BY_POSSIBLE_VALUES';
export const SET_SHOW_FILTER_BOX = 'SET_SHOW_FILTER_BOX';
export const SET_HAS_SAVED_FILTERS = 'SET_HAS_SAVED_FILTERS';
export const SET_PAGE_SIZE = 'SET_TASKS_PAGE_SIZE';
