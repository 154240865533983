import React from 'react';
import PropTypes from 'prop-types';
import Utils from '../../../../lib/utils';
import { MuiThemeProvider } from '@material-ui/core/styles';
import vertikalTheme from '../../../../lib/theme';
import MUIDataTable from 'mui-datatables';
import { Button } from '@material-ui/core';

const InsurersSuggestions = props => {
  const { suggestions, handleEnableNewInsurer, handleInsurer } = props;
  const columns = [
    {
      name: 'AMBestCompanyNumber',
      label: 'AMbestNumber',
      options: {
        display: 'excluded',
        // filter: false,
      }
    },
    { name: 'NAICCompanyNumber', label: 'NAIC #' },
    { name: 'CompanyName', label: 'Insurer' },
    {
      name: 'AMBestRatingAlpha',
      label: 'Ra.',
      options: {
        // filterType: 'dropdown'
      }
    },
    {
      name: 'AMBestFinancialAlpha',
      label: 'Fi.',
      options: {
        // filterType: 'dropdown'
      }
    },
  ];
  let options = Utils.MUITableDefaultConfig(columns);

  options = {
    ...options,
    viewColumns: false,
    filter: false,
    onRowClick: (rowData, rowMeta) => {
      const data = {
        AMBestNumber: rowData[0],
        NAIC: rowData[1],
        Name: rowData[2],
        rating: rowData[3],
        financial: rowData[4],
      }
      handleInsurer(data);
    },
  }

  return (
    <MuiThemeProvider theme={vertikalTheme.getMuiTheme()}>
      <MUIDataTable
        title={'AM Best Companies suggestions'}
        data={suggestions}
        columns={columns}
        options={options}
      />
      <a
        className="cancel-add-item"
        onClick={handleEnableNewInsurer} >
        New Insurer
      </a>
    </MuiThemeProvider>
  );
};

InsurersSuggestions.propTypes = {
  suggestions: PropTypes.array.isRequired,
};

export default InsurersSuggestions;