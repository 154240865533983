/* eslint-disable */
import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import moment from 'moment';
import html2pdf from 'html2pdf.js';
import Api from '../../../lib/api';

import Utils from '../../../lib/utils';
import RequirementSetsList from './requirement-sets-list';
import AttachmentsList from './attachments-list';
import EndorsementsList from './endorsements-list';

import DocumentList from './document-list';
import DocumentUploadForm from './document-upload-form';
import DropIn from "braintree-web-drop-in-react";

import * as certUploadActions from './actions';
import './cert-upload.css';
import { PENDING_PROCESSING_DOCUMENT_STATUS_ID, MAGELLAN_QUEUE_ID, SENT_TO_PROCESSING_DOCUMENT_STATUS_ID } from '../../../lib/appConstants';
import ProjectAIWording from "./project-ai-wording";
import FullScreenReCAPTCHA from '../../common/recaptcha/full-screen-recaptcha'

const SHOW_RECAPTCHA = process.env.REACT_APP_SHOW_RECAPTCHA === 'true';

class CertUpload extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      certUploadProfile: {},
      enablePage: false,
      invalid: false,
      invalidMessage: 'Invalid hash',
      showAllRequirements: false,
      archived: false,
      exempted: false,
      userData: {},
      fee: null,
      dropIn: null,
      waitingBraintree: false,
      allRequirements: [],
      showReCAPTCHA: SHOW_RECAPTCHA,
    }
  }

  componentDidMount () {
    this.validate();
  }

  validate () {
    const { hash, userHash } = this.props.match.params;
    let initialFee = null;
    let additionalFee = null;
    this.props.certUploadActions.validateHash(hash, userHash,  async (success, response, user) => {
      if (success) {
        this.setState({
          enablePage: true,
          certUploadProfile: response,
          userData: user
        });

        this.props.certUploadActions.fetchRequirementSets({
          projectInsuredId: response.projectInsuredId,
          requirementSetId: response.reqSetId,
          holderId: response.holderId,
          filterByNonCompliantItems: true,
        });
        // to generate pdf
        this.fetchAllRequirements();

        const {holderInitialFee, holderAdditionalFee,  parentInitialFee, parentAdditionalFee} = response;

        if (holderInitialFee) initialFee = holderInitialFee;
        if (!holderInitialFee && parentInitialFee) initialFee = parentInitialFee;

        if (holderAdditionalFee) additionalFee = holderAdditionalFee;
        if (!holderAdditionalFee && parentAdditionalFee) additionalFee = parentAdditionalFee;

        //console.log('initialfee: ', initialFee);
        //console.log('additionalFee: ', additionalFee);

        this.props.certUploadActions.fetchDocuments({projectInsuredId: response.projectInsuredId})
          .then(async response => {
            let uploadFee = null
            this.setState({fee: null});
            if (response.length == 0) {
              if (initialFee) uploadFee = initialFee;
            } else {
              if (additionalFee) uploadFee = additionalFee;
            }

            if (uploadFee) {
              this.setState({
                userData: user,
                waitingBraintree: true,
                fee: uploadFee
              });
              const token = await this.props.certUploadActions.getCustomerId(user)
              if (token) {
                this.setState({
                  waitingBraintree: false
                });
              }
            }
            this.forceUpdate();

          })
          .catch(error => {
              throw Error(error.message);
          })
        
        this.setState({ enablePage: true, certUploadProfile: response }, () => {
          this.checkIfArchivedOrExempted(response.projectInsuredId);
        });          
      } else {
        this.setState({ invalid: true, invalidMessage: response })
      }
    });
  }

  checkIfArchivedOrExempted (projectInsuredId) {    
    this.props.certUploadActions.checkIfArchivedOrExempted({ projectInsuredId: projectInsuredId }, (success, isArchived, isExempted) => {
      if (isArchived) {
        this.setState({ archived: true });
      }
      if (isExempted) {
        this.setState({ exempted: true });
      }
    });
  }

  handleFormSubmit = async (values) => {
   //console.log('Submit!', values, this.state.certUploadProfile);
    let paymentParams ={};
    const documentStatusId = this.state.certUploadProfile.AllowDocumentAutoProcess ? SENT_TO_PROCESSING_DOCUMENT_STATUS_ID : PENDING_PROCESSING_DOCUMENT_STATUS_ID;
    const serializedObj = {
      ...values,
      hiringClientId: this.state.certUploadProfile.holderId,
      projectId: this.state.certUploadProfile.projectId,
      subcontractorId: this.state.certUploadProfile.insuredId,
      projectInsuredId: this.state.certUploadProfile.projectInsuredId,
      documentStatusId, // Sent To Processing OR Pending Processing
      queueId: MAGELLAN_QUEUE_ID, // Magellan Queue
      allowDocumentAutoProcess: this.state.certUploadProfile.AllowDocumentAutoProcess,
      allowUrlDocumentUploads: this.state.certUploadProfile.AllowURLDocumentUploads
    };
    const {dropIn} = this.state;
    if (dropIn) {
      const {nonce} = await dropIn.requestPaymentMethod();
      paymentParams = {
        customer: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone? values.phone : '',
          company: this.state.certUploadProfile.insuredName,
        },
        payment_method_nonce: nonce,
        insuredId: this.state.certUploadProfile.insuredId,
        insuredName: this.state.certUploadProfile.insuredName,
        sourceSystemId: this.state.certUploadProfile.projectNumber,
        fee: this.state.fee,
        holderId: this.state.certUploadProfile.holderId,
        holderName: this.state.certUploadProfile.holderName,
      }
    }

    this.props.certUploadActions.uploadFiles(serializedObj, async (success, data) => {
      if (success) {
        if (dropIn) {
          await this.props.certUploadActions.pay(paymentParams);
        }

        if(this.state.certUploadProfile.AllowDocumentAutoProcess){
          // send document to IDP is AllowDocumentAutoProcess is true
          this.sendDocumentToIDP(data)
        }
        Swal({
          type: 'success',
          title: 'Cert Upload',
          text: 'Upload was successful.',
          onClose: () => {
            window.location.reload();
            // this.props.certUploadActions.fetchDocuments({projectInsuredId: serializedObj.projectInsuredId});
          }
        });
      } else {
        Swal({
          type: 'error',
          title: 'Cert Upload',
          text: 'Error uploading files. Please try again.',
        });
      }
    });
  };

  sendDocumentToIDP = (document) => {
    if(process.env.REACT_APP_ALLOW_DOCUMENT_AUTO_PROCESS === 'true') {
      let reqSetId = this.state.certUploadProfile.reqSetId;
      const body = {
        document_url: document.documentUrl ? document.documentUrl : '',
        holder_id: this.state.certUploadProfile.holderId,
        project_id: this.state.certUploadProfile.projectId,
        project_insured_id: this.state.certUploadProfile.projectInsuredId,
        requirements_set_id: reqSetId.length > 0 ? reqSetId[0] : '',
        document_id: document.documentId,
        certificate_id: document.certificateId ? document.certificateId : '',
        hash: this.props.match.params.hash
      }
      Api.post(`cf/certUpload/send-to-idp`, body);
    }
  }

  renderDownloadableRequirements = (requirementSetsList, endorsementsList) => {
    const { conditionPossibleValues } = this.props.holderRequirementSets;
    const { certUploadProfile } = this.state;
    const {
      downloadRequirementTitle,
      insuredNameLabel,
      holderNameLabel,
      projectNameLabel,
      projectNumberLabel,
      requirementsTitle,
      endorsementsTitle,
      noEndorsements,
    } = this.props.locale.download;

    const requirementSets = Utils.groupBy(requirementSetsList, 'ruleGroupName');
    
    return (
      <div className="requirement-sets-to-pdf container-fluid">
        <div><h5 className="">{downloadRequirementTitle}</h5></div>
        <div className="card" style={{ backgroundColor: 'white' }}>
          <div className="card-block" style={{ backgroundColor: 'white' }}>
            <div className="row">
              <div className="col-6">
                <p><strong>{holderNameLabel}:</strong>&nbsp; {certUploadProfile.holderName}</p>
                <p><strong>{insuredNameLabel}:</strong>&nbsp; {certUploadProfile.insuredName}</p>
              </div>
              <div className="col-6">
                <p><strong>{projectNameLabel}:</strong>&nbsp; {certUploadProfile.projectName}</p>
                <p><strong>{projectNumberLabel}:</strong>&nbsp; {certUploadProfile.projectNumber}</p>
              </div>
            </div>
          </div>          
        </div>

        <div className="row mt-1">
          <div className="col-12 requirements-groups">            
          <div><h5>{requirementsTitle}</h5></div>
          { Object.keys(requirementSets).map((requirementSetGroup, index) => {            
            return (
              <div key={requirementSetGroup}>
                <div className="card" style={{ backgroundColor: 'white' }}>
                  <div className="card-block" style={{ backgroundColor: 'white' }}>
                    <div className="row d-flex align-items-center">
                      <div className="col-md-12">
                        <h6 className="mb-0">{requirementSetGroup}</h6>
                      </div>
                    </div>                    
                  { requirementSets[requirementSetGroup].map(requirement => {
                    const condition = conditionPossibleValues.find(el => el.value === requirement.conditionTypeId);
                    
                    return (
                      <div className="row align-items-center" key={requirement.ruleId}>                     
                        <div className={`col-md-3`}>
                          {requirement.attributeName}
                        </div>
                        <div className="col-md-1 requirement-condition">
                          {condition.label.slice(0, 2)}
                        </div>
                        <div className={`col-md-2`}>
                          {((Number(requirement.conditionTypeId) > 3) && (Number(requirement.conditionTypeId) < 8))
                            ? Utils.formatCurrency(requirement.conditionValue) 
                            : requirement.conditionValue
                          }
                        </div>
                        <div className="col-md-4">
                          {requirement.deficiencyText}
                        </div>                     
                      </div>
                    )}
                  )}
                  </div>
                </div>
              </div>  
            )}
          )}    
          </div>
        </div>   

        <div className="row mt-1">
          <div className="col-12 requirements-groups">            
          <div><h5 className="mb-0 mr-3">{endorsementsTitle}</h5></div>
          { (endorsementsList.length === 0) 
            ? <div>{noEndorsements}</div> 
            : endorsementsList.map(endorsement => (
              <div key={endorsement.Id}>
                <div className="card" style={{ backgroundColor: 'white' }}>
                  <div className="card-block" style={{ backgroundColor: 'white' }}>
                    <div className="row d-flex align-items-center">
                      <div className="col-md-11">{endorsement.Name}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>  
      </div>
    )
  }

  fetchAllRequirements = () => {
    const { certUploadProfile } = this.state;

    this.props.certUploadActions.fetchOnlyRequirements({
      projectInsuredId: certUploadProfile.projectInsuredId,
      requirementSetId: certUploadProfile.reqSetId,
      holderId: certUploadProfile.holderId,
    }, (error, data) => {
        const allRequirements = Array.isArray(data) 
        ? data.map((requirement) => {
            return {
              ruleGroupName: requirement.CoverageType,
              ruleId: requirement.RuleID,
              attributeName: requirement.AttributeName,
              conditionTypeId: requirement.ConditionTypeID,
              conditionValue: requirement.ConditionValue,
              deficiencyText: requirement.DeficiencyText,
              deficiencyTypeId: requirement.DeficiencyTypeID,
              status: (requirement.AttributeStatus !== 'Waived') ? requirement.AttributeStatus : ''
            }
          })
        : [];
      this.setState({ allRequirements: allRequirements });
    });
  }  

  downloadRequirements = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setTimeout(() => {
      const input = document.getElementById('divToPrint');
      const pdfOptions = {
        margin:       [0,0],
        filename:     'myfile.pdf',
        image:        { type: 'png' },
        html2canvas:  { scale: 1 },
        jsPDF:        { unit: 'in', orientation: 'portrait', format: 'a4' },
      };
      html2pdf().set(pdfOptions).from(input).save();
    }, 200)
  }

  fetchRequirementSets = () => {
    const { showAllRequirements, certUploadProfile } = this.state;
    const fetchParams = {
      projectInsuredId: certUploadProfile.projectInsuredId,
      requirementSetId: certUploadProfile.reqSetId,
      holderId: certUploadProfile.holderId,
      ...(!!showAllRequirements) && { filterByNonCompliantItems: true },
    };
    this.props.certUploadActions.fetchRequirementSets({ ...fetchParams });
  }

  onReCAPTCHAVerify = () => {
    this.setState({
      showReCAPTCHA: false
    })
  }

  render () {
    const { 
      certUploadProfile,
      invalid,
      archived,
      exempted,
      redirect,
      allRequirements,
    } = this.state;
        
    if (invalid || archived || exempted) {      
      let errMessage = `Invalid hash. Please try again.`;      
      if (archived) errMessage = `This Project Insured is no longer active.`;
      if (exempted) errMessage = `This Project Insured is exempted.`;
      if (!Swal.isVisible()) {
        Swal({
          type: 'error',
          title: 'Cert Upload',
          text: errMessage,
          onClose: () => { this.props.history.push("/login"); }
        });
      }
      return false;
    }
    
    if (redirect) {
      <Redirect push to="/login" />
    }

    if (this.state.showReCAPTCHA) {
      return <FullScreenReCAPTCHA onVerify={this.onReCAPTCHAVerify} />
    }
    
    const requirementSetsList = Array.isArray(this.props.certUpload.requirementSets) 
      ? this.props.certUpload.requirementSets.map((requirement) => {
          return {
            ruleGroupName: requirement.CoverageType,
            ruleId: requirement.RuleID,
            attributeName: requirement.AttributeName,
            conditionTypeId: requirement.ConditionTypeID,
            conditionValue: requirement.ConditionValue,
            deficiencyText: requirement.DeficiencyText,
            deficiencyTypeId: requirement.DeficiencyTypeID,
            status: (requirement.AttributeStatus !== 'Waived') ? requirement.AttributeStatus : ''
          }
        })
      : [];


    const attachmentsList = Array.isArray(this.props.certUpload.attachments)
      ? this.props.certUpload.attachments.map((attachment) => {
          return {
            requirementSetDocumentId: attachment.RequirementSets_DocumentID,
            fileName: attachment.FileName,
            url: attachment.Url,
          } 
      })
      : [];


    const endorsementsList = [];
    if (this.props.certUpload.endorsements.length > 0) {
      this.props.certUpload.endorsements.map((endorsement) => {
        if (! endorsementsList.some((f) => f.Id === endorsement.Id)) {
          endorsementsList.push({
            Id: endorsement.EndorsementID,
            Name: endorsement.EndorsementName,
            AlwaysVisible: endorsement.AlwaysVisible,
          });
        }
      });
    }
    if (this.props.certUpload.allEndorsements.length > 0) {
      this.props.certUpload.allEndorsements.forEach((e) => {
        if ((e.AlwaysVisible === true) && (! endorsementsList.some((f) => f.Id === e.Id))) {
          endorsementsList.unshift(e);
        }
      });
    }

    const documentList = Array.isArray(this.props.certUpload.list)
      ? this.props.certUpload.list.map((document) => {
          let uploadBy;
          if (document.FirstName && document.LastName) {
            uploadBy = document.FirstName + ' ' + document.LastName.charAt(0).toUpperCase() + '.';
          }
          else {
            const uploadedByUser = document.UploadedByUser.split(' ');
            if (uploadedByUser.length > 0) {
              uploadBy = uploadedByUser[0] + ' ' + uploadedByUser[uploadedByUser.length-1].charAt(0).toUpperCase() + '.';
            } else {
              uploadBy = document.UploadedByUser;
            }
          }

          return {
            id: document.DocumentID,
            name: document.FileName,
            uploadBy: uploadBy,
            uploadDate: moment(document.DateCreated).format('MM/DD/YYYY'),
            documentStatus: document.DocumentStatus
          }
        })
      : [];    

    return (this.state.enablePage) && (
      <div className="container-fluid">
        <section className="border p-3">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-3 justify-content-center">
              {certUploadProfile.logo && (
                <img src={`data:image/jpeg;base64,${certUploadProfile.logo}`}
                  alt="Logo"
                  className="holder-logo" />
              )}
              </div>
              <div className="col-md-3 pt-3"><h2>{certUploadProfile.holderName}</h2></div>
              <div className="col-md-6 pt-3">
                <div className="row">
                  <div className="col-md-4">{this.props.locale.insuredTitle}: </div>
                  <div className="col-md-8">{certUploadProfile.insuredName}</div>
                </div>
                <div className="row">
                  <div className="col-md-4">{this.props.locale.projectTitle}: </div>
                  <div className="col-md-8">{certUploadProfile.projectName}</div>
                </div>
                <div className="row">
                  <div className="col-md-4">{this.props.locale.projectName2Title}: </div>
                  <div className="col-md-8">{certUploadProfile.projectName2}</div>
                </div>                
                <div className="row">
                  <div className="col-md-4">{this.props.locale.projectNumberTitle}: </div>
                  <div className="col-md-8">{certUploadProfile.projectNumber}</div>
                </div>
                <div className="row">
                  <div className="col-md-4">{this.props.locale.complianceStatusTitle}: </div>
                  <div className="col-md-8">{certUploadProfile.complianceStatus}</div>
                </div>
              </div>              
            </div>
            {
              certUploadProfile.AllowURLDocumentUploads && (
                <div className="row pt-3">
                <div className="col-md-12 d-flex justify-content-start">
                  <p className="explanatory-text">
                    {this.props.locale.uploadHint} or <a href="#upload">click here</a>.
                  </p>
                </div>
              </div>
              )
            }
            <div className="row pt-3">
              <div className="col-md-8">
                <div className="row pt-3">
                  <div className="col-md-6"><h5>{this.props.locale.requiredInsurance}</h5></div>
                  <div className="col-md-6 d-flex justify-content-end">
                    <button
                      className="bn bn-small bg-green-dark-gradient"
                      onClick={() => this.setState({ showAllRequirements: !this.state.showAllRequirements }, 
                        this.fetchRequirementSets()
                      )}
                    >
                    { this.state.showAllRequirements ? `Hide` : `Show` } all requirements
                    </button>
                    {(allRequirements.length > 0) && (
                      <button
                        className="bn bn-small bg-green-dark-gradient"
                        onClick={(e) => this.downloadRequirements(e)}
                      >
                        Download Requirements
                      </button>
                    )}
                  </div>  
                </div>
             
                <div className="row pt-3">
                  <RequirementSetsList 
                    requirementSetsList={requirementSetsList} 
                    isFetching={this.props.certUpload.fetchingRequirements} 
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="row pt-3">
                  <div className="col-md-12">
                    <h5>{`Attachments`}</h5>
                    <AttachmentsList attachmentsList={attachmentsList} />
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-md-12">
                  <h5>{this.props.locale.additionalRequeriments}</h5>
                  <EndorsementsList endorsementsList={endorsementsList} />
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-md-12">
                    <h5>AI Wording</h5>
                    <ProjectAIWording AIWording={certUploadProfile.AIWording} />
                  </div>
                </div>
              </div>

            </div>
          
            <div className="row pt-3 border-top" id="upload">
              {
                certUploadProfile.AllowURLDocumentUploads && (
                  <div className="col-md-6">
                    <h5>{this.props.locale.newDocuments}</h5>
                    <DocumentUploadForm
                      onSubmit={this.handleFormSubmit}
                      user={this.state.userData}
                      waitPayment={this.state.waitingBraintree}
                    />
                  </div>
                )
              }
              <div className={`pr-1 ${certUploadProfile.AllowURLDocumentUploads ? 'col-md-6' : 'col-md-12'}`} >
                <h5>{this.props.locale.prevDocuments}</h5>
                <DocumentList
                  documentList={documentList}
                />
              </div>
            </div>
            {(this.props.certUpload.customerToken)
              ? <React.Fragment>
                <DropIn options={{authorization: this.props.certUpload.customerToken}}
                        onInstance={(instance) => (this.setState({dropIn: instance}))}
                ></DropIn>
                <p>The certificate processing has a fee of U$S {this.state.fee}</p>
              </React.Fragment>
              : null
            }
          </div>
        </section>

        {/* HIDDEN DIV FOR HTML2CANVAS */}
        <div style={{ display: 'none', height: '900px'}}>
          <div id="divToPrint" className="mt1" style={{
            backgroundColor: '#f5f5f5',
            width: '210mm',
            minHeight: '297mm',
            margin: '2px',
            overflowY: 'visible',
          }}>
            {allRequirements.length > 0 && this.renderDownloadableRequirements(allRequirements, endorsementsList)}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    locale: state.localization.strings.certUpload,
    certUpload: state.certUpload,
    holderRequirementSets: state.holderRequirementSets,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    certUploadActions: bindActionCreators(certUploadActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CertUpload);
