//import { env } from '../:'
const env =process.env.REACT_APP_NODE_ENV;
let siteKey = ''

if (env === 'local') {
  siteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
} else if (env === 'dev') {
  siteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
} else if (env === 'stage') {
  siteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
} else if (env === 'prod') {
  siteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
} else if (env === 'sbx') {
  siteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
}

export const GOOGLE_RECAPTCHA_SITE_KEY = siteKey