import _ from 'lodash';
import debounceAsync from 'debounce-async';

import {setHCListError} from '../../../actions';
import Utils from '../../../../../lib/utils';
import Api from '../../../../../lib/api';

/**
 * Data structure used to cache the value and error to avoid 
 * executing the function multiple times.
 * @type {{ value: string, error: string }}
 */
export const cache = {
  value: null,
  error: null
};

/**
 * Utility to clear the cached value and error. 
 * Currently it is only be used on testing.
 * When testing these values things might get a little bit complicated, 
 * so it'd be better to clean them too on each test case.
 */
export const clearCache = () => {
  cache.value = null;
  cache.error = null;
};

export const asyncValidate = async ({companyName}, dispatch, props) => {
  if (props.profile && props.profile.name === companyName) {
    return {};
  }

  if (!companyName) {
    return {};
  }

  if (cache.value === companyName && !cache.error) {
    return {};
  }

  if (cache.value === companyName && cache.error) {
    throw {companyName: cache.error};
  }
  
  const local = props.local.strings.hiringClients;
  cache.value = companyName;
  
  try {
    const endpoint = `hiringclientdetail?hiringClientName=${companyName}`;
    const response = await Api.get(endpoint);
    
    if (response.data.success) {
      const message = local.addHCModal.companyInfoTab.nameAlreadyTaken;
      cache.error = message;
      throw cache.error;
    }
  } catch (error) {
    if (cache.error) {
      throw {companyName: cache.error};
    } else if (_.get(error, 'response.data')) {
      dispatch(setHCListError(Utils.getErrorMessage(
        error.response.data, 
        local.actions
      )));
    } else {
      dispatch(setHCListError(local.actions.errorDefault));
    }
  }
  
  cache.error = null;

  return {};
};

export default debounceAsync(asyncValidate, 300);