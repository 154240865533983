import React,{useState,useEffect} from 'react';
import { Field, reduxForm ,touch,change} from 'redux-form';
import { connect } from 'react-redux';
import {AsyncPaginate} from 'react-select-async-paginate';
import Utils from '../../../lib/utils';
import renderField from '../../customInputs/renderField';
import renderSelect from '../../customInputs/renderSelect';
import FilterActions from '../../common/filterActions/FilterActions'
import { bindActionCreators } from 'redux';
import * as loadApplyOptions from '../../common/countyDropdownActions/loadOptions'
import * as applyActions from '../../common/countyDropdownActions/actions'
import _ from 'lodash';

let FilterSC = props => {

  let {
    title,
    keywordsLabel,
    keywordsPlaceholder,
    HCLabel,
    HCPlaceholder,
    SCStatusLabel,
    SCStatusPlaceholder,
    tradeLabel,
    tradePlaceholder,
    tierRatingLabel,
    tierRatingPlaceholder,
    stateLabel,
    statePlaceholder,
    maxSingleLabel,
    maxSinglePlaceholder,
    maxAggregateLabel,
    maxAggregatePlaceholder,
    vendorIdLabel,
    vendorIdPlaceholder,
    countryLabel
  } = props.local.strings.subcontractors.filter;

  const SelectAsyncOptions = ({ input, meta, dispatch, ...props }) => {
    const touchedError = meta.touched && meta.error;
    const name = input.name;
    const baseClassName = `${props.className || ''} select-${name}`;
    const errorClassName = `${touchedError ? `${baseClassName}-error` : ''}`;
  
    return (
      <React.Fragment>
        <AsyncPaginate
          {...input}
          {...props}
          openMenuOnClick={true}
          closeMenuOnScroll={true}
          cacheOptions={true}
          pageSize={10}
          additional={{ page: 1 }}
          classNamePrefix="vk"
          className={`${baseClassName} ${errorClassName}`}
          menuPlacement="auto"
          onFocus={(_) => { }}
          onBlur={() => meta.dispatch(touch('FilterSC', name))}
          onChange={(newValue) => input.onChange(newValue)}
          captureMenuScroll={true}
          closeMenuOnSelect={true}
          debounceTimeout={300}
          loadOptionsOnMenuOpen={true}
        />
        {touchedError && (
          <span className="select-error-message field-validation-message">
            {meta.error}
          </span>
        )}
      </React.Fragment>
    );
  };
  const [selectedCountry,setselectedCountry] = useState(null)

  const resetStateValue = (value) => {
    props.dispatch(change(
      'FilterSC',
      'countryId',
      {value:value.value,label:value.label}
    ));
    if(selectedCountry !== value.value){
      props.dispatch(change(
        'FilterSC',
        'stateId',
        {value:null,label:''}
      ));
    }
    setselectedCountry(value.value)
  };
  const handleSelectState = (value) => {
    props.dispatch(change(
      'FilterSC',
      'stateId',value.value
    ));
  }
  // hc options
  const hcOptionsList = Utils.getOptionsList(HCPlaceholder, props.hc.list, 'name', 'id', 'name');
  // status options
  const statusOptionsList = Utils.getMultiVariateOptionsList(SCStatusPlaceholder, props.sc.subcontratorStatusWithCounts, 'Status', 'SubCount', 'Id');

  // const statusOptionsList = Utils.getOptionsList(SCStatusPlaceholder, props.sc.subcontratorStatus, 'Status', 'Id', 'Id');
  // trade options
  const tradeOptionsList = Utils.getOptionsList(tradePlaceholder, props.sc.tradesForFilterList, 'description', 'id', 'orderIndex');
  // tier rates
  const tierRatesOptionsList = Utils.getOptionsList(tierRatingPlaceholder, props.sc.subcontratorTierRates, 'Name', 'Id', 'Id');
  // state options
  // const statesOptionsList = Utils.getOptionsList(statePlaceholder, props.register.geoStates, 'Name', 'ShortName', 'Name');

  const {handleSubmit} = props;
  const countryId = _.get(props, 'currentForm.FilterSC.values.countryId.value', null);
  
  return (
    <form
      onSubmit={handleSubmit}
      className="list-view-filter-form filter-sc-list" >
      <h2 className="list-view-filter-title">{title}</h2>
      <div className="container-fluid filter-fields">
        <div className="row sc-filter-row">

          <div className="col-md-3 col-sm-12 no-padd">
            <div className="admin-form-field-wrapper keyword-field">
              <label htmlFor="keywords">{keywordsLabel}: </label>
              <Field
                name="keywords"
                type="text"
                placeholder={`--${keywordsPlaceholder}--`}
                component={renderField}
                className="tags-input"
              ></Field>
            </div>
          </div>

          {
            !props.fromHCtab && (
              <div className="col-md-3 col-sm-12 no-padd" >
                <div className="admin-form-field-wrapper ass-hc-field">
                  <label htmlFor="associatedHCs">{HCLabel}: </label>
                  <div className="select-wrapper">
                    <Field
                      name="associatedHCs"
                      component={renderSelect}
                      options={hcOptionsList} />
                  </div>
                </div>
              </div>
            )
          }

          <div className="col-md-3 col-sm-12 no-padd" >
            <div className="admin-form-field-wrapper status-field">
              <label htmlFor="status">{SCStatusLabel}: </label>
              <div className="select-wrapper">
                <Field
                  name="status"
                  component={renderSelect}
                  options={statusOptionsList} />
              </div>
            </div>
          </div>

          <div className="col-md-3 col-sm-12 no-padd" >
            <div className="admin-form-field-wrapper trade-field">
              <label htmlFor="trade">{tradeLabel}: </label>
              <div className="select-wrapper">
                <Field
                  name="trade"
                  component={renderSelect}
                  options={tradeOptionsList} />
              </div>
            </div>
          </div>

          <div className="col-md-3 col-sm-12 no-padd">
            <div className="admin-form-field-wrapper keyword-field">
              <label htmlFor="vendorId">{vendorIdLabel}: </label>
              <Field
                name="vendorId"
                type="text"
                placeholder={`--${vendorIdPlaceholder}--`}
                component={renderField}
                className="tags-input"
              ></Field>
            </div>
          </div>

        </div>

        <div className="row sc-filter-row">

          <div className="col-md-3 col-sm-12 no-padd" >
            <div className="admin-form-field-wrapper tier-rating-field">
              <label htmlFor="tierRating">{tierRatingLabel}: </label>
              <div className="select-wrapper">
                <Field
                  name="tierRating"
                  component={renderSelect}
                  options={tierRatesOptionsList} />
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12 no-padd" >
            <div className="admin-form-field-wrapper state-field">
            <label htmlFor="countryId">
                  {countryLabel}:
                  </label>
                  <Field
                    name="countryId"
                    onChange={(values)=>resetStateValue(values)}
                    type="text"
                    required={true}
                    component={SelectAsyncOptions}
                    placeholder='Select Country'
                    loadOptions={props.actions.loadCountries(props)}
                  />
            </div>
          </div>
          {countryId ? 
          <div className="col-md-3 col-sm-12 no-padd" >
            <div className="admin-form-field-wrapper state-field">
              <label htmlFor="stateId">{stateLabel}: </label>
              <div className="select-wrapper">
                  <Field
                  name="stateId"
                  type="text"
                  required={true}
                  component={SelectAsyncOptions}
                  placeholder='Select State'
                  cacheUniqs={[selectedCountry]}
                  onChange={(value)=>handleSelectState(value)}
                  loadOptions={props.actions.loadStates(props,selectedCountry)}
                  />
              </div>
            </div>
          </div>
          :null}
          
          <div className="col-md-3 col-sm-12 no-padd" style={{display:'none'}}>
            <div className="admin-form-field-wrapper max-single-field">
              <label htmlFor="maxSingle">{maxSingleLabel}: </label>
              <Field
                name="maxSingle"
                type="text"
                placeholder={`--${maxSinglePlaceholder}--`}
                component={renderField}
                className="tags-input"
              ></Field>
            </div>
          </div>

          <div className="col-md-3 col-sm-12 no-padd" style={{display:'none'}}>
            <div className="admin-form-field-wrapper max-aggregate-field">
              <label htmlFor="maxAggregate">{maxAggregateLabel}: </label>
              <Field
                name="maxAggregate"
                type="text"
                placeholder={`--${maxAggregatePlaceholder}--`}
                component={renderField}
                className="tags-input"
              ></Field>
            </div>
          </div>

          <FilterActions
            formName={props.form}
            dispatch={props.dispatch} />

        </div>
      </div>
    </form>
  );
}

FilterSC = reduxForm({
  form: 'FilterSC',
})(FilterSC);

const mapStateToProps = (state, ownProps) => {
  return {
    register: state.register,
    hc: state.hc,
    sc: state.sc,
    local: state.localization,
    currentForm: state.form
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      ...bindActionCreators(applyActions, dispatch),
      ...loadApplyOptions
    }
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(FilterSC);
