let en = {
  auth: {
    common: {
      AuthFormHeader: {
        title: 'Welcome to your Dashboard',
        subtitle: 'Please Sign in'
      },
    },
    login: {
      signIn: 'Sign in',
      ssoSignIn: 'SSO Sign in',
      recoverPasswordLink: 'Forgot your password?',
      privacyPolicy: 'Privacy Policy',
      userNameRequired: 'Username is required',
      passwordRequired: 'Password is required',
      userPlaceholder: 'Username',
      passwordPlaceholder: 'Password',
      rememberMe: 'Remember Me',
      actions: {
        error10005: 'Please, login again',
        error10006: 'This session is invalid. Please, login again.',
        error10007: 'This session has expired. Please, login again.',
        error10008: 'This user was not found. Please try again with a different one.',
        error10009: 'Invalid password. Please try again.',
        error10011: 'This user was disabled. Please, contact an admin to enable it.',
        errorDefault: 'Error: please, try again. Contact an admin if this error persists.',
        errorConnection: 'Connection error'
      }
    },
    forgot: {
      userNameRequired: 'Username is required',
      sendButtonText: 'Recover password',
      subTitleHeader: 'Reset your password',
      placeholdUserName: 'Username',
      actions: {
        errorMsjBase: 'Recovery email was not sent. ',
        errorMsjPassword: 'Password was not changed. ',
        errorMsjPlease: 'Please, try again.',
        loginExtraMessage: 'Please, check your email for a new password and sign in',
        error10003: 'Invalid user provided. Please, try again with a different one.',
        error10008: 'This user was not found. Please, try again with a different one.',
        error10011: 'This user was disabled. Contact an admin in order to enable it or try again with a different one.',
        error10013: 'Invalid email format, please check your user email address',
        errorDefault: 'Error: please, try again. Contact an admin if this error persists.',
        errorConnection: 'Connection error'
      }
    },
    reset: {
      passwordRequired: 'Password is required',
      passwordBoth: 'Both passwords must be equal',
      subTitleHeader: 'Please enter your new password!',
      newPasswordPlaceholder: 'New Password',
      retypePasswordPlaceholder: 'Re type your new Password',
      actions: {
        error10005: 'You were not logged in when trying to reset. Please, login and try again.',
        error10006: 'Something is wrong with this session. Please, login again to open a new session and try again.',
        error10007: 'This session has expired. Please, login again to open a new session and try again.',
        error10008: 'This user was not found. Please try with a different one.',
        error10009: 'Invalid password. Please, add a new password that meet the requiremets.',
        error10011: 'This user is disabled. Please, contact an admin to enable this account and try to update your profile again.',
        error10012: 'Invalid password format. Please, add a new password that meet the requiremets.',
        errorDefault: 'Error: please, try again. Contact an admin if this error persists.',
        errorConnection: 'Connection error'
      }
    }
  },
  yurbi: {
    redirectErrorMessage: 'User is not authorized to sign in to BI',
    authorizationErrorMessage: 'BI authorization error'
  },
  register: {
    registerMain: {
      step1Title: '1. User Information (Prequal Contact)',
      step2Title: '2. Company Profile',
      title: 'Subcontractor registration form',
      instruction: 'Please use the form below to register for prequalification.',
      suggestion: 'The Email address provided in user information should be remembered for future logins!',
      submit: 'Continue',
      accountAssociated: 'Your account has been associated with an additional Hiring Client. You can switch between Hiring Clients by changing the Hiring Client dropdown value at the top of the screen.'
    },
    applyMain: {
      title: 'Subcontractor application form',
      instruction: 'Please use the form below to register for prequalification.',
      suggestion: 'The Email address provided in user information should be remembered for future logins!',
      submit: 'Continue'
    },
    registerSidebar: {
      slogan: 'Welcome to Prequal, the subcontractor prequalification software'
    },
    companyProfile: {
      fieldLengthValidation: 'Field is too long',
      requiredValidation: 'Required',
      wrongEmailFormat: 'Wrong email format',
      wrongTaxIdFormat: 'Wrong Tax ID format',
      zipcodeErrorMessage: 'Wrong Zip Code format',
      companyNameLabel: 'Legal Name of Company',
      companyNameSlug :'(per your W-9 or country specific equivalent)',
      companyNamePlaceholder: 'Company Name ABC',
      dropdownPlaceholder: 'Select...',
      dropdownNoOptionsMessage: 'No options found.',
      dropdownLoadingMessage: 'Please wait a moment...',
      tradeLabel: 'Main Trade',
      secondaryTradeLabel: 'Second Trade',
      tertiaryTradeLabel: 'Third Trade',
      quaternaryTradeLabel: 'Fourth Trade',
      quinaryTradeLabel: 'Fifth Trade',
      addressLabel: 'Address',
      addressPlaceholder: '123 Main Street',
      cityLabel: 'City',
      cityPlaceholder: 'Anytown',
      stateLabel: 'State / Province',
      provinceLabel: 'Province',
      statePlaceholder: 'State',
      zipCodeLabel: 'Postal Code',
      zipCodePlaceholder: '*********',
      mainEmailLabel: 'Main email',
      mainEmailPlaceholder: 'Title',
      countryLabel: 'Country',
      taxIdLabel: 'Tax ID',
      vatLabel: 'VAT',
      gstLabel: 'Sales Tax Reg. Number (GST)',
      gstPlaceholder:'XXXXXXXXXRTXXXX',
      taxIdPlaceholder: '00-0000000',
      mustAgree: 'You must agree to our',
      userAgreementLinkText: 'User Agreement',
      before: 'before continuing',
      agreeLabel: 'I agree to the User Agreement',
      submitText: 'SUBMIT',
      taxIdAlreadyTaken: 'This Tax Id was already taken',
      contactNameLabel: 'Contact Name',
      contactNamePlaceholder: 'Contact Name',
      contactEmailLabel: 'Contact Email',
      contactEmailPlaceholder: 'Contact Email',
      postalCodeErrorMessageCanada:'Postal Code Format: A1A 1A1',
      postalCodeErrorMessage: 'Postal Code Format: 00000',
    },
    userInformation: {
      titlesDropdownPlaceholder: 'Select...',
      titlesDropdownNoOptionsMessage: 'No titles found.',
      titlesDropdownLoadingMessage: 'Please wait a moment...',
      fieldLengthValidation: 'Field is too long',
      requiredValidation: 'Required',
      phoneErrorMessage: 'Invalid format',
      wrongEmailFormat: 'Wrong email format',
      passwordBoth: 'Both passwords must be equal',
      firstNameLabel: 'First Name',
      firstNamePlaceholder: 'Jon Jon',
      lastNameLabel: 'Last Name',
      lastNamePlaceholder: 'Doe',
      titleLabel: 'Title',
      phoneLabel: 'Phone number',
      phonePlaceholder: '+55 (555) 555 5555',
      cellPhoneLabel: 'Cell Phone number',
      cellPhonePlaceholder: '+55 (555) 555 5555',
      emailLabel: 'Email',
      mainEmailPlaceholder: 'example@example.com',
      passwordLabel: 'Please Create Password',
      retypePassword: 'Re-Type password',
      passwordPlaceholder: '**********',
      timezoneLabel: 'Choose your timezone',
      userButtonText: 'CONTINUE',
      emailAlreadyTaken: 'This email was already taken'
    },
    actions: {
      error10001: 'Error: Server error. Please, refresh.',
      error10003: 'Error: invalid data. Please check the form fields.',
      error10004: 'Error: email already used. Please check the email field.',
      error10005: 'Error: this session is not valid. Please, sign in again.',
      error10006: 'Error: You are not logged in. Please, sign in and try again.',
      error10007: 'Error: Session expired. Please, sign in again to update your profile.',
      error10008: 'Error: current user does not exist. Please, login again with a valid user and try again.',
      error10011: 'Error: This user is disabled. Please, contact an admin to enable this account and try to update your profile again later.',
      error10013: 'Error: invalid email format. Please check the email field and write a valid email address.',
      error10014: 'Error: this is not a valid user type. Please, select a valid user type.',
      error10155: 'Error: Please select Hiring Client/Holder.',
      errorDefault: 'Error: please, try again. Contact an admin if this error persists.',
      errorConnection: 'Connection error - Please, check your Internet service.',
      invalidHash: 'The link is invalid or expired.',
      registrationError: 'There was a problem with the registration process: Missing form.',
      registrationEmailError: 'The email is already registered. Please check the email field.',
      registrationSuccess: 'The registration was successful. Please log in and complete the prequalification process.',
      associatedSubExists: 'You already have an account with a different TaxID. Please check TaxID entered, or contact your ADMIN',
      subAlreadyExists: 'This Subcontractor is already registered with {hiringClientName}. Please log in, or contact your ADMIN',
      emailHCFamilyTaken: 'You are already registered with "{10156}", please contact your admin.',
      taxIdHCFamilyTaken: 'The company is already registered with "{10157}", please contact your admin.'
    },
    reducer: {
      allUsersLabel: 'All User Types'
    }
  },
  dashboard: {
    title: 'Dashboard',
    formBuilderLink: 'Form Builder',
    formListLink: 'Forms',
    usersLink: 'Users',
    registerLink: 'Register',
    HCLink: 'Hiring Clients',
    templatesLink: 'Template List',
    templateBuilderLink: 'Template Builder',
    HCProfileLink: 'HC Profile'
  },
  common: {
    sidebar: {
      navigation: [
        'Tasks',
        'Hiring Clients',
        'Subcontractors',
        'Users',
        'Forms',
        'Comm. Templates',
        'Financials',
      ],
      headerIcons: {
        hiringClientsHoldersTitle: 'Hiring Clients/Holders',
        hiringClientsTitle: 'Hiring Clients',
        holdersTitle: 'Holders',
        usersTitle: 'Users',
        dashboardTitle: 'Dashboard',
        mailTitle: 'Mail',
        tasksTitle: 'Tasks',
        videosTitle: 'Videos',
        projectsTitle: 'Projects',
        insuredsTitle: 'Insureds',
        contactsTitle: 'Contacts',
        settingsTitle: 'Settings',
        helpTitle: 'Help',
        searchTitle: 'Search',
        documentsTitle: 'Documents',
        agenciesTitle: 'Producers',
        analyticsTitle: 'Analytics',
      },
    },
    table: {
      noResults: 'No results found for this search criteria'
    },
    typeAheadAndSearch: {
      holdersPlaceholder: 'All Holders',
      keywordsPlaceholder: '--Keywords--'
    },
    typeAheadErrors: {
      error10005: 'Error: This is an invalid session. Please, sign in again.',
      error10006: 'Error: You are not logged in. Please, sign in and try again.',
      error10007: 'Error: Session expired. Please, sign in and try again.',
      error10011: 'Error: your current user is disabled. Please, contact an admin to enable this account and login again to continue.',
      error10019: 'No results found.',
      errorDefault: 'Something went wrong, please try again.',
      errorConnection: 'Connection error - Please, check your Internet service.'
    }
  },
  users: {
    userId: 'User ID',
    title: 'Users',
    tableHeaderName: 'Name',
    tableHeaderPhone: 'Phone',
    tableHeaderEmail: 'Email',
    tableHeaderCompany: 'Company',
    tableHeaderUserType: 'User Type',
    tableHeaderStatus: 'Status',
    tableHeaderIsEmailOnOff: 'Email On/Off',
    filterNameAll: 'All',
    filterNameAdmin: 'Admin',
    filterNameOperator: 'Operator',
    filterNameHC: 'Hiring Client',
    filterNameSC: 'Subcontractor',
    buttonFilterUser: 'Filter User',
    buttonAddUser: 'Add User',
    buttonUserLog: 'User Log',
    noResults: 'No results found for this search criteria',
    addUserOverlay: {
      title: 'Please choose a user type',
      buttonAdmin: 'Admin',
      buttonOperator: 'Operator',
      buttonHC: 'Hiring Clients',
      buttonSC: 'Subcontractor',
    },
    mouseHoverLink: 'View Details',
    addUser: {
      requiredValidation: 'Required',
      phoneErrorMessage: 'Invalid format',
      wrongEmailFormat: 'Wrong email format',
      passwordBoth: 'Both passwords must be equal',
      ModalTitleAdmin: 'Admin',
      ModalTitleOperator: 'Operator',
      ModalTitleHC: 'Hiring Client',
      ModalTitleSC: 'Subcontractor',
      editUser: 'Edit User',
      addUser: 'Add New User',
      roleLabel: 'Role',
      rolePlaceholder: 'Select Role',
      noRoleAssigned: 'No Role Assigned',
      nameLabel: 'First Name',
      namePlaceholder: 'First Name',
      lastNameLabel: 'Last Name',
      lastNamePlaceholder: 'Last Name',
      emailLabel: 'Email',
      emailPlaceholder: 'example@email.com',
      phoneLabel: 'Phone #',
      phonePlaceholder: '+0 (000) 000-0000 / (111) 111-1111',
      associatedSCLabel: 'Associate Subcontractors',
      associatedSCPlaceholder: 'Type Subcontractors',
      associatedHCLabel: 'Associate Hiring Clients',
      associatedHCPlaceholder: 'Type Hiring Clients',
      associatedHolderLabel: 'Associate Holders',
      associatedInsuredLabel: 'Associate Insureds',
      passwordLabel: 'Password',
      passwordPlaceholder: '*************',
      buttonGeneratePass: 'Generate Password',
      retypePasswordLabel: 'Confirm Password',
      checkLabel: 'Ask to change password after login',
      checkBIAccessLabel: 'BI Access',
      checkCFAPIAccessLabel: 'CF API Access',
      checkPQAPIAccessLabel: 'PQ API Access',
      timeZoneLabel: 'Choose your timezone',
      saveUserButton: 'Save user',
      cancelButton: 'Cancel',
      subsPlaceholder: 'Search Subcontractors',
      departmentsPlaceholder: 'Select a Department'
    },
    rowPopOver: {
      HCTitle: 'Hiring Clients',
      SCTitle: 'Subcontractors',
      buttonMoreLogs: 'View More Logs'
    },
    filter: {
      title: 'Filter Users',
      keywordsLabel: 'Keywords',
      keywordsPlaceholder: 'All Project Users',
      HClabel: 'Hiring Client',
      HCPlaceholder: 'Hiring Client',
      userTypeLabel: 'User Type',
      userTypePlaceholder: 'User Type',
      buttonSearch: 'Search'
    },
    table: {
      quickViewLink: 'Quick View',
      edituserLink: 'Edit User',
      reviewApplicationsLink: 'Review Applications',
    },
    reducer: {
      statusChangePassword: 'Change Password',
      statusActive: 'Active',
      statusInactive: 'Inactive',
      allHC: 'All Hiring Clients',
      allSC: 'All Subcontractors',
      select: 'Select Subcontractor'
    },
    actions: {
      error10000: 'Backend error',
      error10001: 'Error: Server error. Please, refresh.',
      error10003: 'Error: invalid filter data. Please, adjust the filters values and try again.',
      error10004: 'Error: email already used. Please check the email field.',
      error10005: 'Error: This is an invalid session. Please, sign in again.',
      error10006: 'Error: You are not logged in. Please, sign in and try again.',
      error10007: 'Error: Session expired. Please, sign in and try again.',
      error10008: 'Error: current user does not exist. Please, login again with a valid user and try again.',
      error10011: 'Error: your current user is disabled. Please, contact an admin to enable this account and login again to continue.',
      error10013: 'Error: invalid email format. Please check the email field and write a valid email address.',
      error10014: 'Error: this is not a valid user type. Please, select a valid user type.',
      error10155: 'Error: Please select Hiring Client/Holder.',
      errorDefault: 'Something went wrong, please try again.',
      errorConnection: 'Connection error - Please, check your Internet service.'
    }
  },
  hiringClients: {
    title: 'Hiring Clients',
    listTitle: 'Hiring Clients',
    addButton: 'Add Hiring Client',
    noResults: 'No Hiring Clients found',
    tableHeaderHcName: 'Name',
    tableHeaderHcArchive: 'Archive',
    tableHeaderRegistrationUrl: 'Registration Url',
    tableHeaderCountry: 'Country',
    tableHeaderContactName: 'Contact Name',
    tableHeaderHcPhone: 'Phone',
    tableHeaderHcState: 'State',
    viewHC: 'VIEW HC',
    editHC: 'EDIT HC',
    filterHC: 'Filter',
    addHCModal: {
      title: 'WELCOME TO THE HIRING CLIENT WIZARD',
      companyInfoTab: {
        title: 'Company Information',
        labelCompanyLogo: 'Click here to upload your company logo',
        registrationUrlSufix: '.usa.prequal.com',
        labelRegistrationUrl: 'Registration URL',
        labelCompanyName: 'Company Name',
        labelAddress1: 'Company Address 1',
        labelAddress2: 'Company Address 2',
        labelCity: 'City',
        labelState: 'State',
        labelPostalCode: 'Postal Code',
        labelPhone: 'Phone',
        labelFax: 'Fax',
        labelPhone2: 'Phone 2',
        labelEntity: 'Entity Type',
        entityTypeNone: 'NONE',
        entityTypeChild: 'CHILD',
        entityTypeParent: 'PARENT',
        labelParentHC: 'Parent HC',
        parentHCLoadingMessage: 'Please wait a moment...',
        parentHCNoOptionsMessage: 'No parents found.',
        parentHCPlaceholder: 'Please select the parent HC',
        allowApplications: 'Allow Applications',
        autoApproveApplications: 'Auto Approve Applications',
        cancel: 'Cancel',
        continueButton: 'Continue',
        saveButton: 'Save',
        requiredValidation: 'Required',
        nameAlreadyTaken: 'This name was already taken',
        fileTypeValidation: 'This file has to be an image (jpg, png or gif)',
        mustBeNumericValue: 'This field has to be numeric',
        officeIdValueLength: 'This field has allow up to 150 characters',
        noteValueLength: 'This field has allow up to 500 characters',
        faxErrorMessage: 'Invalid format',
        phoneErrorMessage: 'Invalid format',
        wrongEmailFormat: 'Wrong email format',
        postalCodeErrorMessage: 'Postal Code Format: 00000',
        invalidValueErrorMessage: 'Invalid Value',
        mainPhoneErrorMessage: 'Phone Format: +0 (000) 000-0000 / (111) 111-1111',
        postalCodeErrorMessageCanada:'Postal Code Format: A1A 1A1'
      },
      contactTab: {
        title: 'Contact person',
        tabCreate: 'Create New HC User',
        tabChoose: 'Choose an existing user',
        labelFirstName: 'First Name',
        labelLastName: 'Last Name',
        labelEmail: 'Email',
        labelPhone: 'Phone Number',
        labelPassword: 'Password',
        labelRetypePassword: 'Re-type Password',
        labelTimezone: 'Timezone',
        emailAlreadyTaken: 'This email was already taken',
        generatePasswordButton: 'Generate Password',
        chooseExistingHC: 'Existing HC User',
        existingHCPlaceholder: 'Choose an existing user',
        chooseHCUserLoadingMessage: 'Please wait a moment...',
        chooseHCUserNoOptionsMessage: 'No users found.',
        cancel: 'Cancel',
        finishButton: 'Finish',
        requiredValidation: 'Required',
        phoneErrorMessage: 'Invalid format',
        wrongEmailFormat: 'Wrong email format',
        userAlreadyExists: 'This user already exists',
        passwordBoth: 'Both passwords have to be equal',
      }
    },
    filter: {
      title: 'Filter Hiring Clients',
      keywordsLabel: 'Keywords',
      keywordsPlaceholder: 'Keywords',
      searchButton: 'Search'
    },
    actions: {
      error10000: 'Backend error',
      error10001: 'Error: Server error. Please, refresh.',
      error10003: 'Error: invalid filter data. Please, adjust the filters values and try again.',
      error10004: 'Error: email already used. Please check the email field.',
      error10005: 'Error: This is an invalid session. Please, sign in again.',
      error10006: 'Error: You are not logged in. Please, sign in and try again.',
      error10007: 'Error: Session expired. Please, sign in and try again.',
      error10008: 'Error: current user does not exist. Please, login again with a valid user and try again.',
      error10011: 'Error: your current user is disabled. Please, contact an admin to enable this account and login again to continue.',
      error10013: 'Error: invalid email format. Please check the email field and write a valid email address.',
      error10014: 'Error: this is not a valid user type. Please, select a valid user type.',
      error10019: 'No Hiring clients found',
      error10155: 'Error: Please select Hiring Client/Holder.',
      errorDefault: 'Something went wrong, please try again.',
      errorConnection: 'Connection error - Please, check your Internet service.'
    }
  },
  subcontractors: {
    title: 'Subcontractors',
    addButton: 'Add Subcontractor',
    filterSC: 'Filter SC',
    assignSCUser: 'Assign SC User',
    viewSC: 'View SC',
    viewInsured: 'View Insured',
    tableHeader: {
      scName: 'Subcontractor',
      taxId: 'Tax Id',
      tierRating: 'Tier Rating',
      status: 'Status',
      singleProjectLimit: 'Single Project Limit',
      state: 'State',
      trade: 'Trade',
      primaryTrade: 'Primary Trade',
      requestorName: 'Requestor',
      requestorEmail: 'Req. Mail'
    },
    searchTableHeader: {
      hcName: 'Hiring Client',
      scName: 'Subcontractor',
      status: 'Status',
      state: 'State'
    },
    renderSC: {
      labelCompanyName: 'SC Name',
      labelContactName: 'SC Contact Name',
      labelMail: 'SC Contact Email',
      labelPhone: 'SC Contact Phone',
      labelTaxId: 'SC Tax Id',
      labelSourceSystemId: 'SC Source System ID',
      labelRequestorName: 'Requestor Name',
      labelRequestorEmail: 'Requestor Email',
      wrongEmailFormat: 'Wrong email format',
      requiredValidation: 'Required',
    },
    addSCModal: {
      title: 'Upload new subcontractor(s)',
      uploadFileLabel: 'Upload SC File List',
      uploadManuallyLabel: 'Upload SC List Manually',
      chooseHCLabel: 'Choose a HC to link with these subcontractors:',
      buttonInvite: 'Invite',
      buttonCancel: 'Cancel',
      scFileLabel: 'Please choose a file to upload (.xlsx/.xls/.csv)',
      validation: {
        dataFyleIncorrect: 'The data file type is not correct',
        dataFyleNotUploaded: 'The data file was not uploaded'
      }
    },
    searchSCModal: {
      title: 'Subcontractor Search Results',
      buttonCancel: 'Close'
    },
    filter: {
      title: 'Filter Subcontractors',
      keywordsLabel: 'Keywords',
      keywordsPlaceholder: 'Keywords',
      vendorIdLabel: 'Vendor ID',
      vendorIdPlaceholder: 'Vendor ID',
      HCLabel: 'Associated HC',
      HCPlaceholder: 'Hiring Client',
      SCStatusLabel: 'Subcontractor Status',
      SCStatusPlaceholder: 'Status',
      tradeLabel: 'Trade',
      tradePlaceholder: 'Trade',
      tierRatingLabel: 'Tier Rating',
      tierRatingPlaceholder: 'Tier Rating',
      stateLabel: 'Select State',
      countryLabel: 'Select Country',
      statePlaceholder: 'State',
      maxSingleLabel: 'Max Single Limit',
      maxSinglePlaceholder: 'Max Single Limit',
      maxAggregateLabel: 'Max Aggregate Limit',
      maxAggregatePlaceholder: 'Max Aggregate Limit'
    },
    actions: {
      error10001: 'Error: Server error. Please, refresh.',
      error10003: 'Error: invalid filter data. Please, adjust the filters values and try again.',
      error10004: 'Error: email already used. Please check the email field.',
      error10005: 'Error: This is an invalid session. Please, sign in again.',
      error10006: 'Error: You are not logged in. Please, sign in and try again.',
      error10007: 'Error: Session expired. Please, sign in and try again.',
      error10008: 'Error: current user does not exist. Please, login again with a valid user and try again.',
      error10011: 'Error: your current user is disabled. Please, contact an admin to enable this account and login again to continue.',
      error10013: 'Error: invalid email format. Please check the email field and write a valid email address.',
      error10014: 'Error: this is not a valid user type. Please, select a valid user type.',
      error10019: 'No Hiring clients found',
      error10155: 'Error: Please select Hiring Client/Holder.',
      errorDefault: 'Something went wrong, please try again.',
      errorConnection: 'Connection error - Please, check your Internet service.'
    }
  },
  hcProfile: {
    tabs: {
      tradeCategories: 'Trades',
      communicationTemplates: 'Templates',
      forms: 'Forms',
      projects: 'Projects',
      language: 'Language',
      workflow: 'Workflow',
      subContractor: 'Subcontractors',
      users: 'Users',
      notesTasks: 'Tasks/Notes',
      applications: 'Applications',
      procore: 'Procore'
    },
    tradeCategories: {
      tableHeaderValue: 'Value',
      tableHeaderDescripcion: 'Description',
      tableHeaderOrderIndex: 'Order',
      saveOrderButton: 'SAVE CHANGES',
      addTradeCategory: 'ADD TRADE CATEGORY',
      removeNewTradeCategory: 'REMOVE NEW TRADE CATEGORY ROW',
      actions: {
        error10001: 'Error: Server error. Please, refresh.',
        error10003: 'Error: invalid filter data. Please, adjust the filters values and try again.',
        error10004: 'Error: email already used. Please check the email field.',
        error10005: 'Error: This is an invalid session. Please, sign in again.',
        error10006: 'Error: You are not logged in. Please, sign in and try again.',
        error10007: 'Error: Session expired. Please, sign in and try again.',
        error10008: 'Error: current user does not exist. Please, login again with a valid user and try again.',
        error10011: 'Error: your current user is disabled. Please, contact an admin to enable this account and login again to continue.',
        error10013: 'Error: invalid email format. Please check the email field and write a valid email address.',
        error10014: 'Error: this is not a valid user type. Please, select a valid user type.',
        error10019: 'No Hiring clients found',
        error10155: 'Error: Please select Hiring Client/Holder.',
        errorDefault: 'Something went wrong, please try again.',
        errorConnection: 'Connection error - Please, check your Internet service.'
      }
    },
    template: {
      editTemplate: 'EDIT TEMPLATE',
      addNewTemplate: 'ADD NEW TEMPLATE',
      templateTitle: 'Template Title',
      templateDescription: 'Template Description'
    },
    projects: {
      viewProjects: 'View Contracts',
      editProject: 'Edit Project',
      editContract: 'Edit Contract',
      filterProjects: 'Filter Projects',
      addNewProject: 'Add New Project',
      projectView: {
        projectNumber: 'Project Number',
        projectStatus: 'Project Status',
        numberOfContracts: '# of Contracts',
        tradeTotal: 'Trade Total',
        contractsTitle: 'Contracts in project',
        contractNumber: 'Contract Number',
        startDate: 'Start Date',
        contractAmount: 'Contract Amount',
        noContractsFound: 'No contracts found for this project',
        scName: 'Subcontractor',
        noScText: 'Unassigned'
      },
      filter: {
        title: 'Filter projects',
        keywordsLabel: 'Keywords',
        keywordsPlaceholder: 'Keywords',
        tradeLabel: 'Trade',
        tradePlaceholder: 'Trade',
      },
      headers: {
        projectName: 'Project Name',
        tradeTotal: 'Trade Total',
        contractsAwarded: 'Contracts',
      },
      addProjectModal: {
        title: 'CREATE NEW PROJECT',
        titleEditProject: 'EDIT PROJECT',
        titleEditContract: 'EDIT CONTRACT',
        AddContractsTab: {
          title: 'ADD CONTRACTS',
          labelSubcontractor: 'Subcontractor',
          labelAddButton: 'ADD ROW',
          labelRemoveButton: 'Remove',
          labelContractNumber: 'Contract Number',
          labelContractAmount: 'Contract Amount',
          labelStartDate: 'Start Date',
          cancel: 'Cancel',
          finishButton: 'Finish',
          saveButton: 'Save',
          requiredValidation: 'Required',
          subcontractorPlaceholder: 'Subcontractor'
        },
        projectTab: {
          labelProjectName: 'Project Name',
          labelProjectName2: 'Optional Name',
          labelProjectStatus: 'Project Status',
          cancel: 'Cancel',
          continueButton: 'Continue',
          saveButton: 'Save',
          requiredValidation: 'Required',
          labelHolder: 'Holder',
          labelAccountManager: 'Account Manager',
          labelAddress1: 'Address',
          labelAddress2: 'Address line 2',
          labelCity: 'City',
          labelState: 'State',
          labelPostalCode: 'Postal Code',
          labelCountry: 'Country',
          labelReqSet: 'Require Set.',
          labelProjectNote: 'Project Note',
          labelAiWording: 'AI Wording',
          labelContactName: 'Contact Name',
          labelContactPhone: 'Contact Phone',
          labelProjectEmail: 'Email Address',
          labelProjectNumber: 'Project Number',
          labelCustomAttribute: 'Custom Attribute',
          labelProjectDescription: 'Project Description',
          labelStatus: 'Status'
        }
      },
      actions: {
        error10001: 'Error: Server error. Please, refresh.',
        error10003: 'Error: invalid filter data. Please, adjust the filters values and try again.',
        error10004: 'Error: email already used. Please check the email field.',
        error10005: 'Error: This is an invalid session. Please, sign in again.',
        error10006: 'Error: You are not logged in. Please, sign in and try again.',
        error10007: 'Error: Session expired. Please, sign in and try again.',
        error10008: 'Error: current user does not exist. Please, login again with a valid user and try again.',
        error10011: 'Error: your current user is disabled. Please, contact an admin to enable this account and login again to continue.',
        error10013: 'Error: invalid email format. Please check the email field and write a valid email address.',
        error10014: 'Error: this is not a valid user type. Please, select a valid user type.',
        error10019: 'No Hiring clients found',
        error10155: 'Error: Please select Hiring Client/Holder.',
        errorDefault: 'Something went wrong, please try again.',
        errorConnection: 'Connection error - Please, check your Internet service.'
      }
    },
  },
  scProfile: {
    header: {
      addButton: "Add Subcontractor",
      labelScName: 'Name',
      labelHcName: 'Hiring Client',
      labelTrade: "Main Trade",
      labelPrequalDate: "Last Prequal Date",
      labelPhoneNumber: "Phone Number",
      labelStatus: "Status",
      labelTierRating: "Tier Rating",
      labelSingleProjectLimit: "Single Project Limit",
      labelAggregateProjectExposure: "Aggregate Project Limit",
      labelCorpType: "Company type",
      labelTaxId: "Tax ID",
      viewRegistrationLinkBtn: "Registration Link",
      changeStatusBtn: "Change Status",
      changeSubName: "Change Name",
      labelHiringClient: "Hiring Client",
      labelSubcontractor: "Company",
      labelSubAddress: "Address",
      labelPhone: "Phone",
      changeTierBtn: "Change Tier",
      changeBasicDataBtn: 'Change Basic Data',
      riskRecommend: 'risk mitigation recommended',
      labelVendorId: 'Vendor ID',
      changeVendorId: "Change Vendor ID",
      dateLastModified: 'Date Last Modified',
      selectMoveToHiringClient: {
        placeholder: "Assign to",
        noOptionsMessage: "No options found.",
        loadingMessage: "Please wait a moment...",
        errorOnMove: 'Something went wrong, please try again.',
        confirmationOnMove: 'Are you sure you want to move the SubContractor?'
      }
    },
    tabs: {
      tradeContracts: 'Contracts',
      notesTasks: 'Notes/Tasks',
      forms: 'Forms',
      files: 'Files',
      financialInfo: 'Financial Info',
      users: 'USERS',
      references: 'REFERENCES',
      scoreCards: 'SCORECARDS',
      locations: 'Locations'
    },
    locations: {
      address: 'Address',
      city: 'City',
      state: 'State / Province',
      zipCode: 'Postal Code',
      contactName: 'Contact Name',
      comments: 'Comments',
      Primary: 'Primary',
      active: 'Active',
      view: 'Quick View',
      edit: 'Edit'
    },
    files: {
      viewFile: 'VIEW',
      deleteFile: 'DELETE',
      filterFiles: 'FILTER FILES',
      uploadFile: 'UPLOAD FILE',
      editFile: 'EDIT FILE',
      headers: {
        fileName: 'FILE NAME',
        uploadDate: 'UPLOAD DATE',
        expirationDate: 'EXPIRATION DATE',
        uploadedFrom: 'UPLOAD TYPE',
        formID:'FORM ID',
        fileDescription: 'FILE DESCRIPTION',
        isConfidential: 'INTERNAL'
      },
      filter: {
        title: 'Filter Files',
        keywords: 'Keywords',
        startDate: 'from',
        endDate: 'to',
        dateValidation: 'Invalid date range'
      },
      addModal: {
        title: 'UPLOAD NEW FILE',
        fileInputLabel: 'Please select a file',
        descriptionInputLabel: 'File Description',
        formSelectLabel: 'Please select a Form this file belongs to',
        checkboxLabel: 'This file contains financial data',
        checkBoxConfidentialLabel:'Internal / Confidential',
        buttonText: 'UPLOAD FILE',
        requiredValidation: 'Required'
      },
      actions: {
        error10003: 'Error: invalid filter data. Please, adjust the filters values and try again.',
        error10005: 'Error: This is an invalid session. Please, sign in again.',
        error10006: 'Error: You are not logged in. Please, sign in and try again.',
        error10007: 'Error: Session expired. Please, sign in and try again.',
        error10011: 'Error: your current file is disabled. Please, contact an admin to enable this account and login again to continue.',
        errorDefault: 'Something went wrong, please try again.'
      },
      editModal:{
        title: "EDIT FILE",
        expireDate: 'EXPIRE DATE',
        tempalte:'TEMPLATE',
        updateButtonText:'UPDATE',
        addButtonText:'ADD',
        cancelButtonText:'CANCEL',
      },
      expireDate: {
        addExpireDate: 'ADD EX DATE',
        editExpireDate: 'EDIT EX DATE',
        deleteExpireDate: 'DELETE EX DATE',
      }
    },
    references: {
      filterReferences: 'FILTER REFERENCES',
      addReference: 'ADD REFERENCE',
      editReference: 'Edit Reference',
      headers: {
        type: 'TYPE',
        companyName: 'COMPANY NAME',
        contactName: 'CONTACT NAME',
        contactPhone: 'CONTACT PHONE',
        contactEmail: 'CONTACT EMAIL',
        refDate: 'DATE'
      },
      filter: {
        title: 'Filter References',
        keywords: 'Keywords',
        type: 'Type'
      },
      modal: {
        addTitle: 'Add new reference',
        editTitle: 'Edit reference',
        typeLabel: 'Type',
        companyNameLabel: 'Company Name',
        savedFormLabel: 'Saved From',
        contactNameLabel: 'Contact Name',
        contactEmailLabel: 'Contact Email',
        contactPhoneLabel: 'Contact Phone',
        evaluationLabel: 'Evaluation',
        continueButton: 'CONTINUE',
        saveButton: 'UPLOAD REFERENCE',
        fileInputLabel: 'Add a file to this question',
        requiredValidation: 'Required',
        phoneErrorMessage: 'Invalid format',
        wrongEmailFormat: 'Wrong email format',
      },
      actions: {
        error10001: 'Error: Server error. Please, refresh.',
        error10003: 'Error: invalid  data. Please, adjust the values and try again.',
        error10004: 'Error: email already used. Please check the email field.',
        error10005: 'Error: This is an invalid session. Please, sign in again.',
        error10006: 'Error: You are not logged in. Please, sign in and try again.',
        error10007: 'Error: Session expired. Please, sign in and try again.',
        error10008: 'Error: current user does not exist. Please, login again with a valid user and try again.',
        error10011: 'Error: your current user is disabled. Please, contact an admin to enable this account and login again to continue.',
        error10013: 'Error: invalid email format. Please check the email field and write a valid email address.',
        error10014: 'Error: this is not a valid user type. Please, select a valid user type.',
        error10019: 'No Hiring clients found',
        error10155: 'Error: Please select Hiring Client/Holder.',
        errorDefault: 'Something went wrong, please try again.',
        errorConnection: 'Connection error - Please, check your Internet service.'
      }
    },
    registrationLinkModal: {
      title: 'Registration Link',
      closeBtn: 'Close'
    },
    changeStatusModal: {
      title: 'Change Subcontractor Status',
      statusLabel: 'Status',
      cancelBtn: 'Cancel',
      saveBtn: 'Save',
      resetStatusMessage: 'Please reset the Status for',
      resetStatusLabel: 'Do you want to reset the Status for'
    },
    changeTradesModal: {
      requiredValidation: 'Required',
      title: 'Change Subcontractor Trades',
      tradeLabel: 'Main Trade',
      secondaryTradeLabel: 'Second Trade',
      tertiaryTradeLabel: 'Third Trade',
      quaternaryTradeLabel: 'Fourth Trade',
      quinaryTradeLabel: 'Fifth Trade',
      cancelBtn: 'Cancel',
      saveBtn: 'Save',
    },
    changeVendorModal: {
      title: 'Change Hiring Client Vendor ID',
      label: 'Vendor ID',
      cancelBtn: 'Cancel',
      saveBtn: 'Save'
    },
    changeSubNameModal: {
      title: 'Change Subcontractor Name',
      nameLabel: 'Name',
      hiringClientLabel: 'Hiring client',
      cancelBtn: 'Cancel',
      saveBtn: 'Save'
    },
    changeTierModal: {
      title: 'Change Subcontractor Tier Rating',
      tierLabel: 'Tier Ratings',
      cancelBtn: 'Cancel',
      saveBtn: 'Save',
      requiredValidation: 'Required',
      singProjectLimit: 'Single Project Limit',
      aggProjectLimit: 'Aggregate Project Limit',
      comment: 'Comment'

    },
    changeBasicDataModal: {
      title: 'Change Subcontractor Basic Data',
      labelContactName: 'Contact Name',
      labelMail: 'Contact Email',
      labelPhone: 'Contact Phone',
      cancelBtn: 'Cancel',
      saveBtn: 'Save'
    },
    changeTaxIdModal: {
      title: 'Update Tax ID',
      cancelBtn: 'Cancel',
      saveBtn: 'Save',
      labelCountry: 'Country',
      labelTaxId: 'Tax ID'
    },
    ChangeLocationsModal: {
      editLocation: 'Edit Location',
      filterLocations: 'Filter Locations',
      addLocation: 'Add Location',
      viewLocations: 'VIEW LOCATION',
      modal: {
        titleCreate: 'Add New Location',
        titleEdit: 'Edit Location',
        labelTitle: 'Location',
        labelAddress: 'Address',
        labelCity: 'City',
        labelState: 'State / Province',
        labelProvTerr: 'Province / Terr',
        labelZipCode: 'Postal Code',
        labelCountry: 'Country',
        labelComments: 'Comments',
        labelActive: 'Active',
        labelPrimary: 'Primary Location',
        labelPrimaryShort: 'Primary',
        labelPhone: 'Phone',
        labelFax: 'Fax',
        labelContactName: 'Contact Name',
        labelContactNameShort: 'Name',
        labelContactEmail: 'Contact Email',
        labelContactEmailShort: 'Email',
        buttonSave: 'Save',
        buttonDelete: 'Delete',
        buttonCancel: 'Cancel',
        requiredValidation: 'Required',
        statesPlaceHolder: 'select State',
        provTerrPlaceHolder: 'select Province or Territory',
        countriesPlaceHolder: 'select Country',
        wrongEmailFormat: 'Wrong email format',
        wrongPhoneFormat: 'Invalid Phone format',
        emailPlaceholder: 'example@email.com',
        labelHiringClient: 'Hiring Client',
        hiringClientPlaceholder: 'Select Hiring Client'
      }
    },
    locationFilter: {
      title: 'Location Filter',
      keywordsLabel: 'Search by City, Comments or Contact Name',
      keywordsPlaceholder: 'Keywords',
      filterState: 'State',
      filterProvTerr: 'Province or Territory',
      filterCity: 'City',
      filterActive: 'Is Active',
      filterPrimary: 'Is Primary',
      searchByPrimary: 'Search by Primary Location',
      searchByActive: 'Search by Active Location',
      filterComments: 'Comments',
      filterContactName: 'Contact Name',
      statesPlaceHolder: 'select State',
      provTerrPlaceHolder: 'select Province or Territory',
    },
    notesTasks: {
      editNoteTask: 'Edit Task',
      filterNotesTasks: 'Filter Notes/Tasks',
      filterTasks: 'Filter Tasks',
      addNotesTasks: 'Add Note/Task',
      addTasks: 'Add Task',
      viewNotesTasks: 'VIEW TASK',
      modal: {
        titleCreate: 'Add New Note/Task',
        titleEdit: 'Edit Note/Task',
        titleCreateNote: 'Add New Note',
        titleEditNote: 'Edit Note',
        titleCreateTask: 'Add New Task',
        titleEditTask: 'Edit Task',
        labelTitle: 'Note Title',
        labelNoteTitle: 'Note Title',
        labelTaskTitle: 'Task Title',
        labelType: 'Type',
        labelAssign: 'Assign To',
        labelContactType: 'Contact Type',
        labelRoleAssign: 'Assign To Role',
        labelPriority: 'Urgency',
        labelDateDue: 'Due Date',
        labelNote: 'Note',
        labelDescription: 'Description',
        labelProject: 'Project',
        typePlaceholder: 'Select Type',
        assignedToPlaceholder: 'Select User',
        assignedToRolePlaceholder: 'Select Role',
        contactTypePlaceholder: 'Select Contact Type',
        priorityPlaceholder: 'Select Urgency',
        projectPlaceholder: 'Select Project',
        buttonCreate: 'Leave Note/Task',
        buttonCreateTask: 'Create Task',
        buttonCreateNote: 'Create Note',
        buttonEdit: 'Save Changes',
        buttonCancel: 'Cancel',
        requiredValidation: 'Required',
        bothValidation: 'Select User or Role',
      },
      filter: {
        title: 'Filter Notes/Tasks',
        taskTitle: 'Filter Tasks',
        noteTitle: 'Filter Note',
        nameLabel: 'Name',
        namePlaceholder: 'Name',
        keywordsLabel: 'Keywords',
        keywordsPlaceholder: 'Keywords',
        assignedToLabel: 'Assigned to',
        labelRoleAssign: 'Assign To Role',
        assignedToPlaceholder: 'User',
        assignedToRolePlaceholder: 'Role',
        typePlaceholder: 'Type',
        dueDateLabel: 'Due Date',
        enteredDateLabel: 'Entered Date',
        datePlaceholder: 'Date',
        statusPlaceholder: 'Status',
        contactTypeLabel: 'Contact Type',
        contactTypePlaceholder: 'Type',
        hiringClientPlaceholder: 'Hiring Client',
        createdBy: 'Created By',
        assignedToMyRoleLabel: 'Assigned to my role',
        assignedToMeLabel: 'Assigned to me',
        urgencyPlaceholder: 'Urgency',
      },
      headers: {
        noteTaskTitle: 'Name',
        taskName: 'TASK NAME',
        date: 'Date',
        assignedTo: 'Assigned To',
        hcName: 'Hiring Client',
        scName: 'Subcontractor',
        dueDate: 'DUE DATE',
        type: 'Type',
        completed: 'Status',
        contactType: 'Contact Type',
        enteredDate: 'Entered Date',
        noteOrTask: 'Note or Task',
        status: 'status',
        createdBy: 'Created By',
        comments: 'Comments',
        holderName: 'Holder',
        insuredName: 'Insured',
        description: 'Description',
        urgency: 'Urgency',
      },
    },
    financialInfo: {
      alerts: {
        formIncomplete: {
          title: 'Error',
          message: 'Associated form is incomplete. Please submit form.',
        }
      }
    }
  },
  formBuilder: {
    checkBoxLabel: 'Checkbox label',
    elements: 'Elements',
    section: 'Section',
    name: 'Name',
    formFeeLabel: 'Subcontractor Fee',
    sectionDivider: 'Section Divider',
    subSectionDivider: 'Sub Section Divider',
    checkbox: 'Checkbox',
    radioButton: 'Radio button',
    datePicker: 'Date Picker',
    fileUpload: 'File Upload',
    textbox: 'Text Box',
    header: 'Header',
    paragraph: 'Paragraph',
    dropDown: 'Drop Down',
    multiSelect: 'Multi Select',
    email: 'Email',
    phone: 'Phone',
    currency: 'Currency',
    emailHolder: 'email@domain.com',
    password: 'Password',
    hiringClientLabel: 'Hiring Client',
    formTitleLabel: 'Form title',
    formFeeLabelPlaceholder: 'New Subcontractor Fee',
    formTitlePlaceholder: 'NEW FORM',
    formDescriptionLabel: 'Form description',
    formDescriptionPlaceholder: 'New form description',
    newSectionLabel: 'New Section Title',
    subSectionLabel: 'Sub Section',
    textBoxPlaceholder: 'Input text',
    paragraphPlaceholder: 'Text area',
    phonePlaceholder: '(123) 123-1234',
    dropDownPlaceholder: '-- Select one --',
    multiSelectPlaceholder: '-- Select --',
    sourceOfFinancialStatementPlaceholder: '-- Select a source --',
    periodPlaceholder: '-- Select a period --',
    companyTypePlaceholder: '-- Select a type --',
    currencyPlaceholder: 'US $',
    formCreated: 'FORM CREATED',
    formOwner: 'FORM OWNER',
    previewForm: 'PREVIEW FORM',
    saveForm: 'SAVE FORM',
    clearForm: 'CLEAR FORM',
    Literal: "Label",
    Text: "Text Box",
    Currency: "Currency",
    Date: "Date Picker",
    CheckBox: "Check Box",
    FileUpload: "File Upload",
    Paragraph: "Paragraph",
    Email: "Email",
    SectionDivider: "Section",
    DropDown: "Drop Down",
    MultiSelect: "Multi Select",
    RadioButton: "Radio Button",
    Phone: "Phone",
    SubSectionDivider: "Sub Section",
    Sourceoffinancialstatement: "Source of Financial Statement",
    Financialstatementdate: "Financial Statement Date",
    Period: "Period",
    CompanyType: "Company Type",
  },
  formPreviewer: {
    title: 'Form Preview'
  },
  formList: {
    title: 'Forms',
    tableHeaderName: 'Form Name',
    tableHeaderFinUpdate: 'FinUpdate',
    tableHeaderId: 'Form ID',
    tableHeaderSubmittedBy: 'Submitted By',
    tableHeaderStartDate: 'Start Date',
    tableHeaderSubmissionDate: 'Submission Date',
    tableHeaderStatus: 'Status',
    tableItemViewSubmission: 'Form Submission',
    tableItemViewUsersLog: 'Users Log',
    tableHeaderCreator: 'Form Creator',
    tableHeaderDateCreater: 'Form Date',
    tableFormSubmissions: 'Form Submissions',
    tableFormSubmissionId: 'Form Submission ID',
    tableHeaderIsDefault: 'Default Form',
    noResults: 'No results found for this search criteria',
    buttonFilterForm: 'Filter Form',
    buttonFormSubmissions: 'Forms submissions',
    buttonAddForm: 'Add Form',
    buttonOptions: 'Options',
    buttonEditForm: 'Edit Form',
    buttonSendForm: 'Send Form',
    filter: {
      title: 'Filter Forms',
      keywordsLabel: 'Keywords',
      keywordsPlaceholder: 'Keywords',
      formCreatorLabel: 'Form Creator',
      formCreatorPlaceholder: 'Creator',
      formSCSentToPlaceholder: 'Subcontractor',
      sentToLabel: 'Sent To',
      buttonSearch: 'Search'
    },
    table: {
      sendForm: 'Send Form',
      editForm: 'Edit Form',
      formSubmissions: 'Form Submissions',
    },
    actions: {
      error10003: 'Error: invalid filter data. Please, adjust the filters values and try again.',
      error10005: 'Error: This is an invalid session. Please, sign in again.',
      error10006: 'Error: You are not logged in. Please, sign in and try again.',
      error10007: 'Error: Session expired. Please, sign in and try again.',
      error10011: 'Error: your current form is disabled. Please, contact an admin to enable this account and login again to continue.',
      errorDefault: 'Something went wrong, please try again.'
    }
  },
  templates: {
    title: 'Communication Templates',
    filterCT: 'Filter CT',
    filter: {
      title: 'Filter Communication Templates',
      keywordsLabel: 'Keywords',
      keywordsPlaceholder: 'Keywords',
      searchButton: 'Search'
    }
  },
  templateBuilder: {
    header: {
      nameLabel: "Template Name",
      subjectLabel: "Template Subject",
      activityLabel: "Template Activity",
      communicationTypeLabel: "Communication Type",
      fromAddressLabel: "From Address",
      emailPlaceHolder: "email@domain.com",
      ownerLabel: "Hiring Client",
      ownerPlaceholder: "Select a Hiring Client",
      commTypePlaceholder: "Select a Communication Type",
      activityPlaceholder: "Select Template Activity",
    },
    footer: {
      saveBtn: 'Save Template',
      discardBtn: 'Discard',
      dateLabel: "Template Created",
      creatorLabel: "Created By",
    }
  },
  mailComposer: {
    sendBtn: 'Send Mail',
    discardBtn: 'Discard',
    header: {
      hiringClientLabel: 'Hiring Client',
      recipientsLabel: 'To',
      recipientsPlaceholder: 'type here to search users',
      pickHCFirstLabel: '(Pick a HC first)',
      subjectLabel: 'Subject',
      subjectPlaceholder: 'subject',
      selectHCHolder: 'Select a Hiring Client',
      subcontractorLabel: 'Subcontractor',
      subcontractorPlaceholder: 'Select a Subcontractor',
      dueDateLabel: 'Due Date',
      taskDetailLabel: 'Task Detail',
      templateLabel: 'Email Template',
      templatePlaceholder: 'Select Template'
    },
    headerCF: {
      hiringClientLabel: 'Holder',
      recipientsLabel: 'To',
      recipientsPlaceholder: 'Emails (comma separated)',
      pickHCFirstLabel: '(Pick a Holder first)',
      subjectLabel: 'Subject',
      subjectPlaceholder: 'subject',
      selectHCHolder: 'Select a Holder',
      subcontractorLabel: 'Insured',
      subcontractorPlaceholder: 'Select a Insured',
      dueDateLabel: 'Due Date',
      taskDetailLabel: 'Task Detail',
      templateLabel: 'Email Template',
      templatePlaceholder: 'Select Template',
      projectsLabel: 'Project',
      selectProjectHolder: 'Select a Project',
    },
  },
  multipleMail: {
    sendDefaultMail: {
      title: 'There are unsent emails',
      html: `Do you wish to send the default template to them?`,
      confirmButton: 'Yes!',
      cancelButton: 'No',
    },
    mailForm: {
      mailSentOK: {
        title: 'Email Sent',
        text: 'Email sent to #1',
      },
      mailSentFail: {
        title: 'Email Not Sent',
        text: 'Error sending the email. Please try again',
      },
      fields: {
        insured: {
          label: 'Insured',
        },
        recipients: {
          label: 'To',
        },
        project: {
          label: 'Project',
        },
        template: {
          label: 'Email Template',
        },
        subject: {
          label: 'subject',
        },
        holder: {
          label: 'Holder',
        }
      },
      buttons: {
        send: 'Send Email'
      }
    }
  },
  logs: {
    filterLogs: 'Filter Logs',
    tableHeader: {
      UserName: 'User Name',
      Activity: 'Activity',
      TimeStamp: 'Date',
      DateLastModified: 'Date Last Modified',
      SubmissionDate: 'Submission Date ',
      UserEmail: 'User Email'
    },
    filter: {
      title: 'Users Log',
      keywordsLabel: 'Keywords',
      keywordsPlaceholder: 'Keywords',
      usernameLabel: 'User name',
      usernamePlaceholder: 'User name',
      systemModuleLabel: 'System Module',
      systemModulePlaceHolder: 'System Module',
      buttonSearch: 'Search'
    }
  },
  profile: {
    updateButton: "Update Information",
    associatedHC: "Associated Hiring Clients",
    associatedSC: "Associated Subcontractors",
    activityTitle: "User Activity",
  },
  tasks: {
    viewTaskModal: {
      createdBy: 'Created By',
      createdOn: 'Created On',
      assignedTo: 'Assigned To',
      urgency: 'Urgency',
      dueDate: 'Due Date',
      contactTypeLabel: 'Contact Type',
      projectLabel: 'Project',
      taskDescription: 'Task Description',
      assignButton: 'Assign task to me',
      completeButton: 'Mark task as completed',
      close: 'Close',
    }
  },
  video: {
    title: 'Prequal Tutorial Videos'
  },
  reviewApplications: {
    reviewApplicationsList: {
      subcontractorNameColumn: 'Subcontractor',
      primaryTradeColumn: 'Trade',
      addressColumn: 'Address',
      cityColumn: 'City',
      stateColumn: 'State',
      zipCodeColumn: 'ZipCode',
      previousWorkedForHCColumn: 'Previous Work',
      hiringClientProjectColumn: 'HC Project Name',
      hiringClientContactNameColumn: 'HC Contact Name',
      selectAllBtn: 'Select All',
      deSelectAllBtn: 'Deselect All',
      approveBtn: 'Approve',
      declineBtn: 'Decline',
    },
    applyForm: {
      subcontractorContactNameLabel: 'Contact Name',
      subcontractorContactPhoneLabel: 'Contact Phone Number',
      subcontractorContactEmailLabel: 'Contact Email',
      subcontractorNameLabel: 'Company Name',
      primaryTradeLabel: 'Main Trade',
      subcontractorFullAddressLabel: 'Full Address',
      subcontractorPhoneLabel: 'Phone Number',
      subcontractorTaxIdLabel: 'Tax ID',
      saveButton: 'Save',
      commentsLabel: 'General Comments',
      hasWorkedWithHCLabel: 'Previously worked for',
      previousProjectLabel: 'Previous Project Name',
      defaultHCLabel: 'Hiring Client',
      projectManagerNameLabel: 'Project Manager Name',
      projectManagerNamePlaceholder: 'John Doe',
      projectManagerEmailLabel: 'Project Manager Email',
      projectManagerEmailPlaceholder: 'john.doe@company.com',
      selectHCLoadingMessage: 'Loading...',
      selectHCNoOptionsMessage: 'No Companies found.',
      selectHCPlaceholder: 'Select a company to apply to',
    },
    errors: {
      error10000: 'Backend error',
      error10001: 'MSSQL error (uncategorized error)',
      error10003: 'Invalid Data (missing or wrong parameters)',
      error10005: 'No token provided',
      error10006: 'Failed to authenticate token',
      error10007: 'The token is expired',
      error10019: 'This Subcontractor was already associated with the current Hiring Client',
      error10156: 'You are already registered with "{10156}", please contact your admin.',
      error10157: 'The company is already registered with "{10157}", please contact your admin.',
      errorDefault: 'Error retrieving data',
      errorConnection: 'Connection error'
    },
    success: {
      submitted: 'Your prequalification application is pending approval. You will receive an email once "{hiringClientName}" approves your application.',
      autoApproved: 'The registration was successful. Please log in and complete the prequalification process.',
    }
  },
  holders: {
    title: 'Holders',
    holdersList: {
      addHolderButton: 'Add Holder',
      tableHeaderHolder: 'Holder',
      tableHeaderParentHolder: 'Parent Holder',
      tableHeaderRegistrationUrl: 'Registration URL',
      tableHeaderPortalUrl: 'Portal URL',
      tableHeaderContactName: 'Contact Name',
      tableHeaderContactPhone: 'Contact Phone',
      filterHolders: 'Filter Holders',
      viewHolders: 'VIEW HOLDER',
      editHolders: 'EDIT HOLDER',
      archiveHolder: 'UNARCHIVED',
      unArchiveHolder: 'ARCHIVED',
    },
    archiveAlert: {
      archiveTitle: 'Archive',
      unArchiveTitle: 'Unarchive',
      archiveText: 'archive',
      unArchiveText: 'unarchive',
      title: '$1 Holder',
      text: 'Are you sure you want to $1 Holder # $2?',
    },
    holdersProfile: {
      editBtn: 'Edit Profile',
      copyHolderBtn: 'Copy Holder',
      parentHolderLabel: 'Parent Holder',
      addressLabel: 'Address',
      intOfficeLabel: 'Int Office ID',
      contactNameLabel: 'Contact Name',
      contactPhoneLabel: 'Contact Phone',
      accountManagerLabel: 'Account Manager',
      departmentLabel: 'Department',
      initialFeeAndCreditsLabel: 'Initial Fee & Credits',
      addlFeeAndCreditsLabel: 'Addl Fee & Credits',
      emailAliasAddressLabel: 'Email Alias Address',
      emailAliasNameLabel: 'Email Alias Name',
      noteLabel: 'Note',
      pqFeesLabel: 'PQ Fees',
      tabs: {
        projects: 'Projects',
        tasks: 'Tasks',
        coverageTypes: 'Coverage Types',
        reqSets: 'Req Sets',
        contacts: 'Contacts',
        tags: 'Tags',
        customFields: 'Custom Fields',
        endorsements: 'Additional Reqs',
        documentTypes: 'Document Types',
        users: 'Users - old',
        workflow: 'Workflow',
        settings: 'Settings',
        holderUsers: 'Users',
        registration: 'Registration',
      }
    },
    filter: {
      title: 'Filter Holders',
      nameLabel: 'Name',
      namePlaceholder: 'Name',
      contactNameLabel: 'Contact Name',
      contactNamePlaceholder: 'Contact Name',
      searchButton: 'Search'
    },
    addHolderModal: {
      title: 'WELCOME TO THE HOLDER WIZARD',
      holderInfoTab: {
        labelPortalUrl: 'Portal URL',
        portalUrlSufix: '.portal.cf.com',
        labelHolderName: 'Holder Name',
        labelParentHolder: 'Parent Holder',
        labelAddress1: 'Address',
        labelAddress2: 'Address line 2',
        labelCity: 'City',
        labelState: 'State',
        labelPostalCode: 'Postal Code',
        labelCountry: 'Country',
        labelDepartment: 'Department',
        labelIntOfficeId: 'Int. Office ID',
        labelContactName: 'Contact Name',
        labelContactPhone: 'Contact Phone',
        labelContactEmail: 'Contact Email',
        labelAccountManager: 'Account Manager',
        labelInitialFee: 'Initial Fee',
        labelInitialCredits: 'Initial Credits',
        labelAddlFee: 'Addl Fee',
        labelAddlCredits: 'Addl Credits',
        labelPQInitialFee: 'PQ Initial Fee',
        cancelButton: 'Cancel',
        saveButton: 'Save',
        selectManagerPlaceholder: 'Select Manager',
        selectCountryPlaceholder: 'Select Country',
        selectStatePlaceholder: 'Select State',
        labelCompanyLogo: 'Click here to upload your company logo',
        departmentsPlaceholder: 'Select a department',
        portalHomeText: {
          label: `Portal Home Text`,
          ph: ``,
        },
        note: {
          label: `Note`,
          ph: ``,
        },
        portalFAQ: {
          label: `Portal FAQ`,
          ph: ``,
        },
        labelEmailAliasName: 'Email Alias Name',
        labelEmailAliasAddress: 'Email Alias Address',
      }
    },
    copyHolders: {
      title: 'Copy to Holders',
      copyColumn: 'Copy',
      holderNameColumn: 'Holder Name',
      optionsColumn: 'Options',
      copyToAllBtn: 'Copy to All Holders',
      copyToSelectedBtn: 'Copy to Selected Holders',
      copyHolderMessage: 'Are you sure you want to continue? Any preexisting configuration for the selected holders will be overridden'
    }
  },
  insured: {
    title: 'Insured',
    addInsuredModal: {
      title: 'WELCOME TO THE INSURED WIZARD',
      insuredInfoTab: {
        labelInsuredName: 'Insured Name',
        labelLegalName: 'Legal name',
        labelAddress1: 'Address',
        labelAddress2: 'Address line 2',
        labelCity: 'City',
        labelState: 'State',
        labelPostalCode: 'Postal Code',
        labelCountry: 'Country',
        labelContactName: 'Contact Name',
        labelContactPhone: 'Contact Phone',
        labelContactFax: 'Contact Fax',
        labelContactEmail: 'Contact Email',
        labelTaxId: 'Tax ID',
        labelVendor: 'Vendor Number',
        labelDescription: 'Description',
        labelUniqueId: 'Unique ID',
        labelHolder: 'Holder',
        labelNote: 'Note',
        cancelButton: 'Cancel',
        saveButton: 'Save'
      }
    },
    addTagToInsuredModal: {
      title: 'Add Tag',
      closeBtn: 'Close',
    },
    insuredList: {
      nameColumn: 'Insured',
      vendorColumn: 'Vendor #',
      projectColumn: 'Project',
      holderColumn: 'Holder',
      statusColumn: 'Status',
      stateColumn: 'State',
      viewProjects: 'View Projects',
      viewHolders: 'View Holders',
      editInsured: 'Edit Insured',
      viewInsured: 'View Insured',
      filterBtn: 'Filter',
      addBtn: 'Add Insured',
      archiveAction: {
        archive: 'Archive',
        unArchive: 'Unarchive',
      },
      archiveAlert: {
        title: {
          archive: 'Archive',
          unArchive: 'Unarchive',
        },
        text: {
          archive: 'archive',
          unArchive: 'unarchive',
        },
        description: 'Are you sure you want to $1 Insured # $2?',
        titleText: '$1 Insured',
      },
      popOver: {
        projectsTitle: 'Projects',
        holdersTitle: 'Holders',
      },
      filter: {
        title: 'Filter Insured',
        insuredLabel: 'Insured',
        projectLabel: 'Project',
        holderLabel: 'Holder',
        stateLabel: 'State',
        countryLabel: 'Country',
        vendorLabel: 'Vendor',
        vendorNumberLabel: 'Vendor #',
        tagsLabel: 'Tags',
      },
    },
    insuredView: {
      tabs: {
        coverages: 'Coverages',
        waivers: 'Waivers',
        tasks: 'Tasks',
        notes: 'Notes',
        contacts: 'Contacts',
        finance: 'Finance',
        projects: 'Projects',
        documents: 'Documents',
        agencies: 'Producers',
        mailActivity: 'Emails',
      },
      editBtn: 'Edit Insured',
      legalNameLabel: 'Legal Name',
      addressLabel: 'Address',
      contactNameLabel: 'Contact Name',
      contactPhoneLabel: 'Contact Phone',
      contactFaxLabel: 'Contact Fax',
      contactEmailLabel: 'Contact Email',
      taxIdLabel: 'Tax ID',
      noteLabel: 'Note',
    },
  },
  contacts: {
    title: 'Contacts',
    contactsList: {
      filterBtn: 'Filter',
      addBtn: 'Add Contact',
      editContact: 'Edit Contact',
      filter: {
        title: "Contacts Filter",
        firstNameLabel: 'First Name',
        lastNameLabel: 'Last Name',
        entityLabel: 'Entity',
        entityTypeLabel: 'Entity Type',
        contactTypeLabel: 'Contact Type',
      },
      firstNameColumn: 'First Name',
      lastNameColumn: 'Last Name',
      phoneColumn: 'Phone',
      mobileColumn: 'Mobile Phone',
      emailColumn: 'Email',
      entityColumn: 'Entity',
      entityTypeColumn: 'Entity Type',
      contactTypeColumn: 'Contact Type',
    },
    addContactModal: {
      title: 'Add Contact',
      titleEdit: 'Edit Contact',
      labelFirstName: 'First Name',
      labelLastName: 'Last Name',
      labelPhone: 'Phone',
      labelMobile: 'Mobile',
      labelEmail: 'Email',
      labelTitle: 'Title',
      labelContactType: 'Contact Type',
      cancelButton: 'Cancel',
      saveButton: 'Save'
    },
    errors: {
      error10000: 'Backend error',
      error10001: 'MSSQL error (uncategorized error)',
      error10003: 'Invalid Data (missing or wrong parameters)',
      error10005: 'No token provided',
      error10006: 'Failed to authenticate token',
      error10007: 'The token is expired',
      error10019: 'No Contacts found',
      errorDefault: 'Error retrieving contacts',
      errorConnection: 'Connection error'
    }
  },
  certFocusProjects: {
    title: 'Projects',
    projectsList: {
      editProject: 'Edit Project',
      viewProject: 'View Project',
      archivedProject: 'Archived',
      unarchivedProject: 'Unarchived',
      projectNumberColumn: 'Number',
      projectColumn: 'Project',
      holderColumn: 'Holder',
      CompliantInsuredsColumn: 'Compliant Insureds',
      NonCompliantInsuredsColumn: 'Non-Compliant Insureds',
      EscalatedInsuredsColumn: 'Escalated Insureds',
      cityColumn: 'City',
      stateColumn: 'State',
      statusColumn: 'Status',
      myListColumn: 'My list',
      filterBtn: 'Filter',
      addBtn: 'Add Project',
      filter: {
        title: 'Filter Projects',
        projectLabel: 'Project',
        stateLabel: 'State',
        holderLabel: 'Holder',
        myListLabel: 'My List',
        keywordsLabel: 'Keywords',
        archivedLabel: 'Archived',
        myListOptionLabels: {
          onMyListOption: 'Only on my list', 
          notOnMylistOption: 'Not on my list'
        }
      }
    },
    errors: {
      error10000: 'Backend error',
      error10001: 'MSSQL error (uncategorized error)',
      error10003: 'Invalid Data (missing or wrong parameters)',
      error10004: 'This email is already in use',
      error10005: 'No token provided',
      error10006: 'Failed to authenticate token',
      error10007: 'The token is expired',
      errorDefault: 'Server error',
      errorConnection: 'Connection error'
    },
    projectView: {
      tabs: {
        insureds: 'Insureds',
        requirements: 'Requirements',
        certText: 'Cert Text',
        users: 'Users',
        attachments: 'Attachments'
      },
      holderLabel: 'Holder',
      addressLabel: 'Address',
      emailLabel: 'Email',
      requireSetLabel: 'Requirement Set',
      projectNoteLabel: 'Project Note',
      contactNameLabel: 'Contact Name',
      contactPhoneLabel: 'Contact Phone',
      projectNumberLabel: 'Project Number',
      projectEmailLabel: 'Contact Email ',
      customAttributeLabel: 'Custom Attribute',
      projectDescriptionLabel: 'Project Description',
      archivedLabel: 'Archived',
      projectName2Label: 'Optional Name',
      editBtn: 'Edit Project',
      addToMyListBtn: 'Add to My List',
      removeFromMyListBtn: 'Remove from my list',
      accountManagerLabel: 'Account Manager',
      projectAIWordingLabel: 'AI Wording'
    },
    updateCustomFieldsModal: {
      cancelButton: 'Cancel',
      uploadButton: 'Upload',
      downloadTemplate: 'Download Template',
      requiredValidation: 'Required',
      fileTypeValidation: 'This file has to be a csv.',
      error: 'There is a file format or template error, please reupload the document.',
      errorConnection: 'Connection error - Please, check your Internet service.',
      successMessage: 'Your file has been successfully processed.'
    }
  },
  tags: {
    title: 'Tags',
    tagsList: {
      filterBtn: 'Filter',
      addBtn: 'Add Tag',
      editTag: 'Edit Tag',
      filter: {
        title: "Tags Filter",
        nameLabel: 'Tag Name',
        archivedLabel: 'Archived',
        namePlaceholder: 'Tag name',
        archivedPlaceholder: 'N/A',
      },
      headers: {
        nameColumn: 'Name',
        orderColumn: 'Display Order',
        archivedColumn: 'Archived'
      }
    },
    addTagModal: {
      title: 'Add Tag',
      titleEdit: 'Edit Tag',
      labelName: 'Tag Name',
      labelOrder: 'Display Order',
      labelArchived: 'Archived',
      cancelButton: 'Cancel',
      saveButton: 'Save'
    },
    errors: {
      errorDefault: 'Error retrieving Tags',
      errorConnection: 'Connection error',
      postErrorDefault: 'There was an error saving the Tag, please try again.'
    }
  },
  customFields: {
    title: 'Custom Fields',
    customFieldsList: {
      filterBtn: 'Filter',
      addBtn: 'Add Custom Field',
      editField: 'Edit Field',
      filter: {
        title: 'Filter Custom Inputs',
        nameLabel: 'Name',
        inputTypeLabel: 'Input Type',
        displayOrderLabel: 'Display Order',
        archivedLabel: 'Archived',
        archivedPlaceholder: 'N/A',
      },
      headers: {
        propertyName:'Property Name',
        nameColumn: 'Name',
        inputTypeColumn: 'Input Type',
        inputValuesColumn: 'Input Values',
        orderColumn: 'Display Order',
        archivedColumn: 'Archived'
      }
    },
    addCustomFieldModal: {
      title: 'Add Custom Field',
      titleEdit: 'Edit Custom Field',
      labelName: 'Field Name',
      labelType: 'Field Type',
      labelValues: 'Input Values',
      placeholderValues: 'value1,value2,value3...',
      labelOrder: 'Display Order',
      labelArchived: 'Archived',
      cancelButton: 'Cancel',
      saveButton: 'Save'
    },
    errors: {
      errorDefault: 'Error retrieving Custom Fields',
      errorConnection: 'Connection error',
      postErrorDefault: 'There was an error saving the Custom Field, please try again.'
    }
  },
  documentTypes: {
    nameColumn: 'Name',
    expiresColumn: 'Expires',
    archiveColumn: 'Archive',
    editDocumentType: 'Edit document type',
    filterBtn: 'Filter document type',
    addBtn: 'Add document type',
    filter: {
      title: 'Filter document type',
      nameLabel: 'Name',
      expirePeriodLabel: 'Expired Period',
      archivedLabel: 'Archived',
    },
    addModal: {
      title: 'Add Document Type',
      titleEdit: 'Edit Document Type',
      labelName: 'Name',
      labelExpires: 'Expiration Period',
      labelExpireAmount: 'Expiration Amount',
      placeholderExpires: '00',
      labelArchive: 'Archived',
      cancelButton: 'Cancel',
      saveButton: 'Save'
    }
  },
  holderSettings: {
    labelDataEntryRules: 'Data Entry Rules',
    labelRevisedWordingPresent: 'Revised Wording Present',
    labelCompliantAndAccepted: 'Compliant & Accepted',
    labelRevisedDates: 'Revised Dates',
    labelNonDateFields: 'Non-DateFields',
    labelApplyingCertificates: 'Applying Certificates',
    allowDocumentAutoProcess: 'Allow Document Auto Process',
    allowURLDocumentUploads: 'Allow URL Document Uploads',
    hawkiExtractDailyLimit: 'Hawk-i Extract Daily Limit',
    errors: {
      errorDefault: 'Error retrieving settings.',
      errorConnection: 'Connection error',
      postErrorDefault: 'There was an error saving the settings, please try again.'
    }
  },
  projectInsureds: {
    title: 'Project Insureds',
    projectInsuredsList: {
      filterBtn: 'Filter',
      addBtn: 'Add Insured',
      editInsured: 'Edit',
      viewInsured: 'View Insured',
      viewProject: 'View Project',
      viewCoverage: 'View Coverages',
      viewCerts: 'View Certs',
      upload: 'Upload',
      copyUrl: 'Copy URL',
      exempted: 'Exempted',
      nonExempted: 'Non-Exempted',
      unarchived: 'Unarchived',
      archived: 'Archived',
      filter: {
        title: 'Filter Insureds',
        insuredNameLabel: 'Insured',
        projectNameLabel: 'Project',
        holderNameLabel: 'Holder',
        myListLabel: 'My List',
        statusLabel: 'Compliance Status',
        statusPlaceholder: 'N/A',
        keywordNameLabel: 'keyword',
        archivedLabel: 'Archived',
        coverageAttributeStatusLabel: 'Coverage Attribute Status',
      },
      headers: {
        insuredIdColumn: 'Vendor #',
        insuredColumn: 'Insured',
        statusColumn: 'Compliance Status',
        stateColumn: 'State',
        earliestExpirationDate: 'Expire Date',
        projectNumberColumn: 'Project #',
        projectNameColumn: 'Project Name',
        requirementSetNameColumn: 'Requirement Set',
      }
    },
    addProjectinsuredsModal: {
      title: 'ADD INSURED TO PROJECT',
      titleEdit: 'EDIT PROJECT INSURED',
      labelInsured: 'Insured',
      labelStatus: 'Status',
      labelSearch: 'search insureds',
      cancelButton: 'Cancel',
      saveButton: 'Save',
      createInsuredButton: 'Create Insured',
      reqSet: {
        label: 'Requirement Set',
        ph: 'Select a requirement set',
      },
      modal: {
        changeReqSet: {
          title: 'Edit Requirement Set',
          body: `<p>Are you sure you want change the requirement set? </p><p><h5>This action will wipe all data entry from coverages and certificates that don't match the new requirement set.</h5></p>`,
        }
      },
    },
    errors: {
      error10005: 'Error: This is an invalid session. Please, sign in again.',
      error10006: 'Error: You are not logged in. Please, sign in and try again.',
      error10007: 'Error: Session expired. Please, sign in and try again.',
      error10011: 'Error: your current user is disabled. Please, contact an admin to enable this account and login again to continue.',
      error10019: 'No results found.',
      error10130: 'The insured is already added to this project.',
      errorDefault: 'Something went wrong, please try again.',
      errorConnection: 'Connection error - Please, check your Internet service.',
    }
  },
  customTerminology: {
    customTerminologyList: {
      nameColumn: 'Name',
      codeColumn: 'Code',
      displayOrderColumn: 'Display Order',
      deficiencyMessageColumn: 'Deficiency Message',
      deficiencyCodeColumn: 'Deficiency Code',
      removeCustomTerminology: 'Remove Custom Terminology',
      editCustomTerminology: 'Edit Custom Terminology',
      archivedColumn: 'Archived',
      filterBtn: 'Filter Custom Terminology',
      addBtn: 'Add Custom Terminology',
      filter: {
        title: 'Custom Terminology Filter',
        nameLabel: 'Name',
        codeLabel: 'Code',
        archivedLabel: 'Archived',
      },
    },
  },
  coverageTypes: {
    coverageTypesList: {
      nameColumn: 'Name',
      codeColumn: 'Code',
      displayOrderColumn: 'Display Order',
      deficiencyMessageColumn: 'Deficiency Message',
      deficiencyCodeColumn: 'Deficiency Code',
      removeCoverageType: 'Remove Coverage Type',
      restoreCoverageType: 'Restore Coverage Type',
      editCoverageType: 'Edit Coverage Type',
      archivedColumn: 'Archived',
      filterBtn: 'Filter Coverage Type',
      addBtn: 'Add Coverage Type',
      filter: {
        title: 'Coverage Type Filter',
        nameLabel: 'Name',
        codeLabel: 'Code',
        archivedLabel: 'Archived',
      },
    },
    addModal: {
      title: 'Add Coverage Type',
      titleEdit: 'Edit Coverage Type',
      nameLabel: 'Name',
      codeLabel: 'Code',
      displayOrderLabel: 'Display Order',
      deficiencyMessageLabel: 'Deficiency Message',
      deficiencyCodeLabel: 'Deficiency Code',
      archivedLabel: 'Archived',
      cancelButton: 'Cancel',
      saveButton: 'Save',
      findLabel: 'Coverage Type',
    },
    deleteModal: {
      title: 'Confirm Coverage Type deletion',
      hasProjects: 'This coverage type has associated active projects, are you sure you want to delete it?',
      cancelButton: 'Cancel',
      saveButton: 'Delete',
    },
    attributes: {
      title: 'Attributes',
      attributesList: {
        addButton: 'Add Attribute',
        filterBtn: 'Filter',
        editAttribute: 'Edit Attribute',
        filter: {
          title: 'Filter Attributes',
        },
        headers: {
          attributeNameColumn: 'Attribute Name',
          archivedColumn: 'Archived',
          editColumn: 'Edit Attribute',
        }
      },
      addAttributeModal: {
        title: 'ADD ATTRIBUTE',
        titleEdit: 'EDIT ATTRIBUTE',
        attributeNameLabel: 'Attribute Name',
        ocrFieldCaptionLabel: 'OCR Field Caption',
        archivedLabel: 'Archived',
        cancelButton: 'Cancel',
        saveButton: 'Save'
      },
    }
  },
  search: {
    title: 'Search Results',
    buttonFilterForm: 'Criteria',
    filter: {
      keywordLabel: 'Keyword',
      complianceLabel: 'Compliance',
      holderLabel: 'Holder',
      tierRatingLabel: 'Tier Rating',
      projectLabel: 'Project',
      coverageLabel: 'Coverage',
      insuredLabel: 'Insured',
      coverageStatusLabel: 'Coverage Status',
      stateLabel: 'State',
      expirationDatesLabel: 'Expiration Dates',
      customerUniqueIdLabel: 'Customer Unique ID',
      insurerLabel: 'Insurer',
      includeArchivedLabel: 'Include Archived',
    },
    tabs: {
      insureds: 'Insureds',
      projects: 'Projects',
      holders: 'Holders',
      contacts: 'Contacts',
      agencies: 'Producers'
    },
    errors: {
      error10005: 'Error: This is an invalid session. Please, sign in again.',
      error10006: 'Error: You are not logged in. Please, sign in and try again.',
      error10007: 'Error: Session expired. Please, sign in and try again.',
      error10011: 'Error: your current user is disabled. Please, contact an admin to enable this account and login again to continue.',
      error10019: 'No results found.',
      error10130: 'The insured is already added to this project.',
      errorDefault: 'Something went wrong, please try again.',
      errorConnection: 'Connection error - Please, check your Internet service.',
    }
  },
  endorsements: {
    endorsementsList: {
      filter: {
        title: 'Additional Requirement Filter',
        nameLabel: 'Name',
        urlLabel: 'URL',
        codeLabel: 'Code',
        alwaysVisibleLabel: 'Always Visible',
      },
      addEndorsementModal: {
        title: 'Add Additional Requirement',
        titleEdit: 'Edit Additional Requirement',
        nameLabel: 'Name',
        displayOrderLabel: 'Display order',
        urlLabel: 'URL',
        codeLabel: 'Code',
        alwaysVisibleLabel: 'Always Visible',
        cancelButton: 'Cancel',
        saveButton: 'Save',
      },
      nameColumn: 'Additional Requirement',
      urlColumn: 'URL',
      displayOrderColumn: 'Display Order',
      codeColumn: 'Code',
      alwaysVisibleColumn: 'Always Visible',
      editAnchor: 'Edit Additional Requirement',
      filterBtn: 'Filter Additional Requirement',
      addBtn: 'Add Additional Requirement',
    },
  },
  holderRequirementSets: {
    list: {
      filter: {
        title: 'Requirement Sets Filter',
        labelName: 'Name',
        labelDescription: 'Description',
        labelHolder: 'Holder',
        labelArchived: 'Archived',
        labelHolderSetId: 'Holder Set ID',
        holderPlaceholder: 'Select Holder',
        archivedPlaceholder: 'N/A',
        holderSetIdPlaceholder: 'Select ID',
      },
      duplicate: {
        title: 'Duplicate Req. Set',
        description: 'Are you sure you want to duplicate this Req.Set. ?',
        cancelButton: 'Cancel',
        confirmButton: 'Confirm',
      },
      setAsDefault: {
        title: 'Set Requirement Set As Default',
        description: 'Are you sure you want to set this Requirement Set as default?',
        unsetTitle: 'Unset Requirement Set Default',
        unsetDescription: 'Are you sure you want to unset this Requirement Set?',
      },
      pauseRequestProcess: {
        label: 'Pause workflow request process',
        title: 'Workflow Request Process',
        startText: 'start',
        pauseText: 'pause',
        text: 'Are you sure you want to $1 workflow request process?',
      },
      addModal: {
        title: 'Add Requirement Set',
        titleEdit: 'Edit Requirement Set',
        nameLabel: 'Set Name',
        holderLabel: 'Holder',
        holderSetIdLabel: 'Holder Set ID',
        archivedLabel: 'Archived',
        templateLabel: 'Template',
        descriptionLabel: 'Description',
        holderSetIdPlaceholder: 'Select ID',
        cancelButton: 'Cancel',
        saveButton: 'Save',
      },
      nameColumn: 'Set Name',
      descriptionColumn: 'Description',
      viewLinkColumn: 'View',
      editLinkColumn: 'Edit',
      downloadLinkColumn: 'Download',
      copyLinkColumn: 'Duplicate',
      setAsDefaultColumn: 'Set As Default',
      unsetDefaultColumn: 'Unset Default',
      holderColumn: 'Holder',
      holderSetIdColumn: 'Holder Set ID',
      archivedColumn: 'Archived',
      addBtn: 'Add Req. Set',
      filterBtn: 'Filter Req. Set',
    },
    details: {
      backToList: 'Back to Requirement Sets List',
      nameLabel: 'Name',
      descriptionLabel: 'Description',
      systemLabel: 'Source System Name',
      editInformationBtn: 'Edit Basic Information',
      requirementsTitle: 'Requirements',
      endorsementsTitle: 'Additional Requirements',
      noEndorsements: 'No Additional Requirement found',
      addRequirementGroupBtn: 'Add Requirement Group',
      addRequirementBtn: 'Add Requirement',
      addEndorsementBtn: 'Add Additional Requirement',
      attachmentsTitle: 'Attachments',
      addAttachmentBtn: 'Add Attachment',
      noAttachment: 'No Attachments Found',
      noRequirementGroup: 'No Requirement Groups Found',
      addAttachmentModal: {
        titleText: 'Add Attachment',
        nameLabel: 'Attachment Name',
        fileLabel: 'Select File',
        saveButton: 'Upload',
        cancelButton: 'Cancel',
      },
      addRequirementGroupModal: {
        titleText: 'Add Group',
        editText: 'Edit Group',
        nameLabel: 'Group Name',
        coverageLabel: 'Coverage Type',
        saveButton: 'Save',
        cancelButton: 'Cancel',
      },
      addRequirementModal: {
        titleText: 'Requirement',
        attributeLabel: 'Attribute',
        conditionLabel: 'Condition',
        valueTypeLabel: 'Value Type',
        valueLabel: 'Value',
        deficiencyTextLabel: 'Deficiency Text',
        deficiencyTypeLabel: 'Deficiency Type',
        saveButton: 'Save',
        cancelButton: 'Cancel',
      },
      addEndorsementModal: {
        titleText: 'Add Additional Requirement',
        nameLabel: 'Additional Requirement',
        saveButton: 'Save',
        cancelButton: 'Cancel',
      },
    },
  },
  holderRequirementSetsView: {
    editBtn: 'Edit Requirement Set',
    filterBtn: 'Filter Rules',
    addBtn: 'Add Rule',
    backToList: 'Back to Requirement Sets List',
    holderLabel: 'Holder',
    holderIdLabel: 'Holder ID',
    archivedLabel: 'Archived',
    list: {
      coverageColumn: 'Coverage',
      attributeColumn: 'Attribute',
      conditionColumn: 'Condition',
      valueColumn: 'Value',
      typeColumn: 'Type',
      editColumn: 'Edit Requirement',
      deleteColumn: 'Delete Requirement',
    },
    filter: {
      title: 'Requirement Filter',
      conditionLabel: 'Condition',
      deficiencyTypeLabel: 'Deficiency Type',
      coverageLabel: 'Coverage',
      attributeLabeL: 'Attribute',
    },
    addEndorsementModal: {
      title: 'Select Additional Requirement',
      closeBtn: 'Close',
    },
    actions: {
      error20001: 'Rule already in this rule group',
      error10005: 'Please, login again',
      error10006: 'This session is invalid. Please, login again.',
      error10007: 'This session has expired. Please, login again.',
      error10009: 'Invalid username or password',
      error10011: 'This user was disabled. Please, contact an admin to enable it.',
      errorDefault: 'Server error',
      errorConnection: 'Connection error'
    }
  },
  attachments: {
    list: {
      attachmentNameColumn: 'Attachment Name',
      nameColumn: 'File Name',
      documentTypeColumn: 'Document Type',
      dateUploadedColumn: 'Date Uploaded',
      uploadedByColumn: 'Uploaded By',
      holderNameColumn: 'Holder',
      projectColumn: 'Project',
      projectNumberColumn: 'Project #',
      documentStatusColumn: 'Document Status',
      downloadLink: 'Download',
      addBtn: 'Add Attachment',
      filterBtn: 'Filter Documents',
      processDocument: 'Process',
      documentColumn:'Document ID',
      topLayerColumn:'Top Layer',
      addModal: {
        title: 'Add Attachment',
        projectLabel: 'Project',
        nameLabel: 'Attachment Name',
        fileLabel: 'Select File',
        saveButton: 'Upload',
        cancelButton: 'Cancel',
      },
    },
    filter: {
      title: 'Documents Filter',
      fileNameLabel: 'File Name',
      holderLabel: 'Holder',
      dateCreatedLabel: 'Date Created',
      projectLabel: 'Project',
    },
  },
  processing: {
    selectHolderAndProject: 'You must select a Holder, a Project and upload the Document to continue.',
    filter: {
      holderTypeAheadLabel: 'Holder',
      projectTypeAheadLabel: 'Project',
      filterButton: 'Filter',
      toggleViewer: 'Toggle Viewer',
    },
    deficiencyViewer: {
      title: 'Deficiency Viewer',
      majorDeficienciesText: 'Major Deficiencies',
      minorDeficienciesText: 'Minor Deficiencies',
      endorsementsText: 'Endorsements',
      noDeficienciesText: 'No Deficiencies Found',
      showRequirementsBtn: 'Show requirements',
      waiveBtn: 'Waive',
      acceptBtn: 'Accept',
    },
    dataEntry: {
      title: 'Data Entry',
      labelPdfFile: 'Click here to upload your file',
      labelRevisedTextPresent: 'Revised Text Present',
      labelAccordForm: 'Accord Form',
      labelEffectiveDate: 'Effective Date',
      labelExpirationDate: 'Expiration Date',
      labelAdditionalInsured: 'Additional Insured',
      labelLimit1: 'Limit 1',
      cancelButton: 'Cancel',
      continueButton: 'Continue',
      saveButton: 'Save',
      saveAndAddMoreButton: 'Save & Add More',
      requiredValidation: 'Required',
      duplicatedValidation: 'Duplicated',
      fileTypeValidation: 'This file has to be a PDF',
      mustBeNumericValue: 'This field has to be numeric',
      faxErrorMessage: 'Invalid format',
      phoneErrorMessage: 'Invalid format',
      wrongEmailFormat: 'Wrong email format',
      postalCodeErrorMessage: 'Postal Code Format: 00000',
      postalCodeErrorMessageCanada:'Postal Code Format: A1A 1A1',
      invalidValueErrorMessage: 'Invalid Value',
      labelAttribute: 'Attribute',
      labelValue: 'Value',
      noCoverages: 'No coverages found for this project',
      wrongFileMessageText: 'Incorrect file format, upload a PDF document',
      wrongFileMessageTitle: 'Wrong Format',
      insurerRowLabel: 'Insurer',
      policyRowLabel: 'Policy',
      addInsurerBtn: 'Add insurer',
      createInsurerBtn: 'Create insurer',
      coverageRowInsurerLabel: 'Insurer',
      coverageRowCoverageLabel: 'Coverage',
      coverageRowPolicyLabel: 'Policy',
      coverageRowEffectiveDateLabel: 'Effective Date',
      coverageRowExpirationDateLabel: 'Expiration Date',
      coverageRowLimitsTitle: 'Limits',
      coverageRowAddCoverageBtn: 'Add Coverage',
      newDocumentSettingsTitle: 'NEW DOCUMENT SETTINGS',
      certificateOfLiabilityInsuranceTitle: 'CERTIFICATE OF LIABILITY INSURANCE',
      masterCertificateLabel: 'Master Certificate',
      dateLabel: 'Date',
      producerTitle: 'PRODUCER',
      changeAgencyBtn: 'Change Producer',
      agencyTitle: 'Producer',
      addAgencyLabel: 'Producer',
      insuredTitle: 'INSURED',
      addInsuredLabel: 'Insured',
      changeInsuredBtn: 'Change Insured',
      insurersTitle: 'INSURERS',
      coveragesTitle: 'COVERAGES',
      endorsementsTitle: 'ADDITIONAL REQUIREMENTS',
      missingProducerOrInsuredMessage: 'Missing Producer or Insured',
      missingProducerOrInsuredTitle: 'Error',
      missingDocumentMessage: 'Missing Document',
      missingDocumentTitle: 'Error',
      insurer: {
        insurerTitleCreate: 'Create Insurer',
        insurerTitleEdit: 'Edit Insurer',
        nameLabel: 'Name',
        AMBestCompanyNumberLabel: 'AMBestCompany #',
        NAICCompanyNumberLabel: 'NAICCompany #',
        cityLabel: 'City',
        stateLabel: 'State',
        zipCodeLabel: 'Zip Code',
      },
      previewTitle: 'Data Entry Preview',
      previewSaveButton: 'Save and Calculate',
      previewCancelButton: 'Return to Data Entry',
    }
  },
  projectCertText: {
    headerLabel: 'Header',
    cancellationLabel: 'Cancellation Notice',
    disclaimerLabel: 'Disclaimer',
    mandatoryLabel: 'Mandatory Req.',
    saveBtn: 'Save',
    editBtn: 'Edit',
    cancelBtn: 'Cancel',
  },
  projectRequirements: {
    list: {
      nameColumn: 'Group',
      attributeColumn: 'Attribute',
      conditionColumn: 'Condition',
      valueColumn: 'Value',
      typeColumn: 'Type',
      requirementDocumentTitle: 'Requirement Set Document',
      inherittedFlagTitle: 'Inheritted Flag',
      noReqSetMessage: 'No Requirement set was selected for this project.',
    },
  },
  finance: {
    list: {
      dateColumn: 'Transaction Date',
      activityColumn: 'Activity',
      transactionColumn: 'Transaction Number',
      amountColumn: 'Amount ($)',
      amountCreditsColumn: 'Amount (Credits)',
      creditBalanceColumn: 'Credit Balance',
      viewField: 'View Record',
    },
  },
  waivers: {
    list: {
      holder: 'Holder Name',
      project: 'Project Name',
      deficiency: 'Coverage',
      attribute: 'Attribute',
      dateExpires: 'Expiration Date',
      requiredValue: 'Required',
      providedValue: 'Provided'
    },
    filter: {
      title: 'Waivers Filter',
      holderName: {
        label: 'Holder',
        ph: 'Holder Name',
      },
      projectName: {
        label: 'Project',
        ph: 'Project Name',
      },
      waiverDate: {
        label: 'Expiration Date',
      },
      keywordsLabel: 'Keywords',
      coverageTypeLabel: 'Coverage Type',
      waiverStatusLabel: 'Waiver Status',
      waiverDateLabel: 'Waiver Date',
    },
    alerts: {
      undoWaive: {
        title: 'Undo Waived Deficiency',
        description: 'Are you sure you want to remove this waived deficiency?'
      }
    },
    actions: {
      undoWaive: 'Undo',
      editWaive: 'Edit',
    },
    modal: {
      holder: {
        label: 'Holder Name',
      },
      projectNumber: {
        label: 'Project Number',
      },
      projectName: {
        label: 'Project Name',
      },
      insured: {
        label: 'Insured Name',
      },
      reqSetName: {
        label: 'Requirement Set Name',
      },
      waiverSetBy: {
        label: 'Waiver Set By',
      },
      waiverStart: {
        label: 'Waiver Start Date',
      },
      waiverEnd: {
        label: 'Waiver End Date',
      },
      userApproved: {
        label: 'Approved By',
      },
      coverageName: 'Coverage Name',
      coverageAttribute: 'Coverage Attribute',
      coverageValue: 'Value',
      btnSave: 'Save & Close',
      btnCancel: 'Cancel',
    },
    messages: {
      confirm: 'Waived deficiency edited',
      error: 'Could not edit the waived deficiency',
    }
  },
  coverages: {
    holderNameLabel: 'Holder Name',
    projectNumberLabel: 'Project Number',
    projectNameLabel: 'Project Name',
    projectName2Label: 'Optional Name',
    insuredNameLabel: 'Insured Name',
    lastActionDateLabel: 'Last Action Date',
    lastActionLabel: 'Last Action',
    nextActionLabel: 'Next Action',
    finishedWFLabel: 'Complete',
    uploadDocumentLabel: 'Upload Document',
    list: {
      documentIdColumn: 'Doc. ID',
      nameColumn: 'Coverage',
      statusColumn: 'Status',
      expireDateColumn: 'Expire Date',
      majorDefColumn: 'Major Def.',
      minorDefColumn: 'Minor Def.',
      agencyColumn: 'Producer',
      viewEditLink: 'View/Edit Coverage',
      viewDeficiencies: 'View Deficiencies',
      layerLink: 'View Layers',
      layerReturn: 'Close Layers',
      addCoverageBtn: 'Add Coverages',
    },
    statusModal: {
      title: 'Status History',
      statusHeader: 'Status',
      dateHeader: 'Date',
      updatedByHeader: 'Updated By',
      closeBtn: 'Close',
      noHistory: 'There is no records for this status',
    },
    agencyModal: {
      title: 'Producer Information',
      nameLabel: 'Producer Name',
      addressLabel: 'Address',
      mainPhoneLabel: 'Main Phone',
      mainEmailLabel: 'Main Email',
      faxNumberLabel: 'Fax Number',
      closeBtn: 'Close',
    },
    addModal: {
      title: 'Add Coverage',
      titleEdit: 'Edit Coverage',
      nameLabel: 'Coverage Name',
      statusLabel: 'Status',
      expireLabel: 'Expire Date',
      majorDefLabel: 'Major Def.',
      minorDefLabel: 'Minor Def.',
      agencyLabel: 'Producer',
      cancelButton: 'Cancel',
      saveButton: 'Save',
    },
    viewLayers: {
      title: 'View Layers',
      garbage: 'Garbage',
      isTopLayer: 'Yes',
      notTopLayer: 'No',
    }
  },
  CFNotes: {
    filterBtn: 'Filter Notes',
    addBtn: 'Add Notes',
    list: {
      nameColumn: 'Name',
      descriptionColumn: 'Description',
      commentColumn: 'Comment',
      userColumn: 'User',
      typeColumn: 'Contact Type',
      dateColumn: 'Date',
      viewLink: 'View Note',
      editLink: 'Edit Note',
    },
  },
  portal: {
    header: 'Portal',
    form: {
      companyHeader: 'Company Information',
      agencyHeader: 'Producer Information',
      companyNameLabel: 'Company Name',
      legalNameLabel: 'Legal Name',
      addressLabel: 'Address',
      cityLabel: 'City',
      stateLabel: 'State',
      zipCodeLabel: 'Postal Code',
      countryLabel: 'Country',
      filesLabel: 'Files',
      agencyNameLabel: 'Producer Name',
      agentNameLabel: 'Producer Contact Name',
      commentsLabel: 'Comments',
      addFilesBtn: 'Add Files',
      submitBtn: 'Submit',
      resetBtn: 'Reset',
    },
    paymentForm: {
      nameLabel: 'Name',
      cardNumberLabel: 'Card Number',
      expirationMonthLabel: 'Expiration Month',
      expirationYearLabel: 'Expiration Year',
      expirationDateLabel: 'Expiration Date',
      securityCodeLabel: 'Security Code',
      payBtn: 'Pay',
    },
  },
  certFocusSettings: {
    tabs: {
      reqSets: 'Requirement Sets',
      coverageTypes: 'Coverage Types',
      customTerminology: 'Custom Terminology',
      documentQueueDefinitions: 'Document Queue Definitions',
      departments: 'Departments',
      documentTypes: 'Document Types',
      ambest: 'AM Best',
    },
    customTerminology: {
      customTerminologyList: {
        customTermIdColumn: 'Id',
        holderColumn: 'Holder',
        originalTermColumn: 'Original Term',
        customTermColumn: 'Custom Term',
        editCustomTerm: 'Edit Custom Term',
        deleteCustomTerm: 'Delete Custom Term',
        filterBtn: 'Filter',
        addBtn: 'Add Custom Term',
      },
      addModal: {
        title: 'Add Custom Terminology',
        titleEdit: 'Edit Custom Terminology',
        holderLabel: 'Holder',
        originalTermLabel: 'Original Term',
        customTermLabel: 'Custom Term',
        cancelButton: 'Cancel',
        saveButton: 'Save',
      },
      filter: {
        title: 'Custom Terminology Filter',
        holderNameLabel: 'Holder',
        originalTermLabel: 'Original Term',
        customTermLabel: 'Custom Term',
      },
    },
    documentTypes: {
      documentTypesList: {
        documentTypeIdColumn: 'Id',
        documentTypeNameColumn: 'Document Type',
        editDocumentType: 'Edit Document Type',
        deleteDocumentType: 'Delete Document Type',
        filterBtn: 'Filter',
        addBtn: 'Add Document Type',
      },
      addModal: {
        title: 'Add Document Type',
        titleEdit: 'Edit Document Type',
        documentTypeNameLabel: 'Name',
        cancelButton: 'Cancel',
        saveButton: 'Save',
      },
      filter: {
        title: 'Document Types Filter',
        documentTypeNameLabel: 'Document Type Name',
      },
    }
  },
  breadcrumb: {
    hiringclients: 'hiringclients',
    profile: 'profile',
    settings: 'settings',
    insureds: 'insureds',
    contacts: 'contacts',
    tasks: 'tasks',
    searchResults: 'searchResults',
    projects: 'projects',
    documents: 'documents',
  },
  documents: {
    title: 'Documents',
    documentsList: {
      filterBtn: 'Filter',
      viewDocument: 'View',
      processDocument: 'Process',
      viewDataEntry: 'Data Entry',
      garbagedDocument: 'Garbaged',
      nonGarbagedDocument: 'Non-garbaged',
      filter: {
        title: 'Filter Documents',
        holderLabel: 'Hiring Client',
        insuredLabel: 'Insured',
        documentStatusLabel: 'Document Status',
        projectLabel: 'Project',
        documentTypeLabel: 'Document Type',
        garbageLabel: 'Garbage',
        urgentLabel: 'Urgent',
        archivedLabel: 'Archived',
        documentIdLabel:'Document ID'
      },
      headers: {
        fileNameColumn: 'Document Name',
        dateCreatedColumn: 'Upload Date',
        hiringClientColumn: 'Hiring Client',
        projectColumn: 'Project',
        insuredColumn: 'Insured',
        documentTypeColumn: 'Document Type',
        documentStatusColumn: 'Document Status',
        urgentColumn: 'Urgent',
        garbageColumn: 'Garbage',
        archivedColumn: 'Archived',
        topLayerColumn:'Top Layer',
        documentIdColumn:'Document ID'
      }
    },
    addDocumentModal: {
      title: 'ADD DOCUMENT',
      titleEdit: 'PROCESS DOCUMENT',
      holderLabel: 'Hiring Client',
      insuredLabel: 'Insured',
      documentStatusLabel: 'Document Status',
      projectLabel: 'Project',
      documentTypeLabel: 'Document Type',
      garbageLabel: 'Garbage',
      urgentLabel: 'Urgent',
      cancelButton: 'Cancel',
      saveButton: 'Save'
    },
    errors: {
      error10005: 'Error: This is an invalid session. Please, sign in again.',
      error10006: 'Error: You are not logged in. Please, sign in and try again.',
      error10007: 'Error: Session expired. Please, sign in and try again.',
      error10011: 'Error: your current user is disabled. Please, contact an admin to enable this account and login again to continue.',
      error10019: 'No results found.',
      error10130: 'The insured is already added to this project.',
      errorDefault: 'Something went wrong, please try again.',
      errorConnection: 'Connection error - Please, check your Internet service.',
    }
  },
  agencies: {
    title: 'Producers',
    agenciesList: {
      addButton: 'Add Producer',
      filterBtn: 'Filter',
      viewAgents: 'View Producer Contacts',
      editAgency: 'Edit Producer',
      filter: {
        title: 'Filter Producers',
        agencyNameLabel: 'Producer Name',
        cityLabel: 'City',
        zipCodeLabel: 'Zip Code',
        countryLabel: 'Select Country',
        stateLabel:"Select State"
      },
      headers: {
        nameColumn: 'Producer Name',
        cityColumn: 'City',
        stateColumn: 'State',
        zipCodeColumn: 'Zip Code',
        mainPhoneColumn: 'Main Phone',
        mainEmailColumn: 'Main Email',
        viewAgentsColumn: 'View Producer Contacts',
        agentNamesColumn: 'Producer Contact Names',
        coverageAbbreviationColumn: 'Coverage Abbreviation',
        editColumn: 'Edit Producer',
      }
    },
    addAgencyModal: {
      title: 'ADD PRODUCER',
      titleEdit: 'EDIT PRODUCER',
      agencyNameLabel: 'Producer Name',
      addressLabel: 'Address',
      cityLabel: 'City',
      stateLabel: 'State',
      countryLabel: 'Country',
      zipCodeLabel: 'Zip Code',
      mainPhoneLabel: 'Main Phone',
      mainEmailLabel: 'Main Email',
      faxNumberLabel: 'Fax Number',
      cancelButton: 'Cancel',
      saveButton: 'Save'
    },
    agentsList: {
      addButton: 'Add Producer Contact',
      editAgent: `Edit Contact`,
      headers: {
        firstNameColumn: 'First Name',
        lastNameColumn: 'Last Name',
        mobileNumberColumn: 'Mobile Number',
        phoneNumberColumn: 'Phone Number',
        emailAddressColumn: 'Email Address',
        agencyIdColumn: 'Producer ID',
        editColumn: 'Edit Producer Contact',
      }
    },
    addAgentsModal: {
      title: 'ADD NEW CONTACT',
      titleEdit: 'EDIT Producer Contact',
      firstNameLabel: 'First Name',
      lastNameLabel: 'Last Name',
      mobileNumberLabel: 'Mobile Number',
      phoneNumberLabel: 'Phone Number',
      emailAddressLabel: 'Email Address',
      cancelButton: 'Cancel',
      saveButton: 'Save'
    },
    errors: {
      error10005: 'Error: This is an invalid session. Please, sign in again.',
      error10006: 'Error: You are not logged in. Please, sign in and try again.',
      error10007: 'Error: Session expired. Please, sign in and try again.',
      error10011: 'Error: your current user is disabled. Please, contact an admin to enable this account and login again to continue.',
      error10019: 'No results found.',
      error10130: 'The insured is already added to this project.',
      errorDefault: 'Something went wrong, please try again.',
      errorConnection: 'Connection error - Please, check your Internet service.',
    }
  },
  documentQueueDefinitions: {
    documentQueueDefinitionsList: {
      nameColumn: 'Name',
      dateCreatedColumn: 'Date Created',
      archivedColumn: 'Archived',
      isDefaultColumn: 'Default',
      filterBtn: 'Filter Document Queue Definition',
      addBtn: 'Add Document Queue Definition',
      editBtn: 'Edit Document Queue Definition',
      filter: {
        nameLabel: 'Name',
        archivedLabel: 'Archived',
      },
    },
    addModal: {
      title: 'Add Document Queue Definition',
      titleEdit: 'Edit Document Queue Definition',
      nameLabel: 'Name',
      archivedLabel: 'Archived',
      cancelButton: 'Cancel',
      saveButton: 'Save',
      findLabel: 'Document Queue Definition',
    },
    documentQueueUsers: {
      documentQueueUsersList: {
        headers: {
          userNameColumn: 'User Name',
          userRoleColumn: 'User Role',
          deleteColumn: 'Delete Document Queue User',
        },
        addBtn: 'Add Document Queue User',
        deleteDocumentQueueUser: 'Delete Document Queue User'
      },
      addDocumentQueueUsersModal: {
        title: 'Add Document Queue User',
        titleEdit: 'Edit Document Queue User',
        userLabel: 'User',
        userRoleLabel: 'User Role',
        cancelButton: 'Cancel',
        saveButton: 'Save',
        findLabel: 'Document Queue User',
      }
    }
  },
  projectUsers: {
    tableHeaderName: 'Name',
    tableHeaderPhone: 'Phone',
    tableHeaderEmail: 'Email',
    tableHeaderUserType: 'User Type',
    tableHeaderStatus: 'Status',
    buttonFilterUser: 'Filter User',
    buttonAddUser: 'Add User',
    removeProjectUser: 'Archive',
    removeTitle: 'Archive user',
    restoreProjectUser: 'Restore',
    filter: {
      title: 'Filter Users',
      keywordsLabel: 'Keywords',
      keywordsPlaceholder: 'All Project Users',
      userTypeLabel: 'User Type',
      userTypePlaceholder: 'User Type',
      buttonSearch: 'Search'
    },
    addUser: {
      title: 'ADD USER TO PROJECT',
      userPH: 'select user',
      previewTitle: 'User to be added:',
      saveButton: 'Save',
      cancelButton: 'Cancel',
      requiredValidation: 'Required'
    },
    actions: {
      errorConnection: 'Connection error - Please, check your Internet service.'
    }
  },
  holderUsers: {
    buttonAddUser: 'Add User',
    table: {
      archiveUser: 'Archive',
      restoreUser: 'Restore'
    }
  },
  departments: {
    deleteUser: {
      alertTitle: 'Remove User',
      alertHTML: 'Are you sure you want to remove this user?',
    },
    archiveDepartment: {
      alertTitle: 'Archive department',
      alertHTML: 'The department has holders associated, do you want to proceed?',
      archiveSuccess: 'Department archived',
      archiveFail: 'Could not archive department',
      restoreSuccess: 'Department restored',
      restoreFail: 'Could not restore department',
    },
    table: {
      columns: {
        departmentId: 'Department ID',
        name: 'Department Name',
        created: 'Date Created',
        archived: 'Archived',
        users: 'User Name',
        roles: 'User Role',
        mails: 'User Email',
      },
      actions: {
        edit: 'Edit',
        archive: 'Archive',
        restore: 'Restore',
        delete: 'Delete',
        users: 'Users',
      }
    },
    buttonAdd: 'Add Department',
    buttonAddUser: 'Add User',
    buttonFilter: 'Filter Departments',
    form: {
      usersTitle: 'Users of department: ',
      departmentName: {
        label: 'Name',
        ph: '-- Department Name --'
      },
      archived: {
        label: 'Archived',
      },
      user: {
        label: 'User',
        ph: '-- Select User --'
      },
      role: {
        label: 'User Role',
        ph: '-- Select User Role--'
      },
      addUserTitle: 'Add User to department',
      cancelButton: 'Cancel',
      saveButton: 'Save',
      requiredValidation: 'Required',
      success: 'The department has been successfully added',
      fail: 'ERROR: The department WAS NOT  added.  Please contact Administrator'
    },
    modal: {
      title: 'Add New Department',
      titleEdit: 'Edit Department',
    },
    errors: {
      error10003: 'Bad request',
      errorDefault: 'Server error',
      errorConnection: 'Connection error'
    },
    filter: {
      title: 'Filter',
      nameLabel: 'Name',
      archivedLabel: 'Archived',
    },
  },
  CFTasks: {
    modal: {
      titleCreate: 'Add New Task',
      buttonCreate: 'Save',
      buttonCancel: 'Cancel',
      requiredValidation: 'Required',
      atLeastOneAssigned: 'Assign to Department, rol or user',
      atLeastOneRelated: 'Associate task to a Holder or Insured',
      invalidDueDate: 'Select a valid date',
      form: {
        comment: {
          label: 'Comment',
          ph: '',
        },
        priority: {
          label: 'Urgency',
          ph: 'Select Urgency',
        },
        type: {
          label: 'Type',
          ph: 'Select Task Type',
        },
        dueDate: {
          label: 'Due Date',
          ph: '',
        },
        departments: {
          label: 'Assign to Department',
          ph: 'Select a Department',
        },
        role: {
          label: 'Assign to Role',
          ph: 'Select a Role',
        },
        user: {
          label: 'Assign to User',
          ph: 'Search a User',
        },
        insured: {
          label: 'Related to an Insured',
          ph: 'Search a Insured',
        },
        projectInsured: {
          label: 'Related to an Project/Insured',
          ph: 'Search a Insured',
        },
        holder: {
          label: 'Related to Holder',
          ph: 'Search a Holder',
        },
        project: {
          label: 'Related to Project',
          ph: 'Select a Project',
        },
        document: {
          label: 'Related to Document',
          ph: 'Select a Document',
        }
      }
    },
    viewDetail: {
      actionLinks: {
        detail: 'View Detail',
        certificate: 'View Certificate',
      },
      title: 'Task Detail',
      projectHistory: 'Project\'s History',
      taskHistory: 'Tasks\'s History',
      labels: {
        holder: 'Holder',
        insured: 'Insured',
        action: 'Action',
        projectNumber: 'Project #',
        projectName: 'Project Name',
        dateDue: 'Due Date',
        document: 'Document',
        status: 'Current Status',
        comment: 'Original comment',
      },
      commentForm: {
        buttons: {
          reply: {
            label: 'Reply',
          },
          reassign: {
            label: 'Reassign',
          },
          postpone: {
            label: 'Postpone',
          },
        },
        fields: {
          toUser: {
            label: 'To User',
            ph: 'Select User',
          },
          comment: {
            label: 'Comment',
          },
          toDate: {
            label: 'Postpone To Date',
          }
        },
        errors: {
          required: 'Required',
        }
      },
      history: {
        date: 'Date',
        type: 'Type',
        user: 'User',
        summary: 'Summary',
        contact: 'Contact',
        status: 'Status',
      },
      contacts: {
        table: {
          header: {
            contactType: 'Contact Type',
            contactName: 'Name',
            contactEmail: 'Email',
            contactPhone: 'Phone',
            contactMobile: 'Mobile',
          }
        }
      },
      buttons: {
        save: 'Close Task',
        cancel: 'Cancel'
      }
    },
    table: {
      headers: {
        assignedToUser: 'Assigned To User',
        assignedToRole: 'Assigned To Role',
        assignedFrom: 'Assigned From',
        dateCreated: 'Last Modified',
        dueDate: 'Due Date',
        holder: 'Holder Name',
        insured: 'Insured Name',
        projectNumber: 'Project #',
        project: 'Project Name',
        taskType: 'Task Type',
        description: 'Task Description',
        done: 'Done',
      },
    },
    totalizers: {
      total: 'Count',
      urgent: 'Urgent Tasks',
      unassigned: 'Unassigned',
      urgentUnassigned: 'Urgent Unassigned',
    },
    filter: {
      title: 'Filter tasks',
      keywords: {
        label: 'Keyword in Comment or Project Name',
        ph: 'Keyword'
      },
      dateDueFrom: {
        label: 'Due Date From',
      },
      dateDueTo: {
        label: 'Due Date To',
      },
      assignedToUser: {
        label: 'Assigned to',
        ph: 'Select a User'
      },
      assignedToRole: {
        label: 'Assigned to',
        ph: 'Select a Role'
      },
      status: {
        label: 'Task Status',
        ph: 'Select a Status'
      },
      priority: {
        label: 'Task Priority',
        ph: 'Select a Priority'
      },
      type: {
        label: 'Task Type',
        ph: 'Select a Type'
      },
      departments: {
        label: 'Assign to Department',
        ph: 'Select a Department',
      },
      holderKeywords: {
        label: 'Holder Name',
        ph: 'Holder Name Keyword',
      },
      insuredKeywords: {
        label: 'Insured Name',
        ph: 'Insured Name Keyword',
      },
    },
  },
  financialInfo: {
    copySubmissionModal: {
      title: 'Copy Submission',
      hiringClientLabel: 'Hiring Client',
      cancelBtn: 'Cancel',
      copyBtn: 'Copy',
      saveSuccessMessage: 'Successfully Saved',
      saveErrorMessage: 'Error Occured',
      requiredValidation: 'Required',
    },
  },
  copyCertificate: {
    title: 'Copy Certificate',
    insuredList: {
      copyColumn: 'Copy',
      complianceStatusColumn: 'Compliance Status',
      holderNameColumn: 'Holder Name',
      projectNumberColumn: 'Project #',
      projectNameColumn: 'Project Name',
      addressColumn: 'Address',
      cityColumn: 'City',
      stateColumn: 'State',
      zipColumn: 'Zip',
      requiredCoveragesColumn: 'Required Coverages',
      viewCertColumn: 'View Cert',
      expirationDateColumn: 'Expiration Date',
      filterBtn: 'Filter',
      viewCert: 'View Cert',
      copyToSelected: 'COPY TO SELECTED',
      copyToAll: 'COPY TO ALL',
      skipStep: 'SKIP STEP',
      copyStatus: 'Copy Status',
      filter: {
        title: 'Filter Insured',
        projectNumberLabel: 'Project #',
        projectNameLabel: 'Project Name',
        holderNameLabel: 'Holder Name',
        requiredCoveragesLabel: 'Required Coverages',
        stateLabel: 'State',
      },
    },
  },
  certUpload: {
    insuredTitle: `Insured Name`,
    projectTitle: `Project Name`,
    projectName2Title: `Optional Name`,
    projectNumberTitle: `Project Number`,
    complianceStatusTitle: `Compliance Status`,
    newDocuments: `New Documents`,
    prevDocuments: `Previous Documents`,
    additionalRequeriments: `Additional Requirements`,
    requiredInsurance: `Outstanding Insurance Requirements`,
    uploadHint: `Carefully review the insurance requirements listed below. To upload documents please scroll to the bottom of the page`,
    download: {
      downloadRequirementTitle: `Insurance Requirements`,
      insuredNameLabel: `Insured Name`,
      holderNameLabel: `Holder Name`,
      projectNameLabel: `Project Name`,
      projectNumberLabel: `Project Number`,
      requirementsTitle: 'Requirements',
      endorsementsTitle: 'Additional Requirements',
      noRequirementGroup: 'No Requirement Group found',
      noEndorsements: 'No Additional Requirement found',
    }
  },
  mailActivity: {
    table: {
      title: `Insured Mail Activity`,
      columns: {
        from: `From Email`,
        to: `To Email`,
        date: `Date`,
        template: 'Template',
        activity: 'Activity',
        holder: 'Holder',
        projectNumber: 'Project #',
        project: 'Project',
        actions: 'Actions',
      }
    }
  },
  ambest: {
    table: {
      title: 'AM Best Imports',
      headers: {
        importDate: 'Date',
        startHour: 'Started at',
        endHour: 'Finished at',
        user: 'User',
        mail: 'User Email',
        inserts: 'New',
        updates: 'Updates',
        total: 'Total',
        actions: 'Actions'
      },
      rowActions: {
        view: 'View',
      }
    },
    import: {
      fileInputLabel: 'Please choose a file to upload (.csv)',
    },
    importHistory: {
      table: {
        title: 'History of import done at $1 by $2',
        headers: {
          importAction: 'Action',
          naicNumber: 'NAIC #',
          rating: 'AM Best Rating',
          financial: 'AM Best Financial',
          effectiveDate: 'Effective Date',
          insuredName: 'Company Name',
        }
      }
    }
  },
  ambestImport: {
    form: {
      saveButton: 'Upload',
      cancelButton: 'Cancel',
    }
  },
  workflows: {
    enableTitle: 'Enable',
    disableTitle: 'Disable',
    enableText: 'enable',
    disableText: 'disable',
    title: '$1 Workflow',
    text: 'Are you sure you want to $1 workflow for Holder # $2?',
    autoTitle: '$1 Workflow Auto',
    autoText: 'Are you sure you want to $1 workflow auto property for Holder # $2?',
    removeWorkflowActionLabel: 'Remove Workflow Action',
    removeWorkflowActionText: 'Are you sure you want to remove workflow action for $1 workflow?',
    wfIncompleteText: 'Workflow can not be saved due to some incomplete fields',
    wfIncompleteTitle: 'Please check incomplete fields'
  },
  registration: {
    registrationCodeLabel: 'Registration Code',
    companyNameLabel: 'Company Name',
    taxIdLabel: 'Tax Id',
    addressLabel: 'Address',
    cityLabel: 'City',
    stateLabel: 'State',
    countryLabel: 'Country',
    zipCodeLabel: 'Zip Code',
    contactNameLabel: 'Contact Name',
    emailLabel: 'Email',
    phoneLabel: 'Phone',
    faxLabel: 'Fax',
    insuranceAgencyCompanyNameLabel: 'Insurance Agency Company Name',
    insuranceAgencyContactPersonLabel: 'Insurance Agency Contact Person',
    insuranceAgencyPhoneLabel: 'Insurance Agency Phone',
    insuranceAgencyEmailLabel: 'Insurance Agency Email',
  },
  holderRegistrationSettings: {
    title: 'Registration Settings',
    registrationCodeLabel: 'Registration Code',
    connectToProjectLabel: 'Connect to Project',
    selectProjectLabel: 'Select Project',
    selectRequirementSetLabel: 'Select Requirement Set',
    saveButton: 'Save',
  },
  dataEntryRecords: {
    CTAButton: 'Export Data Entry',
    modalTitle: 'Registered Data Entry',
    exportFileName: 'Data Entry.csv',
    columns: {
      date: 'Date',
      mail: 'User',
      total: 'Entries',
      queue: 'Queue',
    }
  },
  certfocusCertificates: {
    confirmAttributeModelTitle: "Unable to Confirm",
    confirmAttributeModelText: "Policy Dates Must be Active!",
    confirmAttributeModelConfirmButtonText: "Ok",
  }
};

export default en;
