import React from 'react';
import MUIDataTable from "mui-datatables";
import {Button, createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import "./index.scss"
import {bindActionCreators} from "redux";
import * as commonActions from "../actions";
import {connect} from "react-redux";
import _ from "lodash";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'


/*
* Required Props:
* urlQuery: string
* fileName: string
* filterBox: object
* columns: object
* orderBy: string
* orderDirection: ASC|DESC
* */

const DataTable = (props) => {
    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MuiTable: {
                    root: {
                        position: 'relative',
                        zIndex: '0'
                    }
                },
                MUIDataTable: {
                    responsiveBase: {
                        // overflow:'unset !important',
                        // overflowX:'auto !important'
                    },
                },

                MuiGrid: {
                    'spacing-xs-4': {
                        '& > $item': {
                            padding: 5
                        }
                    },
                    root: {
                        width: '100% !important',
                        margin: '0 !important'
                    }
                },
                MuiTableCell: {
                    root: {
                        padding: '6px !important'
                    },
                    head: {
                        color: '#085DAD',
                        fontSize: 14,
                        textTransform: 'uppercase',
                        padding: 10,
                        fontWeight: 700,
                        position: 'relative !important'
                    },
                },
                MUIDataTableFilter: {
                    root: {
                        padding: '24px 8px 36px 8px'
                    },
                    gridListTile: {
                        marginTop: 0,
                        paddingTop: 4,
                        paddingBottom: 4,
                    },
                },
                MuiTypography: {
                    h6: {
                        color: '#2E5965',
                        fontSize: 18,
                        textTransform: 'uppercase',
                        fontWeight: 700,
                    }
                },
                MUIDataTableBodyCell: {
                    root: {
                        '& > a': {
                            cursor: 'pointer',
                            display: 'block',
                            color: '#8CC739',
                            position: 'relative',
                            '&:hover': {
                                textDecoration: 'underline',
                            },
                            '& > svg': {
                                fontSize: 16,
                                marginRight: 5
                            },
                            '&:before': {
                                color: '#8CC739',
                                margin: '5px'
                            }
                        },

                    }
                },
                MUIDataTableHeadCell: {
                    data: {
                        color: '#085dad !important',
                        fontSize: '14px !important',
                        textTransform: 'uppercase !important',
                        fontWeight: 'bold',
                        position: 'relative !important'
                    }
                },
                MUIDataTableSelectCell: {
                    checked: {color: "#2e5965 !important" }
                }
            },
        });

    const options = {
        download: true,
        filter: false,
        filterType: 'textField',
        selectableRowsHeader: props.rowSelection,
        selectableRowsHideCheckboxes: !props.rowSelection,
        rowsSelected: props.selectedRows ? props.selectedRows : [],
        selectToolbarPlacement: 'none',
        print: false,
        count: props.pagination.total,
        rowsPerPage: props.pagination.itemsPerPage,
        page: props.pagination.currentPageNumber - 1,
        responsive: 'standard',
        fixedHeader: true,
        tableBodyMaxHeight: '',
        jumpToPage: true,
        confirmFilters: true,
        serverSide: true,
        viewColumns: false,
        search: false,
        sort: props.sorted,
        sortOrder: {
            name: props.tableOrderActive,
            direction: props.wrapperState.order[props.tableOrderActive].toLowerCase()
        },
        onDownload: (buildHead, buildBody, columns, values) => {
            if (values.length > 0) {
                const fileType =
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                const fileExtension = ".xlsx";

                console.log(values);

                const json = values.reduce((result, val) => {
                    const temp = {};
                    val.data.forEach((field, idx) => {
                        if(props.items.header[columns[idx].name] && props.items.header[columns[idx].name] !== "") {
                            if(field && field.props && field.props.href){
                                temp[columns[idx].label] = field.props.href;
                            } else if(field && field.props && field.props.children){
                                if(Array.isArray(field.props.children) && field.props.children.length > 0){
                                    temp[columns[idx].label] = field.props.children[0];
                                } else if(field.props.children && field.props.children.props && field.props.children.props.hasOwnProperty('defaultChecked')){
                                    temp[columns[idx].label] = field.props.children.props.defaultChecked;
                                } else {
                                    temp[columns[idx].label] = field.props.children;
                                }
                            } else if(field && field.props && field.props.hasOwnProperty('isChecked')){
                                temp[columns[idx].label] = field.props.isChecked;
                            } else if(field && field.props && field.props.defaultChecked){
                                temp[columns[idx].label] = field.props.defaultChecked;
                            } else if(Array.isArray(field) && field.length > 0){
                                temp[columns[idx].label] = field[0];
                            } else {
                                temp[columns[idx].label] = field;
                            }
                        }
                    });
                    result.push(temp);
                    return result;
                }, []);

                const fileName = `${props.name || 'data'}`;
                const ws = utils.json_to_sheet(json);
                const sheets = {}
                sheets[fileName] = ws
                const wb = { Sheets: sheets, SheetNames: [`${fileName}`] };
                const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
                const data = new Blob([excelBuffer], { type: fileType });
                saveAs(data, fileName + fileExtension);

                // cancel default  CSV download from table
                return false;
            } else {
                return false;
            }
        },
        textLabels: {
            toolbar: {
                downloadCsv: "Download XLSX"
            }
        },

        onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
            if(props.onRowSelection) {
                props.onRowSelection(rowsSelected)
            }
        },
        rowsPerPageOptions: [10, 25, 50, 100, 500],
        downloadOptions: {
            filename: `${props.name || 'table'}.csv`,
            separator: ';',
            filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
            }
        },
        setTableProps: () => {
            return {
                size: 'small',
            };
        },
        onColumnSortChange: (changedColumn, direction) => {
            if(props.clickOnColumnHeader) props.clickOnColumnHeader('', changedColumn)
        },
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    if(props.pagination.setPageHandler) props.pagination.setPageHandler(tableState.page + 1, tableState.rowsPerPage)
                    break;
                case 'changeRowsPerPage':
                    let maxPage = 0;
                    if(props.pagination.total !== 0){
                        maxPage = Math.ceil(props.pagination.total / tableState.rowsPerPage)
                    }
                    const pageNo = maxPage !== 0 && props.pagination.currentPageNumber > maxPage ? maxPage : props.pagination.currentPageNumber;
                    if(props.pagination.setPageHandler) props.pagination.setPageHandler(pageNo, tableState.rowsPerPage)
                    break;
                default:
            }
        }
    };
    return props.isFetching ? (<div className="spinner-wrapper">
            <div className="spinner"></div>
        </div>) : (props.items && props.items.body && props.items.body.length === 0 ?
          (<div className="no-results-msg">
            {props.local.strings.common.table.noResults}
          </div>) :
        (<div className={`custom-datatable ${props.login.currentSystem}`}>
            <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title={props.name || 'table'}
                    data={props.items.body}
                    columns={props.items.fields.map(field => {
                        return {
                            name: field,
                            label: props.items.header[field],
                            options: {
                                ...props.items.header[field] === "" ? {customHeadRender: () => (<th className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeSmall"></th>)} : {},
                                sort: !!(props.sorted && props.wrapperState.order && props.wrapperState.order[field]),
                                download: props.items.header[field] && props.items.header[field] !== "" ? true : false
                            }
                        }
                    })}
                    options={options}
                />
            </MuiThemeProvider>
        </div>)
    );
}


const mapStateToProps = (state, ownProps) => {
    return {
        local: state.localization,
        login: state.login
    };
};

export default connect(mapStateToProps)(DataTable);
