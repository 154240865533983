import Api from '../../../lib/api';

const asyncValidate = (values, dispatch, props) => {
  const errors = {};
  const urlQuery = `cf/registration/holderdetail?holderId=${values.registrationCode}`;
  return Api.get(urlQuery).then(response => {
    const { success, data } = response.data;
    if (!success || data.length === 0) {
      errors.registrationCode = 'Please contact Vertikal RMS at 877-875-5089 to obtain your registration code';
      throw errors;
    }
  });
}

export default asyncValidate;