import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as loginActions from '../login/actions';
import * as commonActions from '../../common/actions';

import SidePicture from '../common/SidePicture';
import AuthFormHeader from '../common/AuthFormHeader';
import Loader from '../../common/loader';

import auth0Client from './Auth';

class LoginSSO extends React.Component {
  constructor(props) {
    super(props);

    let location = {from: {pathname: '/sso'}};
    if (props.location.state) {
      if(props.location.state.from.pathname !== "/"){
        location = props.location.state;
      }
    }
    this.state = {
      checkingSession: true,
      location
    }        
  }

  async componentDidMount() {
    //console.log('BEGIN componentDidMount');
    // if (this.props.location.pathname === '/callback') {
    //console.log('callback');
    //   this.setState({checkingSession:false});
    //   return;
    // }
    try {
      //console.log('silentAuth');
      await auth0Client.silentAuth();
      this.forceUpdate();
    } catch (err) {
      //console.log(err.error);
      //if (err.error !== 'login_required') //console.log(err.error);
    }
    this.setState({checkingSession:false});

    //console.log('END componentDidMount');
  }

  signOut = () => {
    //console.log('signOut');
    auth0Client.signOut();
    this.props.history.replace('/sso');
  };

  onClickContinue = (event) => {
    //console.log('DATA', this.props.history, this.state.location.from);

    const profile = auth0Client.getProfile();
    //console.log('PROFILE', profile);
    const credentials = {
      remember: true,
      username: profile.name,
      sso: profile.sub,
    };
    // if (authentication_token) {
    //   this.props.actions.sendCredentials(credentials, this.props.history, this.state.location.from);
    // } 
    this.props.actions.sendCredentials(credentials, this.props.history, this.state.location.from);
  }


  render() {
    let loginStrings = this.props.local.strings.auth.login;

    // if (this.state.checkingSession) return <h3>Validating session...</h3>;
    //console.log('profile', auth0Client.getProfile());
    // if (!auth0Client.isAuthenticated()){ 
    //   this.props.commonActions.setLoading(true);
    //   auth0Client.signIn();
    // } else {
    //   this.props.commonActions.setLoading(false);
    // }

    return (
      <div className="container-fluid login">
        <Loader />
        <div className="row">
          <SidePicture />
          <div className="form-wrapper col-custom-padding col-md-4 col-sm-12">
            <AuthFormHeader subtitle={this.props.login.extraMessage || ''}/>

            <div className="auth-form login-form">
              <div className="form-buttons">
                {
                  !auth0Client.isAuthenticated() &&
                  <button
                    className="sign-in-icon bn icon-login-door" 
                    onClick={auth0Client.signIn}
                  >
                    {loginStrings.signIn}
                  </button>
                }
                {
                  auth0Client.isAuthenticated() &&
                  <div>
                    <div className="field-wrapper">
                      <label>{auth0Client.getProfile().name}</label>
                    </div>
                    <div className="form-buttons">
                      <button className="sign-in-icon bn icon-login-door" onClick={this.onClickContinue}>Continue</button>
                      <button className="cancel bn icon-login-door" onClick={this.signOut}>Sign Out</button>
                    </div>
                  </div>
                }               
                {/* onClick={this.onClickSignin} */}

              </div>          
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    login: state.login,
    local: state.localization,
    common: state.common,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(loginActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginSSO));
