import React, { useEffect, useState } from "react";

const Dashboard = ({ dashboardId, sessionToken, title }) => {
  const minWidth = 1024;
  const minHeight = 640;
  const [screenResolution, setScreenResolution] = useState({width: 0, height: 0});

  useEffect(() => {
    const sw = 5;
    const sh = 195;
    const width =  window.innerWidth > window.screen.availWidth ? window.innerWidth - sw : window.screen.availWidth - sw; // adjust the scrollbar
    const height = window.innerHeight > window.screen.availHeight ? window.innerHeight - sh :window.screen.availHeight - sh; // adjust the additional height
    setScreenResolution({
      width: width > minWidth ? width : minWidth,
      height: height > minHeight ? height : minHeight,
    });
  }, []);

  return (
    screenResolution.width && 
      <div style={{
        "height": 'auto',
        "width": '100%',
        "overflowX": "auto" 
      }}>
        <iframe
          src={`${process.env.REACT_APP_YURBI_SERVICE_URL}/analytics/vrms_embeded_dashboard.html?t=d&i=${dashboardId}&s=${sessionToken}`}
          title={title}
          width={screenResolution.width}
          height={screenResolution.height}
          frameBorder="0"
        />
      </div>
  );
};

export default Dashboard;
