import React from 'react';

function StaticLoader() {
    return <div className='static-loader-container'>
    <div className="spinner-wrapper">
      <div className="spinner mb-4"></div>
    </div>
  </div>
}

export default StaticLoader