export const SET_INSURED_DETAILS_ERROR = 'SET_INSURED_DETAILS_ERROR';
export const SET_INSURED_DETAILS_FETCHING = 'SET_INSURED_DETAILS_FETCHING';
export const SET_INSURED_DETAILS = 'SET_INSURED_DETAILS';

// TAGS
export const SET_EDIT_TAG_FETCHING = 'SET_EDIT_TAG_FETCHING';
export const SET_EDIT_TAG_SUCCESS = 'SET_EDIT_TAG_SUCCESS';
export const SET_EDIT_TAG_ERROR = 'SET_EDIT_TAG_ERROR';
export const SET_INSURED_TAGS_ERROR = 'SET_INSURED_TAGS_ERROR';
export const SET_INSURED_TAGS_FETCHING = 'SET_INSURED_TAGS_FETCHING';
export const SET_INSURED_TAGS = 'SET_INSURED_TAGS';
export const SET_DELETE_TAG_SUCCESS = 'SET_DELETE_TAG_SUCCESS';

export const SET_SELECT_TAG_TAB = 'SET_SELECT_TAG_TAB';
export const SET_HIDE_ADD_TASK = 'SET_HIDE_ADD_TASK';
export const SET_HIDE_ADD_NOTE = 'SET_HIDE_ADD_NOTE';

export const SET_HOLDER_SELECTED = "SET_HOLDER_SELECTED";