import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import validate from "./validate";
import renderField from "../../../../../customInputs/renderField";

class ContactSummaryForm extends Component {

  render() {
    const { handleSubmit, pristine, submitting, dismiss, userCanCloseTask } = this.props;

    return (
      <form onSubmit={handleSubmit} className={'list-view-filter-form taskForm'}>
        <Field name="contactUser" component="input" type="hidden" readOnly/>
        <div className="container-fluid filter-fields pt-2">
          <div className="row">

            <div className="col-8">
              <label htmlFor="contactSummary"> Contact Summary: </label>
            </div>
            <div className="col-4">
              <label>Contact Person: {this.props.contact}</label>
            </div>
            <div className="col-12 admin-form-field-wrapper no-padding">
              <Field
                name="contactSummary"
                type="textarea"
                component={renderField}
                disabled={!userCanCloseTask}
              />
            </div>

          </div>
        </div>
        <div className="formButtons">
          <button name={'save'}
            type="submit"
            className={`${(!userCanCloseTask || pristine) ? 'bg-pink-gradient' : 'bg-sky-blue-gradient'} bn`} 
            disabled={!userCanCloseTask || pristine || submitting}
          >{this.props.locale.buttons.save}
          </button>
          <a className="bg-sky-blue-gradient bn" onClick={dismiss}>{this.props.locale.buttons.cancel}</a>
        </div>
      </form>
    );
  }
}

ContactSummaryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

ContactSummaryForm = reduxForm({
  form: 'taskContactSummaryForm',
  validate,
  initialValues: {
    contactSummary: ''
  }
})(ContactSummaryForm)

const mapStateToProps = (state) => {
  return {
    locale: state.localization.strings.CFTasks.viewDetail,
    tasks: state.CFTasks,
    common: state.common,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactSummaryForm);