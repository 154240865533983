import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import Projects from './../../projects';
import Tags from './tags';
import Users from '../../../users';
import HolderUsers from "./users";
import Contacts from '../../contacts';
import CustomFields from './custom-fields';
import Tasks from '../../tasks';
import DocumentTypes from './document-types';
import Settings from './settings';
import CoverageTypes from '../../coverage-types';
import Endorsements from './endorsements';
import RequirementSets from '../../requirement-sets';
import Workflow from './workflow';
import {bindActionCreators} from "redux";
import * as actions from "../../coverage-types/actions";
import CFTemplates from "./templates";
import HolderRegistrationSettings from "./registration";

import RolAccess from "../../../common/rolAccess";

class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0
    };
  }

  setCurrentTab = (e, i, label) => {
    const {
      coverageTypes,
    } = this.props.local.strings.holders.holdersProfile.tabs;
    if (label === coverageTypes) {
      // todo reset filter
      const {actions} = this.props;
      const query = this.addId({
        orderBy: 'displayOrder',
        orderDirection: 'ASC',
      });

      actions.fetchCoverageTypes(query);
    }
    e.preventDefault();
    this.setState({
      currentTab: i
    });
  };
  addId = (query) => {
    const { holderId } = this.props.match.params;

    if (holderId) {
      return {...query, holderId};
    }

    return query;
  }


  renderTab = (tab, idx) => {
    return (
        <li className="tab-item" key={idx}>
          <a
              className={`tab-button ${this.state.currentTab === idx ? 'active' : ''}`}
              onClick={e => {
                if(this.props.holderRequirementSets.hasTmpRequirementGroup) {
                  const confirm = window.confirm('Are you sure you want to exit? Requirement groups without attribute will not be saved.')
                  if(confirm) {
                    this.setCurrentTab(e, idx)
                  }
                } else {
                  this.setCurrentTab(e, idx)
                }
              }}>{tab.tabLabel}</a>
        </li>
    )
  }

  renderTabContent = (tab, idx) => {
    return (
        <div
            className={`tab-pane ${this.state.currentTab === idx ? 'active' : ''}`}
            key={idx} >
          { 
            this.state.currentTab === idx ?
            tab.content :
            null
          }
        </div>
    );
  }

  render() {
    const { holderId } = this.props.match.params;
    const { holderArchived } = this.props;
    const { accountManagerId } = this.props.holderProfile
    const {
      projects,
      tasks,
      coverageTypes,
      reqSets,
      contacts,
      tags,
      customFields,
      endorsements,
      documentTypes,
      users,
      workflow,
      settings,
      holderUsers,
      registration,
    } = this.props.local.strings.holders.holdersProfile.tabs;

    const tabs = [
      {
        tabLabel: projects,
        masterTab: 'projects',
        sectionTab: 'view_tab',
        content: <Projects 
                  fromHolderTab 
                  holderId={holderId} 
                  holderArchived={holderArchived}
                  accountManagers={accountManagerId}
                />
      },
      {
        tabLabel: tasks,
        masterTab: 'tasks',
        sectionTab: 'view_tab',
        content: <Tasks 
                  fromHolderTab 
                  holderId={holderId} 
                  holderName={this.props.holdersProfile.name}
                  holderArchived={holderArchived}
                />
      },
      {
        tabLabel: coverageTypes,
        masterTab: 'coverage_types',
        sectionTab: 'view_tab',
        content: <CoverageTypes 
                  fromHolderTab 
                  holderId={holderId} 
                  holderArchived={holderArchived}
                />
      },
      {
        tabLabel: reqSets,
        masterTab: 'requirements',
        sectionTab: 'view_tab',
        content: <RequirementSets 
                  fromHolderTab 
                  holderId={holderId} 
                  holderArchived={holderArchived}
                />
      },
      {
        tabLabel: contacts,
        masterTab: 'hiring_client_holder_contacts',
        sectionTab: 'view_tab',
        content: <Contacts 
                  holderId={holderId} 
                  holderArchived={holderArchived}
                />
      },
      {
        tabLabel: tags,
        masterTab: 'tags',
        sectionTab: 'view_tab',
        content: <Tags 
                  holderId={holderId} 
                  holderArchived={holderArchived}
                />
      },
      {
        tabLabel: customFields,
        masterTab: 'custom_fields',
        sectionTab: 'view_tab',
        content: <CustomFields 
                  holderId={holderId} 
                  holderArchived={holderArchived}
                />
      },
      {
        tabLabel: endorsements,
        masterTab: 'requirements',
        sectionTab: 'view_tab',
        content: <Endorsements 
                  holderId={holderId} 
                  holderArchived={holderArchived}
                />
      },
      {
        tabLabel: documentTypes,
        masterTab: 'document_types',
        sectionTab: 'view_tab',
        content: <DocumentTypes 
                  holderId={holderId}
                  holderArchived={holderArchived}
                />
      },
      {
        tabLabel: holderUsers,
        masterTab: 'holder_users',
        sectionTab: 'view_tab',
        content: <HolderUsers 
                  fromHolderTab 
                  hcId={holderId}
                  holderArchived={holderArchived}
                />
      },
      {
        tabLabel: workflow,
        masterTab: 'workflow',
        sectionTab: 'view_tab',
        content: <Workflow 
                  holderId={holderId} 
                  holderArchived={holderArchived}
                />
      },
      {
        tabLabel: settings,
        masterTab: 'holder_settings',
        sectionTab: 'view_tab',
        content: <Settings 
                  holderId={holderId} 
                  holderArchived={holderArchived}
                />
      },
      {
        tabLabel: 'Templates',
        masterTab: 'templates',
        sectionTab: 'view_tab',
        content: <CFTemplates 
                  fromHolderTab 
                  holderId={holderId}
                  holderArchived={holderArchived}
                />
      },
      {
        tabLabel: registration,
        masterTab: 'holder_settings',
        sectionTab: 'view_tab',
        content: <HolderRegistrationSettings 
                  holderId={holderId} 
                  holderArchived={holderArchived}
                />
      },
    ];

    let renderedTabs = [];
    if (this.props.login.profile.CFRole) {
      const { CFRole } = this.props.login.profile;

      if (!CFRole) {
        return <Redirect push to="/profile" />;
      } else {
        renderedTabs = [ ...tabs ];
      }
    }



    return (
      <div className="tab-frame">
        <ul className="profile-tabs nav nav-tabs">
          {
            renderedTabs.map((tab,idx) => {
              return (
                  <RolAccess
                      key={tab.tabLabel+'-tab'}
                      masterTab={tab.masterTab}
                      sectionTab={tab.sectionTab}
                      component={() => this.renderTab(tab,idx)}>
                  </RolAccess>
              );
            })
          }
        </ul>
        <div className="tab-content">
          {
            renderedTabs.map((tab,idx) => {
              return (
                  <RolAccess
                      key={tab.tabLabel+'-content'}
                      masterTab={tab.masterTab}
                      sectionTab={tab.sectionTab}
                      component={() => this.renderTabContent(tab,idx)}>
                  </RolAccess>
              );
            })
          }
        </div>
      </div>

    );
  }
};

const mapStateToProps = (state) => {
  return {
    local: state.localization,
    login: state.login,
    holdersProfile: state.holdersProfile.profileData,
    holderRequirementSets: state.holderRequirementSets,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Tabs));
