import React from "react";
import { Link } from 'react-router-dom'

class FormNavbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            previousUrl: props.previousUrl
        };
    }

    render() {
        let isPQAdminUser = false;
        if(this.props.profile && this.props.profile.Role){
            isPQAdminUser =
                this.props.profile.Role.Id === 1 &&
                this.props.profile.Role.System === 'pq';
        }

        return (
            <div>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <Link className="nav-link" to={this.state.previousUrl}>
                            <i className="fa fa-chevron-left"></i>
                        </Link>
                    </li>
                    {isPQAdminUser &&
                        <>
                            <li className="nav-item">
                                <Link className="nav-link" to={`/formio/forms/${this.props.formId}`}>
                                    <i className="fa fa-pencil"></i> Enter Data
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={`/formio/forms/${this.props.formId}/edit`}>
                                    <i className="fa fa-edit"></i> Edit Form
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={{
                                    pathname: `/formio/forms/${this.props.formId}/delete`,
                                    state: { previousUrl: this.state.previousUrl } }}>
                                    <i className="fa fa-trash"></i> Delete Form
                                </Link>
                            </li>
                        </>
                    }
                </ul>
            </div>
        )
    }
}
export default FormNavbar;
