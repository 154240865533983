import React from 'react';
import { Field, reduxForm, touch, change, reset } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import Utils from '../../../../lib/utils';
import renderField from '../../../customInputs/renderField';
import renderSelect from '../../../customInputs/renderSelect';
import renderRemovable from '../../../customInputs/renderRemovable';
import renderTypeAhead from '../../../customInputs/renderTypeAhead';
import FilterActions from '../../../common/filterActions/FilterActions'
import {AsyncPaginate} from 'react-select-async-paginate';
import * as commonActions from '../../../common/actions';
import * as registerActions from '../../../register/actions';
import * as loadApplyOptions from '../../../common/countyDropdownActions/loadOptions'
import * as applyActions from '../../../common/countyDropdownActions/actions';
import * as filterActions from '../../filterbox/actions';
import * as actions from "../actions";

const SelectAsyncOptions = ({ input, meta, dispatch, ...props }) => {
  const touchedError = meta.touched && meta.error;
  const name = input.name;
  const baseClassName = `${props.className || ''} select-${name}`;
  const errorClassName = `${touchedError ? `${baseClassName}-error` : ''}`;

  return (
    <React.Fragment>
      <AsyncPaginate
        {...input}
        {...props}
        openMenuOnClick={true}
        closeMenuOnScroll={true}
        cacheOptions={true}
        pageSize={10}
        additional={{ page: 1 }}
        classNamePrefix="vk"
        className={`${baseClassName} ${errorClassName}`}
        menuPlacement="auto"
        onFocus={(_) => { }}
        onBlur={() => meta.dispatch(touch('FilterProjects', name))}
        onChange={(newValue) => input.onChange(newValue)}
        captureMenuScroll={true}
        closeMenuOnSelect={true}
        debounceTimeout={300}
        loadOptionsOnMenuOpen={true}
      />
      {touchedError && (
        <span className="select-error-message field-validation-message">
          {meta.error}
        </span>
      )}
    </React.Fragment>
  );
};

class FilterAgencies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry : null
    }
    this.filtersResetCallback = this.filtersResetCallback.bind(this)
  }
  componentDidMount() {
    this.preloadFilters();
  }

  preloadFilters = () => {
    let saveFilter = { tab: 'producers' }
    if (this.props.fromInsureds) {
      saveFilter.module = 'Insureds';
      saveFilter.objectId = this.props.insuredId;
    }
    if (this.props.fromProducers) {
      saveFilter.module = 'GlobalProducers';
      saveFilter.objectId = null;
    }
    let query = Utils.getFetchQuery("Name", 1, "DESC", 10);
    this.props.filterActions.fetchDefaultFilters(saveFilter, 'FilterAgencies', (filters) => {
      if (filters && typeof filters == "object") {
        query = {
          ...query,
          name: filters.name || "",
          city: filters.city || "",
          zipCode: filters.zipCode || "",
        }
        if (filters.countryId.value) {
          query.countryId = filters.countryId.value;
          this.setState({ selectedCountry: filters.countryId.value })
        }
        if (filters.stateId.value) {
          query.stateId = filters.stateId.value;
        }
      }
      this.props.commonActions.setLoading(false);
      this.props.agenciesActions.fetchAgencies(query);
    });
  }

  async filtersResetCallback(values) {
    values.preventDefault();
    this.props.commonActions.setLoading(true);
    this.props.dispatch(reset(this.props.formName));
    let saveFilter = { tab: 'producers' }
    if (this.props.fromInsureds) {
      saveFilter.module = 'Insureds';
      saveFilter.objectId = this.props.insuredId;
    }
    if (this.props.fromProducers) {
      saveFilter.module = 'GlobalProducers';
      saveFilter.objectId = null;
    }
    saveFilter.filterBox = '';
    await this.props.filterActions.saveDefaultFilters('FilterAgencies', saveFilter);
    this.preloadFilters();
  }
  
  handleChangeCountry(value, name) {
    if (this.state.selectedCountry !== value.value) {
      if (name === 'country') {
        this.props.dispatch(change(
          'FilterAgencies',
          'country',
          { value: null, label: '' }
        ))
        this.setState({ selectedCountry: value.value })
      } else {
        this.props.dispatch(change(
          'FilterAgencies',
          'state',
          { value: null, label: '' } 
        ))
      }
    }
  }
  renderFormField = (element, idx) => {
    const {
      name,
      label,
      ph,
      type,
      conditional,
      show,
      options,
      loadOptions
    } = element;
    
    const style = {};
    if (conditional && !show) {
      style.display = 'none';
    }

    if (type === 'typeAhead') {
      const { fetching, results, error, handleSearch, onSelect } = element;

      return (
        <div className="col-md no-padd" key={idx} style={style}>
          <div className="admin-form-field-wrapper keywords-field">
            <label htmlFor={name}>{`${label}:`}</label>
            <Field
              resetOnClick
              name={name}
              placeholder={ph}
              fetching={fetching}
              results={results}
              handleSearch={handleSearch}
              fetchError={error}
              component={renderTypeAhead}
              onSelect={onSelect}
            />
          </div>
        </div>
      );
    } else if (type === 'removable') {
      const { valueText, disabled, onRemove } = element;
      return (
        <div className="col-md no-padd" key={idx} style={style}>
          <div className="admin-form-field-wrapper keywords-field">
            <label htmlFor={name}>{`${label}:`}</label>
            <Field
              name={name}
              valueText={valueText}
              component={renderRemovable}
              onRemove={onRemove}
              disabled={disabled}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="col-md no-padd" key={idx} style={style}>
        <div className="admin-form-field-wrapper keywords-field">
          <label htmlFor={name}>{`${label}:`}</label>
          {
          loadOptions ? 
          <div className="select-wrapper">
            <Field
              name={name}
              component={SelectAsyncOptions}
              loadOptions={loadOptions}
              cacheUniqs={[this.state.selectedCountry]}
              onChange={value => this.handleChangeCountry(value,name)}
            />
          </div>
          :
          <Field
            name={name}
            type="text"
            placeholder={ph}
            component={renderField}
          />
        }
        </div>
      </div>
    );
  }

  render() {
    const {
      title,
      agencyNameLabel,
      cityLabel,
      stateLabel,
      zipCodeLabel,
      countryLabel
    } = this.props.local.strings.agencies.agenciesList.filter;

    const {
      handleSubmit,
      register
    } = this.props;
    const countryOptions = this.props.actions.loadCountries(this.props)
    const stateOptions = this.props.actions.loadStates(this.props,this.state.selectedCountry)
    const countryId = _.get(this.props, 'currentForm.FilterAgencies.values.country.value', null);

    const fields = countryId ? [
      { name: 'name', label: agencyNameLabel, ph: `-- Select ${agencyNameLabel} --` },
      { name: 'city', label: cityLabel, ph: `-- Select ${cityLabel} --`  },
      { name: 'country', label: countryLabel, ph: `-- Select ${countryLabel} --` ,loadOptions:countryOptions },
      { name: 'state', label: stateLabel, ph: `-- Select ${stateLabel} --` ,loadOptions:stateOptions },
      { name: 'zipCode', label: zipCodeLabel, ph: `-- Select ${zipCodeLabel} --`  },
    ] : [
      { name: 'name', label: agencyNameLabel, ph: `-- Select ${agencyNameLabel} --` },
      { name: 'city', label: cityLabel, ph: `-- Select ${cityLabel} --`  },
      { name: 'country', label: countryLabel, ph: `-- Select ${countryLabel} --` ,loadOptions:countryOptions },
      { name: 'zipCode', label: zipCodeLabel, ph: `-- Select ${zipCodeLabel} --`  },
    ]

    return (
      <form onSubmit={handleSubmit} className="list-view-filter-form">
        <h2 className="list-view-filter-title">{title}</h2>
        <div className="container-fluid filter-fields">
          <div className="row">
            {fields.map(this.renderFormField)}
          </div>

          <div className="row">
            <div className="col-md-12 d-flex justify-content-end">
              <FilterActions
                formName={this.props.form}
                dispatch={this.props.dispatch}
                filtersResetCallback={this.filtersResetCallback}
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

FilterAgencies = reduxForm({
  form: 'FilterAgencies',
  enableReinitialize : true,
})(FilterAgencies);

const mapStateToProps = (state) => {
  const { filterbox } = state.filterBox;
  return {
    local: state.localization,
    login: state.login,
    common: state.common,
    register: state.register,
    currentForm: state.form,
    initialValues: ((filterbox && filterbox.FilterAgencies) ? {
      name: filterbox.FilterAgencies.name,
      city: filterbox.FilterAgencies.city,
      state: filterbox.FilterAgencies.stateId,
      zipCode: filterbox.FilterAgencies.zipCode,
      country: filterbox.FilterAgencies.countryId
    } : {})
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      ...bindActionCreators(applyActions, dispatch),
      ...loadApplyOptions
    },
    commonActions: bindActionCreators(commonActions, dispatch),
    registerActions: bindActionCreators(registerActions, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch),
    agenciesActions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterAgencies);
