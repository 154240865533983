import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Form, Field } from 'redux-form';
import _ from 'lodash';
import Spinner from '../../../../common/spinner';
import DatePicker from 'react-date-picker';
import CloseIcon from '@material-ui/icons/Close';
import moment from "moment";
import * as filesActions from '../actions';
import { bindActionCreators } from 'redux';
import './manageFileExpiration.css';

class ManageFileExpiration extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      message: ""
    }
  }
  validation = () => {
    if(!this.props.selectedFile.expirationDate || !moment(this.props.selectedFile.expirationDate).isValid()){
      this.setState({
        message : 'Please enter expiration date.'
      })
    }else if(!moment(this.props.selectedFile.expirationDate).isAfter(new Date())){
      this.setState({
        message : 'Expiration date should be future date.'
      })
    }else if(!this.props.selectedFile.templateId){
      this.setState({
        message : 'Please select template.'
      })
    }else{
      this.updatefileExpirationDate()
    }
  }
  updatefileExpirationDate = async () => {
    const self = this.props;
    await this.props.filesActions.updatefileExpirationDate(self.selectedFile, self.hcId, self.subcontractorId, (err, result) =>{
       if(err){
        this.setState({
          message : err
        })
       }
       else{
        this.props.closeExDateModal()
       }
    })
   }
  resetMessage = () => {
    this.setState({
      message : ""
    })
  }

  onChangeFileExpirationDate = (value) => {
    if (value){
      this.props.onDateChange(value); 
      this.resetMessage("");
    }else{
      this.props.onDateChange(""); 
    }
  }

  render() {
    const {
      title,
      tempalte,
      expireDate,
      cancelButtonText,
      addButtonText,
      updateButtonText
    } = this.props.local.strings.scProfile.files.editModal;
    return (
      <div className="add-file-modal">
        <header className="add-file-modal-header">{title}</header>

        <div className="add-file-content container-fluid filter-fields">
          <div className="row">
            <div className="col-sm-12 no-padd">
            <div className="col-md-12 col-sm-12 no-padding">
                <div className="admin-form-field-wrapper">
                  <label htmlFor="addEditExDate" className="required">{expireDate}: </label>
                  <DatePicker
                    value={ this.props.selectedFile.expirationDate && moment(this.props.selectedFile.expirationDate).isValid() ? moment(this.props.selectedFile.expirationDate).toDate(): ""}
                    format="MM/dd/yyyy"
                    placeholderText="mm/dd/yyyy"
                    dayPlaceholder={"dd"}
                    monthPlaceholder={"mm"}
                    yearPlaceholder={"yyyy"}
                    minDate={new Date("02-01-1111")}
                    maxDate={new Date("02-29-9999")}
                    onChange={value => this.onChangeFileExpirationDate(value)} 
                    clearIcon={ this.props.selectedFile.expirationDate && moment(this.props.selectedFile.expirationDate).isValid() ? <CloseIcon fontSize="small" className="custom-calendar-icon close-icon" id="close-icon" onClick={() => this.props.onDateChange("")} /> : null}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}         
                  />
                </div>
              </div>
              <div className="col-md-12 col-sm-12 no-padding mt-4">
                <div className="admin-form-field-wrapper">
                  <label htmlFor="addEditExDate" className="required">{tempalte}: </label>
                  <select name={"fileExTempalte"} style={{height:30}} className="w-100 border border-info bg-white" id={"fileExTempalte"} value={this.props.selectedFile.templateId} onChange={(value) => {this.props.onTempalteChange(value); this.resetMessage()}}>
                  <option value="">Select Template:</option>
                    {this.props.fileExTemplate && this.props.fileExTemplate.length > 0 && this.props.fileExTemplate.map((template,idx) => {
                      return <option value={template.Id
                      } key={idx}>{template.TemplateName}</option>
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-2'>
            <div className='col-12 no-padding'>
              <span className='text-danger'>{this.state.message}</span>
            </div>
          </div>
          <div className="wiz-buttons">
              <button
                className="bn bn-small bg-green-dark-gradient create-item-bn icon-save"
                type="button" onClick={this.validation}>
                {this.props.selectedFile.expirationDate ? updateButtonText : addButtonText}
              </button>
              <a
                className="cancel-add-item"
                onClick={this.props.closeExDateModal}>
                {cancelButtonText}
              </a>          
          </div>
        </div >

      </div >
    );
  }
};

const mapStateToProps = (state) => {
  return {
    users: state.users,
    local: state.localization,
    loginProfile: state.login.profile,
    hcId: state.SCProfile.hcId,
  };
};
const mapDispatchToProps = dispatch => ({
  filesActions: bindActionCreators(filesActions, dispatch),
});
export default connect(mapStateToProps,mapDispatchToProps)(ManageFileExpiration);
