import * as types from './types';
import { getYurbiUserInfo, getYurbiDashboard } from "../api";
import moment from "moment";

export const setDashboards = (dashboards) => {
    return {
        type: types.SET_DASHBOARDS,
        dashboards,
    };
}

export const reset = () => {
    return {
        type: types.RESET,
    }
}

export const setError = (error, errorMessage) => {
    return {
        type: types.SET_ERROR,
        error,
        errorMessage
    };
}

export const setSession = (sessionToken, sessionTimeout) => {
    return {
        type: types.SET_SESSION,
        sessionToken,
        sessionTimeout,
    };
}

export const setSessionApiCallStatus = (sessionAPICallStatus) => {
    return {
        type: types.SET_SESSION_API_CALL,
        isFetchApiCall: sessionAPICallStatus,
    };
}

export const setDashboardApiCallStatus = (dasboardAPICallStatus) => {
    return {
        type: types.SET_DASHBOARD_API_CALL,
        isDashboardApiCall: dasboardAPICallStatus,
    };
}

export const fetchSession = (sessionToken, sessionTimeout) => {
    return async (dispatch) => {
        try {
            // Check expiry, reAuth if expired
            if(!sessionToken || !sessionTimeout || moment(sessionTimeout).isBefore(moment())) {
                const yurbiUserInfo = await getYurbiUserInfo();
                const { SessionToken, SessionExpir } = yurbiUserInfo.yurbi;
                dispatch(setSession(SessionToken, SessionExpir));
            }
        } catch (error) {
            dispatch(setError('BI authorization error', error));
        }
    }
}

export const fetchDashboards = (module, sessionToken) => {
    return async (dispatch) => {
        try {
            const yurbiDashboards = await getYurbiDashboard(sessionToken);
            if(yurbiDashboards[module + "DashboardId"] === null) {
                throw Error(`${module} dashboard not found`);
            }
            dispatch(setDashboards(yurbiDashboards));
        } catch (error) {
            dispatch(setError('BI dashboard error', error));
        }
    }
}
