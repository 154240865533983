import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import _ from 'lodash';

import * as registerActions from '../actions';
import CompanyProfileForm from './form';
import Utils from '../../../lib/utils';

export const CompanyProfileTab = (props) => {
  const onSubmit = (values) => {
    const companyPayload = {
      id: props.register.subcontractorId,
      hiringClientId: props.register.hiringClientId,
      companyName: Utils.replaceQuotes(values.companyName),
      tradeId: _.get(values, 'trade.value', 0),
      secTradeId: _.get(values, 'secondarytrade.value', 0),
      terTradeId: _.get(values, 'tertiarytrade.value', 0),
      quatTradeId: _.get(values, 'quaternaryTrade.value', 0),
      quinTradeId: _.get(values, 'quinaryTrade.value', 0),
      address: values.address,
      city: values.city,
      StateId:_.get(values, 'state.value', 0),
      zipCode: values.zipCode,
      mainEmail: props.register.userPayload.email,
      countryId: _.get(values, 'country.value', 0),
      taxId: values.taxId,
      agree: values.agree
    };
    props.actions.sendRegistration(props.register.userPayload, companyPayload);
  };

  return (
    <React.Fragment>
      <CompanyProfileForm onSubmit={onSubmit}/>
      <div className="register-modal-error">
        {props.register.errorCompanyRegister}
      </div>
    </React.Fragment>
  );
};

CompanyProfileTab.propTypes = {
  register: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.shape({
      subcontractorId: PropTypes.number,
      hiringClientId: PropTypes.number,
      companyPayload: PropTypes.object,
      errorCompanyRegister: PropTypes.string
    })
  ]),
  actions: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.shape({
      setCompanyPayload: PropTypes.func.isRequired,
      sendRegistration: PropTypes.func.isRequired
    })
  ]).isRequired
};

CompanyProfileTab.defaultProps = {
  register: {errorCompanyRegister: null},
  actions: {
    setCompanyPayload: () => {},
    sendRegistration: () => {}
  }
};

const mapStateToProps = (state, ownProps) => ({register: state.register});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(registerActions, dispatch)
});

const withRedux = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(withRedux(CompanyProfileTab));