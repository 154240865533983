import { LOCAL_STORE_KEY_CURRENT_SYSTEM, PQ_CURRENT_SYSTEM, AUTH_TOKEN, SSO } from "../lib/appConstants";

async function readFileUrlAndReturnBlob (fileUrl) {
  try{
    let response = await fetch(fileUrl);
    return await response.blob();
  }catch(err){
    return null  
  }
}

export const createObjectUrlAndDownloadFile = async (fileUrl,fileName) => {
  let blob =  await  readFileUrlAndReturnBlob(fileUrl);
  if(blob){
    let downloadLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    downloadLink.href = url;  
    downloadLink.setAttribute('download',fileName);
    downloadLink.target = '_blank';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
}
export const createElement = (node) => {
  let element = document.createElement("span");
  element.setAttribute('id',node.href);
  element.setAttribute('value',node.innerText);
  element.classList.add("custom-file-span")
  element.innerHTML = node.innerText;
  return element
}
// This method only used for downloading files under formio forms
export const createFormIoDownloadFileElements = async (targetSelector='a[ref="fileLink"]') => {
  // Download file event listener, dedicated to this method so this is an inner function
  const downloadEventListener = async (e) => {
    if (!e || !e.target) return;

    const fileUrl = e.target.id;
    const fileName = e.target.innerText;

    await createObjectUrlAndDownloadFile(fileUrl, fileName);
  }

  if(document) {
    const elements = document.querySelectorAll(targetSelector);
    if (elements && elements.length > 0) {
      elements.forEach(async (anchorElement) => {
        const element = createElement(anchorElement);
        element.addEventListener('click', downloadEventListener);
        anchorElement.parentElement.replaceChild(element, anchorElement)
      });
    }
  }
}

export const IsCurrentSystemPQ = () => {
  return localStorage.getItem(LOCAL_STORE_KEY_CURRENT_SYSTEM) === PQ_CURRENT_SYSTEM;
}

export const removeTokenAndSession = () => {
  if (localStorage) {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(SSO);
  }
  if (sessionStorage) {
    sessionStorage.removeItem(AUTH_TOKEN);
  }
  
}