import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DocumentTypesForm from './form';

import * as documentTypesActions from '../../settings/documentTypes/actions';
import * as commonActions from '../../../common/actions';

import '../addEntityModal.css';

class AddDocumentTypesModal extends Component {
  send = (values) => {
    const { selectedDocumentType } = this.props;
    const serializedObj = {
      ...values,
      ...(selectedDocumentType ? { documentTypeId: selectedDocumentType.DocumentTypeID } : {}),
    };

    this.props.actions.sendDocumentTypes(serializedObj, this.props.close);
  };

  hideModal = () => {
    const { onHide, close } = this.props;
    if (onHide) onHide();
    else close();
  }

  render() {
    const {
      title,
      titleEdit,
    } = this.props.local.strings.certFocusSettings.documentTypes.addModal;

    const titleText = this.props.selectedDocumentType ? titleEdit : title;
    
    return (
      <div className="add-item-view add-entity-form-small">
        <div className="add-item-header">
          <h1>{titleText}</h1>
        </div>

        <section className="white-section">
          <div className="add-item-form-subsection">
            <DocumentTypesForm
              close={this.hideModal}
              onSubmit={this.send}
              selectedDocumentType={this.props.selectedDocumentType}
            />
          </div>
        </section>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    local: state.localization,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(documentTypesActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDocumentTypesModal);
