import swal from "sweetalert2";
import { ToastContainer, toast } from 'react-toastify';

export function showInformationAlert(
  title,
  text,
  confirmButtonText,
  showCancelButton,
  callbackFunction
) {
  const cb = callbackFunction;
  swal({
    title,
    text,
    type: "info",
    showCancelButton,
    cancelButtonText: "Cancel",
    confirmButtonClass: "btn-danger",
    confirmButtonColor: "#2e5965",
    confirmButtonText,
  }).then((result) => {
    cb(result.value);
  });
}

/**
 * Show sweetalert2 warning modal
 * @param title {String} Modal title
 * @param text {String} Modal text
 * @param callback {Function|undefined} Optional callback to be executed after closing modal
 */
export function showWarningAlert(title, text, callback) {
  swal({
    type: "warning",
    title,
    text,
    onClose: () => {
      if (callback) callback();
    },
  });
}

// content = {title, text, btn_no, btn_yes}
export function showActionConfirmation(content, callbackFunction) {
  const cb = callbackFunction;
  swal({
    title: content.title,
    text: content.text,
    type: "question",
    showCancelButton: true,
    cancelButtonText: content.btn_no,
    cancelButtonColor: "#2e5965",
    confirmButtonClass: "btn-danger",
    confirmButtonColor: "#2e5965",
    confirmButtonText: content.btn_yes,
    allowEscapeKey: false,
    allowOutsideClick: false,
  }).then((result) => {
    cb(result.value);
  });
}

export function showQuickConfirmation(content) {
  let timerInterval;
  swal({
    html: `<h5>${content.title}</h5>`,
    timer: content.timer,
    showConfirmButton: false,
    onClose: () => {
      clearInterval(timerInterval);
    },
  });
}

export function showErrorAlert(title, html, callback) {
  swal({
    type: "error",
    title,
    html,
    confirmButtonColor: "#f27474",
    onClose: () => {
      if (callback) callback();
    },
  });
}

export function showRenewalDateErrorAlert(title, html, callback) {
  swal({
    type: "error",
    title,
    html,
    confirmButtonColor: "#f27474",
    onClose: () => {
      if (callback) callback();
    },
  });
}

export function showAlertMessage(type, title, callback) {
  let color = "#0ba133";
  if (type === "success") {
    color = "#0ba133";
  } else if (type === "info") {
    color = "#66c2ff";
  } else if (type === "warning") {
    color = "#ffa560";
  } else if (type === "error") {
    color = "#f05e5e";
  }
  swal({
    type,
    title:`<h2 style="color:${color}">${title}</h2>`,
    onClose: () => {
      if (callback) callback();
    },
  });
}

export function showToastSuccessNotification(successMsg) {
  toast.success(successMsg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  });
}