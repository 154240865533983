import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import Sidebar from '../components/common/sidebar';
import Header from '../components/common/header';
import Overlay from '../components/common/overlay';
import Loader from '../components/common/loader';
import Announcement from '../components/common/announcement';
import RedirectByRole from './redirectByRole';

import * as loginActions from '../components/auth/login/actions';
import NeedHelp from '../components/common/needHelp';
import RolAccess from '../components/common/rolAccess';
import RefreshToken from '../components/auth/refreshToken/RefreshToken';
import StaticLoader from '../components/common/loader/StaticLoader';

const PrivateRoute = ({ component: Component, onlyHeader, hasSidebar, title, cfTitle, store, fromhc, ...rest }) => {
  //console.log('title', title);
  const { isLoggedIn, profile } = store.getState().login;
  const requests = async () => {
    await store.dispatch(loginActions.getProfile());
  };

  useEffect(() => {
    if (_.isEmpty(profile)) {
      requests();
    }
  }, [profile])

  const renderTitle = () => {
    const { profile } = store.getState().login
    const { Role, CFRole } = profile

    const isOnlyPrequal = Role && !CFRole && title
    const isOnlyCertFocus = !Role && CFRole && cfTitle
    //const isBoth = Role && CFRole && title && cfTitle

    let titleTxt = ''

    if (isOnlyPrequal) {
      titleTxt = title
    } else if (isOnlyCertFocus) {
      titleTxt = cfTitle
    } else {
      let _cfTitle = cfTitle ? cfTitle : '';
      let _title = title ? title : '';

      titleTxt = `${_title} - ${_cfTitle}`
      if (!cfTitle || !title) {
        titleTxt = titleTxt.replace('-', '');
      }
    }

    if (rest.path.indexOf('hiringclients') !== -1) {
      return rest.common.headerTitle || titleTxt || ''
    }
    return titleTxt
  }

  const renderPrivateRoute = (props) => {

    let mainStyle = {};
    if (onlyHeader) {
      mainStyle = {
        left: 0,
        width: '100%'
      }
    }

    if (isLoggedIn) {

      const {
        Role,
        CFRole,
        multipleSubcontractors,
        multipleStrictHCs,
        HCuserMultipleStrictSubs,
        // relatedProjects,
        // relatedProjectInsureds,
      } = profile;

      // console.log('multipleStrictHCs ==',rest.multipleStrictHCs);
      // console.log('multipleSubcontractors ==',rest.multipleSubcontractors);
      // console.log('roleID ==',rest.roleID);

      // console.log('profile = ', profile)

      let routeAllowed = false;
      const urlPath = window.location.pathname.split('/');
      // console.log('rest = ', rest)
      // console.log('The URL the Server is working with = ', window.location.pathname)
      if (Array.isArray(urlPath)) {
        if (Role) {
          const currentRoute = urlPath[1];
          const currentURLId = parseInt(urlPath[2]);

          switch (currentRoute) {
            case "subcontractors":
              if (!isNaN(currentURLId) && Role.Id === 4) {
                if (Array.isArray(multipleSubcontractors)) {
                  routeAllowed =
                    multipleSubcontractors &&
                    multipleSubcontractors.includes(currentURLId);

                  if (!routeAllowed) {
                    return (
                      <Redirect
                        to={{
                          pathname: "/profile",
                          state: {
                            from: props.location,
                          },
                        }}
                      />
                    );
                  }
                }
              } else if (
                !isNaN(currentURLId) &&
                (Role.Id === 3 || Role.Id === 6)
              ) {
                if (Array.isArray(HCuserMultipleStrictSubs)) {
                  routeAllowed =
                    HCuserMultipleStrictSubs &&
                    HCuserMultipleStrictSubs.includes(currentURLId);

                  if (!routeAllowed) {
                    return (
                      <Redirect
                        to={{
                          pathname: "/profile",
                          state: {
                            from: props.location,
                          },
                        }}
                      />
                    );
                  }
                }
              } else if (
                isNaN(currentURLId) &&
                (Role.Id === 3 || Role.Id === 6)
              ) {
                return (
                  <Redirect
                    to={{
                      pathname: "/profile",
                      state: {
                        from: props.location,
                      },
                    }}
                  />
                );
              }

              break;
            case "hiringclients":
              if (!isNaN(currentURLId) && (Role.Id === 3 || Role.Id === 6)) {
                if (Array.isArray(multipleStrictHCs)) {
                  routeAllowed =
                    multipleStrictHCs &&
                    multipleStrictHCs.includes(currentURLId);

                  if (!routeAllowed) {
                    return (
                      <Redirect
                        to={{
                          pathname: "/profile",
                          state: {
                            from: props.location,
                          },
                        }}
                      />
                    );
                  }
                }
              }
              break;
            default:
            // console.log('Router without control');
          }
        }
        if (CFRole) {
          const currentRoute = urlPath[2];
          const HolderRoleIds = [10, 20, 21, 22, 23, 24];

          switch (currentRoute) {
            case "insureds": {
              const currentURLId = parseInt(urlPath[3]);
              if (!isNaN(currentURLId) && HolderRoleIds.includes(CFRole.Id)) {
                if (Array.isArray(HCuserMultipleStrictSubs)) {
                  routeAllowed =
                    HCuserMultipleStrictSubs &&
                    HCuserMultipleStrictSubs.includes(currentURLId);

                  if (!routeAllowed) {
                    return (
                      <Redirect
                        to={{
                          pathname: "/profile",
                          state: {
                            from: props.location,
                          },
                        }}
                      />
                    );
                  }
                }
              }
              break;
            }
            case "holders": {
              const currentURLId = parseInt(urlPath[3]);
              if (!isNaN(currentURLId) && HolderRoleIds.includes(CFRole.Id)) {
                if (Array.isArray(multipleStrictHCs)) {
                  routeAllowed =
                    multipleStrictHCs &&
                    multipleStrictHCs.includes(currentURLId);
                  if (!routeAllowed) {
                    return (
                      <Redirect
                        to={{
                          pathname: "/profile",
                          state: {
                            from: props.location,
                          },
                        }}
                      />
                    );
                  }
                }
              }
              break;
            }
            // case "projects": {
            //   const currentURLId = parseInt(urlPath[3]);
            //   if (!isNaN(currentURLId) && HolderRoleIds.includes(CFRole.Id)) {
            //     if (Array.isArray(relatedProjects)) {
            //       routeAllowed =
            //         relatedProjects &&
            //         relatedProjects.includes(currentURLId);

            //       if (!routeAllowed) {
            //         return (
            //           <Redirect
            //             to={{
            //               pathname: "/profile",
            //               state: {
            //                 from: props.location,
            //               },
            //             }}
            //           />
            //         );
            //       }
            //     }
            //   }
            //   break;
            // }
            // case "certificates": {
            //   const currentURLId = parseInt(urlPath[3]);
            //   if (!isNaN(currentURLId) && HolderRoleIds.includes(CFRole.Id)) {
            //     if (Array.isArray(relatedProjectInsureds)) {
            //       routeAllowed =
            //         relatedProjectInsureds &&
            //         relatedProjectInsureds.includes(currentURLId);

            //       if (!routeAllowed) {
            //         return (
            //           <Redirect
            //             to={{
            //               pathname: "/profile",
            //               state: {
            //                 from: props.location,
            //               },
            //             }}
            //           />
            //         );
            //       }
            //     }
            //   }
            //   break;
            // }
            default:
            // console.log('Router without control');
          }
        }
      }
      
      const needHelp = () =>{
        return <NeedHelp/>
      }

      const withSidebar = (
        <div>
          <Loader />
          <RedirectByRole location={props.location} path={rest.path} />
          <div>
            <div className="sidebar-root">
              {
                onlyHeader ? null :
                  <div className="sidebar-col" id="sidebar-menu">
                    <Sidebar ref="sidebar" />
                  </div>
              }
              <div id="main" className={`body-col ${props.match.params.hcId ? 'profile-body' : ''}`} style={mainStyle}>
                <RolAccess
                 masterTab="authenticated_users"
                 sectionTab="authenticated_routes"
                 component={()=>needHelp()}>
                </RolAccess>

                <Overlay style={{ height: '100%' }} />
                <Announcement />
                <Header className="header" title={renderTitle()} hcId={props.match.params.hcId} onlyHeader={onlyHeader} />
                {
                  !_.isEmpty(profile) ? <Component {...props} fromhc={!!fromhc} /> : <StaticLoader />
                }
                
                <RefreshToken />
              </div>
            </div>
          </div>
        </div>
      );


      // if (Role) {
      return hasSidebar || onlyHeader ? withSidebar : (
        <div>
          <RedirectByRole location={props.location} path={rest.path} />
          <Component {...props} />
          <RefreshToken />
        </div>
      );
      // } else {
      //   return (<div></div>);
      // }

    } else {
      if (!rest.path.includes('login')) {
        return <Redirect to={{
          pathname: '/login',
          state: {
            from: props.location
          }
        }} />;
      }
      return null; 
    }
  };

  return (
    <Route {...rest} etv={true} render={renderPrivateRoute} />
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    common: state.common,
    multipleStrictHCs: state.login.profile.multipleStrictHCs,
    multipleSubcontractors: state.login.profile.multipleSubcontractors,
    roleID: state.login.profile.RoleID,
    isLoggedIn: state.login.isLoggedIn
  };
};

export default connect(mapStateToProps)(PrivateRoute);