import * as types from './types';
import Api from '../../../lib/api';
import Utils from '../../../lib/utils';
import sizeof from 'object-sizeof'
import {PQ_ADMIN_ROLE_ID, CF_ADMIN_ROLE_ID, SUBCONTRACTOR_ROLE_ID} from '../../../lib/appConstants';

// sets

export const setCurrentEditingUser = (user = {}) => {
  return {
    type: types.SET_CURRENT_EDITING_USER,
    payload: user
  }
}

export const setUsersListError = (error) => {
  return {
    type: types.SET_USERS_LIST_ERROR,
    error
  };
};

export const setUsersList = (list, errors) => {
  return {
    type: types.SET_USERS_LIST,
    usersData: {
      list,
      statusInactive: errors.statusInactive,
      statusChangePassword: errors.statusChangePassword,
      statusActive: errors.statusActive
    }
  };
};

export const setUsersNpqList = (nPQlist, errors) => {
  return {
    type: types.SET_USERS_N_PQ_LIST,
    usersData: {
      nPQlist,
      statusInactive: errors.statusInactive,
      statusChangePassword: errors.statusChangePassword,
      statusActive: errors.statusActive
    }
  };
};

export const setHiringClientsOptions = (options, allLabel) => {
  return {
    type: types.SET_HIRING_CLIENTS_OPTIONS,
    HCData: {
      options,
      allLabel
    }
  };
};

export const setSubcontractorOptions = (options, allLabel) => {
  return {
    type: types.SET_SUBCONTRACTOR_OPTIONS,
    SCData: {
      options,
      allLabel
    }
  };
};

export const setPopoverHiringClients = (list) => {
  return {
    type: types.SET_POPOVER_HIRINGCLIENTS,
    list
  };
};

export const setPopoverSubcontractors = (list) => {
  return {
    type: types.SET_POPOVER_SUBCONTRACTORS,
    list
  };
};

export const setPopoverLogs = (list) => {
  return {
    type: types.SET_POPOVER_LOGS,
    list
  };
};

export const setHiringClientsTags = (list) => {
  return {
    type: types.SET_HIRING_CLIENTS_TAGS,
    list
  };
};

  export const setSubContractorsTags = (list) => {
    return {
      type: types.SET_SUBCONTRACTORS_TAGS,
      list
    };
  };

export const setFetchingUsers = (isFetching) => {
  return {
    type: types.SET_FETCHING_USERS,
    isFetching
  };
};

export const setFetchingPopOverHC = (isFetching) => {
  return {
    type: types.SET_FETCHING_POPOVER_HC,
    isFetching
  };
};

export const setFetchingPopOverSC = (isFetching) => {
  return {
    type: types.SET_FETCHING_POPOVER_SC,
    isFetching
  };
};

export const setFetchingPopOverLogs = (isFetching) => {
  return {
    type: types.SET_FETCHING_POPOVER_LOGS,
    isFetching
  };
};

export const setTotalAmountOfUsers = (usersLength) => {
  return {
    type: types.SET_TOTAL_AMOUNT_OF_USERS,
    usersLength
  };
};

export const setUserLogs = (logs)=> {
  return {
    type: types.SET_LOGS,
    logs
  }
};

export const setTotalAmountOfLogs = (total)=> {
  return {
    type: types.SET_TOTAL_LOGS,
    total
  }
};

export const setFetchingLogs = (isFetching)=> {
  return {
    type: types.SET_FETCHING_LOGS,
    isFetching
  }
};

export const setShowOverlayAddUser = (flag) => {
  return {
    type: types.SET_SHOW_OVERLAY_ADDUSER,
    flag
  }
};

export const setHcsForSubUser = hcs => {
  return {
    type: types.SET_HCS_FOR_SC_PROFILE,
    hcs
  }
}

export const setActiveSubUsers = activeSubUsers => {
  return {
    type: types.SET_ACTIVE_SUB_USERS,
    activeSubUsers
  };
};


// add and delete

export const addHiringClientTag = (tag) => {
  return {
    type: types.ADD_HIRINGCLIENT_TAG,
    value: tag.text,
    id: tag.id
  };
};

export const addSubContractorTag = (tag) => {
  return {
    type: types.ADD_SUBCONTRACTOR_TAG,
    value: tag.text,
    id: tag.id
  };
};

export const deleteHCTag = (id) => {
  return {
    type: types.DELETE_HIRINGCLIENT_TAG,
    id
  };
};

export const deleteSCTag = (id) => {
  return {
    type: types.DELETE_SUBCONTRACTOR_TAG,
    id
  };
};


//True - false

export const isScIdChangedInEdition = isScIdChanged => {
  return {
    type: types.IS_SC_ID_CHANGE_IN_EDITION,
    isScIdChanged
  };
};

export const fetchUsersHiringClientsAndOrSubcontractors = (userId) => {
  return (dispatch, getState) => {
    const { login, localization } = getState();
    let {
      error10005,
      error10006,
      error10007,
      error10011,
      errorDefault,
      errorConnection,
    } = localization.strings.users.actions;

    dispatch(setFetchingPopOverHC(true));

    const token = login.authToken;
    let urlQuery = `users/hiringClientsAndOrSubcontractors?userId=${userId}`;
    return Api.get(urlQuery, token)
    .then(response => {
      const { success, data } = response.data;
      let errorMsg = '';
      if(success) {
        if (data.hiringClients && data.hiringClients.length > 0) {
          dispatch(setHiringClientsTags(data.hiringClients));
        }
        if (data.subContractors && data.subContractors.length > 0) {
          dispatch(setSubContractorsTags(data.subContractors));
        }
      }
      else {
        switch(data.errorCode) {
          case 10005:
            errorMsg = error10005;
          break;
          case 10006:
            errorMsg = error10006;
            break;
          case 10007:
            errorMsg = error10007;
            break;
          case 10011:
            errorMsg = error10011;
            break;
          default:
            errorMsg = errorDefault;
            break;
        }
      //console.log('line 238 errorMsg = ', errorMsg)
        dispatch(setUsersListError(errorMsg));
      }
      dispatch(setFetchingPopOverHC(false));
    })
    .catch(error => {
    //console.log('line 238 error = ', error)
      dispatch(setUsersListError(errorConnection));
    });
  };
};

export const fetchHiringClients = (id, subcontractorId) => {
  return (dispatch, getState) => {
    const { login, localization } = getState();
    let {
      error10005,
      error10006,
      error10007,
      error10011,
      errorDefault,
      errorConnection,
    } = localization.strings.users.actions;
    const {allHC} = localization.strings.users.reducer;
    const token = login.authToken;
    let urlQuery = 'hiringclients';
    if(id) {
      dispatch(setFetchingPopOverHC(true));
      urlQuery = `${urlQuery}?userId=${id}`
    }
    return Api.get(urlQuery, token)
    .then(response => {
      const {success, data } = response.data;
      let errorMsg = '';
      if(success) {
        if(id) {
          dispatch(setPopoverHiringClients(data.hiringClients));
        }
        else {
          dispatch(setHiringClientsOptions(data.hiringClients, allHC));
        }
      }
      else {
        switch(data.errorCode) {
          case 10005:
            errorMsg = error10005;
          break;
          case 10006:
            errorMsg = error10006;
            break;
          case 10007:
            errorMsg = error10007;
            break;
          case 10011:
            errorMsg = error10011;
            break;
          default:
            errorMsg = errorDefault;
            break;
        }
      //console.log('line 238 errorMsg = ', errorMsg)
        dispatch(setUsersListError(errorMsg));
        if(id) {
          dispatch(setPopoverHiringClients([]));
        }
      }
      dispatch(setFetchingPopOverHC(false));
    })
    .catch(error => {
    //console.log('line 238 error = ', error)
      dispatch(setUsersListError(errorConnection));
    });
  };
};

export const fetchSubContractorsByKeyword = (keyword, showCF, showPQ) => {
  return (dispatch, getState) => {
    const { login, localization } = getState();
    let {
      error10005,
      error10006,
      error10007,
      error10011,
      errorDefault,
      errorConnection,
    } = localization.strings.users.actions;
    const { select } = localization.strings.users.reducer;
    const token = login.authToken;
    const payload = {
      showPrequalEnabled: showPQ,
      showCertfocusEnabled: showCF
    }
    if (keyword) {
      let encoded = encodeURIComponent(keyword);
      keyword = encoded;
    }
    return Api.post(`subcontractors_by_keyword?keyword=${keyword}`, payload, token)
    .then(response => {
      const { success, data } = response.data;
      let errorMsg = '';
      if(success) {
        dispatch(setSubcontractorOptions(data.subContractors, select))  ;
      }
      else {
        switch(data.errorCode) {
          case 10005:
            errorMsg = error10005;
          break;
          case 10006:
            errorMsg = error10006;
            break;
          case 10007:
            errorMsg = error10007;
            break;
          case 10011:
            errorMsg = error10011;
            break;
          default:
            errorMsg = errorDefault;
            break;
        }
        //console.log('line 238 errorMsg = ', errorMsg)
        dispatch(setUsersListError(errorMsg));
      }
      dispatch(setFetchingPopOverSC(false));
    })
    .catch(error => {
      //console.log('line 309 error = ', error)
      dispatch(setUsersListError(errorConnection));
    });
  };
};

export const fetchSubContractorsForPopover = (id) => {
  return (dispatch, getState) => {
    const { login, localization } = getState();
    let {
      error10005,
      error10006,
      error10007,
      error10011,
      errorDefault,
      errorConnection,
    } = localization.strings.users.actions;
    const token = login.authToken;

    dispatch(setFetchingPopOverSC(true));

    return Api.get(`subcontractors/popover?userId=${id}`, token)
    .then(response => {
      const {success, data } = response.data;
      let errorMsg = '';
      if(success) {
        dispatch(setPopoverSubcontractors(data.subContractors));
      }
      else {
        switch(data.errorCode) {
          case 10005:
            errorMsg = error10005;
          break;
          case 10006:
            errorMsg = error10006;
            break;
          case 10007:
            errorMsg = error10007;
            break;
          case 10011:
            errorMsg = error10011;
            break;
          default:
            errorMsg = errorDefault;
            break;
        }
        //console.log('line 405 errorMsg = ', errorMsg)
        dispatch(setUsersListError(errorMsg));
        dispatch(setPopoverSubcontractors([]));
      }
      dispatch(setFetchingPopOverSC(false));
    })
    .catch(error => {
      //console.log('line 412 error = ', error)
      dispatch(setUsersListError(errorConnection));
    });
  };
};

export const fetchSubContractors = (id) => {
  return (dispatch, getState) => {
    const { login, localization } = getState();
    let {
      error10005,
      error10006,
      error10007,
      error10011,
      errorDefault,
      errorConnection,
    } = localization.strings.users.actions;
    const {allSC} = localization.strings.users.reducer;
    const token = login.authToken;
    let urlQuery = 'subcontractors';
    if(id) {
      dispatch(setFetchingPopOverSC(true));
      urlQuery = `${urlQuery}?userId=${id}`
    }
    return Api.get(urlQuery, token)
    .then(response => {
      const {success, data } = response.data;
      let errorMsg = '';
      if(success) {
        if(id) {
          dispatch(setPopoverSubcontractors(data.subContractors));
        }
        else {
          dispatch(setSubcontractorOptions(data.subContractors, allSC))  ;
        }
      }
      else {
        switch(data.errorCode) {
          case 10005:
            errorMsg = error10005;
          break;
          case 10006:
            errorMsg = error10006;
            break;
          case 10007:
            errorMsg = error10007;
            break;
          case 10011:
            errorMsg = error10011;
            break;
          default:
            errorMsg = errorDefault;
            break;
        }
        //console.log('line 238 errorMsg = ', errorMsg)
        dispatch(setUsersListError(errorMsg));
        if(id) {
          dispatch(setPopoverSubcontractors([]));
        }
      }
      dispatch(setFetchingPopOverSC(false));
    })
    .catch(error => {
      //console.log('line 309 error = ', error)
      dispatch(setUsersListError(errorConnection));
    });
  };
};

export const fetchLogs = (query_params) => {
  return (dispatch, getState) => {
    const { login, localization } = getState();
    let {
      error10005,
      error10006,
      error10007,
      error10011,
      errorDefault,
      errorConnection,
    } = localization.strings.users.actions;
    const token = login.authToken;
    let queryParams = {...query_params};
    if(queryParams.userId) {
      dispatch(setFetchingPopOverLogs(true));
    } else {
      dispatch(setTotalAmountOfLogs(0));
      dispatch(setFetchingLogs(true))
    }

    if(queryParams) {
      if(!queryParams.pageNumber) {
        queryParams.pageNumber = 1;
      }
    }
    else {
      queryParams = {
        pageNumber: 1
      };
    }
    queryParams.pageSize = getState().users.logsPerPage;


    let payload = queryParams
    return Api.post(
      'getLog',
      payload,
      token)
    .then(response => {
      const { data, success } = response.data;
      let errorMsg = '';

      if (success && data.totalCount === 0) {
        dispatch(setPopoverLogs(data.logEntries));

        dispatch(setTotalAmountOfLogs(0));
        dispatch(setUserLogs([]));
      } else if(success && data.totalCount > 0) {
        dispatch(setTotalAmountOfLogs(data.totalCount));
        dispatch(setUserLogs(data.logEntries));
      }
      else {
        switch(data.errorCode) {
          case 10005:
            errorMsg = error10005;
          break;
          case 10006:
            errorMsg = error10006;
            break;
          case 10007:
            errorMsg = error10007;
            break;
          case 10011:
            errorMsg = error10011;
            break;
          default:
            errorMsg = errorDefault;
            break;
        }
        dispatch(setUsersListError(errorMsg));
        dispatch(setTotalAmountOfLogs(0));
        dispatch(setUserLogs([]));
      }
      dispatch(setFetchingPopOverLogs(false));
      dispatch(setFetchingLogs(false));
    })
    .catch(error => {
      dispatch(setUsersListError(errorConnection));
    });
  };
};

export const fetchUsers = (queryParams) => {
  return (dispatch, getState) => {
    const { login, localization } = getState();
    let {
      error10003,
      error10005,
      error10006,
      error10007,
      error10011,
      errorDefault,
      errorConnection
    } = localization.strings.users.actions;
    const labels = localization.strings.users.reducer;
    const token = login.authToken;
    let urlParameters = '';

    dispatch(setFetchingUsers(true));

    if(queryParams) {
      if (queryParams.withoutPagination) {
        delete queryParams.pageNumber;
        delete queryParams.pageSize;
      } else {
        queryParams.withoutPagination = false;
        if(!queryParams.pageNumber) {
          queryParams.pageNumber = 1;
        }
        if(!queryParams.orderBy) {
          queryParams.orderBy = 'name';
        }
        queryParams.pageSize = getState().users.usersPerPage;
      }
    }
    else {
      queryParams = {
        pageNumber: 1,
        orderBy: 'name',
        pageSize: getState().users.usersPerPage
      };
    }
    if (queryParams.searchTerm) {
      let encoded = encodeURIComponent(queryParams.searchTerm);
      queryParams.searchTerm = encoded;
    }
    urlParameters = `?${Object.keys(queryParams).map(i => `${i}=${queryParams[i]}`).join('&')}`;
    return Api.get(`users${urlParameters}`, token)
    .then(response => {
      const { success, data } = response.data;
      let errorMsg = '';
      if(success) {
        if (queryParams.associatedOnly || queryParams.admin || queryParams.hcIdSendEmails) {
          dispatch(setTotalAmountOfUsers(data.totalCount));
          dispatch(setUsersList(data.users, labels));
        } else {
          dispatch(setUsersNpqList(data.users, labels));
        }
        dispatch(setUsersListError(''));
      }
      else {
        switch(data.errorCode) {
          case 10003:
            errorMsg = error10003;
            break;
          case 10005:
            errorMsg = error10005;
            break;
          case 10006:
            errorMsg = error10006;
            break;
          case 10007:
            errorMsg = error10007;
            break;
          case 10011:
            errorMsg = error10011;
            break;
          default:
            errorMsg = errorDefault;
            break;
        }
        dispatch(setUsersListError(errorMsg));
        if (queryParams.associatedOnly) {
          dispatch(setTotalAmountOfUsers(0));
          dispatch(setUsersList([], labels));
        } else {
          dispatch(setUsersNpqList([], labels));
        }

      }
      dispatch(setFetchingUsers(false));
    })
    .catch(error => {
      dispatch(setUsersListError(errorConnection));
    });
  };
};

export const setNPQlistToZero = () => {
  return (dispatch, getState) => {
    const { localization } = getState();
    const labels = localization.strings.users.reducer;
    dispatch(setUsersNpqList([], labels));
  }
}

// submits

export const sendUser = (values, closeModal, editUserId) => {

  return (dispatch, getState) => {
    const { login, localization } = getState();

    let {
      error10001,
      error10003,
      error10004,
      error10005,
      error10006,
      error10007,
      error10008,
      error10011,
      error10013,
      error10014,
      error10155,
      errorDefault,
      errorConnection,
    } = localization.strings.users.actions;

    const token = login.authToken;
    let method = 'post';
    dispatch(setUsersListError(''));

    let payload = {
      roleId: `${values.role}`,
      CFRoleId: `${values.cfRole}`,
      firstName: values.firstName,
      lastName: values.lastName,
      mail: values.email,
      password: values.password,
      phone: Utils.normalizePhoneNumber(values.phone),
      mustRenewPass: (values.changeuponlogin ? 1 : 0) + '',
      biAccess: (values.biaccess ? 1 : 0),
      pqAPIAccess: (values.pqapiaccess ? 1 : 0),
      cfAPIAccess: (values.cfapiaccess ? 1 : 0),
      timeZoneId: (parseInt(values.timezone, 10) || 0) + '',
      subContractorId: values.subContractorId,
      HiringClientId: values.HiringClientId,
      HiringClientsMultiple: values.HiringClientsMultiple,
      SubcontractorsMultiple: values.SubcontractorsMultiple,
      Profile : values.profile,
      IsScIdChanged: values.IsScIdChanged,
      SSO: values.ssoAccess ? 1 : 0,
      // HiringClientsMultiple[i].id
    };
    if(values.archiveUser && (values.role != PQ_ADMIN_ROLE_ID && values.role != CF_ADMIN_ROLE_ID)){
      payload.IsEnabled = 0
    } else if(values.archiveUser === true || values.archiveUser === false){
      payload.IsEnabled = values.archiveUser === true ? 0 : 1
    }

    if(editUserId) {
      payload.id = editUserId + '';
      method = 'put';
    }
    if(values.archiveUserError && payload.roleId == SUBCONTRACTOR_ROLE_ID){
      dispatch(setUsersListError('User cannot be removed, at least one user must be active for this subcontractor.'));
      closeModal();
    } else {
      return Api[method](
          `users/profile`,
          payload,
          token)
          .then(response => {
            const { success, data } = response.data;
            let errorMsg = '';
            if(success) {
              closeModal(success, data);
            } else {
              switch(data.errorCode) {
                case 10000:
                case 10001:
                  errorMsg = error10001;
                  break;
                case 10003:
                  errorMsg = error10003;
                  break;
                case 10004:
                  errorMsg = error10004;
                  break;
                case 10005:
                  errorMsg = error10005;
                  break;
                case 10006:
                  errorMsg = error10006;
                  break;
                case 10007:
                  errorMsg = error10007;
                  break;
                case 10008:
                  errorMsg = error10008;
                  break;
                case 10011:
                  errorMsg = error10011;
                  break;
                case 10013:
                  errorMsg = error10013;
                  break;
                case 10014:
                  errorMsg = error10014;
                  break;
                case 10155:
                  errorMsg = error10155;
                  break;
                case 10158:
                  errorMsg = data.description;
                  break;
                default:
                  errorMsg = errorDefault;
                  break;
              }
              dispatch(setUsersListError(errorMsg));
              closeModal();
            }
          })
          .catch(error => {
            dispatch(setUsersListError(errorConnection));
            //console.log('success -> false, err: ', error)
            closeModal();
          });
    }
  };
}

/* Support log user */
export const setLogUsers = (logUsers) => {
  return {
    type: types.SET_LOG_USERS,
    logUsers
  };
};
export const fetchLogUsers = () => {
  return (dispatch, getState) => {
    const token = getState().login.authToken;
    return Api.get(
      `log/users`,
      token
    ).then(response => {
      const {success, data } = response.data;
      if (success && data) {
        dispatch(setLogUsers(data));
      }
    })
    .catch(error => {
      //console.log(error);
    });
  };
}

/* Support log modules */
export const setLogModules = (logModules) => {
  return {
    type: types.SET_LOG_MODULES,
    logModules
  };
};
export const fetchLogModules = () => {
  return (dispatch, getState) => {
    const token = getState().login.authToken;
    return Api.get(
      `log/modules`,
      token
    ).then(response => {
      const {success, data } = response.data;
      if (success && data) {
        dispatch(setLogModules(data));
      }
    })
    .catch(error => {
      //console.log(error);
    });
  };
}

export const toggleHolderUserStatus = (params) => {
  return (dispatch, getState) => {
    const token = getState().login.authToken;
    const method = 'put';
    const payload = {
      holderId: params.holderId,
      userId: params.userId,
    };
    return Api[method](`cf/holders/user/status`, payload, token)
      .then(response => {
        const {success, data} = response.data;
        if (success) {
          return data.statusChanged;
        }
        return false;
      })
      .catch(error => {
        return false;
      });
  }
};

export const setEmailOnOff = (userId, subContractorId, hiringClientId, emailOnOff, callback) => {
  return (dispatch, getState) => {
    const token = getState().login.authToken;
    const method = 'post';
    const payload = {
      hiringClientId: hiringClientId,
      sendEmails: emailOnOff ? '1' : '0',
      subContractorId: Number(subContractorId),
      userId: userId
    };
    
    return Api[method](`users/setEmailOnOff`, payload, token)
      .then(response => {
        const {success, data} = response.data;
        if (success) {
          dispatch(setActiveSubUsers(data[1].activeSubUsers))
          return callback(true, data[0].statusChanged);
        } else {
          return callback(false, data.description);  
        } 
      })
      .catch(error => {
        return callback(false, error);
      });
  }
};
export const setUserEmailOnOff = (userId, hiringClientId, emailOnOff, roleId, callback) => {
  return (dispatch, getState) => {
    const token = getState().login.authToken;
    const method = 'post';

    const payload = {
      hiringClientId: hiringClientId,
      receiveEmail: emailOnOff ? '1' : '0',
      roleId: roleId,
      userId: userId
    };
    
    return Api[method](`users/setUserWorkflowEmailOnOff`, payload, token)
      .then(response => {
        const {success, data} = response.data;
        if (success) {
          return callback(true, data[0].statusChange);
        } else {
          return callback(false, data.description);  
        } 
      })
      .catch(error => {
        return callback(false, error);
      });
  }
};

export const getEmailOnOffFlag = (userId, scId, hcId) => {
  return async (dispatch, getState) => {
    const token = getState().login.authToken;
    const urlQuery = `users/getEmailOnOffFlag?userId=${userId}&scId=${scId}&hcId=${hcId}`
    try {
      const response = await Api.get(`${urlQuery}`, token);
      const { success, data } = response.data;
      if (success) {
        return data.emailFlags;
      }
      return false;
    } catch (error) {
      return false;
    }
  }
};

export const getHiringClientsForUserSubContractor = (scId, callback) => {
  return async (dispatch, getState) => {
    const token = getState().login.authToken;
    const urlQuery = `users/getHiringClientsForUserSubContractor?scId=${scId}`
    try {
      const response = await Api.get(`${urlQuery}`, token);
      const { success, data } = response.data;
      if (success) {
          dispatch(setHcsForSubUser(data.hcs));
          callback(true, data.hcs);
      }
      callback(false, null);
    } catch (error) {
      return callback(false, error);
    }
  }
};

export const getPQUsersForNotesTasks = () => {
  return async (dispatch, getState) => {
    const token = getState().login.authToken;
    const urlQuery = `users/get-pqusers-notes-tasks`
    try {
      const response = await Api.get(`${urlQuery}`, token);
      const { success, data } = response.data;
      if (success) {
          dispatch(setUsersNpqList(data, ''))
          return  data;
      }
      dispatch(setUsersNpqList([], 'error'))
      return false;
    } catch (error) {
      dispatch(setUsersNpqList([], error))
      return  false;
    }
  }
};

export const setPageSize = (payload) => {
  return {
    type: types.SET_PAGE_SIZE,
    payload
  };
};
