import * as types from './types';
import Api from '../../../lib/api';


export const fetchDefaultFilters = (data,filterBoxTag,callback) => {
  return (dispatch, getState) => {
    const { login, localization } = getState();
    let {
      error10005,
      error10006,
      error10007,
      error10011,
      error10019,
      errorDefault,
      errorConnection,
    } = localization.strings.hiringClients.actions;
    const token = login.authToken;
    const urlQuery = `getFilterByModule?module=${data.module}&tab=${data.tab}&objectId=${data.objectId}`;
    // query = Utils.addSearchFiltersToQuery(query, data);
    dispatch(setFetchingFilters(true));
    return Api.get(urlQuery, token)
      .then((response) => {
        let errorMsg = '';
        const { success, data } = response.data;
        if (success) {
          dispatch(setFilterBox(data,filterBoxTag));
          if (callback) {
            callback(data)
          }
        } else {
          switch (data.errorCode) {
            case 10005:
              errorMsg = error10005;
              break;
            case 10006:
              errorMsg = error10006;
              break;
            case 10007:
              errorMsg = error10007;
              break;
            case 10011:
              errorMsg = error10011;
              break;
            case 10019:
              errorMsg = error10019;
              break;
            default:
              errorMsg = errorDefault;
              break;
          }
          dispatch(setHCListError(errorMsg));
        }
        dispatch(setFetchingFilters(false));
      })
      .catch((error) => {
        dispatch(setHCListError(errorConnection));
        dispatch(setFetchingFilters(false));
      });
  };
};

export const saveDefaultFilters = (filterBoxTag, filterBox) => {
  return (dispatch, getState) => {
    const { login, localization } = getState();
    let {
      error10005,
      error10006,
      error10007,
      error10011,
      error10019,
      errorDefault,
      errorConnection,
    } = localization.strings.hiringClients.actions;
    const token = login.authToken;
    dispatch(setFetchingFilters(true));

    return Api.post('saveFilterByModule', {
      filterBox
    }, token)
      .then((response) => {
        let errorMsg = '';
        const { success, data } = response.data;
        if (!success) {
          switch (data.errorCode) {
            case 10005:
              errorMsg = error10005;
              break;
            case 10006:
              errorMsg = error10006;
              break;
            case 10007:
              errorMsg = error10007;
              break;
            case 10011:
              errorMsg = error10011;
              break;
            case 10019:
              errorMsg = error10019;
              break;
            default:
              errorMsg = errorDefault;
              break;
          }
          dispatch(setHCListError(errorMsg));
        } else {
          const payload = filterBox.filterBox;
          dispatch(setFilterBox(payload, filterBoxTag));
        }
        return !!success;
      })
      .catch((error) => {
        dispatch(setHCListError(errorConnection));
        return false;
      });
  };
};

export const resetDefaultFilters = (filterBoxTag,filterBox) => {
  return (dispatch, getState) => {
    const { login, localization } = getState();
    let {
      error10005,
      error10006,
      error10007,
      error10011,
      error10019,
      errorDefault,
      errorConnection,
    } = localization.strings.hiringClients.actions;
    const token = login.authToken;
    dispatch(setFetchingFilters(true));
    dispatch(setFilterBox({},filterBoxTag));
    return Api.post('saveFilterByModule', {
      filterBox
    }, token)
      .then((response) => {
        let errorMsg = '';
        const { success, data } = response.data;
        if (!success) {
          switch (data.errorCode) {
            case 10005:
              errorMsg = error10005;
              break;
            case 10006:
              errorMsg = error10006;
              break;
            case 10007:
              errorMsg = error10007;
              break;
            case 10011:
              errorMsg = error10011;
              break;
            case 10019:
              errorMsg = error10019;
              break;
            default:
              errorMsg = errorDefault;
              break;
          }
          dispatch(setHCListError(errorMsg));
        } else {
          dispatch(setFilterBox({},filterBoxTag));
        }
        return !!success;
      })
      .catch((error) => {
        dispatch(setHCListError(errorConnection));
        return false;
      });
  };
};

export const setFetchingFilters = (isFetching) => {
  return {
    type: types.SET_GENERIAL_FETCHING_FILTER,
    isFetching
  }
};

export const setFilterBox = (filterBox,filterBoxTag) => {
  return {
    type: types.SET_GENERIAL_FILTER_BOX_SUCCESS,
    filterBox,
    filterBoxTag
  };
};

export const setHCListError = (error) => {
  return {
    type: types.SET_GENERIAL_FILTER_BOX_ERROR,
    error
  };
};


