import * as types from "../actions/types";

export default function registerReducer(
  state = {
    fileSaving: false,
    contractsError: null,
    docInfo: null,
    docInfoFetching: false,
  },
  action
) {
  switch (action.type) {
    case types.SET_SAVING_FILE:
      return Object.assign({}, state, {
        fileSaving: action.isSaving,
      });

    case types.SET_CONTRACTS_ERROR:
      return Object.assign({}, state, {
        contractsError: action.error,
      });

    case types.SET_DOC_INFO:
      return Object.assign({}, state, {
        docInfo: action.data,
      });

    case types.SET_DOC_FETCHING:
      return Object.assign({}, state, {
        docInfoFetching: action.isFetching,
      });

    default:
      return state;
  }
}
