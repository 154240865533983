import _ from 'lodash';

import Api from '../../../../../lib/api';
import Utils from '../../../../../lib/utils';
import {setHCListError} from '../../../actions';

const loadOptions = (dispatch, local, profileId) => {
  return async (currentValue, prevOptions, {page}) => {
    const next = {
      options: prevOptions,
      hasMore: false,
      additional: {page}
    };

    try {
      const params = new URLSearchParams({name: currentValue, page});
      const response = await Api.get(`hiringclients/only-parents?${params}`);
      const {results, counts} = response.data.data;
      next.options = results.filter((parent) => parent.value !== profileId);
      next.hasMore = counts.hasNextPage > 0 && results.length > 0;
      next.additional = {page: next.hasMore ? page + 1 : page};
    } catch (error) {
      if (_.get(error, 'response.data.data')) {
        dispatch(setHCListError(Utils.getErrorMessage(
          error.response.data, 
          local.actions
        )));
      } else {
        dispatch(setHCListError(local.actions.errorDefault));
      }
    }

    return next;
  };
};

export default loadOptions;