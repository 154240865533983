import React from "react";
import { withRouter } from "react-router-dom";
import { Form, Errors } from "@formio/react";
import { Loading } from "../../../../common";
import * as formsActions from "../../../../actions";
import * as commonActions from "../../../../../common/actions";
import * as linkActions from "../../../../../formLink/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { showAlertMessage, showToastSuccessNotification } from "../../../../../alerts";
import { ToastContainer, toast } from "react-toastify";
import {DATA_SAVED, PQ_FORM_LOG_TYPE, SAVE_AND_SUBMIT_FORM, SAVE_FORM} from "../../../../../../lib/appConstants";
import FormIOUtils from "../../../../utils";
import { FORM_IO_WIZARD_FORM, FORM_IO_NORMAL_FORM } from "../../../../../../lib/appConstants";
import { FORM_IO_INCOMPLETE_FORM_STATUS } from "../../../../../../lib/appConstants";
import { Link } from "react-router-dom";
import { createFormIoDownloadFileElements } from "../../../../../../utils";
import _ from "lodash";
import Api from "../../../../../../lib/api";

function debounce(func, timeout = 500){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}


class FormView extends React.Component {
  constructor(props) {
    super(props);
    this.locationState = props.location.state || {};

    const { subcontractorId } = this.locationState.formioSubmissionState || this.locationState;

    this.state = {
      idleTime: 1000 * 60 * 2,
      subcontractorId,
      formId: "",
      redirectUrl: subcontractorId && `/subcontractors/${subcontractorId}`,
      formInstance: null,
      submissionData: null,
      normalFormOptions: {},
      wizardFormOptions: {},
      correctTaxId: '',
      savedFormId: props.savedFormId
    };

    this.timeInterval = null;
    this.isChange = this.locationState.change || false;
    this.targetTypes = ['file', 'checkbox', 'select-one', 'select-multiple', 'radio', 'hidden', 'number', 'input'];
    document.addEventListener("keyup", this.eventListener);
    document.addEventListener("change", this.eventChangeListener);
    document.addEventListener("click", this.eventClickListener);
    document.addEventListener("paste", this.eventClipboardListener);
    document.addEventListener("drop", this.eventClipboardListener);
    document.addEventListener("mousedown", this.eventClickListener);
    document.addEventListener("input", this.eventInputListener);
  }

  componentWillUnmount = () => {
    clearInterval(this.timeInterval);
    this.timeInterval = null;
    document.removeEventListener("keyup", this.eventListener);
    document.removeEventListener("change", this.eventChangeListener);
    document.removeEventListener("click", this.eventClickListener);
    document.removeEventListener("paste", this.eventClipboardListener);
    document.removeEventListener("drop", this.eventClipboardListener);
    document.removeEventListener("mousedown", this.eventClickListener);
    document.removeEventListener("input", this.eventInputListener);
  };

  componentDidMount = async () => {
    let authToken = this.props.login.authToken;
    Api.get(`savedForm/${this.props.savedFormId}`, authToken)
    .then(response => {
      const { success, data } = response.data;
      if(success) {
        if(data && data.subcontractorId) {
          this.setState({
            subcontractorId: data.subcontractorId,
            hcName: data.hcName,
            redirectUrl: `/subcontractors/${data.subcontractorId}`
          })
          if (data.subcontractorId) {
            this.fetchSubcontractorTaxId(data.subcontractorId);
          }
        }
      } else {
        // this.props.history.push('/login');
      }
    }).catch(err => {
      // this.props.history.push('/login');
    })

    this.timeInterval = await setInterval(this.handleAutoSave, this.state.idleTime);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.subcontractorId !== prevState.subcontractorId) {
      this.fetchSubcontractorTaxId(this.state.subcontractorId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.formioSubmission.submission) {
      this.setState({
        ...this.state,
        submissionData: nextProps.formioSubmission.submission,
      });
    }
    const displayType = (nextProps.formioForms && nextProps.formioForms.form && nextProps.formioForms.form.display) ? nextProps.formioForms.form.display : null;
    const role = nextProps.profile.Role;
    const commonConditions = role && displayType !== null
    if (commonConditions) {
      const formOptions = FormIOUtils.getFormButtonSettings(role, FORM_IO_INCOMPLETE_FORM_STATUS)
      this.setState({
        normalFormOptions: displayType === FORM_IO_NORMAL_FORM ? formOptions : {},
        wizardFormOptions: displayType === FORM_IO_WIZARD_FORM ? formOptions : {},
      });
    }
  }

  resetAutoSaveTimerDebounce = async () => {
    if(this.timeInterval) {
      await clearInterval(this.timeInterval);
      this.timeInterval = await setInterval(this.handleAutoSave, this.state.idleTime);
    }
  };

  resetAutoSaveTimer = debounce(() => this.resetAutoSaveTimerDebounce());

  eventInputListener = (event) => {
    if (event && event.type === 'input' || event && event.target.name === 'data[dateTime]') {
      this.isChange = true;
      this.resetAutoSaveTimer();
    }
  }
  
  // Check user press any button
  eventListener = (event) => {
    if (
      (event.keyCode >= 32 && event.keyCode <= 90) ||
      (event.keyCode >= 97 && event.keyCode <= 122) ||
      event.keyCode === 8 || event.keyCode === 46
    ) {
      this.isChange = true;
      this.resetAutoSaveTimer();
    }
  };

  handleUpdateForm = (form) => {
    this.setState({ formInstance: form });
  }

  eventChangeListener = (event)=>{
    if(this.targetTypes.includes(event.target.type) || event.target.name === 'data[dateTime]') {
      this.isChange = true;
      this.resetAutoSaveTimer();
    }
  };

  eventClickListener = (event) =>{
    const { target } = event;
    if(target) {
      const isDraggable = [...target.classList].some(className =>
        className.startsWith('formio-drag-button')
      );
  
      if(target['classList'].value ==='fa fa-remove' || target['classList'].value ==='signature-pad-canvas' || isDraggable){
        this.isChange = true;
        this.resetAutoSaveTimer();
      }
    }
  };
  


  eventClipboardListener = (event) => {
    this.isChange = true;
    this.resetAutoSaveTimer();
  };

  onSortEnd = ({oldIndex, newIndex}) => {
    if (oldIndex !== newIndex) {
      this.isChange = true;
      this.resetAutoSaveTimer();
    }
  }

  onDraft = (error) => {
    const alertConfig = {
      type: error ? "error" : "success",
      title: error ? "There was an error saving a draft" : DATA_SAVED,
    };

    if (!error) {
      showToastSuccessNotification(alertConfig.title);
    } else {
      showAlertMessage(alertConfig.type, alertConfig.title, () => {});
    }

  };

  fetchSubcontractorTaxId = async (subcontractorID) => {
    try {
      this.props.linkActions.getSubcontractorTaxId(
        { 
          subcontractorId: subcontractorID, 
          callback: (data, error) => {
            if (error) {
              return;
            }
            if (data) {
              this.setState({ correctTaxId: data });
            }
          } 
        }
      );
    } catch (error) {
      console.error('An unexpected error occurred:', error);
    }
  }

  logSubmission = ({ isComplete }) => {
    const { subcontractorId, savedFormId } = this.state;

    this.props.linkActions.addSubcontractorFormsLog({
      subcontractorId,
      savedFormId,
      eventType: isComplete ? SAVE_AND_SUBMIT_FORM : SAVE_FORM,
      logType: PQ_FORM_LOG_TYPE
    });
  };

  handleDraft = async (data, { successMsg } = {}) => {
    if (this.isChange) {
      this.isChange = false;
      this.props.commonActions.setLoading(true);
      const { savedFormId } = this.state;
      const { title } = this.props.formioForms.form;
      const { _id: submissionId } = data || {};
      const localData = data;
      let draftError = null; // Used to capture the error
  
      await this.props.actions.saveSubmission(
        submissionId ? data.data : (data.data ? data.data : data),
        this.props.formId,
        title,
        async (err, submissionResult) => {
          if (err) draftError = err;
  
          if (submissionResult && savedFormId) {
            await this.props.linkActions.updateSavedFormStatus(
              savedFormId,
              "false",
              (_, error) => {
                if (error) draftError = error;
              },
              { formioSubmissionId: submissionResult._id },
              { formioSubmissionData: localData.data }
            );
            if (!data._id) {
              window.history.replaceState(null, null, `${this.props.formId}/submission/${submissionResult._id}/edit`);
            }
            this.logSubmission({ isComplete: false });
            this.resetAutoSaveTimer();
          }
        },
        {
          draft: true,
          submissionId,
        }
      );
  
      this.props.linkActions.saveFormSubmissionLogs(
        savedFormId,
        localData,
        (_, error) => {
          if (error) draftError = error;
        },
        draftError
      );
  
      this.onDraft(draftError, { successMsg });
  
      this.props.commonActions.setLoading(false);
    }
  };
  
  onCustomEvent = async ({ type: eventType, data }) => {
    if (eventType === "draft") {
      this.handleDraft(
        this.state.submissionData ? this.state.submissionData : data
      );
    }
  };

  onSubmit = (submission) => {
    const { hcName, savedFormId } = this.state;
    this.isChange = false;
    this.props.commonActions.setLoading(true);
    this.props.actions.saveSubmission(
      submission.data,
      this.props.formioForms.form._id,
      this.props.formioForms.form.title,
      (err, submissionResult) => {
        if (!err) {
          // this.props.history.push(`/${this.props.formioForms.form._id? `formio/forms/${this.props.formioForms.form._id}/submission` : `${this.props.formioForms.form.name}`}/${submission._id}`);
          if (savedFormId) {
            linkActions.markSavedFormAsComplete(
              savedFormId,
              submissionResult._id,
              this.props.login,
              submission.data,
              (completeRes) => {
                if (completeRes.success && completeRes.data.formUpdated) {
                  let type = "success",
                    title = `This form was successfully submitted. ${hcName} has been notified. No further action is required at this time.`;
                  showAlertMessage(type, title, () => {
                    this.state.redirectUrl &&
                      this.props.history.push(this.state.redirectUrl);
                  });
                } else {
                  let type = "error",
                    title = "There was an error submitting the form";
                  showAlertMessage(type, title, () => {
                    this.state.redirectUrl &&
                      this.props.history.push(this.state.redirectUrl);
                  });
                }
              }
            );
            if (savedFormId) this.logSubmission({ isComplete: true });
          }
        }
        this.props.commonActions.setLoading(false);
      },
      {
        submissionId: submission._id,
      }
    );
  };

  handleAutoSave = async () => {
    if (this.isChange) {
      let data;
      if (this.state.submissionData) {
        data = this.state.submissionData
      } else {
        data = this.state.formInstance._data ? this.state.formInstance._data : this.state.formInstance;
      }
      this.handleDraft(data);
      this.isChange = false;
    }
  };

  financialForms() {
    const formProperties = this.props.formioForms.components;
    const userRoleId = this.props.login.profile.RoleID;
    if (formProperties && userRoleId === 6) {
      for (const key of Object.keys(formProperties)) {
        let data = (key, formProperties[key]);
        if (data.properties.isFinancialInformation === "true") {
          data.uploadOnly = true;
        }
      }
    }
  }

  pathArray = [];
  
  getTaxFieldsPath (obj, name, val) {
    this.pathArray = [];
    this.parseFormioFormMetadata(obj, name, val);
    return this.pathArray;
  }
  
  parseFormioFormMetadata = (obj, name, val, currentPath) => {
    currentPath = currentPath || "";
    let matchingPath;

    if (!obj || typeof obj !== "object") return;

    if (obj[name] && obj[name].includes(val)){
      this.pathArray.push(`${currentPath}['${name}']`)
    }

    for (const key of Object.keys(obj)) {
      if (key === name && obj[key] === val) {
        matchingPath = currentPath;
      } else {
        matchingPath = this.parseFormioFormMetadata(
          obj[key],
          name,
          val,
          `${currentPath}['${key}']`
        );
      }
      if (matchingPath) break;
    }
    return matchingPath;
  };
  scrollToTop = (e) => {
    document.querySelector(`#main`).scrollIntoView({
      behavior: "smooth",
    });
  };

  onRender = ()=> {
    createFormIoDownloadFileElements()
  }
  render() {
    const { hideComponents } = this.props;
    const { wizardFormOptions, normalFormOptions } = this.state;
    let formOptions = {
      template: "bootstrap3",
      iconset: "fa",
      buttonSettings: { },
      hide: {},
      readOnly: true,
    };

    // hide submit button for wizard forms
    formOptions.buttonSettings.showSubmit = !wizardFormOptions.hideSubmit;

    //hidden submit button, only works in normal forms, not in wizard
    formOptions.hide.submit = this.props.hideSubmit ? true : normalFormOptions.hideSubmit;

    //hidden save button, only works in normal forms, not in wizard
    formOptions.hide.save = normalFormOptions.hideSave;

    formOptions.readOnly = normalFormOptions.readOnly || wizardFormOptions.readOnly;

    this.financialForms();

    const { isActive } = this.props.formioForms;
    if (isActive) {
      return <Loading />;
    }

    if (
      this.props.formioForms.form.components &&
      this.props.formioForms.form.components.length > 0
    ) {
      let path = this.getTaxFieldsPath(
        this.props.formioForms.form,
        "key",
        "CompanyTaxID"
      );
      if (path) {
        path.forEach((componentPath) => {
          componentPath = componentPath.replace("['key']", "");
          let componentProperties = _.get(this.props.formioForms, `form${componentPath}.properties`);
          window.correctTaxId = this.state.correctTaxId.TaxID;
          let customValidation = `if (input) {
            let expectedValue = window.correctTaxId;
            if (input.replace('_', '') === expectedValue) {
              valid = true;
            } else {
              valid = '${componentProperties.taxID}';
            }
          }`;
    
          _.set(this.props.formioForms, `form${componentPath}.validate.custom`, customValidation);
          
        });
      }
    }

    return (
    <>
      <div className="full-width padding-bottom10px">
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <Link
            className="nav-link"
            onClick={() => {
              this.props.history.goBack();
            }}
          >
            <i className="fa fa-chevron-left">
              &nbsp;<span>Back</span>
            </i>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            to={{
                pathname: `/formio/forms/${this.props.formioForms.id}/pdf`,
                state: {
                    formInstance: this.state.formInstance,
                }
            }}
          >
            View PDF
          </Link>
        </li>
      </ul>
    </div>
      <div>
        <h3>New {this.props.formioForms.form.title}</h3>
        <Errors
          errors={[
            this.props.formioForms.error,
            this.props.formioSubmission.error,
          ]}
        />
        {
          (Object.keys(normalFormOptions).length > 0 || Object.keys(wizardFormOptions).length > 0) &&
          <Form
            onCustomEvent={this.onCustomEvent}
            form={this.props.formioForms.form}
            submission={this.props.formioSubmission.submission}
            url={this.props.formioForms.form.url}
            options={formOptions}
            hideComponents={hideComponents}
            onSubmit={this.onSubmit}
            formReady={(formInstance) => {
              this.setState({ formInstance });
            }}
            onChange={(formInstance) => {
              this.handleUpdateForm(formInstance.data);
              this.onRender()
            }}
            onNextPage={(e) => this.scrollToTop(e)}
            onPrevPage={(e) => this.scrollToTop(e)}
            onRender={(e) => this.onRender()}
          />
        }
        <ToastContainer />
      </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    login: state.login,
    formioForms: state.formioForms,
    formioSubmission: state.formioSubmission,
    profile: state.login.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(formsActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    linkActions: bindActionCreators(linkActions, dispatch),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormView)
);
