import * as regex from "../../../../lib/regex";
import _ from "lodash";

const validate = (values, props) => {
  const {
    requiredValidation,
    wrongTaxIdFormat,
    fieldLengthValidation,
    postalCodeErrorMessageCanada,
    postalCodeErrorMessage,
  } = props.local.strings.register.companyProfile;

  const errors = {};

  if (!values.companyName || !values.companyName.trim()) {
    errors.companyName = requiredValidation;
  } else if (values.companyName.length > 100) {
    errors.companyName = fieldLengthValidation;
  }

  const trade = _.get(values, "trade.value", null);

  if (!trade || trade === 19678 || trade <= 0) {
    errors.trade = requiredValidation;
  }

  const country = _.get(values, "country.value", null);

  if (!country) {
    errors.country = requiredValidation;
  }

  const state = _.isObject(values.state)
    ? _.get(values, "state.label")
    : values.state || "";

  if (!state || !state.trim()) {
    errors.state = requiredValidation;
  } else if (state.length > 100) {
    errors.state = fieldLengthValidation;
  }

  if (!values.city || !values.city.trim()) {
    errors.city = requiredValidation;
  } else if (values.city.length > 150) {
    errors.city = fieldLengthValidation;
  }

  if (!values.address || !values.address.trim()) {
    errors.address = requiredValidation;
  } else if (values.address.length > 150) {
    errors.address = fieldLengthValidation;
  }

  if (!values.zipCode || !values.zipCode.trim()) {
    errors.zipCode = requiredValidation;
  } else if (values.zipCode.length > 10) {
    errors.zipCode = fieldLengthValidation;
  } else if (country && country === 1) {
    // Validate USA Zipcode
    if (!regex.POSTAL_CODE.test(values.zipCode)) {
      errors.zipCode = postalCodeErrorMessage;
    }
  } else if (country && country === 34) {
    // Validate Canadian Zipcode
    if (!regex.CANADIAN_POSTAL_CODE.test(values.zipCode)) {
      errors.zipCode = postalCodeErrorMessageCanada;
    }
  }

  if (!values.taxId || !values.taxId.trim()) {
    errors.taxId = requiredValidation;
  } else if (values.taxId.length > 16) {
    errors.taxId = fieldLengthValidation;
  } else if(country && country === 34){
    // If country is CANADA validate the GST format
    if(values.taxId && !regex.CANADIAN_TAX_FORMAT.test(values.taxId)){
        errors.taxId = wrongTaxIdFormat;
    }
  } else if (country && country === 1) {
    // If country is USA validate the tax id format
    if (!regex.TAX_ID.test(values.taxId)) {
      errors.taxId = wrongTaxIdFormat;
    }
  } else if (country && country !== 1) {
    // For any other country, just check that this is a number
    if (Number.isNaN(Number(values.taxId))) {
      errors.taxId = wrongTaxIdFormat;
    }
  }

  if (!values.agree) {
    errors.agree = requiredValidation;
  }

  return errors;
};

export default validate;
