/*eslint no-loop-func: 0*/
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import html2pdf from 'html2pdf.js';

import * as formActions from './actions';
import * as formBuilder from '../formBuilder/actions';
import FormView from './formView';
import './previewer.css';

const formToPdf = require('./formToPdf/formToPdf');
const formPreviewerUtils = require('./utils');
const _ = require('lodash');

class FormPreviewer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      form: null,
      fieldValues: [],
    }

    this.saveFieldValue = this.saveFieldValue.bind(this);
    this.saveRadioButtonValues = this.saveRadioButtonValues.bind(this);
  }

  async componentDidMount() {
    const formPreviewerId = this.props.match.params.formPreviewerId;
    await this.props.formBuilder.getFormById(formPreviewerId);
    const propsForm = this.props.formPreviewer.form;
    const fieldValues = formPreviewerUtils.getFormFieldValues(propsForm);
    this.setState({ form: propsForm, fieldValues });
  }  

  saveFieldValue(key, value, internalName) {
    const newList = this.state.fieldValues.map(
      (fieldValue, index) => fieldValue.key === key ?
        {
          ...fieldValue,
          value,
          internalName
        } : fieldValue
    );
    this.setState({ fieldValues: newList });
  }

  saveRadioButtonValues(keyValueArr) {
    const fieldValues = this.state.fieldValues;
    const newList = [];
    for (var i = 0; i < fieldValues.length; i++) {
      const index = _.findIndex(keyValueArr, (o) => { return o.key === fieldValues[i].key; });
      if (index !== -1) {
        fieldValues[i].value = keyValueArr[index].value;
        fieldValues[i].internalName = keyValueArr[index].internalName;
      }
      newList.push(fieldValues[i]);
    }
    this.setState({ fieldValues: newList });
  }

  goBack() {

    this.props.history.goBack();

  }

  viewPDF() {
    if (!this.state.form) return;
    this.setState({ showPdfView: true });
    window.scrollTo(0, 0);
  }

  async downloadPDF() {
    if (!this.state.form) return;

    let fileName = this.state.form.name + '_' + Date.now() + '.pdf';
    let options = {
      filename: fileName,
      jsPDF: { format: 'a2', },
      pagebreak: { mode: ['css','lagacy'] },
      enableLinks: false
    };

    let content = document.getElementById('formLagacy');
    await html2pdf().from(content).set(options).toPdf().output('datauristring').save();
  }

  render() {
    if (!this.props.common.checkingAuthorizations) {
      if (!this.props.common.formBuilderAuth) {
        return <Redirect push to="/dashboard" />;
      }
    }

    return (
      <div style={{ height: 'auto' ,width:'100%'}}>
        <div className="pdf-buttons-container">
          <button className="pdf-button" onClick={() => this.goBack()}>Back</button>
          <button className="pdf-button" onClick={() => this.viewPDF()}>View PDF</button>
          <button className="pdf-button" onClick={() => this.downloadPDF()}>Download PDF</button>
        </div>
        <div id='formLagacy' className='row'>
          {
            this.state.form && this.state.showPdfView ?
              <div className='col-sm-8 m-auto'>
                {
                  this.state.form.formSections.map((section,index)=>{
                    let currentForm={},hideLogo=true;
                    if (index === 0) {
                      currentForm = { ...this.state.form, formSections: [section] }
                    } else {
                      currentForm = { ...this.state.form, name: '', description: '', formSections: [section] }
                      hideLogo=false
                    }

                    return (<FormView
                      hideLogo={hideLogo}
                      form={currentForm}
                      fieldValues={this.state.fieldValues}
                      saveFieldValue={this.saveFieldValue}
                      saveRadioButtonValues={this.saveRadioButtonValues}
                      canEditValues={false}
                      canSubmit={false}
                    />)

                  })
                }
                
              </div>
              : null
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    formPreviewer: state.formBuilder,
    local: state.localization,
    login: state.login,
    common: state.common,
   
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(formActions, dispatch),
    formBuilder: bindActionCreators(formBuilder, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormPreviewer));
