import _ from 'lodash';
import * as types from '../actions/types';

const initialState = {
    id: '',
    url: '',
    error: '',
    form: {}, //To Process Single Form 
    forms: [], //TO Process All Forms
    isActive: false,
    lastUpdated: 0,
    pagination: {
        numPages: 0,
        page: 1,
        total: 0,
    },
}
export default function formsioReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_FORM_CLEAR_ERROR:
            return {
                ...state,
                error: '',
            };
        case types.SET_FORM_REQUEST:
            return {
                ...state,
                isActive: true,
                id: action.id,
                form: {},
                url: action.url,
                error: '',
            };
        case types.SET_FORM_SUCCESS:
            return {
                ...state,
                isActive: false,
                id: action.form._id,
                form: action.form,
                url: action.url || state.url,
                error: '',
            };
        case types.SET_FORM_FAILURE:
            return {
                ...state,
                isActive: false,
                isInvalid: true,
                error: action.error,
            };
        case types.SET_FORM_SAVE:
            return {
                ...state,
                isActive: true,
            };
        case types.SET_FORM_RESET:
            return initialState;
            
        case types.SET_FORMS_RESET:
            return initialState;
        case types.SET_FORMS_REQUEST:
            return {
                ...state,
                error: '',
                forms: [],
                isActive: true,
                pagination: {
                    ...state.pagination,
                    page: action.page,
                },
            };
        case types.SET_FORMS_SUCCESS: {
            const total = action.forms.serverCount;

            return {
                ...state,
                forms: action.forms,
                isActive: false,
                pagination: {
                    ...state.pagination,
                    numPages: Math.ceil(total / action.limit),
                    total,
                },
            };
        }
        case types.SET_FORMS_FAILURE:
            return {
                ...state,
                error: action.error,
                isActive: false,
            };
        default:
            return state;
    }
}
