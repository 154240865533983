import React from 'react';

const SidePicture = () => {
  return (
    <div className="col-sm-8 workers-col col-no-padding hidden-sm-down">
      <div className="workers-picture-wrapper">
      </div>
    </div>
  );
}

export default SidePicture;