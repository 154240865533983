import _ from 'lodash';

import * as regex from '../../../../../lib/regex';

const validate = (values, props) => {
  const {
    passwordBoth,
    phoneErrorMessage,
    requiredValidation,
    wrongEmailFormat,
  } = props.local.strings.hiringClients.addHCModal.contactTab;

  const errors = {};
  
  if (values.tab === 'create') {
    if (_.isEmpty(_.get(values, 'firstName', '').trim())) {
      errors.firstName = requiredValidation;
    }

    if (_.isEmpty(_.get(values, 'lastName', '').trim())) {
      errors.lastName = requiredValidation;
    }

    if (_.isEmpty(_.get(values, 'email', '').trim())) {
      errors.email = requiredValidation;
    } else if (!regex.EMAIL.test(values.email)) {
      errors.email = wrongEmailFormat;
    }

    if (_.isEmpty(_.get(values, 'phone', '').trim())) {
      errors.phone = requiredValidation;
    } else if (!regex.PHONE.test(values.phone)) {
      errors.phone = phoneErrorMessage;
    }

    if (_.isEmpty(_.get(values, 'password', '').trim())) {
      errors.password = requiredValidation;
    }

    if (_.isEmpty(_.get(values, 'retypePassword', '').trim())) {
      errors.retypePassword = requiredValidation;
    }

    if (values.password !== values.retypePassword) {
      errors.password = passwordBoth;
      errors.retypePassword = passwordBoth;
    }
  } else {
    if (!_.get(values, 'hcUser.value')) {
      errors.hcUser = requiredValidation;
    }
  }

  return errors;
};

export default validate;