import Api from "../../../lib/api";

export const getYurbiUserInfo = async (authToken) => {
    const response = await Api.get('yurbi/userinfo', authToken);
    const { success, data } = response.data;

    if(!success) {
        throw Error("Could not find user information in BI");
    }
    return data;
};

export const getYurbiDashboard = async (sessionToken) => {
    const response = await Api.get(`yurbi/dashboards?sessionToken=${sessionToken}`);

    const { success, data } = response.data;

    if(!success) {
        throw Error("Dashboard doesn't exist. Please check your privilege or contact admin.");
    }
    return data;
};
