const validate = (values, props) => {
  const errors = {};
  const {
    requiredValidation,
  } = props.local.strings.hiringClients.addHCModal.companyInfoTab;

  const validateFilled = [
    'insured', 'status', 'insuredReqSet'
  ];
  validateFilled.forEach(name => {
    if(!values[name]) {
      errors[name] = requiredValidation;
    }
  });

  if (props.projectInsured) {
    const {requirementSetId} = props.projectInsured;
    if (values['insuredReqSet'] == requirementSetId) errors['insuredReqSet'] = 'Choose another requirement set';
  }

  return errors;
};

export default validate;
