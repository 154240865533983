export const SET_APPLY_ERROR_MESSAGE = 'SET_APPLY_ERROR_MESSAGE';

export const SET_TITLE_OPTIONS = 'SET_TITLE_OPTIONS';
export const SET_TIMEZONE_OPTIONS = 'SET_TIMEZONE_OPTIONS';
export const SET_TRADE_OPTIONS = 'SET_TRADE_OPTIONS';
export const SET_ROLE_OPTIONS = 'SET_ROLE_OPTIONS';
export const SET_COUNTRY_OPTIONS = 'SET_COUNTRY_OPTIONS';

export const SET_GEO_STATES = 'SET_GEO_STATES';

export const SET_PROCESSING_REGISTRATION_EP = 'SET_PROCESSING_REGISTRATION_EP';
export const SET_REGISTRATION_ERROR = 'SET_REGISTRATION_ERROR';
export const SET_REGISTRATION_SUCCESS = 'SET_REGISTRATION_SUCCESS';
export const SET_REGISTRATION_REDIRECT_ON_ERROR = 'SET_REGISTRATION_REDIRECT_ON_ERROR';

export const SET_REGISTRATION_SC_ID = 'SET_REGISTRATION_SC_ID';
export const SET_REGISTRATION_HC_ID = 'SET_REGISTRATION_HC_ID';
export const SET_REGISTRATION_HC_NAME = 'SET_REGISTRATION_HC_NAME';
export const SET_REGISTRATION_HC_ENTITY_TYPE = 'SET_REGISTRATION_HC_ENTITY_TYPE';
export const SET_APPLY_PAYLOAD = 'SET_APPLY_PAYLOAD';
export const SET_APPLY_PAYLOAD_FIELD = 'SET_APPLY_PAYLOAD_FIELD';

export const SET_REGISTRATION_TEMP_TOKEN = 'SET_REGISTRATION_TEMP_TOKEN';
export const SET_REGISTRATION_HC_LOGO = 'SET_REGISTRATION_HC_LOGO';
export const SET_USER_EXISTS = 'SET_USER_EXISTS';
