export const SET_HOLDER_REQUIREMENT_SETS_ERROR_VIEW = 'SET_HOLDER_REQUIREMENT_SETS_ERROR_VIEW';
export const SET_HOLDER_REQUIREMENT_SETS_LIST_VIEW = 'SET_HOLDER_REQUIREMENT_SETS_LIST_VIEW';
export const SET_TOTAL_AMOUNT_OF_HOLDER_REQUIREMENT_SETS_VIEW = 'SET_TOTAL_AMOUNT_OF_HOLDER_REQUIREMENT_SETS_VIEW';
export const SET_FETCHING_HOLDER_REQUIREMENT_SETS_VIEW = 'SET_FETCHING_HOLDER_REQUIREMENT_SETS_VIEW';
export const SET_HOLDER_REQUIREMENT_SETS_SHOW_MODAL_VIEW = 'SET_HOLDER_REQUIREMENT_SETS_SHOW_MODAL_VIEW';
export const SET_HOLDER_REQUIREMENT_SETS_ADD_ERROR_VIEW = 'SET_HOLDER_REQUIREMENT_SETS_ADD_ERROR_VIEW';
export const SET_HOLDER_REQUIREMENT_SETS_ADD_SUCCESS_VIEW = 'SET_HOLDER_REQUIREMENT_SETS_ADD_SUCCESS_VIEW';
export const SET_HOLDER_REQUIREMENT_SETS_ADD_FETCHING_VIEW = 'SET_HOLDER_REQUIREMENT_SETS_ADD_FETCHING_VIEW';

// ENDORSEMENTS
export const SET_HOLDER_REQUIREMENT_SETS_VIEW_ENDORSEMENTS_ERROR = 'SET_HOLDER_REQUIREMENT_SETS_VIEW_ENDORSEMENTS_ERROR';
export const SET_HOLDER_REQUIREMENT_SETS_VIEW_ALL_ENDORSEMENTS = 'SET_HOLDER_REQUIREMENT_SETS_VIEW_ALL_ENDORSEMENTS';
export const SET_HOLDER_REQUIREMENT_SETS_VIEW_ENDORSEMENTS = 'SET_HOLDER_REQUIREMENT_SETS_VIEW_ENDORSEMENTS';
export const SET_HOLDER_REQUIREMENT_SETS_VIEW_ENDORSEMENTS_FETCHING = 'SET_HOLDER_REQUIREMENT_SETS_VIEW_ENDORSEMENTS_FETCHING';
export const SET_HOLDER_REQUIREMENT_SETS_VIEW_ENDORSEMENTS_SELECT_FETCHING = 'SET_HOLDER_REQUIREMENT_SETS_VIEW_ENDORSEMENTS_SELECT_FETCHING';
export const SET_REQUIREMENT_SETS_VIEW_ENDORSEMENT_SELECT_SUCCESS = 'SET_REQUIREMENT_SETS_VIEW_ENDORSEMENT_SELECT_SUCCESS';
export const SET_REQUIREMENT_SETS_VIEW_ENDORSEMENT_SELECT_ERROR = 'SET_REQUIREMENT_SETS_VIEW_ENDORSEMENT_SELECT_ERROR';
