import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import * as actions from '../../tasks/actions';
import * as commonActions from '../../../common/actions';

import './viewNotesModal.css';

class ViewNotesModal extends Component {

  render() {
    const {
      createdBy,
      createdOn,
      projectLabel,
      taskDescription,
      close,
    } = this.props.local.strings.tasks.viewTaskModal;

    const {
      Name,
      Description,
      ProjectFullName,
      CreatedByUser,
      CreationDate,
    } = this.props.task
    
    return (
      <div className="view-task-modal">
        <header className="view-task-modal-header">{Name}</header>

        <div className="view-task-content container-fluid filter-fields">
          <div className="row">
            <div className="col-md-6 col-sm-12 no-padd">
              <div className="admin-form-field-wrapper">
                <div className="view-task-label">{createdBy}:</div>
                <div className="view-task-text">{CreatedByUser}</div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 no-padd">
              <div className="admin-form-field-wrapper">
                <div className="view-task-label">{createdOn}:</div>
                <div className="view-task-text">{moment(CreationDate).format('YYYY-MM-DD HH:mm:ss')}</div>
              </div>
            </div>
          </div>

        {(ProjectFullName) && (
          <div className="row"> 
            <div className="col-md-4 col-sm-12 no-padd">
              <div className="admin-form-field-wrapper">
                <div className="view-task-label">{projectLabel}:</div>
                <div className="view-task-text">{ProjectFullName}</div>
              </div>
            </div>
          </div>  
        )}

          <div className="row">
            <div className="col-sm-12 no-padd">
              <div className="admin-form-field-wrapper">
                <div className="view-task-label">{taskDescription}:</div>
                <div className="description-text">{Description}</div>
              </div>
            </div>
          </div >
          <div className="text-right">
            <button className="bg-sky-blue-gradient bn" onClick={this.props.close}>{close}</button>
          </div>
        </div>
      </div>
    );
  }
};

ViewNotesModal = reduxForm({
  form: 'ViewCFTaskModalForm'
})(ViewNotesModal);

const mapStateToProps = (state) => {
  return {
    notesTasks: state.CFTasks,
    local: state.localization,
    login: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewNotesModal);
