// sets
export const SET_FORMS_LIST_ERROR = 'SET_FORMS_LIST_ERROR';
export const SET_FORMS_LIST = 'SET_FORMS_LIST';
export const SET_FETCHING_FORMS = 'SET_FETCHING_FORMS';
export const SET_TOTAL_AMOUNT_OF_FORMS = 'SET_TOTAL_AMOUNT_OF_FORMS';
export const SET_FORMS_LENGTH = 'SET_FORMS_LENGTH';
export const SET_FORM_AS_DEFAULT = 'SET_FORM_AS_DEFAULT';
export const SET_FORM_CREATOR_USERS = 'SET_FORM_CREATOR_USERS';
export const SET_FORM_SC_SENT_TO = 'SET_FORM_SC_SENT_TO';
export const SET_DISCREET_ACCOUNTS_LIST = 'SET_DISCREET_ACCOUNTS_LIST';
export const SET_SCORECARDS_FIELDS_LIST = 'SET_SCORECARDS_FIELDS_LIST';
export const SET_FETCHING_HIDDEN_SCORECARD_FIELDS = 'SET_FETCHING_HIDDEN_SCORECARD_FIELDS';
export const SET_FORMS_LIST_HIDDEN_SCORECARD_FIELDS = 'SET_FORMS_LIST_HIDDEN_SCORECARD_FIELDS';
export const SET_UPDATE_FORMS_LIST_DISCRETE_ACCOUNTS = 'SET_UPDATE_FORMS_LIST_DISCRETE_ACCOUNTS';
export const SET_DISCRETE_ACCOUNT_CONFIGURATIONS = 'SET_DISCRETE_ACCOUNT_CONFIGURATIONS';
