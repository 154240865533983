import axios from "axios";

export const updateSubmissionApi = async (submissionId, formId, data, 
  { draft } = {}
) => {
  const validation = draft ? '?noValidate=1' : '';

  try {
    return await axios.put(
      `${process.env.REACT_APP_FORMIO_SERVICE_URL}/form/${formId}/submission/${submissionId}${validation}`,
      {
        data,
      },
      {
        headers: { "x-jwt-token": process.env.REACT_APP_FORMIO_TOKEN },
      }
    );
  } catch (error) {
    throw { error: error };  
  }
};

export const saveSubmissionApi = async (formId, data, 
  { draft } = {}
) => {
  const validation = draft ? '?noValidate=1' : '';

  try {
    return await axios.post(
      `${process.env.REACT_APP_FORMIO_SERVICE_URL}/form/${formId}/submission${validation}`,
      {
        data,
      },
      {
        headers: { "x-jwt-token": process.env.REACT_APP_FORMIO_TOKEN },
      }
    );
  } catch (error) {
    throw { error: error };
  }
};
