import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import Accordion from './../../../../common/accordion';

import * as actions from './../../actions/actions';
import * as commonActions from './../../../../common/actions';
import * as certificateActions from './../../../../certfocus/certificates/actions';
import RolAccess from "../../../rolAccess";
import DisplayTextWithConditionalModal from '../../../displayTextWithConditionalModal';
import { TEXT_DISPLAY_LIMIT, PROJECT_AI_WORDING } from '../../../../../lib/appConstants';
import swal from 'sweetalert2';

class DeficiencesMinor extends React.Component {

  dispatchConfirm(id) {
    const {local, certificatesList, deficiences, commonActions, actions, certificateActions} = this.props
    const {
      confirmAttributeModelTitle,
      confirmAttributeModelText,
      confirmAttributeModelConfirmButtonText
    } = local.strings.certfocusCertificates
    let projectInsuredID = certificatesList[0].ProjectInsuredID;
    let deficienciesToConfirm = deficiences.filter((x) => (x.ProjectInsuredDeficiencyID != null && x.ProjectInsuredDeficiencyID == id && x.AttributeStatus !== "Expired"));
    if(deficienciesToConfirm && deficienciesToConfirm.length > 0){
      commonActions.setLoading(true);
      let deficienciesRemaining = deficiences;
      actions.fetchConfirmDeficiences(deficienciesToConfirm, projectInsuredID, deficienciesRemaining, () => {
        certificateActions.fetchCertificates(projectInsuredID);
        actions.fetchDeficiences(projectInsuredID);
        certificateActions.fetchProjectInsured(projectInsuredID);
        commonActions.setLoading(false);
      });
  }else{
    swal({
      title: confirmAttributeModelTitle,
      text: confirmAttributeModelText,
      confirmButtonText: confirmAttributeModelConfirmButtonText,
    });
  }
  }

  dispatchWaive(id) {
    this.props.actions.setSelectWaiver(id);
    this.props.showWaivedModal();
  }

  renderSectionContent(tableBody, hasValue) {
    let component = hasValue ? (
      <table className="table" style={{ fontSize: 13 }}>
        <thead>
          <tr>
            <th scope="col">Coverage</th>
            <th scope="col">Expire Date</th>
            <th scope="col">Attribute</th>
            <th scope="col">Required</th>
            <th scope="col">Provided</th>
            <th scope="col">Status</th>
            <RolAccess
              masterTab='certificates_insurance'
              sectionTab='edit_certificate'
              component={() => this.renderDeficiencyActionsHeader}>
            </RolAccess>
          </tr>
        </thead>
        <tbody>
          {tableBody}
        </tbody>
      </table>
    ) : (<span className="d-flex justify-content-center align-items-center mt-2">No results</span>);
    return component;
  }

  renderDeficiencyActionsHeader = () => {
    return (
        <React.Fragment>
          <th scope="col ml-2"></th>
          <th scope="col ml-2"></th>
        </React.Fragment>
    )
  }

  renderDeficiencyActions = (def, isArchived) => {
    return (    
      <td>
        {(!isArchived) && (
          <React.Fragment>
          <a
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => this.dispatchConfirm(def.ProjectInsuredDeficiencyID)}>
            Confirm
          </a><br />
          <a
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => this.dispatchWaive(def.ProjectInsuredDeficiencyID)}>
            Waive
          </a>
          </React.Fragment>
        )}
      </td>
    )
  }

  render() {
    const { isArchived, AIWording } = this.props;
    const splitDeficiency = [1,2];
    const deficiencesMinor = !this.props.data ? [] : this.props.data.filter(deficiences => deficiences.DefTypeID == 2 && (!splitDeficiency.includes(deficiences.DeficiencyStatusID)));
    const currentDate = new Date().getTime();

    const { conditionPossibleValues } = this.props.holderRequirementSets;

    const tableBody = deficiencesMinor.map((def, index) => {
      let defDate = (def.ExpirationDate) ? new Date(def.ExpirationDate).getTime() : null;
      let hasExpired = (defDate && (currentDate < defDate)) ? false : true;
      const condition = conditionPossibleValues.find(el => el.value === def.ConditionTypeID);
      return (
        <tr key={index}>
          <td>{def.CoverageType}</td>
          <td>
            <span style={{ color: (hasExpired) ? '#F04C41' : '#8CC739' }}>
              {(def.CoverageStatusID !== 1 && def.ExpirationDate) ? moment.utc(def.ExpirationDate).format('MM/DD/YYYY') : ''}
            </span>
          </td>
          <td>
            <div>{def.AttributeName}</div>
            <div style={{ fontSize: 10, marginTop: 4 }}>{
              (def.AttributeName && def.AttributeName.toLowerCase().includes(PROJECT_AI_WORDING.toLowerCase())) ? AIWording : def.DeficiencyText
            }</div>
          </td>
          <td>{<DisplayTextWithConditionalModal string={def.ConditionValue} displayTextLimit={TEXT_DISPLAY_LIMIT.HUNDRED} />}</td>
          <td>{<DisplayTextWithConditionalModal string={def.AttributeValue} displayTextLimit={TEXT_DISPLAY_LIMIT.HUNDRED} />}</td>
          <td>{def.DeficiencyStatusName}</td>
          <RolAccess
              masterTab='certificates_insurance'
              sectionTab='edit_certificate'
              component={() => this.renderDeficiencyActions(def, isArchived)}>
          </RolAccess>
        </tr>
      )
    });

    const title = <span className="accordionHeader d-flex justify-content-center deficiency-menu-item">
        <span className="mt-2">
          <h5>Minor</h5>
        </span>
      </span>;

    const data = [
      {
        title: title,
        content: this.renderSectionContent(tableBody, deficiencesMinor.length > 0),
        isShown: this.props.showAll || this.props.firstView
      }
    ]

    const headerStyle = {
      background: 'linear-gradient(to bottom right, #7ED0BC, #29AEA2)',
      color: '#FFFFFF',
      paddingTop: '5px',
      paddingBottom: '5px',
    };

    return (
      <div className="p-2" style={{ width: '100%' }}>
        <Accordion
          data={data}
          headerStyle={headerStyle}
        />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    certificateActions: bindActionCreators(certificateActions, dispatch)
  };
};

const mapStateToProps = (state) => {
  return {
    deficiences: state.deficiences.deficiencesList,
    certificatesList: state.certificates.certificatesList,
    showAll: state.deficiences.showAll,
    firstView: state.deficiences.firstView,
    holderRequirementSets: state.holderRequirementSets,
    local: state.localization,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeficiencesMinor);
