export const SET_FORM_CLEAR_ERROR = 'FORM_CLEAR_ERROR';
export const SET_FORM_REQUEST = 'FORM_REQUEST';
export const SET_FORM_SUCCESS = 'FORM_SUCCESS';
export const SET_FORM_FAILURE = 'FORM_FAILURE';
export const SET_FORM_RESET = 'FORM_RESET';
export const SET_FORM_SAVE = 'FORM_SAVE';
export const SET_FORMS_RESET = 'FORMS_RESET';
export const SET_FORMS_REQUEST = 'FORMS_REQUEST';
export const SET_FORMS_SUCCESS = 'FORMS_SUCCESS';
export const SET_FORMS_FAILURE = 'FORMS_FAILURE';
export const SET_SUBMISSION_CLEAR_ERROR = 'SUBMISSION_CLEAR_ERROR';
export const SET_SUBMISSION_REQUEST = 'SUBMISSION_REQUEST';
export const SET_SUBMISSION_SAVE = 'SUBMISSION_SAVE';
export const SET_SUBMISSION_SUCCESS = 'SUBMISSION_SUCCESS';
export const SET_SUBMISSION_FAILURE = 'SUBMISSION_FAILURE';
export const SET_SUBMISSION_RESET = 'SUBMISSION_RESET';
export const SET_SUBMISSIONS_RESET = 'SUBMISSIONS_RESET';