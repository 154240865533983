import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import "./savedFormStatus.css";
import { connect } from 'react-redux';
import * as scProfileActions from "../../../actions";
import {
  MARK_INCOMPLETE_FORM,
  PQ_ADMIN_ROLE_ID, PQ_FORM_LOG_TYPE,
  PQ_OPERATOR_ROLE_ID,
  SELF_SERVICE_ADMIN_ROLE_ID
} from "../../../../../lib/appConstants";
import {bindActionCreators} from "redux";
import * as linkActions from "../../../../formLink/actions";

const SavedFormStatus = (props) => {
  const [status, setStatus] = useState(props.status);
  const [showModal, setShowModal] = useState(false);
  const [showRenewalSubmission, setShowRenewalSubmission] = useState(false);
  const [showIncompleteButton, setShowIncompleteButton] = useState(false);
  const [statusId, setStatusId] = useState();
  const [error, setError] = useState(" ");
  const isComplete = "false";
  const statusValue = "Incomplete";
  const adminOperator = [PQ_ADMIN_ROLE_ID, PQ_OPERATOR_ROLE_ID, SELF_SERVICE_ADMIN_ROLE_ID];
  const admin = [PQ_ADMIN_ROLE_ID, SELF_SERVICE_ADMIN_ROLE_ID];
  const subContractorStatusIds = [4, 7]
  const [subContractorStatus, setSubContractorStatus] = useState(props.scProfile.subcontratorStatus);
  const [subContractorStatusId, setSubContractorStatusId] = useState(props.scProfile.subcontratorStatusId);

  useEffect(() => {
    setStatus(props.status);
    checkFormCompletedOrNot();
    const hcId = props.scProfile.currentHiringClient;
    const scId = props.scProfile.id;
    props.fetchAccountsList(hcId, scId, props.latestSavedForm.savedFormId);
  }, [props.status, props.latestSavedForm, props.savedForm]);

  const checkFormCompletedOrNot = () => {
    const latestSavedForm = props.latestSavedForm;
    const subContractorStatusId = props.scProfile.subcontratorStatusId;
    const isCompleteAndFormIdMatch =
      status === "Complete" &&
      latestSavedForm.savedFormId === props.savedForm.id &&
      latestSavedForm.isComplete;
    const roleId = props.loginProfile.RoleID;
  
    if (
      (isCompleteAndFormIdMatch &&
        !latestSavedForm.finIsComplete &&
        adminOperator.includes(roleId)) ||
      (isCompleteAndFormIdMatch &&
        latestSavedForm.finIsComplete &&
        admin.includes(roleId) &&
        !subContractorStatusIds.includes(subContractorStatusId))
    ) {
      setShowIncompleteButton(true);
    } else {
      setShowIncompleteButton(false);
    }
  };

  function closeIncompleteModal() {
    setShowModal(false);
  }

  function markIncompleteClick() {
    if (
      props.latestSavedForm.savedFormId === props.savedForm.id &&
      props.latestSavedForm.finIsComplete === 1
    ) {
      setShowRenewalSubmission(true);
    } else {
      setShowRenewalSubmission(false);
    }
    setShowModal(true);
  }

  const markIncomplete = () => {
    changeFormStatus(
      props.updateSavedFormStatus,
      setStatus,
      statusValue,
      props.savedForm.id,
      isComplete,
      props.fetchForms,
      props.scProfile.currentHiringClient,
      props.scProfile.id,
    );

  };

  const getSubcontractorStatusText = (subcontractorStatusId, oldStatus) => {
    switch (subcontractorStatusId) {
      case "4":
        return 'Pending Submission';
      case "5":
        return 'Pending Review';
      case "7":
        return 'Pending Renewal';
      default:
        return oldStatus;
    }
  };

  const changeFormStatus = async (
    updateSavedFormStatus,
    setStatus,
    statusValue,
    savedFormId,
    isComplete,
    fetchForms,
    hcId,
    scId
  ) => {
    await updateSavedFormStatus(savedFormId, isComplete, async () => {
      setStatus(statusValue);
      // Log for mark incomplete form
      props.linkActions.addSubcontractorFormsLog({
        savedFormId,
        subcontractorId: scId,
        eventType: MARK_INCOMPLETE_FORM,
        logType: PQ_FORM_LOG_TYPE
      });

      await fetchForms({
        hiringClientId: hcId,
        orderBy: "formName",
        orderDirection: "ASC",
        subcontractorId: scId,
      });
    });
  };
  
  const updateSCstatus = async (
    savedFormId,
    fetchAccountsList,
    markFinancialInfoAsIncompleted,
    holderId,
    projectInsuredId,
    toStatus,
    user_id,
    changeStatus,
    currentStatus,
    fetchForms,
    props
  ) => {
    const payload = {
      hiringClientId: holderId,
      subcontractorId: projectInsuredId,
      subcontractorStatusId: toStatus,
      userId: user_id,
    };
    const payloadForMarkFinfo = { savedFormId: savedFormId };
    await changeStatus(payload, async (success) => {
      //to update SC Status
      if (success) {
        await markFinancialInfoAsIncompleted(payloadForMarkFinfo, async (response) => {
          // to mark Form Financial Info IsIncompleted
          if (response) {
            await fetchAccountsList(null, null, savedFormId);
            await fetchForms({ hiringClientId: holderId, orderBy: "formName", orderDirection: "ASC", subcontractorId: projectInsuredId });
          }
        });
        
        const newStatus = getSubcontractorStatusText(toStatus);
        const newStatusId = toStatus;
        props.setUpdateSCstatus({subcontratorStatus: newStatus, subcontratorStatusId: newStatusId});
      }
    });
  };

  function saveStatus(e) {
    e.preventDefault();
    if (!statusId || statusId === "") {
      setError("Select SC Status From DropDown");
    } else {
      changeFormStatus(
        props.updateSavedFormStatus,
        setStatus,
        statusValue,
        props.savedForm.id,
        isComplete,
        props.fetchForms,
        props.scProfile.currentHiringClient,
        props.scProfile.id,
      );
  
      if (
        props.latestSavedForm.savedFormId == props.savedForm.id &&
        props.latestSavedForm.finIsComplete
      ) {
        setShowRenewalSubmission(true);
      }
      const oldStatus = props.scProfile.subcontratorStatus;
      updateSCstatus(
        props.savedForm.id,
        props.fetchAccountsList,
        props.markFinancialInfoAsIncompleted,
        props.scProfile.currentHiringClient,
        props.scProfile.id,
        statusId,
        props.loginProfile.Id,
        props.changeStatus,
        props.latestSavedForm.finIsComplete,
        props.fetchForms,
        props
      );
      const newStatus = getSubcontractorStatusText(statusId, oldStatus);
      const newStatusId = statusId;
      props.setUpdateSCstatus({subcontratorStatus: newStatus, subcontratorStatusId: newStatusId});
      setShowModal(false);
      setShowRenewalSubmission(false);
    }
  }
  
  function resetModal() {
    // window.location.reload();
    setShowModal(false);
    setShowRenewalSubmission(false);
  }
  return (
    <div className="statusContainer">
    <Modal
      show={showModal}
      onHide={closeIncompleteModal}
      className="add-item-modal note-editor-modal"
      centered
      contentClassName="modal-no-padding"
    >
      <Modal.Body className="remove-modal-padding-margin">
        <div className="status-change-modal">
          <header className="status-change-modal-header"></header>
          <div className="status-change-content">
            {!showRenewalSubmission ? (
              <span className="text-center">
                {props.resetStatusLabel}&nbsp;
                <strong>{props.scProfile.name}</strong> ?{" "}
              </span>
            ) : (
              ""
            )}
            <Row className="justify-content-center mt-3">
              {showRenewalSubmission ? (
                ""
              ) : (
                <div>
                  <Button
                    className="bg-sky-blue-gradient bn action-button mx-1"
                    onClick={() => setShowRenewalSubmission(true)}
                  >
                    Yes
                  </Button>
                  <Button
                    className="bg-sky-blue-gradient bn action-button mx-1"
                    onClick={() => {
                      //even user click on NO this needs to mark the form as incomplete
                      markIncomplete();
                      resetModal();
                    }}
                  >
                    No
                  </Button>
                </div>
              )}
            </Row>
            {showRenewalSubmission ? (
              <div>
                <div className="mb-3">
                  {props.resetStatusMessage}&nbsp;
                  <strong>{props.scProfile.name}</strong>
                </div>
                <div>
                  <Form.Label>{props.statusLabel}:</Form.Label>
                  <Form.Control
                    as="select"
                    value={statusId}
                    onChange={(e) => setStatusId(e.target.value)}
                    className="w-50 border border-info bg-white"
                  >
                    <option value="">--- Select Status ---</option>
                    <option value={4}>Pending Submission</option>
                    {props.latestSavedForm.finIsComplete && (
                      <option value={5}>Pending Review</option>
                    )}
                    <option value={7}>Pending Renewal</option>
                  </Form.Control>
                  <p className="text-danger">{error}</p>
                </div>
              </div>
            ) : (
              <div> </div>
            )}
            {showRenewalSubmission ? (
              <Row className="justify-content-end p-1">
                <Col xs="auto">
                  <Button
                    className="bg-sky-blue-gradient bn action-button"
                    type="button"
                    onClick={resetModal}
                  >
                    {props.cancelBtn}
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button
                    className="bg-sky-blue-gradient bn action-button"
                    onClick={saveStatus}
                    type="button"
                  >
                    {props.saveBtn}
                  </Button>
                </Col>
              </Row>
            ) : (
              <div></div>
            )}
          </div>
        </div>
        {/* <CopySubmissions onHide={this.closeModalCopySubmissions}></CopySubmissions> */}
      </Modal.Body>
    </Modal>
      <div>
        <span
          className={`status-cell ${
            status == "Complete" ? "complete" : "incomplete"
          }`}
        >
          {status}
        </span>
      </div>

      {/* Cheking permission to display the button for Role ID & if is latest saved form */}
      {/*--When a PQ Form was submitted, and the Prequalification associated with this form has NOT been completed yet (save & complete button in Fin. Info tab not pressed) */}
      {showIncompleteButton && (
        <button
          className="sciButton"
          onClick={() => {
            markIncompleteClick();
          }}
        >
          Mark Incomplete
        </button>
      )}
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    headerDetails:state.SCProfile.headerDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUpdateSCstatus: (status) => dispatch(scProfileActions.setUpdateSCstatus(status)),
    linkActions: bindActionCreators(linkActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedFormStatus);
