import _ from 'lodash';
import Api from '../../../lib/api'
import Utils from '../../../lib/utils';
import { setApplyErrorMessage } from './actions';

export const loadOptions = (props) => {
  return async (name, prevOptions, {page}) => {
    const next = {
      options: prevOptions, 
      hasMore: false, 
      additional: {page}
    };

    try {
      const endpoint = props.getEndpoint(name, page);
      const response = await Api.get(endpoint);
      const {results, counts} = response.data.data;
      next.options = props.getOptions(results);
      next.hasMore = counts.hasNextPage > 0 && results.length > 0;
      next.additional = {page: next.hasMore ? page + 1 : page};
    } catch (error) {
      if (_.get(error, 'response.data.data')) {
        props.dispatch(setApplyErrorMessage(Utils.getErrorMessage(
          error.response.data, 
          props.local.actions
        )));
      } else {
        props.dispatch(setApplyErrorMessage(
          props.local.actions.errorDefault
        ));
      }
    }

    return next;
  };
};

export const loadStates = (props, countrySelected) => {
  return props.actions.loadOptions({
    getEndpoint: (name, page) => {
      const params = new URLSearchParams({name, page});
      return `countries/${countrySelected}/states?${params}`;
    },
    getOptions: (results) => results.map(country => ({
      value: country.id,
      label: country.name
    }))
  });
};    

export const loadCountries = (props) => {
  return loadOptions({ 
    getEndpoint: (name, page) => {
      const params = new URLSearchParams({name, page});
      return `countries?${params}`;
    },
    getOptions: (results) => results.map(country => ({
      value: country.id,
      label: country.name
    }))
  });
};

export default loadOptions;
