import React, { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react'


const PostHogSession = function({ userId, children }) {
    const posthog = usePostHog();
  
    useEffect(() => {
        if (userId && posthog) {
            posthog.identify(userId, {
                userId: userId,
            });
        }
    }, [posthog, userId]);
  
    return children;
}

export default PostHogSession;
  