import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AMBestImportForm from './form';

const AMBestImportModal = props => {
  const {locale, close, onSubmit} = props;

  return (
    <div className="add-item-view add-entity-form-small">
      <div className="add-item-header">
        <h1>Upload AM Best Document</h1>
      </div>

      <section className="white-section">
        <div className="add-item-form-subsection">
          <AMBestImportForm
            close={close}
            locale={locale.form}
            onSubmit={onSubmit}
            />
        </div>
      </section>
    </div>
  );
};

AMBestImportModal.propTypes = {

};

const mapStateToProps = (state) => {
  return {
    locale: state.localization.strings.ambestImport,
  }
};

export default connect(mapStateToProps)(AMBestImportModal);