import Api from '../../../../../../lib/api';
import {SET_HC_ID} from './types';

export const setHcId = (hcId) => {
  return {
    type: SET_HC_ID,
    hcId: hcId
  }
}

export const enableWorkflow = (payload, callback) => {
  return (dispatch, getState) => {
    const { login: { authToken } } = getState();
    const urlQuery = `cf/workflow/enable`;
    return Api.put(`${urlQuery}`, payload, authToken)
      .then(response => {
        const { success } = response.data;
        if (success) {
          callback(false);
        } else {
          callback(true);
        }
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export const removeWorkflowAssocTasks = (workflowComponents, callback) => {
  return (dispatch, getState) => {
    const { login: { authToken } } = getState();
    const urlQuery = `cf/workflow/assocTasks`;
    return Api.put(`${urlQuery}`, { workflowComponents: workflowComponents }, authToken)
      .then(response => {
        const { success } = response.data;
        if (success) {
          callback(false);
        } else {
          callback(true);
        }
      })
      .catch((error) => {
        callback(error);
      });
  };
}