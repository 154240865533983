import _ from 'lodash';

import Api from '../../../../lib/api';
import Utils from '../../../../lib/utils';
import {setCompanyErrorMessage} from '../../actions';

export const loadOptions = (props) => {
  return async (name, prevOptions, {page}) => {
    const next = {
      options: prevOptions, 
      hasMore: false, 
      additional: {page}
    };

    try {
      const endpoint = props.getEndpoint(name, page);
      const tempToken = props.tempToken;
      const response = await Api.get(endpoint, tempToken);
      const {results, counts} = response.data.data;
      next.options = props.getOptions(results);
      if(page === 1 && endpoint.includes("trades?")){
        next.options.unshift({
          "label": "--- Please Select ---",
          "value": null
        })
      }
      next.hasMore = counts.hasNextPage > 0 && results.length > 0;
      next.additional = {page: next.hasMore ? page + 1 : page};
    } catch (error) {
      if (_.get(error, 'response.data.data')) {
        props.dispatch(setCompanyErrorMessage(Utils.getErrorMessage(
          error.response.data, 
          props.local.actions
        )));
      } else {
        props.dispatch(setCompanyErrorMessage(
          props.local.actions.errorDefault
        ));
      }
    }

    return next;
  };
};

export const loadTrades = (props) => {
  return props.actions.loadOptions({
    dispatch: props.dispatch, 
    local: props.local.strings.register,
    tempToken: props.register.tempToken,
    getEndpoint: (description, page) => {
      const params = new URLSearchParams({
        description, 
        page,
        hiringClientId: props.hiringClientId
      });
      return `trades?${params}`;
    },
    getOptions: (results) => {
      const toLabel = (results) => ({
        label: results.description,
        value: results.id
      });
      const noSelect = (trade) => 
        trade.label.toUpperCase() !== '--SELECT--' && trade;
      return results.map(toLabel).map(noSelect).filter(v => v);
    },
  });
};

export const loadStates = (props, countrySelected) => {
  return props.actions.loadOptions({
    dispatch: props.dispatch, 
    local: props.local.strings.register,
    tempToken: props.register.tempToken,
    getEndpoint: (name, page) => {
      const params = new URLSearchParams({name, page});
      return `countries/${countrySelected}/states?${params}`;
    },
    getOptions: (results) => results.map(country => ({
      value: country.id,
      label: country.name
    }))
  });
};

export const loadCountries = (props) => {
  return props.actions.loadOptions({
    dispatch: props.dispatch, 
    local: props.local.strings.register,
    tempToken: props.register.tempToken,
    getEndpoint: (name, page) => {
      const params = new URLSearchParams({name, page});
      return `countries?${params}`;
    },
    getOptions: (results) => results.map(country => ({
      value: country.id,
      label: country.name
    }))
  });
};

export default loadOptions;
