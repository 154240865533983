import _ from 'lodash';
import * as types from '../actions/types';

const initialState = {
  id: '',
  url: '',
  error: '',
  formId: '',
  formName: '',
  lastUpdated: 0,
  isActive: false,
  pagination: {
    numPages: 0,
    page: 1,
    total: 0,
  },
  submission: undefined, // To Process Individual Submission
  submissions: [], // To Process All Submissions
};

export default function formioSubmissionReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_FORM_CLEAR_ERROR:
      return {
        ...state,
        error: '',
      };
    case types.SET_SUBMISSION_REQUEST:
      return {
        ...state,
        formId: action.formId,
        id: action.id,
        url: action.url,
        submission: undefined,
        isActive: true,
      };
    case types.SET_SUBMISSION_SAVE:
      return {
        ...state,
        formId: action.formId || state.formId,
        id: action.id || state.id,
        url: action.url || state.url,
        submission: undefined,
        isActive: true,
      };
    case types.SET_SUBMISSION_SUCCESS:
      return {
        ...state,
        id: action.submission._id,
        submission: { ...action.submission },
        isActive: false,
        error: '',
      };
    case types.SET_SUBMISSION_FAILURE:
      return {
        ...state,
        isActive: false,
        isInvalid: true,
        error: action.error,
      };
    case types.SET_SUBMISSION_RESET:
      return initialState;

    case types.SET_SUBMISSIONS_RESET:
      return initialState;

    default:
      return state;
  }
}