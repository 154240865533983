import React, { Component, Fragment } from 'react';
import { Field, reduxForm, change, formValueSelector ,touch} from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Utils from '../../../../../lib/utils';

import renderField from '../../../../customInputs/renderField';
import renderSelect from '../../../../customInputs/renderSelect';
import renderTypeAhead from '../../../../customInputs/renderTypeAhead';
import renderRemovable from '../../../../customInputs/renderRemovable';
import * as commonActions from '../../../../common/actions';
import {AsyncPaginate} from 'react-select-async-paginate';
import validate from './validation';
import QuickView from '../../../insureds/quickview';
import * as loadApplyOptions from '../../../../common/countyDropdownActions/loadOptions'
import * as applyActions from '../../../../common/countyDropdownActions/actions'

const SelectAsyncOptions = ({ input, meta, dispatch, ...props }) => {
  const touchedError = meta.touched && meta.error;
  const name = input.name;
  const baseClassName = `${props.className || ''} select-${name}`;
  const errorClassName = `${touchedError ? `${baseClassName}-error` : ''}`;

  return (
    <React.Fragment>
      <AsyncPaginate
        {...input}
        {...props}
        openMenuOnClick={true}
        closeMenuOnScroll={true}
        cacheOptions={true}
        pageSize={10}
        additional={{ page: 1 }}
        classNamePrefix="vk"
        className={`${baseClassName} ${errorClassName}`}
        menuPlacement="auto"
        onFocus={(_) => { }}
        onBlur={() => meta.dispatch(touch('InsuredInfoForm', name))}
        onChange={(newValue) => input.onChange(newValue)}
        captureMenuScroll={true}
        closeMenuOnSelect={true}
        debounceTimeout={300}
        loadOptionsOnMenuOpen={true}
      />
      {touchedError && (
        <span className="select-error-message field-validation-message">
          {meta.error}
        </span>
      )}
    </React.Fragment>
  );
};
class InsuredInfoForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showHolderList: true,
      selectedCountry : this.props && this.props.profile && this.props.profile.CountryID || 1
    }
  }
  handleChangeCountry(value,name){
    if(this.state.selectedCountry !== value.value){
      this.props.dispatch(change(
      'InsuredInfoForm',
      'stateId',
      {value:null,label:''}
    ))
    }
    this.setState({selectedCountry:value.value})
  }
  componentDidMount() {
    const { profile, dispatch } = this.props;
    
    if (profile) {
      dispatch(change('InsuredInfoForm', 'insuredName', profile.InsuredName || ""));
      dispatch(change('InsuredInfoForm', 'legalName', profile.LegalName || ""));
      dispatch(change('InsuredInfoForm', 'address1', profile.Address || ""));
      if(profile.Address2 !== "not available"){
        dispatch(change('InsuredInfoForm', 'address2', profile.Address2 || ""));
      }
      dispatch(change('InsuredInfoForm', 'city', profile.City || ""));
      dispatch(change('InsuredInfoForm', 'postalCode', profile.PostalCode || ""));
      dispatch(change('InsuredInfoForm', 'stateId', {value:profile.StateId,label:profile.stateName} || ""));
      dispatch(change('InsuredInfoForm', 'countryId', {value:profile.CountryID,label:profile.countryName} || ""));

      dispatch(change('InsuredInfoForm', 'contactName', profile.ContactName || ""));
      dispatch(change('InsuredInfoForm', 'contactPhone', profile.ContactPhone || ""));
      dispatch(change('InsuredInfoForm', 'contactFax', profile.ContactFax || ""));
      dispatch(change('InsuredInfoForm', 'contactEmail', profile.ContactEmail || ""));      
      dispatch(change('InsuredInfoForm', 'taxId', profile.TaxID || ""));
      dispatch(change('InsuredInfoForm', 'vendor', profile.Vendor || ""));
      dispatch(change('InsuredInfoForm', 'note', profile.Note || ""));

      if (Array.isArray(profile.HolderId) && Array.isArray(profile.HolderName)) {
        const holderObj = {
          value: profile.HolderId[0],
          label: profile.HolderName[0]
        };
        dispatch(change('InsuredInfoForm', 'holderId', holderObj || ""));
      }
    }else{
      dispatch(change('InsuredInfoForm', 'countryId', {value:1,label:"United States"}));
    }
  }

  getHolderList = () => {

    if (!this.props.profile) return null
    const {HolderName} = this.props.profile;

    if (Array.isArray(HolderName) && HolderName.length > 1) {
      return (
        <a className='icon-quick_view cell-table-link'>
          View all
          <QuickView column="holder" row={HolderName} idx={0} />
        </a>
      )
    }

    return null
  }

  hideHolderList = () => {
    this.setState({
      showHolderList: false
    })
  }

  renderFormField = (element, idx) => {
    const { type, name, label, ph, options, conditional, show, loadOptions } = element;    
    const fieldType = type || 'text';
    const style = {};
    if (conditional && !show) {
      style.display = 'none';
    }

    if (type === 'typeAhead') {
      const { fetching, results, error, handleSearch, onSelect } = element;

      return (
        <div className="col-md no-padd" key={idx} style={style}>
          <div className="admin-form-field-wrapper keywords-field">
            <label htmlFor={name}>{`${label}:`}</label>
            <Field
              resetOnClick
              name={name}
              placeholder={ph}
              fetching={fetching}
              results={results}
              handleSearch={handleSearch}
              fetchError={error}
              component={renderTypeAhead}
              onSelect={onSelect}
            />
          </div>
        </div>
      );
    } else if (type === 'removable') {
      const { valueText, disabled, onRemove } = element;
      return (
        <div className="col-md no-padd" key={idx} style={style}>
          <div className="admin-form-field-wrapper keywords-field">
            <label htmlFor={name}>{`${label}:`}</label>
            <Field
              name={name}
              valueText={valueText}
              component={renderRemovable}
              onRemove={onRemove}
              disabled={disabled}
            />
            <div className={'col insured-modal'} hidden={!this.state.showHolderList}>
              {this.getHolderList()}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div key={idx} className="wiz-field admin-form-field-wrapper" style={style}>
        <label htmlFor={name}>{`${label}:`}</label>
        {
          loadOptions?
          <div className="select-wrapper">
            <Field
               name={name}
               component={SelectAsyncOptions}
               className={'accountManager'}
               loadOptions={loadOptions}
               cacheUniqs={[this.state.selectedCountry]}
               onChange={value => this.handleChangeCountry(value,name)}
               />
          </div>
          :
          <Field
            name={name}
            type={fieldType}
            placeholder={ph}
            component={renderField} />
        }
      </div>
    );
  }

  handleSearch = (filterTerm) => {
    this.props.commonActions.fetchTypeAhead({ nameTerm: filterTerm });
  }

  render() {
    const {
      labelInsuredName,
      labelLegalName,
      labelAddress1,
      labelAddress2,
      labelCity,
      labelState,
      labelPostalCode,
      labelCountry,
      labelContactName,
      labelContactPhone,
      labelContactFax,
      labelContactEmail,
      labelTaxId,
      labelVendor,
      labelHolder,
      labelNote,
      cancelButton,
      saveButton
    } = this.props.local.strings.insured.addInsuredModal.insuredInfoTab;
    const { 
      handleSubmit, 
      countryCurrentValue, 
      holderIdCurrentValue,
      common 
    } = this.props;
    const {
      typeAheadResults,
      typeAheadFetching,
      typeAheadError,
    } = this.props.common;

    // const countryOptions = Utils.getOptionsList(`-- ${labelCountry} --`, common.countries, 'name', 'id', 'name');
    const holderOptions = Utils.getOptionsList(null, typeAheadResults, 'name', 'id', 'name');
    const countryOptions = this.props.actions.loadCountries(this.props)
    const stateOptions = this.props.actions.loadStates(this.props,this.state.selectedCountry)

    const leftFields = [
      { name: 'insuredName', label: labelInsuredName, ph: `-- ${labelInsuredName} --` },
      { name: 'legalName', label: labelLegalName, ph: `-- ${labelLegalName} --` },
      { name: 'address1', label: labelAddress1, ph: `-- ${labelAddress1} --` },
      { name: 'address2', label: labelAddress2, ph: `-- ${labelAddress2} --` },
      { name: 'city', label: labelCity, ph: `-- ${labelCity} --` },
      { name: 'postalCode', label: labelPostalCode, ph: `-- ${labelPostalCode} --` },
      { name: 'stateId', label: labelState, ph: `-- ${labelState} --`, loadOptions:stateOptions},
      { name: 'countryId', label: labelCountry, options: countryOptions, loadOptions:countryOptions},      
    ]
    const rightFields = [
      { name: 'contactName', label: labelContactName, ph: `-- ${labelContactName} --` },
      { name: 'contactPhone', label: labelContactPhone, ph: `-- ${labelContactPhone} --` },
      { name: 'contactFax', label: labelContactFax, ph: `-- ${labelContactFax} --` },
      { name: 'contactEmail', label: labelContactEmail, ph: `-- ${labelContactEmail} --` },
      { name: 'taxId', label: labelTaxId, ph: `-- ${labelTaxId} --` },
      { name: 'vendor', label: labelVendor, ph: `-- ${labelVendor} --` },
      {
        name:'holderId', label: labelHolder, ph: `-- Search ${labelHolder} --`, type: 'typeAhead',
        handleSearch: this.handleSearch, fetching: typeAheadFetching, results: holderOptions,
        error: typeAheadError, conditional: true, show: !holderIdCurrentValue
      },
      {
        name:'holderId', label: labelHolder, type: 'removable',
        valueText: holderIdCurrentValue ? holderIdCurrentValue.label : '',
        conditional: true, show: holderIdCurrentValue, onRemove: this.hideHolderList
      },
      { name: 'note', label: labelNote, ph: `-- ${labelNote} --` },
    ];

    return (
      <form
        onSubmit={handleSubmit}
        className="entity-info-form wiz-form"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              {leftFields.map(this.renderFormField)}
            </div>
            <div className="col-md-6 col-sm-12">
              {rightFields.map(this.renderFormField)}
            </div>
          </div>
        </div>

        <div className="wiz-buttons">
          {this.props.insureds.addInsuredFetching ? (
            <div className="spinner-wrapper">
              <div className="spinner mb-4"></div>
            </div>
          ) : (
            <Fragment>
              <a className="wiz-cancel-button" onClick={this.props.close}>{cancelButton}</a>
              <button className="wiz-continue-btn bg-sky-blue-gradient bn">{saveButton}</button>
            </Fragment>
          )}
        </div>
      </form>
    );
  }
};

InsuredInfoForm = reduxForm({
  form: 'InsuredInfoForm',
  validate,
  //asyncValidate,
  //asyncBlurFields: ['insuredName'],
})(InsuredInfoForm);

const mapStateToProps = (state, ownProps) => {
  return {
    currentForm: state.form,
    local: state.localization,
    common: state.common,
    insureds: state.insureds,
    countryCurrentValue: formValueSelector('InsuredInfoForm')(state, 'countryId'),
    holderIdCurrentValue: formValueSelector('InsuredInfoForm')(state, 'holderId')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      ...bindActionCreators(applyActions, dispatch),
      ...loadApplyOptions
    },
    commonActions: bindActionCreators(commonActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InsuredInfoForm);
