import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as formsActions from '../../../../actions';
import html2pdf from 'html2pdf.js'
import { Formio } from "@formio/react";
import { FORM_IO_WIZARD_FORM } from '../../../../../../lib/appConstants';
class viewPdfAndDownload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      downloadEnable: false,
      submissionData: null,
      formInstance: null
    }
    this.downloadPDF = this.downloadPDF.bind(this);
    this.viewPdf = this.viewPdf.bind(this);
  }

  async componentWillMount() {
    const {formInstance} = this.props.location.state;
    await this.setState({formInstance: formInstance});
  }

  async componentDidMount() {
    const formId = this.props.match.params.formId;
    const submissionId = this.props.match.params.submissionId;
    this.props.actions.getForm(this.props.match.params.formId);
    if (submissionId) {
      this.props.actions.getSubmission(submissionId, formId);
    }
    await this.viewPdf()

  }

  findFinFile(filesComponents, key, value) {
    filesComponents.some(function iter(item) {
        if (item && Object.keys(item.properties).length > 0  && item.properties[key] === value) {
          item.uploadOnly = true;
        }
        return Array.isArray(item.components) && item.components.some(iter);
    });
    return filesComponents;
  }

  financialWizardForms(form, userRoleId) {
    if (form.components && userRoleId === 6) {
       form.components = this.findFinFile(form.components, 'isFinancialInformation', 'true');
    }
    return form;
  }

  financialForms(form, userRoleId) {
    if (form.components && userRoleId === 6) {
      for (let i = 0; i < form.components.length; i++) {
        if (form.components[i].properties.isFinancialInformation === 'true') {
          form.components[i].uploadOnly = true;
        }
      }
    }
    return form;
  }

  goBack() {
    this.props.history.goBack();
  }
  
  async downloadPDF() {
    if (!this.props.formioForms || !this.state.downloadEnable) {
      return
    }
   await this.setState({ loading: true });
    let options = {
      filename: 'myfile.pdf',
      jsPDF: { format: 'a2', },
      pagebreak: { mode: ['avoid-all']},
      enableLinks: false
    };
    let content = document.getElementById('formiocomp');
    await html2pdf().from(content).set(options).toPdf()
      .get('pdf').then(function (pdf) {
        var totalPages = pdf.internal.getNumberOfPages();
        let i;
        for (i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.setTextColor(150);
          pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() / 2.25), (pdf.internal.pageSize.getHeight() - 8));
        }
      }).save();
    this.setState({ loading: false });
  }

  //receive props and use as value for formio
  async viewPdf() {
    if (!this.props.formioForms) {
      return
    }
    const formId = this.props.match.params.formId;
    const submissionId = this.props.match.params.submissionId;
    let linkToForm;
    if (submissionId) {
      linkToForm = `${process.env.REACT_APP_FORMIO_SERVICE_URL}/form/${formId}/submission/${submissionId}`
    } else {
      linkToForm = `${process.env.REACT_APP_FORMIO_SERVICE_URL}/form/${formId}`
    }
    let formio = new Formio(linkToForm);
    await formio.loadForm().then((form) => {
      const { RoleID } = this.props.login.profile;
      if (form.display === FORM_IO_WIZARD_FORM) {
        this.financialWizardForms(form, RoleID);
      } else {
        this.financialForms(form, RoleID);
      }
      form.display = 'form';
      Formio.createForm(document.getElementById('formiocomp'), form, {
        readOnly: true,
      }).then((instance) => {
        if (submissionId) {
          formio.loadSubmission().then(async (submission) => {
            instance.submission = submission;
          });
        } else {
            instance.submission = {data: this.state.formInstance};
        }

      });
    });
    this.setState({ downloadEnable: true });
  }

  render() {
    return (
        <div>
          <div className="pdf-buttons-container">
            <button className="pdf-button" onClick={() => this.goBack()}>Back</button>
            <button className="pdf-button" onClick={() => this.downloadPDF()}>Download PDF</button>
            {this.state.loading ? <div className="common-spinner-wrapper">
              <div className="common-spinner" />
            </div> : null}
          </div>
          <div id="formiocomp" style={{ width: '90%', height: 'auto', marginLeft: 'auto', marginRight: 'auto'}} ></div>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    formioForms: state.formioForms,
    formioSubmission: state.formioSubmission,
    login: state.login
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(formsActions, dispatch)
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(viewPdfAndDownload);
