export const SET_FETCHING_ACCOUNTS_LIST = 'SET_FETCHING_ACCOUNTS_LIST';
export const SET_ACCOUNTS_LIST = 'SET_ACCOUNTS_LIST';
export const SET_SUBMITION_DATES = 'SET_SUBMITION_DATES';
export const SET_PREQUAL_DATES = 'SET_PREQUAL_DATES';
export const SET_SAVED_FORM_AS_COMPLETED = 'SET_SAVED_FORM_AS_COMPLETED';

export const SET_FETCHING_SCORE_CARDS_CONCEPTS = 'SET_FETCHING_SCORE_CARDS_CONCEPTS';
export const SET_SCORE_CARDS_CONCEPTS = 'SET_SCORE_CARDS_CONCEPTS';
export const SET_SCORE_CARDS_CONCEPTS_EMPTY = 'SET_SCORE_CARDS_CONCEPTS_EMPTY';

export const SET_FINANCIALS_ERROR = 'SET_FINANCIALS_ERROR';
export const SET_FINANCIALS_SUCCESS_MSG = 'SET_FINANCIALS_SUCCESS_MSG';

export const SET_CALCULATED_ACCOUNTS = 'SET_CALCULATED_ACCOUNTS';
export const SET_FETCHING_CALCULATE_ACCOUNTS = 'SET_FETCHING_CALCULATE_ACCOUNTS';
export const SET_WORKING_CAPITAL = 'SET_WORKING_CAPITAL';

export const SET_COPY_FINANCIAL_SUBMISSION_FETCHING = 'SET_COPY_FINANCIAL_SUBMISSION_FETCHING';
export const SET_COPY_FINANCIAL_SUBMISSION_SUCCESS = 'SET_COPY_FINANCIAL_SUBMISSION_SUCCESS';
export const SET_COPY_FINANCIAL_SUBMISSION_ERROR = 'SET_COPY_FINANCIAL_SUBMISSION_ERROR';
