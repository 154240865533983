import React, {useEffect} from 'react';
import FormSendMail from './forms/FormSendMail'

const MailPanel = (props) => {
  useEffect(() => {
    // console.log('mail panel: ', data);
  })
  const {mailForm, data: {holderId, insuredId, projectId, holderName, insuredName, projectName, recipients, sent, templateId, documents}, onSendEmail, subject} = props;
  return (<React.Fragment>
    <FormSendMail
      locale={mailForm}
      onSendEmail={onSendEmail}
      holderName={holderName}
      insuredName={insuredName}
      projectName={projectName}
      recipients={recipients}
      sent={sent}
      templateId={templateId}
      holderId={holderId}
      insuredId={insuredId}
      projectId={projectId}
      subject={subject}
      documents={documents}
    />
  </React.Fragment>)
};

export default MailPanel;