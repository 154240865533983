import * as types from '../actions/types';

export default function HCProfileReducer(state = {
  fetchingHeader: false,
  headerDetails: {},
  fetchingHC: false,
  hcList: {},
  hcId: "",
  paymentStatus: null,
  paymentError: '',
  lastPayment: {},
  locations: [],
  totalAmountOfLocations: 0,
  fetchingLocations: false,
  fetchingLocationsError: null,
  states: [],
  provAndTerr: [],
  countries: [],
  savingLocation: false,
  savingLocationsError: null,
  primaryWasChanged: null,
  tradesListForChangeModal: [],
  tradesListForChangeModalFetching: false,
  tradesListForChangeModalError: '',
  tradesUpdateModalFetching: false,
  tradesUpdateModalError: '',
  taxIdExists: false,
  latestSavedForm: {},
  latestSavedFormError: '',
  existingSubcontractorId: 0,
  existingSubcontractorName: '',
  mergeSubcontractorsSucceeded: false,
  isMergingSubcontractors: false,
  isSCMoving: false,
  isSCMoved: false,
  scMoveError: null,
  scHasHCSiblings: false,
  hasTemplateDisconnect: false,
}, action) {
  switch(action.type) {
    case types.SET_FETCHING_SC_HEADER:
      return Object.assign({}, state, {
        fetchingHeader: action.isFetching
      });
    case types.SET_SC_HEADER_DETAILS:
      return Object.assign({}, state, {
        headerDetails: action.headerDetails
      });
    case types.SET_FETCHING_SC_HIRINGCLIENTS:
      return Object.assign({}, state, {
        fetchingHC: action.isFetching
      });
    case types.SET_SC_HIRINGCLIENTS:
      return Object.assign({}, state, {
        hcList: action.hcList
      });
    case types.SET_SC_HC_ID:
      return Object.assign({}, state, {
        hcId: action.hcId
      });
    case types.SET_SC_PAYMENT_STATUS:
      return Object.assign({}, state, {
        paymentStatus: action.payload,
      });
    case types.SET_SC_PAYMENT_STATUS_ERROR:
      return Object.assign({}, state, {
        paymentError: action.payload,
      });
    case types.SET_SC_LAST_PAYMENT:
      return Object.assign({}, state, {
        lastPayment: action.payload,
      });
    case types.SET_FETCHING_LOCATIONS:
      return Object.assign({}, state, {
        fetchingLocations: action.isFetching
      });
    case types.SET_TOTAL_AMOUNT_OF_LOCATIONS:
      return Object.assign({}, state, {
        totalAmountOfLocations: action.locationsLength,
      });
    case types.SET_LOCATIONS_LIST:
      return Object.assign({}, state, {
        locations: action.list,
      });
    case types.SET_LOCATIONS_LIST_ERROR:
      return Object.assign({}, state, {
        fetchingLocationsError: action.error,
      });
    case types.SET_US_STATES_LIST:
      return Object.assign({}, state, {
        states: action.list,
      });
    case types.SET_CANADIAN_PROVINCES_AND_TERRITORIES_LIST:
      return Object.assign({}, state, {
        provAndTerr: action.list,
      });
    case types.SET_COUNTRIES_LIST:
      return Object.assign({}, state, {
        countries: action.list,
      });
    case types.SET_LOCATIONS_ERROR:
      return Object.assign({}, state, {
        savingLocationsError: action.error,
      });
    case types.SET_SAVING_LOCATION:
      return Object.assign({}, state, {
        savingLocation: action.saving,
      });
    case types.SET_CHANGING_PRIMARY:
      return Object.assign({}, state, {
        primaryWasChanged: action.isChanged,
      });
    case types.SET_TAX_ID_EXISTS:
      return Object.assign({}, state, {
        taxIdExists: action.taxIdExists,
        existingSubcontractorId: action.existingSubcontractorId,
        existingSubcontractorName: action.existingSubcontractorName
      });
    case types.SET_IS_MERGING_SUBCONTRACTORS:
      return Object.assign({}, state, {
        isMergingSubcontractors: action.isMergingSubcontractors
      });
    case types.SET_MERGE_SUBCONTRACTOTRS_SUCCEEDED:
      return Object.assign({}, state, {
        mergeSubcontractorsSucceeded: action.mergeSubcontractorsSucceeded
      });
    case types.SET_LASTEST_SAVED_FORM:
      return Object.assign({}, state, {
        latestSavedForm : action.latestSavedForm
      });
    
    case types.SET_LASTEST_SAVED_FORM_ERROR:
    return Object.assign({}, state, {
      latestSavedFormError : action.error
    });

    case types.SET_CHANGE_TRADES_MODAL_LIST_FETCHING:
      return { ...state, tradesListForChangeModal: [], tradesListForChangeModalFetching: true, tradesListForChangeModalError: '' };
    case types.SET_CHANGE_TRADES_MODAL_LIST_SUCCESS:
      return {
        ...state,
        tradesListForChangeModalFetching: false,
        tradesListForChangeModal: action.trades.map(trade => ({ label: trade.description, value: trade.id })),
      };
    case types.SET_CHANGE_TRADES_MODAL_LIST_ERROR:
      return { ...state, tradesListForChangeModalError: action.error, tradesListForChangeModalFetching: false };

    case types.SET_UPDATE_TRADES_MODAL_FETCHING:
      return { ...state, tradesUpdateModalFetching: true, tradesUpdateModalError: '' };
    case types.SET_UPDATE_TRADES_MODAL_SUCCESS:
      return { ...state, tradesUpdateModalFetching: false };
    case types.SET_UPDATE_TRADES_MODAL_ERROR:
      return { ...state, tradesUpdateModalError: action.error, tradesUpdateModalFetching: false };
    case types.SET_MOVING_SC_TO_HIRINGCLIENT:
      return {...state, isSCMoving: action.isSCMoving};
    case types.SET_IS_SUBCONTRACTOR_MOVED:
      return {...state, isSCMoved: action.isSCMoved};
    case types.SET_HAS_TEMPLATE_DISCONNET:
      return {...state, hasTemplateDisconnect: action.hasTemplateDisconnect};
    case types.SET_SUBCONTRACTOR_MOVE_FAILED:
      return {...state, scMoveError: action.scMoveError};
    case types.SET_SUBCONTRACTOR_HAS_HC_SIBLINGS:
      return {...state, scHasHCSiblings: action.scHasHCSiblings};
    case types.SET_UPDATE_SC_STATUS:
      return {
          ...state, 
          headerDetails: {
            ...state.headerDetails,
            subcontratorStatus: action.status.subcontratorStatus,
            subcontratorStatusId: action.status.subcontratorStatusId,
          },
      };          
    default:
      return state;
  }
};
