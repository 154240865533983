// sets
export const SET_PROJECTS_ERROR = 'SET_HOLDERS_PROJECTS_ERROR';
export const SET_PROJECTS_LIST = 'SET_HOLDERS_PROJECTS_LIST';
export const SET_TOTAL_AMOUNT_OF_PROJECTS = 'SET_HOLDERS_TOTAL_AMOUNT_OF_PROJECTS';
export const SET_FETCHING_PROJECTS = 'SET_HOLDERS_FETCHING_PROJECTS';
export const SET_CONTRACTS_ERROR = 'SET_HOLDERS_CONTRACTS_ERROR';
export const SET_CONTRACTS_LIST = 'SET_HOLDERS_CONTRACTS_LIST';
export const SET_TOTAL_AMOUNT_OF_CONTRACTS = 'SET_HOLDERS_TOTAL_AMOUNT_OF_CONTRACTS';
export const SET_FETCHING_CONTRACTS = 'SET_HOLDERS_FETCHING_CONTRACTS';
export const SET_PROJECT_STATUS = 'SET_HOLDERS_PROJECT_STATUS';
export const SET_SHOW_MODAL = 'SET_HOLDERS_SHOW_MODAL';
export const SET_ADD_PROJECT_DATA = 'SET_HOLDERS_ADD_PROJECT_DATA';
export const SET_PROJECT_FAVORITE_ERROR = 'SET_HOLDERS_PROJECT_FAVOTIRE_ERROR';
export const SET_PROJECT_FAVORITE = 'SET_HOLDERS_PROJECT_FAVORITE';
export const SET_PROJECT_FAVORITE_FETCHING = 'SET_HOLDERS_PROJECT_FAVORITE_FETCHING';
export const SET_PROJECT_ADD_ERROR = 'SET_HOLDERS_PROJECT_ADD_ERROR';
export const SET_PROJECT_ADD_SUCCESS = 'SET_HOLDERS_PROJECT_ADD_SUCCESS';
export const SET_PROJECT_ADD_FETCHING = 'SET_HOLDERS_PROJECT_ADD_FETCHING';

export const SET_HOLDER_CUSTOM_FIELDS_FETCHING = 'SET_HOLDER_CUSTOM_FIELDS_FETCHING';
export const SET_HOLDER_CUSTOM_FIELDS_SUCCESS = 'SET_HOLDER_CUSTOM_FIELDS_SUCCESS';
export const SET_HOLDER_CUSTOM_FIELDS_ERROR = 'SET_HOLDER_CUSTOM_FIELDS_ERROR';

export const SET_TYPE_AHEAD_ERROR_PROJECTS = 'SET_TYPE_AHEAD_ERROR_PROJECTS';
export const SET_TYPE_AHEAD_SUCCESS_PROJECTS = 'SET_TYPE_AHEAD_SUCCESS_PROJECTS';
export const SET_TYPE_AHEAD_FETCHING_PROJECTS = 'SET_TYPE_AHEAD_FETCHING_PROJECTS';
export const RESET_TYPE_AHEAD_RESULTS_PROJECTS = 'RESET_TYPE_AHEAD_RESULTS_PROJECTS';

export const SET_TOTAL_AMOUNT_PROJECT_ARCHIVE = 'SET_TOTAL_AMOUNT_PROJECT_ARCHIVE';
