const validate = (values, props) => {
  const { requiredValidation } = props.local.strings.scProfile.changeTradesModal;

  const errors = {}
  if (!values.tradeId) {
    errors.tradeId = requiredValidation;
  }

  if (Number(values.tradeId.value) <= 0) {
    errors.tradeId = requiredValidation;
  }

  return errors;
};

export default validate;
