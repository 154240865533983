import React from 'react';

const ProjectAIWording = (props) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="attachments-table">
          <table className="table table-bordered table-striped">
            <thead>
            <tr>
              <th>Description</th>
            </tr>
            </thead>
            <tbody>
              <tr>
                <td>{
                  props.AIWording && props.AIWording !== "" ? props.AIWording : 'No AI Wording Found'
                }</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
};

export default ProjectAIWording;
