export const SET_AGENCIES_LIST_ERROR = 'SET_AGENCIES_LIST_ERROR';
export const SET_AGENCIES_LIST = 'SET_AGENCIES_LIST';
export const SET_FETCHING_AGENCIES = 'SET_FETCHING_AGENCIES';
export const SET_SHOW_AGENCIES_MODAL = 'SET_SHOW_AGENCIES_MODAL';

export const SET_AGENTS_LIST_ERROR = 'SET_AGENTS_LIST_ERROR';
export const SET_AGENTS_LIST = 'SET_AGENTS_LIST';
export const SET_FETCHING_AGENTS = 'SET_FETCHING_AGENTS';
export const SET_SHOW_AGENTS_MODAL = 'SET_SHOW_AGENTS_MODAL';
export const SET_SHOW_ADD_AGENTS_MODAL = 'SET_SHOW_ADD_AGENTS_MODAL';
