import React, { Fragment, useState, useEffect } from 'react';
import {Field, getFormValues, reduxForm, touch, change} from 'redux-form';
import { connect } from 'react-redux';

import renderField from '../../customInputs/renderField';
import FilterActions from '../../common/filterActions/FilterActions'
import renderSelect from '../../customInputs/renderSelect';
import Api from '../../../lib/api';

import ReactSelect from 'react-select';

const RenderSelectInput = ({ input, meta, dispatch, ...props }) => {
  const name = input.name;

  return (
    <React.Fragment>
      <ReactSelect
        {...input}
        {...props}
        name={name}
        value={input.value}
        isMulti
        isSearchable
        isClearable={false}
        onBlurResetsInput={false}
        onBlur={() => meta.dispatch(touch('FilterHC', name))}
        onChange={(newValue) => input.onChange(newValue)}
      />
    </React.Fragment>
  );
};

let FilterHC = props => {
  const [hcSuggestions, setHcSuggestions] = useState([]);
  const [holderSuggestions, setHolderSuggestions] = useState([]);

  const mapSuggestionsProperties = (suggestions) => suggestions.map(suggestion => ({
    value: suggestion.id,
    label: suggestion.name
  }));

  const fetchHiringClients = (system) => {
    return Api.get(`hiringclients?system=${system}`, props.login.authToken)
      .then(response => {
        const {success, data} = response.data;

        if (success && data.hiringClients.length > 0) {
          return data.hiringClients;
        }
      });
  };

  let {
    title,
    keywordsLabel,
    keywordsPlaceholder
  } = props.local.strings.hiringClients.filter;

  let {
    nameLabel,
    namePlaceholder,
    contactNameLabel,
    contactNamePlaceholder,
  } = props.local.strings.holders.filter;

  const { handleSubmit, profile } = props;

  const archivedOptions = [
    { label: 'False', value: 0, selected: true },
    { label: 'True', value: 1 },
    { label: 'N/A', value: 2 },
  ];

  useEffect(() => {
    if (props.login.currentSystem === 'pq') {  
      if (hcSuggestions.length === 0) {
        fetchHiringClients('pq')
          .then(hcs => {
            hcs && hcs.length && setHcSuggestions(
                mapSuggestionsProperties(hcs)
            )
          });
      }
    } else if (holderSuggestions.length === 0) {
      fetchHiringClients('cf')
        .then(holders => {
          holders && holders.length && setHolderSuggestions(
              mapSuggestionsProperties(holders)
          )
        });
    }
  }, [props.login.currentSystem, profile.Role, profile.CFRole]);

  useEffect(() => {
    if(props.login.currentSystem === 'pq') {
      props.dispatch(change('FilterHC', 'hcTags', props.filterBox.hcTags))
      props.dispatch(change('FilterHC', 'searchTerm', props.filterBox.searchTerm))
    }
  }, [props.filterBox])

  const handleSaveFilters = () => {
    props.filtersSaveCallback(props.formValues)
  }

  return (
    <form onSubmit={handleSubmit} className="list-view-filter-form">
      <h2 className="list-view-filter-title">{title}</h2>
      <div className="container-fluid filter-fields">
        <div className="row">

          {profile.CFRole && (
            <Fragment>
              <div className="col-md no-padd">
                <div className="admin-form-field-wrapper keywords-field">
                  <label htmlFor="nameTerm">{nameLabel}: </label>
                  <Field
                    name="nameTerm"
                    placeholder={namePlaceholder}
                    type="text"
                    component={renderField}
                    className="tags-input"
                  />
                </div>
              </div>
              <div className="col-md no-padd">
                <div className="admin-form-field-wrapper keywords-field">
                  <label htmlFor="contactNameTerm">{contactNameLabel}: </label>
                  <Field
                    name="contactNameTerm"
                    type="text"
                    placeholder={contactNamePlaceholder}
                    component={renderField}
                    className="tags-input"
                  />
                </div>
              </div>
              <div className="col-md no-padd">
                <div className="admin-form-field-wrapper keywords-field">
                  <label htmlFor="archived">{'Archived'}: </label>
                  <div className="select-wrapper">
                    <Field
                      name="archive"
                      component={renderSelect}
                      options={archivedOptions}
                      onChange={props.setArchiveFilterTag}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md no-padd">
                <label htmlFor="holdersTags">{props.local.strings.holders.title}:</label>
                <div className="select-wrapper">
                  <Field
                    name="holderTags"
                    type="text"
                    id="holderTags"
                    placeholder={`Select...`}
                    component={RenderSelectInput}
                    className="tags-input"
                    options={holderSuggestions}
                  />
                </div>
              </div>
            </Fragment>
          )}

          {profile.Role && (
            <Fragment>
              <div className="col-md no-padd">
                <div className="admin-form-field-wrapper keywords-field">
                  <label htmlFor="searchTerm">{keywordsLabel}: </label>
                  <Field
                    name="searchTerm"
                    type="text"
                    placeholder={`--${keywordsPlaceholder}--`}
                    component={renderField}
                    className="tags-input"
                  />
                </div>
              </div>
              <div className="col-md no-padd">
                <div className="admin-form-field-wrapper keywords-field">
                  <label htmlFor="hiringClientsTags">{props.local.strings.hiringClients.title}:</label>
                  <div className="select-wrapper">
                    <Field
                      name="hcTags"
                      type="text"
                      id="hcTags"
                      placeholder={`Select...`}
                      component={RenderSelectInput}
                      className="tags-input"
                      options={hcSuggestions}
                    />
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>

        <div className="row">
          <div className="col-md-12 d-flex justify-content-end">
            {
              props.login.currentSystem === 'pq' ? (
                <FilterActions
                  filtersSaveCallback={handleSaveFilters}
                  filtersResetCallback={(e) => props.filtersResetCallback(e)}
                  filtersDeleteCallback={props.filtersDeleteCallback}
                  formName={props.form}
                  dispatch={props.dispatch}
                />
              ) : (
                <FilterActions
                  filtersResetCallback={(e) => props.filtersResetCallback(e)}
                  formName={props.form}
                  dispatch={props.dispatch}
                />
              )
            }
          </div>
        </div>
      </div>
    </form>

  );
}

FilterHC = reduxForm({
  form: 'FilterHC',
  enableReinitialize: true
})(FilterHC);

const mapStateToProps = (state, ownProps) => {
  const { filterbox } = state.filterBox;
  return {
    initialValues: ((filterbox && filterbox.FilterHiringClients && typeof filterbox.FilterHiringClients === 'object') ? {
      ...filterbox.FilterHiringClients
    } : {
      archive: 0
    }),
    // register: state.register,
    local: state.localization,
    login: state.login,
    filter: state.filterBox.filterbox,
    formValues: getFormValues('FilterHC')(state),
  }
};

export default connect(mapStateToProps)(FilterHC);
