import Api from '../../../../../lib/api';
import * as types from './types';


export const setFinancialLogsVisible = (visible) => {
  return {
    type: types.SET_FINANCIAL_LOGS_VISIBLE,
    visible
  };
};

export const setFormLogsVisible = (visible) => {
  return {
    type: types.SET_FORM_LOGS_VISIBLE,
    visible
  };
};

export const setFetchingLogs = (isFetching) => {
  return {
    type: types.SET_FETCHING_LOGS,
    isFetching
  };
};

export const setFetchingLogsError = (error) => {
  return {
    type: types.SET_LOGS_ERROR,
    error
  };
};

export const setLogs = (logs) => {
  return {
    type: types.SET_LOGS,
    logs
  };
};

export const fetchLogs = (query) => {
  return (dispatch, getState) => {
    const { login, localization } = getState();
    let {
      error10005,
      error10006,
      error10007,
      error10011,
      error10019,
      errorDefault,
      errorConnection,
    } = localization.strings.scProfile.files.actions;
    const token = login.authToken;

    dispatch(setFetchingLogs(true));
    const urlParameters = `?${Object.keys(query).map(i => `${i}=${query[i]}`).join('&')}`;

    const urlQuery = 'log/subcontrators';

    return Api.get(`${urlQuery}${urlParameters}`, token)
      .then(response => {
        const { success, data } = response.data;
        let errorMsg = '';
        if (success) {
          dispatch(setLogs(data));
        }
        else {
          switch (data.errorCode) {
            case 10005:
              errorMsg = error10005;
              break;
            case 10006:
              errorMsg = error10006;
              break;
            case 10007:
              errorMsg = error10007;
              break;
            case 10011:
              errorMsg = error10011;
              break;
            case 10019:
              errorMsg = error10019;
              break;
            default:
              errorMsg = errorDefault;
              break;
          }
          dispatch(setFetchingLogsError(errorMsg));
        }
        dispatch(setFetchingLogs(false));
      })
      .catch(error => {
        dispatch(setFetchingLogs(false));
        dispatch(setFetchingLogsError(errorConnection));
      });
  };
}