import React, {useEffect, useState} from "react";
import "./index.scss";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import {Collapse} from "react-bootstrap";
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as processingActions from "../processing/actions";
import {Tooltip} from "@material-ui/core";
import Api from "../../../lib/api";
import moment from "moment";
import { Prompt } from "react-router-dom";


const DocumentProgressBar = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [documents, setDocuments] = useState([])
  const [pendingDocuments, setPendingDocuments] = useState(0)

  useEffect(() => {
    getDocuments()
    const interval = setInterval(() => {
      getDocuments()
    }, 5000);
    return () => {
      clearInterval(interval)
      props.processingActions.setAutoProcessingDocument({autoProcessingDocument: false})
    };
  }, [])

  const getDocuments = () => {
    const { authToken } = props.login;
    const params = {};
    if(props.projectId){
      params.projectId = props.projectId
    }
    if(props.insuredId){
      params.insuredId = props.insuredId
    }
    if(props.projectInsuredId){
      params.projectInsuredId = props.projectInsuredId
    }
    let urlParameters = `?${Object.keys(params).map(i => `${i}=${params[i]}`).join('&')}`;
    const urlQuery = `cf/idp/processing-documents${urlParameters}`;
    Api.get(urlQuery, authToken)
    .then(async response => {
      let { success, documents } = response.data;
      if(success) {
        const pendingDocs = await documents.filter(item => item.IsProcessedByIDP === false)
        setPendingDocuments(pendingDocs.length)
        setDocuments(documents)
        if(documents.length === 0){
          setPendingDocuments(0)
          props.processingActions.setAutoProcessingDocument({autoProcessingDocument: false})
        }
      }
    })
    .catch((err) => {

    });
  }

  const handleClose = () => {
    if(pendingDocuments > 0){
      if(window.confirm('Are you sure you want to close? Document progress bar will not be display.')){
        props.processingActions.setAutoProcessingDocument({autoProcessingDocument: false})
      }
    } else {
      props.processingActions.setAutoProcessingDocument({autoProcessingDocument: false})
    }
  }

  const renderProgress = (item) => {
    const diff = moment().diff(moment(item.CreatedAt), 'minutes')
    if(item.IsProcessedByIDP){
      return (
        <div className="process">
          <CheckCircleIcon size="1rem"/>
        </div>
      )
    } else if(item.CreatedAt !== item.UpdatedAt && !item.CertificateTypeId) {
      return (
        <div className="process">
          <Tooltip title="Only Acord 25/28 certificates can be process at this moment, Operations team will take a look.">
            <InfoIcon size="1rem"/>
          </Tooltip>
        </div>
      )
    } else if(diff >= 5 || item.CreatedAt !== item.UpdatedAt) {
      return (
        <div className="process">
          <Tooltip title="Something went wrong, Please try again latter.">
            <InfoIcon size="1rem"/>
          </Tooltip>
        </div>
      )
    } else {
      return (
        <div className="process" style={{padding: '3px'}}>
          <CircularProgress size="1.2rem"/>
        </div>
      )
    }
  }

  return (
    <div className="progress-bar-main">
      <Prompt
        when={pendingDocuments > 0}
        message={() =>
          'Are you sure you want to leave? Document progress bar will not be display.'
        }
      />
      <div className="header">
        <div className="title">
          Processing Documents
        </div>
        <div className="action">
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => setIsCollapsed(!isCollapsed)}>
            { isCollapsed ?
              (<ExpandLessIcon fontSize="medium"/>) : (<ExpandMoreIcon fontSize="medium"/>)
            }
          </IconButton>
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => handleClose()}>
            <CloseIcon fontSize="small"/>
          </IconButton>
        </div>
      </div>
      <div className="content">
        <Collapse in={!isCollapsed}>
          <div id="collapse-text">
            {documents && documents.map((item, index) => {
              return (
                <div key={index} className="row mx-0 item">
                  <div className="file-icon">
                    <DescriptionIcon size="small"/>
                  </div>
                  <div className="name">
                    <p>{item.FileName}</p>
                    <div>PI: {item.ProjectInsuredId}</div>
                  </div>
                  {renderProgress(item)}
                </div>
              )
            })}
          </div>
        </Collapse>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    processing: state.processing,
    login: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    processingActions: bindActionCreators(processingActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentProgressBar);

