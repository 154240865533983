import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from 'moment';
import Utils from '../../../lib/utils';

import FilterProjectInsureds from './filter';

import * as commonActions from '../../common/actions';
import * as actions from './actions/index';

import './copy-certificates.css';

class CopyCertificates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {
        pageNumber: 1
      },
      filterBox: {
        projectNumber: '',
        projectName: '',
        holderName: '',
        projectState: '',
        requiredCoverages: '',
      },
      tableOrderActive: 'insuredName',
      order: {
        insuredName: 'asc',
        projectName: 'desc',
        holderName: 'desc',
        requiredCoverages: 'desc',
      },
      currentInsured: null,
      showFilterBox: false,
      checkboxes: [],
      copyStatus: false,
      // showAllRelatedProjects: false,
    };
  }

  componentDidMount() {
    this.fetchProjectInsureds();
  }

  fetchProjectInsureds() {
    let query = Utils.getFetchQuery('insuredName', 1, 'ASC');
    query = this.addId(query);
    query.archived = 0;
    query.copyCertificatesPage = true;
    this.props.actions.fetchProjectInsureds(query);
    this.props.commonActions.fetchComplianceStatus();
  }

  addId = (query) => {
    const { holderId, insuredId, projectInsuredId } = this.props;
    // const { showAllRelatedProjects } = this.state;
    query.copyCertificatesPage = true;

    if (insuredId) {
      query.insuredId = insuredId;
    }
    if (holderId) {
      query.holderId = holderId;
    }
    if (projectInsuredId) {
      query.copyOfProjectInsuredId = projectInsuredId;
    }
    return query;
  }  
  
  clickOnColumnHeader = (e, field) => {
    if (field === 'edit' || field === 'view') return;

    // get base query
    const pageNumber = this.state.filter.pageNumber;
    const orderDirection = this.state.order[field] === 'asc' ? 'DESC' : 'ASC';
    let query = Utils.getFetchQuery(field, pageNumber, orderDirection);
    query = this.addId(query);

    // add search filters
    query = Utils.addSearchFiltersToQuery(query, this.state.filterBox);

    // fetch using query
    this.props.actions.fetchProjectInsureds(query);

    // save new active tab and order
    let newState = {
      tableOrderActive: field,
      order: {
        projectNumber: field === 'projectNumber' ? 'asc' : 'desc',
        projectName: field === 'projectName' ? 'asc' : 'desc',
        holderName: field === 'holderName' ? 'asc' : 'desc',
        requiredCoverages: field === 'requiredCoverages' ? 'asc' : 'desc',
      }
    };
    newState.order[field] = this.state.order[field] === 'asc' ? 'desc' : 'asc';
    this.setState(newState);
  }

  setPageFilter = (e, pageNumber, force) => {
    if (force || this.state.filter.pageNumber !== pageNumber) {

      // get base query
      const field = this.state.tableOrderActive;
      const orderDirection = this.state.order[field] === 'asc' ? 'ASC' : 'DESC';
      let query = Utils.getFetchQuery(field, pageNumber, orderDirection);
      query = this.addId(query);

      // add search filters
      query = Utils.addSearchFiltersToQuery(query, this.state.filterBox);

      // fetch using query
      this.props.actions.fetchProjectInsureds(query);

      // save page number
      this.setState({
        filter: {
          pageNumber,
        }
      });
    }
  }

  submitFilterForm = (values) => {    
    // get base query
    const field = this.state.tableOrderActive;
    const pageNumber = 1;
    const orderDirection = this.state.order[field] === 'asc' ? 'ASC' : 'DESC'
    let query = Utils.getFetchQuery(field, pageNumber, orderDirection);

    // add search filters
    const filterBox = {
      projectNumber: values.projectNumber || "",
      projectName: values.projectName || "",
      holderName: values.holderName || "",
      projectState: values.state || "",
      requiredCoverages: values.requiredCoverages || "",
    };
    query = Utils.addSearchFiltersToQuery(query, filterBox);
    query = this.addId(query);

    // fetch using query
    this.props.actions.fetchProjectInsureds(query);

    // save searchterm and pagenumber
    this.setState({
      filterBox,
      filter: {
        pageNumber: 1
      }
    });
  }

  copyToSelected = () => {
    const { copyCertificateData } = this.props;
    const { checkboxes } = this.state;
    if (checkboxes.length > 0) {
      Swal({
        title: `Copy Certificates`,
        text: `Are you sure you want to copy the certificate to the selected project-insureds?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2E5965',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
      }).then((result) => {
        if (result.value) {
          this.props.commonActions.setLoading(true);
          const payload = {
            selectedProjectInsureds: this.state.checkboxes,
            copyCertificateData: copyCertificateData,
            copyStatus: this.state.copyStatus
          }
          this.props.actions.copyCertificate(payload, (error, data) => {
            this.props.commonActions.setLoading(false);
            if (!error) {              
              const textMessage = (data.created.length > 0) 
                ? `${data.created.length} certificates were copied successfully.`
                : `No certificates were copied. Please check your data.`

              Swal({
                type: 'success',
                title: 'Copy Certificate',
                text: textMessage,
                onClose: () => this.closeCertificateModal()
              });
            } else {
              const textMessage = "There was an error coping the certificates, please retry";
              Swal({
                type: 'error',
                title: 'Copy Certificate',
                text: textMessage,
                onClose: () => this.closeCertificateModal()
              });
            }
            this.setState({ checkboxes: [] });
          });
        }
      });
    }
  }

  copyToAll = () => {
    const { copyCertificateData, projectInsureds } = this.props;
    const selectedCheckboxes = projectInsureds.list.map(item => ({
      projectInsuredId: item.ProjectInsuredID,
      holderId: item.HolderID,
      projectId: item.ProjectID
    }));
    this.setState({ checkboxes: selectedCheckboxes });    
    
    Swal({
      title: `Copy Certificates`,
      text: `Are you sure you want to copy the certificate to all project-insureds?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2E5965',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        this.props.commonActions.setLoading(true);
        const payload = {
          selectedProjectInsureds: this.state.checkboxes,
          copyCertificateData: copyCertificateData,
          copyStatus: this.state.copyStatus
        }
        this.props.actions.copyCertificate(payload, (error, data) => {
          this.props.commonActions.setLoading(false);
          if (!error) {
            const textMessage = (data.created.length > 0) 
              ? `${data.created.length} certificates were copied successfully.`
              : `No certificates were copied. Please check your data.`

            Swal({
              type: 'success',
              title: 'Copy Certificate',
              text: textMessage,
              onClose: () => this.closeCertificateModal()
            });
          } else {
            const textMessage = "There was an error coping the certificates, please retry";
            Swal({
              type: 'error',
              title: 'Copy Certificate',
              text: textMessage,
              onClose: () => this.closeCertificateModal()
            });
          }
          this.setState({ checkboxes: [] });  
        });
      } else {
        this.setState({ checkboxes: [] });
      }
    });
  }

  handleChange = (e, projectInsuredId, holderId, projectId) => {
    const { checked } = e.target;
    if (!checked) {
      let filteredArray = this.state.checkboxes.filter(item => item.projectInsuredId !== projectInsuredId)
      this.setState({ checkboxes: filteredArray });
    } else {
      const newObj = { projectInsuredId, holderId, projectId };
      this.setState({
        checkboxes: this.state.checkboxes.concat([newObj]),
      });
    }
  };

  closeCertificateModal = () => {
    window.close();
    //if window.close fails redirect.
    this.props.redirectTo();
  }
  
  render() {
    const {
      title
    } = this.props.local.strings.copyCertificate;
           
    const {
      copyColumn,
      complianceStatusColumn,
      holderNameColumn,
      projectNumberColumn,
      projectNameColumn,
      addressColumn,
      cityColumn,
      stateColumn,
      zipColumn,
      requiredCoveragesColumn,
      viewCertColumn,
      expirationDateColumn,
      viewCert,
      copyToSelected,
      copyToAll,
      skipStep,
      copyStatus,
    } = this.props.local.strings.copyCertificate.insuredList;

    return (
      <div className="add-item-view processing-preview-screen-container">
        <div className="add-item-header modal-title">
          <h1>{title}</h1>
        </div>

        <section className="list-view-filters">
          <FilterProjectInsureds
            onSubmit={this.submitFilterForm}
          />
        </section>

        <div className="copy-buttons-container">
          {/* <div>
            <button
              className="bn bn-small show-all-btn"
              onClick={() => this.setState({ showAllRelatedProjects: !this.state.showAllRelatedProjects }, () => {
                setTimeout(() => {
                  this.fetchProjectInsureds()
                }, 500)}                
              )}
            >
            { this.state.showAllRelatedProjects ? `HIDE` : `SHOW` } ALL RELATED PROJECTS
            </button>
          </div>                */}
          <div className="copy-status-container">
            <input 
              type="checkbox" 
              className="copy-status-checkbox" 
              id={`copyStatus`}
              onChange={(e) => this.setState({ copyStatus: e.target.checked })} 
            />
            <label className="copy-status-label" htmlFor={`copyStatus`}>{copyStatus}</label>
          </div>
          <div>
            <button
              onClick={() => this.copyToSelected()}
              className={`bn bn-small copy-to-selected-btn`}
              disabled={this.state.checkboxes.length === 0}
            >{copyToSelected}
            </button>
          </div>
          <div>
            <button
              onClick={() => this.copyToAll()}
              className={`bn bn-small copy-to-all-btn`}
            >{copyToAll}
            </button>
          </div>
          <div>
            <button
              onClick={this.closeCertificateModal}
              className={`bn bn-small skip-step-btn`}
            >{skipStep}
            </button>
          </div>
        </div>

        <div className="project-insured-table">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th colSpan="2">{copyColumn}</th>
                <th>{complianceStatusColumn}</th>
                <th>{holderNameColumn}</th>
                <th>{projectNumberColumn}</th>
                <th>{projectNameColumn}</th>
                <th>{addressColumn}</th>
                <th>{cityColumn}</th>
                <th>{stateColumn}</th>
                <th>{zipColumn}</th>
                <th>{requiredCoveragesColumn}</th>
                <th style={{ whiteSpace: 'nowrap' }}>{viewCertColumn}</th>
                <th>{expirationDateColumn}</th>
              </tr>
            </thead>
            <tbody>
            {this.props.projectInsureds.list && this.props.projectInsureds.list.length > 0 ? (
              this.props.projectInsureds.list.map((projectInsured, idx) => {
                const {
                  ComplianceStatusName,
                  ProjectID,
                  ProjectName,
                  CFProjectName2,
                  ProjectNumber,
                  HolderID,
                  HolderName,
                  ProjectAddress,
                  City,
                  ProjectState,
                  ZipCode,
                  ProjectInsuredID,
                  HasCertificate,
                  HasProcessedDocuments,
                  RequiredCoverages,
                  EarliestExpirationDate,
                  certificateHasBeenCopied,
                } = projectInsured;

                const projectName = (CFProjectName2) ? ProjectName.concat(' - ', CFProjectName2) : ProjectName;
                
                return (
                  <tr key={ProjectInsuredID}>
                    <td>
                      <div className="custom-control custom-checkbox">
                        <input 
                          type="checkbox" 
                          className="custom-control-input" 
                          id={`customCheck_${idx}`}
                          onChange={(e) => this.handleChange(e, ProjectInsuredID, HolderID, ProjectID)} 
                        />
                        <label className="custom-control-label" htmlFor={`customCheck_${idx}`}></label>
                      </div>
                    </td>
                    <td>  
                      {(certificateHasBeenCopied) && (<div className="copied-label" title="Already copied!" alt="Already copied!">C</div>)}
                    </td>
                    <td>{ComplianceStatusName}</td>
                    <td>{HolderName}</td>
                    <td>{ProjectNumber}</td>
                    <td>{projectName}</td>
                    <td>{ProjectAddress}</td>
                    <td>{City}</td>
                    <td>{ProjectState}</td>
                    <td>{ZipCode}</td>
                    <td>{RequiredCoverages}</td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                      {(HasCertificate) ? 
                        (HasProcessedDocuments) ? // Processing Complete / Pending Review status
                          (
                          <Link
                            to={`/certfocus/certificates/${ProjectInsuredID}`}
                            className="cell-table-link"
                          >
                            {viewCert}
                          </Link>
                        ) : 'Pending Processing'  
                      : 'No Certs Provided'}
                    </td>
                    <td>
                      {(EarliestExpirationDate) && moment.utc(EarliestExpirationDate).format('MM/DD/YYYY')}
                    </td>
                  </tr>  
                  );
                })
            ):(
              <tr>
                <td colSpan={12}>
                  {(this.props.projectInsureds.fetchingProjectInsureds) ? (
                    <div className="spinner-wrapper mt-0"><div className="spinner" /></div>
                  ):(
                    <div>There are no Projects-Insureds to be copied</div>
                  )}
                </td>  
              </tr>     
            )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
};



const mapStateToProps = (state) => {
  return {
    projectInsureds: state.projectInsureds,
    local: state.localization,
    login: state.login
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch)    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CopyCertificates);