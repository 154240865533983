import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';

import vertikalTheme from '../../../lib/theme';
import * as actions from './actions';
import Utils from '../../../lib/utils';
import AMBestImportHistoryToolbar from '../ambestImportHistory/AMBestImportHistoryToolbar';

const DataEntryRecords = props => {
  const {locale, authToken, handleClose} = props;

  const [dataEntryRecords, setDataEntryRecords] = useState([]);

  const _fetchData = async () => {
    try {
      const records = await actions.fetchDataEntryRecords(authToken);
      setDataEntryRecords(records);
    } catch (e) {
      console.error('Could not fetch data entry records');
    }
  }

  useEffect( () => {
    (async function loadData () {
      await _fetchData();
    })();
  }, [])

  const columns = [
    {name: 'date', label: locale.columns.date},
    {name: 'userEmail', label: locale.columns.mail},
    {name: 'total', label: locale.columns.total, options: {filter: false}},
    {name: 'queue', label: locale.columns.queue},
  ];

  let options = Utils.MUITableDefaultConfig(columns);
  options ={
    ...options,
    download: true,
    downloadOptions: {
      filename: locale.exportFileName,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      }
    },
    customToolbar: () => <AMBestImportHistoryToolbar handleCloseClick={handleClose} />
  }

  return (
    <div>
      <MuiThemeProvider theme={vertikalTheme.getMuiTheme()}>
        <MUIDataTable
          title={locale.modalTitle}
          data={dataEntryRecords}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </div>
  );
};

DataEntryRecords.propTypes = {
  locale: PropTypes.object.isRequired,
  authToken: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default DataEntryRecords;