import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import Toggle from "react-toggle";
import Swal from "sweetalert2";
import { Button, createTheme, MuiThemeProvider } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import debounceAsync from "debounce-async";
import moment from "moment";

import "react-toggle/style.css";

import AddProjectModal from "../modals/addProjectModal";
import Utils from "../../../lib/utils";
import CountryDropdown from "./filter/CountryDropdown";
import FilterProjects from "./filter";

import * as actions from "./actions";
import * as commonActions from "../../common/actions";
import * as projectDetailsActions from "../project-view/actions";
import * as reqSetsActions from "../requirement-sets/actions";
import * as holderProfileActions from "./../holders-profile/actions";

import RolAccess from "./../../common/rolAccess";

import "./Projects.css";
import * as filterActions from '../filterbox/actions'


const initialStateFilterBox = {
  projectName: "",
  holderName: "",
  myList: "",
  searchTerm: "",
  archived: 0,
  emptyReqSets: false,
  stateId: null,
  stateLabel: "",
  CFCountryId: null,
  CFCountryLabel: "",
};


class Projects extends Component {
  constructor(props) {
    super(props);
    const isProjectOnly = props.location.pathname == '/certfocus/projects';

    this.state = {
      showFilterBox: true,
      isProjectOnly,
      filter: {
        pageNumber: 0,
        pageSize: 10,
      },
      filterBox: {
        projectName: "",
        holderName: "",
        myList: "",
        searchTerm: "",
        archived: 0,
        emptyReqSets: false,
        stateId: null,
        stateLabel: "",
        CFCountryId: null,
        CFCountryLabel: "",
      },
      order: { name: "name", direction: "asc" },
      currentProject: null,
      filteredByEmptyReqSets: false,
      rowScrollIndex: 0,
      data: [],
    };
  }

  componentDidMount() {
    const { actions, holderId, insuredId, commonActions, fromHolderTab } = this.props;

    actions.setAddProjectData();
    commonActions.fetchUSStates();
    if (!this.state.isProjectOnly) {
      actions.fetchProjects({
        orderBy: "name",
        orderDirection: "ASC",
        ...(!holderId && !insuredId && { archived: 0 }),
        ...(holderId && { holderId }),
        ...(insuredId && { insuredId }),
        ...(fromHolderTab && { archived: 0 }),
      });
    }

    this.props.commonActions.setBreadcrumbItems([]);
  }

  componentWillUpdate(nextProps) {
    if (nextProps.projects.list !== this.props.projects.list) {
      const accountProjectsNonArchive =
        nextProps.projects.list.length > 0 ? nextProps.projects.list[0].accountProjectsNonArchived : 0;
      this.props.holderProfileActions.setAccountProjectsNonArchived(accountProjectsNonArchive);
      this.setState({ data: nextProps.projects.list });
    }
  }

  addId(query) {
    const { holderId, insuredId } = this.props;

    if (holderId) {
      query.holderId = holderId;
    } else if (insuredId) {
      query.insuredId = insuredId;
    } else {
      query.archived = 0;
    }
  }

  handleSortOrderChange = (changedColumn, direction) => {
    // get base query
    const { pageNumber, pageSize } = this.state.filter;
    const orderDirection = direction === "asc" ? "ASC" : "DESC";
    let query = Utils.getFetchQuery(changedColumn, pageNumber + 1, orderDirection, pageSize);
    this.addId(query);

    // add search filters
    query = Utils.addSearchFiltersToQuery(query, this.state.filterBox);

    // fetch using query
    this.props.actions.fetchProjects(query);

    this.setState({
      filter: {
        pageNumber,
        pageSize,
      },
      order: { name: changedColumn, order: direction },
    });
  };

  setPageFilter = (pageNumber, force, pageSize) => {
    if (force || this.state.filter.pageNumber !== pageNumber) {
      const { name, direction } = this.state.order;
      const orderDirection = direction === "asc" ? "ASC" : "DESC";
      let query = Utils.getFetchQuery(name, pageNumber + 1, orderDirection, pageSize);
      this.addId(query);

      // add search filters
      query = Utils.addSearchFiltersToQuery(query, this.state.filterBox);

      if (this.props.fromHolderTab && !query.archived) {
        query.archived = 0;
      }

      // filtering by empty ReqSets
      query.emptyReqSets = this.state.emptyReqSets ? 1 : 0;

      // fetch using query
      this.props.actions.fetchProjects(query);

      // save page number
      this.setState({
        filter: {
          pageNumber,
          pageSize,
        },
      });
    }
  };

  handlePageChange = (pageNumber) => this.setPageFilter(pageNumber, false, this.state.filter.pageSize);

  handleRowsPerPageChange = (numberOfRows) => this.setPageFilter(this.state.filter.pageNumber, true, numberOfRows);
  
  clearOrRefreshFilterBoxState = () => {
    this.setState({
      filterBox: {...initialStateFilterBox},
    });
  };

  submitFilterForm = (values) => {
    // get base query
    const { direction, name } = this.state.order;
    const pageNumber = 0;
    const pageSize = 10;
    const orderDirection = direction === "asc" ? "ASC" : "DESC";
    let query = Utils.getFetchQuery(name, pageNumber + 1, orderDirection, pageSize);
    this.addId(query);

    // add search filters
    const filterBox = {
      projectName: values.name || "",
      stateId: (values.stateId && values.stateId.value) || null,
      stateLabel: (values.stateId && values.stateId.label) || "",
      CFCountryId: (values.countryId && values.countryId.value) || null,
      CFCountryLabel: (values.countryId && values.countryId.label) || "",
      holderName: values.holderName || "",
      myList: values.mylist || "",
      searchTerm: values.searchTerm || "",
      archived: values.archived,
    };
    if (this.state.isProjectOnly) {
      let saveFilter = { tab: 'project' }
      saveFilter.module = 'GlobalProjects';
      saveFilter.objectId = null;
      saveFilter.filterBox = filterBox;
      this.props.filterActions.saveDefaultFilters('FilterProjects', saveFilter);
    }

    query = Utils.addSearchFiltersToQuery(query, filterBox);

    // fetch using query
    this.props.actions.fetchProjects(query);

    // save searchterm and pagenumber
    this.setState({
      filterBox,
      filter: {
        pageNumber,
        pageSize,
      },
    });
  };

  handleSearch = (searchTerm) => {
    const debounced = debounceAsync(async (e) => {
      this.submitFilterForm({ searchTerm });
    }, 200);
    debounced();
  };

  editProject = (project) => {
    const { setLoading } = this.props.commonActions;
    setLoading(true);

    this.props.reqSetsActions.fetchRequirementSetsPossibleValues({ holderId: project.holderId });
    this.props.actions.fetchHolderCustomFields(project.holderId);
    this.props.projectDetailsActions.fetchProject(project.id, (data) => {
      setLoading(false);

      this.setState(
        {
          currentProject: data,
        },
        this.openModal
      );
    });
  };

  archiveProject = (e, projectId, newArchivedStatus) => {
    const archivedTitle = newArchivedStatus === 1 ? "Archive" : "Unarchive";
    const archivedText = newArchivedStatus === 1 ? "archive" : "unarchive";

    Swal({
      title: `${archivedTitle} Project`,
      text: `Are you sure you want to ${archivedText} project # ${projectId}?`,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2E5965",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.value) {
        this.props.actions.archiveProject({ projectId: projectId, archived: newArchivedStatus }, () => {
          this.props.commonActions.setLoading(false);
          this.setPageFilter(0, true, 10);
        });
      }
    });
  };

  openModal = () => {
    this.props.actions.setShowModal(true);
  };

  closeModal = () => {
    this.props.actions.setShowModal(false);

    this.setState({
      currentProject: null,
    });
  };

  closeModalAndRefresh = () => {
    this.props.actions.setShowModal(false);
    this.setPageFilter(0, true, 10);

    this.props.actions.setCustomFieldsList([]);

    this.setState({
      currentProject: null,
    });
  };

  onToggleChange = (e, projectId) => {
    const { profile } = this.props.login;

    this.props.actions.sendProjectFavorite(e.target.checked, { projectId, userId: profile.Id });
  };

  renderButtonAddProject() {
    let component = (
      <div>
        <a onClick={this.openModal} className="nav-btn nav-bn icon-add">
          {this.props.local.strings.certFocusProjects.projectsList.addBtn}
        </a>
      </div>
    );
    return component;
  }

  renderButtonEditProject(p) {
    let component = (
      <a onClick={() => this.editProject(p)} className="cell-table-link icon-edit">
        {this.props.local.strings.certFocusProjects.projectsList.editProject}
      </a>
    );
    return component;
  }

  renderButtonViewProject(projectId) {
    let component = (
      <Link to={`/certfocus/projects/${projectId}`} className="cell-table-link icon-quick_view">
        {this.props.local.strings.certFocusProjects.projectsList.viewProject}
      </Link>
    );

    return component;
  }

  renderButtonArchiveProject(archived, projectId) {
    const { archivedProject, unarchivedProject } = this.props.local.strings.certFocusProjects.projectsList;
    let component = !archived ? (
      <a onClick={(e) => this.archiveProject(e, projectId, 1)} className="cell-table-link">
        {unarchivedProject}
      </a>
    ) : (
      <a onClick={(e) => this.archiveProject(e, projectId, 0)} className="cell-table-link" style={{ color: "#F00" }}>
        {archivedProject}
      </a>
    );

    return component;
  }

  renderDefaultArchiveProject(archived, projectId) {
    const { unarchivedProject, archivedProject } = this.props.local.strings.certFocusProjects.projectsList;
    const component = (
      <span className={`status-cell ${!archived ? "inactive" : "active"}`}>
        {!archived ? unarchivedProject : archivedProject}
      </span>
    );
    return component;
  }

  filteredByEmptyReqSets = (e, emptyReqSets) => {
    e.preventDefault();
    this.setState({ emptyReqSets: emptyReqSets, filteredByEmptyReqSets: emptyReqSets }, () => {
      this.setPageFilter(0, true, 10);
    });
  };

  render() {
    const getMuiTheme = () =>
      createTheme({
        overrides: {
          MuiTable: {
            root: {
              position: "relative",
              zIndex: "0",
            },
          },
          MuiGrid: {
            "spacing-xs-4": {
              "& > $item": {
                padding: 5,
              },
            },
            root: {
              width: "100% !important",
              margin: "0 !important",
            },
          },
          MuiTableCell: {
            head: {
              color: "#085DAD",
              fontSize: 14,
              textTransform: "uppercase",
              padding: 10,
              fontWeight: 700,
            },
          },
          MUIDataTableFilter: {
            root: {
              padding: "24px 8px 36px 8px",
            },
            gridListTile: {
              marginTop: 0,
              paddingTop: 4,
              paddingBottom: 4,
            },
          },
          MuiTypography: {
            h6: {
              color: "#2E5965",
              fontSize: 18,
              textTransform: "uppercase",
              fontWeight: 700,
            },
          },
          MUIDataTableBodyCell: {
            root: {
              "& > a": {
                cursor: "pointer",
                display: "block",
                color: "#8CC739",
                position: "relative",
                "&:hover": {
                  textDecoration: "underline",
                },
                "& > svg": {
                  fontSize: 16,
                  marginRight: 5,
                },
                "&:before": {
                  color: "#8CC739",
                  margin: "5px",
                },
              },
            },
          },
          MUIDataTableHeadCell: {
            data: {
              color: "#085dad !important",
              fontSize: "14px !important",
              textTransform: "uppercase !important",
              fontWeight: "bold",
            },
          },
        },
      });

    const { fromHolderTab, fromInsuredTab, holderArchived } = this.props;
    const {
      projectNumberColumn,
      projectColumn,
      holderColumn,
      CompliantInsuredsColumn,
      NonCompliantInsuredsColumn,
      EscalatedInsuredsColumn,
      stateColumn,
      statusColumn,
      myListColumn,
      archivedProject,
      filterBtn,
      filter,
    } = this.props.local.strings.certFocusProjects.projectsList;
    const { onMyListOption, notOnMylistOption } = filter.myListOptionLabels;

    let { totalAmountOfProjects, fetchingProjects, showModal } = this.props.projects;
    let { profile } = this.props.login;

    if (!profile.Id || fetchingProjects) {
      this.props.commonActions.setLoading(true);
    } else {
      this.props.commonActions.setLoading(false);
    }

    // check for missing reqSets
    let missingReqSet = false;
    if (this.props.projects && this.state.data) {
      missingReqSet = this.state.data.some((e) => e.requirementSetId === null);
    }

    const columns = [
      {
        name: "number",
        label: projectNumberColumn,
        options: {
          filter: false,
        },
      },
      {
        name: "name",
        label: projectColumn,
        options: {
          customBodyRender: (value, tableMeta) => {
            const { rowIndex } = tableMeta;
            const { CFProjectName2, name } = this.state.data[rowIndex];

            return CFProjectName2 ? name.concat("-", CFProjectName2) : name;
          },
        },
      },
      {
        name: "holderName",
        label: holderColumn,
      },
      {
        name: "CompliantInsureds",
        label: CompliantInsuredsColumn,
        options: {
          filter: false,
        },
      },
      {
        name: "NonCompliantInsureds",
        label: NonCompliantInsuredsColumn,
        options: {
          filter: false,
        },
      },
      {
        name: "EscalatedInsureds",
        label: EscalatedInsuredsColumn,
        options: {
          filter: false,
        },
      },
      {
        name: "stateName",
        label: stateColumn,
        options: {
          filterType: "custom",
          filterOptions: {
            fullWidth: true,
            display: (filterList, onChange, index, column) => {
              const handleSubmit = (stateId, stateLabel, countryId, countryLabel) => {
                const filterObj = {
                  stateId: { value: stateId, label: stateLabel },
                  countryId: { value: countryId, label: countryLabel },
                };
                filterList[index][0] = filterObj;
                onChange(filterList[index], index, column);
              };

              return (
                <>
                  <CountryDropdown
                    handleSubmit={handleSubmit}
                    stateId={this.state.filterBox.stateId}
                    stateLabel={this.state.filterBox.stateLabel}
                    countryId={this.state.filterBox.CFCountryId}
                    countryLabel={this.state.filterBox.CFCountryLabel}
                  />
                </>
              );
            },
          },
        },
      },
      {
        name: "mylist",
        label: myListColumn,
        options: {
          download: false,
          filterType: "dropdown",
          filterOptions: {
            names: [notOnMylistOption, onMyListOption],
          },
          customHeadLabelRender: () => <div>{myListColumn}</div>,
          customBodyRender: (value, tableMeta) => {
            const { rowIndex } = tableMeta;
            const { id } = this.state.data[rowIndex];

            return (
              <Toggle
                onChange={(e) => this.onToggleChange(e, id)}
                checked={value ? true : false}
                disabled={this.props.projects.favoriteFetching === id}
              />
            );
          },
        },
      },
      {
        name: "viewProject",
        options: {
          download: false,
          sort: false,
          filter: false,
          customHeadLabelRender: () => (
            <RolAccess
              masterTab="projects"
              sectionTab="view_project"
              component={() => <div style={{ width: 120 }}></div>}
            ></RolAccess>
          ),
          customBodyRender: (value, tableMeta) => {
            const { rowIndex } = tableMeta;
            const { id } = this.state.data[rowIndex];

            return (
              <RolAccess
                masterTab="projects"
                sectionTab="view_project"
                component={() => this.renderButtonViewProject(id)}
              ></RolAccess>
            );
          },
        },
      },
      {
        name: "editProject",
        options: {
          download: false,
          sort: false,
          filter: false,
          customHeadLabelRender: () => (
            <RolAccess
              masterTab="projects"
              sectionTab="edit_project"
              component={() => <div style={{ width: 120 }}></div>}
            ></RolAccess>
          ),
          customBodyRender: (value, tableMeta) => {
            const { rowIndex } = tableMeta;
            const project = this.state.data[rowIndex];
            const { archived } = project;

            return (
              !archived && (
                <RolAccess
                  masterTab="projects"
                  sectionTab="edit_project"
                  component={() => this.renderButtonEditProject(project)}
                ></RolAccess>
              )
            );
          },
        },
      },
      {
        name: "archived",
        label: archivedProject,
        options: {
          sort: false,
          download: false,
          filterType: "dropdown",
          filterOptions: {
            names: ["false", "true"],
          },
          defaultValue: "true",
          customHeadLabelRender: () => (
            <RolAccess
              masterTab="projects"
              sectionTab="archive_project"
              component={() => <div style={{ width: 120 }}></div>}
            ></RolAccess>
          ),
          customBodyRender: (value, tableMeta) => {
            const { rowIndex } = tableMeta;
            const { id, archived } = this.state.data[rowIndex];

            return (
              <RolAccess
                masterTab="projects"
                sectionTab="archive_project"
                component={() => this.renderButtonArchiveProject(archived, id)}
                default={(e) => this.renderDefaultArchiveProject(archived, id)}
              ></RolAccess>
            );
          },
        },
      },
    ];

    const { pageNumber, pageSize, order } = this.state.filter;
    const parseFilterObj = (filterList = []) => {
      let filterObj = {};

      columns.forEach((col, key) => {
        if (filterList[key].length > 0) {
          const value = filterList[key][0];

          switch (col.name) {
            case "name":
              filterObj.name = value;
              break;
            case "holderName":
              filterObj.holderName = value;
              break;
            case "mylist":
              filterObj.mylist = value === onMyListOption ? 1 : 0;
              break;
            case "archived":
              filterObj.archived = value === "true" ? 1 : 0;
              break;
            case "stateName":
              filterObj.stateId = value.stateId;
              filterObj.countryId = value.countryId;
              break;
            default:
              break;
          }
        }
      });
      return filterObj;
    };
    var typingTimer;
    var doneTypingInterval = 1000;
    let options = Utils.MUITableDefaultConfig(columns);
    options = {
      ...options,
      download: true,
      downloadOptions: {
        filename: `Vertikal_Projects_${moment().utc().format("YYYY-MM-DD_hh-mm-A")}`,
      },
      serverSide: true,
      count: totalAmountOfProjects,
      rowsPerPage: pageSize,
      rowsPerPageOptions: [10, 25, 50, 100, 500],
      page: pageNumber,
      sortOrder: order,
      confirmFilters: true,
      searchPlaceholder: "Keywords",
      filter: false,
      search: false,
      viewColumns:false,
      onChangePage: this.handlePageChange,
      onChangeRowsPerPage: this.handleRowsPerPageChange,
      onColumnSortChange: this.handleSortOrderChange,
      searchProps: {
        onBlur: (e) => {},
        onKeyUp: (e) => {
          clearTimeout(typingTimer);
          let value = e.target.value;
          typingTimer = setTimeout(() => {
            this.handleSearch(value);
          }, doneTypingInterval);
        },
        onKeyDown: (e) => {
          clearTimeout(typingTimer);
        },
      },
      onSearchChange: (searchQuery, currentRow, columns) => {
        if (!searchQuery) {
          clearTimeout(typingTimer);
          this.handleSearch(searchQuery);
        }
        return true;
      },
      setFilterChipProps: (colIndex, colName, filterValue) => {
        let chipLabel = columns[colIndex]["label"].toUpperCase();
        let chipValue = filterValue;
        if (filterValue.countryId) {
          const countryLabel = filterValue.countryId.label;
          chipValue = countryLabel;
          chipLabel = `LOCATION`;
        }
        if (filterValue.stateId) {
          console.log("filterValue", filterValue);
          const stateLabel = filterValue.stateId.label;
          chipValue = `${stateLabel}, ${chipValue}`;
        }
        return { label: `${chipLabel}: ${chipValue}` };
      },
      onFilterChange: (changedColumn, filterList, type) => {
        let filterObj = parseFilterObj(filterList);
        if (type === "chip") {
          this.submitFilterForm(filterObj);
        }
      },
      onDownload: (buildHead, buildBody, columns, rows) => {
        return "\uFEFF" + buildHead(columns) + buildBody(rows);
      },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{ marginTop: "40px" }}>
            <Button variant="contained" onClick={() => this.submitFilterForm(parseFilterObj(applyNewFilters()))}>
              Apply Filters
            </Button>
            <Button
              variant="contained"
              style={{ marginLeft: "20px" }}
              onClick={() => {
                var newFilterList = applyNewFilters();
                newFilterList.map((col) => {
                  col.length = 0;
                  return col;
                });
                const filterBox = {};
                this.setState({
                  filterBox,
                });
                this.submitFilterForm(parseFilterObj(newFilterList));
              }}
            >
              Reset
            </Button>
          </div>
        );
      },
    };

    const { projects: projectsTableName } = this.props.local.strings.holders.holdersProfile.tabs;

    return (
      <div className="list-view admin-view-body projects-list">
        <Modal show={showModal} onHide={this.closeModal} className="add-item-modal add-hc">
          <Modal.Body className="add-item-modal-body mt-0">
            <AddProjectModal
              project={this.state.currentProject}
              onHide={this.closeModal}
              close={this.closeModalAndRefresh}
              fromHolderTab={fromHolderTab}
              accountManagers={
                this.props.accountManagers || (this.state.currentProject && this.state.currentProject.accountManagers)
              }
            />
          </Modal.Body>
        </Modal>

        <div className="projects-list-header">
          <div>
            <a
              onClick={() => this.setState({ showFilterBox: !this.state.showFilterBox })}
              className="nav-btn icon-login-door"
            >
              {filterBtn}
            </a>
          </div>

          {!this.state.filteredByEmptyReqSets && missingReqSet && (
            <div>
              <a onClick={(e) => this.filteredByEmptyReqSets(e, true)} className="nav-btn btn-danger">
                Missing Req Sets
              </a>
            </div>
          )}

          {this.state.filteredByEmptyReqSets && (
            <div>
              <a onClick={(e) => this.filteredByEmptyReqSets(e, false)} className="nav-btn btn-success">
                View All Projects
              </a>
            </div>
          )}

          {!fromInsuredTab && !holderArchived && (
            <RolAccess
              masterTab="projects"
              sectionTab="add_project"
              component={() => this.renderButtonAddProject()}
            ></RolAccess>
          )}
        </div>

        {this.state.showFilterBox && (
          <section className="list-view-filters">
            <FilterProjects
              onSubmit={this.submitFilterForm}
              fromHolderTab={fromHolderTab}
              holderId={this.props.holderId}
              insuredId={this.props.insuredId}
              isProjectOnly={this.state.isProjectOnly} 
              refreshLocalState={this.clearOrRefreshFilterBoxState}
            />
          </section>
        )}

        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable title={projectsTableName} columns={columns} data={this.state.data} options={options} />
        </MuiThemeProvider>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.holdersProjects,
    local: state.localization,
    login: state.login,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    projectDetailsActions: bindActionCreators(projectDetailsActions, dispatch),
    reqSetsActions: bindActionCreators(reqSetsActions, dispatch),
    holderProfileActions: bindActionCreators(holderProfileActions, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Projects));
