import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as financialActions from '../../actions';
import * as listActions from '../../../../../formList/actions';
import Spinner from '../../../../../common/spinner';
import FinancialInfoRenewalModalForm from './financialInfoRenewalModalForm/financialInfoRenewalModalForm';

import './financialInfoRenewalModal.css';

class FinancialInfoRenewalModal extends Component {
  constructor(props){
    super(props);
    const fetchFormsParams = { hiringClientId: props.hcId, orderBy: 'name', orderDirection: 'ASC', justFormData: true, archived: 0};
    props.actions.fetchForms(fetchFormsParams);
  }

  onSubmit = (data) => {
    this.props.type == 'renewal' ? this.props.onForce('1', data.formId) : this.props.onForce(this.props.hcId, data.formId)
  }

  render() {
    const { close, forms } = this.props;
    if (forms.list && forms.list.length == 0) {
      return <Spinner />
    }
    const formIdOptions = forms.list.map(f => {
      return {
        value: f.id,
        label: f.name
      }
    });
    formIdOptions.splice(0, 0, { value: 0, label: '-- Select FormID --'});

    return (
      <div className="sc-financial-info-copy-submission-modal">
        <header className="sc-financial-info-copy-submission-modal-header">
          Select FormId for {this.props.type=='add' ? 'new Form' : 'Renewal'}
        </header>
        <FinancialInfoRenewalModalForm
          close={close}
          onSubmit={this.onSubmit}
          formIdOptions={formIdOptions}
          type={this.props.type}
        />
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    local: state.localization,
    forms: state.forms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    financialActions: bindActionCreators(financialActions, dispatch),
    actions: bindActionCreators(listActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinancialInfoRenewalModal);
	