import _ from 'lodash';

import * as types from './types';
import Api from '../../../../../lib/api';
import { getMultipleFilesApi } from '../api/';
import { FILE_SIZE, FILE_SIZE_UNIT, MAX_FILE_SIZE } from '../../../../../lib/appConstants';
import { createObjectUrlAndDownloadFile } from '../../../../../utils';
import JSZip from 'jszip';
export const setFetchingFiles = (isFetching) => {
  return {
    type: types.SET_FETCHING_FILES,
    isFetching
  };
};

export const setDownloadingFile = (isDownloading) => {
  return {
    type: types.SET_DOWNLOADING_FILE,
    isDownloading
  };
};

export const setPageSize = (payload) => {
  return {
    type: types.SET_PAGE_SIZE,
    payload
  };
};

export const setDownloadingMultipleFiles = (isDownloadingMultiple) => {
  return {
    type: types.SET_DOWNLOADING_MULTIPLE_FILES,
    isDownloadingMultiple
  };
};

export const setMultipleFilesDownloadError = (isDownloadingMultipleError) => {
  return {
    type: types.SET_ERROR_DOWNLOADING_MULTIPLE_FILES,
    isDownloadingMultipleError
  };
};


export const setFileUrl = (url) => {
  return {
    type: types.SET_FILE_URL,
    url
  };
};

export const setSavingFile = (isSaving) => {
  return {
    type: types.SET_SAVING_FILE,
    isSaving
  };
};

export const setDeletingFile = (isDeleting) => {
  return {
    type: types.SET_SAVING_FILE,
    isDeleting
  };
};

export const confirmDeleteFile = (id) => {
  return {
    type: types.DELETE_FILE,
    id
  };
};

export const setFiles = (files) => {
  return {
    type: types.SET_FILES,
    files
  };
};

export const setTotalAmountOfFiles = (filesLength) => {
  return {
    type: types.SET_TOTAL_AMOUNT_OF_FILES,
    filesLength
  };
};

export const setFilesError = (error) => {
  return {
    type: types.SET_CONTRACTS_ERROR,
    error
  };
};

// saveMultipleFiles
export const setSavingMultipleFiles = () => {
  return {
    type: types.SET_SAVING_MULTIPLE_FILES,
  };
};

export const setMultipleFilesSuccess = () => {
  return {
    type: types.SET_SAVING_MULTIPLE_FILES_SUCCESS,
  };
};

export const setMultipleFilesError = (error) => {
  return {
    type: types.SET_SAVING_MULTIPLE_FILES_ERROR,
    error
  };
};
export const setFileEXTemplate = (templates) => {
  return {
    type: types.SET_HC_FILEEXPIRATION_TEMPLATE,
    templates
  };
};

export const fileExpirationWorkflow = (isEnabled) => {
  return {
    type: types.HC_FILEEXPIRATION_WORKFLOW_ENABLED,
    isEnabled
  };
};
export const fetchFiles = (query) => {
  // console.log('query = ', query)
  return (dispatch, getState) => {
    const { login, localization } = getState();
    let {
      error10005,
      error10006,
      error10007,
      error10011,
      error10019,
      errorDefault,
      errorConnection,
    } = localization.strings.scProfile.files.actions;
    const token = login.authToken;

    dispatch(setFetchingFiles(true));

    const urlQuery = 'files';
    const urlParameters = `?${Object.keys(query).map(i => `${i}=${query[i]}`).join('&')}`;

    return Api.get(`${urlQuery}${urlParameters}`, token)
      .then(response => {
        const { success, data, totalCount } = response.data;
        let errorMsg = '';
        if (success) {
          // console.log('totalCount = ', totalCount)
          dispatch(setTotalAmountOfFiles(totalCount));
          dispatch(setFiles(data));
        }
        else {
          switch (data.errorCode) {
            case 10005:
              errorMsg = error10005;
              break;
            case 10006:
              errorMsg = error10006;
              break;
            case 10007:
              errorMsg = error10007;
              break;
            case 10011:
              errorMsg = error10011;
              break;
            case 10019:
              errorMsg = error10019;
              break;
            default:
              errorMsg = errorDefault;
              break;
          }
          dispatch(setFilesError(errorMsg));
        }
        dispatch(setFetchingFiles(false));
      })
      .catch(error => {
        dispatch(setFilesError(errorConnection));
      });
  };
};

export const viewFileLink = (query) => {
//console.log('query in viewFileLink = ', query)
  return (dispatch, getState) => {
    dispatch(setDownloadingFile(true));
    const token = getState().login.authToken;

    const urlQuery = 'viewFileLink';
    const urlParameters = `?${Object.keys(query).map(i => query[i] && `${i}=${query[i]}`).join('&')}`;

    if (query.fileUrl) {
      (async () => {
        await createObjectUrlAndDownloadFile(query.fileUrl,query.orignalFileName)
        dispatch(setDownloadingFile(false));    
      } )();
    } else {
      return Api.get(`${urlQuery}${urlParameters}`, token)
        .then(response => {
          const { data } = response;

          if (data.success) {
            // let storage = window.localStorage
            // storage.setItem('doc', data.url)
            // window.open( `/ViewDocumentPage`, '_blank');
            (async () => {
              await createObjectUrlAndDownloadFile(data.url,query.orignalFileName)
              dispatch(setDownloadingFile(false));    
            } )();
            // console.log('data.url = ', data.url)
            // setFileUrl(data.url)

          } else {
            dispatch(setFilesError("There was an error, please try again"));
          }
          dispatch(setDownloadingFile(false));
        })
        .catch(error => {
          dispatch(setFilesError("There was an error, please try again"));
          dispatch(setDownloadingFile(false));
        });
    }
  };
}

export const downloadFile = (query) => {
  return (dispatch, getState) => {
    dispatch(setDownloadingFile(true));
    const token = getState().login.authToken;

    const urlQuery = 'files/download';
    const urlParameters = `?${Object.keys(query).map(i => `${i}=${query[i]}`).join('&')}`;

    return Api.get(`${urlQuery}${urlParameters}`, token)
      .then(response => {
        const { data } = response;

        if (data.success) {
          const link = document.createElement('a');
          link.download = data.fileName;
          link.href = 'data:application/octet-stream;base64,' + data.fileData;
          link.className = 'd-none';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          dispatch(setFilesError("There was an error, please try again"));
        }
        dispatch(setDownloadingFile(false));
      })
      .catch(error => {
        dispatch(setFilesError("There was an error, please try again"));
        dispatch(setDownloadingFile(false));
      });
  };
}

export const downloadMultipleFiles = (query) => {
  return (dispatch, getState) => {
    dispatch(setDownloadingMultipleFiles(true));
    const legacyFilePaths = [];
    const formioFilePaths = [];
    
    query.forEach((file) => {
      if (file.fileUrl) {
        file.serverFileName = file.fileUrl.split('/').pop();
        formioFilePaths.push(file.fileUrl.split('/').pop());
      } else {
        file.serverFileName = file.url;
        legacyFilePaths.push(file.url);
      }
    });
    
    return getMultipleFilesApi(legacyFilePaths, formioFilePaths)
      .then(response => {
        if (response.status === 200) {
          const {data} = response;
          JSZip.loadAsync(data).then(function (zipData) {
            for(let i =0; i < query.length; i++){
              let fileName = query[i].updateFileName;
              let serverFileName = query[i].serverFileName;
              if(zipData.files[serverFileName] && Object.keys(zipData.files[serverFileName]).length > 0){
                delete Object.assign(zipData.files, {[fileName]: zipData.files[serverFileName] })[serverFileName];
                zipData.files[fileName].name = fileName
                zipData.files[fileName].unsafeOriginalName = fileName
              }
            }
            return zipData
          }).then(function (pngContent) {
            pngContent.generateAsync({type:'blob'}).then(function(content) {
              const link = document.createElement('a');
              link.download = "files.zip";
              const new_blob = new Blob( [ content ], { type: 'attachment/zip' } );
              const url = URL.createObjectURL( new_blob );
              link.href = url;
              link.className = 'd-none';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
           });
          })
        }
        else {
          dispatch(setMultipleFilesDownloadError(true));
        }
        dispatch(setDownloadingMultipleFiles(false));
      })
      .catch(error => {
        dispatch(setMultipleFilesDownloadError(true));
        dispatch(setDownloadingMultipleFiles(false));
      });
  };
}

export const setDownloadError = (status) => {
  return (dispatch, getState) => {
    dispatch(setMultipleFilesDownloadError(status));
  }
}

export const saveFile = (payload, callback) => {
  return (dispatch, getState) => {
    const token = getState().login.authToken;
    dispatch(setSavingFile(true));

    Api.post(
      `files/`,
      payload,
      token
    )
      .then(response => {
        let err = null
        if (!response.data.success) {
          let msg = "There was an error while saving, please try again"
          dispatch(setFilesError(msg));
          err = msg
        }
      //console.log('upload file data = ', response.data)
        dispatch(setSavingFile(false));
        if (callback) {
          callback(err)
        }
      })
      .catch(error => {
        dispatch(setFilesError("There was an error while saving, please try again"));
        dispatch(setSavingFile(false));
        if (callback) {
          callback(error)
        }
      });
  };
}

export const saveMultipleFiles = (payload, callback) => {
  return async (dispatch, getState) => {
    const token = getState().login.authToken;

    dispatch(setSavingMultipleFiles());

    let relatedFiles = [];
    for (let file of payload) {
      file.append('relatedFiles', relatedFiles);
      const fileName = file.get('documentFile').name;
      const fileSize = file.get('documentFile').size;

      try {
        // validate size for file
        if ((fileSize / 1024) > MAX_FILE_SIZE) {
          throw new Error();
        }

        const { data } = await Api.post(`files/`, file, token);

        if (!data.success) {
          throw new Error();
        }

        relatedFiles = [ ...relatedFiles, data.fileId ];

      } catch(error) {
        const getErrorMessage = (file) => {
          return `
            <div style="margin-bottom: 10px">The following files cannot be uploaded:</div>
            <div style="margin-bottom: 10px">
              <strong>- ${file}</strong>
            </div>
            Please ensure to remove any special characters from file name and the file size is less than ${FILE_SIZE}${FILE_SIZE_UNIT}. If you get this error again please upload a blank document if file field is mandatory and send the files to us in email.
          `;
        }

        const errorMessage = getErrorMessage(fileName);

        dispatch(setMultipleFilesError(errorMessage));
        
        if(relatedFiles.length > 0) {
          // This is just un case the request gets rejected from AWS because of the body size
          Api.delete(`files`, { filesIds: relatedFiles }, token);
        }

        if (callback) callback(errorMessage, relatedFiles);

        return;
      }
    }

    dispatch(setMultipleFilesSuccess());

    if (callback) callback();
  };
}

export const deleteFile = (payload) => {
  return (dispatch, getState) => {
    const token = getState().login.authToken;
    dispatch(setDeletingFile(true));
    Api.delete(`files`, { filesIds: [payload] }, token)
    .then(response => {
      dispatch(setDeletingFile(false));
      dispatch(confirmDeleteFile(payload))
    })
    .catch(err => {
    //console.log(err);
    });
  };
}

export const getFileEXTemplate = (hcId) => {
  return (dispatch, getState) => {
    const token = getState().login.authToken;
    Api.get(`communications/fileexpirationtemplates/${hcId}`, token)
    .then(response => {
      const {success,data} = response.data
      if(success){
        dispatch(setFileEXTemplate(data));
      }
    })
    .catch(err => {
      return {success: false , message: "Error in getting file expiration templates"}
    });
  };
}

export const updatefileExpirationDate = (file, hcId, scId, callback) => {
  const {expirationDate, templateId} = file;
  return (dispatch, getState) => {
    const token = getState().login.authToken;
    Api.put(`file/${file.id}`,{templateId, expirationDate, hcId, scId}, token)
    .then(response => {
        const {success,data,message} = response.data;
        if(success){
          callback(null,data);
          return response.data
        }
        callback(message,null);
        return response.data
    })
    .catch(err => {
      callback(err,null);
      return {success: false , message: "Error in updating file expiration date api"}
    });
  };
}

export const checkFileExpirationWoarkflowEnabled = (hcId) => {
  return (dispatch, getState) => {
    const token = getState().login.authToken;
    Api.get(`check/fileExpirationWorkflows?hiringClientId=${hcId}`, token)
    .then(response => {
      const {success, data} = response.data
      if(success){
        dispatch(fileExpirationWorkflow(data.isEnabled));
      }
    })
    .catch(err => {
      return {success: false , message: "Error in checking file expiration workflow"}
    });
  };
}

export const deletefileExpirationDate = (fileId,hcId,scId,callback) => {
  return (dispatch, getState) => {
    const token = getState().login.authToken;
    Api.delete(`fileExpirationDate/${fileId}`,{hcId,scId}, token)
    .then(response => {
        const {success,data} = response.data;
        if(success){
          callback(null,data);
          return response.data
        }
        callback("Error in edit expiration date",null);
        return response.data
    })
    .catch(err => {
      callback(err,null);
      return {success: false , message: "Error in update file api"}
    });
  };
}