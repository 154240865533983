export const SET_REQUIREMENT_SETS = 'SET_REQUIREMENT_SETS';
export const SET_ATTACHMENTS = 'SET_ATTACHMENTS';
export const SET_ENDORSEMENTS = 'SET_ENDORSEMENTS';
export const SET_ALL_ENDORSEMENTS = 'SET_ALL_ENDORSEMENTS';
export const SET_REQUIREMENT_SETS_ERROR = 'SET_REQUIREMENT_SETS_ERROR';
export const SET_FETCHING_DOCUMENTS = 'SET_FETCHING_DOCUMENTS';
export const SET_DOCUMENTS_LIST = 'SET_DOCUMENTS_LIST';
export const SET_DOCUMENTS_ERROR = 'SET_DOCUMENTS_ERROR';
export const SET_CUSTOMER_TOKEN = 'SET_CUSTOMER_TOKEN';
export const SET_CUSTOM_TERMS = 'SET_CUSTOM_TERMS';
export const SET_FETCHING_REQUIREMENTS = 'SET_FETCHING_REQUIREMENTS';