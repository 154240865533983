import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import HolderInfoTab from './holderInfoTab';

import * as holdersActions from '../../holders/actions';
import * as commonActions from '../../../common/actions';
import * as departmentsActions from '../../../certfocus/settings/departments/actions';

import Utils from '../../../../lib/utils';

import '../addEntityModal.css';

class AddHolderModal extends React.Component {
  constructor(props) {
    super(props);

    //props.actions.fetchParentHolders();
    props.actions.fetchAccountManagers();
    if(props.common.countries.length <= 0){
      props.commonActions.fetchCountries();
    }
  };

  componentDidMount() {
    this.props.departmentsActions.getDepartments({
      orderBy: 'name',
      orderDirection: 'ASC',
      archived: 0,
      withoutPag: true
    });
  }

  send = (values) => {
    let formattedPhone = values.contactPhone;
    if(formattedPhone){
      formattedPhone = Utils.normalizePhoneNumber(formattedPhone);
      /*if(formattedPhone.length === 10){
        formattedPhone = `+1${formattedPhone}`
      }*/
    }     

    const payload = {
      
      holderId: this.props.profile ? this.props.profile.id : undefined,
      holderName: values.holderName,
      parentHolderID: values.parentHolder ? values.parentHolder.value : null,
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      stateId: Number(values.stateId.value),
      postalCode: values.postalCode,
      countryId: Number(values.countryId.value),
      department: values.department,
      phoneNumber: formattedPhone,
      accountManagerId: values.accountManager &&  typeof values.accountManager === 'object'? JSON.stringify(values.accountManager): values.accountManager &&  typeof values.accountManager === 'string' ? values.accountManager : "",
      portalURL: values.subdomain,
      contactName: values.contactName,
      contactPhone: formattedPhone,
      contactId: values.contactId ? values.contactId : undefined,
      intOfficeID: values.intOfficeID,
      contactEmail: values.contactEmail,
      initialFee: values.initialFee >= 0 ? values.initialFee : undefined,
      initialCredits: values.initialCredits >= 0 ? values.initialCredits : undefined,
      addlFee: values.addlFee >= 0 ? values.addlFee : undefined,
      addlCredits: values.addlCredits >= 0 ? values.addlCredits : undefined,
      portalHomepageText: values.portalHomepageText ? values.portalHomepageText : '',
      note: values.note,
      portalFaqText: values.portalFaqText ? values.portalFaqText : '',
      emailAliasAddress: values.emailAliasAddress ? values.emailAliasAddress : '',
      emailAliasName: values.emailAliasName ? values.emailAliasName : ''
    };

    this.props.commonActions.setLoading(true);
    this.props.actions.postHolder (payload, (holder) => {
      this.props.commonActions.setLoading(false);
      if(holder){
        this.props.close(true);
      }
    });

  };

  hideModal = () => {
    const { onHide, close } = this.props;
    if(onHide)onHide();
    else close();
  }

  render() {
    const {
      title
    } = this.props.local.strings.holders.addHolderModal;

    return (
      <div className="new-entity-form wiz-wrapper">
        <header>
          <h2 className="modal-wiz-title">
            {title} 
          </h2>
        </header>

        <div className="steps-bodies add-item-view">
          <div className='step-body add-item-form-subsection active'>
            <HolderInfoTab
              close={this.hideModal}
              continueHandler={this.send}
              profile={this.props.profile}
            />
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    local: state.localization,
    holders: state.holders,
    common: state.common
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(holdersActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    departmentsActions: bindActionCreators(departmentsActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddHolderModal);
