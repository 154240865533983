/*global ExagoApi*/

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './actions';
import Utils from '../../../lib/utils';

import './exago.css';

class Exago extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      reportTree: '',
      printParams: '',
      hasLoadActionBeenTaken: false,
      reportPath: '',
      loading: false
    }
    this.api = null;
  }

  componentDidMount() {
    const { printReport, userProfile } = this.props;
  //console.log('userProfile ', userProfile);
    if (userProfile.Id && userProfile.ReportingRole && userProfile.CFRoleId) {
      this.routeActions(printReport, userProfile.Id, userProfile.ReportingRole, userProfile.CFRoleId)
    } else {
      console.error('Cannot retrieve report');
    }
  }

  componentWillReceiveProps(nextProps) {
    const { printReport } = this.props;
    const {
      CFRoleId,
      ReportingRole,
      Id
    } = this.props.userProfile;
    let shouldDisplayPrintPage = false;

    if (this.props.exago.loading && !nextProps.exago.loading){
      if (nextProps.exago.error) {
        console.error(nextProps.exago.error);
      }
    }

    const newPrint  = nextProps.printReport;
    const newRoleId = nextProps.userProfile.CFRoleId;
    const newRole   = nextProps.userProfile.ReportingRole;
    const newId     = nextProps.userProfile.Id;

    shouldDisplayPrintPage = (
      (nextProps.printReport && nextProps.printReport !== printReport) ||
      (nextProps.userProfile.CFRoleId && nextProps.userProfile.CFRoleId !== CFRoleId) ||
      (nextProps.userProfile.ReportingRole && nextProps.userProfile.ReportingRole !== ReportingRole) ||
      (nextProps.userProfile.Id && nextProps.userProfile.Id !== Id)
    )

    if (shouldDisplayPrintPage) {
      this.routeActions(newPrint, newId, newRole, newRoleId)
    }
  }

  componentWillUnmount () {
    this.disposeAll();
  }

  routeActions = (print, Id, ReportingRole, RoleID) => {
  //console.log('routeActions ', print, Id, ReportingRole, RoleID);

    if (!this.state.hasLoadActionBeenTaken && Id && ReportingRole && RoleID) {
      if (!this.api && !print) {
        this.props.actions.initSession(() => {
          this.initApiAndShowFullView(Id, ReportingRole, RoleID);
        });
      }
      else if (!print) {
        this.ShowFullUI();
        //this.ShowInput();
        //this.ShowControls();
        this.api.OnDisposeContainer = this.ResetContainer;
      }
      else if (print) {
        const storage    = window.localStorage;
        const str        = storage.str;
        const keys       = ['dates', 'savedFormId', 'hiringClientIDEnabled', 'subcontractorIDEnabled', 'savedFormIDEnabled', 'userId', 'scId', 'reportPath'];
        const types      = ['obj', 'num', 'bool', 'bool', 'bool', 'num', 'num', 'str'];
        const params     = Utils.objectifyString(str, keys, types);
        const reportPath = params.reportPath;
        const report     = {};

      //console.log('params in Exago right after str was "objectified" = ', params)

        if (params.hiringClientIDEnabled) {
          report['pocHiringClientIDList'] = params.userId
        } else {
          report['pocHiringClientIDList'] = 'NA'
        }
        if (params.savedFormIDEnabled) {
          report['pSavedFormID'] = params.savedFormId
        } else {
          report['pSavedFormID'] = 'NA'
        }
        if (params.subcontractorIDEnabled) {
          report['pSubcontractorIDList'] = params.scId
        } else {
          report['pSubcontractorIDList'] = 'NA'
        }
      //console.log('report = ', report)

        this.setState({ reportPath }, () => {
          this.props.actions.initSession(() => {
            this.printReport(report);
          });
        })
        storage.removeItem('str');
      }

      this.setState({ hasLoadActionBeenTaken: true });
    }
  }

  initApiAndShowFullView = (Id, ReportingRole, RoleID) => {
    this.props.actions.setParameter('roles', ReportingRole, { IsActive: true }, () => {
      this.props.actions.setParameter('parameters', 'pocHiringClientIDList', { Value: Id } , () => {
        this.props.actions.setParameter('roles', ReportingRole, { Value: Id },  () => {
          this.loadExagoAPI(this.ShowFullUI)
        }, RoleID);
      });
    });
  }

  printReport = (params) => {
    const keys = Object.keys(params);
    const values  = Object.values(params);
  //console.log('keys and vals in Exago/printReport = ', keys, values);
    this.props.actions.setParameter('parameters', keys[0], { Value: values[0] } , () => {
      this.props.actions.setParameter('parameters', keys[1], { Value: values[1] } , () => {
        this.props.actions.setParameter('parameters', keys[2], { Value: values[2] } , () => {
          this.loadExagoAPI(this.executeReport);
        });
      });
    });
  }

  handleInputChange = (e) => {
    e.preventDefault()
    let psub = Number(this.psub.value);
    this.setSub(psub);
  }

  /*
  * Patches that send Exago the subcontractor selection NEED to happen before the Exago API is instantiated.
  * Instantiating the Exago API causes the sessionId (and ApiKey) to refresh.
  */
  setSub = (sub) => {
    this.props.actions.setParameter('psub', sub, () => {
      this.loadExagoAPI();
    });
  }

  loadExagoAPI = (callback) => {
    const exagoContainer = this.getElement('ExagoWrapper');
    if (exagoContainer) {
      let apiKey = this.props.exago.apiKey;
      if (apiKey && apiKey.length > 0) {
        this.api = new ExagoApi(`${process.env.REACT_APP_EXAGO_SERVER}/Exago/`, apiKey, callback); // eslint-disable-line no-use-before-define
        this.api.OnDisposeContainer = this.ResetContainer;
      }
    }
  }

  checkData = () => {
    this.props.actions.getParameter('psub');
  }

  ShowInput = () => {
    const input = 'input';
    this.showElement(input);
    return this.getElement(input);
  }

  ShowControls = () => {
    const jsApiMenu = 'JavascriptApiMenu';
    this.showElement(jsApiMenu);
    this.executeReport();
    return this.getElement(jsApiMenu);
  }

  executeReport = () => {
    this.hideElement('Container-FullUI');
    this.disposeAll()
    const container = this.getElement('Container-Report');
    const reportPath = this.state.reportPath;

  //console.log('container = ', container)
  //console.log('reportPath = ', reportPath)
    this.api.ExecuteReport(container, 'Html', reportPath, null);
    this.showElement('Container-Report');
  }

  ShowFullUI = () => {
    const fullUI = 'Container-FullUI';
    this.hideElement('Container-Report');
    this.disposeAll();
    this.showElement(fullUI);
    const elem = this.getElement(fullUI);
    this.api.LoadFullUI(elem);
    this.setState({ loading: false });
  }

  setTreeToState = (res) => {
  //console.log('succesffully called api.LoadReportTree() => ', res)
    this.setState({ reportTree: res });
  }

  // errCB = () => {
  //   console.log('Error in calling LoadReportTree')
  // }

  ShowReportTree = () => {
    this.disposeAll();
    this.api.LoadReportTree(this.setTreeToState, this.errCB);
  }

  ResetContainer = (container) => {
		if (container)
			container.innerHTML = "";
  }

  getElement = (elem) => {
    return document.getElementById(elem);
  }

  hideElement = (elem) => {
    const el = this.getElement(elem);
    if(el)el.classList.add("hide");
  }

  showElement = (elem) => {
    const el = this.getElement(elem);
    if(el)el.classList.remove("hide");
  }

  disposeAll = () => {
    //console.log('disposed of all');
    const exagoContainers = document.querySelectorAll("div.exago");
    for (let i = 0, len = exagoContainers.length; i < len; i++) {
      if(this.api){
        this.api.DisposeContainerContent(exagoContainers[i]);
      }
    }
  }

  render() {
    const spinnerContainerStyle = {
      position:'fixed',
      top:'0',
      left:'0',
      backgroundColor:'#80808087',
      width:'100%',
      height:'100%',
    };
    const spinnerStyle = {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };

    return (
      <div className="dashboard">
        <div>
          { this.state.loading
            ? <div style={spinnerContainerStyle} >
                <div className="spinner-wrapper" style={spinnerStyle} >
                  <div className="spinner"></div>
                </div>
              </div>
            : null
          }
        </div>
        <form id="input" className="hide" onSubmit={this.handleInputChange}>
          <input type="text" id="input" ref={ psub => this.psub = psub } placeholder="select subcontractor" style={{ 'height': '25px', 'width':'200px' }}/>
          <input type="submit" id="jsBtn1" value="submit" style={{ 'height': '25px', 'width':'200px' }}/>
        </form>
        <div id="JavascriptApiMenu" ref={this.jsApiMenu} className="hide">
          <input type="button" id="jsBtn0" onClick={this.executeReport} value="Report" />
          <input type="button" id="jsBtn1" onClick={this.ShowFullUI} value="Full UI" />
          <input type="button" id="jsBtn1" onClick={this.ShowReportTree} value="Report Tree" />
          <input type="button" id="jsBtn1" onClick={this.checkData} value="Check psub data" />
        </div>
        <div id="ExagoWrapper" ref={this.exagoCont} runat="server">
          <div id="Container-Report" ref={this.contReport} className="hide">
            <div id="SectionText" runat="server" />
            <div id="SectionChart" className="exago" runat="server" />
            <div id="SectionReport" className="exago" runat="server" />
          </div>
          <div id="Container-FullUI" ref={this.fullUI} className="exago hide" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    local: state.localization,
    exago: state.exago
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Exago);