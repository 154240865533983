import Api from '../../../../lib/api';

export const createInsured = (payload, callback) => {
  return (dispatch, getState) => {
    const { localization } = getState();
    let {
      errorDefault,
      errorConnection,
    } = localization.strings.hcProfile.projects.actions;
    
    // create insured
    return Api.post(`cf/registration/insureds`, payload)
      .then(response => {
        const { success, data } = response.data;
        if (success) {
          const insuredId = data.insuredId;
          
          // register insured
          const registerPayload = { insuredId: insuredId, holderId: payload.holderId, projectId: payload.selectedProject || null };
          Api.post(`cf/registration/insureds/register`, registerPayload)
            .then(response => {
              const { success } = response.data;
              if (!success) {
                callback(false, insuredId);
              }
            });

          // create insured contact
          if (payload.insuranceAgencyCompanyName && payload.insuranceAgencyContactPerson && payload.insuranceAgencyPhone) {
            const contactPayload = {
              firstName: payload.insuranceAgencyContactPerson,
              lastName: payload.insuranceAgencyCompanyName,
              phoneNumber: payload.insuranceAgencyPhone,
              emailAddress: payload.insuranceAgencyEmail,
              typeId: payload.typeId,
            }
            Api.post(`cf/registration/contacts`, contactPayload)
              .then(response => {
                const { success, data } = response.data;
                if (success) {
                  Api.post(`cf/registration/contacts/insuredrelation`, { insuredId: insuredId, contactId: data.cId })
                    .then(response => {
                      callback(false, insuredId);
                    });
                } else {
                  callback(false, insuredId);
                }  
              });
          } else {
            callback(false, insuredId);
          }
        } else {
          callback(true, errorDefault);
        }
      })
      .catch(() => {
        callback(true, errorConnection);
      });
  };
};

export const fetchHolderData = (holderId, callback) => {
  return (dispatch, getState) => {
    const { localization } = getState();
    let {
      errorDefault,
      errorConnection,
    } = localization.strings.hcProfile.projects.actions;

    return Api.get(`cf/registration/holderdetail?holderId=${holderId}`)
      .then(response => {
        const { success, data } = response.data;
        if (success) {
          callback(false, data);
        } else {
          callback(true, errorDefault);
        }
      })
      .catch(() => {
        callback(true, errorConnection);
      });
  };
};

export const createProjectInsured = (payload, callback) => {
  return (dispatch, getState) => {
    const { localization } = getState();
    let {
      errorDefault,
      errorConnection,
    } = localization.strings.hcProfile.projects.actions;
    
    return Api.post(`cf/registration/projectInsureds`, payload)
      .then(response => {
        const { success } = response.data;
        if (success) {
          // return cert upload hash
          Api.get(`cf/registration/projectInsureds?projectId=${payload.projectId}&insuredId=${payload.insuredId}`)
            .then(response => {
              const { success, projectInsureds } = response.data;
              return (success) ? callback(false, projectInsureds[0].CertUploadHash) : callback(true, null);
            });
        } else {
          callback(true, errorDefault);
        }
      })
      .catch(() => {
        callback(true, errorConnection);
      });
  };
};

/* Support for Geo States */
export const fetchGeoStates = (callback) => {
  return (dispatch) => {
    return Api.get(`cf/registration/states`).then(response => {
      const {success, data } = response.data;
      if (success && data) {
        callback(false, data.states);
      }
    })
    .catch(error => {
      callback(true, error);
    });
  };
}