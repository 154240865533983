const {
	CF_ADMIN_ROLE_ID,
  DEPARTMENT_MANAGER_ROLE_ID,
  ACCOUNT_MANAGER_ROLE_ID,
  PROCESSOR_ROLE_ID,
} = require('../../../../../lib/appConstants');

const validate = (values, props) => {
  const { CFRoleId } = props.login.profile;
  const availableRoles = [
    CF_ADMIN_ROLE_ID, 
    DEPARTMENT_MANAGER_ROLE_ID,
    ACCOUNT_MANAGER_ROLE_ID,
    PROCESSOR_ROLE_ID
  ];
  const disabledByRole = (availableRoles.find(e => e === Number(CFRoleId))) ? false : true;
  const {
    requiredValidation,
  } = props.local.strings.hiringClients.addHCModal.companyInfoTab;
  const errors = {};
  
  const validateFilled = [
    'holderId', 
    'projectId',
    'insuredId',
    'documentTypeId',
  ];
  validateFilled.forEach(name => {
    if(!values[name]) {
      errors[name] = requiredValidation;
    }
  });
  
  // Validate by certificate / another doc type
  if (!disabledByRole && !values.queueId) {
    errors.queueId = requiredValidation;
  }
  
  return errors;
};

export default validate;