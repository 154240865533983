import Api from '../../../lib/api';

const loadOptions = (sc, hc) => {
  return async (name, prevOptions, {page}) => {
    const next = {
      options: prevOptions,
      hasMore: false,
      additional: {page}
    };

    try {
      const qs = new URLSearchParams({name, page, hc});
      const endpoint = `subcontractors/${sc}/siblings-hc?${qs}`;
      const response = await Api.get(endpoint);
      const {results, counts} = response.data.data;
      const pickValues = ({name: label, id: value}) => ({label, value});
      next.options = results.map(pickValues);
      next.hasMore = counts.hasNextPage > 0 && results.length > 0;
      next.additional = {page: next.hasMore ? page + 1 : page};
    } catch (error) {
      console.error(error);
    }

    return next;
  };
};

export default loadOptions;