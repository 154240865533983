const validate = (values, props) => {
  const errors = {};
  const {
    requiredValidation,
  } = props.local.strings.hiringClients.addHCModal.companyInfoTab;
  
  const validateFilled = [
    'CFRegistrationCode',
  ];
  
  validateFilled.forEach(name => {
    if(!values[name]) {
      errors[name] = requiredValidation;
    }
  });

  if (parseInt(values.CFConnectToProject,10) === 1) {
    if (!values.CFSelectedProject) {
      errors.CFSelectedProject = requiredValidation;
    }
    if (!values.CFSelectedRequirementSet) {
      errors.CFSelectedRequirementSet = requiredValidation;
    }
  }

  return errors;
};

export default validate;