import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';

import DeficiencesMinor from './components/minor';
import DeficiencesMajor from './components/major';
import DeficiencesEndorsements from './components/endorsements';
import DeficiencesAccepted from './components/accepted';
import DeficiencesWaived from './components/waive';
import WaivedModal from './components/waived-modal';
import * as actions from './actions/actions';
import * as commonActions from './../../common/actions';
import * as certificateActions from './../../certfocus/certificates/actions';

import './deficiencies.css';
import RolAccess from "../rolAccess";
class Deficiences extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      showAll: false,
      showModalWaived: false,
      titleShowAllBtn: 'Show All'
    }
  }

  componentDidMount() {
    const { projectInsuredId } = this.props.params;
    this.props.actions.fetchDeficiences(projectInsuredId);
  }

  actionConfirmAll() {
    this.props.actions.setSelectAllConfirm();
    Swal({
      title: "Confirm!",
      showCancelButton: true,
      type: 'warning',
      text: "Are you sure you want to Confirm All ?",
      icon: "success",
      button: "OK!",
    }).then((result) => {
      if (result.value) {
        this.confirmDeficiences();
      }
      else {
        this.props.actions.setUnSelectAllConfirm();
      }
    })
  }
  checkDeficiencesDateExpired(def){
    if(!def.ExpirationDate){
      return false
    }
    let currentDate = new Date().getTime();
    let defDate = (def.ExpirationDate) ? new Date(def.ExpirationDate).getTime() : null;
    return (defDate && (currentDate < defDate)) ? false : true;
  }
  confirmDeficiences() {
    const {deficiences, certificatesList, commonActions, actions, certificateActions} = this.props;
    let confirmDeficiences = this.getConfirmDeficency();
    let projectInsuredID = certificatesList[0].ProjectInsuredID;
    if(confirmDeficiences.length > 0){
      let deficienciesToConfirm = deficiences.filter((deficiency) => deficiency.ProjectInsuredDeficiencyID != null  && deficiency.AttributeStatus !== "Expired" && !this.checkDeficiencesDateExpired(deficiency));
      commonActions.setLoading(true);
      let deficienciesRemaining = deficiences;
      actions.fetchConfirmDeficiences(deficienciesToConfirm, projectInsuredID, deficienciesRemaining, () => {
        setTimeout(() => {
          certificateActions.fetchCertificates(projectInsuredID);
          actions.fetchDeficiences(projectInsuredID);
          certificateActions.fetchProjectInsured(projectInsuredID);
          commonActions.setLoading(false);
          this.unableToConfirmModel();
        }, 1000);
      });
    }
    else{
      this.unableToConfirmModel()
    }

  }
  unableToConfirmModel(){
    const splitDeficiency = [1,2];
    const {local, deficiences} = this.props;
    const {
      confirmAttributeModelTitle,
      confirmAttributeModelText,
      confirmAttributeModelConfirmButtonText
    } = local.strings.certfocusCertificates
    let isExpired = deficiences.some((deficiency) => (deficiency.DefTypeID == 1 || deficiency.DefTypeID == 2 ) && (!splitDeficiency.includes(deficiency.DeficiencyStatusID)) && ( deficiency.ProjectInsuredDeficiencyID === null  || deficiency.AttributeStatus === "Expired" || this.checkDeficiencesDateExpired(deficiency)));
    if(isExpired){
      Swal({
        title: confirmAttributeModelTitle,
        text: confirmAttributeModelText,
        confirmButtonText: confirmAttributeModelConfirmButtonText,
      });
    }
  }

  getConfirmDeficency(){
    const splitDeficiency = [1,2];
    const {deficiences} = this.props;
    return deficiences.filter(deficiences => (deficiences.DefTypeID == 1 || deficiences.DefTypeID == 2 ) && (!splitDeficiency.includes(deficiences.DeficiencyStatusID)) &&  deficiences.ProjectInsuredDeficiencyID != null  && deficiences.AttributeStatus !== "Expired" && !this.checkDeficiencesDateExpired(deficiences));
  }
  actionWaiverAll() {
    this.props.actions.setSelectAllWaiver();
    this.setState({ showModalWaived: true });
  }

  disableBtnConfirm() {
    const enableConfirmAll = [1,2]
    return (this.props.deficiences && this.props.deficiences.some(element => !enableConfirmAll.includes(element.DeficiencyStatusID)));
  }

  actionShowAll() {
    let payload = { show: !this.props.showAll };
    this.setState({ titleShowAllBtn: !payload.show ? 'Show All' : 'Hide All' });
    this.props.actions.setShowAll(payload);
  }

  hideWaivedModal = () => {
    this.setState({ showModalWaived: false });
  }

  showWaivedModal = () => {
    this.setState({ showModalWaived: true });
  }

  renderDeficienciesActions = () => {
    let disableBtnConfirm = this.disableBtnConfirm();
    const { isArchived } = this.props;
    return (
        <div className="row mt-2">
          <div className="col-4">
            <button
                className={`nav-btn nav-bn ${(isArchived) ? 'disabledBtn' : ''}`}
                disabled={!disableBtnConfirm || isArchived}
                onClick={() => this.actionConfirmAll()}>Confirm All</button>
          </div>
          <div className="col-4">
            <button
                className={`nav-btn nav-bn ${(isArchived) ? 'disabledBtn' : ''}`}
                disabled={!disableBtnConfirm || isArchived}
                onClick={() => this.actionWaiverAll()}>Waiver All</button>
          </div>
          <div className="col-4">
            <button className="nav-btn nav-bn"
                    onClick={() => this.actionShowAll()}>{this.state.titleShowAllBtn}</button>
          </div>
        </div>
    )
  }

  render() {    
    const { isArchived, hasMajorDefs, AIWording } = this.props;
    const {CFRole} = this.props.profile;
    return (
      <div>
        <div className="row">
          <div className="col d-flex justify-content-center">
            <span><h5>Deficiencies</h5></span>
          </div>
        </div>
        <hr></hr>
        <RolAccess
            masterTab='certificates_insurance'
            sectionTab='edit_certificate'
            component={() => this.renderDeficienciesActions()}>
        </RolAccess>
        {CFRole && CFRole.Name === 'Holder View Only' && (
          <div className='row mt-2'>
            <div className="col-4">
                <button className="nav-btn nav-bn"
                        onClick={() => this.actionShowAll()}>{this.state.titleShowAllBtn}</button>
              </div>
          </div>
        )}

        <div className="row mt-4">
          <div className="col d-flex">
            <DeficiencesMajor 
              showWaivedModal={this.showWaivedModal} 
              data={this.props.deficiences}
              isArchived={isArchived}
              AIWording={AIWording}
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col">
            <DeficiencesMinor 
              showWaivedModal={this.showWaivedModal} 
              data={this.props.deficiences}
              isArchived={isArchived}
              AIWording={AIWording}
            />
          </div>
        </div>

        {/*<div className="row mt-2">
          <div className="col">
            <DeficiencesEndorsements showWaivedModal={this.showWaivedModal}  data={this.props.deficiences} />
          </div>
        </div>*/}

        <div className="row mt-2">
          <div className="col">
            <DeficiencesAccepted 
              data={this.props.deficiences}
              isArchived={isArchived}
              AIWording={AIWording}
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col">
            <DeficiencesWaived 
              data={this.props.deficiences}
              isArchived={isArchived}
              AIWording={AIWording}
            />
          </div>
        </div>

        <WaivedModal showModalWaived={this.state.showModalWaived} closeModalWaived={this.hideWaivedModal}></WaivedModal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    deficiences: state.deficiences.deficiencesList,
    showAll: state.deficiences.showAll,
    certificatesList: state.certificates.certificatesList,
    profile: state.login.profile,
    local: state.localization,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    certificateActions: bindActionCreators(certificateActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Deficiences);
