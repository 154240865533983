import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-bootstrap';

import Utils from '../../../../../lib/utils';
import { SCORE_CARD_CONCEPT_IDS_FOR_CREDIT_HISTORY, SCORE_CARD_CONCEPT_IDS_FOR_CREDIT_LINE, SCORE_CARD_CONCEPT_IDS_FOR_LEGAL, SCORE_CARD_CONCEPT_IDS_FOR_PROFITABILITY, SCORE_CARD_CONCEPT_IDS_FOR_REFERENCE } from '../../../../../lib/appConstants';
import * as financialActions from '../../financialInfo/actions';
import moment from "moment";

class ScoreCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displaySCname: this.props.scProfile.headerDetails.name || '',
      hasScoreCardBeenUpdated: false,
      filter: {
        pageNumber: 1
      },
      order: {
        name: 'asc',
        value: 'asc'
      },
      tableOrderActive: 'name',
      workingCapital: '$100',
      savedFormId: '',
      fetchingScoreCardsConcepts: '',
      scoreCardsConcepts: '',
      subcontractorDataConcepts: '',
      scorecardMainParameters: '',
      averageConcepts: '',
      commentary: '',
      emrAccounts: '',
      nevadaSingleLimit: '',
      fetchId: '',
      scorecardAccountDisplayTypeId: 1,
      scorecardHiddenFields: [],
      isCovid19Form: false,
      basicAccounts: []
    }
  };

  // THIS IS UNNECESSARY FOR NOW, SINCE "fetchScoreCardsConcepts" is initialized in the constructor
  // componentWillMount() {
  //   this.props.financialActions.fetchScoreCardsConcepts(this.props.savedFormId, "ScoreCard CWM");
  // }

  fetchScoreCards(props) {
    const {
      hasScoreCardBeenUpdated
    } = this.state;
    const newSavedFormId = props.savedFormId;
    const newFSCC = props.financials.fetchingScoreCardsConcepts;
    const newSCC = props.financials.scoreCardsConcepts;
    const newSDC = props.financials.subcontractorDataConcepts;
    const newSDCsubName = newSDC && newSDC.subcontractorName ? newSDC.subcontractorName : '';
    const newSCname  = props.scProfile.headerDetails.name;
    const oldSCname  = this.props.scProfile.headerDetails.name;
    const newSMP = props.financials.scorecardMainParameters;
    const newAC = props.financials.averageConcepts;
    const newC = props.financials.commentary;
    const newEmrA = props.financials.emrAccounts;
    const newNSL = props.financials.nevadaSingleLimit;
    const isEmpty = props.financials.isScoreCardEmpty;
    const scorecardAccountDisplayTypeId = props.financials.scorecardAccountDisplayTypeId;
    const scoreCardsdiscreteAccounts = props.financials.scoreCardsdiscreteAccounts.map(element => ({
      ...element,
      valueSelected: element.valueSeleted,
    }));
    const scorecardHiddenFields = props.financials.scorecardHiddenFields;
    const isCovid19Form = props.financials.isCovid19Form;
    const newBAC = props.financials.basicAccounts

    if (newFSCC === false) {
      this.setState({
        fetchingScoreCardsConcepts: newFSCC,
      });
    }

    if ((newSCname || newSCname == null) && newSCname !== oldSCname) {
      this.setState({
        displaySCname: newSCname ? Utils.displayQuotes(newSCname) : ''
      })
    }

    const shouldUpdateState = (!newSDCsubName && (this.props.savedFormId !== newSavedFormId) || !hasScoreCardBeenUpdated);

    this.setState({
      averageConcepts: isEmpty ? [] : newAC,
      commentary: isEmpty ? '' : newC,
      emrAccounts: isEmpty ? [] : newEmrA,
      nevadaSingleLimit: isEmpty ? '' : newNSL,
      scoreCardsConcepts: isEmpty ? [] : newSCC,
      subcontractorDataConcepts: isEmpty ? [] : newSDC,
      scorecardMainParameters: isEmpty ? [] : newSMP,
      hasScoreCardBeenUpdated: true,
      scoreCardsdiscreteAccounts: scoreCardsdiscreteAccounts,
      scorecardAccountDisplayTypeId: scorecardAccountDisplayTypeId,
      scorecardHiddenFields: scorecardHiddenFields,
      isCovid19Form: isCovid19Form,
      basicAccounts: isEmpty ? [] : newBAC
    }, () => {
      // console.log('state was called')
    });

    if (shouldUpdateState || this.props.savedFormId !== newSavedFormId) {
      this.props.financialActions.fetchScoreCardsConcepts(newSavedFormId)
    }

  }

  componentDidMount() {
    this.fetchScoreCards(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.fetchScoreCards(nextProps);
  }

  getLimitValue(value) {

    let unFormattedValue = 0

    if (value && typeof value === 'number') {
      let tempValue = Math.round(value * 1) / 1
      unFormattedValue = tempValue.toString()
    }

    return `$ ${Utils.formatNumberWithCommas(unFormattedValue)}`;
  }

  getNevadaLimitValue(value) {
    if (typeof value === 'number') {
      if (value < 0) {
        return 'unlimited'
      } else {
        return this.getLimitValue(value)
      }
    }
  }

  getLargestContractCompletedValue(index) {
    const {
      averageConcepts
    } = this.state;

    const concept = _.get(averageConcepts, `avgProjectAccounts[${index}]`);

    const value = concept && `$ ${Utils.formatNumberWithCommas(Math.trunc(concept.value))}`;

    return value || null;
  }
  getLargestContractCompletedDate(index) {
    const { averageConcepts } = this.state;
    const concept = _.get(averageConcepts, `avgProjectAccounts[${index}]`);
    const value = concept && concept.date ? concept.date : false;
    return value || null;
  }

  getTotalRevenueEarnedValue(index) {
    const {
      averageConcepts
    } = this.state;

    const concept = _.get(averageConcepts, `avgVolumeAccounts[${index}]`);

    const value = concept && `$ ${Utils.formatNumberWithCommas(Math.trunc(concept.value))}`;

    return value || null;
  }

  getTotalRevenueEarnedDate(index) {
    const { averageConcepts } = this.state;
    const concept = _.get(averageConcepts, `avgVolumeAccounts[${index}]`);
    const value = concept && concept.date ? concept.date : false;
    return value || null;
  }

  getExperienceModificationRateValue(index) {
    const {
      emrAccounts
    } = this.state;

    const concept = _.find(emrAccounts, { 'index': index });

    const value = concept && `${concept.value}`;

    return value || null;
  }

  getExperienceModificationRateDate(index) {
    const { emrAccounts } = this.state;
    const concept = _.find(emrAccounts, { index: index });
    const conceptDate = _.get(concept, "date");
    const newDate = new Date(conceptDate).getFullYear();
    const value = newDate;
    return value || null;
  }

  getScorcardConceptValue(scorecardConceptId) {
    const {
      scoreCardsConcepts
    } = this.state;

    const concept = _.find(scoreCardsConcepts, { scorecardConceptId });
    const conceptValue = _.get(concept, 'value');
    const value = (conceptValue && Utils.isValidNumber(conceptValue)) ? Utils.formatNumberWithCommas(conceptValue) : conceptValue;
    const color = concept && concept.color;

    const iconClassName = classnames({
      'concept-flag': true,
      'green': color === 1,
      'yellow': color === 2,
      'red': color === 3
    });
    const scoreCardForWaivedList = [SCORE_CARD_CONCEPT_IDS_FOR_LEGAL, SCORE_CARD_CONCEPT_IDS_FOR_PROFITABILITY, SCORE_CARD_CONCEPT_IDS_FOR_REFERENCE, SCORE_CARD_CONCEPT_IDS_FOR_CREDIT_LINE, SCORE_CARD_CONCEPT_IDS_FOR_CREDIT_HISTORY]
    let requirementWaivedField = scoreCardForWaivedList.find(f => f === scorecardConceptId);
    let finIsCompleted=this.props.financials.accountsListData.finIsComplete;
    if (requirementWaivedField && !value && finIsCompleted) {
      const yellowIconClass = classnames({
        'concept-flag': true,
        'yellow': true,
      });
      return (<Row className="concept-value">
        <Col md={6}>
          {"Requirement Waived"}
        </Col>
        <Col md={6}>
          <span className={yellowIconClass}>&#8226;</span>
        </Col>
      </Row>)
    } else {
      return value ? (
        <Row className="concept-value">
          <Col md={6}>
            {value}
          </Col>
          <Col md={6}>
            <span className={iconClassName}>&#8226;</span>
          </Col>
        </Row>
      ) : null;
    }
  }
  renderDiscreteAccounts() {
    const {
      scoreCardsdiscreteAccounts,
    } = this.state;

    if (scoreCardsdiscreteAccounts) {
      const chunkedDiscreteAccounts = _.chunk(scoreCardsdiscreteAccounts, 2)

      return chunkedDiscreteAccounts.map((discreteAccounts, index) => {
        return (
          <Row key={index}>
            {discreteAccounts.map((element) => {
              if (element.valueSelected === "0") return null;

              const iconClassName = classnames({
                'concept-flag': true,
                'green': element.color === 1,
                'yellow': element.color === 2,
                'red': element.color === 3
              });

              return (
                <Col md={6} key={element.id}>
                  <Row>
                    <Col md={6}>
                      {element.name}:
                    </Col>
                    <Col md={6}>
                      <Row className="concept-value">
                        <Col md={6}>
                          {element.valueSelected}
                        </Col>
                        <Col md={6}>
                          <span className={iconClassName}>&#8226;</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
        );
      });
    }

    return null;
  }

  render() {
    const {
      fetchingScoreCardsConcepts,
      subcontractorDataConcepts,
      scorecardMainParameters,
      commentary,
      nevadaSingleLimit,
      scorecardAccountDisplayTypeId,
      scorecardHiddenFields,
      isCovid19Form,
      basicAccounts
    } = this.state;

    let adjustedWorkingCapitalResult = 0;
    if (scorecardMainParameters.adjustedWorkingCapital) {
      adjustedWorkingCapitalResult = scorecardMainParameters.adjustedWorkingCapital;
    }

    const dateOfFinancialStatement = subcontractorDataConcepts.dateOfFinancialStatement;
    let splitDateOfFinancialStatement = null;
    if (dateOfFinancialStatement) {
      splitDateOfFinancialStatement = dateOfFinancialStatement.split('T')[0]
    }
    const dateOfRenewal = subcontractorDataConcepts.dateOfRenewal;
    let splitDateOfRenewal = null;
    if (dateOfRenewal) {
      splitDateOfRenewal = dateOfRenewal.split('T')[0]
    }
    const dateOfPrequal = subcontractorDataConcepts.dateOfPrequal;
    let splitDateOfPrequal = null;
    if (dateOfPrequal) {
      splitDateOfPrequal = dateOfPrequal.split('T')[0]
    }
    const dateLastModified = subcontractorDataConcepts.dateLastModified;
    let splitDateLastModified = null;
    if (dateLastModified) {
      splitDateLastModified = dateLastModified.split('T')[0]
    }

    let backlogAccount;

    if (Array.isArray(basicAccounts)) {
      backlogAccount = basicAccounts.find(account => account.accountName === 'Backlog' || account.name === 'Backlog');
    } 
    else if (basicAccounts && Array.isArray(basicAccounts.accounts)) {
      backlogAccount = basicAccounts.accounts.find(account => account.accountName === 'Backlog' || account.name === 'Backlog');
    }
  
    return (
      <div ref={this.props.containerRef}>
        {
          !fetchingScoreCardsConcepts ?
            <div className="scorecard">
              {isCovid19Form && (
                <Row>
                  <Col md={12}>
                    <div className="scorecard-mention">
                      Covid Update
                    </div>
                  </Col>
                </Row>
              )}
              <Row className="header">
                <Col md={8}>
                  {this.state.displaySCname}
                </Col>
                <Col md={4}>
                  Tier: {'' || scorecardMainParameters.tieRating}
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <Row>
                    <Col md={3}>
                      Date of Prequalification:
                    </Col>
                    <Col md={3}>
                      {splitDateOfPrequal ? moment(splitDateOfPrequal).format("LL") : ''}
                    </Col>
                    <Col md={3}>
                      Date Last Modified:
                    </Col>
                    <Col md={3}>
                      {splitDateLastModified ? moment(splitDateLastModified).format("LL") : ''}
                    </Col>
                    {[1, 3].includes(scorecardAccountDisplayTypeId)
                      ?
                      <>
                        <Col md={3}>
                          Financial Statement Date:
                        </Col>
                        <Col md={3}>
                          {splitDateOfFinancialStatement ? moment(splitDateOfFinancialStatement).format("LL") : null}
                        </Col>
                        <Col md={3}>
                          Financial Statement Type:
                        </Col>
                        <Col md={3}>
                          {subcontractorDataConcepts.financialStatementType}
                        </Col>
                      </>
                      : null
                    }
                    <Col md={3}>
                      Analysis Type:
                    </Col>
                    <Col md={3}>
                      {subcontractorDataConcepts.analysisType}
                    </Col>
                    <Col md={3}>
                      Renewal Date:
                    </Col>
                    <Col md={3}>
                      {splitDateOfRenewal ? moment(splitDateOfRenewal).format("LL") : null}
                    </Col>
                    <Col md={3}>
                      Main Trade:
                    </Col>
                    <Col md={3}>
                      {'' || subcontractorDataConcepts.tradeName}
                    </Col>
                  </Row>
                </Col>
                <Col md={4}>
                  <Row>
                    <Col md={6}>
                      Single Project Limit:
                    </Col>
                    <Col md={6}>
                      {'' || this.getLimitValue(scorecardMainParameters.singleProjectLimit)}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      Aggregate Project Limit:
                    </Col>
                    <Col md={6}>
                      {'' || this.getLimitValue(scorecardMainParameters.aggregateProjectExposure)}
                    </Col>
                  </Row>
                  {[1, 3].includes(scorecardAccountDisplayTypeId) ?
                    <Row>
                      <Col md={6}>
                        Working Capital (Adj.):
                      </Col>
                      <Col md={6}>
                        $ {adjustedWorkingCapitalResult.toLocaleString()}
                      </Col>
                    </Row>
                    : null
                  }
                  {
                    backlogAccount && [1, 3].includes(scorecardAccountDisplayTypeId)
                    ?
                      <Row>
                        <Col md={6}>
                          Current Backlog:
                        </Col>
                        <Col md={6}>
                        $ {backlogAccount.value.toLocaleString()}
                        </Col>
                      </Row> 
                    : null
                  }
                </Col>
              </Row>
              {
                nevadaSingleLimit && nevadaSingleLimit.value && nevadaSingleLimit.value !== 0
                  ? <Row>
                    <Col md={8}>
                      <Row>
                        <Col md={3}></Col>
                        <Col md={9}></Col>
                      </Row>
                    </Col>
                    <Col md={4}>
                      <Row>
                        <Col md={6}>
                          Nevada Single Limit:
                        </Col>
                        <Col md={6}>
                          {this.getNevadaLimitValue(nevadaSingleLimit.value)}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  : ''
              }

              {[1, 3].includes(scorecardAccountDisplayTypeId) && (
                <Row>
                  <Col md={8}>
                    <Row>
                      <Col md={3}></Col>
                      <Col md={9}></Col>
                    </Row>
                  </Col>

                  <Col md={4}>

                  </Col>
                </Row>
              )}

              {!scorecardHiddenFields.includes(1) && (
                <div className="commentary">
                  <span className="bold-label">Analyst Commentary: </span>
                  <span className="commentary-text">{commentary}</span>
                </div>
              )}

              <Row>
                {!scorecardHiddenFields.includes(2) && (
                  <Col md={4} className="bold-label">
                    Largest Contract Completed
                  </Col>
                )}
                {!scorecardHiddenFields.includes(3) && (
                  <Col md={4} className="bold-label">
                    Total Revenue Earned
                  </Col>
                )}
                {!scorecardHiddenFields.includes(4) && (
                  <Col md={4} className="bold-label">
                    Experience Modification Rate
                  </Col>
                )}
              </Row>

              <Row>
              {!scorecardHiddenFields.includes(2) && (
                <Col md={4}>
                  <Row>
                    <Col md={6}>
                      {this.getLargestContractCompletedDate(0) ||
                        "Most Current Year:"}
                    </Col>
                    <Col md={6} className="concept-value">
                      {"" || this.getLargestContractCompletedValue(0)}
                    </Col>
                  </Row>
                </Col>
              )}
              {!scorecardHiddenFields.includes(3) && (
                <Col md={4}>
                  <Row>
                    <Col md={6}>
                      {this.getTotalRevenueEarnedDate(0) ||
                        " Most Current Year:"}
                    </Col>
                    <Col md={6} className="concept-value">
                      {"" || this.getTotalRevenueEarnedValue(0)}
                    </Col>
                  </Row>
                </Col>
              )}
              {!scorecardHiddenFields.includes(4) && (
                <Col md={4}>
                  <Row>
                    <Col md={6}>
                      {this.getExperienceModificationRateDate(0) ||
                        "Most Current Year:"}
                    </Col>
                    <Col md={6} className="concept-value">
                      {"" || this.getExperienceModificationRateValue(0)}
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>

            <Row>
              {!scorecardHiddenFields.includes(2) && (
                <Col md={4}>
                  <Row>
                    <Col md={6}>
                      {this.getLargestContractCompletedDate(1) || "Year 2:"}
                    </Col>
                    <Col md={6} className="concept-value">
                      {"" || this.getLargestContractCompletedValue(1)}
                    </Col>
                  </Row>
                </Col>
              )}
              {!scorecardHiddenFields.includes(3) && (
                <Col md={4}>
                  <Row>
                    <Col md={6}>
                      {this.getTotalRevenueEarnedDate(1) || " Year 2:"}
                    </Col>
                    <Col md={6} className="concept-value">
                      {"" || this.getTotalRevenueEarnedValue(1)}
                    </Col>
                  </Row>
                </Col>
              )}
              {!scorecardHiddenFields.includes(4) && (
                <Col md={4}>
                  <Row>
                    <Col md={6}>
                      {this.getExperienceModificationRateDate(1) || "Year:"}
                    </Col>
                    <Col md={6} className="concept-value">
                      {"" || this.getExperienceModificationRateValue(1)}
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>

            <Row>
              {!scorecardHiddenFields.includes(2) && (
                <Col md={4}>
                  <Row>
                    <Col md={6}>
                      {this.getLargestContractCompletedDate(2) || "Year 3:"}
                    </Col>
                    <Col md={6} className="concept-value">
                      {"" || this.getLargestContractCompletedValue(2)}
                    </Col>
                  </Row>
                </Col>
              )}
              {!scorecardHiddenFields.includes(3) && (
                <Col md={4}>
                  <Row>
                    <Col md={6}>
                      {this.getTotalRevenueEarnedDate(2) || " Year 3:"}
                    </Col>
                    <Col md={6} className="concept-value">
                      {"" || this.getTotalRevenueEarnedValue(2)}
                    </Col>
                  </Row>
                </Col>
              )}
              {!scorecardHiddenFields.includes(4) && (
                <Col md={4}>
                  <Row>
                    <Col md={6}>
                      {this.getExperienceModificationRateDate(2) || "Year:"}
                    </Col>
                    <Col md={6} className="concept-value">
                      {"" || this.getExperienceModificationRateValue(2)}
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>

              {[1, 3].includes(scorecardAccountDisplayTypeId) && (
                <React.Fragment>
                  <div className="measures">
                    <span className="bold-label">Measures</span>
                  </div>

                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col md={5}>
                          Current Ratio:
                        </Col>
                        <Col md={7}>
                          {'' || this.getScorcardConceptValue(1)}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={5}>
                          AR Turnover:
                        </Col>
                        <Col md={7}>
                          {'' || this.getScorcardConceptValue(4)}
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col md={5}>
                          Working Capital to Backlog:
                        </Col>
                        <Col md={7}>
                          {'' || this.getScorcardConceptValue(2)}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={5}>
                          AP Turnover:
                        </Col>
                        <Col md={7}>
                          {'' || this.getScorcardConceptValue(5)}
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col md={5}>
                          Number of Days Cash:
                        </Col>
                        <Col md={7}>
                          {'' || this.getScorcardConceptValue(3)}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={5}>
                          Debt to Net Worth:
                        </Col>
                        <Col md={7}>
                          {'' || this.getScorcardConceptValue(6)}
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col md={5}>
                          Net Worth to backlog:
                        </Col>
                        <Col md={7}>
                          {'' || this.getScorcardConceptValue(7)}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={5}>
                          Legal:
                        </Col>
                        <Col md={7}>
                          {'' || this.getScorcardConceptValue(10)}
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col md={5}>
                          Profitability:
                        </Col>
                        <Col md={7}>
                          {'' || this.getScorcardConceptValue(8)}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={5}>
                          References:
                        </Col>
                        <Col md={7}>
                          {'' || this.getScorcardConceptValue(11)}
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col md={5}>
                          Credit Line:
                        </Col>
                        <Col md={7}>
                          {'' || this.getScorcardConceptValue(9)}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={5}>
                          Credit History
                        </Col>
                        <Col md={7}>
                          {'' || this.getScorcardConceptValue(12)}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </React.Fragment>
              )}

              {[2, 3].includes(scorecardAccountDisplayTypeId) && (
                <React.Fragment>
                  <div className="measures">
                    <span className="bold-label">Discrete Accounts</span>
                  </div>
                  {this.renderDiscreteAccounts()}
                </React.Fragment>
              )}
            </div>
            :
            <div style={{
              width: '100%',
              height: '300px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <div className="spinner-wrapper">
                <div className="spinner"></div>
              </div>
            </div>
        }
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    local: state.localization,
    scProfile: state.SCProfile,
    financials: state.financials
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    financialActions: bindActionCreators(financialActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScoreCard);