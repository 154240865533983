import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Utils from '../../../../../lib/utils';
import renderField from '../../../../customInputs/renderField';
import renderSelect from '../../../../customInputs/renderSelect';
import renderMultiSelectSearch from '../../../../customInputs/renderMultiSelectSearch';
import renderSelectSearch from '../../../../customInputs/renderSelectSearch';
import FilterActions from '../../../../common/filterActions/FilterActions'
import * as hcActions from '../../../../hiringclients/actions';
import * as notesTasksActions from '../actions';
import { saveFilterApi, deleteFilterApi } from '../api';

class FilterNotesTasks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSnack: false,
      snackMessage: '',
    }
    props.actions.fetchHiringClients({orderBy: 'name', orderDirection:'ASC', withoutPag: true, system: 'pq'});
    this.saveFilter = this.saveFilter.bind(this);
    this.deleteSavedFilter = this.deleteSavedFilter.bind(this);
  }

  saveFilter() {
    saveFilterApi({...this.props.filterValues, ...this.props.formValues}, this.props.filterBoxTag)
      .then(response => {
        this.setState({ showSnack: true, snackMessage: 'Filter saved successfully' });
        this.props.notesTasksActions.setHasSavedFilters(true);
      })
      .catch(error => {
        this.setState({ showSnack: true, snackMessage: 'Error saving filter' });
      });
  }

  deleteSavedFilter() {
    
    deleteFilterApi(this.props.filterBoxTag)
      .then(({ data }) => {
        this.props.notesTasksActions.setHasSavedFilters(false);
        if (data.data) {
          this.setState({ showSnack: true, snackMessage: 'Filters deleted successfully' },() =>{
            this.props.getFilterApi()
          });
        } else {
          this.setState({ showSnack: true, snackMessage: 'No saved Filters' });
        }
      })
      .catch(error => {
        this.setState({ showSnack: true, snackMessage: 'Error deleting filter' });
      });
  }


  handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ showSnack: false });
  }

  render() {
    const {
      title,
      keywordsLabel,
      keywordsPlaceholder,
      assignedToLabel,
      labelRoleAssign,
      assignedToPlaceholder,
      assignedToRolePlaceholder,
      typePlaceholder,
      dueDateLabel,
      enteredDateLabel,
      datePlaceholder,
      statusPlaceholder,
      hiringClientPlaceholder,
      createdBy,
      assignedToMyRoleLabel,
      assignedToMeLabel,
      urgencyPlaceholder,
    } = this.props.local.strings.scProfile.notesTasks.filter;

    const { taskPriorityPossibleValues } = this.props.notesTasks;

    // assigned to options
    const usersList = this.props.notesTasks.users;
    const hiringClientList = this.props.hc.list;
    const rolesPossibleValues = this.props.notesTasks.rolesPossibleValues;
    const createdByList = this.props.notesTasks.createdByPossibleValues;
    const hiringClientOptionsList = Utils.getOptionsList(null, hiringClientList, 'name', 'id', 'name')
    const assignedOptionsList = Utils.getOptionsList(assignedToPlaceholder, usersList, 'name', 'id', 'name');
    const assignRoleOptionsList = Utils.getOptionsList(assignedToRolePlaceholder, rolesPossibleValues, 'name', 'id', 'name');
    const typesOptionsList = Utils.getOptionsList(typePlaceholder, [{ id: 1, name: 'Note' }, { id: 2, name: 'Task' } ], 'name', 'id', 'name');
    const statusOptionsList = Utils.getOptionsList(statusPlaceholder, [{ id: 1, name: 'WIP' }, { id: 2, name: 'Completed' } ], 'name', 'id', 'name');
    const createdByOptionsList = Utils.getOptionsList('', createdByList, 'name', 'id', 'name')
    const urgencyOptionsList = Utils.getOptionsList(null, taskPriorityPossibleValues, 'name', 'id', 'name')
    
    const {handleSubmit} = this.props;

    return (
      <React.Fragment>
        <form
          onSubmit={handleSubmit}
          className="list-view-filter-form"
          style={{
            backgroundColor: 'white',
            display: this.props.visible ? 'block' : 'none',
          }}
        >
          <h2 className="list-view-filter-title">{title}</h2>
          <div className="container-fluid filter-fields">
            <div className="row">
              <div className="col-md-4 col-sm-12 no-padd">
                <div className="admin-form-field-wrapper keywords-field">
                  <label htmlFor="keywords">{keywordsLabel}: </label>
                  <Field
                    name="keywords"
                    type="text"
                    placeholder={`--${keywordsPlaceholder}--`}
                    component={renderField}
                    style={{minHeight: '38px'}}
                    className="tags-input"
                  />
                </div>
              </div>

              {
                !this.props.fromSidebar &&
                <div className="col-md-2 col-sm-12 no-padd" >
                  <div className="admin-form-field-wrapper keywords-field">
                    <label htmlFor="assignedTo">{assignedToLabel}: </label>
                    <div className="select-wrapper">
                      <Field
                        name="assignedTo"
                        component={renderSelect}
                        style={{minHeight: '38px'}}
                        options={assignedOptionsList}/>
                    </div>
                  </div>
                </div>
              }

              {
                !_.isEmpty(rolesPossibleValues) && this.props.fromHCtab &&
                <div className="col-md-2 col-sm-12 no-padd" >
                  <div className="admin-form-field-wrapper keywords-field">
                    <label htmlFor="assignedTo">{labelRoleAssign}: </label>
                    <div className="select-wrapper">
                      <Field
                        name="assignedToRoleId"
                        component={renderSelect}
                        style={{minHeight: '38px'}}
                        options={assignRoleOptionsList} />
                    </div>
                  </div>
                </div>
              }

              {
                this.props.fromSidebar &&
                <div className="col-md-2 col-sm-12 no-padd">
                  <div className="admin-form-field-wrapper keywords-field">
                    <label htmlFor="createdBy">{createdBy}: </label>
                    <div className="select-wrapper">
                      <Field
                        name="createdBy"
                        component={renderSelectSearch}
                        options={createdByOptionsList}
                      />
                    </div>
                  </div>
                </div>
              }

              {
                !this.props.fromSidebar &&
                <div className="col-md-2 col-sm-12 no-padd">
                  <div className="admin-form-field-wrapper keywords-field">
                    <label htmlFor="date">{statusPlaceholder}: </label>
                    <Field
                      name="statusId"
                      component={renderSelect}
                      style={{minHeight: '38px'}}
                      options={statusOptionsList} />
                  </div>
                </div>
              }

              {
                !this.props.fromSidebar &&
                <div className="col-md-2 col-sm-12 no-padd" >
                  <div className="admin-form-field-wrapper keywords-field">
                      <label htmlFor="typeId">{typePlaceholder}: </label>
                      <div className="select-wrapper">
                      <Field
                          name="typeId"
                          component={renderSelect}
                          style={{minHeight: '38px'}}
                          options={typesOptionsList} />
                      </div>
                  </div>
                </div>
              }

              <div className="col-md-2 col-sm-12 no-padd">
                <div className="admin-form-field-wrapper keywords-field">
                  <label htmlFor="date">{enteredDateLabel}: </label>
                  <Field
                    name="enteredDate"
                    type="date"
                    placeholder={`--${datePlaceholder}--`}
                    component={renderField}
                    style={{minHeight: '38px'}}
                    className="tags-input"
                  />
                </div>
              </div>

              <div className="col-md-2 col-sm-12 no-padd">
                <div className="admin-form-field-wrapper keywords-field">
                  <label htmlFor="date">{dueDateLabel}: </label>
                  <Field
                    name="dueDate"
                    type="date"
                    placeholder={`--${datePlaceholder}--`}
                    component={renderField}
                    style={{minHeight: '38px'}}
                    className="tags-input"
                  />
                </div>
              </div>

              {
                this.props.fromSidebar &&
                <>
                  <div className="col-md-2 col-sm-12 no-padd" >
                    <div className="admin-form-field-wrapper keywords-field">
                      <label htmlFor="assignedTo">{hiringClientPlaceholder}: </label>
                      <div className="select-wrapper">
                        <Field
                          name="hiringClient"
                          component={renderMultiSelectSearch}
                          style={{minHeight: '38px'}}
                          options={hiringClientOptionsList}/>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-12 no-padd" >
                    <div className="admin-form-field-wrapper keywords-field">
                      <label htmlFor="assignedTo">{urgencyPlaceholder}: </label>
                      <div className="select-wrapper">
                        <Field
                          name="tasksPriorityId"
                          component={renderMultiSelectSearch}
                          style={{minHeight: '38px'}}
                          options={urgencyOptionsList}/>
                      </div>
                    </div>
                  </div>
                </>
              }

            </div>
            {
              this.props.fromSidebar &&
              <div className="row">
                <div className="col-md-2 col-sm-12 no-padd">
                  <div className="keywords-field filter__checkbox-container">
                    <label className="filter__checkbox-label">{assignedToMyRoleLabel}: </label>
                    <Field
                      name="assignedToMyRole"
                      type="checkbox"
                      component={renderField}
                      className="filter__checkbox"
                    />
                  </div>
                </div>
                <div className="col-md-2 col-sm-12 no-padd">
                  <div className="keywords-field filter__checkbox-container">
                    <label className="filter__checkbox-label">{assignedToMeLabel}: </label>
                    <Field
                      name="assignedToMe"
                      type="checkbox"
                      component={renderField}
                      className="filter__checkbox"
                    />
                  </div>
                </div>
              </div>
            }
            
            <div className="row">
              <FilterActions
                formName={this.props.form}
                dispatch={this.props.dispatch}
                filtersSaveCallback={this.saveFilter}
                filtersDeleteCallback={this.deleteSavedFilter}
                disableDeleteFilters={!this.props.notesTasks.hasSavedFilters}
              />
            </div>
          </div>
        </form>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={this.state.showSnack}
          autoHideDuration={6000}
          onClose={this.handleCloseSnack}
          message={this.state.snackMessage}
          ContentProps={{
            classes: {
              message: 'filter-actions-snackbar-text',
            },
          }}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleCloseSnack}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}

FilterNotesTasks = reduxForm({
  form: 'FilterNotesTasks',
  initialValues: {
    assignedToMyRole: true,
    assignedToMe: true,
  },
})(FilterNotesTasks);

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector('FilterNotesTasks');
  const {
    keywords,
    hiringClient,
    enteredDate,
    dueDate,
    createdBy,
    assignedToMyRole,
    assignedToMe,
    assignedTo,
    assignedToRoleId,
    statusId,
    typeId,
    urgency,
  } = selector(
      state,
      'keywords',
      'hiringClient',
      'enteredDate',
      'dueDate',
      'createdBy',
      'assignedToMyRole',
      'assignedToMe',
      'assignedTo',
      'assignedToRoleId',
      'statusId',
      'typeId',
      'urgency'
    )
  return {
    users: state.users,
    hc: state.hc,
    local: state.localization,
    notesTasks: state.notesTasks,
    formValues: {
      keywords,
      hiringClient,
      enteredDate,
      dueDate,
      createdBy,
      assignedToMyRole,
      assignedToMe,
      assignedTo,
      assignedToRoleId,
      statusId,
      typeId,
      urgency,
    },
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(hcActions, dispatch),
    notesTasksActions: bindActionCreators(notesTasksActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterNotesTasks);
