// sets 
export const SET_PROJECTS_ERROR = 'SET_PROJECTS_ERROR';
export const SET_PROJECTS_LIST = 'SET_PROJECTS_LIST';
export const SET_TOTAL_AMOUNT_OF_PROJECTS = 'SET_TOTAL_AMOUNT_OF_PROJECTS';
export const SET_FETCHING_PROJECTS = 'SET_FETCHING_PROJECTS';
export const SET_CONTRACTS_ERROR = 'SET_CONTRACTS_ERROR';
export const SET_CONTRACTS_LIST = 'SET_CONTRACTS_LIST';
export const SET_TOTAL_AMOUNT_OF_CONTRACTS = 'SET_TOTAL_AMOUNT_OF_CONTRACTS';
export const SET_FETCHING_CONTRACTS = 'SET_FETCHING_CONTRACTS';
export const SET_PROJECT_STATUS = 'SET_PROJECT_STATUS';
export const SET_SHOW_MODAL = 'SET_SHOW_MODAL';
export const SET_ADD_PROJECT_DATA = 'SET_ADD_PROJECT_DATA';
