import React from 'react'

const SpinnerLoader = () => {
    return (
        <div className="spinner-wrapper" style={{ margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: 'fit-content', position: 'relative' }}>
            <div className="spinner" style={{ width: '20px', height: '20px', position: 'relative', borderWidth: '4px'}}></div>
        </div>
    )
}

export default SpinnerLoader