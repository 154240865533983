import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import _, { isArray } from 'lodash';
import classnames from 'classnames';
import moment from 'moment';
import axios from 'axios';
import swal from "sweetalert2";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getWorkFlow } from '../../../hc-profile/tabs/workflow/service';
import Utils from '../../../../lib/utils';
import NoteEditorModal from '../../modals/noteEditor';
import FinancialInfoCopySubmissionModal from './modals/copySubmissionModal/financialInfoCopySubmissionModal';
import FinancialInfoRenewalModal from './modals/renewalModal/financialInfoRenewalModal';
import RolAccess from '../../../common/rolAccess';

import * as financialActions from './actions';
import * as scPorfileActions from '../../actions';
import * as formLinkActions from '../../../formLink/actions'

import './financials.css';
import SCLogs from '../sc-logs';
import * as scLogsAction from '../sc-logs/actions';
import Api from '../../../../lib/api';
import {
  PQ_ADMIN_ROLE_ID,
  PQ_FINANCIAL_ANALYST_ROLE_ID,
  PQ_OPERATOR_ROLE_ID,
  SELF_SERVICE_ADMIN_ROLE_ID,
  SELF_SERVICE_ANALYST_ROLE_ID,
  SELF_SERVICE_OPERATOR_ROLE_ID,
  ROLES,
  MARK_INCOMPLETE_FIN_INFO,
  PQ_FORM_LOG_TYPE,
  PQ_FINANCIAL_INFO_LOG_TYPE,
  FORCE_FINANCIAL_UPDATE_FIN_INFO,
  FORCE_RENEWAL_FIN_INFO
} from '../../../../lib/appConstants';
import CloseIcon from '@material-ui/icons/Close';
import DatePicker from 'react-date-picker';
import * as linkActions from "../../../formLink/actions";

const Alerts = require('../../../alerts');

const TOTAL_LIABILITIES_AND_EQUITY_ID = 10;

class FinancialInfo extends React.Component {

  constructor(props) {
    super(props);
    this.totalEquityEndYear = 0;
    this.showAlerts = this.showAlerts.bind(this);
    this.onScorecardVariablesChange = this.onScorecardVariablesChange.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onParentClicked = this.onParentClicked.bind(this);
    this.onShowTable = this.onShowTable.bind(this);
    this.onModifyAccountValue = this.onModifyAccountValue.bind(this);
    this.onBlurAccountValue = this.onBlurAccountValue.bind(this);
    this.onSaveChanges = this.onSaveChanges.bind(this);
    this.onSaveChangesAndComplete = this.onSaveChangesAndComplete.bind(this);
    this.onCalculateAccounts = this.onCalculateAccounts.bind(this);
    this.getPayload = this.getPayload.bind(this);
    this.renderParentListGroup = this.renderParentListGroup.bind(this);
    this.renderAccountListGroup = this.renderAccountListGroup.bind(this);
    this.renderLiabilitiesListGroup = this.renderLiabilitiesListGroup.bind(this);
    this.renderAccount = this.renderAccount.bind(this);
    this.addAccountRow = this.addAccountRow.bind(this);
    this.onEditNoteTask = this.onEditNoteTask.bind(this);
    this.closeNoteEditor = this.closeNoteEditor.bind(this);
    this.saveAccountNote = this.saveAccountNote.bind(this);
    this.prequalDateChange = this.prequalDateChange.bind(this);
    this.refreshHeader = this.refreshHeader.bind(this);
    this.formatAccountsList = this.formatAccountsList.bind(this);
    this.waiveRequirementsHandler = this.waiveRequirementsHandler.bind(this);

    this.calculateTotalEquityEndYear = this.calculateTotalEquityEndYear.bind(this);
    this.addToTotalEquityEndYear = this.addToTotalEquityEndYear.bind(this);
    this.checkIfShouldShowSaveAndComplete = this.checkIfShouldShowSaveAndComplete.bind(this)
    this.updateShowsaveAndCompleteConditions = this.updateShowsaveAndCompleteConditions.bind(this)
    this.autoSuggestRenewalDate = this.autoSuggestRenewalDate.bind(this);
    this.setDiscreteAccountsSelected = this.setDiscreteAccountsSelected.bind(this);
    this.getScorecardFieldValueByKey = this.getScorecardFieldValueByKey.bind(this);
    this.isFinancialPropertiesEqual = this.isFinancialPropertiesEqual.bind(this);
    this.renderCalculateValues = this.renderCalculateValues.bind(this);


    let scId = ''
    let hcId = ''
    scId = props.scIdFromTabs ? Number(props.scIdFromTabs) : (props.scId ? Number(props.scId) : '')
    hcId = props.scProfile.hcId ? Number(props.scProfile.hcId) : ''

    this.state = {
      waiveRequirementIsEnabled: false,
      scId,
      hcId,
      savedFormId: '',
      accountsList: [],
      accountsListData: {},
      rawAccountsListData: {},
      closedParents: [],
      closedAccordions: [],
      basicAccounts: [],
      discreteAccounts: [],
      finInfoAccountDisplayTypeId: 1,
      discreteAccountsSelected: [],
      avgProjectAccounts: [],
      avgVolumeAccounts: [],
      editedNotes: [],
      scorecardVariables: {},
      visibleHiddenRows: {},
      addAccountRowSelectValues: {},
      selectedAccountForNoteEdition: {},
      pendingChanges: false,
      showNoteEditor: false,
      isCalculatingValue: false,
      calculatedAccountsFetched: false,
      shouldShowSave: false,
      shouldShowSaveAndComplete: false,
      isSaving: false,
      // saveAndCompleteConditions is an array of booleans that need to be true in order for the Save and Complete button to display.
      saveAndCompleteConditions: {
        'dateOfFinancialStatement': false,
        'creditHistoryId': false,
        'legalStatusId': false,
        'referencesStatusId': false,
        'bankLineUsageId': false,
        'turnOverRateId': false,
        'analysisTypeId': false,
        'financialStatementTypeId': false,
        'discreteAccounts': false,
        'companyTypeId': false
      },
      showCopySubmissionModal: false,
      showRenewalModal: false,
      showForceActions: false,
      workflows: [],
      showModalAddForm: false,
      showLoader: false,
      savedFormIsLocked: false,
      dateOfFinancialStatementOpen: false,
      dateOfFinancialStatementPreparedOpen: false,
      dateOfRenewalOpen: false,
      inputStates: {},
      saveBtnLabel: 'Save',
      saveAndCompleteBtnLabel: 'Save & Complete'
    };
    this.financials = React.createRef();
    this.calendarRef = React.createRef();
    this.finalcialStatmentDateCalendarRef = React.createRef();
    this.finalcialStatmentDatePreparedCalendarRef = React.createRef();
  }

  autoSuggestRenewalDate() {
    const {
      turnOverRateId,
      dateOfFinancialStatement,
      dateOfFinancialStatementPrepared,
      financialStatementTypeId,
    } = this.state.accountsListData

    // console.log('turnOverRateId = ', turnOverRateId)
    // console.log('financialStatementTypeId = ', financialStatementTypeId)
    // console.log('dateOfFinancialStatement = ', dateOfFinancialStatement)
    // console.log('dateOfFinancialStatementPrepared = ', dateOfFinancialStatementPrepared)

    if (turnOverRateId && financialStatementTypeId && dateOfFinancialStatement && dateOfFinancialStatementPrepared) {
      // console.log('autoSuggestRenewalDate kicked off')

      const cpaIdsPreparedStatements = [1, 2, 3];
      const internallyPreparedStatements = [4, 5, 6];
      const isCpaPreparedStatement = _.includes(cpaIdsPreparedStatements, Number(financialStatementTypeId));
      const isInternallyPreparedStatements = _.includes(internallyPreparedStatements, Number(financialStatementTypeId));
      const momentFinancialStatement = moment(dateOfFinancialStatement);
      const momentFinancialStatementPrepared = moment(dateOfFinancialStatementPrepared);
      
      let turnOverMonths = ''
      const pSelected = this.props.financials.scorecardTurnOverRatesPossibleValues.find(p => p.id == turnOverRateId);
      if (pSelected) {
        turnOverMonths = pSelected.value / 30;
      }

      // console.log('turnOverMonths = ', turnOverMonths)

      if (!turnOverMonths) return;

      // isLastDayOfYear function
      const isLastDayOfYear = (date) => {
        let isLastDayOfYear = false;

        if (date) {
          const momentDate = moment(date);
          const lastDayOfYear = moment([momentDate.year(), '11', '31']); //11 represents December in moment()

          isLastDayOfYear = momentDate.isSame(lastDayOfYear, 'day'); //check just day, month, and year
        }

        return isLastDayOfYear;
      }

      // isDateAfterLastDayOfMay function
      const isDateAfterLastDayOfMay = (date) => {
        const momentDate = moment(date);
        const lastDayOfMay = moment([momentDate.year(), '04', '31']); //04 represents May in moment()

        return momentDate.isAfter(lastDayOfMay);
      }

      //Logic
      let renewalDate;
      if (turnOverMonths === 12 && isCpaPreparedStatement && isLastDayOfYear(dateOfFinancialStatement)) {
        renewalDate = momentFinancialStatementPrepared.add(12, 'months');
    
        if (isDateAfterLastDayOfMay(renewalDate)) {
          renewalDate = moment(renewalDate).month('04').date('01'); //04 represents May in moment()
        }
      } else if (turnOverMonths === 12 && isInternallyPreparedStatements && isLastDayOfYear(dateOfFinancialStatement)) {
        renewalDate = momentFinancialStatementPrepared.add(14, 'months').toDate();
    
        if (isDateAfterLastDayOfMay(renewalDate)) {
          renewalDate = moment(renewalDate).month('04').date('01'); //04 represents May in moment()
        }
      } else if (turnOverMonths === 12 && isCpaPreparedStatement && !isLastDayOfYear(dateOfFinancialStatement)) {
        renewalDate = momentFinancialStatement.add(12, 'months').toDate();
      } else if (turnOverMonths === 12 && isInternallyPreparedStatements && !isLastDayOfYear(dateOfFinancialStatement)) {
        renewalDate = momentFinancialStatementPrepared.add(12, 'months').toDate();
    
      } else if (turnOverMonths === 9 && isCpaPreparedStatement) {
        renewalDate = momentFinancialStatement.add(7, 'months').toDate();
      } else if (turnOverMonths === 9 && isInternallyPreparedStatements) {
        renewalDate = momentFinancialStatementPrepared.add(11, 'months').toDate();
    
      } else if (turnOverMonths === 6 && isCpaPreparedStatement) {
        renewalDate = momentFinancialStatement.add(8, 'months').toDate();
      } else if (turnOverMonths === 6 && isInternallyPreparedStatements) {
        renewalDate = momentFinancialStatementPrepared.add(11, 'months').toDate();
      // New periods
      } else if (turnOverMonths === 7 && isCpaPreparedStatement) {
        renewalDate = momentFinancialStatement.add(4, 'months').toDate();
      } else if (turnOverMonths === 7 && isInternallyPreparedStatements) {
        renewalDate = momentFinancialStatementPrepared.add(11, 'months').toDate();
    
      } else if (turnOverMonths === 8 && isCpaPreparedStatement) {
        renewalDate = momentFinancialStatement.add(4, 'months').toDate();
      } else if (turnOverMonths === 8 && isInternallyPreparedStatements) {
        renewalDate = momentFinancialStatementPrepared.add(11, 'months').toDate();
    
      } else if (turnOverMonths === 10 && isCpaPreparedStatement) {
        renewalDate = momentFinancialStatement.add(4, 'months').toDate();
      } else if (turnOverMonths === 10 && isInternallyPreparedStatements) {
        renewalDate = momentFinancialStatementPrepared.add(11, 'months').toDate();
    
      } else if (turnOverMonths === 11 && isCpaPreparedStatement) {
        renewalDate = momentFinancialStatement.add(4, 'months').toDate();
      } else if (turnOverMonths === 11 && isInternallyPreparedStatements) {
        renewalDate = momentFinancialStatementPrepared.add(11, 'months').toDate();
      }

      const { accountsListData } = this.state;

      accountsListData['dateOfRenewal'] = renewalDate;

      this.setState({
        accountsListData
      });
    }
  }

  accountMapper = (account) => {
    const accountValues = {
      adjustment: Utils.formatNumberWithCommas(account.adjustment) || 0,
      value: Utils.formatNumberWithCommas(account.value) || 0,
      note: {
        assignedToUserId: this.props.login.profile.Id,
        description: account.noteDescription,
        name: account.noteName,
        taskId: account.noteId || null,
      }
    }

    Object.assign(account, accountValues);
  };

  showToastMessage(isSuccess) {
    const {
      saveSuccessMessage,
      saveErrorMessage
    } = this.props.local.strings.financialInfo.copySubmissionModal;

    const message = isSuccess ? saveSuccessMessage : saveErrorMessage;
    const toastFunction = isSuccess ? toast.success : toast.error;

    toastFunction(message, {
      position: toast.POSITION_TOP_RIGHT,
    });
  }

  formatAccountsList(accountsList) {
    accountsList.forEach(parent => {
      parent.groups.forEach(group => {
        group.accounts.forEach(this.accountMapper);
      });
    });

    return accountsList
  }

  getScorecardFieldValueByKey(key) {
    if((this.state.isSaving || this.state.isCalculatingValue) && this.state.scorecardVariables && this.state.scorecardVariables[key]){
      return this.state.scorecardVariables[key];
    }
    return '';
  }

  isFinancialPropertiesEqual(nextProps) {
    const defaultValueProperties = {
      error: null,
      successMsg: null,
      fetchingAccountsList: false,
      fetchingCalculateAccounts: false,
      fetchingScoreCardsConcepts: false,
      copyFinancialSubmissionFetching: false,
      copyFinancialSubmissionError: '',
    }
    const props = Object.assign({}, this.financials.current, defaultValueProperties)
    const newNextProps = Object.assign({}, nextProps.financials, defaultValueProperties)
    return _.isEqual(props, newNextProps)
  }

  componentWillMount() {
    this.props.actions.setEmptyAccountsList();
    this.props.actions.setFetchingAccountsList(true);
  }

  componentWillReceiveProps(nextProps) {
    // let oldHcId = Number(this.props.scProfile.hcId)
    let oldHcId = this.state.hcId
    let newHcId = Number(nextProps.scProfile.hcId)
    // let oldScId = Number(this.props.scProfile.headerDetails.id)
    let oldScId = this.state.scId
    let newScId = nextProps.scIdFromTabs ? Number(nextProps.scIdFromTabs) : (nextProps.scProfile.headerDetails.id ? Number(nextProps.scProfile.headerDetails.id) : '')

    if (newHcId != oldHcId || newScId != oldScId) {
      if (newHcId && newScId) {
        this.setState({
          scId: newScId,
          hcId: newHcId
        }, () => {
          this.props.actions.fetchAccountsList(newHcId, newScId, null, 'FinInfo CWRP');
          getWorkFlow(newHcId, this.props.login.authToken, (err, data) => {
            this.setState({ workflows: data }, () => this.showForceActions())
          });
        })
      }
    }
    // checking to see if fetching has ended and if nested data is present
    if (!nextProps.financials
      || !nextProps.financials.subcontractorData
      || !nextProps.financials.basicAccounts
      || !nextProps.financials.avgProjectAccounts
      || !nextProps.financials.avgVolumeAccounts
      || !nextProps.financials.basicAccounts.accounts
      || !nextProps.financials.avgProjectAccounts.accounts
      || !nextProps.financials.avgVolumeAccounts.accounts
      || !nextProps.financials.basicAccounts.accounts.length
      || !nextProps.financials.avgProjectAccounts.accounts.length
      || !nextProps.financials.avgVolumeAccounts.accounts.length
      || nextProps.financials.fetchingAccountsList
      || !nextProps.financials.subcontractorData.savedFormId
      || !nextProps.financials.discreteAccounts
      || this.isFinancialPropertiesEqual(nextProps)
    ) {
      return
    }

    this.financials.current = nextProps.financials;
    this.showAlerts(nextProps);

    let {
      accountsList,
      accountsListData,
      scorecardConcetpsList,
      basicAccounts,
      discreteAccounts,
      avgProjectAccounts,
      avgVolumeAccounts,
      subcontractorData,
      finInfoAccountDisplayTypeId,
    } = nextProps.financials;

    const turnOverRateId = accountsListData.turnOverRateId                                      // nextProps var
    const analysisTypeId = accountsListData.analysisTypeId
    const financialStatementTypeId = accountsListData.financialStatementTypeId                                      // nextProps var
    const companyTypeId = accountsListData.companyTypeId
    let bankLineUsage = {};
    let legalStatus = {};
    let referencesStatus = {};
    let creditHistory = {};
    if(scorecardConcetpsList && scorecardConcetpsList.length) {
      scorecardConcetpsList.forEach((concepts) => {
        if (concepts.scorecardConceptId === 9) {
          bankLineUsage = concepts.value;
        } else if (concepts.scorecardConceptId === 10) {
          legalStatus = concepts.value;
        } else if (concepts.scorecardConceptId === 11) {
          referencesStatus = concepts.value;
        } else if (concepts.scorecardConceptId === 12) {
          creditHistory = concepts.value;
        }
      });
    }
    
    // const {
    //   bankLineUsageId,
    //   legalStatusId,
    //   referencesStatusId,
    //   creditHistoryId
    // } = this.state.scorecardVariables

    const date = subcontractorData.dateOfFinancialStatement              // nextProps var
    const datePrep = subcontractorData.dateOfFinancialStatementPrepared  // nextProps var
    const dateRenew = subcontractorData.dateOfRenewal                    // nextProps var
    const datePrequal = subcontractorData.dateOfPrequal                  // nextProps var
    const savedFormId = subcontractorData.savedFormId                    // nextProps var

    accountsListData['dateOfFinancialStatement'] = date                  // nextProps var
    accountsListData['dateOfFinancialStatementPrepared'] = datePrep      // nextProps var
    accountsListData['dateOfRenewal'] = dateRenew                        // nextProps var
    accountsListData['dateOfPrequal'] = datePrequal                      // nextProps var

    const BLUID = bankLineUsage
      && bankLineUsage.id
      ? bankLineUsage.id
      : this.getScorecardFieldValueByKey("bankLineUsageId");

    const LSID = legalStatus
      && legalStatus.id
      ? legalStatus.id
      : this.getScorecardFieldValueByKey("legalStatusId");

    const RSID = referencesStatus
      && referencesStatus.id
      ? referencesStatus.id
      : this.getScorecardFieldValueByKey("referencesStatusId");

    const CHID = creditHistory
      && creditHistory.id
      ? creditHistory.id
      :  this.getScorecardFieldValueByKey("creditHistoryId");

    const scorecardVariables = {
      bankLineUsageId: BLUID,
      legalStatusId: LSID,
      referencesStatusId: RSID,
      creditHistoryId: CHID
    }

    //formating values

    accountsList = this.formatAccountsList(accountsList)     // nextProps var
    basicAccounts.accounts.forEach(this.accountMapper);      // nextProps var
    avgProjectAccounts.accounts.forEach(this.accountMapper); // nextProps var
    avgVolumeAccounts.accounts.forEach(this.accountMapper);  // nextProps var

    if ((Number(this.state.savedFormId) != Number(savedFormId)) && accountsList.length !== 0 && basicAccounts && basicAccounts.accounts.length !== 0) {
      let data = {};
      let subcontractorData = {};

      subcontractorData.companyTypeId = accountsListData.companyTypeId

      data.savedFormId = savedFormId
      // data.basicAccounts = basicAccounts.accounts.map(this.basicAccountMapper);
      data.subcontractorData = subcontractorData
      data = this.addValuesToAccountsList(data, accountsList)
      this.props.actions.fetchWorkingCapital({ data })
    }

    const isFinNotComplete = accountsListData.finIsComplete !== 1;
    // Discrete accounts
    let discreteAccountsSelected = discreteAccounts.map(element => {
      const valueIsNotInOptions = (isArray(element.options) && element.options.indexOf(element.valueSeleted) < 0);
      let colorSelected = (isFinNotComplete && valueIsNotInOptions) ? 0 : element.colorSelected;
      if (isFinNotComplete && !valueIsNotInOptions) {
        const idx = element.options.indexOf(element.valueSeleted);
        if (element.colors[idx] != element.colorSelected) {
          colorSelected = element.colors[idx];
        }
      } 

      return {
        accountId: element.id,
        name: element.name,
        valueSelected: (isFinNotComplete && valueIsNotInOptions) ? '0' : element.valueSeleted,
        colorSelected: colorSelected,
        options: element.options,
        colors: element.colors,
        note: element.note,
        discreteValueId: element.discreteValueId,
      };
    });

    this.setState({
      savedFormId,        // set to prioritize nextProps
      accountsList,       // set to prioritize nextProps
      accountsListData,   // set to prioritize nextProps
      scorecardVariables, // updated to prioritize nextProps
      basicAccounts,
      discreteAccounts,      // set to prioritize nextProps
      avgProjectAccounts, // set to prioritize nextProps
      avgVolumeAccounts,   // set to prioritize nextProps
      discreteAccountsSelected: [ ...discreteAccountsSelected ],
      finInfoAccountDisplayTypeId: finInfoAccountDisplayTypeId,
      rawAccountsListData :accountsListData
    }, () => {
      this.updateShowsaveAndCompleteConditions('dateOfFinancialStatement', date);
      this.updateShowsaveAndCompleteConditions('bankLineUsageId', this.state.scorecardVariables.bankLineUsageId);
      this.updateShowsaveAndCompleteConditions('legalStatusId', this.state.scorecardVariables.legalStatusId);
      this.updateShowsaveAndCompleteConditions('referencesStatusId', this.state.scorecardVariables.referencesStatusId);
      this.updateShowsaveAndCompleteConditions('creditHistoryId', this.state.scorecardVariables.creditHistoryId);
      this.updateShowsaveAndCompleteConditions('turnOverRateId', turnOverRateId);
      this.updateShowsaveAndCompleteConditions('analysisTypeId', analysisTypeId);
      this.updateShowsaveAndCompleteConditions('financialStatementTypeId', financialStatementTypeId);
      this.updateShowsaveAndCompleteConditions('discreteAccounts', discreteAccountsSelected);
      this.updateShowsaveAndCompleteConditions('companyTypeId', companyTypeId);
    });

  }

  componentDidMount() {
    let scId = this.props.scIdFromTabs ? Number(this.props.scIdFromTabs) : (this.props.scId ? Number(this.props.scId) : '')
    let hcId = this.props.scProfile.hcId ? Number(this.props.scProfile.hcId) : ''
    if (hcId && scId) {
      this.props.actions.fetchAccountsList(hcId, scId, null, 'FinInfo constructor');
      getWorkFlow(hcId, this.props.login.authToken, (err, data) => {
        this.setState({ workflows: data }, () => this.showForceActions())
      });
    }
  }

  componentDidUpdate() {
    const el = document.getElementById('financial-info-page');
    const canInputFinInfo = this.props.login.rolesAccessPermissions
      .filter(el => el.MasterTab === 'financial_info' && el.SectionTab === 'input').length > 0;
  
    if (el && (!canInputFinInfo || this.state.accountsListData.finIsComplete === 1 || this.state.savedFormIsLocked)) {
      const inputs = el.getElementsByTagName('input');
      const selects = el.getElementsByTagName('select');
      const textArea = el.getElementsByTagName('textarea');
  
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].disabled = true;
      }
  
      for (let j = 0; j < selects.length; j++) {
        selects[j].disabled = (selects[j].className !== 'sciInput addAccountSelect' && selects[j].className !== 'sciInput changeSubmission');
      }
  
      textArea[0].disabled = true;
    } else {    
      // Explicitly enable all fields
      if(el) {
        const inputs = el.getElementsByTagName('input');
        const selects = el.getElementsByTagName('select');
        const textArea = el.getElementsByTagName('textarea');
    
        for (let i = 0; i < inputs.length; i++) {
          inputs[i].disabled = false;
        }
    
        for (let j = 0; j < selects.length; j++) {
          selects[j].disabled = false;
        }
    
        if (textArea[0]) {
          textArea[0].disabled = false;
        }
      }
    }
    
  }

  setDiscreteAccountsSelected(event, accountIdSelected, accountName) {
    let discreteAccounts = [];

    const value = event.target.value;

    if (value && accountIdSelected) {
      discreteAccounts = this.state.discreteAccountsSelected.map((element) => {
        if (element.accountId == accountIdSelected) {
          element.valueSelected = value.trim();
          const idx = element.options.indexOf(value);
          if (idx > -1 && element.colors.length === element.options.length) {
            element.colorSelected = element.colors[idx];
          }
        }
        
        return element;
      });

      this.setState({
        discreteAccountsSelected: [...discreteAccounts],
      }, () => {
        this.updateShowsaveAndCompleteConditions('discreteAccounts', [...discreteAccounts]);
      });
    }
  }
  waiveRequirementsHandler() {
    this.setState({ shouldShowSaveAndComplete: true, waiveRequirementIsEnabled: true })
  }
  async refreshHeader(isSuccess) {
    const {
      hiringClientId,
      subcontractorId
    } = this.state.accountsListData;
    const { savedFormId } = this.state
    try {
      await this.props.scPorfileActions.fetchHeaderDetails(subcontractorId, hiringClientId, 'financialInfo tab refreshHeader');
      await this.props.actions.fetchAccountsList(hiringClientId, subcontractorId, savedFormId, 'FinInfo refresh header');
      await this.props.actions.fetchSubmissionDates(hiringClientId, subcontractorId, `FinInfo refresh header`)
      await this.props.actions.fetchScoreCardsConcepts(savedFormId, 'ScoreCards CWRP');
    } catch (error) {
      console.log(error);
    }
    this.setState({ saveBtnLabel: 'Saved' })
    setTimeout(() => {
      this.setState({ isSaving:false, saveBtnLabel: 'Save' })
    }, 1000)
  }

  showAlerts(nextProps) {
    if (this.props.financials.fetchingAccountsList && !nextProps.financials.fetchingAccountsList) {
      if (nextProps.financials.error) {
        Alerts.showInformationAlert(
          'Error',
          nextProps.financials.error,
          'Accept',
          false,
          () => { }
        );
        this.props.actions.setError(null);
        this.props.actions.setSuccessMsg(null);
      } else if (nextProps.financials.successMsg) {
        this.props.actions.setError(null);
        this.props.actions.setSuccessMsg(null);
        this.setState({ pendingChanges: false })
      }
    }
  }


  prequalDateChange(event) {
    const value = event.target.value;
    // this.setState({showDiscreteAccountLoader: true});
    if (value >= 0) {
      this.setState({
        savedFormId: value,
        calculatedAccountsFetched: false,
        isCalculatingValue: false,
      }, () => {
        if(value === 0) {
          this.setState({
            savedFormIsLocked : false
          })
        } else {
          this.props.formLinkActions.getSavedFormForFinInfoTab(this.state.savedFormId, (data) => {
            if(data && data.data && data.data.savedForms[0] && data.data.savedForms[0].isLocked != null && data.data.savedForms[0].isLocked == 1 && data.data.savedForms[0].FinIsComplete == 1) {
              this.setState({
                savedFormIsLocked : true
              })
            } else {
              this.setState({
                savedFormIsLocked : false
              })
            }
          })
        }
        this.props.actions.fetchAccountsList(null, null, value, 'line 279');
      })
    }
  }
  

  onScorecardVariablesChange(e, fieldName) {
    const { scorecardVariables } = this.state;
    const val = e.target.value;
    const newScorecardVariables = Object.assign({}, scorecardVariables);
    newScorecardVariables[fieldName] = val;
    this.updateShowsaveAndCompleteConditions(fieldName, val)
    this.setState({ scorecardVariables: newScorecardVariables, pendingChanges: true });
  }

  onSelectChange(e, fieldName) {
    const { accountsListData } = this.state;
    const val = e.target.value;
    accountsListData[fieldName] = val;
    this.updateShowsaveAndCompleteConditions(fieldName, val);
    this.setState({
      accountsListData,
      pendingChanges: true
    }, () => {
    });
  }
  removeDate = (fieldName) => {
    let cloneAccountListData = {...this.state.accountsListData};
    cloneAccountListData[fieldName] = null;
    this.setState({
      accountsListData: cloneAccountListData,
      pendingChanges: true
    },() => {
      if(fieldName === "dateOfFinancialStatement"){
        this.updateShowsaveAndCompleteConditions('dateOfFinancialStatement', null);
      }
    })
  }
  calendarEvent = (e,fieldName) => {
    const {accountsListData} = this.state;
    const numericValue = [0,1,2,3,4,5,6,7,8,9];
    let str = e.target.value;
    let keyCode = e.keyCode || e.which;
    if (keyCode >= 96 && keyCode <= 105) {
      keyCode -= 48;
    }
    if(keyCode === 8 && e.target.name === "year"){
      e.preventDefault();
      document.getElementsByClassName('react-date-picker--open')[0].querySelector("[name='year']").value = ""
    }else if(e.target.name === "year" && keyCode !== 38 && keyCode !== 40){
      e.preventDefault();
      let keyValue = String.fromCharCode(keyCode);
      let isValueFind = numericValue.findIndex(x => x == keyValue);
      if(isValueFind > -1){
        if(str.length == 4){
          document.getElementsByClassName('react-date-picker--open')[0].querySelector("[name='year']").value = str
        }
        else if(str.length < 4){
          str += keyValue;
          document.getElementsByClassName('react-date-picker--open')[0].querySelector("[name='year']").value =str
          if(str.length === 4){
            let month = document.getElementsByClassName('react-date-picker--open')[0].querySelector("[name='month']").value;
            let day = document.getElementsByClassName('react-date-picker--open')[0].querySelector("[name='day']").value;
            let d = moment(month + "/" + day +"/"+ str);
            if(d.isValid()){
              const dateObj = moment(d).format("YYYY-MM-DD");
              accountsListData[fieldName] = dateObj;
              this.setState({
                accountsListData,
                pendingChanges: true
              })
            }
          }
        }
      }

    }
  }
  onDateChange(value, fieldName) {
    const momentDate = moment(value);  
    const val = moment(momentDate).format("MM/DD/YYYY")  
    const { accountsListData } = this.state;
    if (momentDate.isValid()) {
      const dObj = new Date(val);
      const y = dObj.getFullYear();
      const m = dObj.getMonth();
      const d = dObj.getDate();
      const date = new Date(y, m, d, 0, 0, 0, 0);
      if(fieldName === 'dateOfFinancialStatement'){
        accountsListData[fieldName] = date.toISOString();
        this.updateShowsaveAndCompleteConditions('dateOfFinancialStatement', val);
      }
      else if (fieldName === 'dateOfRenewal') {
        accountsListData[fieldName] = date.toISOString();
      }
      else {
        accountsListData[fieldName] = date.toISOString();
      }
      this.setState({
        accountsListData,
        pendingChanges: true
      }, () => {
      });
    }
    else if(value === null){
      accountsListData[fieldName] = null;
      this.setState({
        accountsListData,
        pendingChanges: true
      })
    }
    else{
      accountsListData[fieldName] = value;
      this.setState({
        accountsListData,
        pendingChanges: true
      })
    }
  }


  checkIsDateValid = () => {
    const { accountsListData } = this.state;
    const { rawAccountsListData } = this.state;
    if(this.state.accountsListData['dateOfRenewal'] != ""){
      let localDate = moment(this.state.accountsListData['dateOfRenewal']);
      localDate.set({hour:0,minute:0,second:0,millisecond:0})
      let dateOfRenewal = moment(localDate).toDate()
      let checkedDate = this.validateRenewalDate(dateOfRenewal);
      if(checkedDate && checkedDate.isValid){
        accountsListData['dateOfRenewal'] = checkedDate.date.toISOString();
      }else{
        accountsListData['dateOfRenewal'] = null;
      }
      this.setState({
        accountsListData,
        pendingChanges: true
      })
    } 
  }

  IsRenewalDateValid = () => {
    const { accountsListData } = this.state;
    let checkedDate = { isValid: false };

    if(accountsListData['dateOfRenewal'] != ""){
      let accListFormattedDate = Utils.getFormattedDateSmall(accountsListData['dateOfRenewal']);
      let localDate = moment(accListFormattedDate);
      localDate.set({hour:0,minute:0,second:0,millisecond:0})
      let dateOfRenewal = moment(localDate).toDate()
      checkedDate = this.validateRenewalDate(dateOfRenewal);
    }

    return checkedDate.isValid
  }

  validateRenewalDate(date) {
    let d = new Date();
    d.setHours(0,0,0,0);

    if (d >= date) {
      Alerts.showRenewalDateErrorAlert('Error', 'The Renewal Date must be greater than today.');
      d.setHours(18,59,59,0);
      return {isValid : false ,date : d};
    }
    return {isValid : true ,date : date};
  }

  onParentClicked(parentId) {
    const closedParents = this.state.closedParents;
    const index = closedParents.indexOf(parentId);
    if (index !== -1) {
      closedParents.splice(index, 1);
    } else {
      closedParents.push(parentId);
    }
    this.setState({ closedParents });
  }

  onShowTable(groupId) {

    const closedAccordions = this.state.closedAccordions;
    const index = closedAccordions.indexOf(groupId);
    if (index !== -1) {
      closedAccordions.splice(index, 1);
    } else {
      closedAccordions.push(groupId);
    }
    this.setState({ closedAccordions });
  }

  onModifyAccountValue(value, account, fieldName) {
    const {
      accountsList,
      basicAccounts,
      avgProjectAccounts,
      avgVolumeAccounts
    } = this.state;

    let basicAccountIndex = _.findIndex(basicAccounts.accounts, { accountId: account.accountId });
    let avgProjectAccountsIndex = -1;
    let avgVolumeAccountsIndex = -1;

    if (basicAccountIndex !== -1) {
      basicAccounts.accounts[basicAccountIndex][fieldName] = value
      this.setState({ basicAccounts, pendingChanges: true });

      return;
    } else {
      avgProjectAccountsIndex = _.findIndex(avgProjectAccounts.accounts, { accountId: account.accountId });
    }

    if (avgProjectAccountsIndex !== -1) {
      avgProjectAccounts.accounts[avgProjectAccountsIndex][fieldName] = value
      this.setState({ avgProjectAccounts, pendingChanges: true });

      return;
    } else {
      avgVolumeAccountsIndex = _.findIndex(avgVolumeAccounts.accounts, { accountId: account.accountId });
    }

    if (avgVolumeAccountsIndex !== -1) {
      avgVolumeAccounts.accounts[avgVolumeAccountsIndex][fieldName] = value
      this.setState({ avgVolumeAccounts, pendingChanges: true });

      return;
    } else {
      const parentIdx = _.findIndex(accountsList, (o) => {
        return o.id.toString() === account.parentGroupId.toString()
      });

      const groupIdx = _.findIndex(accountsList[parentIdx].groups, (o) => {
        return o.id.toString() === account.groupId.toString()
      });

      const accountIdx = _.findIndex(accountsList[parentIdx].groups[groupIdx].accounts, (o) => {
        return o.accountId.toString() === account.accountId.toString()
      });

      accountsList[parentIdx].groups[groupIdx].accounts[accountIdx][fieldName] = value;
      this.setState({ accountsList, pendingChanges: true });
    }
  }

  onBlurAccountValue(value, account, fieldName) {
    value = Utils.formatNumberWithCommas(value);
    this.onModifyAccountValue(value, account, fieldName);
  }

  onSaveChangesAndComplete() {
    const { isComplete } = this.props.financials.subcontractorData
    const { title , message } = this.props.local.strings.scProfile.financialInfo.alerts.formIncomplete
    const { hiringClientId: scHiringClientId, subcontractorId }= this.state.accountsListData;
    
    // Validate form is completed 
    if (!isComplete) { 
      Alerts.showRenewalDateErrorAlert(title, message);
      return;
    }
    // Validate RenewalDate     
    const isValidRenewalDate = this.IsRenewalDateValid();
    if (!isValidRenewalDate) { return; }

    if (!this.state.accountsListData.dateOfRenewal) {
      Alerts.showRenewalDateErrorAlert('Error', 'Please enter Renewal Date.');
    }
    else {
      this.props.actions.setSavedFormAsCompleted();
      this.onSaveChanges(null, 1);
    // Check for hardcoded SC ID for local testing purposes. Eg. 11900
    // TODO: A microservice is needed in the gateway to control client configurations
    /* Since there is no identifier for turner beyond hiring client level. 
    This is required
    Update: We moved Turner to sandbox, now we have no way identifying if it's turner or not.
    Need a different solution soon.
    Update 2: We decided to check for parent HC for the current HC and send the mail to configured people,
    follow `sendFormSubmissionNotification` endpoint for implementation details
    */

    Api.get(
      `forms/savedforms?savedFormId=${this.state.savedFormId}`,
      this.props.login.authToken
    )
      .then(({ data: savedFormData }) => {
        const formioSubmissionId = savedFormData.data.savedForms[0].formioSubmissionId;
        if (!formioSubmissionId) {
          return false;
        }
        const formData = new FormData();
        formData.append(
          "message",
          `A new prequal record was completed for hiringClientId: ${scHiringClientId}. Submission Id: ${formioSubmissionId}\nSubcontractor ID: ${subcontractorId}`
        );
        formData.append(
          "subject",
          `A new prequal record was completed for hiringClientId: ${scHiringClientId}. Submission Id: ${formioSubmissionId}`
        );
        formData.append(
          "hiringClientId", scHiringClientId
        );
        return axios({
          method: 'post',
          url: process.env.REACT_APP_URL_API + "sendFormSubmissionNotification",
          data: formData,
        });
      })
      .then((response) => {
        if(response) {
          const { success, notificationSent } = response.data;
          if (success && notificationSent) {
            swal({
              type: "success",
              title: "Notification sent to Hiring Client",
            });
          }
        }
      })
      .catch(err => {
        swal({
          type: "error",
          title: "Error",
          text: "There was a problem with Prequalification or Hiring Client was not notified"
        });
      });

    }
  }

  onSaveChanges(event, completeForm) {
    if(completeForm) {
      this.setState({ isSaving: true, saveAndCompleteBtnLabel: 'Saving & Completing' });
    } else {
      this.setState({ isSaving: true, saveBtnLabel: 'Saving' });
    }

    const payload = this.getPayload(completeForm);
    payload.update = true;


    // Notify Backend to Send Prequalification Auto-Notification Email to HC Requestors where hcOption = 1 (Currently only CRB)
    if (completeForm && this.props.scProfile && this.props.scProfile.headerDetails && this.props.scProfile.headerDetails.hcOption && this.props.scProfile.headerDetails.hcOption == 1) {
      const {
        hcOption,
        emailToHCactivityId,
        requestorName,
        requestorEmail,
        emailToHCSubject,
        emailToHCBody,
        emailToHCFromAddress,
        name,
        id,
        emailToHCtemplateId
      } = this.props.scProfile.headerDetails


      payload.hcOption = hcOption
      payload.emailToHCactivityId = emailToHCactivityId
      payload.emailParams = {
        subject: `${emailToHCSubject}: ${name}`,
        body: emailToHCBody,
        hiringClientId: this.state.hcId,
        subcontractorId: id,
        subcontractorName: name,
        isRequestorEmail: true,
        requestorName: requestorName,
        requestorEmail,
        emailToHCFromAddress,
        templateId: emailToHCtemplateId
      }
    }

    this.props.actions.saveAccountsList({ data: payload }, (isSuccess) => {
      this.setState({visibleHiddenRows: {}});
      setTimeout(() => this.refreshHeader(isSuccess), 2000);
    });
  }

  onCalculateAccounts() {
    const payload = this.getPayload();
    payload.update = false;

    this.setState({
      calculatedAccountsFetched: true,
      isCalculatingValue: true
    });

    this.props.actions.fetchCalculatedAccounts({ data: payload }, () => {
      this.setState({
        isCalculatingValue: false
      });
    });

  }

  addValuesToAccountsList = (payload, accountsList) => {

    payload.accountsList = [];
    accountsList.forEach(parent => {
      parent.groups.forEach(group => {
        group.accounts.forEach(account => {
          const note = this.state.editedNotes[account.accountId] || (account.note.name ? account.note : null);

          payload.accountsList.push({
            accountId: account.accountId,
            value: Utils.normalizeNumber(account.value) || 0,
            adjustment: Utils.normalizeNumber(account.adjustment) || 0,
            note: note
          });
        });
      });
    });

    return payload

  }

  basicAccountMapper = (account) => {
    return {
      ...account,
      value: Utils.normalizeNumber(account.value),
      account: Utils.normalizeNumber(account.adjustment),
      note: this.state.editedNotes[account.id] || account.note
    }
  }

  basicDiscreteAccountMapper = (account) => {
    let note = this.state.editedNotes[account.accountId];
    return {
      ...account,
      note: note
    }
  }

  getPayload(completeForm) {
    const {
      accountsListData,
      accountsList,
      scorecardVariables,
      finInfoAccountDisplayTypeId,
    } = this.state;

    let payload = {};
    let currentTime = new Date().toISOString();
  

    //Subcontractor Data
    payload.subcontractorData = {
      savedFormId: this.state.savedFormId,
      hiringClientId: accountsListData.hiringClientId,
      subcontractorId: accountsListData.subcontractorId,
      dateOfPrequal: accountsListData.dateOfPrequal,
      dateOfFinancialStatement: accountsListData.dateOfFinancialStatement,
      dateOfFinancialStatementPrepared: accountsListData.dateOfFinancialStatementPrepared,
      scorecardSourceId: accountsListData.scorecardSourceId,
      companyTypeId: accountsListData.companyTypeId,
      turnOverRateId: accountsListData.turnOverRateId,
      typeOfRenewal: accountsListData.typeOfRenewal,
      dateOfRenewal: accountsListData.dateOfRenewal,
      analysisTypeId: accountsListData.analysisTypeId,
      financialStatementTypeId: _.toInteger(accountsListData.financialStatementTypeId),
      commentary: accountsListData.commentary,
      finIsComplete: completeForm,
      dateLastModified : currentTime
    };

    //AccountList
    payload = this.addValuesToAccountsList(payload, accountsList)

    //Scorecard Variables
    const {
      referencesPossibleValues,
      legalPossibleValues,
      creditHistoryPossibleValues,
      bankLinePossibleValues
    } = this.props.financials;


    const bankLine = _.find(bankLinePossibleValues, { id: _.toInteger(scorecardVariables.bankLineUsageId) });
    const legal = _.find(legalPossibleValues, { id: _.toInteger(scorecardVariables.legalStatusId) });
    const references = _.find(referencesPossibleValues, { id: _.toInteger(scorecardVariables.referencesStatusId) });
    const creditHistory = _.find(creditHistoryPossibleValues, { id: _.toInteger(scorecardVariables.creditHistoryId) });

    payload.scorecardConcetpsList = []

    if (bankLine) {
      payload.scorecardConcetpsList.push({
        scorecardConceptId: '9',
        id: bankLine.id,
        value: bankLine.name,
        color: bankLine.color
      });
    }

    if (legal) {
      payload.scorecardConcetpsList.push({
        scorecardConceptId: '10',
        id: legal.id,
        value: legal.name,
        color: legal.color
      });
    }

    if (references) {
      payload.scorecardConcetpsList.push({
        scorecardConceptId: '11',
        id: references.id,
        value: references.name,
        color: references.color
      });
    }

    if (creditHistory) {
      payload.scorecardConcetpsList.push({
        scorecardConceptId: '12',
        id: creditHistory.id,
        value: creditHistory.name,
        color: creditHistory.color
      });
    }

    //Save Account Mapper

    //Basic Accounts
    payload.basicAccounts = (this.state.basicAccounts.accounts || []).map(this.basicAccountMapper);

    //Average Project
    payload.avgProjectAccounts = (this.state.avgProjectAccounts.accounts || []).map(this.basicAccountMapper);;

    //Average Volume
    payload.avgVolumeAccounts = (this.state.avgVolumeAccounts.accounts || []).map(this.basicAccountMapper);;

    //Discrete Accounts
    payload.discreteAccounts = this.state.discreteAccountsSelected;
    payload.noteDiscreteAccount = this.state.editedNotes.filter(x => x.typeAccount == 'discreteAccount');

    //Account Display Type
    payload.accountDisplayTypeId = finInfoAccountDisplayTypeId;

    return payload;
  }

  getParentTotals(parent) {
    const groups = parent.groups || [];
    let totalValue = 0;
    let totalAdjustment = 0;
    let totalAdjusted;

    for (var i = 0; i < groups.length; i++) {
      const { value, adjustment } = this.getGroupTotals(groups[i]);
      totalValue += Utils.normalizeNumber(value);
      totalAdjustment += Utils.normalizeNumber(adjustment);
    }

    totalAdjusted = Utils.formatNumberWithCommas((totalValue + totalAdjustment));
    totalValue = Utils.formatNumberWithCommas(totalValue);
    totalAdjustment = Utils.formatNumberWithCommas(totalAdjustment);

    return { value: totalValue, adjustment: totalAdjustment, adjusted: totalAdjusted }
  }

  getWorkingCapitalAdjustment() {
    let workingCapitalAdjustment = 0;
    let distributionsAccount = _.find(this.state.basicAccounts.accounts, { 'accountName': 'Distributions' });
    let netIncomeAccount = _.find(this.state.basicAccounts.accounts, { 'accountName': 'Net Income' });
    let revenueAccount = _.find(this.state.basicAccounts.accounts, { 'accountName': 'Revenue' });

    let distributions = Utils.normalizeNumber(distributionsAccount.value) + Utils.normalizeNumber(distributionsAccount.adjustment);
    let netIncome = Utils.normalizeNumber(netIncomeAccount.value) + Utils.normalizeNumber(netIncomeAccount.adjustment);

    if (this.props.financials.subcontractorData.companyTypeId === 2) {
      let totalRevenue = Utils.normalizeNumber(revenueAccount.value);

      // IF Net Income < 0
      if (netIncome < 0) {
        workingCapitalAdjustment = 0;
      }
      else {
        if (totalRevenue >= 10000000) {
          if (distributions >= 0.25 * netIncome) {
            workingCapitalAdjustment = 0;
          }
          else {
            workingCapitalAdjustment = (0.25 * netIncome) - distributions;
          }
        }
        else {
          if (distributions >= 0.125 * netIncome) {
            workingCapitalAdjustment = 0;
          }
          else {
            workingCapitalAdjustment = (0.125 * netIncome) - distributions;
          }
        }
      }
    }

    return workingCapitalAdjustment;
  }

  formatAccountName(name) {

    return name.toLowerCase().trim().replace(/ /g, '')

  }

  addToTotalEquityEndYear(account) {

    const accountsToSum = [
      "advancestoaffiliates",
      "advancestostockholders-currentportion",
      "duefromaffiliates/advancestoaffiliates",
      "duefromaffiliates-net",
      "duefromemployees/advancestoempoloyees",
      "duefromrelatedparties-net",
      "duefromrelatedparties/advancestorelatedparties",
      "duefromstockholdersandpartners/advancestostockholdersandpartners",
      "equityinaffiliatedcompanies",
      "equityinjointventures",
      "investmentinaffiliatedcompanies",
      "investmentinjointventures",
      "duefromshareholders/members/owners/partners-net",
      "goodwill",
      "tradename"
    ]

    //if(this.formatAccountName(account.accountName) === accountsToSum[0]) this.totalEquityEndYear = 0;

    if (accountsToSum.indexOf(this.formatAccountName(account.accountName)) > -1) {

      this.totalEquityEndYear += Utils.normalizeNumber(account.adjustment) || 0;

    }

  }

  calculateTotalEquityEndYear() {
    this.totalEquityEndYear = 0;
    const { accountsList, basicAccounts, avgProjectAccounts, avgVolumeAccounts } = this.state;

    accountsList.forEach(parent => {
      parent.groups.forEach(group => {
        group.accounts.forEach(account => {
          this.addToTotalEquityEndYear(account);
        });
      });
    });

    if (basicAccounts.accounts) {
      basicAccounts.accounts.forEach(account => {
        this.addToTotalEquityEndYear(account);
      });
    }

    if (avgProjectAccounts.accounts) {
      avgProjectAccounts.accounts.forEach(account => {
        this.addToTotalEquityEndYear(account);
      });
    }

    if (avgVolumeAccounts.accounts) {
      avgVolumeAccounts.accounts.forEach(account => {
        this.addToTotalEquityEndYear(account);
      });
    }
  }

  getGroupTotals(group) {
    const accounts = group.accounts || [];
    let value = 0;
    let adjustment = 0;
    let adjusted;
    let elements = 0;

    for (var i = 0; i < accounts.length; i++) {
      if (accounts[i].accountName === "Total Equity at the End of the Year") {
        accounts[i].adjustment = this.totalEquityEndYear
      }
      //this.calculateTotalEquityEndYear(accounts[i])
      const actualValue = Utils.normalizeNumber(accounts[i].value) || 0;
      value += actualValue;
      adjustment += Utils.normalizeNumber(accounts[i].adjustment) || 0;

      if (group.totalType === 'average' && actualValue > 0) {
        elements += 1;
      }
    }

    if (group.totalType === 'average') {
      value = value / elements;
      value = Math.trunc(value);
    }

    if (group.name === "Current Liabilities") {
      adjustment += this.getWorkingCapitalAdjustment();
    }

    adjusted = Utils.formatNumberWithCommas((value + adjustment));
    value = Utils.formatNumberWithCommas(value);
    adjustment = Utils.formatNumberWithCommas(adjustment);

    return { value, adjustment, adjusted }
  }

  renderGroupTotals(parent, totalLabel) {
    const { value, adjustment, adjusted } = this.getParentTotals(parent);

    // filter possible additional Total keyword from label
    const label = !totalLabel ? parent.name : totalLabel;
    const filteredLabel = `${label}`.toLowerCase().includes('total') ? label : `Total ${label}`;

    return (
      <div className="row sciGroupTotal">
          <div className="col col-lg-3">
            {filteredLabel}
          </div>
          <div className="col col-lg-3">
            <input value={value} disabled />
          </div>
          <div className="col col-lg-3">
            <input value={adjustment} disabled />
          </div>
          <div className="col col-lg-3">
            <input value={adjusted} style={{ fontWeight: 600 }} disabled />
          </div>
        </div>
    );
  }

  /**
   * Modified version of method renderParentListGroup
   * this method renders liabilities with a different wrapper
   * and calculated totals
   */
  renderLiabilitiesListGroup(parent, idx) {
    const LIABILITIES_CURRENT_ID = 7;
    const LIABILITIES_NON_CURRENT_ID = 8;
    const LIABILITIES_GROUP_ID = `${LIABILITIES_CURRENT_ID}-${LIABILITIES_NON_CURRENT_ID}`;
    const accountListGroups = parent.groups || [];
    const showGroups = this.state.closedParents.indexOf(parent.id) === -1;
    const arrowClass = showGroups ? "sci-arrow-up" : "sci-arrow-down";

    // group current liabilities with non current liabilities, and the remainder groups
    const [ liabilitiesGroup, restOfGroups ] = accountListGroups
      .reduce((result, group) => {
        result[[LIABILITIES_CURRENT_ID, LIABILITIES_NON_CURRENT_ID].includes(group.id) ? 0 : 1].push(group);
        return result;
      },
      [[], []]);

    const showLiabilitiesGroups = this.state.closedParents.indexOf(LIABILITIES_GROUP_ID) === -1;
    const arrowLiabilitiesClass = showLiabilitiesGroups ? "sci-arrow-up" : "sci-arrow-down";

    return (
      <div key={idx} className="sciParentGroup">
        <div className="sciGroupTitle" onClick={() => { this.onParentClicked(parent.id) }} >
          <div className={arrowClass}></div>
          {parent.name}
        </div>
        {
          showGroups &&
          <div className="sciParentGroupsContainer">
            <div key={LIABILITIES_GROUP_ID} className="sciParentGroup">
              <div className="sciGroupTitle" onClick={() => { this.onParentClicked(LIABILITIES_GROUP_ID) }} >
                <div className={arrowLiabilitiesClass}></div>
                Liabilities
              </div>
              { 
                showLiabilitiesGroups && 
                <div className="sciParentGroupsContainer">
                  {liabilitiesGroup.map(this.renderAccountListGroup)} 
                </div>
              }
              {this.renderGroupTotals({
                  ...parent,
                  groups: liabilitiesGroup
                }, 'Liabilities')}
            </div>
            {restOfGroups.map(this.renderAccountListGroup)}
          </div>
        }
        {this.renderGroupTotals(parent, parent.name)}
      </div>
    );
  }

  renderParentListGroup(parent, idx) {
    const accountListGroups = parent.groups || [];
    const showGroups = this.state.closedParents.indexOf(parent.id) === -1;
    const arrowClass = showGroups ? "sci-arrow-up" : "sci-arrow-down";

    return (
      <div key={idx} className="sciParentGroup">
        <div className="sciGroupTitle" onClick={() => { this.onParentClicked(parent.id) }} >
          <div className={arrowClass}></div>
          {parent.name}
        </div>
        {
          showGroups &&
          <div className="sciParentGroupsContainer">
            {accountListGroups.map(this.renderAccountListGroup)}
          </div>
        }
        {this.renderGroupTotals(parent)}
      </div>
    );
  }

  renderAccountListGroup(group, idx) {
    const accounts = group.accounts || [];
    const sortedAccounts = _.sortBy(accounts, ['orderIndex']);
    const accountsToShow = _.filter(sortedAccounts, (account) => ((Utils.normalizeNumber(account.value) && !account.visible) || (account.alwaysVisible === 'true' || account.alwaysVisible === true)));
    const showTable = this.state.closedAccordions.indexOf(group.id) === -1;
    const { value, adjustment, adjusted } = this.getGroupTotals(group);
    const arrowClass = showTable ? 'sci-arrow-up' : 'sci-arrow-down';
    const colClassname = classnames({
      'col': true,
      'col-lg-3': !group.hideAdjustment,
      'col-lg-6': group.hideAdjustment,
    });

    return (
      <div key={idx} className="sciGroup">
        <div className="sciGroupTitle" onClick={() => { this.onShowTable(group.id) }} >
          <div className={arrowClass}></div>
          {group.name}
        </div>
        {
          showTable &&
          <div className="row sciGroupTableHeader">
            <div className={colClassname}>
              {group.name}
            </div>
            <div className={colClassname}>
              {'Value'}
            </div>
            {
              !group.hideAdjustment &&
              <div className={colClassname}>
                {'Allowances and Adjustments'}
              </div>
            }
            {
              !group.hideAdjustment &&
              <div className={colClassname}>
                {'Adjusted Balance'}
              </div>
            }
          </div>
        }
        {
          showTable &&
          <div className="sciGroupTable">
            {accountsToShow.map(this.renderAccount)}
            {this.renderHiddenAccounts(group)}
          </div>
        }
        {
          group.totalType !== 'none' &&
          <div className="row sciGroupTotal">
            <div className={colClassname}>
              {!group.hideAdjustment && 'TOTAL'} {group.totalLabel || group.name}
            </div>
            <div className={colClassname}>
              <input value={value} disabled />
            </div>
            {
              !group.hideAdjustment &&
              <div className={colClassname}>
                <input value={adjustment} disabled />
              </div>
            }
            {
              !group.hideAdjustment &&
              <div className={colClassname}>
                <input value={adjusted} style={{ fontWeight: 600 }} disabled />
              </div>
            }
          </div>
        }
      </div>
    );
  }

  renderDiscreteAccountListGroup(group, idx) {
    const showTable = this.state.closedAccordions.indexOf('discreteAccounts') === -1;
    const arrowClass = showTable ? 'sci-arrow-up' : 'sci-arrow-down';
    const colClassname = classnames({
      'col': true,
      'col-lg-3': !group.hideAdjustment,
      'col-lg-6': group.hideAdjustment,
    });

    return (
      <div key={idx} className="sciGroup">
        <div className="sciGroupTitle" onClick={() => { this.onShowTable('discreteAccounts') }} >
          <div className={arrowClass}></div>
          Discrete Accounts
        </div>
        {
          showTable &&
          <Fragment>
            <div className="row sciGroupTableHeader">
              <div className={colClassname}>
                Discrete Account
              </div>
              <div className={colClassname}>
                Value
              </div>

            </div>
            <div className="sciGroupTable">
              {group.map(this.renderDiscreteAccount)}
            </div>
          </Fragment>
        }
      </div>
    );
  }



  renderAccount(account, idx) {
    const value = Utils.normalizeNumber(account.value) || 0;
    const adjustment = Utils.normalizeNumber(account.adjustment) || 0;
    const adjustedValue = Utils.formatNumberWithCommas(value + adjustment);
    const colClassname = classnames({
      'col': true,
      'col-lg-3': !account.hideAdjustment,
      'col-lg-6': account.hideAdjustment,
    });

    // because this account is calculated
    const disableAdjustment = (account.accountName === "Total Equity at the End of the Year");

    return (
      <div key={idx} className="row sciTableRow">
        <div className={colClassname}>
          <div className="d-flex align-items-center justify-content-between">
            {account.accountName}
            <a onClick={() => this.onEditNoteTask(account)} className="edit-note-button cell-table-link icon-edit" >
              {account.note.name || this.state.editedNotes[account.accountId] ? 'Edit' : 'Note'}
            </a>
          </div>
        </div>
        <div className={colClassname}>
          <input
            type="text"
            value={account.value}
            onChange={(e) => { this.onModifyAccountValue(e.target.value, account, 'value'); }}
            onBlur={(e) => { this.onBlurAccountValue(e.target.value, account, 'value'); }} />
        </div>
        {
          !account.hideAdjustment &&
          <div className={colClassname}>
            {
              disableAdjustment ?
                <input value={account.adjustment} style={{ fontWeight: 600 }} disabled />
                :
                <input
                  type="text"
                  value={account.adjustment}
                  onChange={(e) => this.onModifyAccountValue(e.target.value, account, 'adjustment')}
                  onBlur={(e) => { this.onBlurAccountValue(e.target.value, account, 'adjustment'); }} />
            }
          </div>
        }
        {
          !account.hideAdjustment &&
          <div className={colClassname}>
            <input value={adjustedValue} style={{ fontWeight: 600 }} disabled />
          </div>
        }
      </div>
    );
  }



  renderDiscreteAccount = (account, idx) => {

    const colClassname = classnames({
      'col': true,
      'col-lg-3': true
    });

    const hasValueInOptions = account.options.indexOf(account.valueSelected) > -1;
    return (
      <div key={idx} className="row sciTableRow">
        <div className={colClassname}>
          <div className="d-flex align-items-center justify-content-between">
            {account.name}

            <a onClick={() => this.onEditNoteTaskDisceteAccount(account)} className="edit-note-button cell-table-link icon-edit" >
              {!account.note ? 'Note' : 'Edit'}
            </a>
          </div>
        </div>

        <div className="col sciSelectWrapper col-lg-2 no-padd">
          <select className="sciInput" value={account.valueSelected} onChange={(e) => this.setDiscreteAccountsSelected(e, account.accountId, account.name)} >
            <option key={0} value="0">Select a value</option>
            {
              this.state.accountsListData.finIsComplete === 1 && !hasValueInOptions && (
                <option key={1} value={account.valueSelected}>{account.valueSelected}</option>
              )
            }
            {account.options.map((item, idx) => {
              return <option key={idx+1} value={item}>{item}</option>;
            })}
          </select>
        </div>
      </div>
    );
  }

  renderHiddenAccounts(group) {
    const accounts = group.accounts || [];
    const {
      visibleHiddenRows
    } = this.state;
    const hiddenAccounts = visibleHiddenRows[group.id] || [];
    const hiddenAccountsToShow = hiddenAccounts.map(this.renderAccount);
    const remainingHiddenAccounts = _.filter(accounts, (account) => ((account.alwaysVisible === 'false' || account.alwaysVisible === false) && !account.visible && !Utils.normalizeNumber(account.value)));
    const remainingHiddenAccountsOptions = Utils.getOptionsList('', remainingHiddenAccounts, 'accountName', 'accountId', 'accountName');
    const opts = remainingHiddenAccountsOptions.map((opt, idx) => <option value={opt.value} key={opt.value}>{opt.label}</option>);

    return (
      <div>
        {hiddenAccountsToShow}
        {
          !_.isEmpty(remainingHiddenAccounts) &&
          <div className="row sciTableRow justify-content-center">
            <div className='sciSelectWrapper col-sm-2 no-padd'>
              <select className="sciInput addAccountSelect" onChange={(e) => this.onAddAccountRowSelectChange(e, group.id)}>
                {opts}
              </select>
            </div>
            <button className="addAccountButton list-view-nav-link nav-bn icon-add" onClick={() => { this.addAccountRow(group) }} />
          </div>
        }
      </div>
    )
  }

  addAccountRow(group) {
    const {
      addAccountRowSelectValues
    } = this.state;
    const accounts = group.accounts || [];
    const selectedValue = addAccountRowSelectValues[group.id];
    const selectedAccount = _.find(accounts, { accountId: parseFloat(selectedValue) });

    if (selectedAccount) {
      selectedAccount.visible = true;

      const visibleHiddenRows = { ...this.state.visibleHiddenRows };
      const addAccountRowSelectValues = { ...this.state.addAccountRowSelectValues };

      if (_.isEmpty(visibleHiddenRows[group.id])) {
        visibleHiddenRows[group.id] = [];
      }

      visibleHiddenRows[group.id].push(selectedAccount);
      addAccountRowSelectValues[group.id] = '';

      this.setState({
        visibleHiddenRows,
        addAccountRowSelectValues
      });
    }
  }

  renderScorecardVariables() {
    const id = 'scorecardVariables';

    const {
      referencesPossibleValues,
      legalPossibleValues,
      creditHistoryPossibleValues,
      bankLinePossibleValues,
      scorecardConcetpsList
    } = this.props.financials;

    const {
      scorecardVariables
    } = this.state;

    if (scorecardConcetpsList) {
      scorecardConcetpsList.forEach((scoreCard) => {
        // Bank Line Usage
        if (scoreCard.scorecardConceptId === 9 && !scorecardVariables.bankLineUsageId){
          scorecardVariables.bankLineUsageId = scoreCard.value;
        }
        else if(scoreCard.scorecardConceptId === 10 && !scorecardVariables.legalStatusId){
          scorecardVariables.legalStatusId = scoreCard.value;
        }
        else if(scoreCard.scorecardConceptId === 11 && !scorecardVariables.referencesStatusId){
          scorecardVariables.referencesStatusId = scoreCard.value;
        }
        else if(scoreCard.scorecardConceptId === 12 && !scorecardVariables.creditHistoryId){
          scorecardVariables.creditHistoryId = scoreCard.value;
        }
      });
    }

    const showRow = this.state.closedParents.indexOf(id) === -1;
    const arrowClass = showRow ? 'sci-arrow-up' : 'sci-arrow-down';

    return (
      <div className="sciParentGroup">
        <div className="sciGroupTitle" onClick={() => { this.onParentClicked(id) }} >
          <div className={arrowClass}></div>
          Scorecard Variables
        </div>
        {
          showRow &&
          <div className="row sciGroupVariables">
            <div>
              Credit History
            </div>
            <div className='sciSelectWrapper col-sm-2 no-padd'>
              <select className="sciInput" onChange={(e) => this.onScorecardVariablesChange(e, 'creditHistoryId')} value={scorecardVariables.creditHistoryId}>
                {
                  creditHistoryPossibleValues.map((item, idx) => {
                    return (
                      <option key={idx} value={item.id}>{item.name}</option>
                    )
                  })
                }
              </select>
            </div>
            <div>
              Legal Status
            </div>
            <div className='sciSelectWrapper col-sm-2 no-padd'>
              <select className="sciInput" onChange={(e) => this.onScorecardVariablesChange(e, 'legalStatusId')} value={scorecardVariables.legalStatusId}>
                {
                  legalPossibleValues.map((item, idx) => {
                    return (
                      <option key={idx} value={item.id}>{item.name}</option>
                    )
                  })
                }
              </select>
            </div>
            <div>
              References Status
            </div>
            <div className='sciSelectWrapper col-sm-2 no-padd'>
              <select className="sciInput" onChange={(e) => this.onScorecardVariablesChange(e, 'referencesStatusId')} value={scorecardVariables.referencesStatusId}>
                {
                  referencesPossibleValues.map((item, idx) => {
                    return (
                      <option key={idx} value={item.id}>{item.name}</option>
                    )
                  })
                }
              </select>
            </div>

            <div>
              Bank Line Usage
            </div>

            <div className='sciSelectWrapper col-sm-2 no-padd'>
              <select className="sciInput" onChange={(e) => this.onScorecardVariablesChange(e, 'bankLineUsageId')} value={scorecardVariables.bankLineUsageId}>
                {bankLinePossibleValues.map((item, idx) => {
                  return (
                    <option key={idx} value={item.id}>{item.name}</option>
                  )
                })}
              </select>
            </div>
          </div>
        }
      </div>
    )
  }


  onAddAccountRowSelectChange(event, groupId) {

    const currentValue = event.target.value;
    const addAccountRowSelectValues = { ...this.state.addAccountRowSelectValues };
    addAccountRowSelectValues[groupId] = currentValue;

    this.setState({
      addAccountRowSelectValues
    });
  }

  renderCalculatedAccountsGroup() {
    const { finInfoAccountDisplayTypeId } = this.state;

    const calculatedAccounts = _.get(this.props.financials, 'calculatedAccounts.calculatedAccounts') || {};

    const groupName = 'calculatedAccounts';
    const showTable = this.state.closedAccordions.indexOf(groupName) === -1;
    const arrowClass = showTable ? 'sci-arrow-up' : 'sci-arrow-down';
    const colClassname = classnames({
      'col': true,
      'col-lg-6': true,
    });

    const calculatedAccountsArray = [
      {
        name: 'Single Project Limit',
        value: calculatedAccounts.singleProjectLimitValue
      },
      {
        name: 'Aggregate Project Limit',
        value: calculatedAccounts.aggregateProjectExposure
      },
      ...([1, 3].includes(finInfoAccountDisplayTypeId) ? [
        {
          name: 'Total Accounts Receivable',
          value: calculatedAccounts.totalAccountsReceivable
        },
        {
          name: 'Total Accounts Payable',
          value: calculatedAccounts.totalAccountsPayable
        },
        {
          name: 'Cost of Backlog',
          value: calculatedAccounts.costOfBacklog
        },
        {
          name: 'Working Capital (Adjusted)',
          value: calculatedAccounts.adjustedWorkingCapital
        },
        {
          name: 'Total Liabilities',
          value: calculatedAccounts.totalLiabilities
        }
      ] : []),
    ];

    return (
      <div className="sciGroup">
        <div className="sciGroupTitle" onClick={() => { this.onShowTable(groupName) }} >
          <div className={arrowClass}></div>
          Calculated Accounts
        </div>
        {
          showTable &&
          <div className="row sciGroupTableHeader">
            <div className={colClassname}>
              Calculated Accounts
            </div>
            <div className={colClassname}>
              Value
            </div>
          </div>
        }
        {
          showTable &&
          <div className="sciGroupTable">
            {calculatedAccountsArray.map(this.renderCalculatedAccount)}
          </div>
        }
      </div>
    )
  }

  renderCalculatedAccount(account, index) {
    const colClassname = classnames({
      'col': true,
      'col-lg-6': true,
    });

    const value = account.value ? Utils.formatNumberWithCommas(parseInt(`${account.value}`).toFixed(2)) : account.value;

    return (
      <div key={index} className="row sciTableRow">
        <div className={colClassname}>
          <div className="d-flex align-items-center justify-content-between">
            {account.name}
          </div>
        </div>
        <div className={colClassname}>
          {value}
        </div>
      </div>
    );
  }

  renderRatiosGroup() {
    const ratios = _.get(this.props.financials, 'calculatedAccounts.ratios') || {};

    const groupName = 'ratiosAccounts';
    const showTable = this.state.closedAccordions.indexOf(groupName) === -1;
    const arrowClass = showTable ? 'sci-arrow-up' : 'sci-arrow-down';
    const colClassname = classnames({
      'col': true,
      'col-lg-4': true,
    });

    const getAccountObject = (accountKey) => {
      const account = ratios[accountKey];
      return {
        value: account,
        color: ''
      }
    }

    const ratiosAccounts = [
      {
        name: 'Current Ratio',
        ...getAccountObject('currentRatio')
      },
      {
        name: 'Work Capital to Backlog',
        ...getAccountObject('workCapitalToBacklog')
      },
      {
        name: 'Number of Days Cash',
        ...getAccountObject('numberOfDaysCash')
      },
      {
        name: 'AR Turnover',
        ...getAccountObject('ARTurnover')
      },
      {
        name: 'AP Turnover',
        ...getAccountObject('APTurnover')
      },
      {
        name: 'Debt to Net Worth',
        ...getAccountObject('debtToNetWorth')
      },
      {
        name: 'Net Worth to Backlog',
        ...getAccountObject('netWorthToBacklog')
      },
      {
        name: 'Profitability',
        ...getAccountObject('profitability')
      },
      {
        name: 'Tier Rating',
        ...getAccountObject('tierRating')
      },

    ]

    return (
      <div className="sciGroup">
        <div className="sciGroupTitle" onClick={() => { this.onShowTable(groupName) }} >
          <div className={arrowClass}></div>
          Calculated Accounts
        </div>
        {
          showTable &&
          <div className="row sciGroupTableHeader">
            <div className={colClassname}>
              Calculated Accounts
            </div>
            <div className={colClassname}>
              Value
            </div>
            <div className={colClassname}>
              Color
            </div>
          </div>
        }
        {
          showTable &&
          <div className="sciGroupTable">
            {ratiosAccounts.map(this.renderRatiosAccount)}
          </div>
        }
      </div>
    )
  }

  renderRatiosAccount(account, index) {
    const colClassname = classnames({
      'col': true,
      'col-lg-4': true,
    });

    const value = (account.value && Utils.isValidNumber(account.value)) ? Utils.formatNumberWithCommas(account.value.toFixed(2)) : account.value;

    return (
      <div key={index} className="row sciTableRow">
        <div className={colClassname}>
          <div className="d-flex align-items-center justify-content-between">
            {account.name}
          </div>
        </div>
        <div className={colClassname}>
          {value}
        </div>
        <div className={colClassname}>
          {account.color}
        </div>
      </div>
    );
  }

  renderCalculatedAccordions(spinner) {
    const { finInfoAccountDisplayTypeId } = this.state;

    if (this.state.calculatedAccountsFetched) {
      if (this.props.financials.fetchingCalculateAccounts) {
        return spinner;
      } else {
        return (
          <div>
            {this.renderCalculatedAccountsGroup()}

            {[1, 3].includes(finInfoAccountDisplayTypeId) && this.renderRatiosGroup()}
          </div>
        );
      }
    }
  }

  onEditNoteTask(account) {

    this.setState({
      selectedAccountForNoteEdition: account,
      showNoteEditor: true
    });
  }

  populateDataFromForm = () => {
    let showLoader = this.state.showLoader;
    showLoader = true;
    this.setState({showLoader});
    this.props.formLinkActions.getSavedFormioFormFinInfoTab(this.state.savedFormId, (data) => {
      const basicAccounts = this.state.basicAccounts
      const avgProjectAccounts = this.state.avgProjectAccounts
      const avgVolumeAccounts = this.state.avgVolumeAccounts



      if(data && data.data && data.data.FinBacklogCurrent){
        const index = basicAccounts.accounts.findIndex(item => item.accountName === 'Backlog')
        if(index !== -1) {
          basicAccounts.accounts[index]['value'] = data.data.FinBacklogCurrent
        }
      }
      const formioPerformanceData =
        data.data &&
        (data.data
          .whatWasTheAverageAnnualVolumeOfWorkPerformedOverThePast5Years ||
          data.data.GridRevenue);
      if(formioPerformanceData){
        avgProjectAccounts.accounts[0]['value'] = formioPerformanceData[0]['FinLargestProjectYear1']
        avgProjectAccounts.accounts[1]['value'] = formioPerformanceData[1]['FinLargestProjectYear1']
        avgProjectAccounts.accounts[2]['value'] = formioPerformanceData[2]['FinLargestProjectYear1']
  
        avgVolumeAccounts.accounts[0]['value'] = formioPerformanceData[0]['FinRevenueEarnedYear1']
        avgVolumeAccounts.accounts[1]['value'] = formioPerformanceData[1]['FinRevenueEarnedYear1']
        avgVolumeAccounts.accounts[2]['value'] = formioPerformanceData[2]['FinRevenueEarnedYear1']
      }

      showLoader = false;
      this.setState({showLoader});
      this.setState({basicAccounts, avgProjectAccounts, avgVolumeAccounts});
    })
  }

  onEditNoteTaskDisceteAccount(account) {

    let discreteAccount = {
      accountId: account.accountId,
      accountName: account.name,
      value: account.valueSelected,
      typeAccount: 'discreteAccount',
      note: account.note ? account.note : {}
    }

    this.setState({
      selectedAccountForNoteEdition: discreteAccount,
      showNoteEditor: true
    });
  }

  closeNoteEditor() {
    this.setState({
      showNoteEditor: false
    });
  }

  openCopySubmissionSelectorModal = () => {
    this.setState({ showCopySubmissionModal: true });
  }

  closeCopySubmissionSelectorModal = (e, refreshFinInfo) => {
    if (refreshFinInfo) {
      this.props.actions.fetchAccountsList(null, null, this.state.savedFormId);
    }

    this.setState({ showCopySubmissionModal: false });
  }

  openRenewalModal = () => {
    this.setState({ showRenewalModal: true });
  }

  closeRenewalModal = (e, refreshFinInfo) => {
    if (refreshFinInfo) {
      this.props.actions.fetchAccountsList(null, null, this.state.savedFormId);
    }

    this.setState({ showRenewalModal: false });
  }

  closeAddFormModal = (e, refreshFinInfo) => {
    if (refreshFinInfo) {
      this.props.actions.fetchAccountsList(null, null, this.state.savedFormId);
    }

    this.setState({ showModalAddForm: false });
  }

  saveAccountNote(note) {
    const newArray = this.state.editedNotes.concat([]);
    const mappedNote = {
      taskId: note.taskId || null,
      assignedToUserId: note.assignedToUserId == undefined ? this.state.selectedAccountForNoteEdition.accountId : note.assignedToUserId,
      description: note.description,
      name: note.name
    };

    if (this.state.selectedAccountForNoteEdition.typeAccount != undefined) {
      mappedNote.typeAccount = this.state.selectedAccountForNoteEdition.typeAccount;
      this.state.discreteAccountsSelected.map(element => {
        if (element.accountId == this.state.selectedAccountForNoteEdition.accountId) {
          mappedNote.taskId = element.note && element.note.taskId ? element.note.taskId : null;
          element.note = mappedNote;
        }
        return element;
      });
    } else {
      newArray[this.state.selectedAccountForNoteEdition.accountId] = mappedNote;

      this.setState({
        editedNotes: newArray,
      });
    }
  }

  checkIfShouldShowSaveAndComplete() {
    const { saveAndCompleteConditions, finInfoAccountDisplayTypeId } = this.state;

    const keys = Object.keys(saveAndCompleteConditions);

    let shouldShowSaveAndComplete = false;
    let shouldShowSave = this.props.login.rolesAccessPermissions.filter(el => el.MasterTab === 'financial_info' && el.SectionTab === 'save').length > 0;
    
    if (this.props.login.rolesAccessPermissions.filter(el => el.MasterTab === 'financial_info' && el.SectionTab === 'save_and_complete').length > 0) {
      if (finInfoAccountDisplayTypeId === 1) {
        shouldShowSaveAndComplete = keys.every(key => {
          if (key === 'discreteAccounts') {
            return true;
          }

          return saveAndCompleteConditions[key];
        });
      } else if (finInfoAccountDisplayTypeId === 2) {
        shouldShowSaveAndComplete = saveAndCompleteConditions.discreteAccounts;
      } else if (finInfoAccountDisplayTypeId === 3) {
        shouldShowSaveAndComplete = keys.every(key => saveAndCompleteConditions[key]);
      }
    }

    this.setState({
      shouldShowSave,
      shouldShowSaveAndComplete,
    });
  }

  updateShowsaveAndCompleteConditions(fieldName, val) {
    const { saveAndCompleteConditions, waiveRequirementIsEnabled } = this.state;

    if (waiveRequirementIsEnabled) {
      return;
    }
  
    const conditions = {
      'dateOfFinancialStatement': val => Boolean(val),
      'creditHistoryId': val => val && val !== 4,
      'legalStatusId': val => val && val !== 8,
      'referencesStatusId': val => val && val !== 8,
      'bankLineUsageId': val => val && val !== 9,
      'turnOverRateId': val => Boolean(val),
      'analysisTypeId': val => Boolean(val),
      'financialStatementTypeId': val => Boolean(val),
      'discreteAccounts': val => Array.isArray(val) ? val.every(discreteAccount => Number(discreteAccount.valueSelected) !== 0) : false,
      'companyTypeId': val => Boolean(val)
    };
  
    if (conditions[fieldName]) {
      const isValueExpectedToBeNumber = ['creditHistoryId', 'legalStatusId', 'referencesStatusId', 'bankLineUsageId', 'turnOverRateId', 'analysisTypeId', 'companyTypeId', 'financialStatementTypeId'].includes(fieldName);
      const valueToUse = isValueExpectedToBeNumber ? Number(val) : val;
      saveAndCompleteConditions[fieldName] = conditions[fieldName](valueToUse);
      this.setState({ saveAndCompleteConditions }, this.checkIfShouldShowSaveAndComplete);
    }
  }

  // Mark Financial Info as Incompleted Feature
  markFinancialInfoAsIncompleted = (event) => {
    event.preventDefault();

    const { scId, scProfile, login } = this.props;
    const { hcId } = scProfile;
    const { userId } = login;
    const payload = {
      hiringClientId: hcId,
      subcontractorId: scId,
      subcontractorStatusId: 15,
      userId,
    };

    this.showConfirmationAlert(payload);
  };

  showConfirmationAlert = (payload) => {
    Alerts.showActionConfirmation(
      {
        title: 'Mark as incompleted',
        text: 'Are you sure you want to mark this financial information form as incompleted ?',
        btn_no: 'No',
        btn_yes: 'Yes',
      },
      (result) => {
        if (result) {
          this.markFormAsIncompleted(payload);
        }
      }
    );
  };

  markFormAsIncompleted = (payload) => {
    const { savedFormId } = this.state;
    this.props.actions.markFinancialInfoAsIncompleted({ savedFormId }, (response) => {
      if (response) {
        this.changeStatus(payload);
        this.props.formLinkActions.addSubcontractorFormsLog({
          savedFormId,
          subcontractorId: payload.subcontractorId,
          eventType: MARK_INCOMPLETE_FIN_INFO,
          logType: PQ_FINANCIAL_INFO_LOG_TYPE
        });
      } else {
        Alerts.showErrorAlert('Error', 'There was an error marking the form as incompleted');
      }
    });
  };

  changeStatus = (payload) => {
    this.props.scPorfileActions.changeStatus(payload, (success) => {
      if (success) {
        this.updateState();
      }
    });
  };

  updateState = () => {
    this.setState(
      (prevState) => ({
        accountsListData: { ...prevState.accountsListData, finIsComplete: 0 },
        savedFormIsLocked: false,
      }),
      () => {
        this.refreshHeader(true);
      }
    );
  };
  // End Mark Financial Info as Incompleted

  force = (type) => {
    const typeForce = type == '1' ? 'Renewal' : 'Financial Update';
    Alerts.showActionConfirmation({
      title: `Force ${typeForce}`,
      text: `Are you sure you want to Force ${typeForce} date for this financial information form ?`,
      btn_no: 'No',
      btn_yes: 'Yes',
    }, (result) => {
      if (result) {
        if (type == '1') {
          this.openRenewalModal();
        } else {
          this.forceAction(type)
        }
      }
    });
  }

  forceAction = (type, formId) => {
    const {
      hiringClientId,
      subcontractorId
    } = this.state.accountsListData;

    this.props.actions.force({ hiringClientId, subcontractorId, typeOfRenewal: type, formId }, async (response) => {
      if (response.success) {
        await this.props.formLinkActions.addSubcontractorFormsLog({
          savedFormId: this.state.savedFormId,
          subcontractorId: subcontractorId,
          eventType: type == '1' ? FORCE_RENEWAL_FIN_INFO : FORCE_FINANCIAL_UPDATE_FIN_INFO,
          logType: PQ_FINANCIAL_INFO_LOG_TYPE,
          callback: () => {
            window.location.reload(false);
          }
        });
      } else {
        let errorMsg = 'There was an error forcing renewal';
        if(response && response.data && response.data.description){
          errorMsg = response.data.description;
        }
        Alerts.showErrorAlert('Error', errorMsg);
      }
    });
  }

  showForceActions() {
    let showActions = false;
    const { workflows } = this.state;
    if (workflows.length > 0) {
      const renewalWorkflow = workflows.find(x => x.description === 'Subcontractor Renewal')
      if (renewalWorkflow) {
        showActions = renewalWorkflow.isEnabled
      }
    }

    this.setState({ showForceActions: showActions });
  }

  filterHiringClients = (userArray, subArray) => {
    const userSet = new Set(userArray)
    let output = []
    for (let i=0; i<subArray.length; i++) {
      const subHiringClientId = subArray[i].hiringClientId
      if (userSet.has(subHiringClientId)) {
        output.push(subArray[i])
      }
    }
    return output
  }

  modifyParentListGroup(accountsList) {
    return accountsList.map((listItem, idx) => {
      if (listItem.id === TOTAL_LIABILITIES_AND_EQUITY_ID) {
        return this.renderLiabilitiesListGroup(listItem, idx)
      } else {
        return this.renderParentListGroup(listItem, idx)
      }
    });
  }

  renderCalculateValues () {

    const canCalculateUncompletedForm = this.props.login.rolesAccessPermissions.filter(el => el.MasterTab === 'financial_info' && el.SectionTab === 'calculate_uncompleted_form').length > 0;

    const { finIsComplete } = this.state.accountsListData;

    if (!finIsComplete && !canCalculateUncompletedForm) {
      return null;
    }

    return (
      <React.Fragment>
        {!this.state.calculatedAccountsFetched && <p className="d-flex justify-content-center">Press the button to get calculated accounts</p>}
        <div className="sciFooter">
          <button className={`sciButton${(this.state.isSaving || this.state.isCalculatingValue) ? " disabled" : ""}`} disabled={this.state.isSaving || this.state.isCalculatingValue} onClick={this.onCalculateAccounts}>Calculate Values</button>
        </div>
      </React.Fragment>
    );
  }

  openUserLog = () => {
    this.props.scLogsAction.setFinancialLogsVisible(true)
  }

  renderFinancialLog = () => (
    <SCLogs savedFormId={this.state.savedFormId} logType={1} />
  );
  onCloseCalendar = (e,fieldName) => {
    const canInputFinInfo = this.props.login.rolesAccessPermissions
      .filter(el => el.MasterTab === 'financial_info' && el.SectionTab === 'input').length > 0;
    let isClearClass = e.target.classList.contains('react-date-picker__clear-button');
    let isCloseIdFound = false;
    if((e.target && e.target.id && e.target.id === "close-icon") || (e.target && e.target.viewportElement && e.target.viewportElement.id && e.target.viewportElement.id === "close-icon")) {
      isCloseIdFound = true;
    }
    if(!isClearClass && !isCloseIdFound && !this.state.accountsListData.finIsComplete && !this.state.savedFormIsLocked && canInputFinInfo)
    {
      if(fieldName === 'dateOfFinancialStatementOpen')
        this.setState({dateOfFinancialStatementOpen : true});
      else if(fieldName === 'dateOfFinancialStatementPreparedOpen')
        this.setState({dateOfFinancialStatementPreparedOpen : true});
      else if(fieldName === 'dateOfRenewalOpen')
        this.setState({dateOfRenewalOpen : true});
    }
  }

  render() {
    const {
      shouldShowSave,
      shouldShowSaveAndComplete,
      accountsListData,
      finInfoAccountDisplayTypeId,
      waiveRequirementIsEnabled
    } = this.state;

    const dateOfFinancialStatement = Utils.getFormattedDateSmall(accountsListData['dateOfFinancialStatement'])
    const dateOfFinancialStatementPrepared = Utils.getFormattedDateSmall(accountsListData['dateOfFinancialStatementPrepared'])
    const dateOfRenewal = Utils.getFormattedDateSmall(accountsListData['dateOfRenewal'])
    // const dateOfPrequal = accountsListData['dateOfPrequal']

    this.calculateTotalEquityEndYear();
    const prequalDates = this.props.financials.prequalDates;
    const pOptions = this.props.financials.scorecardTurnOverRatesPossibleValues.filter(x => x.id != 0);
    const periodOptions = Utils.getOptionsList("Select period", pOptions, 'name', 'id', 'value', '0');
    const analysisTypePossibleValues = Utils.getOptionsList("Select Analysis Type", this.props.financials.analysisTypePossibleValues, 'name', 'id', 'id');
    const financialStatementTypePossibleValues = Utils.getOptionsList("Select Financial Statement Type", this.props.financials.financialStatementTypePossibleValues, 'name', 'id', 'id');

    const {
      companyType,
      turnOverRateId,
      analysisTypeId,
      financialStatementTypeId,
      companyTypeId,
      typeOfRenewal
    } = this.props.financials.subcontractorData

    const { hiringClientsListForCopy } = this.props.scProfile.headerDetails

    const { multipleStrictHCs, RoleID, Role } = this.props.login.profile

    const IsPrequalRole = _.get(this.props.login, 'profile.Role.IsPrequalRole');

    let analysisTypeIdValue = analysisTypeId || '';
    let financialStatementValue = financialStatementTypeId || '';
    let companyTypeValue = companyTypeId || '';
    let commentary = accountsListData.commentary || '';
    const canInputFinInfo = this.props.login.rolesAccessPermissions
    .filter(el => el.MasterTab === 'financial_info' && el.SectionTab === 'input').length > 0;
    const spinner = (
      <div style={{
        width: '100%',
        height: '300px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <div className="spinner-wrapper">
          <div className="spinner"></div>
        </div>
      </div>
    );

    if (!this.state.isSaving && (this.props.financials.fetchingAccountsList || this.state.showLoader)) {
      return spinner;
    }

    let companiesTypesPossibleValues = [];
    if (this.props.financials.companiesTypesPossibleValues.length > 0) {
      let ctOptions = this.props.financials.companiesTypesPossibleValues.filter(x => x.id != 0);
      companiesTypesPossibleValues = Utils.getOptionsList("Select", ctOptions, 'name', 'id', null, '0');
    }
    const pqRoleName = Role && Role.Name;
    const canHCFilter = (pqRoleName === ROLES.SELF_SERVICE_OPERATOR || pqRoleName === ROLES.SELF_SERVICE_ANALYST || RoleID === 26) ? true : false;
    const HCForCopy = canHCFilter && typeof hiringClientsListForCopy != 'undefined' ? this.filterHiringClients(multipleStrictHCs, hiringClientsListForCopy) : hiringClientsListForCopy || [];
    const waiveRequirementList = [PQ_ADMIN_ROLE_ID, PQ_OPERATOR_ROLE_ID, PQ_FINANCIAL_ANALYST_ROLE_ID, SELF_SERVICE_ADMIN_ROLE_ID, SELF_SERVICE_OPERATOR_ROLE_ID, SELF_SERVICE_ANALYST_ROLE_ID];
    const checkUserRole = this.props.login.profile.RoleID;
    let checkWaiveRequirementPermission;
    if (checkUserRole) {
      checkWaiveRequirementPermission = waiveRequirementList.find(f => f === checkUserRole)
    }

    return (
      this.props.isLogVisible ? 
      this.renderFinancialLog()
      :
      <div className="finance-tab">
          <div className="row">
              <div className="col-sm-12">
                  <nav className="finance-nav">
                    <ul className='finance-nav-margin'>
                        <li>
                          <a
                            className="finance-nav-link nav-bn"
                            onClick={this.openUserLog}
                          >
                            Users Log
                          </a>
                        </li>
                    </ul>
                  </nav>
              </div>
          </div>

        <div id="financial-info-page" className="list-view admin-view-body scoreCardInput"> 
          <Modal
            show={this.state.showNoteEditor}
            onHide={this.closeNoteEditor}
            className="add-item-modal noteEditorModal" >
            <Modal.Body>
              <NoteEditorModal
                closeAndRefresh={this.closeNoteEditor}
                close={this.closeNoteEditor}
                note={this.state.editedNotes[this.state.selectedAccountForNoteEdition.accountId] || this.state.selectedAccountForNoteEdition.note}
                saveNote={this.saveAccountNote}
                subcontractorId={this.state.scId}
                fromSCtab
                fromFinancialTab />
            </Modal.Body>
          </Modal>

          <Modal
            show={this.state.showCopySubmissionModal}
            onHide={this.closeCopySubmissionSelectorModal}
            className="add-item-modal noteEditorModal"
          >
            <Modal.Body className="add-item-modal-body">
              <FinancialInfoCopySubmissionModal
                closeAndRefresh={() => this.closeCopySubmissionSelectorModal(null, true)}
                close={this.closeCopySubmissionSelectorModal}
                subcontractorId={this.state.scId}
                hiringClientId={this.state.hcId}
                hiringClients={HCForCopy}
              />
            </Modal.Body>
          </Modal>

          <Modal
            show={this.state.showRenewalModal}
            onHide={this.closeRenewalModal}
            className="add-item-modal noteEditorModal"
          >
            <Modal.Body className="add-item-modal-body">
              <FinancialInfoRenewalModal
                closeAndRefresh={() => this.closeRenewalModal(null, true)}
                close={this.closeRenewalModal}
                onForce={this.forceAction}
                hcId={this.state.hcId}
                type='renewal'
              />
            </Modal.Body>
          </Modal>

          <div className="sciActions">
          {this.state.accountsListData && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {this.state.accountsListData.finIsComplete === 1 && (
                <RolAccess
                  masterTab="financial_info"
                  sectionTab="mark_incompleted"
                  component={() => (
                    <div>
                      <button
                        className="sciButton"
                        onClick={this.markFinancialInfoAsIncompleted}
                      >
                        Mark as incompleted
                      </button>
                    </div>
                  )}
                />
              )}
              {this.state.showForceActions !== 0 && this.state.accountsListData.finIsComplete === 1 && this.state.accountsListData.dateOfPrequal === prequalDates[0].dateOfPrequal && (
                <RolAccess
                  masterTab="financial_info"
                  sectionTab="force_actions"
                  component={() => (
                    <div>
                      <button
                        className="sciButton"
                        onClick={() => this.force('1')}
                      >
                        Force Renewal
                      </button>
                      <button
                        className="sciButton"
                        onClick={() => this.force('2')}
                      >
                        Force Financial Update
                      </button>
                    </div>
                  )}
                />
              )}
            </div>
          )}

            {this.state.accountsListData && (this.state.accountsListData.finIsComplete === 0 || this.state.accountsListData.finIsComplete === null) && (
              <RolAccess
                masterTab="financial_info"
                sectionTab="copy_submission"
                component={() => (
                  <button className={`sciButton ${HCForCopy.length == 0 ? `disabled`: ``}` } 
                    disabled={HCForCopy.length == 0} 
                    onClick={this.openCopySubmissionSelectorModal}>
                    Copy Submission
                  </button>
                )}
              />
            )}
            {(this.state.accountsListData && (this.state.accountsListData.finIsComplete === 0 || this.state.accountsListData.finIsComplete === null)) && (
              <RolAccess
                masterTab="financial_info"
                sectionTab="waive_requirements"
                component={() => (
                  <button className={`sciButton ${waiveRequirementIsEnabled?`disabled`:``}`} onClick={this.waiveRequirementsHandler}>
                    Waive Requirements
                  </button>
                )}
              />
            )}
            {this.state.accountsListData && (this.state.accountsListData.finIsComplete === 0 || this.state.accountsListData.finIsComplete === null) ? <RolAccess
                  masterTab="financial_info"
                  sectionTab="populate_from_form"
                  component={() => (
                    <button className={`sciButton` }  
                      onClick={this.populateDataFromForm}>
                      Populate from Form
                    </button>
                  )}
                /> : null}
          </div>

          <div className="sciHeader">
            <div className="row">
              <div className="col-sm-6 row">
                <div className="col-sm-6">
                  <div className='sciLabelWrapper'>Prequalification Date</div>
                </div>

                <div className="col-sm-6">
                  <div className='sciSelectWrapper'>
                    <select className="sciInput changeSubmission" onChange={this.prequalDateChange} value={this.state.savedFormId}>
                      {
                        prequalDates.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {Utils.getFormattedDate(item.dateOfPrequal, true) || ''}
                            </option>
                          )
                        })
                      }
                    </select>
                  </div>
                </div>
              </div>

              {[1, 3].includes(finInfoAccountDisplayTypeId) &&
                <div className="col-sm-6 row">
                  <div className="col-sm-6">
                    <div className='sciLabelWrapper'>Financial statement Date: </div>
                  </div>
                  <div className="col-sm-6" style={{ fontSize: '14px' }}>
                  <DatePicker
                      value={dateOfFinancialStatement ? moment(dateOfFinancialStatement).toDate(): ""}
                      onChange={value => this.onDateChange(value, 'dateOfFinancialStatement')}          
                      format="MM/dd/yyyy"
                      placeholderText="mm/dd/yyyy"
                      clearIcon={dateOfFinancialStatement && !this.state.accountsListData.finIsComplete && !this.state.savedFormIsLocked && canInputFinInfo ? <CloseIcon fontSize="small" className="custom-calendar-icon close-icon" id="close-icon" onClick={() => this.removeDate('dateOfFinancialStatement')} /> : null}
                      calendarIcon={dateOfFinancialStatement ? null : <div  className='linear-icon-calendar custom-calendar-icon ' ></div>}
                      dayPlaceholder={"dd"}
                      monthPlaceholder={"mm"}
                      yearPlaceholder={"yyyy"}
                      onKeyDown={(e) => this.calendarEvent(e,'dateOfFinancialStatement')}
                      isOpen={this.state.dateOfFinancialStatementOpen}
                      onCalendarClose={() => {
                        this.setState({
                          dateOfFinancialStatementOpen : false
                        })
                        if(!dateOfFinancialStatement)
                        {
                          const cloneRawListData = {...this.state.rawAccountsListData};
                          const { accountsListData } = this.state;
                          accountsListData['dateOfFinancialStatement'] = new Date()
                          accountsListData['dateOfFinancialStatement'] = cloneRawListData['dateOfFinancialStatement']
                          this.setState({
                            accountsListData
                          })
                        }}}
                        onClick={(e) => this.onCloseCalendar(e,'dateOfFinancialStatementOpen')}
                        />
                  </div>
                </div>
              }

              <div className="col-sm-6 row" style={{ marginTop: '15px' }}>
                <div className="col-sm-6">
                  <div className='sciLabelWrapper'>Corp Type: </div>
                </div>

                {
                  IsPrequalRole ? (
                    <div className="col-sm-6">
                      <div className='sciSelectWrapper'>
                        <select className="sciInput" onChange={(e) => this.onSelectChange(e, 'companyTypeId')} value={companyTypeValue}>
                          {
                            companiesTypesPossibleValues.map((item, idx) => {
                              return (
                                <option key={idx} value={item.value}>{item.label}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                    </div>
                  ) : (
                      <div className="col-sm-6" style={{ fontSize: '14px' }}>
                        {companyType}
                      </div>
                    )}

              </div>

              {[1, 3].includes(finInfoAccountDisplayTypeId) &&
                <div className="col-sm-6 row" style={{ marginTop: '15px' }}>
                  <div className="col-sm-6">
                    <div className='sciLabelWrapper'>Financial statement Date Prepared: </div>
                  </div>
                  <div className="col-sm-6" style={{ fontSize: '14px' }}>
                    <DatePicker
                      value={dateOfFinancialStatementPrepared ? moment(dateOfFinancialStatementPrepared).toDate(): ""}
                      onChange={value => this.onDateChange(value, 'dateOfFinancialStatementPrepared')}          
                      format="MM/dd/yyyy"
                      placeholderText="mm/dd/yyyy"
                      clearIcon={dateOfFinancialStatementPrepared && !this.state.accountsListData.finIsComplete && !this.state.savedFormIsLocked && canInputFinInfo ? <CloseIcon fontSize="small" className="custom-calendar-icon close-icon" id="close-icon" onClick={() => this.removeDate('dateOfFinancialStatementPrepared')} /> : null}
                      calendarIcon={dateOfFinancialStatementPrepared ? null : <div  className='linear-icon-calendar custom-calendar-icon ' ></div>}
                      dayPlaceholder={"dd"}
                      monthPlaceholder={"mm"}
                      yearPlaceholder={"yyyy"}
                      onCalendarClose={() => {
                        this.setState({
                          dateOfFinancialStatementPreparedOpen : false
                        })
                        if(!dateOfFinancialStatementPrepared)
                        {
                          const cloneRawListData = {...this.state.rawAccountsListData};
                          const { accountsListData } = this.state;
                          accountsListData['dateOfFinancialStatementPrepared'] = new Date()
                          accountsListData['dateOfFinancialStatementPrepared'] = cloneRawListData['dateOfFinancialStatementPrepared']
                          this.setState({
                            accountsListData
                          })
                        }}}
                        onClick={(e) => this.onCloseCalendar(e,'dateOfFinancialStatementPreparedOpen')}                     
                        onKeyDown={(e) => this.calendarEvent(e,'dateOfFinancialStatementPrepared')}
                        isOpen={this.state.dateOfFinancialStatementPreparedOpen}
                    />
                  </div>
                </div>
              }

              <div className="col-sm-6 row" style={{ marginTop: '15px' }}>
                <div className="col-sm-6">
                  <div className='sciLabelWrapper'>Renewal Type: </div>
                </div>
                <div className="col-sm-6">
                  <div className='sciSelectWrapper'>
                    <select className="sciInput" onChange={(e) => this.onSelectChange(e, 'typeOfRenewal')} value={typeOfRenewal || ''}>
                      <option value="null">--Select Type of Renewal--</option>
                      <option value="1">Renewal</option>
                      <option value="2">Financial Update</option>
                    </select>
                  </div>
                </div>
              </div>

              {[1, 3].includes(finInfoAccountDisplayTypeId) &&
                <div className="col-sm-6 row" style={{ marginTop: '15px' }}>
                  <div className="col-sm-6">
                    <div className='sciLabelWrapper'>Financial statement Type: </div>
                  </div>
                  <div className="col-sm-6">
                    <div className='sciSelectWrapper'>
                      <select className="sciInput" onChange={(e) => this.onSelectChange(e, 'financialStatementTypeId')} value={financialStatementValue}>
                        {
                          financialStatementTypePossibleValues.map((item, idx) => {
                            return (
                              <option key={idx} value={item.value}>{item.label}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>
              }

              <div className="col-sm-6 row" style={{ marginTop: '15px' }}>
                <div className="col-sm-6">
                  <div className='sciLabelWrapper'>Renewal date: </div>
                </div>
                <div className="col-sm-6" style={{ fontSize: '14px' }}>
                  <DatePicker
                    value={dateOfRenewal ? moment(dateOfRenewal).toDate(): ""}
                    onChange={value => this.onDateChange(value, 'dateOfRenewal')}          
                    format="MM/dd/yyyy"
                    placeholderText="mm/dd/yyyy"
                    onCalendarClose={() => {
                      this.setState({
                      dateOfRenewalOpen : false
                    });
                    if(dateOfRenewal){
                      this.checkIsDateValid();
                    }                  
                    }}
                    clearIcon={dateOfRenewal && !this.state.accountsListData.finIsComplete && !this.state.savedFormIsLocked && canInputFinInfo ? <CloseIcon fontSize="small" className="custom-calendar-icon close-icon" id="close-icon" onClick={() => this.removeDate('dateOfRenewal')} /> : null}
                    calendarIcon={dateOfRenewal ? null : <div  className='linear-icon-calendar custom-calendar-icon ' ></div>}
                    dayPlaceholder={"dd"}
                    monthPlaceholder={"mm"}
                    yearPlaceholder={"yyyy"}
                    onClick={(e) => this.onCloseCalendar(e,'dateOfRenewalOpen')}
                    onKeyDown={(e) => this.calendarEvent(e,'dateOfRenewal')}
                    isOpen={this.state.dateOfRenewalOpen}
                  />
                </div>
              </div>

              {[1, 3].includes(finInfoAccountDisplayTypeId) &&
                <div className="col-sm-6 row" style={{ marginTop: '15px' }}>
                  <div className="col-sm-6">
                    <div className='sciLabelWrapper'>Period: </div>
                  </div>
                  <div className="col-sm-6">
                    <div className='sciSelectWrapper'>
                      <select className="sciInput" onChange={(e) => this.onSelectChange(e, 'turnOverRateId')} value={turnOverRateId}>
                        {
                          periodOptions.map((item, idx) => {
                            return (
                              <option key={idx} value={item.value}>{item.label}</option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>
              }
              {
                IsPrequalRole ? (
                  <div className="col-sm-6 row" style={{ marginTop: '15px' }}>
                    <div className="col-sm-6">
                      <div className='sciLabelWrapper'>Analysis Type: </div>
                    </div>
                    <div className="col-sm-6">
                      <div className='sciSelectWrapper'>
                        <select className="sciInput" onChange={(e) => this.onSelectChange(e, 'analysisTypeId')} value={analysisTypeIdValue}>
                          {
                            analysisTypePossibleValues.map((item, idx) => {
                              return (
                                <option key={idx} value={item.value}>{item.label}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                ) : (
                    <div className="col-sm-6 row" style={{ marginTop: '15px' }} />
                  )
              }
            </div>
          </div>
          <div className="sciBody">
            {[1, 3].includes(finInfoAccountDisplayTypeId) && this.renderScorecardVariables()}
            {[1, 3].includes(finInfoAccountDisplayTypeId) && this.modifyParentListGroup(this.state.accountsList)}
            {[1, 3].includes(finInfoAccountDisplayTypeId) && this.renderAccountListGroup(this.state.basicAccounts, 0, true)}
            {[2, 3].includes(finInfoAccountDisplayTypeId) && this.renderDiscreteAccountListGroup(this.state.discreteAccountsSelected)}
            {this.renderAccountListGroup(this.state.avgProjectAccounts)}
            {this.renderAccountListGroup(this.state.avgVolumeAccounts)}

            {this.renderCalculateValues()}

            {this.renderCalculatedAccordions(spinner)}

          </div>
          <div className="sciCommentary">
            <textarea
              className="sciInput"
              placeholder="Commentary"
              maxLength="999"
              name="commentary"
              id="commentary"
              value={commentary}
              onChange={(e) => this.onSelectChange(e, 'commentary')}
            />
          </div>

          <div className="scoreCardInput">
          {this.state.accountsListData && this.state.accountsListData.finIsComplete === 1 ? null : (
            <div className="sciFooter">
              {shouldShowSave && <button className={`sciButton${(this.state.isSaving || this.state.isCalculatingValue) ? " disabled" : ""}`} disabled={this.state.isSaving || this.state.isCalculatingValue} onClick={this.onSaveChanges}>{this.state.saveBtnLabel}</button>}
              {shouldShowSaveAndComplete && this.state.accountsListData.typeOfRenewal > 0
                ? <button className={`sciButton${(this.state.isSaving || this.state.isCalculatingValue) ? " disabled" : ""}`} disabled={this.state.isSaving || this.state.isCalculatingValue} onClick={this.onSaveChangesAndComplete}>{this.state.saveAndCompleteBtnLabel}</button>
                : null
              }
            </div>
          )}
        </div>
          <ToastContainer />
        </div>
      </div>

    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    local: state.localization,
    financials: state.financials,
    login: state.login,
    scProfile: state.SCProfile,
    scId: state.SCProfile.headerDetails.id,
    isLogVisible: state.scLogs.isFinancialLogVisible
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(financialActions, dispatch),
    scPorfileActions: bindActionCreators(scPorfileActions, dispatch),
    formLinkActions: bindActionCreators(formLinkActions, dispatch),
    scLogsAction: bindActionCreators(scLogsAction, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FinancialInfo));
